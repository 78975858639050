import { ClickAwayListener } from '@mui/material';
import { useIsMutating } from '@tanstack/react-query';
import classNames from 'classnames';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import RequestLoader from 'components/Loaders/Request';
import { Flex } from 'components/Utils/Flex';
import Text from 'components/Utils/Text';
import { useBrandStore } from 'config/hooks/useBrandStore';
import { useOrderingFormContext } from 'containers/orderingForm/contexts/useOrderingFormContext';
import 'containers/orderingForm/footer/_footer.scss';
import { CartButton } from 'containers/orderingForm/footer/cartButton';
import { CartList } from 'containers/orderingForm/footer/cartList';
import { NextButton } from 'containers/orderingForm/footer/nextButton';
import { useState } from 'react';
import { NXQuery } from 'utilities/query';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const Footer = () => {
    /***** STATE *****/
    const [isCartListOpen, setIsCartListOpen] = useState(false);
    const { activeBrand } = useBrandStore();

    /***** HOOKS *****/
    const { cartToken, currentPage } = useOrderingFormContext();
    const isEditCartItemLoading = useIsMutating({ mutationKey: ['editCartItem'] });

    /***** QUERIES *****/
    const { data: cart_data, isLoading: isCartDataLoading } = NXQuery.orderingForm.cart.cartId.getCart.useQuery(cartToken);

    /***** FUNCTIONS *****/
    const handleCartButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setIsCartListOpen(!isCartListOpen);
    };

    const footerCartClassNames = classNames('modalFooter__cart', {
        'modalFooter__cart--active': isCartListOpen,
        'modalFooter__cartText': currentPage === 'confirmOrder',
    });

    /***** RENDER HELPERS *****/
    const renderCart = () => {
        if (isCartDataLoading) return <RequestLoader />;

        if (cart_data?.cart?.items?.length) {
            return (
                <Flex justify="between">
                    {currentPage === 'confirmOrder' ? (
                        <Flex direction="column">
                            <Text className={footerCartClassNames} size--xm bold>
                                {`Total Amount: $${cart_data?.cart?.subtotal_amount}`}
                            </Text>
                            <Text>
                                {activeBrand === 'intaserve'
                                    ? `This amount will be added to your next invoice`
                                    : `You will be invoiced for the above domains`}
                            </Text>
                        </Flex>
                    ) : (
                        <Flex align="center" className={footerCartClassNames}>
                            <ClickAwayListener onClickAway={() => setIsCartListOpen(false)}>
                                <div>
                                    <CartList isCartListOpen={isCartListOpen} />
                                    <CartButton
                                        icon={PhosphorIcons.Plus}
                                        iconSize={25}
                                        onClick={handleCartButtonClick}
                                        isCartListOpen={isCartListOpen}
                                    />
                                </div>
                            </ClickAwayListener>
                            <Flex gap={0} direction="column">
                                <Text
                                    size--xm
                                    bold
                                >{`${cart_data?.cart?.items?.length} ${cart_data?.cart?.items?.length > 1 ? 'items' : 'item'} selected`}</Text>
                                <Flex>
                                    <Text>Subtotal: </Text>
                                    {isEditCartItemLoading ? (
                                        <RequestLoader fillHeight={18} height={10} fillWidth={5} />
                                    ) : (
                                        <Text>${cart_data?.cart?.subtotal_amount}</Text>
                                    )}
                                </Flex>
                            </Flex>
                        </Flex>
                    )}
                </Flex>
            );
        }

        return (
            <Flex justify="center">
                <Text>Your cart is empty </Text>
            </Flex>
        );
    };

    /***** RENDER *****/
    if (currentPage === 'editItemConfiguration') return;
    return (
        <Flex direction="column" justify="between" className="modalFooter">
            {renderCart()}
            <NextButton setIsCartListOpen={setIsCartListOpen} />
        </Flex>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
