import type { UseMutateFunction, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';
import { pushNotification } from 'components/Toast/functions';
import invariant from 'tiny-invariant';
import { createPopup, getErrorFromFailResponse } from 'utilities/methods/commonActions';
import { z } from 'zod';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type DefaultExpectedResponse = {
    data: {
        data: {
            attributes: {
                url: string;
            };
        };
    };
};

type Mutation<TData, TParams> = <TContext>(
    args: UseMutationOptions<TData, any, TParams, TContext>
) => UseMutationResult<TData, any, TParams, TContext>;

type Args<TData, TParams> =
    | Mutation<TData, TParams>
    | {
          useMutation: Mutation<TData, TParams>;
      };

type Select<TData> = (data: TData) => string;

type UsePopupMutationReturnType<TParams, TData = DefaultExpectedResponse> = [
    UseMutateFunction<TData, Error, TParams, ReturnType<typeof createPopup>>, //
    UseMutationResult<TData, Error, TParams, ReturnType<typeof createPopup>>
];

/**********************************************************************************************************
 *   SCHEMA
 **********************************************************************************************************/
const schema = z.object({
    data: z.object({
        data: z.object({
            attributes: z.object({
                url: z.string()
            })
        })
    })
});

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
const defaultSelect = (args: any) => {
    const validated = schema.safeParse(args);

    if (import.meta.env.DEV) {
        invariant(
            validated.success,
            "It looks like the default selector on usePopupMutation was used, however the type of the data doesn't match the expected structure, please provide a custom selector to extract the url from the response data."
        );
    }

    return args?.data?.data.attributes.url;
};

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Abstraction that accepts an object with a standard useMutation function (follows the NXQueryUtils.TMutationOptions pattern). This then
 * appropriately calls the mutation while creating a popup with the correct callbacks.
 *
 * If your mutation accepts a parameter such as an Id that needs to be bound (ie. `useMutation(id, opts)`), then this will need to be bound
 * to the function before being passed. This can be done like so:
 *
 * @example
 * ```ts
 * // This is untested and may need to create a clone before binding to avoid side effects
 * const useBoundMutation = NXQuery.hosting.loginCpanel.useMutation.bind(null, id);
 * const [mutate] = usePopupMutation(useBoundMutation);
 * ```
 *
 * @example
 * ```ts
 * const [mutate, { isLoading }] = usePopupMutation(NXQuery.hosting.loginCpanel);
 * ```
 *
 * @note - Available for use through NXQuery.Utils
 */
export const _usePopupMutation = <TParams, TData = DefaultExpectedResponse>(
    queryTreeLeafNode: Args<TData, TParams>,
    select: Select<TData> = defaultSelect
): UsePopupMutationReturnType<TParams, TData> => {
    const useMutation =
        typeof queryTreeLeafNode === 'function'
            ? queryTreeLeafNode //
            : queryTreeLeafNode.useMutation;

    /***** QUERIES *****/
    const result = useMutation({
        onMutate: () => createPopup(),
        onSuccess: (data, _, { goToTargetUrl }) => goToTargetUrl(select(data)),
        onError(error, _, context) {
            pushNotification(getErrorFromFailResponse(error));
            context?.closePopup();
        }
    });

    /***** HOOK RESULTS *****/
    return [/** mutate function */ result.mutate, /** extra named options */ result] as const;
};
