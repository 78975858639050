import classNames from 'classnames';
import { phosphorIconPropKeys } from 'components/Icons/Phosphor/consts';
import type { IconifyIconNamespace } from 'components/Icons/types';
import { createAppliedStylingClasses } from 'components/Utils/methods';

// Ratio of the original SVG size vs the standard size
const ratio = 255 / 34;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const RowsFill2: IconifyIconNamespace.SVGComponent = (props = {}) => {
    const { size = 25, svgProps } = props;

    /***** RENDER HELPERS *****/
    const appliedStylingClasses = createAppliedStylingClasses({
        props,
        keyBoundary: phosphorIconPropKeys,
        componentName: 'Icon',
        delimiter: '--'
    });

    const styles = {
        transform: classNames({
            [`rotate(${svgProps?.rotate}deg)`]: svgProps?.rotate
        })
    };

    return (
        <svg
            width={size}
            height={size}
            className={classNames('Icon', appliedStylingClasses)}
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 256 256"
            fill="currentColor"
            style={styles}
        >
            <g transform="translate(1, 1)">
                <g>
                    <g id="Layer_1_1727924307335">
                        <g>
                            <rect
                                ry={2 * ratio}
                                rx={2 * ratio}
                                height={9 * ratio}
                                width={24 * ratio}
                                y={6 * ratio}
                                x={4 * ratio}
                                opacity={0.2}
                                vectorEffect="non-scaling-stroke"
                                fill="currentColor"
                            />
                            <rect
                                ry={2 * ratio}
                                rx={2 * ratio}
                                height={9 * ratio}
                                width={24 * ratio}
                                y={17 * ratio}
                                x={4 * ratio}
                                vectorEffect="non-scaling-stroke"
                                fill="currentColor"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};
