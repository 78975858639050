import type { DefaultError, MutationOptions } from '@tanstack/react-query';
import { queryClient } from 'store/queryClient';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type CreateMutationOptions = <TData = unknown, TError = DefaultError, TVariables = void, TContext = unknown>(
    opts: MutationOptions<TData, TError, TVariables, TContext>
) => MutationOptions<TData, TError, TVariables, TContext>;

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
/**
 * utility function to create mutation options.
 */
export const mutationOptions: CreateMutationOptions = (opts) => opts;

/**
 * utility function to execute a mutation.
 */
export const createExecuteMutation = <TData = unknown, TError = DefaultError, TVariables = void>(
    createMutationOptions: <TContext>(
        options?: MutationOptions<TData, TError, TVariables, TContext>
    ) => MutationOptions<TData, TError, TVariables, TContext>
) => {
    const executeMutation = <TContext>(variables: TVariables, options?: MutationOptions<TData, TError, TVariables, TContext>) => {
        return queryClient.getMutationCache().build(queryClient, createMutationOptions(options)).execute(variables);
    };

    return executeMutation;
};
