import { invariant } from '@tanstack/react-router';
import { createContext, useContext } from 'react';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
export type PossibleLoginViews = (typeof possibleLoginViews)[number];
export type PossibleLoginSuccessViews = (typeof possibleLoginSuccessViews)[number];
type LoginContext = {
    /**
     * Set the current Login view.
     */
    setView: (view: PossibleLoginViews) => void;

    /**
     * Get the current login view
     */
    currentView: PossibleLoginViews;
};

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
export const possibleLoginViews = ['login', 'recovery', '2fa', 'forgot', 'alternative-2fa', 'success', 'reset', 'accountlist', 'loading'] as const;
export const possibleLoginSuccessViews = ['manual', 'password', 'reset'] as const;

/**********************************************************************************************************
 *   CONTEXT START
 **********************************************************************************************************/
export const LoginContext = createContext<LoginContext | undefined>(undefined);
export const useLoginContext = () => {
    const context = useContext(LoginContext);

    invariant(context, 'useLoginContext must be used within the /_login route');

    return context;
};
