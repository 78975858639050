import { Outlet } from "@tanstack/react-router";
import classNames from "classnames";
import { useBrandStore } from "config/hooks/useBrandStore";
import VentraIPCompanyLogo from 'config/images/company_without_australia.svg';
import { LoginFooter } from "containers/login/components/footer";
import { useLoginContext } from "containers/login/consts";
import ScamWarning from "containers/login/modules/scamWarning";
import ScamWarningBackground from "containers/login/modules/scamWarning/background";
import ScamWarningMessage from 'containers/login/modules/scamWarning/message';
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { useAppViewport } from "utilities/hooks/useAppViewport/useAppViewport";

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const VentraLoginLayoutOutlet = () => {
    /***** HOOKS *****/
    const { currentView } = useLoginContext();
    const { application } = useBrandStore();
    const isMobile = useAppViewport(['xs', 'sm']);

    /***** RENDER HELPERS *****/
    const isLoginPage = currentView === 'login';
    const loginContainerClassname = classNames('login__container', `login__container--${currentView}`, {
        maxHeight: ['recovery'].includes(currentView)
    });

    /***** RENDER *****/
    return (
        <>
            <div className="login__side--left">
                {!isMobile && isLoginPage && <ScamWarning />}
                {!(currentView === 'login') && (
                    <>
                        <div className="login__side-header">
                            <CompanyLogo />
                        </div>
                        <div className="login__side-content">
                            <div className="title animated">Welcome to {application}</div>
                            <div className="desc animated">We&apos;ve made a few changes!</div>
                        </div>
                    </>
                )}
            </div>
            <div className="login__side--right">
                {isMobile && isLoginPage && (
                    <>
                        <ScamWarningBackground />
                        <ScamWarningMessage />
                    </>
                )}
                <GoogleReCaptchaProvider reCaptchaKey={import.meta.env.VITE_GOOGLE_RECAPTCHA_CLIENT_KEY || ''}>
                    <div className={loginContainerClassname}>
                        <div className="login__containerContent">
                            <div className="login__transition">
                                <Outlet />
                            </div>
                        </div>
                    </div>
                </GoogleReCaptchaProvider>
                <LoginFooter />
            </div>
        </>
    );
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function CompanyLogo() {
    /***** HOOKS *****/
    const { activeBrand, company } = useBrandStore();

    /***** RENDER *****/
    switch (activeBrand) {
        case 'intaserve':
            return null;
        case 'ventra':
        default:
            return <img src={VentraIPCompanyLogo} alt={company} />;
    }
}
