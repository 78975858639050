import { authenticationQueryKeys } from 'containers/login/queries/queryKeys';
import { createElement } from 'react';
import { API } from 'utilities/api/login';
import { createNXQuery } from 'utilities/methods/tanstack/createQuery';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
const _accountList = createNXQuery(() => ({
    queryKey: authenticationQueryKeys.accountList(),
    queryFn: API.user.account.list,
    select: (data) => data?.data,
    staleTime: Infinity
}));

export const accountList = Object.assign(_accountList, {
    /**
     * HOC to pass through accountList data to a class component during a transition phase from redux to react-query
     */
    withData<P = {}>(component: React.Component<P>): React.FC<P> {
        return (props: P) => {
            const { data: login_account_list_data } = _accountList.useQuery();

            // @ts-ignore
            return createElement(component, {
                ...props,
                login_account_list_data
            });
        };
    }
});

