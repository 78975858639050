import { queryOptions, useQuery } from '@tanstack/react-query';
import { katanaQueryKeys } from 'containers/katana/queries/katanaQueryKeys';
import { KATANA_API } from 'utilities/api/katana';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Params = Parameters<typeof KATANA_API.katana.service_id.preview.page_preset.POST>[0];

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
function createQueryKey(params: Params) {
    return katanaQueryKeys.katana.service.ID.preview.pagePreset.ID(params);
}

function createQueryOptions({ serviceID, presetID, site, enabled }: Params & { enabled?: boolean }) {
    return queryOptions({
        queryKey: createQueryKey({ serviceID, presetID, site }),
        queryFn: () => KATANA_API.katana.service_id.preview.page_preset.POST({ serviceID, presetID, site }),
        enabled: Boolean(serviceID && presetID && enabled),
        staleTime: Infinity,
        select: (data) => {
            if (typeof data === 'string') {
                return data;
            }
        }
    });
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Gets the preview for the preset
 */
function _useQuery({ serviceID, presetID, site, enabled }: Params & { enabled?: boolean }) {
    return useQuery(createQueryOptions({ serviceID, presetID, site, enabled }));
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const pagePresetPreview = Object.freeze({
    useQuery: _useQuery,
    createQueryKey,
    createQueryOptions
});
