import { Base } from 'components/NXTable/Actions/base';
import { useNXTableContext } from 'components/NXTable/NXTableContext';
import { WrapWithComponent } from 'components/WrapWithComponent';
import React, { useLayoutEffect } from 'react';
import { isFragment } from 'react-is';
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';
import useRegistryData from 'utilities/hooks/useRegistryData';
import { useStyle } from 'utilities/hooks/useStyle';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Table Actions wrapper to handle display of buttons
 *
 * @Note - This component needs to be in the component that is rendering the actions, otherwise it will only see a single child and
 * will not resize the table accordingly, for example, even if a child component renders multiple buttons inside of a fragment,
 * this component will only see said child component, not the two buttons inside of it.
 *
 * @param {Object} props
 * @param {number} [props.force] - Force the number of actions to be registered
 * @param {React.ReactNode} props.children - The child components
 * @param {string} [props.className] - Optional className to add to the component
 */
export function Actions({ children, force, className }) {
    /***** HOOKS *****/
    const context = useNXTableContext();

    const isMobile = useAppViewport(['xs', 'sm']);
    const style = useStyle({
        '--actionButtonWidth': '100%'
    });

    if (Object.keys(context).length === 0)
        throw new Error(
            "No NXTableContext props, if you're using this inside the Accordion, use the Base Actions component instead: NXTable.Actions.Base"
        );

    const { columnsDefinition, setAllActions } = context;
    const { updateRegistry } = useRegistryData({ setRegistry: setAllActions });

    /***** EFFECTS *****/
    useLayoutEffect(() => {
        if (columnsDefinition?.includes('NXActions')) {
            switch (true) {
                case !!force:
                    return updateRegistry(force);
                case !children:
                    return updateRegistry(0);
                case Array.isArray(children):
                    return updateRegistry(children.length);
                case isFragment(children):
                    // Empty nodes (null, undefined, and Booleans) are ommitted from React.Children.toArray, but we still filter truthy
                    // so that values such as 0 are not considered children.
                    return updateRegistry(React.Children.toArray(children.props.children).filter(Boolean).length);
                default:
                    return updateRegistry(1);
            }
        }
    }, [columnsDefinition, children, force]);

    /***** RENDER *****/
    return (
        // prettier-ignore
        // @ts-ignore - WrapWithComponent needs to be updated with advanced type inference and support for native react-dom elements as strings
        <WrapWithComponent wrap={isMobile} component="div" style={style}>
            {/* Set 100% of buttons width on mobile (since buttons are stacked) */}
            <Base className={className}>
                {children}
            </Base>
        </WrapWithComponent>
    );
}
