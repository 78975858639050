import { ErrorBoundary } from '@sentry/react';
import { OutlineButton } from 'components/Buttons/OutlineButton';
import { Modal } from 'components/Lightboxes/Modal';
import RequestLoader from 'components/Loaders/Request';
import { RestoreServiceModalContent } from 'containers/services/modules/hosting/components/body/actions/restoreServiceButton/content';
import { RestoreServiceModalErrorComponent } from 'containers/services/modules/hosting/components/body/actions/restoreServiceButton/error';
import { Suspense, useState } from 'react';
import './_OverlayRestore.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const RestoreServiceButton = () => {
    /***** STATE *****/
    const [isLightboxOpen, setIsLightboxOpen] = useState(false);

    /***** FUNCTIONS *****/
    const closeLightbox = () => setIsLightboxOpen(false);

    /***** RENDER *****/
    return (
        <>
            <OutlineButton onClick={() => setIsLightboxOpen(true)} size="medium">
                Restore
            </OutlineButton>
            <Modal isOpen={isLightboxOpen} onClose={closeLightbox} ariaLabel="Service Restoration">
                <Modal.Title>Service Restoration</Modal.Title>
                <div className="OverlayRestore">
                    <ErrorBoundary fallback={<RestoreServiceModalErrorComponent />}>
                        <Suspense fallback={<RequestLoader />}>
                            <RestoreServiceModalContent />
                        </Suspense>
                    </ErrorBoundary>
                </div>
            </Modal>
        </>
    );
};
