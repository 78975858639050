import type { VentraPrimitiveTokens } from 'config/tokens/types';

/**********************************************************************************************************
 *   TOKEN START
 **********************************************************************************************************/
/**
 * @warning DO NOT USE INSIDE CCP
 */
export const _ventraPrimitive = {
    'P_color_primary': '#c32888',
    'P_color_primary_hover': '#b52578',
    'P_color_secondary': '#6c7a89',
    'P_color_secondary_hover': '#677482',
    'P_color_tertiary': '#ffffff',
    'P_color_tertiary_hover': '#ffffff',
    'P_color_confirm': '#2bce78',
    'P_color_confirm_hover': '#31b971',
    'P_color_info': '#54a9ef',
    'P_color_info_hover': '#5dadf0',
    'P_color_notice': '#fcae57',
    'P_color_notice_hover': '#f69122',
    'P_color_warn': '#fe7274',
    'P_color_warn_hover': '#eb5557',
    'P_color_white': '#ffffff',
    'P_color_black': '#343636',
    'P_color_grey': '#919ba7',
    'P_border-radius_default': '2px',
    // Above here are "compulsory", below are additional

    'P_color_transparent': 'transparent',

    'P_color_grey_20': '#fbfcfc',
    'P_color_grey_50': '#f5f7f8',
    'P_color_grey_60': '#f2f2f2',
    'P_color_grey_80': '#eaeaea',
    'P_color_grey_100': '#f5f6f7',
    'P_color_grey_125': '#f0f1f3',
    'P_color_grey_150': '#eff2f4',
    'P_color_grey_200': '#d7d7d7',
    'P_color_grey_220': '#cfd8dc',
    'P_color_grey_230': '#bcc4c7',
    'P_color_grey_250': '#c8d3d9',
    'P_color_grey_275': '#d7dfe3',
    'P_color_grey_300': '#bebebe',
    'P_color_grey_400': '#abb2ba',
    'P_color_grey_550': '#6e7076',
    'P_color_grey_580': '#55575c',
    'P_color_grey_700': '#5c6875',
    'P_color_grey_900': '#49535d',

    'P_color_orange_700': '#fd9727',

    'P_color_purple_50': '#fdf7fa',
    'P_color_purple_100': '#fff4f9',
    'P_color_purple_200': '#f3d4e7',
    'P_color_purple_300': '#eab4d5',
    'P_color_purple_325': '#e193c3',
    'P_color_purple_350': '#c42a87',
    'P_color_black_half_opacity': '#34363680',
    'P_color_blue-black': '#2e3a4a',

    'P_color_skyBlue_350': '#e5f1ff',
    'P_color_skyBlue_500': '#2c98f0',

    'P_color_aqua_400': '#159587',
    'P_color_pink_400': '#e72564',
    'P_color_green_400': '#8cc252',
    'P_color_violet_400': '#663fb4',
    'P_color_lightBlue_200': '#f0f7ff',

    'P_border-width_0': '0px',

    'P_border-radius_0': '0px',
    'P_border-radius_3': '3px',
    'P_border-radius_5': '5px',
    'P_border-radius_6': '6px',
    'P_border-radius_7': '7px',
    'P_border-radius_8': '8px',
    'P_border-radius_10': '10px',
    'P_border-radius_12': '12px',
    'P_border-radius_15': '15px',
    'P_border-radius_50': '50px',

    'P_padding_0': '0px',

    'P_margin_0': '0px',

    'P_box-shadow_white': '-6px 6px 12px #e7e4e3, 6px -6px 12px #fffcfb',

    /**
     * VMax Primitive Tokens
     */
    'P_color_primary_vmax': '#cc0099',
    'P_color_primary-darker_vmax': '#b8008a',
    'P_color_primary-darkest_vmax': '#a5007c',
    'P_color_primary-fade_vmax': '#ffccf2',
    'P_color_primary-light_vmax': '#ffe0f7',
    'P_color_primary-lightest_vmax': '#fff3fc',

    /**
     * VMax Greys Colours
     */
    'P_color_containers_vmax': '#f5f5f5',
    'P_color_dark_containers_vmax': '#e5e5e5',
    'P_color_lines_vmax': '#dcdcdc',
    'P_color_darker-lines_vmax': '#b4b4b4',

    /**
     * VMax Text Colours
     */
    'P_color_ebony_vmax': '#09091f',
    'P_color_ebony_light_vmax': '#585867',
    'P_color_ebony_lighter_vmax': '#7a7a87',
    'P_color_ebony_lightest_vmax': '#9e9ea8',

    /**
     * VMax Colours
     */
    'P_color_green_vmax': '#008c99',
    'P_color_red_vmax': '#e63946',
    'P_color_visual-editor_vmax': '#00ffcc',
    'P_color_links_vmax': '#0846ff',
} satisfies VentraPrimitiveTokens;
