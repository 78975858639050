/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useLocation } from '@tanstack/react-router';
import Subnav from 'components/Subnav';
import SolidTag from 'components/Tags/SolidTag';
import { useBrandStore } from 'config/hooks/useBrandStore';
import { isRouteActive } from 'router/utils/isRouteActive';
import { NXQuery } from 'utilities/query';

export const BillingSubnav = () => {
    /***** HOOKS *****/
    const { activeBrand } = useBrandStore();
    const pathname = useLocation({
        select: (state) => state.pathname
    });

    /***** SELECTORS *****/
    const { data: role } = NXQuery.auth.userData.useSelectSuspenseQuery(void 0, ({ data }) => data.attributes.role);

    const paymentMethodsPosition = activeBrand === 'intaserve' ? 2 : 1;

    /***** RENDER HELPERS *****/
    const navItems = [
        {
            label: 'Invoices',
            link: '/billing/invoices',
            icon: 'icon-migrations'
        },
        {
            label: 'Orders',
            link: '/billing/orders',
            icon: ''
        },
        {
            label: (
                <div className="Subnav__option--withTag">
                    VentraIP Wallet<SolidTag color="confirm">new</SolidTag>
                </div>
            ),
            link: '/billing/ventraip-wallet',
            icon: ''
        },
        {
            label: 'Services Alignment',
            link: '/billing/services-alignment',
            icon: ''
        },
        {
            label: 'SLA Claim',
            link: '/billing/sla',
            icon: 'customer-service'
        }
    ];

    if (role === 'Account Holder' || role === 'Admin') {
        navItems.splice(paymentMethodsPosition, 0, {
            label: 'Payment Methods',
            link: '/billing/payment-methods',
            icon: 'eticket'
        });
    }

    const activeBrandNavItems = navItems.filter((item) => {
        switch (item.link) {
            case '/billing/ventraip-wallet':
                return isRouteActive({ path: '/billing/ventraip-wallet' });
            case '/billing/sla':
                return isRouteActive({ path: '/billing/sla' });
            case '/billing/services-alignment':
                return isRouteActive({ path: '/billing/services-alignment' });
            case '/billing/orders':
                return isRouteActive({ path: '/billing/orders' });
            default:
                return true;
        }
    });

    /***** RENDER *****/
    return <Subnav items={activeBrandNavItems} pathname={pathname} />;
};
