import React, { createContext, useContext } from 'react';
import type { NXQuery } from 'utilities/query';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type NXQueryProvider = React.FC<{
    queryTree: NXQuery;
    children: React.ReactNode;
}>;
type NXQuery = typeof NXQuery;

const NXQueryContext = createContext<NXQuery>(undefined!);

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export const useNXQuery = () => useContext(NXQueryContext);

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
// prettier-ignore
export const NXQueryProvider: NXQueryProvider = ({ children, queryTree }) => (
    <NXQueryContext.Provider value={queryTree}>
        {children}
    </NXQueryContext.Provider>
);
