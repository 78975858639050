import { LiveChatWidget } from '@livechat/widget-react';
import { useLocation } from '@tanstack/react-router';
import { changeLivechatVisibility, livechatVisibilityOptions } from 'App/action';
import { serviceNav } from 'config/config';
import { useBrandStore } from 'config/hooks/useBrandStore';
import { useSelector } from 'react-redux';
import { useIsVPN } from 'utilities/hooks/redux/useIsVPN';
import { NXQuery } from 'utilities/query';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const LiveChat = () => {
    /***** HOOKS *****/
    const { application, company } = useBrandStore();
    const { pathname } = useLocation();
    const isVPN = useIsVPN();

    /***** QUERIES *****/
    const { data: visitor } = NXQuery.auth.userData.useSelectSuspenseQuery(void 0, ({ data }) => ({
        name: `${data.attributes.firstname} ${data.attributes.lastname}`,
        email: data.attributes.email
    }));

    /***** SELECTORS *****/
    const app_livechat_visibility = useSelector((state: any) => state.app.app_livechat_visibility);

    /***** RENDER *****/
    if (isVPN || window.ccpIsAppWebview || company !== 'VentraIP' || import.meta.env.VITE_CCP_ENVIRONMENT === 'development') {
        return null;
    }

    /***** RENDER HELPERS *****/
    const params = {
        Company: company,
        Location: application
    };

    // Don't show livechat on VIP sites
    const visibility = pathname.includes(serviceNav.VIPsites.link) ? livechatVisibilityOptions.MINIMIZED.visibility : app_livechat_visibility;

    /***** RENDER *****/
    return (
        <LiveChatWidget
            license="7094961"
            customerName={visitor.name}
            customerEmail={visitor.email}
            sessionVariables={params}
            visibility={visibility}
            onVisibilityChanged={changeLivechatVisibility}
        />
    );
};
