import classNames from 'classnames';
import { Flex } from 'components/Utils/Flex';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type InfoBubble = React.FC<InfoBubbleProps>;
type InfoBubbleProps = {
    label: string;
    className?: string;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const InfoBubble: InfoBubble = ({ label, className }) => {
    /***** RENDER *****/
    return (
        <Flex className={classNames('infoBubble', className)}>
            <Text size--xs semiBold lead--none className="infoBubble__label">
                {label}
            </Text>
        </Flex>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
