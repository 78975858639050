import type { DomainData, Item } from 'containers/orderingForm/types';
import { createContext } from 'react';

type OrderingFormContext = {
    cartToken: string | null;
    setCartToken: React.Dispatch<React.SetStateAction<string | null>>;
    currentPage: string;
    goToPage: (page: string) => void;
    goForwardPage: () => void;
    goBackPage: () => void;
    currentItem: Item | null;
    setCurrentItem: React.Dispatch<React.SetStateAction<Item | null>>;
    clearState: () => void;
    domainData: DomainData;
};

export const OrderingFormContext = createContext<OrderingFormContext | undefined>(undefined);
