/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { createContext, useContext } from 'react';
import { useSelector as _useSelector } from 'react-redux';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
/**
 * @typedef {import('./types').NMicrosoft365Table.TContext} TContext
 * @typedef {import('./types').NMicrosoft365Table.TStore} TStore
 * @typedef {import('react-redux').TypedUseSelectorHook<TStore>} TUseSelector
 */
const context = /** @type {TContext} */ ({
    initialized: false,
    isExpressServiceManager: false,
    numberOfRows: null,
    search: {
        isSearching: false,
        keyword: null,
        status: null
    },
    setSearch: () => {},
    setNumberOfRows: () => {},
    setCancelService: () => {},
    setKeepService: () => {}
});

export const Microsoft365TableContext = createContext(context);
export const useMicrosoft365TableContext = () => useContext(Microsoft365TableContext);
export const useMicrosoft365TableSelector = /** @type {TUseSelector} */ (_useSelector);
