import { accountMutationKeys } from "containers/account/queries/queryKeys";
import { API } from "utilities/api/account";
import { createNXMutation } from "utilities/methods/tanstack/createMutation";

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
export const approveSubaccount = createNXMutation({
    mutationKey: accountMutationKeys.approveSubaccount(),
    mutationFn: API.account.GET.subaccount.approve
});
