import _ from 'lodash';

export function dismiss2faPromptMigration() {
    const currentlyStoredData = localStorage.getItem('dismiss_2fa_prompt');
    if (!currentlyStoredData) return;
    const parsedStoredData = JSON.parse(currentlyStoredData);

    if (!_.isNil(parsedStoredData)) {
        localStorage.setItem('USER_PREFERENCE:IS_2FA_PROMPT_DISMISSED', JSON.stringify(parsedStoredData));
    }
}
