import { queryOptions, useQuery } from '@tanstack/react-query';
import type { Item } from 'containers/orderingForm/types';
import { queryClient } from 'store/queryClient';
import { API } from 'utilities/api/services';
import { createBaseQueryKey } from 'utilities/methods/tanstack/createBaseQueryKey';

/***** FUNCTIONS *****/
function createQueryKey(cartToken: string | null) {
    return createBaseQueryKey(['cart', cartToken]);
}

function createQueryOptions(cartToken: string | null) {
    return queryOptions({
        queryKey: createQueryKey(cartToken),
        queryFn: () => API.cart.GET.items(cartToken),
        enabled: Boolean(cartToken),
        select: (data) => data.data
    });
}

const _setQueryData = (cartToken: string | null, updatedItems: Array<Item>) => {
    return queryClient.setQueryData(createQueryKey(cartToken), () => updatedItems);
};

const _invalidateQuery = (cartToken: string | null) => {
    return queryClient.invalidateQueries({ queryKey: createQueryKey(cartToken) });
};

const _refetchQuery = (cartToken: string | null) => {
    return queryClient.fetchQuery(createQueryOptions(cartToken));
};

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useQuery(cartToken: string | null) {
    return useQuery(createQueryOptions(cartToken));
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const getCart = Object.freeze({
    invalidateQuery: _invalidateQuery,
    useQuery: _useQuery,
    setQueryData: _setQueryData,
    refetchQuery: _refetchQuery,
    createQueryKey,
    createQueryOptions
});
