/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useMutation } from '@tanstack/react-query';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { KATANA_API } from 'utilities/api/katana';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useMutation() {
    return useMutation({
        mutationFn: KATANA_API.katana.service_id.user.socials.$type.callback.POST,
        onSuccess: () => {}
    });
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const socialAuthorisationCallback = Object.freeze({
    useMutation: _useMutation
});
