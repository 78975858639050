import { createRoute } from '@tanstack/react-router';
import { VIPSitesContentLightboxLayoutRoute } from 'containers/katana/routes/vip-sites.$katanaServiceId/_content-editor-lightbox';
import { routerMiddleware } from 'router/utils/middleware';
import { lazyFN } from 'utilities/methods/tanstack/router/lazyFn';

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const VIPSitesAddPageRoute = createRoute({
    getParentRoute: () => VIPSitesContentLightboxLayoutRoute,
    path: 'add-page',
    beforeLoad(opts) {
        routerMiddleware.business(this, opts);
        routerMiddleware.authentication('user', opts);
    }
}).lazy(lazyFN(() => import('./add-page.lazy'), 'LazyVIPSitesAddPageRoute'));
