import type { VentraSemanticTokens } from 'config/tokens/types';
import type { _ventraPrimitive } from 'config/tokens/ventra/primitive';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type VentraSematic = VentraSemanticTokens<typeof _ventraPrimitive>;

/**********************************************************************************************************
 *   TOKEN START
 **********************************************************************************************************/
/**
 * @warning DO NOT USE INSIDE CCP
 */
export const _ventraSemantic = {
    'S_color_background_primary_base': 'P_color_primary',
    'S_color_background_primary_hover': 'P_color_primary_hover',
    'S_color_background_secondary_base': 'P_color_secondary',
    'S_color_background_secondary_hover': 'P_color_secondary_hover',
    'S_color_background_secondary_light': 'P_color_grey_50',
    'S_color_background_secondary-alt_base': 'P_color_secondary',
    'S_color_background_secondary-alt_hover': 'P_color_secondary',
    'S_color_background_tertiary_base': 'P_color_tertiary',
    'S_color_background_tertiary_hover': 'P_color_tertiary_hover',
    'S_color_background_confirm_base': 'P_color_confirm',
    'S_color_background_confirm_hover': 'P_color_confirm_hover',
    'S_color_background_info_base': 'P_color_info',
    'S_color_background_info_hover': 'P_color_info_hover',
    'S_color_background_notice_base': 'P_color_notice',
    'S_color_background_notice_hover': 'P_color_notice_hover',
    'S_color_background_warn_base': 'P_color_warn',
    'S_color_background_warn_hover': 'P_color_warn_hover',
    'S_color_background_default_base': 'P_color_white',
    'S_color_background_inverse_base': 'P_color_black',
    'S_color_background_black_base': 'P_color_black',
    'S_color_background_grey_base': 'P_color_grey',

    'S_color_text_primary_base': 'P_color_primary',
    'S_color_text_primary_hover': 'P_color_primary_hover',
    'S_color_text_secondary_base': 'P_color_secondary',
    'S_color_text_secondary_hover': 'P_color_secondary_hover',
    'S_color_text_confirm_base': 'P_color_confirm',
    'S_color_text_confirm_hover': 'P_color_confirm_hover',
    'S_color_text_info_base': 'P_color_info',
    'S_color_text_info_hover': 'P_color_info',
    'S_color_text_notice_base': 'P_color_notice',
    'S_color_text_notice_hover': 'P_color_notice_hover',
    'S_color_text_warn_base': 'P_color_warn',
    'S_color_text_warn_hover': 'P_color_warn_hover',
    'S_color_text_default_base': 'P_color_black',
    'S_color_text_inverse_base': 'P_color_white',
    'S_color_text_inverse_light': 'P_color_grey_200',
    'S_color_text_grey_base': 'P_color_grey',

    'S_color_icon_primary_base': 'P_color_primary',
    'S_color_icon_secondary_base': 'P_color_secondary',
    'S_color_icon_confirm_base': 'P_color_confirm',
    'S_color_icon_notice_base': 'P_color_notice',
    'S_color_icon_info_base': 'P_color_info',
    'S_color_icon_warn_base': 'P_color_warn',
    'S_color_icon_default_base': 'P_color_white',
    'S_color_icon_inverse_base': 'P_color_black',
    'S_color_icon_grey_base': 'P_color_grey',

    'S_color_border_primary_base': 'P_color_primary',
    'S_color_border_secondary_base': 'P_color_secondary',
    'S_color_border_secondary-alt_base': 'P_color_grey_230',
    'S_color_border_confirm_base': 'P_color_confirm',
    'S_color_border_info_base': 'P_color_info',
    'S_color_border_notice_base': 'P_color_notice',
    'S_color_border_warn_base': 'P_color_warn',
    'S_color_border_default_base': 'P_color_grey_200',
    'S_color_border_grey_base': 'P_color_grey',
    'S_color_border_black_base': 'P_color_black',
    'S_color_border_white_base': 'P_color_white',
    'S_color_border_transparent': 'P_color_transparent',

    'S_border-radius_default': 'P_border-radius_default',
    'S_border-radius_rounded': 'P_border-radius_50',

    /**
     * VMax Containers
     */
    'S_color_background_primary_vmax': 'P_color_primary_vmax',
    'S_color_background_primary_vmax_hover': 'P_color_primary-darker_vmax',
    'S_color_background_primary_vmax_active': 'P_color_primary-darkest_vmax',
    'S_color_background_text_primary_vmax': 'P_color_white',

    'S_color_background_primary-light_vmax': 'P_color_primary-lightest_vmax',
    'S_color_background_primary-light_vmax_hover': 'P_color_primary-light_vmax',
    'S_color_background_primary-light_vmax_active': 'P_color_primary-fade_vmax',
    'S_color_background_text_primary-light_vmax': 'P_color_primary_vmax',

    'S_color_background_black_vmax': 'P_color_ebony_vmax',
    'S_color_background_black_vmax_hover': 'P_color_ebony_light_vmax',
    'S_color_background_black_vmax_active': 'P_color_ebony_lighter_vmax',

    'S_color_background_outline_vmax': 'P_color_white',
    'S_color_background_outline_vmax_hover': 'P_color_containers_vmax',
    'S_color_background_outline_vmax_active': 'P_color_lines_vmax',
    'S_color_background_text_outline_vmax': 'P_color_ebony_vmax',
    'S_color_background_icon_outline_vmax': 'P_color_ebony_light_vmax',
    'S_color_border_outline_vmax': 'P_color_darker-lines_vmax',

    'S_color_background_containers_vmax': 'P_color_containers_vmax',
    'S_color_background_dark-containers_vmax': 'P_color_dark_containers_vmax',

    /**
     * VMax Border
     */
    'S_color_border_lines_vmax': 'P_color_lines_vmax',

    /**
     * VMax Text
     */
    'S_color_text_primary_vmax': 'P_color_ebony_vmax',
    'S_color_text_secondary_vmax': 'P_color_ebony_light_vmax',

    /**
     * VMax Border Radius
     */
    'S_border-radius_vmax-large': 'P_border-radius_12',
    'S_border-radius_vmax-base': 'P_border-radius_10',
    'S_border-radius_vmax-small': 'P_border-radius_8',
} satisfies VentraSematic;
