import type { DropdownWrapperNamespace } from 'components/Dropdowns/DropdownWrapper/types';
import type { DropdownNamespace } from 'components/Dropdowns/types';
import { createContext, useContext } from 'react';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Context = {
    isOpen: boolean;
    setIsOpen: (state: boolean) => void;
    variant: DropdownWrapperNamespace.Variant;
    color: DropdownNamespace.WrapperProps['color'];
};

export const DropdownWrapperContext = createContext<Context>({
    isOpen: false,
    setIsOpen: (state: boolean) => {},
    variant: 'Solid',
    color: 'primary'
});

export function useDropdownWrapperContext() {
    return useContext(DropdownWrapperContext);
}
