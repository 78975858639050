import { auEligibility } from 'containers/domain/queries/auEligibility';
import { availability } from 'containers/domain/queries/availability';
import { configureDNSMode } from 'containers/domain/queries/domainID/configureDNSMode';
import { getDNSPresetPreview } from 'containers/domain/queries/domainID/getDNSPresetPreview';
import { getDomainInfo } from 'containers/domain/queries/domainID/getDomainInfo';
import { eppValidation } from 'containers/domain/queries/eppValidation';
import { domainsList } from 'containers/domain/queries/getDomainsList';
import { domainsSearch } from 'containers/domain/queries/searchDomainsList';
import { suggestion } from 'containers/domain/queries/suggestion';

export const _domainQuery = Object.freeze({
    domainsList,
    domainsSearch,
    domainID: {
        getDomainInfo,
        getDNSPresetPreview,
        configureDNSMode,
        auEligibility
    },
    availability,
    eppValidation,
    suggestion
});
