import { createRoute } from '@tanstack/react-router';
import { AppRoute } from 'App';
import { routerMiddleware } from 'router/utils/middleware';
import { lazyFN } from 'utilities/methods/tanstack/router/lazyFn';

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const ContactSupportRoute = createRoute({
    getParentRoute: () => AppRoute,
    path: 'contact-support',
    beforeLoad(opts) {
        routerMiddleware.business(this, opts);
        // routerMiddleware.authentication('user', opts);
    }
}).lazy(lazyFN(() => import('./index.lazy'), 'LazyContactSupportRoute'));
