/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { Link } from '@tanstack/react-router';
import classNames from 'classnames';
import htmr from 'htmr';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { OutlineButton } from 'components/Buttons/OutlineButton';
import RightBorderButton from 'components/Buttons/RightBorderButton';
import { SolidButton } from 'components/Buttons/SolidButton';
import { CustomDropdown } from 'components/Dropdowns/CustomDropdown';
import { OutlineDropdown } from 'components/Dropdowns/OutlineDropdown';
import SelectDropdown from 'components/Dropdowns/SelectDropdown';
import { SolidDropdown } from 'components/Dropdowns/SolidDropdown';
import RequestLoader from 'components/Loaders/Request';
import Search from 'components/Search';
import Tooltip from 'components/Tooltip';
import Transition from 'components/Transition';
import { accessibilityClick } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_Table.scss';
import type { SharedTableProps } from './types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @deprecated For the sake of sanity, this component is now in __*Component Prison*__, The new `NXTable` is much more flexible and this is just a monster of a component. just don't use it. use `components/NXTable` instead!
 */
class SharedTable extends Component<SharedTableProps> {
    constructor(props) {
        super(props);
        this.state = {
            matrix: null,
            rowIndex: null,
            mobile: false,
            sort: null,
            order: 'desc',
            showDropdownMenu: false,
            stateActionsLength: 0
        };
        this.toggleSort = this.toggleSort.bind(this);
        this.toggleDropdownMenu = this.toggleDropdownMenu.bind(this);
        this.clickAway = this.clickAway.bind(this);
        this.getMaxActionLength = this.getMaxActionLength.bind(this);
    }

    toggleSort(option) {
        const { sort } = this.props;
        const { order } = this.state;

        this.setState(
            {
                sort: option,
                order: order === 'asc' ? 'desc' : 'asc'
            },
            () => {
                const params = {
                    sort_by: option,
                    sort: order === 'asc' ? 'desc' : 'asc'
                };
                sort.function(params);
            }
        );
    }

    toggleDropdownMenu() {
        const { showDropdownMenu } = this.state;
        this.setState({
            showDropdownMenu: !showDropdownMenu
        });
    }

    clickAway(e) {
        if (this.tableActionDropdown && this.tableActionDropdown !== e.target && !this.tableActionDropdown.contains(e.target)) {
            this.setState({
                showDropdownMenu: false
            });
        }
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.clickAway);
        document.removeEventListener('touchstart', this.clickAway);
    }

    componentDidMount() {
        const { matrix } = this.props;
        const { getMaxActionLength } = this;

        getMaxActionLength(matrix);

        document.addEventListener('click', this.clickAway);
        document.addEventListener('touchstart', this.clickAway);
    }

    componentDidUpdate(prevState) {
        const { app_viewport, matrix, rerender } = this.props;
        const { getMaxActionLength } = this;
        const mobile = app_viewport === 'xs' || app_viewport === 'sm';

        if (matrix !== prevState.matrix) {
            this.setState(
                {
                    mobile
                },
                () => {
                    getMaxActionLength(matrix);
                }
            );
        }

        if (rerender) {
            this.forceUpdate();
        }
    }

    rowClick(index) {
        const { app_viewport } = this.props;
        if (index === this.state.rowIndex || (app_viewport !== 'xs' && app_viewport !== 'sm')) {
            return this.setState({
                rowIndex: null
            });
        }

        return this.setState({
            rowIndex: index
        });
    }

    getMaxActionLength(data) {
        let newMax = 0;

        if (data) {
            data.forEach((row) => {
                newMax = row && row.actions && row.actions.length >= newMax ? row.actions.length : newMax;
            });
        }

        this.setState(
            {
                stateActionsLength: newMax
            },
            () => {
                this.forceUpdate();
            }
        );
    }

    render() {
        const {
            app_viewport,
            className,
            title,
            search,
            headingActions,
            matrix,
            header,
            stacked,
            dynamicActions,
            layered,
            embedded,
            loading,
            error,
            footer,
            showAll
        } = this.props;
        const { rowIndex, sort, order, showDropdownMenu, stateActionsLength } = this.state;
        const { toggleSort, toggleDropdownMenu } = this;

        const setActionLength = () => {
            if (stateActionsLength === 1) {
                return `single`;
            } else if (stateActionsLength >= 1) {
                return `multi`;
            }

            return '';
        };

        /*   RENDER HEADING
         **********************************************************************************************************/
        const renderHeading = () => {
            const renderTitle = () => {
                return title ? (
                    <div className="sharedTable__heading--left">
                        <div className="box__heading">{title}</div>
                    </div>
                ) : (
                    ''
                );
            };

            const renderSearch = () => {
                if (!search) return '';

                const { slim, render, functions, conditions, helpers } = search;

                return <Search slim={slim} render={render} functions={functions} conditions={conditions} helpers={helpers} />;
            };

            const renderHeadingActions = () => {
                if (!headingActions || !Array.isArray(headingActions)) return '';

                const handleActionRender = () => {
                    return headingActions.map((item, index) => {
                        switch (item.type) {
                            case 'button':
                                return (
                                    <button
                                        key={index}
                                        disabled={item.loading}
                                        className={classNames('headingActions__action', item.className, { disabled: item.loading })}
                                        type="onClick"
                                        onClick={(e) => {
                                            item.onClick(e);
                                        }}
                                    >
                                        <span>{item.label}</span>
                                    </button>
                                );

                            case 'select':
                                return (
                                    <SelectDropdown
                                        key={index}
                                        noSelectionLabel={item.label}
                                        className={classNames('headingActions__action', item.className)}
                                        options={item.options}
                                        disabled={item.loading}
                                    />
                                );

                            case 'custom':
                                return (
                                    <CustomDropdown
                                        key={index}
                                        label={item.label}
                                        className={classNames('headingActions__action', item.className)}
                                        render={item.render}
                                        disabled={item.loading}
                                    />
                                );

                            case 'icon':
                                return (
                                    <button
                                        key={item.pos + index}
                                        disabled={item.loading}
                                        className={classNames('headingActions__action', 'headingActions__action--icon', item.className, {
                                            disabled: item.loading
                                        })}
                                        type="onClick"
                                        onClick={(e) => {
                                            item.onClick(e);
                                        }}
                                    >
                                        <i className={classNames('headingActions__icon', 'icon', `icon-${item.icon}`)} />
                                    </button>
                                );

                            default:
                                return '';
                        }
                    });
                };

                return <div className="headingActions">{handleActionRender()}</div>;
            };

            return title || search || headingActions ? (
                <div className="sharedTable__heading inline">
                    {renderTitle()}
                    {search || headingActions ? (
                        <div className="sharedTable__heading--right">
                            {renderSearch()}
                            {renderHeadingActions()}
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            ) : (
                ''
            );
        };

        /*   RENDER ROW
         **********************************************************************************************************/
        const renderRow = (data) => {
            if (!data || !data[0]) {
                return (
                    <div key="result-row" className="sharedTable__row result-row sharedTable__error">
                        <div key="result-details" className="sharedTable__details">
                            <div className="sharedTable__details--info results">{error}</div>
                        </div>
                    </div>
                );
            }

            return data.map((row, index) => {
                if (!row) {
                    return false;
                }
                let className = false;
                let rowClass = false;
                if (row['className']) {
                    className = row.className;
                } else if (row['rowClass']) {
                    rowClass = row.rowClass;
                }

                const indexDetailsOnClick = () => {
                    this.rowClick(index + '-row');
                };

                function renderRowActions() {
                    if (row.actions && !React.isValidElement(row.actions)) {
                        const desktopRow = Object.keys(row).map((column) => {
                            if (column !== 'actions') {
                                return '';
                            }
                            return renderColumn(row[column], column, className);
                        });

                        return (
                            <div
                                className={`sharedTable__details--actions${layered ? ' layered' : ''}${
                                    row.actions && typeof row.actions !== 'object' && dynamicActions ? ' stacked--actions' : ''
                                }${className ? ' details-actions__' + className : ''} ${setActionLength()}`}
                            >
                                {app_viewport !== 'sm' && app_viewport !== 'xs' ? desktopRow : ''}
                                <div className={`sharedTable__column--action mobileAction ${className ? 'mobileAction__' + className : ''}`}>
                                    <i className="icon icon-chevron-down"></i>
                                </div>
                            </div>
                        );
                    }

                    if (row.actions && React.isValidElement(row.actions)) {
                        const desktopRow = Object.keys(row).map((column) => {
                            if (column !== 'actions') {
                                return '';
                            }
                            return renderColumn(row[column], column, className);
                        });

                        return (
                            <div
                                className={`sharedTable__details--actions${layered ? ' layered' : ''}${
                                    row.actions && typeof row.actions !== 'object' && dynamicActions ? ' stacked--actions' : ''
                                }${className ? ' details-actions__' + className : ''} ${setActionLength()}${
                                    row.actions && React.isValidElement(row.actions) ? ` hasNotice` : ``
                                }`}
                            >
                                {app_viewport !== 'sm' && app_viewport !== 'xs' ? desktopRow : ''}
                            </div>
                        );
                    }

                    return <div className="sharedTable__details--null"></div>;
                }

                function renderMobileRowActions() {
                    if (!['sm', 'xs'].includes(app_viewport)) {
                        return '';
                    }

                    if (row.actions && typeof row.actions === 'object' && row.actions.constructor !== Array && !React.isValidElement(row.actions)) {
                        return (
                            <div
                                key={index + '-drawer'}
                                className={`sharedTable__drawer ${className ? className : ''}${
                                    row.actions && typeof row.actions === 'object' && dynamicActions ? ' stacked--actions' : ''
                                }`}
                            >
                                {row.html ? (
                                    <div key={index + '-data'} className="sharedTable__drawer--data">
                                        {row.html}
                                    </div>
                                ) : (
                                    ''
                                )}
                                {row.actions ? (
                                    <div key={'g-' + index + '-actions'} className="sharedTable__drawer--actions">
                                        {renderMobileActions(row.actions)}
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                        );
                    }

                    if (row.actions && typeof row.actions === 'object' && row.actions.constructor === Array && !React.isValidElement(row.actions)) {
                        return (
                            <div
                                key={index + '-drawer'}
                                className={`sharedTable__drawer ${className ? className : ''}${
                                    row.actions && typeof row.actions === 'object' && dynamicActions ? ' stacked--actions' : ''
                                }`}
                            >
                                {row.html ? (
                                    <div key={index + '-data'} className="sharedTable__drawer--data">
                                        {row.html}
                                    </div>
                                ) : (
                                    ''
                                )}
                                {row.actions ? (
                                    <div key={'g-' + index + '-actions'} className="sharedTable__drawer--actions">
                                        {renderMobileActionsArray(row.actions)}
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                        );
                    }

                    return '';
                }

                return (
                    <div
                        key={index + '-row'}
                        className={classNames('sharedTable__row', {
                            'active': rowIndex === index + '-row',
                            [rowClass]: rowClass,
                            'sharedTable__row--last-row': !showAll?.conditions
                        })}
                    >
                        {/* Disable lint warning as it's a deprecated component */}
                        {/* eslint-disable-next-line */}
                        <div
                            key={index + '-details'}
                            onClick={() => indexDetailsOnClick()}
                            className={`sharedTable__details${layered ? ' layered' : ''}${
                                row.actions && React.isValidElement(row.actions) && dynamicActions ? ' stacked--actions' : ''
                            }`}
                        >
                            <div
                                className={`sharedTable__details--info${layered ? ' layered' : ''}${className ? ' details-info__' + className : ''}`}
                            >
                                {Object.keys(row).map((column) => {
                                    if (column === 'html' || column === 'className' || column === 'datalabel' || column === 'rowClass') {
                                        return '';
                                    } else if (column !== 'actions') {
                                        return renderColumn(row[column], column, className);
                                    }
                                    return '';
                                })}
                            </div>
                            {renderRowActions()}
                        </div>
                        {renderMobileRowActions()}
                    </div>
                );
            });
        };

        const handleDataLabelRender = (data, key) => {
            if (React.isValidElement(data) && key === 'actions' && dynamicActions) {
                return 'Notice';
            } else if (header) {
                let label;

                header.map((head) => {
                    const { datalabel, title } = head;
                    if (datalabel && datalabel === key) {
                        label = title;
                    }

                    return false;
                });

                return label ? label : key;
            }
            return key;
        };

        /*   RENDER COLUMN
         **********************************************************************************************************/
        const renderColumn = (data, key, className) => {
            if (!data) {
                return (
                    <div
                        key={`${key}-col`}
                        tabIndex={-1}
                        className={`sharedTable__column ${key + '-col'} ${className ? key + '-col__' + className : ''}`}
                        data-label={handleDataLabelRender(data, key)}
                    ></div>
                );
            } else if (typeof data === 'string') {
                return (
                    <div
                        key={`${key}-col`}
                        tabIndex={-1}
                        className={`sharedTable__column ${key + '-col'} ${className ? key + '-col__' + className : ''}`}
                        data-label={handleDataLabelRender(data, key)}
                    >
                        {htmr(data)}
                    </div>
                );
            } else if (
                typeof data === 'number' ||
                typeof data === 'function' ||
                typeof data === 'symbol' ||
                (typeof data === 'object' && data.constructor === RegExp) ||
                (typeof data === 'object' && React.isValidElement(data)) ||
                data instanceof Date
            ) {
                return (
                    <div
                        key={`${key}-col`}
                        tabIndex={-1}
                        className={`sharedTable__column ${key + '-col'} ${className ? key + '-col__' + className : ''}`}
                        data-label={handleDataLabelRender(data, key)}
                    >
                        {data}
                    </div>
                );
            } else if (typeof data === 'object' && data.constructor === Array) {
                return data.map((button) => {
                    return typeof button === 'object' && React.isValidElement(button)
                        ? renderAction({ isElement: true, button }, className)
                        : renderAction(button, className);
                });
            } else if (typeof data === 'object' && data.constructor === Object) {
                return renderAction(data, className);
            }

            return (
                <div
                    key={`${key}-col`}
                    tabIndex={-1}
                    className={`sharedTable__column ${key + '-col'} ${className ? key + '-col__' + className : ''}`}
                    data-label={key}
                >
                    {data.map((r, i) => (
                        <React.Fragment key={i}>{r}</React.Fragment>
                    ))}
                </div>
            );
        };

        /*   RENDER ACTION
         **********************************************************************************************************/
        const renderAction = (data, extClass) => {
            const { label, type, onClick, className, color, list, size, button, isElement, to } = data;

            const keyValue = `${button?.key}_${button?.props?.className}_${label}`;
            const key = `${keyValue}-action`;
            if (isElement) {
                return (
                    <div key={key} className={`sharedTable__column--action ${extClass ? 'action-' + extClass : ''}`}>
                        {button}
                    </div>
                );
            }

            if (button && button === 'Solid') {
                if (list) {
                    const conditionalProps = {};

                    if (to) conditionalProps.to = to;
                    else if (onClick) conditionalProps.titleOnClick = onClick;

                    return (
                        <div key={key} className={`sharedTable__column--action ${extClass ? 'action-' + extClass : ''}`}>
                            <SolidDropdown
                                title={label}
                                color={color ? color : 'primary'}
                                size={size ? size : 'small'}
                                className={className ? className : ''}
                                list={list}
                                {...conditionalProps}
                            />
                        </div>
                    );
                }

                const conditionalProps = {};

                if (to) conditionalProps.to = to;
                else if (onClick) conditionalProps.onClick = onClick;

                return (
                    <div key={key} className={`sharedTable__column--action ${extClass ? 'action-' + extClass : ''}`}>
                        <SolidButton
                            color={color ? color : 'primary'}
                            type={type}
                            size={size ? size : 'small'}
                            className={className ? className : ''}
                            {...conditionalProps}
                        >
                            {label}
                        </SolidButton>
                    </div>
                );
            }

            if (list && list.length > 0) {
                const conditionalProps = {};

                if (to) conditionalProps.to = to;
                else if (onClick) conditionalProps.titleOnClick = onClick;

                return (
                    <div key={label + '-action'} className={`sharedTable__column--action ${extClass ? 'action-' + extClass : ''}`}>
                        <OutlineDropdown
                            title={label}
                            color={color ? color : 'primary'}
                            size={size ? size : 'small'}
                            className={className ? className : ''}
                            list={list}
                            {...conditionalProps}
                        />
                    </div>
                );
            }

            const conditionalProps = {};

            if (to) conditionalProps.to = to;
            else if (onClick) conditionalProps.onClick = onClick;

            return (
                <div key={label + '-action'} className={`sharedTable__column--action ${extClass ? 'action-' + extClass : ''}`}>
                    <OutlineButton
                        color={color ? color : 'primary'}
                        type={type}
                        size={size ? size : 'small'}
                        className={className ? className : ''}
                        {...conditionalProps}
                    >
                        {label}
                    </OutlineButton>
                </div>
            );
        };

        /*   RENDER MOBILE ACTIONS
         **********************************************************************************************************/
        const renderMobileActions = (data) => {
            const newActions = [];

            if (data.list) {
                data.list.forEach((extracted) => {
                    newActions.push(extracted);
                });
            }

            newActions.unshift(data);

            return newActions.map((output) => {
                if (output.disabled && output.disabled === true) {
                    return '';
                }

                if (output.button && output.button === 'Solid') {
                    const conditionalProps = {};

                    if (output.to) conditionalProps.to = output.to;
                    else if (output.onClick) conditionalProps.onClick = output.onClick;

                    return (
                        <SolidButton
                            key={output.label + '-action'}
                            color={output.color ? output.color : 'primary'}
                            type={output.type}
                            {...conditionalProps}
                            className={output.className ? className : ''}
                        >
                            {output.label}
                        </SolidButton>
                    );
                }

                if (output.type === 'internal') {
                    return (
                        <SolidButton
                            key={output.label + '-action'}
                            color={output.color ? output.color : 'primary'}
                            to={output.link}
                            className={output.className ? className : ''}
                        >
                            {output.label}
                        </SolidButton>
                    );
                }

                const conditionalProps = {};

                if (output.to) conditionalProps.to = output.to;
                else if (output.onClick) conditionalProps.onClick = output.onClick;

                // Restructure the object if it was in the list key
                if (output.props) {
                    output = {
                        ...output,
                        color: output.props.color,
                        label: output.props.children,
                        type: output.props.type
                    };

                    conditionalProps.onClick = output.props.onClick;
                }

                return (
                    <SolidButton
                        key={output.label + '-action'}
                        color={output.color ? output.color : 'primary'}
                        type={output.type}
                        {...conditionalProps}
                        className={output.className ? className : ''}
                    >
                        {output.label}
                    </SolidButton>
                );
            });
        };

        const renderMobileActionsArray = (data) => {
            const newActions = [];
            data.forEach((extracted) => {
                newActions.push(extracted);

                if (extracted.list) {
                    extracted.list.forEach((subExtract) => {
                        newActions.push(subExtract);
                    });
                }
            });

            return newActions.map((output) => {
                if (typeof output === 'object' && React.isValidElement(output)) return output;

                if (output.button && output.button === 'Solid') {
                    const conditionalProps = {};

                    if (output.to) conditionalProps.to = output.to;
                    else if (output.onClick) conditionalProps.onClick = output.onClick;

                    return (
                        <SolidButton
                            key={output.label + '-action'}
                            color={output.color ? output.color : 'primary'}
                            type={output.type}
                            {...conditionalProps}
                            className={`${output.className ? output.className + ' ' : ''}${className ? className : ''}`}
                        >
                            {output.label}
                        </SolidButton>
                    );
                } else if (output.type === 'internal') {
                    return (
                        <OutlineButton
                            key={output.label + '-action'}
                            color={output.color ? output.color : 'primary'}
                            type={output.type}
                            to={output.link}
                            className={output.className ? className : ''}
                        >
                            {output.label}
                        </OutlineButton>
                    );
                }

                const conditionalProps = {};

                if (output.to) conditionalProps.to = output.to;
                else if (output.onClick) conditionalProps.onClick = output.onClick;

                return (
                    <OutlineButton
                        key={output.label + '-action'}
                        color={output.color ? output.color : 'primary'}
                        type={output.type}
                        {...conditionalProps}
                        className={`${output.className ? output.className + ' ' : ''}${className ? className : ''}`}
                    >
                        {output.label}
                    </OutlineButton>
                );
            });
        };

        /*   RENDER HEADER
         **********************************************************************************************************/
        const renderHeader = (data) => {
            return data.map((head) => {
                if (head && head.action) {
                    return <div key="tableAction" className="sharedTable__head--action action-head"></div>;
                } else if (head && !head.title && !head.action) {
                    return <div key="tableNull" className="sharedTable__head--null"></div>;
                }

                function getSort() {
                    if (sort && sort === head.sort && order === `asc`) {
                        return <i className="icon icon-chevron-up sortedIcon" />;
                    }

                    if (sort && sort === head.sort && order === `desc`) {
                        return <i className="icon icon-chevron-down sortedIcon" />;
                    }

                    return '';
                }

                if (head && head.sort) {
                    const sharedTableHeadOnClick = () => {
                        toggleSort(head.sort);
                    };

                    const sharedTableHeadClassNames = classNames(
                        'sharedTable__head--text',
                        'sortable',
                        head.className ? `${head.className}-head` : '',
                        { sorted: sort && sort === head.sort }
                    );

                    return (
                        <div
                            role="button"
                            tabIndex={0}
                            key={head.title}
                            onClick={sharedTableHeadOnClick}
                            onKeyDown={(e) => {
                                if (accessibilityClick(e)) sharedTableHeadOnClick(e);
                            }}
                            className={sharedTableHeadClassNames}
                        >
                            {head.title}
                            {head.tooltip ? <Tooltip info={head.tooltip} /> : ''}
                            {getSort()}
                        </div>
                    );
                }

                const sharedTableHeadClassNames = classNames('sharedTable__head--text', head.className ? `${head.className}-head` : '');
                return (
                    <div key={head.title} className={sharedTableHeadClassNames}>
                        {head.title}
                        {head.tooltip ? <Tooltip info={head.tooltip} /> : ''}
                    </div>
                );
            });
        };

        /*   RENDER FOOTER
         **********************************************************************************************************/
        const renderFooter = (data) => {
            const { label, onClick, icon, list } = data;
            if (list) {
                return (
                    <div
                        ref={(el) => {
                            this.tableActionDropdown = el;
                        }}
                        className="sharedTable__footer--wrapper"
                    >
                        <OutlineButton
                            key={label}
                            className="sharedTable__footer--action"
                            color="primary"
                            type="onClick"
                            onClick={(e) => {
                                onClick(e);
                            }}
                        >
                            {label}
                            {typeof icon === 'string' ? <i className={`icon icon-${icon ? icon : `plus-faq`}`} /> : icon ?? ''}
                        </OutlineButton>
                        <button
                            className={`footer__icon icon icon-dots-menu${showDropdownMenu ? ` active` : ''}`}
                            onClick={toggleDropdownMenu}
                        ></button>
                        {showDropdownMenu ? (
                            <div className="footer__icon--list">
                                <ul className="footer__list">{renderFooterList(list)}</ul>
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                );
            }

            return (
                <RightBorderButton
                    key={label}
                    className="sharedTable__footer--action"
                    color="primary"
                    type="onClick"
                    onClick={(e) => {
                        onClick(e);
                    }}
                >
                    <Text uppercase lead--0>
                        {label}
                    </Text>

                    {typeof icon === 'string' ? <i className={`icon icon-${icon ? icon : `plus-faq`}`} /> : icon ?? ''}
                </RightBorderButton>
            );
        };

        const renderFooterList = (list) => {
            return list.map((item, index) => {
                const { label, link, type } = item;
                switch (type) {
                    case 'internal':
                        return (
                            <li key={index}>
                                <Link to={link}>{label}</Link>
                            </li>
                        );

                    case 'external':
                        return (
                            <li key={index}>
                                <a href={link}>{label}</a>
                            </li>
                        );

                    case 'onClick':
                    default:
                        return (
                            <li key={index}>
                                <button
                                    key={`${index}-a`}
                                    onClick={(e) => {
                                        item.onClick(e);
                                        toggleDropdownMenu();
                                    }}
                                >
                                    {label}
                                </button>
                            </li>
                        );
                }
            });
        };

        /*   RENDER SHOW ALL
         **********************************************************************************************************/
        const renderShowAll = () => {
            return showAll?.conditions ? (
                <div className={`sharedTable__showAll${embedded ? ' embedded' : ''}`}>
                    <button onClick={(e) => showAll.onClick(e)} className="sharedTable__showmorelink">
                        {showAll.status === 'loading' ? (
                            <RequestLoader />
                        ) : (
                            <span className="link">{showAll.label ? showAll.label : 'Show All'}</span>
                        )}
                    </button>
                </div>
            ) : (
                ''
            );
        };

        /*   RENDER
         **********************************************************************************************************/
        return (
            <Fragment>
                <div
                    className={`sharedTable ${className ? className : ''}${stacked ? ' stacked' : ''}${header ? '' : ' no-header'}${
                        embedded ? ' embedded' : ''
                    }`}
                >
                    {renderHeading()}
                    <div
                        className={classNames('sharedTable__table', {
                            'sharedTable__table--bottom-radius': !showAll?.conditions
                        })}
                    >
                        {header ? <div className="sharedTable__head">{renderHeader(header)}</div> : ''}
                        <Transition type="table" when={loading}>
                            {loading === 'loading' ? <RequestLoader /> : renderRow(matrix)}
                        </Transition>
                        {footer ? <div className="sharedTable__footer">{renderFooter(footer)}</div> : ''}
                    </div>
                </div>
                {renderShowAll()}
            </Fragment>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
SharedTable = connect((state) => ({
    app_viewport: state.app.app_viewport
}))(SharedTable);

export default SharedTable;
