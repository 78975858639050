/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import RequestLoader from 'components/Loaders/Request';

import './_IsDataUpdatingOverlayStableDomStructure.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @param {IsDataUpdatingOverlayProps & {floatFill?:boolean}} props
 */
function _IsDataUpdatingOverlayStableDomStructure({
    fillHeight,
    height,
    children,
    isDataUpdating,
    message = 'Updating Data...',
    noBorder = false,
    className,
    floatFill,
    Variant = RequestLoader
}) {
    return (
        <div
            className={classNames(
                'IsDataUpdatingOverlayStableDomStructure',
                {
                    'IsDataUpdatingOverlayStableDomStructure--isDataUpdating': isDataUpdating,
                    'IsDataUpdatingOverlayStableDomStructure--noBorder': noBorder,
                    'IsDataUpdatingOverlayStableDomStructure--floatFill': floatFill
                },
                className
            )}
        >
            <div className="IsDataUpdatingOverlayStableDomStructure__children">{children}</div>
            {isDataUpdating && <Variant message={message} fillHeight={fillHeight} height={height} />}
        </div>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { _IsDataUpdatingOverlayStableDomStructure };
