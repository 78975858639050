import classNames from 'classnames';
import type { BaseButtonNamespace } from 'components/Buttons/_BaseButton/types';

export function _createBaseButtonClasses({ isLoading, disabled, variant, className, size, color, width, shape }: BaseButtonNamespace.ClassProps) {
    return classNames(variant, className, size, color, {
        [`${variant}--disabled`]: isLoading || disabled,
        [`${variant}--isLoading`]: isLoading,
        [`${variant}--width-${width}`]: width,
        [`${variant}--shape-${shape}`]: shape
    });
}
