/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { createRoute } from '@tanstack/react-router';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import VipRewardsHome from 'containers/vipRewards/sections/home';
import { VipRewardsSignupSuccessModal } from './sections/moreInfo/signupSuccess';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import FetchPageError from 'components/Errors/FetchPageError';
import RequestLoader from 'components/Loaders/Request';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { brandStore } from 'config/hooks/useBrandStore';
import { getRewardTiersList } from './action';
import { useVipRewards } from './hooks';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { vipRewardsReadableName } from 'containers/vipRewards/consts';

import { AppRoute } from 'App';
import { FreeOnlineLightbox } from 'containers/vipRewards/components/onlineLightbox';
import { FreeSiteLightbox } from 'containers/vipRewards/components/siteLightbox';
import { FreeStoreLightbox } from 'containers/vipRewards/components/storeLightbox';
import { FreeXyzLightbox } from 'containers/vipRewards/components/xyzLightbox';
import VipRewardsMoreInfo from 'containers/vipRewards/sections/moreInfo';
import { routerMiddleware } from 'router/utils/middleware';
import { z } from 'zod';
import './_vipRewards.scss';

const searchSchema = z.object({
    'free-domain': z
        .union([
            z.literal('store'), //
            z.literal('online'),
            z.literal('xyz'),
            z.literal('site')
        ])
        .optional()
});

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const VIPRewardsRootRoute = createRoute({
    getParentRoute: () => AppRoute,
    path: 'vip-rewards',
    pendingMinMs: 0,
    pendingMs: 0,
    pendingComponent: RequestLoader,
    errorComponent: FetchPageError,
    async loader({ context, cause }) {
        if (cause === 'enter') {
            document.title = `${vipRewardsReadableName} | ${brandStore.state.application}`;
        }

        const { rewards_tiers_list_data } = context.store.getState().rewards ?? {};

        if (!rewards_tiers_list_data) {
            await getRewardTiersList();
        }
    },
    beforeLoad(opts) {
        routerMiddleware.business(this, opts);
        routerMiddleware.authentication('user', opts);
    },
    validateSearch: searchSchema
});

/**********************************************************************************************************
 *   ROUTE COMPONENT START
 **********************************************************************************************************/
const VIPRewardsRouteComponent = () => {
    /***** SELECTORS *****/
    const { isMember } = useVipRewards();

    /***** RENDER *****/
    return (
        <main className="vipRewards">
            <VipRewardsSignupSuccessModal>
                {isMember ? <VipRewardsHome /> : <VipRewardsMoreInfo />} {/**/}
            </VipRewardsSignupSuccessModal>

            <FreeStoreLightbox />
            <FreeOnlineLightbox />
            <FreeXyzLightbox />
            <FreeSiteLightbox />
        </main>
    );
};

/**********************************************************************************************************
 *   ROUTE UPDATES
 **********************************************************************************************************/
VIPRewardsRootRoute.update({
    component: VIPRewardsRouteComponent
});
