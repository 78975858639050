import { Title } from 'components/NXTable/Header/Title';
import { SortableButton } from 'components/SortableButton';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {NXTable.Header.TSortableTitle}
 */
export const SortableTitle = ({ sortKey, children, disabled, initialMethod }) =>
    disabled ? (
        <Title>{children}</Title>
    ) : (
        <SortableButton sortKey={sortKey} initialMethod={initialMethod}>
            <Title>{children}</Title>
        </SortableButton>
    );
