import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import 'core-js/features/promise/all-settled';
import 'core-js/features/string/replace-all';
import 'core-js/stable';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import { interceptInvalidToken, updateViewport } from 'App/action';
import { setAxiosDefaults as initializeAxiosDefaults } from 'App/setAxiosDefaults';
import CustomErrorBoundary from 'components/Errors/ErrorBoundary';
import { NXQueryProvider } from 'components/NXQueryProvider';
// @ts-ignore
import {
    initializeLazyLoadHandler,
    initializePolyfillHandler,
    initializeReactGA,
    initializeRouteChangeHandler,
    initializeSentry,
} from 'router/interceptors';
import { RouterContextProvider } from 'router/provider';
import { router } from 'router/router';
import { initialiseQueryClient, queryClient } from 'store/queryClient';
import { handleDefaultErrorNotification } from 'utilities/methods/commonActions/handleDefaultErrorNotification';
import { handleDefaultSuccessNotification } from 'utilities/methods/commonActions/handleDefaultSuccessNotification';
import { interceptorHandler as initializeInterceptorHandlers } from 'utilities/methods/interceptorHandler';
import { NXQuery } from 'utilities/query';
import store from './store/store';

/**********************************************************************************************************
 *   APPLICATION START
 **********************************************************************************************************/
initialiseQueryClient({
    onError: (error) => handleDefaultErrorNotification(error),
    onSuccess: handleDefaultSuccessNotification,
});
initializeSentry();
initializeAxiosDefaults();
initializeRouteChangeHandler();
initializeInterceptorHandlers();
initializeLazyLoadHandler();
initializePolyfillHandler();
interceptInvalidToken(router);
initializeReactGA();

// initialize viewport listeners - This will be replaced with the ViewportManager when it is merged into master
updateViewport();
window.addEventListener('resize', updateViewport);

// Note, the app is not being rendered in "strict mode"
const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
    <Provider store={store} stabilityCheck="never">
        <CustomErrorBoundary>
            <QueryClientProvider client={queryClient}>
                <NXQueryProvider queryTree={NXQuery}>
                    <RouterContextProvider />
                    <ReactQueryDevtools initialIsOpen={false} />
                </NXQueryProvider>
            </QueryClientProvider>
        </CustomErrorBoundary>
    </Provider>
);
