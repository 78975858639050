/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { KatanaColours, KatanaSectionIDHardCodedMetaData } from 'containers/katana/consts';
import type { KatanaNamespace } from 'containers/katana/types';

export function getKatanaSectionIDColour(sectionDefinitionID: KatanaNamespace.SectionDefinitionId) {
    return KatanaSectionIDHardCodedMetaData[sectionDefinitionID]?.color ?? KatanaColours.GRAY;
}

/**
 * Utility function for creating a katanaServiceId. This does not perform any validation on the value, but does ensure
 * that the value is cast to a number and appropriately tagged as a KatanaNamespace.ServiceId.
 *
 * This should be treated similar to writing `as KatanaNamespace.ServiceId` on a number, but provides a single entry point
 * for creating tagged serviceIds that do not directly come from a query/mutation response.
 */
export const createKatanaServiceId = (serviceID: string | number): KatanaNamespace.ServiceId => {
    return +serviceID as KatanaNamespace.ServiceId;
};

/**
 * Utility function for creating a katana page id. This does not perform any validation on the value, but does ensure
 * that the value is cast to a number and appropriately tagged as a KatanaNamespace.PageId.
 *
 * This should be treated similar to writing `as KatanaNamespace.PageId` on a number, but provides a single entry point
 * for creating tagged pageIds that do not directly come from a query/mutation response.
 */
export const createKatanaPageID = (pageID: string | number): KatanaNamespace.PageId => {
    return +pageID as KatanaNamespace.PageId;
};

export const createKatanaSectionID = (serviceID: string | number): KatanaNamespace.SectionId => {
    return +serviceID as KatanaNamespace.SectionId;
};
