import { _AccountQuery } from 'containers/account/queries/queryTree';
import { _domainQuery } from 'containers/domain/queries';
import { _emailQuery } from 'containers/email/queries';
import { _HostingQuery } from 'containers/hosting/queries';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import { KatanaQueryV2 } from 'containers/katana/queries/v2/tanstackTree';
import { _AuthQuery } from 'containers/login/queries/queryTree';
import { _ms365Query } from 'containers/ms365/queries';
import { _orderingFormQuery } from 'containers/orderingForm/queries';
import { _ServicesQuery } from 'containers/services/queries/tanstackTree';
import { _ventraIpWordpressSupportCentreQuery } from 'utilities/api/ventraip-website-wp-support-centre/queries/tanstackTree';
import { _useLazyQuery as useLazyQuery } from 'utilities/methods/queries/useLazyQuery';
import { _usePopupMutation as usePopupMutation } from 'utilities/methods/queries/usePopupMutation';

/***** EXPORTS *****/
/**
 * Tanstack Query/Mutation object that should be used to build the router tree, and used within the application
 * instead of directly calling queries.
 */
export const NXQuery = Object.freeze({
    account: _AccountQuery,
    domain: _domainQuery,
    auth: _AuthQuery,
    email: _emailQuery,
    hosting: _HostingQuery,
    ms365: _ms365Query,
    katana: Object.assign(katanaQuery, {
        v2: KatanaQueryV2
    }),
    services: _ServicesQuery,
    supportCentre: _ventraIpWordpressSupportCentreQuery,
    orderingForm: _orderingFormQuery,
    Utils: {
        usePopupMutation,
        useLazyQuery
    }
});
