import { Store, useStore } from '@tanstack/react-store';
import { isChildPathInRegisteredPaths } from 'containers/katana/components/dynamicFormFieldRenderer/hooks/isChildPathInRegisteredPaths';
import { useKatanaProperty } from 'containers/katana/components/dynamicFormFieldRenderer/PropertyContext/useKatanaProperty';
import { useSectionDefinitionContext } from 'containers/katana/containers/ContentEditorLightbox/formHandlers/sectionDefinitionFormHandler/consts';
import _ from 'lodash';
import { useEffect, useMemo } from 'react';
import { useFormState } from 'react-hook-form';

const registeredPaths = new Store<string[]>([]);

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export function useUnregisteredChildPaths() {
    /***** HOOKS *****/
    const { path } = useKatanaProperty();
    const { sectionDefinition } = useSectionDefinitionContext();
    const registeredPathValues = useStore(registeredPaths);
    const { errors } = useFormState();

    /***** EFFECTS *****/
    useEffect(() => {
        registeredPaths.setState((paths) => [...paths, path]);
        return () => {
            registeredPaths.setState((paths) => paths.filter((p) => p !== path));
        };
    }, [path]);

    /***** RENDER HELPERS *****/
    return useMemo(() => {
        const matchedArrayEntries = path.match(/\[(\d+)\]/g);
        const cleanedPath = path.replace(/\[(\d+)\]/g, '.*');
        const filteredRegisteredPathValues = registeredPathValues.filter((registeredPath) => registeredPath !== path);
        return Object.keys(sectionDefinition.validation_rules)
            .filter((key) => key.includes(cleanedPath))
            .map((key) => {
                if (!matchedArrayEntries) return key;

                const cleanedKey = matchedArrayEntries.reduce((acc, match) => {
                    return acc.replace('.*', match);
                }, key);

                return cleanedKey;
            })
            .filter((fieldPath) => {
                if (fieldPath.includes('.*')) return false;
                if (fieldPath === path) return false;
                if (!fieldPath.includes(path)) return false;

                /**
                 * If the field path is included in the registered paths, we don't want to capture the field state for that.
                 */
                const registeredPath = registeredPathValues.includes(fieldPath);

                if (registeredPath) return false;

                /**
                 * We need to check if an unregistered child path has a registered child path which is deeper than the current path.
                 *
                 * Example:
                 * Current Path:
                 * ```js
                 * "work_project[0]"
                 * ```
                 *
                 * Registered Paths:
                 * ```js
                 * ["work_project[0]", "work_project[0].description"]
                 * ```
                 *
                 * All possible paths:
                 * ```js
                 * [
                 * "work_project[0]",
                 * "work_project[0].description",
                 * "work_project[0].description.type",
                 * "work_project[0].description.content"
                 * ]
                 * ```
                 *
                 * In this case, we need to filter out any path that is deeper than `"work_project[0].description"` because it has a child path `"work_project[0].description.type"` which is also a childpath of the current path, but we don't want to capture the field state for that since that's already handled deeper in the tree.
                 */
                const hasRegisteredChildPath = isChildPathInRegisteredPaths(path, fieldPath, filteredRegisteredPathValues);

                if (hasRegisteredChildPath) return false;

                /**
                 * If the field path has an error message, we want to capture the field state for that.
                 */
                if (_.get(errors, fieldPath)) return true;

                return false;
            });
    }, [registeredPathValues, path, errors]);
}
