import _ from 'lodash';
import { UserPreferences } from 'utilities/UserPreferences';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export function useLockedSuperUserItems() {
    /***** HOOKS *****/
    const [lockedItems = {}] = UserPreferences.useLocalStorage('SUPER_USER_LOCKED_ITEMS', {});

    /***** HOOK RESULTS *****/
    return _.keys(lockedItems) as Array<keyof typeof lockedItems>;
}
