import useResizeObserver from '@react-hook/resize-observer';
import { NXIframe } from 'components/NXIframe';
import { calculateFinalDisplayScale } from 'components/NXIframe/ScalingIframe/methods';
import type { ScalingIframeNamespace } from 'components/NXIframe/ScalingIframe/types';
import mergeRefs from 'merge-refs';
import { forwardRef, useRef, useState } from 'react';
import './_ScalingIframe.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const ScalingIframe = forwardRef<HTMLDivElement, ScalingIframeNamespace.Props>(({ title, srcDoc, height, width, ...iframeProps }, ref) => {
    /***** STATE *****/
    const [_height, setHeight] = useState<number>(0);

    /***** HOOKS *****/
    const iframeWrapperRef = useRef<HTMLDivElement>(null);

    /***** FUNCTIONS *****/
    function handleLoadScalingWrapperResize(element: Element) {
        const iframeWrapperRect = element.getBoundingClientRect();
        const finalDisplayScale = calculateFinalDisplayScale(iframeWrapperRect.width, width);

        const frame = element.querySelector('iframe');

        if (height === '100%') {
            const baseHeight = ~~iframeWrapperRect.height;
            const roundedHeight = Math.round(baseHeight / finalDisplayScale);
            setHeight(roundedHeight);
        }

        if (frame) {
            frame.style.transform = `scale(${finalDisplayScale})`;
        }
    }

    /***** EFFECTS *****/
    useResizeObserver(iframeWrapperRef.current, (entry) => {
        const element = entry.target;
        handleLoadScalingWrapperResize(element);
    });

    const finalHeight = height === '100%' ? _height : height;

    /***** RENDER *****/
    return (
        <div className="ScalingIframe__wrapper" ref={mergeRefs(iframeWrapperRef, ref)}>
            <NXIframe
                className="ScalingIframe"
                height={finalHeight}
                width={width}
                title={title}
                srcDoc={srcDoc}
                {...iframeProps}
                onLoad={() => iframeWrapperRef.current && handleLoadScalingWrapperResize(iframeWrapperRef.current)}
            />
        </div>
    );
});
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
ScalingIframe.displayName = 'ScalingIframe';
