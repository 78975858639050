import classNames from 'classnames';
import type { CheckBoxNamespace } from 'components/Form/CheckBox/types';
import '../_CheckBox.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Props = Omit<CheckBoxNamespace.Props, 'onChange' | 'intrinsic' | 'disabled'> & {
    inject?: boolean;
};
type _CheckBoxVisual = React.FC<Props>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _CheckBoxVisual: _CheckBoxVisual = ({ isChecked, type = 'square', className, children }) => {
    /***** RENDER HELPERS *****/
    const checkBoxClasses = classNames('CheckBox', 'CheckBoxVisual', { 'CheckBox--active': isChecked, [`CheckBox--type-${type}`]: type }, className);

    /***** RENDER *****/
    return (
        <div className={checkBoxClasses}>
            <span className="CheckBox__toggleBox" />
            {children}
        </div>
    );
};
