/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useRef } from 'react';
import { useSelector } from 'react-redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useBrandStore } from 'config/hooks/useBrandStore';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { vipRewardsReadableName } from 'containers/vipRewards/consts';
import './_vipRewardsFaqs.scss';

// TEMP: remove the "show/hide" toggle, but leave the code there just incase we want to add it back
/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function VipRewardsFaqs() {
    /***** HOOKS *****/
    const { application } = useBrandStore();
    const app_viewport = useSelector((state) => state.app.app_viewport);

    const faqsRef = useRef(null);

    /***** RENDER HELPERS *****/
    const faqs = [
        {
            title: `What is ${vipRewardsReadableName}?`,
            description: `Our ${vipRewardsReadableName} program is a way for us to show our appreciation for our loyal customers. By signing up for the program, you can earn discounts on your purchases, access to free services and much more.`
        },
        {
            title: 'How do I sign up for the rewards program?',
            description: `Anyone who is not already signed up for ${vipRewardsReadableName} can sign up within ${application} by clicking on the new VIPrewards button.`
        },
        {
            title: 'What other benefits are there to being a member of the rewards program?',
            description:
                'In addition to earning discounts, members of the rewards program also enjoy other benefits, such as early access to new products and services, exclusive discounts, and priority customer support.'
        },
        {
            title: 'Does it cost to join?',
            description: "No - it's completely free."
        },
        {
            title: 'How do I earn rewards?',
            description:
                "It's so simple - you just need to remain a customer and we'll reward you based on your account age. Also, don't forget to sign up for our marketing emails so you receive all the latest news and promotions straight to your inbox."
        },
        {
            title: "What if I don't receive my discount?",
            description:
                'If there is a discount you believe you should have received, feel free to contact one of our friendly support team members via phone, live chat or email.'
        }
    ];

    /***** RENDER *****/
    return (
        <div className="vipRewardsFaqs">
            <Text size--xxxl={!['sm', 'xs'].includes(app_viewport)} size--xl={['sm', 'xs'].includes(app_viewport)} black medium align--center>
                <h2>Frequently Asked Questions</h2>
            </Text>
            <div ref={faqsRef}>
                {faqs.map(({ title, description }) => (
                    <div key={title}>
                        <Text size--l primary semiBold lead--s>
                            <h4>{title}</h4>
                        </Text>
                        <Text size--s secondary>
                            {description}
                        </Text>
                    </div>
                ))}
            </div>
        </div>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default VipRewardsFaqs;
