import { createContext } from 'react';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type RegistrationOrderingFormContext = {
    isDomainRegistrationFormOpen: boolean;
    setIsDomainRegistrationFormOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isRegistration: boolean;
    setIsRegistration: React.Dispatch<React.SetStateAction<boolean>>;
};

export const RegistrationOrderingFormContext = createContext<RegistrationOrderingFormContext | undefined>(undefined);
