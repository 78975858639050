import { useQueryClient } from '@tanstack/react-query';
import { SolidButton } from 'components/Buttons/SolidButton';
import RequestLoader from 'components/Loaders/Request';
import { Flex } from 'components/Utils/Flex';
import Text from 'components/Utils/Text';
import { useOrderingFormContext } from 'containers/orderingForm/contexts/useOrderingFormContext';
import 'containers/orderingForm/pages/registerPage/recommendedDomains/_recommendedDomains.scss';
import { Suggestion } from 'containers/orderingForm/pages/transferPage/transferSuggestions/suggestion';
import type { DomainSuggestions } from 'containers/orderingForm/types';
import { useEffect, useRef, useState } from 'react';
import { NXQuery } from 'utilities/query';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const TransferSuggestions = () => {
    /***** HOOKS *****/
    const { domainData: registerDomainData } = useOrderingFormContext();
    const queryClient = useQueryClient();

    /***** STATE *****/
    const [suggestions, setSuggestions] = useState([]);
    const [isLoadingNewData, setIsLoadingNewData] = useState(false);
    const previousDomainDataRef = useRef(registerDomainData);

    /***** QUERIES *****/
    const {
        data: register_domain_suggestion_data,
        isLoading: isDomainSuggestionsLoading,
        isFetching: isDomainSuggestionsFetching,
        fetchNextPage,
        hasNextPage
    } = NXQuery.domain.suggestion.useInfiniteQuery(registerDomainData);

    /***** EFFECTS *****/
    // Logic to filter the suggested register domains into an array of transferable suggestions
    useEffect(() => {
        const fetchTransferableSuggestions = async () => {
            if (isDomainSuggestionsLoading) return;

            if (registerDomainData !== previousDomainDataRef.current) {
                setIsLoadingNewData(true);
                setSuggestions([]);
            }

            // If domains suggestions exist, flatten all page into one array and filter out any duplicates
            if (register_domain_suggestion_data?.pages) {
                const allSuggestions = register_domain_suggestion_data.pages
                    .flatMap((page) => page.data)
                    .filter((suggestion, index, self) => {
                        return self.findIndex((s) => s.tld === suggestion.tld) === index;
                    });

                // Filter out all the suggestions that are not registered
                const registeredSuggestions = allSuggestions.filter((suggestion) => !suggestion.available);

                // Check if the remaining suggestions are transferable
                const transferableSuggestions = await Promise.all(
                    registeredSuggestions.map(async (suggestion) => {
                        const suggestionQueryData = {
                            action: 'transfer',
                            domain: suggestion.domain,
                            promos: [],
                            tlds: [suggestion.tld],
                        };
                        const { data: suggestion_transfer_availability_data } = await queryClient.fetchQuery(
                            NXQuery.domain.availability.createQueryOptions(suggestionQueryData)
                        );
                        return suggestion_transfer_availability_data?.[0]?.available ? suggestion_transfer_availability_data?.[0] : null;
                    })
                );

                // Set the suggestions to the domains that are transferable
                setSuggestions(transferableSuggestions.filter(Boolean));
                setIsLoadingNewData(false);
                previousDomainDataRef.current = registerDomainData;
            }
        };

        fetchTransferableSuggestions();
    }, [registerDomainData, register_domain_suggestion_data, queryClient, isDomainSuggestionsLoading]);

    /***** FUNCTIONS *****/
    const handleShowMoreOnClick = () => {
        if (hasNextPage) fetchNextPage();
    };

    /***** RENDER *****/
    if (isDomainSuggestionsLoading || isDomainSuggestionsFetching || isLoadingNewData) {
        return (
            <Flex justify="center" align="center" className="recommendedDomains__informationContainer">
                <RequestLoader />
            </Flex>
        );
    }

    if (suggestions.length > 0) {
        return (
            <Flex direction="column" gap={1} className="recommendedDomains__container">
                <ul className="recommendedDomains__suggestions">
                    {suggestions.map((suggestion: DomainSuggestions, index: number) => {
                        return <Suggestion key={index} suggestion={suggestion} />;
                    })}
                </ul>
                {hasNextPage && (
                    <SolidButton type="onClick" onClick={handleShowMoreOnClick} className="recommendedDomains__button--showMore">
                        <Text semiBold>Show more</Text>
                    </SolidButton>
                )}
            </Flex>
        );
    }

    return (
        <Flex justify="center" className="recommendedDomains__informationContainer">
            <Text size--s align--center>
                There are no suggestions available to transfer
            </Text>
        </Flex>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
