/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useParams } from '@tanstack/react-router';
import { checkIsVictoriaEducationHosting, getHostingProduct, getHostingProductName } from 'containers/hosting/methods';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useChildMatchById } from 'utilities/hooks/router/useChildMatchById';
import { createModuleStore } from 'utilities/methods/createModuleStore';

/**********************************************************************************************************
 *   MODULE STORE START
 **********************************************************************************************************/
export const HostingModuleStore = createModuleStore(
    {
        account: {
            overview: {
                enabled: true,
                label: 'Overview',
                to: '/my-services/hosting/$serviceId/account',
                hash: 'overview'
            },
            payment: {
                enabled: true,
                label: 'Renew',
                to: '/my-services/hosting/$serviceId/account',
                hash: 'renew'
            },
            upgradeDowngrade: {
                enabled: true,
                label: 'Upgrade/Downgrade',
                to: '/my-services/hosting/$serviceId/account',
                hash: 'custom-hosting'
            },
            addons: {
                enabled: true,
                label: 'Addons',
                to: '/my-services/hosting/$serviceId/account',
                hash: 'custom-addons'
            },
            details: {
                enabled: true,
                label: 'Details',
                to: '/my-services/hosting/$serviceId/account',
                hash: 'details'
            },
            UpgradeVicHostingPlan: {
                enabled: false,
                label: 'Upgrade Plan',
                to: '/my-services/hosting/$serviceId/account',
                hash: 'upgrade'
            },
            primaryDomain: {
                enabled: true,
                label: 'Primary Domain',
                to: '/my-services/hosting/$serviceId/account',
                hash: 'primary-domain'
            },
            purchase: {
                enabled: true,
                label: 'Purchase',
                to: '/my-services/hosting/$serviceId/account',
                hash: 'purchase'
            },
            netoriginOverview: {
                enabled: false,
                label: 'Overview',
                to: '/my-services/hosting/$serviceId/account',
                hash: 'overview'
            },
            pleskOverview: {
                enabled: false,
                label: 'Overview',
                to: '/my-services/hosting/$serviceId/account',
                hash: 'overview'
            },
            pleskDetails: {
                enabled: true,
                label: 'Details',
                to: '/my-services/hosting/$serviceId/account',
                hash: 'details'
            },
            resellerSubaccountDetails: {
                enabled: true,
                label: 'Details',
                to: '/my-services/hosting/$serviceId/subaccount/$subaccountId/account',
                hash: 'details'
            },
            resellerSubaccountPrimaryDomain: {
                enabled: true,
                label: 'Primary Domain',
                to: '/my-services/hosting/$serviceId/subaccount/$subaccountId/account',
                hash: 'primary-domain'
            }
        },
        configuration: {
            temporaryURL: {
                enabled: true,
                label: 'Temporary URL',
                to: '/my-services/hosting/$serviceId/config',
                hash: 'temp-url'
            },
            mysql: {
                enabled: true,
                label: 'External MySQL Access',
                to: '/my-services/hosting/$serviceId/config',
                hash: 'sql'
            },
            ssh: {
                enabled: true,
                label: 'SSH Access',
                to: '/my-services/hosting/$serviceId/config',
                hash: 'ssh'
            },
            ipv6: {
                enabled: true,
                label: 'IPv6 Management',
                to: '/my-services/hosting/$serviceId/config',
                hash: 'ipv6'
            }
        },
        security: {
            autossl: {
                enabled: true,
                label: 'Auto SSL',
                to: '/my-services/hosting/$serviceId/security',
                hash: 'autossl'
            },
            permissions: {
                enabled: true,
                label: 'Permissions Fixer',
                to: '/my-services/hosting/$serviceId/security',
                hash: 'permissions'
            },
            firewall: {
                enabled: true,
                label: 'Check Firewall',
                to: '/my-services/hosting/$serviceId/security',
                hash: 'firewall'
            },
            modSecurity: {
                enabled: true,
                label: 'Mod Security',
                to: '/my-services/hosting/$serviceId/security',
                hash: 'modsec'
            },
            monarx: {
                enabled: true,
                label: 'Monarx Security Login',
                to: '/my-services/hosting/$serviceId/security',
                hash: 'monarx'
            }
        },
        admin: {
            move: {
                enabled: true,
                label: 'Move Service',
                to: '/my-services/hosting/$serviceId/admin',
                hash: 'move'
            },
            cancel: {
                enabled: true,
                label: 'Cancel Service',
                to: '/my-services/hosting/$serviceId/admin',
                hash: 'cancel'
            }
        },
        reseller: {
            subaccounts: {
                enabled: true,
                label: 'Sub Accounts',
                to: '/my-services/hosting/$serviceId/reseller',
                hash: 'subaccounts'
            }
        }
    },
    HostingStoreConditions
);

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Component for running conditions for the Hosting store. This will update the store based on the necessary
 * conditions while isolating rerenders of the HostingRouteComponent to only store updates rather than
 * all state updates that determine conditions.
 *
 * This component is in charge of when modules should render and what route they should use in the sidebar
 */
function HostingStoreConditions(args: any) {
    const store = createModuleStore.castHookProps<typeof HostingModuleStore>(args);

    /***** HOOKS *****/
    const hosting_information_data = useSelector((state: any) => state.hosting.hosting_information_data);
    const hosting_isReseller = useSelector((state: any) => state.hosting.hosting_isReseller);
    const hosting_addons_list_status = useSelector((state: any) => state.hosting.hosting_addons_list_status);
    const hosting_addons_list_data = useSelector((state: any) => state.hosting.hosting_addons_list_data);

    const { subaccountId } = useParams({ strict: false });
    const isSubaccount = useChildMatchById('/my-services/hosting/$serviceId/subaccount/$subaccountId');

    /***** DERIVED *****/
    const product = getHostingProduct(hosting_information_data);
    const productName = getHostingProductName(product);
    const isCustomHosting = !!hosting_information_data?.attributes?.custom_hosting;
    const isPlesk = productName.startsWith('plesk');
    const isNetorigin = productName.startsWith('Netorigin');
    const isVictoriaEducationHosting = checkIsVictoriaEducationHosting(productName);
    const isClickhostReseller = productName.startsWith('Clickhost Reseller');

    /***** FUNCTIONS *****/
    const moduleCondition = (module: string) => {
        switch (module) {
            case 'Addons': {
                const isLoading = hosting_addons_list_status !== 'success';
                const hasProducts = !!hosting_addons_list_data?.product_addons?.length;
                const hasServices = !!hosting_addons_list_data?.service_addons?.length;

                switch (true) {
                    case hosting_isReseller || isNetorigin || isPlesk:
                        return false;
                    case isCustomHosting && isLoading:
                    case isCustomHosting && (hasProducts || hasServices):
                        return true;
                    default:
                        return false;
                }
            }
            default:
                return true;
        }
    };

    /***** STORE UPDATES *****/
    const showUpgradeDowngrade = !hosting_isReseller && !isNetorigin && !isPlesk && isCustomHosting && !isVictoriaEducationHosting;
    const showPrimaryDomain = !hosting_isReseller && !isNetorigin && !isPlesk && !isVictoriaEducationHosting;
    const showPurchase = !hosting_isReseller && !isNetorigin && !isPlesk && isCustomHosting && !isVictoriaEducationHosting;
    const subaccountPath = subaccountId ? `/subaccount/$subaccountId` : '';

    useEffect(() => {
        store.batch(() => {
            /***** MODULE CONDITIONS *****/
            store.updateChangedStoreKey('account.overview.enabled', !isNetorigin && !isPlesk && !isSubaccount);
            store.updateChangedStoreKey('account.payment.enabled', !isPlesk && !hosting_isReseller && !isVictoriaEducationHosting);
            store.updateChangedStoreKey('account.upgradeDowngrade.enabled', showUpgradeDowngrade);
            store.updateChangedStoreKey('account.addons.enabled', moduleCondition('Addons') && !isVictoriaEducationHosting);
            store.updateChangedStoreKey('account.details.enabled', !hosting_isReseller && !isNetorigin && !isPlesk);
            store.updateChangedStoreKey('account.UpgradeVicHostingPlan.enabled', isVictoriaEducationHosting);
            store.updateChangedStoreKey('account.primaryDomain.enabled', showPrimaryDomain);
            store.updateChangedStoreKey('account.purchase.enabled', showPurchase);
            store.updateChangedStoreKey('account.netoriginOverview.enabled', !hosting_isReseller && !isPlesk && isNetorigin);
            store.updateChangedStoreKey('account.resellerSubaccountDetails.enabled', hosting_isReseller && isSubaccount);
            store.updateChangedStoreKey('account.resellerSubaccountPrimaryDomain.enabled', hosting_isReseller && isSubaccount);
            store.updateChangedStoreKey('configuration.temporaryURL.enabled', !isPlesk);
            store.updateChangedStoreKey('configuration.mysql.enabled', !isPlesk);
            store.updateChangedStoreKey('configuration.ipv6.enabled', !isPlesk && !isClickhostReseller);
            store.updateChangedStoreKey('security.autossl.enabled', !isPlesk);
            store.updateChangedStoreKey('security.permissions.enabled', !isPlesk);
            store.updateChangedStoreKey('security.modSecurity.enabled', !isPlesk && !isClickhostReseller);
            store.updateChangedStoreKey('security.monarx.enabled', !isPlesk);
            store.updateChangedStoreKey('security.firewall.enabled', !isClickhostReseller);
            store.updateChangedStoreKey('admin.cancel.enabled', !isVictoriaEducationHosting);
            store.updateChangedStoreKey('admin.move.enabled', !isVictoriaEducationHosting);
            store.updateEnabledWithReason(
                'account.pleskOverview',
                isPlesk,
                'The Plesk Overview is only enabled when the product name starts with plesk'
            );
            store.updateEnabledWithReason(
                'account.pleskDetails',
                isPlesk,
                'The Plesk Details are only enabled when the product name starts with plesk'
            );

            /***** MODULE ROUTES *****/
            store.updateChangedStoreKey('configuration.mysql.to', `/my-services/hosting/$serviceId${subaccountPath}/config`);
            store.updateChangedStoreKey('configuration.ssh.to', `/my-services/hosting/$serviceId${subaccountPath}/config`);
            store.updateChangedStoreKey('configuration.ipv6.to', `/my-services/hosting/$serviceId${subaccountPath}/config`);
            store.updateChangedStoreKey('security.autossl.to', `/my-services/hosting/$serviceId${subaccountPath}/security`);
            store.updateChangedStoreKey('security.firewall.to', `/my-services/hosting/$serviceId${subaccountPath}/security`);
            store.updateChangedStoreKey('configuration.temporaryURL.to', `/my-services/hosting/$serviceId${subaccountPath}/config`);
            store.updateChangedStoreKey('security.permissions.to', `/my-services/hosting/$serviceId${subaccountPath}/security`);
            store.updateChangedStoreKey('security.monarx.to', `/my-services/hosting/$serviceId${subaccountPath}/security`);
        });
    });
};
