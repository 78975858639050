import { useMutation } from '@tanstack/react-query';
import { API } from 'utilities/api/account';
import { userSubaccountList } from './getAdditionalUsers';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useMutation() {
    /***** HOOK RESULTS *****/
    return useMutation({
        mutationFn: API.account.DELETE.subaccount.delete,
        onSuccess: () => {
            userSubaccountList.invalidateQueries();
        }
    });
}

export const deleteUserAccount = Object.freeze({
    useMutation: _useMutation
});
