/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useEffect, useState } from 'react';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * @param {'loading' | 'error' | 'success' | null} status - the status of the search
 */
export const useSearchState = (status) => {
    /***** STATE *****/
    /**
     * @typedef {{
     *   isSearching: boolean,
     *   keyword: null | string,
     *   status: 'loading' | 'error' | 'success' | null
     * }} TSearch
     */
    const [search, _setSearch] = useState(
        
        /** @type {TSearch} */ ({
            isSearching: false,
            keyword: null,
            status: null
        })
    );

    /***** FUNCTIONS *****/
    /**
     * @param {string | null} [keyword] - keyword to search or falsy to reset
     */
    const setSearch = (keyword = null) => {
        _setSearch((prev) => ({
            ...prev,
            isSearching: !!keyword,
            keyword: keyword
        }));
    };

    /***** EFFECTS *****/
    useEffect(() => {
        _setSearch((prev) => ({
            ...prev,
            status
        }));
    }, [status]);

    /***** RENDER *****/
    return /** @type {const} */ ([search, setSearch]);
};
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
