import { useNavigate } from '@tanstack/react-router';
import RequestLoader from 'components/Loaders/Request';
import { pushNotification } from 'components/Toast/functions';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { genericTerminatedCancelled } from 'utilities/methods/redirects/messageContent';
import { handleRedirect } from 'utilities/methods/redirects/methods';
import { redirectUrls } from 'utilities/methods/redirects/redirectUrls';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type HostingManageRouteLoaderComponent = React.FC<{
    children: React.ReactNode;
}>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Temporary loading component until we have a full page loader available.
 */
export const HostingRouteLoader: HostingManageRouteLoaderComponent = ({ children }) => {
    /***** HOOKS *****/
    const navigate = useNavigate();

    const hosting_information_status = useSelector((state: any) => state.hosting.hosting_information_status);
    const hosting_information_data = useSelector((state: any) => state.hosting.hosting_information_data);
    const hosting_information_error = useSelector((state: any) => state.hosting.hosting_information_error);

    const isLoading = hosting_information_status === 'loading';
    const hasData = !!hosting_information_data;

    /***** EFFECTS *****/
    /** At some stage we'll probably want to render a <Navigate /> component here, as opposed to doing this logic in a useEffect, so that we don't have to wait for one render of the page before redirecting. */
    useEffect(() => {
        if (hosting_information_error?.status === 403) {
            navigate({ to: redirectUrls.hosting });
            pushNotification(genericTerminatedCancelled);
        } else if (hosting_information_data) {
            handleRedirect({
                serviceType: 'hosting',
                status: hosting_information_data?.attributes?.status,
                domainName: hosting_information_data?.attributes?.domain,
                history: {
                    push: (to) => navigate({ to })
                }
            });
        }
    }, [hosting_information_error, hosting_information_data]);

    if (isLoading || !hasData) {
        return <RequestLoader />;
    }

    /***** RENDER *****/
    return children;
};
