import { cancel } from 'containers/services/queries/cancel';
import { approveMove } from 'containers/services/queries/mutations/approveMove';
import { revertCancellation } from 'containers/services/queries/revertCancellation';
import { unsuspendService } from 'containers/services/queries/unsuspendService';
import { userExists } from 'containers/services/queries/userExists';
import { suspensionReason } from 'containers/services/queries/useSuspensionReason';

export const _ServicesQuery = Object.freeze({
    cancel,
    revertCancellation,
    unsuspendService,
    suspensionReason,
    userExists,
    approveMove
});
