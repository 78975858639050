import { useQueryErrorResetBoundary } from '@tanstack/react-query';
import { createRoute, redirect, type ErrorComponentProps } from '@tanstack/react-router';
import AppLoader from 'components/Loaders/App';
import { LoginRouteComponent } from 'containers/login';
import { LoginErrorBoundaryFallback } from 'containers/login/components/errorBoundary';
import { useLoginContext } from 'containers/login/consts';
import { LoginLayoutRoute } from 'containers/login/routes/_login';
import type React from 'react';
import { routerMiddleware } from 'router/utils/middleware';
import { z } from 'zod';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type ErrorComponent = React.FC<ErrorComponentProps>;

/**********************************************************************************************************
 *   ROUTE SCHEMA START
 **********************************************************************************************************/
const searchSchema = z.object({
    forgot: z.boolean().optional()
});

/**********************************************************************************************************
 *   ROUTE ERROR COMPONENT START
 **********************************************************************************************************/
const ErrorComponent: ErrorComponent = ({ reset: resetRoute }) => {
    const { reset: resetQuery } = useQueryErrorResetBoundary();
    const { setView } = useLoginContext();

    /***** RENDER *****/
    return (
        <LoginErrorBoundaryFallback
            resetError={() => {
                resetQuery();
                resetRoute();
                setView('login');
            }}
        />
    );
};

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const LoginRoute = createRoute({
    getParentRoute: () => LoginLayoutRoute,
    path: '/login',
    beforeLoad(opts) {
        // @ts-ignore
        routerMiddleware.business(this, opts);
        routerMiddleware.authentication('guest', opts, () => {
            throw redirect({ to: opts.search.ref ?? '/dashboard' });
        });
    },
    validateSearch: searchSchema,
    component: LoginRouteComponent,
    pendingComponent: AppLoader,
    errorComponent: ErrorComponent
});

export const ResetPasswordRoute = createRoute({
    getParentRoute: () => LoginLayoutRoute,
    path: '/reset-password/$token',
    beforeLoad(opts) {
        // @ts-ignore
        routerMiddleware.business(this, opts);
        routerMiddleware.authentication('guest', opts);
    },
    validateSearch: searchSchema,
    component: LoginRouteComponent,
    pendingComponent: AppLoader,
    errorComponent: ErrorComponent
});
