/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { createRoute, redirect } from '@tanstack/react-router';
import { pushNotification } from 'components/Toast/functions';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';

/**********************************************************************************************************
 *   ROUTE IMPORTS
 **********************************************************************************************************/
import { MyServicesRoute } from 'containers/services';
import { routerMiddleware } from 'router/utils/middleware';

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const VIPsitesExternalAddDomainNamesRoute = createRoute({
    getParentRoute: () => MyServicesRoute,
    path: 'vip-sites/external-add-domain-names/$katanaRandomSubdomain',
    beforeLoad(opts) {
        routerMiddleware.business({ path: '/my-services/vip-sites' }, opts);
        routerMiddleware.business(this, opts);
        routerMiddleware.authentication('user', opts);
    },
    loader: async ({ context, params: { katanaRandomSubdomain } }) => {
        await katanaQuery.searchDomainsList.prefetchQuery(katanaRandomSubdomain);

        const matchingKatanaSiteId = context.queryClient.getQueryData(
            katanaQuery.searchDomainsList.createQueryOptions(katanaRandomSubdomain).queryKey
        )?.data?.[0]?.id;

        if (!matchingKatanaSiteId) {
            pushNotification({
                status: 403,
                details: 'You do not have permission to view this resource.'
            });

            throw redirect({
                to: '/dashboard'
            });
        }

        throw redirect({
            to: '/my-services/vip-sites/$katanaServiceId/overview',
            params: {
                katanaServiceId: matchingKatanaSiteId
            },
            search: {
                'add-domains': 'open'
            }
        });
    }
});
