import { useSuspenseQueries } from '@tanstack/react-query';
import type { API } from 'utilities/api/login';
import { NXQuery } from 'utilities/query';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type LoggedInAccountData = Awaited<API.user.account.list.ReturnType>['data'][number] | undefined;

/**
 * Gets the logged in account id from the check token data and returns the data for that account from the account list
 */
export function getLoggedInAccountData(
    login_account_list_data?: Awaited<API.user.account.list.ReturnType>['data'],
    app_check_token_data?: Awaited<API.token.check.ReturnType>['data']['attributes']
): LoggedInAccountData {
    return login_account_list_data?.find(({ id }) => id === app_check_token_data?.account_id);
}

// prettier-ignore
export const useLoggedInAccountData = <TSelected = LoggedInAccountData>(select: (data: LoggedInAccountData) => TSelected = ((d): any => d)): TSelected => {
    return useSuspenseQueries({
        queries: [
            NXQuery.auth.accountList.createQueryOptions(),
            NXQuery.auth.login.checkToken.createQueryOptions()
        ],
        combine: ([accountListQuery, checkTokenQuery]) => select(getLoggedInAccountData(accountListQuery?.data, checkTokenQuery?.data?.attributes))
    });
};
