import { queryOptions, useQuery } from '@tanstack/react-query';
import { queryClient } from 'store/queryClient';
import { API } from 'utilities/api/services';
import { MINUTE } from 'utilities/consts';
import { createBaseQueryKey } from 'utilities/methods/tanstack/createBaseQueryKey';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
const createQueryKey = () => createBaseQueryKey(['ssl']);

/***** FUNCTIONS *****/
function createQueryOptions() {
    return queryOptions({
        queryKey: createQueryKey(),
        queryFn: API.ssl.product,
        staleTime: MINUTE * 5
    });
}

function prefetchQuery() {
    queryClient.prefetchQuery(createQueryOptions());
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useQuery() {
    return useQuery(createQueryOptions());
}

/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const sslProduct = Object.freeze({
    useQuery: _useQuery,
    createQueryKey,
    createQueryOptions,
    prefetchQuery
});
