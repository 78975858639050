import { useEnabledSuperUserItems } from 'components/StaffMenu/SuperUser/hooks/useEnabledSuperUserItems';
import { useLockedSuperUserItems } from 'components/StaffMenu/SuperUser/hooks/useLockedSuperUserItems';
import { useRenderedSuperUserItems } from 'components/StaffMenu/SuperUser/hooks/useRenderedSuperUserItems';
import _ from 'lodash';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * We need to track 3 things:
 * 1. Items for which they are currently rendering the hook to allow modification.
 * 2. Items that are currently Enabled
 * 3. Items that are currently Locked
 */
export function useActiveSuperUserItems() {
    /***** HOOKS *****/
    const itemsBeingRendered = useRenderedSuperUserItems();
    const enabledItems = useEnabledSuperUserItems();
    const lockedItems = useLockedSuperUserItems();

    /***** HOOK RESULTS *****/
    return _.uniq([...itemsBeingRendered, ...enabledItems, ...lockedItems].filter((key) => !!key));
}
