import { Component } from 'react';
import type { NXUtils } from 'utilities/NXUtils';
import './_DismissButton.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type DismissButtonProps = {
    onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    
    /**
     * Sets the buttons background to black
     */
    background?: true | NXUtils.Falsy;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class DismissButton extends Component<DismissButtonProps> {
    render() {
        const { onClick, background } = this.props;

        /*  RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <button type="button" className="DismissButton" onClick={onClick}>
                {background ? <div className="DismissButton__background" /> : ''}
                <i className="icon icon-x" />
            </button>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default DismissButton;
