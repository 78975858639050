import { useQuery, type UseQueryOptions } from '@tanstack/react-query';
import { useSearch } from '@tanstack/react-router';
import { hostingQueryKeys } from 'containers/hosting/queries/queryKeys';
import { queryClient } from 'store/queryClient';
import { API } from 'utilities/api/services';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
export type DefaultSelectResult = Awaited<API.hosting.searchNew.ReturnType>;
type QueryOptionsSubset<TData = DefaultSelectResult, TError = Error> = Omit<
    UseQueryOptions<DefaultSelectResult, TError, TData, ReturnType<typeof hostingQueryKeys.hosting.search.keyword>>,
    'queryFn' | 'queryKey'
>;

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
const _useQuery = <TData = DefaultSelectResult, TError = Error>(keyword?: string, opts?: QueryOptionsSubset<TData, TError>) => {
    return useQuery({
        queryKey: hostingQueryKeys.hosting.search.keyword(keyword),
        queryFn: ({ signal }) =>
            API.hosting.searchNew({
                /* query only enabled when this is truthy */
                keyword: keyword!,
                signal
            }),
        select: (d) => d as TData, // must have select to override the queryClient default
        ...opts,
        enabled: !!keyword && (opts?.enabled ?? true)
    });
};

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Hook that automatically reads the "search" query param and passes this as the keyword to the search query.
 *
 * @SearchParams
 *
 * - search
 */
const _useSearchQuery = <TData = DefaultSelectResult, TError = Error>(opts?: QueryOptionsSubset<TData, TError>) => {
    /***** HOOKS *****/
    const { search } = useSearch({ strict: false });

    /***** HOOK RESULTS *****/
    return _useQuery(search, opts);
};

/**********************************************************************************************************
 *   FUNCTIONS
 **********************************************************************************************************/
const invalidateQueries = () => {
    queryClient.invalidateQueries({
        queryKey: hostingQueryKeys.hosting.search()
    });
};

/**********************************************************************************************************
 *   EXPORTS
 **********************************************************************************************************/
export const hostingSearch = {
    useQuery: _useQuery,
    useSearchQuery: _useSearchQuery,
    invalidate: invalidateQueries
};
