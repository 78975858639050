export const GridStyledPropKeys = [
    
    /** Sets alignItems flex-end property */
    'alignItems--end',
    
    /** Sets alignItems center property */
    'alignItems--center',
    
    /** Sets alignItems flex-start property */
    'alignItems--start',
    
    /** Sets alignItems stretch property */
    'alignItems--stretch',
    
    /** Sets `justify-self: stretch` on the direct children of the grid property */
    'justify-children--stretch',
    
    /** Sets justifyItems flex-end property */
    'justifyItems--end',
    
    /** Sets justifyItems center property */
    'justifyItems--center',
    
    /** Sets justifyItems flex-start property */
    'justifyItems--start',
    
    /** Sets justifyItems stretch property */
    'justifyItems--stretch',
    
    /** Sets `align-self: stretch` on the direct children of the grid property */
    'align-children--stretch',
    
    /** Sets width to 100% */
    'fullWidth'
];
