import { useQueryClient } from '@tanstack/react-query';
import { RouterProvider } from '@tanstack/react-router';
import { useNXQuery } from 'components/NXQueryProvider';
import { RouterInnerWrapper } from 'components/Router/InnerWrap';
import { useStore } from 'react-redux';
import { router } from 'router/router';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Router component. This component sites inside Providers, but above the Application and provides global components (toast, alerts, etc) and fills the Router Context.
 */
export const RouterContextProvider = () => {
    /***** DEPENDENCIES *****/
    const queryClient = useQueryClient();
    const store = useStore();
    const NXQuery = useNXQuery();

    /***** RENDER *****/
    return <RouterProvider InnerWrap={RouterInnerWrapper} router={router} context={{ queryClient, store, NXQuery }} />;
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
