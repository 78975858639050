import type { MakeRouteMatchUnion, RegisteredRouter } from '@tanstack/react-router';
import { createRoute, Outlet, useRouter } from '@tanstack/react-router';
import { zodValidator } from '@tanstack/zod-adapter';
import classNames from 'classnames';
import { useBrandStore } from 'config/hooks/useBrandStore';
import type { PossibleLoginViews } from 'containers/login/consts';
import { LoginContext, possibleLoginViews } from 'containers/login/consts';
import { VentraLoginLayoutOutlet } from 'containers/login/routes/_login/layouts/ventra';
import { useState } from 'react';
import { RootRoute } from 'router/__root';
import { routerMiddleware } from 'router/utils/middleware';
import { z } from 'zod';

/**********************************************************************************************************
 *   ROUTE SCHEMA
 **********************************************************************************************************/
const loginLayoutSchema = z.object({
    /* A reference to the route the user wants to access when authenticated. */
    'ref': z.string().optional(),

    /*
     * A token from the approve-user route. If this is present, the login area needs to redirect to the approve-user/$token route after signup.
     * @note - this could reasonably be moved into one serializable param (object with post approval redirect) so it is more generic
     */
    'approve-token': z.string().optional(),

    /*
     * A token from the move-service route. If this is present, the login area needs to redirect to the move-service/$moveToken route after signup.
     * @note - this could reasonably be moved into one serializable param (object with post approval redirect) so it is more generic
     */
    'move-token': z.string().optional(),

    'login-view': z.enum(possibleLoginViews).optional()
});

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const LoginLayoutRoute = createRoute({
    getParentRoute: () => RootRoute,
    id: '_login',
    beforeLoad(opts) {
        routerMiddleware.business(this, opts);
    },
    validateSearch: zodValidator(loginLayoutSchema),
    component: LoginLayoutRouteComponent
});

/**********************************************************************************************************
 *   ROUTE COMPONENT START
 **********************************************************************************************************/
function LoginLayoutRouteComponent() {
    /***** HOOKS *****/
    const { activeBrand } = useBrandStore();
    const router = useRouter();

    /***** STATE *****/
    const [currentView, setCurrentView] = useState<PossibleLoginViews>(() => {
        const { search } = router.state.location;

        const isResetPasswordRoute = router.state.matches.some(
            (match: MakeRouteMatchUnion<RegisteredRouter>) => match.routeId === '/_login/reset-password/$token'
        );

        if (isResetPasswordRoute) return 'reset';
        if (search.token) return 'reset';
        if (search.forgot) return 'forgot';
        if (search['login-view']) return search['login-view'];
        return 'login';
    });

    /***** FUNCTIONS *****/
    const changeView = (view: PossibleLoginViews) => {
        setCurrentView(view);
        const loginContainer = document.querySelector('.login__container');
        if (loginContainer) loginContainer.scrollTop = 0;
    };

    /***** RENDER HELPERS *****/
    const isLoginPage = currentView === 'login';
    const loginClassName = classNames('login', {
        'login__container--scamWarning': isLoginPage,
        'login__intaserve': activeBrand === 'intaserve'
    });

    /***** RENDER *****/
    return (
        <div className={loginClassName}>
            <LoginContext.Provider value={{ setView: changeView, currentView }}>
                {(() => {
                    switch (activeBrand) {
                        case 'ventra':
                            return <VentraLoginLayoutOutlet />;
                        case 'intaserve':
                            return <Outlet />;
                        default:
                            null;
                    }
                })()}
            </LoginContext.Provider>
        </div>
    );
}
