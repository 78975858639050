import { queryOptions, useQuery, useSuspenseQuery } from '@tanstack/react-query';

import { API } from 'utilities/api/domain';
import { createBaseQueryKey } from 'utilities/methods/tanstack/createBaseQueryKey';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Params = {
    domain: string;
    eppCode: string;
};

/***** FUNCTIONS *****/
function createQueryKey({ domain, eppCode }: Params) {
    return createBaseQueryKey(['domain', 'ordering', 'eligibility', 'eppCode', domain, eppCode]);
}

function createQueryOptions(params: Params) {
    return queryOptions({
        queryKey: createQueryKey(params),
        queryFn: ({ signal }) => API.domain.POST.eppValidation(params, signal),
        select: (data) => data,
        enabled: Boolean(params.domain && params.eppCode)
    });
}

function _useSuspenseQuery(params: Params) {
    return useSuspenseQuery(createQueryOptions(params));
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useQuery(params: Params) {
    return useQuery(createQueryOptions(params));
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const eppValidation = Object.freeze({
    useQuery: _useQuery,
    useSuspenseQuery: _useSuspenseQuery,
    createQueryKey,
    createQueryOptions
});
