import { createRoute } from '@tanstack/react-router';
import { createKatanaServiceId } from 'containers/katana/methods';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';

/**********************************************************************************************************
 *   ROUTE IMPORTS
 **********************************************************************************************************/
import { VIPSitesSetupRoute } from 'containers/katana/routes/vip-sites.$katanaServiceId/_content-editor-lightbox/setup';
import { routerMiddleware } from 'router/utils/middleware';
import { lazyFN } from 'utilities/methods/tanstack/router/lazyFn';

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const VIPSitesSetupTemplateContentRoute = createRoute({
    getParentRoute: () => VIPSitesSetupRoute,
    path: 'template-content',
    loader({ params }) {
        const katanaServiceId = createKatanaServiceId(params.katanaServiceId);
        katanaQuery.serviceID.meta.sectionDefinitions.prefetchQuery(katanaServiceId);
    },
    beforeLoad(opts) {
        routerMiddleware.business(this, opts);
        routerMiddleware.authentication('user', opts);
    }
}).lazy(lazyFN(() => import('./index.lazy'), 'LazyVIPSitesSetupTemplateContentRoute'));
