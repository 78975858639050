import { _AdvancedListContainer } from 'components/Form/AdvancedList/container';
import { _AdvanceListItem } from 'components/Form/AdvancedList/item';
import { _AdvanceListItemContainer } from 'components/Form/AdvancedList/itemContainer';
import { _AdvancedListLabel } from 'components/Form/AdvancedList/label';
import './_AdvancedList.scss';

export const AdvancedList = Object.assign(_AdvancedListContainer, {
    Label: _AdvancedListLabel,
    Item: Object.assign(_AdvanceListItem, {
        Container: _AdvanceListItemContainer
    })
});
