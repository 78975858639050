import { createRoute, redirect } from "@tanstack/react-router";
import AppLoader from "components/Loaders/App";
import { RootRoute } from "router/__root";

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const StaffLoginRoute = createRoute({
    getParentRoute: () => RootRoute,
    path: '/staff-login/$staffLoginToken',
    pendingMs: 0, // ensure appLoader always shows
    pendingComponent: () => <AppLoader fullHeight message="You are logging in as a staff member" />,
    staticData: {
        preventLoadAppConfig: true
    },
    async loader({ params: { staffLoginToken }, context: { NXQuery, queryClient } }) {
        try {
            const mercury_data = await NXQuery.auth.login.staff.execute(staffLoginToken);

            // we need to fetch check_token_data so that there is something to append to when merging mercury_data and check_token_data
            await Promise.all([NXQuery.auth.login.checkToken.fetch(), NXQuery.auth.accountList.fetch()]);

            // The existing code expected us to "update" check_token_data to use the attributes from mercury_data, however it looks
            // like calling the bypass endpoint also sets our cookie, so we can also get check_token_data with a success response (normal data).

            // with this in mind, we can now just merge both attributes together so that we have all the check_token_data we expect,
            // as well as the attributes from being a privileged user (which seems to include different properties than the normal check_token_data request)
            NXQuery.auth.login.checkToken.optimistic.merge(void 0, 'data.attributes', mercury_data.data.attributes);

            // finally, continue on with logging in
            await NXQuery.auth.userData.fetch();
        } catch (e) {
            // if anything goes wrong in this process, we should reset all queries and redirect to the login page.
            queryClient.clear();

            throw redirect({ to: '/login' });
        }

        // When everything went successfully, send 'em to the dashboard
        throw redirect({ to: '/dashboard' });
    }
});
