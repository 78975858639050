import RequestLoader from 'components/Loaders/Request';
import { Padding } from 'components/Utils/Padding';
import Text from 'components/Utils/Text';
import { useOrderingFormContext } from 'containers/orderingForm/contexts/useOrderingFormContext';
import 'containers/orderingForm/pages/registerPage/registrationAvailabilityConfirmation/_registrationAvailabilityConfirmation.scss';
import { RegisterErrorConfirmation } from 'containers/orderingForm/pages/registerPage/registrationAvailabilityConfirmation/registerErrorConfirmation';
import { RegisterSuccessConfirmation } from 'containers/orderingForm/pages/registerPage/registrationAvailabilityConfirmation/registerSuccessConfirmation';
import { NXQuery } from 'utilities/query';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const RegistrationAvailabilityConfirmation = () => {
    /***** HOOKS *****/
    const { domainData } = useOrderingFormContext();

    /***** QUERIES *****/
    const {
        data: domain_availability_data,
        status: domainAvailabilityDataStatus,
        isError: isDomainAvailabilityError,
        error: domain_availability_error
    } = NXQuery.domain.availability.useQuery(domainData);

    /***** RENDER HELPERS *****/
    const renderAvailabilityConfirmation = () => {
        if (domainAvailabilityDataStatus === 'pending' && domainData.domain !== '') return <RequestLoader />;
        if (domainAvailabilityDataStatus === 'error')
            return (
                <Text align--center color="S_color_text_warn_base">
                    {domain_availability_error?.errors[0]?.details}
                </Text>
            );
        if (isDomainAvailabilityError || domain_availability_data?.[0]?.available === false) return <RegisterErrorConfirmation />;
        if (domain_availability_data) return <RegisterSuccessConfirmation />;
        return null;
    };

    /***** RENDER *****/
    return (
        <Padding y={5} className="registrationAvailabilityConfirmation__placeholder">
            {renderAvailabilityConfirmation()}
        </Padding>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
