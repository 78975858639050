import classNames from 'classnames';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import { Flex } from 'components/Utils/Flex';
import { Padding } from 'components/Utils/Padding';
import Text from 'components/Utils/Text';
import 'containers/orderingForm/orderingFormInputs/textInput/_orderingFormTextInputIntaserve.scss';
import type { TextInputHookFormProps } from 'containers/orderingForm/orderingFormInputs/types';
import { useState } from 'react';
import type { FieldValues } from 'react-hook-form';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _TextInputHookFormIntaserve = <T extends FieldValues>({
    name,
    label,
    register,
    placeholder = 'Enter text',
    disabled = false,
    type = 'text',
    validationErrors,
    errorBorder,
    successBorder
}: TextInputHookFormProps<T>) => {
    const inputClassNames = classNames('formTextInput__inputField', {
        'formTextInput__inputField--success': successBorder,
        'formTextInput__inputField--error': errorBorder
    });

    /***** STATE *****/
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const inputType = type === 'password' && isPasswordVisible ? 'text' : type;

    const togglePasswordVisibility = () => setIsPasswordVisible((prev) => !prev);

    const openIconClassNames = classNames('formTextInput__openPasswordIcon', {
        'formTextInput__openPasswordIcon--hidden': !isPasswordVisible
    });
    const closedIconClassNames = classNames('formTextInput__openPasswordIcon', {
        'formTextInput__closedPasswordIcon--hidden': isPasswordVisible
    });

    /***** RENDER *****/
    return (
        <div className="formTextInput__container">
            <Flex direction="column">
                {label && <Text semiBold>{label}</Text>}
                <div className="formTextInput__passwordInputWrapper">
                    <input {...register(name)} className={inputClassNames} placeholder={placeholder} type={inputType} disabled={disabled} />
                    {type === 'password' && (
                        <button type="button" className="formTextInput__passwordIconContainer" onClick={togglePasswordVisibility}>
                            <PhosphorIcons.Eye size={25} className={openIconClassNames} />
                            <PhosphorIcons.EyeSlash size={25} className={closedIconClassNames} />
                        </button>
                    )}
                </div>
                {validationErrors?.[name]?.message && (
                    <Padding left={2} bottom={1}>
                        <Text size--xs lead--0 color="S_color_text_warn_base">
                            {validationErrors?.[name]?.message as string}
                        </Text>
                    </Padding>
                )}
            </Flex>
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
