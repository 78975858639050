import Axios from 'axios';
import { pushNotification } from 'components/Toast/functions';
import store from 'store/store';
import {
    API,
    cancelGetCustomer,
    cancelGetData,
    cancelGetPricing,
    cancelGetRecord,
    cancelGetService,
    cancelGetStatus,
    cancelGetUserData,
    cancelGetUsers
} from 'utilities/api/gsuite';
import { API as SERVICES } from 'utilities/api/services';
import { createPopup, getDataFromSuccessResponse, getErrorFromFailResponse } from 'utilities/methods/commonActions';
import {
    GSUITE_BILLING_CYCLE_ERROR,
    GSUITE_BILLING_CYCLE_REQUEST,
    GSUITE_BILLING_CYCLE_SUCCESS,
    GSUITE_CURRENT_STEP,
    GSUITE_CUSTOMER_CHECK_ERROR,
    GSUITE_CUSTOMER_CHECK_REQUEST,
    GSUITE_CUSTOMER_CHECK_SUCCESS,
    GSUITE_CUSTOMER_DATA_ERROR,
    GSUITE_CUSTOMER_DATA_REQUEST,
    GSUITE_CUSTOMER_DATA_SUCCESS,
    GSUITE_DELETE_MAILBOX_ERROR,
    GSUITE_DELETE_MAILBOX_REQUEST,
    GSUITE_DELETE_MAILBOX_SUCCESS,
    GSUITE_INITIAL_STATE,
    GSUITE_LOGIN_ERROR,
    GSUITE_LOGIN_REQUEST,
    GSUITE_LOGIN_SUCCESS,
    GSUITE_MAILBOX_ERROR,
    GSUITE_MAILBOX_REQUEST,
    GSUITE_MAILBOX_SUCCESS,
    GSUITE_PRODUCT_PRICING_ERROR,
    GSUITE_PRODUCT_PRICING_REQUEST,
    GSUITE_PRODUCT_PRICING_SUCCESS,
    GSUITE_PURCHASE_ERROR,
    GSUITE_PURCHASE_REQUEST,
    GSUITE_PURCHASE_SUCCESS,
    GSUITE_SEAT_PRICING_ERROR,
    GSUITE_SEAT_PRICING_REQUEST,
    GSUITE_SEAT_PRICING_SUCCESS,
    GSUITE_SERVICE_ERROR,
    GSUITE_SERVICE_REQUEST,
    GSUITE_SERVICE_SUCCESS,
    GSUITE_UPDATE_BILLING_CYCLE_ERROR,
    GSUITE_UPDATE_BILLING_CYCLE_REQUEST,
    GSUITE_UPDATE_BILLING_CYCLE_SUCCESS,
    GSUITE_UPGRADE_ERROR,
    GSUITE_UPGRADE_REQUEST,
    GSUITE_UPGRADE_SUCCESS,
    GSUITE_USER_ACCOUNTS_ERROR,
    GSUITE_USER_ACCOUNTS_REQUEST,
    GSUITE_USER_ACCOUNTS_SUCCESS,
    GSUITE_USER_CREATE_ERROR,
    GSUITE_USER_CREATE_REQUEST,
    GSUITE_USER_CREATE_SUCCESS,
    GSUITE_USER_DATA_ERROR,
    GSUITE_USER_DATA_REQUEST,
    GSUITE_USER_DATA_SUCCESS,
    GSUITE_USER_DELETE_ERROR,
    GSUITE_USER_DELETE_REQUEST,
    GSUITE_USER_DELETE_SUCCESS,
    GSUITE_VERIFICATION_ERROR,
    GSUITE_VERIFICATION_REQUEST,
    GSUITE_VERIFICATION_STATUS_ERROR,
    GSUITE_VERIFICATION_STATUS_REQUEST,
    GSUITE_VERIFICATION_STATUS_SUCCESS,
    GSUITE_VERIFICATION_SUCCESS,
    GSUITE_VERIFY_RECORD_ERROR,
    GSUITE_VERIFY_RECORD_REQUEST,
    GSUITE_VERIFY_RECORD_SUCCESS
} from './actionTypes';

/**********************************************************************************************************
 *   ACTIONS - INITIAL
 **********************************************************************************************************/
export const resetGSuiteState = () => {
    return (dispatch) => {
        dispatch({
            type: GSUITE_INITIAL_STATE
        });
    };
};

/**********************************************************************************************************
 *   ACTIONS - SETUP
 **********************************************************************************************************/
export const gsuiteGoToStep = (step) => {
    return (dispatch) => {
        dispatch({
            type: GSUITE_CURRENT_STEP,
            gsuite_current_step: step
        });
    };
};

export const getGSuiteVerificationData = (id) => {
    return (dispatch) => {
        if (cancelGetRecord) {
            cancelGetRecord();
        }
        dispatch({
            type: GSUITE_VERIFICATION_REQUEST
        });
        API.gsuite.GET.record(id)
            .then((response) => {
                const gsuite_verification_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: GSUITE_VERIFICATION_SUCCESS,
                    gsuite_verification_data
                });
            })
            .catch((error) => {
                const gsuite_verification_error = getErrorFromFailResponse(error);
                dispatch({
                    type: GSUITE_VERIFICATION_ERROR,
                    gsuite_verification_error
                });
            });
    };
};

export const getGSuiteVerificationStatus = (id) => {
    return (dispatch) => {
        if (cancelGetStatus) {
            cancelGetStatus();
        }
        dispatch({
            type: GSUITE_VERIFICATION_STATUS_REQUEST
        });
        API.gsuite.GET.status(id)
            .then((response) => {
                const gsuite_verification_status_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: GSUITE_VERIFICATION_STATUS_SUCCESS,
                    gsuite_verification_status_data
                });
            })
            .catch((error) => {
                const gsuite_verification_status_error = getErrorFromFailResponse(error);
                dispatch({
                    type: GSUITE_VERIFICATION_STATUS_ERROR,
                    gsuite_verification_status_error
                });
            });
    };
};

export const verifyDomainRecord = (id) => {
    return (dispatch) => {
        dispatch({
            type: GSUITE_VERIFY_RECORD_REQUEST
        });
        API.gsuite.POST.verify(id)
            .then((response) => {
                const gsuite_verify_record_data = getDataFromSuccessResponse(response);
                pushNotification(gsuite_verify_record_data);
                dispatch({
                    type: GSUITE_VERIFY_RECORD_SUCCESS,
                    gsuite_verify_record_data
                });
            })
            .catch((error) => {
                const gsuite_verify_record_error = getErrorFromFailResponse(error);
                pushNotification(gsuite_verify_record_error);
                dispatch({
                    type: GSUITE_VERIFY_RECORD_ERROR,
                    gsuite_verify_record_error
                });
            });
    };
};

/**********************************************************************************************************
 *   ACTIONS - GENERAL
 **********************************************************************************************************/
export const getGSuiteService = (id) => {
    const { dispatch } = store;
    if (cancelGetService) {
        cancelGetService();
    }
    dispatch({
        type: GSUITE_SERVICE_REQUEST
    });
    return API.gsuite.GET.service(id)
        .then((response) => {
            const gsuite_service_data = getDataFromSuccessResponse(response);
            dispatch({
                type: GSUITE_SERVICE_SUCCESS,
                gsuite_service_data
            });
        })
        .catch((error) => {
            const gsuite_service_error = getErrorFromFailResponse(error);
            dispatch({
                type: GSUITE_SERVICE_ERROR,
                gsuite_service_error
            });
        });
};

export const getGSuiteUserAccounts = (id) => {
    const { dispatch } = store;

    if (cancelGetUsers) {
        cancelGetUsers();
    }

    dispatch({
        type: GSUITE_USER_ACCOUNTS_REQUEST
    });

    API.gsuite.GET.users(id)
        .then((response) => {
            const gsuite_user_accounts_data = getDataFromSuccessResponse(response);
            dispatch({
                type: GSUITE_USER_ACCOUNTS_SUCCESS,
                gsuite_user_accounts_data
            });
        })
        .catch((error) => {
            const gsuite_user_accounts_error = getErrorFromFailResponse(error);
            dispatch({
                type: GSUITE_USER_ACCOUNTS_ERROR,
                gsuite_user_accounts_error
            });
        });
};

export const getGSuiteMailbox = (id, mailbox) => {
    return (dispatch) => {
        dispatch({
            type: GSUITE_MAILBOX_REQUEST
        });
        API.gsuite.GET.mailbox(id, mailbox)
            .then((response) => {
                const gsuite_mailbox_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: GSUITE_MAILBOX_SUCCESS,
                    gsuite_mailbox_data
                });
            })
            .catch((error) => {
                const gsuite_mailbox_error = getErrorFromFailResponse(error);
                dispatch({
                    type: GSUITE_MAILBOX_ERROR,
                    gsuite_mailbox_error
                });
            });
    };
};

export const checkGSuiteCustomer = (id) => {
    return (dispatch) => {
        if (cancelGetCustomer) {
            cancelGetCustomer();
        }
        dispatch({
            type: GSUITE_CUSTOMER_CHECK_REQUEST
        });
        API.gsuite.GET.customer(id)
            .then((response) => {
                const gsuite_customer_check_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: GSUITE_CUSTOMER_CHECK_SUCCESS,
                    gsuite_customer_check_data
                });
            })
            .catch((error) => {
                const gsuite_customer_check_error = getErrorFromFailResponse(error);
                dispatch({
                    type: GSUITE_CUSTOMER_CHECK_ERROR,
                    gsuite_customer_check_error
                });
            });
    };
};

export const getGSuiteCustomerData = (id) => {
    return (dispatch) => {
        if (cancelGetData) {
            cancelGetData();
        }
        dispatch({
            type: GSUITE_CUSTOMER_DATA_REQUEST
        });
        API.gsuite.GET.data(id)
            .then((response) => {
                const gsuite_customer_data_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: GSUITE_CUSTOMER_DATA_SUCCESS,
                    gsuite_customer_data_data
                });
            })
            .catch((error) => {
                const gsuite_customer_data_error = getErrorFromFailResponse(error);
                dispatch({
                    type: GSUITE_CUSTOMER_DATA_ERROR,
                    gsuite_customer_data_error
                });
            });
    };
};

export const createGSuiteCustomerData = (id, attributes) => {
    return (dispatch) => {
        dispatch({
            type: GSUITE_CUSTOMER_DATA_REQUEST
        });
        API.gsuite.POST.customer(id, attributes)
            .then((response) => {
                pushNotification(getDataFromSuccessResponse(response));
                dispatch(getGSuiteCustomerData(id));
            })
            .catch((error) => {
                const gsuite_customer_data_error = getErrorFromFailResponse(error);
                pushNotification(gsuite_customer_data_error);
                dispatch(getGSuiteCustomerData(id));
            });
    };
};

export const updateGSuiteCustomerData = (id, attributes) => {
    const dispatch = store.dispatch;

    dispatch({
        type: GSUITE_CUSTOMER_DATA_REQUEST
    });

    API.gsuite.PUT.customer(id, attributes)
        .then((response) => {
            pushNotification(getDataFromSuccessResponse(response));
            dispatch(getGSuiteCustomerData(id));
        })
        .catch((error) => {
            const gsuite_customer_data_error = getErrorFromFailResponse(error);
            pushNotification(gsuite_customer_data_error);
            dispatch(getGSuiteCustomerData(id));
        });
};

export const getGSuiteLogin = (id) => {
    return (dispatch) => {
        dispatch({
            type: GSUITE_LOGIN_REQUEST
        });

        const { goToTargetUrl, closePopup } = createPopup();

        API.gsuite.GET.login(id)
            .then((response) => {
                const gsuite_login_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: GSUITE_LOGIN_SUCCESS,
                    gsuite_login_data
                });

                goToTargetUrl(gsuite_login_data.attributes.link);
            })
            .catch((error) => {
                const gsuite_login_error = getErrorFromFailResponse(error);
                dispatch({
                    type: GSUITE_LOGIN_ERROR,
                    gsuite_login_error
                });

                closePopup();
            });
    };
};

export const deleteGSuiteService = (id, attributes) => {
    return (dispatch) => {
        dispatch({
            type: GSUITE_DELETE_MAILBOX_REQUEST
        });
        SERVICES.service.post
            .cancel(id, attributes)
            .then((response) => {
                const gsuite_delete_mailbox_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: GSUITE_DELETE_MAILBOX_SUCCESS,
                    gsuite_delete_mailbox_data
                });
            })
            .catch((error) => {
                const gsuite_delete_service_error = getErrorFromFailResponse(error);
                dispatch({
                    type: GSUITE_DELETE_MAILBOX_ERROR,
                    gsuite_delete_service_error
                });
            });
    };
};

/**********************************************************************************************************
 *   ACTIONS - SEATS
 **********************************************************************************************************/
export const getGSuiteSeatsPricing = (serviceId, seats, product_id) => {
    return (dispatch) => {
        if (cancelGetPricing) {
            cancelGetPricing();
        }

        dispatch({
            type: GSUITE_SEAT_PRICING_REQUEST
        });

        return API.gsuite.GET.pricing(serviceId, seats, product_id)
            .then((response) => {
                const gsuite_seat_pricing_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: GSUITE_SEAT_PRICING_SUCCESS,
                    gsuite_seat_pricing_data
                });
            })
            .catch((error) => {
                if (error instanceof Axios.Cancel) return;

                const gsuite_seat_pricing_error = getErrorFromFailResponse(error);
                dispatch({
                    type: GSUITE_SEAT_PRICING_ERROR,
                    gsuite_seat_pricing_error
                });
            });
    };
};

export const purchaseGSuiteSeats = (id, seats) => {
    const { dispatch } = store;
    dispatch({
        type: GSUITE_PURCHASE_REQUEST
    });

    return API.gsuite.POST.purchase(id, seats)
        .then((response) => {
            const gsuite_purchase_data = getDataFromSuccessResponse(response);
            pushNotification(gsuite_purchase_data);
            dispatch({
                type: GSUITE_PURCHASE_SUCCESS,
                gsuite_purchase_data
            });
            return gsuite_purchase_data;
        })
        .catch((error) => {
            const gsuite_purchase_error = getErrorFromFailResponse(error);
            pushNotification(gsuite_purchase_error);
            dispatch({
                type: GSUITE_PURCHASE_ERROR,
                gsuite_purchase_error
            });
        });
};

/**********************************************************************************************************
 *   ACTIONS - MAILBOX
 **********************************************************************************************************/
export const createGSuiteMailbox = (id, attributes, history) => {
    return (dispatch) => {
        dispatch({
            type: GSUITE_MAILBOX_REQUEST
        });
        API.gsuite.POST.create(id, attributes)
            .then((response) => {
                const gsuite_mailbox_data = getDataFromSuccessResponse(response);
                pushNotification(gsuite_mailbox_data);
                dispatch({
                    type: GSUITE_MAILBOX_SUCCESS,
                    gsuite_mailbox_data
                });
                getGSuiteService(id);
                history.push(`/my-services/google/${id}/manage#overview`);
            })
            .catch((error) => {
                const gsuite_mailbox_error = getErrorFromFailResponse(error);
                pushNotification(gsuite_mailbox_error);
                dispatch({
                    type: GSUITE_MAILBOX_ERROR,
                    gsuite_mailbox_error
                });
            });
    };
};

export const getGSuiteUserData = (id, mailbox) => {
    const dispatch = store.dispatch;

    if (cancelGetUserData) {
        cancelGetUserData();
    }

    dispatch({
        type: GSUITE_USER_DATA_REQUEST
    });

    API.gsuite.GET.userData(id, mailbox)
        .then((response) => {
            const gsuite_user_data_data = getDataFromSuccessResponse(response);
            dispatch({
                type: GSUITE_USER_DATA_SUCCESS,
                gsuite_user_data_data
            });
        })
        .catch((error) => {
            const gsuite_user_data_error = getErrorFromFailResponse(error);
            dispatch({
                type: GSUITE_USER_DATA_ERROR,
                gsuite_user_data_error
            });
        });
};

export const createGSuiteUserData = (id, attributes, callbacks = {}) => {
    const { dispatch } = store;

    dispatch({
        type: GSUITE_USER_CREATE_REQUEST
    });

    return API.gsuite.POST.create(id, attributes)
        .then((response) => {
            const gsuite_user_create_data = getDataFromSuccessResponse(response);
            pushNotification(gsuite_user_create_data);
            dispatch({
                type: GSUITE_USER_CREATE_SUCCESS,
                gsuite_user_create_data
            });
            callbacks.onSuccess?.();
        })
        .catch((error) => {
            const gsuite_user_create_error = getErrorFromFailResponse(error);
            pushNotification(gsuite_user_create_error);
            dispatch({
                type: GSUITE_USER_CREATE_ERROR,
                gsuite_user_create_error
            });
        });
};

export const createGSuiteAdminData = (id, attributes) => {
    return (dispatch) => {
        dispatch({
            type: GSUITE_USER_CREATE_REQUEST
        });
        API.gsuite.POST.admin(id, attributes)
            .then((response) => {
                const gsuite_user_create_data = getDataFromSuccessResponse(response);
                pushNotification(gsuite_user_create_data);
                dispatch(getGSuiteUserAccounts(id));
                dispatch({
                    type: GSUITE_USER_CREATE_SUCCESS,
                    gsuite_user_create_data
                });
            })
            .catch((error) => {
                const gsuite_user_create_error = getErrorFromFailResponse(error);
                pushNotification(gsuite_user_create_error);
                dispatch({
                    type: GSUITE_USER_CREATE_ERROR,
                    gsuite_user_create_error
                });
            });
    };
};

export const updateGSuiteUserData = (id, mailbox, attributes, callbacks = {}) => {
    const dispatch = store.dispatch;

    dispatch({
        type: GSUITE_USER_DATA_REQUEST
    });

    return API.gsuite.PUT.update(id, mailbox, attributes)
        .then((response) => {
            pushNotification(getDataFromSuccessResponse(response));
            getGSuiteMailbox(id, mailbox)(dispatch);
            callbacks.onSuccess?.();
        })
        .catch((error) => {
            const gsuite_customer_data_error = getErrorFromFailResponse(error);
            pushNotification(gsuite_customer_data_error);
            getGSuiteUserData(id, mailbox);
        });
};

export const deleteGSuiteUser = (id, user, successCallback) => {
    const dispatch = store.dispatch;

    dispatch({
        type: GSUITE_USER_DELETE_REQUEST
    });

    API.gsuite.DELETE.user(id, user)
        .then((response) => {
            const gsuite_user_delete_data = getDataFromSuccessResponse(response);
            pushNotification(gsuite_user_delete_data);
            dispatch({
                type: GSUITE_USER_DELETE_SUCCESS,
                gsuite_user_delete_data
            });

            if (successCallback) {
                successCallback(`/my-services/google/${id}/manage#overview`);
            }
        })
        .catch((error) => {
            const gsuite_user_delete_error = getErrorFromFailResponse(error);
            pushNotification(gsuite_user_delete_error);
            dispatch({
                type: GSUITE_USER_DELETE_ERROR,
                gsuite_user_delete_error
            });
        });
};

/**********************************************************************************************************
 *   ACTIONS - UPGRADE
 **********************************************************************************************************/
export const upgradeGSuiteService = (id, attributes) => {
    return (dispatch) => {
        dispatch({
            type: GSUITE_UPGRADE_REQUEST
        });
        API.gsuite.POST.upgrade(id, attributes)
            .then((response) => {
                const gsuite_upgrade_data = getDataFromSuccessResponse(response);
                pushNotification(gsuite_upgrade_data);
                dispatch({
                    type: GSUITE_UPGRADE_SUCCESS,
                    gsuite_upgrade_data
                });
            })
            .catch((error) => {
                const gsuite_upgrade_error = getErrorFromFailResponse(error);
                pushNotification(gsuite_upgrade_error);
                dispatch({
                    type: GSUITE_UPGRADE_ERROR,
                    gsuite_upgrade_error
                });
            });
    };
};

export const getGSuiteProductPricing = (id) => {
    const { dispatch } = store;
    dispatch({
        type: GSUITE_PRODUCT_PRICING_REQUEST
    });
    API.gsuite.GET.product(id)
        .then((response) => {
            const gsuite_product_pricing_data = getDataFromSuccessResponse(response);
            dispatch({
                type: GSUITE_PRODUCT_PRICING_SUCCESS,
                gsuite_product_pricing_data
            });
        })
        .catch((error) => {
            const gsuite_product_pricing_error = getErrorFromFailResponse(error);
            dispatch({
                type: GSUITE_PRODUCT_PRICING_ERROR,
                gsuite_product_pricing_error
            });
        });
};

export function getGSuiteBillingCycle(id) {
    const { dispatch } = store;

    dispatch({
        type: GSUITE_BILLING_CYCLE_REQUEST
    });
    API.gsuite.GET.billingCycle(id)
        .then((response) => {
            const gsuite_billing_cycle_data = getDataFromSuccessResponse(response);
            dispatch({
                type: GSUITE_BILLING_CYCLE_SUCCESS,
                gsuite_billing_cycle_data
            });
        })
        .catch(() => {
            dispatch({
                type: GSUITE_BILLING_CYCLE_ERROR
            });
        });
}

export function updateGSuiteBillingCycle(id, billingCycleID) {
    const { dispatch } = store;

    dispatch({
        type: GSUITE_UPDATE_BILLING_CYCLE_REQUEST
    });
    API.gsuite.PUT.billingCycle(id, billingCycleID)
        .then((response) => {
            const gsuite_update_billing_cycle_data = getDataFromSuccessResponse(response);
            dispatch({
                type: GSUITE_UPDATE_BILLING_CYCLE_SUCCESS,
                gsuite_update_billing_cycle_data
            });
        })
        .catch((error) => {
            const gsuite_update_billing_cycle_error = getErrorFromFailResponse(error);
            dispatch({
                type: GSUITE_UPDATE_BILLING_CYCLE_ERROR
            });
            pushNotification(gsuite_update_billing_cycle_error);
        });
}
