import { queryOptions, useQuery } from '@tanstack/react-query';
import type { BillingCycle } from 'containers/hosting/forms/renewForm';
import { queryClient } from 'store/queryClient';
import { API } from 'utilities/api/services';
import { createBaseQueryKey } from 'utilities/methods/tanstack/createBaseQueryKey';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
function createQueryKey(id: string) {
    return createBaseQueryKey(['hostingPrepayInfo', id]);
}

function createQueryOptions(id: string, billing_cycle: BillingCycle) {
    return queryOptions({
        // eslint-disable-next-line @tanstack/query/exhaustive-deps
        queryKey: createQueryKey(id),
        queryFn: () => API.service.post.renewDetails(id, { billing_cycle: billing_cycle }),
        select: (response) => {
            if (response?.status === 200) {
                return response.data.attributes;
            }
        }
    });
}

function invalidateQueries(id: string) {
    queryClient.invalidateQueries({ queryKey: createQueryKey(id) });
}

function cancelQueries(id: string) {
    queryClient.cancelQueries({ queryKey: createQueryKey(id) });
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useQuery(id: string, billing_cycle: BillingCycle) {
    return useQuery(createQueryOptions(id, billing_cycle));
}

/**********************************************************************************************************
 *  HOOK END
 * **********************************************************************************************************/

export const hostingPrepayInfo = Object.freeze({
    useQuery: _useQuery,
    createQueryKey,
    createQueryOptions,
    invalidateQueries,
    cancelQueries
});
