import { useSuperUserItemState } from 'components/StaffMenu/SuperUser/hooks/useSuperUserItemState';
import type { SuperUserNamespace } from 'components/StaffMenu/SuperUser/types';
import _ from 'lodash';
import { UserPreferences } from 'utilities/UserPreferences';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export function useSuperUserActionLockState(actionKey: SuperUserNamespace.Key) {
    /***** HOOKS *****/
    const [lockedItems = {}, setLockedItems] = UserPreferences.useLocalStorage('SUPER_USER_LOCKED_ITEMS', {});
    const { value = false } = useSuperUserItemState(actionKey);

    /***** RENDER HELPERS *****/
    const isLocked = _.has(lockedItems, actionKey);

    /***** FUNCTIONS *****/
    function lock() {
        setLockedItems({ ...lockedItems, [actionKey]: value });
    }

    function unlock() {
        const { [actionKey]: _, ...newLockedItems } = lockedItems;
        setLockedItems(newLockedItems);
    }

    function toggleLock() {
        if (isLocked) {
            unlock();
        } else {
            lock();
        }
    }

    /***** HOOK RESULTS *****/
    return { isLocked, lock, unlock, toggleLock };
}
