import { queryOptions, useQuery } from '@tanstack/react-query';
import { API } from 'utilities/api/account';
import { createBaseQueryKey } from 'utilities/methods/tanstack/createBaseQueryKey';

function createQueryKey() {
    return createBaseQueryKey(['paymentMethods']);
}

function createQueryOptions() {
    return queryOptions({
        queryKey: createQueryKey(),
        queryFn: () => API.user.payment_methods.GET(),
        select: (data) => data.data
    });
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useQuery() {
    return useQuery(createQueryOptions());
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const getPaymentMethods = Object.freeze({
    useQuery: _useQuery
});
