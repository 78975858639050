import { type FullSearchSchema, type RegisteredRouter, useNavigate, useSearch } from '@tanstack/react-router';
import _ from 'lodash';
import { useCallback } from 'react';
import { useDebounceCallback } from 'usehooks-ts';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type UseQueryParamSearchResult<TName extends keyof FullSearchSchema<RegisteredRouter['routeTree']>> = {
    /**
     * function to trigger a search, this has a default 300ms debounce applied - can be passed to the `search` function of the Search component
     */
    onChange: (keyword: string) => void;

    /**
     * Reset the search query - can be passed to the `reset` function of the Search component
     */
    onReset: () => void;

    /**
     * The current search query value
     */
    value: FullSearchSchema<RegisteredRouter['routeTree']>[TName];
};

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Provides a hook to manage the NXSearch component using a query param. This hook returns the necessary props to pass to the NXSearch component.
 * 
 * @param name The name of the search param to use when storing the search value in the params.
 * @example
 * ```ts
 * const searchProps = NXSearch.useQueryParamSearch('search');
 * 
 * return (
 *   <div>
 *     <NXSearch placeholder="Search for a web hosting service" {...searchProps} />
 *   </div>
 * );
 * ```
 */
export const _useQueryParamSearch = <TName extends keyof FullSearchSchema<RegisteredRouter['routeTree']>>(
    name: TName
): UseQueryParamSearchResult<TName> => {
    /***** HOOKS *****/
    const navigate = useNavigate();
    const value = useSearch({
        strict: false,
        select: (search) => search[name]
    });

    /***** FUNCTIONS *****/
    const handleChange = useCallback(
        (keyword: string) => {
            navigate({
                to: '.',
                replace: true,
                search: (search) => ({
                    ...search,
                    [name]: keyword
                })
            });
        },
        [name]
    );

    const onReset = useCallback(() => {
        navigate({
            to: '.',
            search: (search) => _.omit(search, name),
            replace: true
        });
    }, [name]);

    const onChange = useDebounceCallback(handleChange, 300);

    /***** RETURN *****/
    return {
        value,
        onChange,
        onReset
    };
};
