/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import { useEffect } from 'react';
import { useIsVPN } from 'utilities/hooks/redux/useIsVPN';
import { createModuleStore } from 'utilities/methods/createModuleStore';
import { NXQuery } from 'utilities/query';

export const AccountModuleStore = createModuleStore(
    {
        general: {
            overview: {
                enabled: true,
                label: 'Overview',
                to: '/account/general',
                hash: 'overview'
            },
            personalDetails: {
                enabled: true,
                label: 'Personal Details',
                to: '/account/general',
                hash: 'personal-details'
            },
            contacts: {
                enabled: true,
                label: 'Contacts',
                to: '/account/general',
                hash: 'contacts'
            },
            additionalUsers: {
                enabled: true,
                label: 'Additional Users',
                to: '/account/general',
                hash: 'additional-users'
            },
            orgAccounts: {
                enabled: true,
                label: 'Organisation Accounts',
                to: '/account/general',
                hash: 'org-accounts'
            },
            accountAccess: {
                enabled: true,
                label: 'Account Access',
                to: '/account/general',
                hash: 'account-access'
            },
            referral: {
                enabled: true,
                label: 'Referrals',
                to: '/account/general',
                hash: 'referral'
            },
            close: {
                enabled: true,
                label: 'Close Account',
                to: '/account/general',
                hash: 'close'
            },
            lleyton: {
                enabled: true,
                label: 'Lleyton',
                to: '/account/general',
                hash: 'lleyton-only-fans'
            }
        },
        security: {
            password: {
                enabled: true,
                label: 'Change Password',
                to: '/account/security',
                hash: 'password'
            },
            information: {
                enabled: true,
                label: 'Security Information',
                to: '/account/security',
                hash: 'information'
            },
            twoFactor: {
                enabled: true,
                label: 'Two-Factor Authentication',
                to: '/account/security',
                hash: 'two-factor'
            },
            loginHistory: {
                enabled: true,
                label: 'Login History',
                to: '/account/security',
                hash: 'login-history'
            }
        },
        emails: {
            view: {
                enabled: true,
                label: 'View Emails',
                to: '/account/emails',
                hash: 'view'
            },
            subscriptions: {
                enabled: true,
                label: 'Subscriptions',
                to: '/account/emails',
                hash: 'subscriptions'
            }
        }
    },
    useModuleConditions
);

function useModuleConditions(args: any) {
    const store = createModuleStore.castHookProps<typeof AccountModuleStore>(args);

    /***** SELECTORS *****/
    const isVPN = useIsVPN();

    /***** QUERIES *****/
    const { data: login_account_list_data } = NXQuery.auth.accountList.useQuery();

    /***** RENDER HELPERS *****/
    const isAccountAccessEnabled = isVPN || Number(login_account_list_data?.length) > 1;

    /***** EFFECTS *****/
    useEffect(() => {
        store.batch(() => {
            store.updateEnabledWithReason(
                'general.accountAccess',
                isAccountAccessEnabled,
                'Module only enabled when on VPN or Multiple users exist for the account'
            );
        });
    });
}
