/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useRef, useState } from 'react';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type TUseTransitionalProps<PreTransition, TransitionStart, TransitionEnd, PostTransition> = {
    preTransition: PreTransition;
    transitionStart: TransitionStart;
    transitionEnd: TransitionEnd;
    postTransition: PostTransition;
};

type TTrigger = (direction: 'forward' | 'backwards') => void;

type TUseTransitionalReturnType<PreTransition, TransitionStart, TransitionEnd, PostTransition> = [
    state: PreTransition | TransitionStart | TransitionEnd | PostTransition,
    trigger: TTrigger
];

type TUseTransitional = <const PreTransition, const TransitionStart, const TransitionEnd, const PostTransition>(
    animationDuration: number,
    {
        preTransition,
        transitionStart,
        transitionEnd,
        postTransition
    }: TUseTransitionalProps<PreTransition, TransitionStart, TransitionEnd, PostTransition>,
    initial?: NoInfer<PreTransition | TransitionStart | TransitionEnd | PostTransition>
) => TUseTransitionalReturnType<PreTransition, TransitionStart, TransitionEnd, PostTransition>;

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * The useTransitional hook provides a way to manage the state of a component through a transition. This is particularly
 * useful for css transitions where you need to animate between 1 value and another. For example, to animate height,
 * you need to set a height value for both the start and end, but may not want to keep calculating the height after the animation
 * ie. height should be 'auto' post transition.
 *
 * This hook lets you provide the 4 states to accommodate for this (pre transition, transition start, transition end, post transition).
 * With this information, the returned value (and trigger) and be directly passed to a component to manage that transition.
 *
 * @note - This hook may be useful in a larger context but is currently local to the Accordion Component. This can be moved to utilities/hooks if that changes.
 */
export const useTransitional: TUseTransitional = (
    animationDuration: number,
    { preTransition, transitionStart, transitionEnd, postTransition },
    initial = preTransition
) => {
    type TState = typeof preTransition | typeof transitionStart | typeof transitionEnd | typeof postTransition;

    /***** STATE *****/
    const [state, setTransitionState] = useState<TState>(initial);
    const transitionFinishRef = useRef<number>(null);
    const transitionPostRef = useRef<ReturnType<typeof setTimeout>>(null);

    /***** FUNCTIONS *****/
    const trigger: TTrigger = (direction: 'forward' | 'backwards') => {
        if (transitionFinishRef.current) {
            cancelAnimationFrame(transitionFinishRef.current);
        }

        if (transitionPostRef.current) {
            clearTimeout(transitionPostRef.current);
        }

        if (direction === 'forward') {
            setTransitionState(transitionStart);

            transitionFinishRef.current = requestAnimationFrame(() => {
                setTransitionState(transitionEnd);

                transitionPostRef.current = setTimeout(() => {
                    setTransitionState(postTransition);
                }, animationDuration);
            });
        }

        if (direction === 'backwards') {
            setTransitionState(transitionEnd);

            transitionFinishRef.current = requestAnimationFrame(() => {
                setTransitionState(transitionStart);

                transitionPostRef.current = setTimeout(() => {
                    setTransitionState(preTransition);
                }, animationDuration);
            });
        }
    };

    return [state, trigger];
};
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
