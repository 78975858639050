import { createRoute, redirect } from "@tanstack/react-router";
import { loadAppConfig } from "App/actions/loadAppConfig";
import { pushNotification } from "components/Toast/functions";
import type { activateAccountSchema } from 'containers/login/modules/activate';
import { noop } from 'lodash';
import { RootRoute } from 'router/__root';
import { z } from 'zod';

const errorSchema = z
    .object({
        code: z.string(),
        errors: z.array(
            z.object({
                status: z.number(),
                details: z.string()
            })
        )
    })
    .transform(({ code, errors }) => ({ code, ...errors[0] }));

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const MoveServiceRoute = createRoute({
    getParentRoute: () => RootRoute,
    path: '/move-service/$moveToken',
    staticData: {
        preventLoadAppConfig: true
    },
    async loader({ params: { moveToken }, context: { NXQuery, queryClient } }) {
        // get necessary information from token for if we do fail to approve user, if this fails, the `approveMove` should also which will appropriately redirect
        await NXQuery.auth.login.checkToken.fetch().catch(noop);

        try {
            // check if the user exists
            await NXQuery.services.userExists.fetch(moveToken);

            // Attempt to approve the user
            const approveResponse = await NXQuery.services.approveMove.execute(moveToken);

            pushNotification(
                {
                    details: 'Service successfully moved into your account.',
                    status: approveResponse.status
                },
                null,
                'global'
            );
        } catch (e) {
            const { data: error } = errorSchema.safeParse(e);
            const isCheckTokenSuccess = queryClient.getQueryState(NXQuery.auth.login.checkToken.createQueryKey())?.status === 'success';
            const { is_security_detail_completed, is_user_detail_completed } = NXQuery.auth.login.checkToken.getData()?.data?.attributes ?? {};

            if (error?.code === 'ERR_ACCESS_TOKEN') error.details = 'You must be logged in to accept this invitation.';
            if (error?.code === 'ERR_INVITATION_REQUIRES_SIGN_UP') {
                if (isCheckTokenSuccess) {
                    error.details = 'Please log out to accept this invitation.';
                } else {
                    error.details = 'Please create an account to accept this invitation.';
                }
            }

            pushNotification(error, null, 'global');

            switch (error?.code) {
                case 'ERR_INVITATION_UNAUTHORISED':
                    return await loadAppConfig({ context: 'loader' });
                case 'ERR_ACCESS_TOKEN':
                    throw redirect({ to: '/login', search: { 'move-token': moveToken } });
                case 'ERR_MOVE_TOKEN_INVALID':
                    return handleTemplate('invalid', moveToken);
                case 'ERR_INVITATION_REQUIRES_SIGN_UP': {
                    // Already logged in, but the token is for a user that doesn't have an account
                    if (isCheckTokenSuccess) {
                        await loadAppConfig({
                            context: 'loader',
                            onError: () => redirect({ to: '/login', throw: true })
                        });
                    }

                    return !is_security_detail_completed || !is_user_detail_completed
                        ? handleTemplate('move-service', moveToken)
                        : handleTemplate('expired-move', moveToken);
                }
                default:
                    return handleTemplate('expired-move', moveToken);
            }
        }

        await loadAppConfig({
            context: 'loader'
        });
    }
});

function handleTemplate(template: z.infer<typeof activateAccountSchema>['template'], move_token: string): never {
    throw redirect({
        to: '/activate',
        search: (rest) => ({
            ...rest,
            move_token,
            template
        })
    });
}
