/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { connect } from 'react-redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import RequestLoader from 'components/Loaders/Request';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { ServiceSelectItemNotAllowed } from '../serviceSelectItemNotAllowed';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { DomainSearchListProps } from 'containers/services/Bulk/Components/domainSearchList/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let DomainSearchList: React.FC<DomainSearchListProps> = ({
    selectedServices,
    resultItemOnClick,
    servicesFilter,
    disabledServices,
    
    /**
     * Redux Props
     */
    domain_search_data
}) => {
    /***** FUNCTIONS *****/
    function getFilteredDomainSearchMap() {
        const filteredDomainSearchMap = domain_search_data.filter((search_data_entry) => {
            const foundSelectedService = selectedServices.find((selectedService) => selectedService.id === search_data_entry.id);
            return !foundSelectedService;
        });

        if (servicesFilter) {
            return filteredDomainSearchMap.filter(servicesFilter);
        }

        return filteredDomainSearchMap;
    }

    /***** RENDER *****/
    if (domain_search_data && domain_search_data.length > 0) {
        const filteredDomainSearchMap = getFilteredDomainSearchMap();

        return filteredDomainSearchMap.map((result, index) => {
            const { id, attributes } = result;
            const { domain } = attributes;

            function onResultItemClick() {
                resultItemOnClick(id);
            }

            const notAllowedMessage = disabledServices?.find(({ condition }) => condition(result));

            return (
                <ServiceSelectItemNotAllowed key={index} disabled={!!notAllowedMessage} content={notAllowedMessage?.content ?? null}>
                    <button className="result__item" onClick={notAllowedMessage ? null : onResultItemClick}>
                        <div className="result__title">{domain}</div>
                    </button>
                </ServiceSelectItemNotAllowed>
            );
        });
    }

    if (domain_search_data && domain_search_data.length <= 0) {
        return <div className="result__item--noresult">{`Couldn't find any matching domain names.`}</div>;
    }

    return <RequestLoader message="Fetching domains" />;
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

const mapStateToProps = (state: any) => {
    return {
        domain_search_data: state.services.domain_search_data
    };
};

DomainSearchList = connect(mapStateToProps)(DomainSearchList);

export default DomainSearchList;
