import { useIsMutating } from '@tanstack/react-query';
import { useParams } from '@tanstack/react-router';
import classNames from 'classnames';
import { SolidButton } from 'components/Buttons/SolidButton';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import RequestLoader from 'components/Loaders/Request';
import { Flex } from 'components/Utils/Flex';
import { Padding } from 'components/Utils/Padding';
import Text from 'components/Utils/Text';
import { useBrandStore } from 'config/hooks/useBrandStore';
import { useOrderingFormContext } from 'containers/orderingForm/contexts/useOrderingFormContext';
import 'containers/orderingForm/pages/registerPage/registrationButton/_registrationButton.scss';
import type { DomainSuggestions } from 'containers/orderingForm/types';
import React, { useState } from 'react';
import { NXQuery } from 'utilities/query';
import { UserPreferences } from 'utilities/UserPreferences';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type RegistrationButton = React.FC<RegistrationButtonProps>;
type RegistrationButtonProps = {
    domainAvalibility?: DomainSuggestions[];
    suggestion?: DomainSuggestions;
    isSuggestionButton?: boolean;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const RegistrationButton: RegistrationButton = ({ domainAvalibility, suggestion, isSuggestionButton }) => {
    const { domain, tld, product_id } = domainAvalibility?.[0] ?? {};
    const { domain: suggestionDomain, tld: suggestionTld, product_id: suggestionId, available: suggestionAvailable } = suggestion ?? {};

    /***** STATE *****/
    const [isAddedButtonHovered, setIsAddedButtonHovered] = useState(false);
    const [vipSitesDomain, setVipSitesDomain] = UserPreferences.useLocalStorage('CART_VIP_SITES_DOMAIN_NAME');

    /***** HOOKS *****/
    const { cartToken, setCartToken } = useOrderingFormContext();
    const { activeBrand } = useBrandStore();
    const { katanaServiceId } = useParams({ strict: false });

    /***** QUERIES *****/
    const { data: cart_data } = NXQuery.orderingForm.cart.cartId.getCart.useQuery(cartToken);
    const { mutate: createCart, isPending: isCreateCartPending } = NXQuery.orderingForm.cart.createCart.useMutation(setCartToken);
    const { mutate: addCartItems, isPending: isCartItemLoading } = NXQuery.orderingForm.cart.cartId.addItems.useMutation();
    const { mutate: removeCartItem, isPending: isRemoveCartItemLoading } = NXQuery.orderingForm.cart.cartId.removeItems.useMutation();

    const isAddItemMutating = !!useIsMutating({ mutationKey: ['addCartItem'] });
    const isCreateCartMutating = !!useIsMutating({ mutationKey: ['createCart'] });

    const isButtonDisabled = isAddItemMutating || isCreateCartMutating;

    const name = (isSuggestionButton ? `${suggestionDomain}${suggestionTld}` : `${domain}${tld}`).toLowerCase();
    const item = [
        {
            pending: true,
            billing_cycle_id: 8,
            name,
            order_type: 'register',
            id: isSuggestionButton ? suggestionId : product_id,
            auto_renew: true,
        },
    ];

    const added = cart_data?.cart?.items.some((item) => item.name === name && item.order_type === 'register');
    const itemId = cart_data?.cart?.items.find((item) => item.name === name)?.uuid;

    /***** FUNCTIONS *****/
    const handleAddItemOnClick = () => {
        if (!cartToken) {
            createCart(item, {
                onSuccess: () => {
                    if (activeBrand === 'ventra' && katanaServiceId) {
                        setVipSitesDomain(name);
                    }
                }
            });
        } else {
            addCartItems(
                { items: item, cartToken },
                {
                    onSuccess: () => {
                        setIsAddedButtonHovered(false);

                        // Only set the domain to be used for VIPsites site if there currently isn't one set
                        const isDomainSelectedForVipSites =
                            Array.isArray(cart_data?.cart?.items) && cart_data.cart.items.some((item) => item.name === vipSitesDomain);

                        if (activeBrand === 'ventra' && katanaServiceId && !isDomainSelectedForVipSites) {
                            setVipSitesDomain(name);
                        }
                    },
                    onError: (response) => {
                        if (response?.errors?.[0]?.details.includes('No token or active cart found')) {
                            createCart(item, {
                                onSuccess: () => {
                                    if (activeBrand === 'ventra' && katanaServiceId) {
                                        setVipSitesDomain(name);
                                    }
                                }
                            });
                        }
                    }
                }
            );
        }
    };

    /***** RENDER HELPERS *****/
    const renderAddedButtonContent = () => {
        if (isRemoveCartItemLoading || isCreateCartPending) {
            return <RequestLoader />;
        }

        if (isAddedButtonHovered) {
            return (
                <>
                    <PhosphorIcons.X.Bold />
                    <Text lead--0 semiBold>
                        Remove
                    </Text>
                </>
            );
        }

        return (
            <>
                <PhosphorIcons.Check.Bold />
                <Text lead--0 semiBold>
                    Added
                </Text>
            </>
        );
    };

    /***** RENDER *****/
    switch (true) {
        case added:
            return (
                <SolidButton
                    type="onClick"
                    width="auto"
                    className="registration__button--added"
                    onMouseOver={() => setIsAddedButtonHovered(true)}
                    onMouseLeave={() => setIsAddedButtonHovered(false)}
                    onClick={() => itemId && cartToken && removeCartItem({ itemId, cartToken })}
                >
                    <Padding x={3}>
                        <Flex align="center">{renderAddedButtonContent()}</Flex>
                    </Padding>
                </SolidButton>
            );

        case suggestionAvailable === false:
            return (
                <SolidButton width="auto" className="registration__button--unavailable">
                    <Padding x={3}>
                        <Flex align="center">
                            <Text lead--0 semiBold>
                                Unavailable
                            </Text>
                        </Flex>
                    </Padding>
                </SolidButton>
            );

        default:
            return (
                <SolidButton
                    type="onClick"
                    width="auto"
                    className={classNames('registration__button', {
                        'registration__button--addSuggestion': isSuggestionButton,
                    })}
                    onClick={() => handleAddItemOnClick()}
                    disabled={isButtonDisabled}
                >
                    <Padding x={3}>
                        <Flex align="center">
                            {isCartItemLoading || isCreateCartPending ? (
                                <RequestLoader width={53} />
                            ) : (
                                <>
                                    <PhosphorIcons.Plus.Bold />
                                    <Text lead--0 semiBold>
                                        Add
                                    </Text>
                                </>
                            )}
                        </Flex>
                    </Padding>
                </SolidButton>
            );
    }
};
