import classNames from 'classnames';
import { IconifyIcon } from 'components/Icons/Iconify';
import type { PhosphorIconNamespace } from 'components/Icons/Phosphor/types';
import React from 'react';
import './_Chevron.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Props = {
    /**
     * Given a boolean "true" means open, "false" means closed
     */
    state: 'up' | 'down' | boolean;
    bold?: boolean;
} & Omit<PhosphorIconNamespace.Props, 'icon'>;

const getBooleanState = (state: boolean) => (state ? 'up' : 'down');

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Functional Chevron Icon with arrow transition
 */
const Chevron: React.FC<Props> = (props) => {
    const { className, state, bold } = props;

    const currentState = typeof state === 'boolean' ? getBooleanState(state) : state;
    
    /***** RENDER HELPERS *****/
    const chevronClassNames = classNames(
        'Chevron',
        `Chevron--${currentState}`,
        {
            'Chevron--bold': bold
        },
        className
    );
    
    /***** RENDER *****/
    return <IconifyIcon icon={bold ? 'ph:caret-up-bold' : 'ph:caret-up'} {...{ ...props, className: chevronClassNames }} />;
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default Chevron;
