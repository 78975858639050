import { _ValidationMessage as ValidationMessage } from 'components/Form/ValidationMessage/_ValidationMessage';
import React from 'react';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type ValidationMessageHookFormDefault = React.FC<{
    isTouched: boolean;
    error?: string;
    validated?: {
        valid: boolean;
        value: string;
    };
}>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _ValidationMessageHookFormDefault: ValidationMessageHookFormDefault = ({ isTouched, error, validated }) => {
    /***** FUNCTIONS *****/
    function getMessage() {
        if (error) {
            return error;
        }
        if (validated?.value) {
            return validated.value;
        }
        return '';
    }

    /***** RENDER HELPERS *****/
    const message = getMessage();

    const warn = (error && !validated) || (validated && !validated.valid);

    const open = Boolean(isTouched && message);

    /***** RENDER *****/
    return (
        <ValidationMessage confirm={validated?.valid} warn={warn} open={open}>
            {message}
        </ValidationMessage>
    );
};
