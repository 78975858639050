/**
 *
 * Accessibility
 * https://www.w3.org/TR/wai-aria-practices-1.2/#dialog_modal
 *
 */
import classNames from 'classnames';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import { registerModal, unregisterModal } from 'components/Lightboxes/Modal/modalControl';
import type { ModalNamespace } from 'components/Lightboxes/Modal/types';
import _ from 'lodash';
import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useUUID } from 'utilities/hooks/useUUID';
import './__Modal.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type _Modal = React.FC<ModalNamespace.Props>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _Modal: _Modal = (props) => {
    const {
        ariaLabel,
        children,
        backgroundChildren,
        className,
        initialFocus,
        onClose = _.noop,
        isOpen,
        preButtonContent,
        removeCloseButton,
        preventScrollBlock,
        overlayRef
    } = props;

    /***** HOOKS *****/
    const id = useUUID();
    const overlayModalRef = useRef<HTMLDivElement | null>(null);
    const _overlayRef = useRef<HTMLDivElement | null>(null);
    const closeButtonRef = useRef<HTMLButtonElement | null>(null);

    const windowScrollYTrackerUseRef = useRef(0);

    function getModalRegistrationRefData(): ModalNamespace.StackRefEntry {
        return {
            id,
            overlayModalRef,
            closeButtonRef,
            initialFocus,
            onClose,
            scrollPosition: windowScrollYTrackerUseRef.current,
            preventScrollBlock
        };
    }
    const modalRegistrationRef = useRef(getModalRegistrationRefData());

    function refreshModalRegistrationRef() {
        modalRegistrationRef.current = getModalRegistrationRefData();
    }

    /***** FUNCTIONS *****/
    function register() {
        registerModal(modalRegistrationRef.current);
    }

    function unregister() {
        unregisterModal(modalRegistrationRef.current);
    }

    /***** EFFECTS *****/
    useEffect(() => {
        windowScrollYTrackerUseRef.current = window.scrollY;

        if (isOpen) {
            refreshModalRegistrationRef();
            register();
        } else {
            unregister();
        }
    }, [isOpen]);

    useEffect(() => {
        if (modalRegistrationRef.current.initialFocus !== initialFocus) {
            modalRegistrationRef.current.initialFocus = initialFocus;
            register();
        }
    }, [initialFocus]);

    useEffect(() => {
        if (isOpen) {
            refreshModalRegistrationRef();
            register();
        }
        return () => {
            unregister();
        };
    }, []);

    /***** RENDER HELPERS *****/
    function renderModalPortal() {
        return (
            <div
                key={id}
                ref={(ref) => {
                    _overlayRef.current = ref;
                    if (overlayRef) {
                        overlayRef.current = ref;
                    }
                }}
                data-testid="overlay-modal"
                className={classNames(`Overlay`, className)}
            >
                {backgroundChildren}
                <div ref={overlayModalRef} className="OverlayModal" aria-label={ariaLabel} aria-modal="true" role="dialog">
                    {children}
                    <div className="Overlay__closeWrapper">
                        {preButtonContent ? preButtonContent : ''}
                        {!removeCloseButton && (
                            <button ref={closeButtonRef} type="button" className="Overlay__close" onClick={(e) => onClose?.(e)}>
                                <PhosphorIcons.X.Bold size={28} />
                            </button>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    /***** RENDER *****/
    return isOpen ? ReactDOM.createPortal(renderModalPortal(), document.body, id) : '';
};
