import { BaseButton } from 'components/Buttons/_BaseButton';
import type { BaseButtonNamespace } from 'components/Buttons/_BaseButton/types';
import React from 'react';
import './_SolidButton.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * that renders a button with the SolidButton variant
 */
export const _SolidButton: React.FC<BaseButtonNamespace.BaseProps> = (props) => {
    /***** RENDER *****/
    return <BaseButton variant="SolidButton" {...props} />;
};
