import Tooltip from 'components/Tooltip';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type AdvancedListLabel = React.FC<{
    children: React.ReactNode;
    tooltip?: string;
}>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _AdvancedListLabel: AdvancedListLabel = ({ children, tooltip }) => {
    return (
        <div className="form__title">
            {!!children && (
                <label>
                    {children}
                    {!!tooltip && <Tooltip info={tooltip} />}
                </label>
            )}
        </div>
    );
};
