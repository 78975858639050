import { PhosphorIcons } from 'components/Icons/Phosphor';
import { Flex } from 'components/Utils/Flex';
import 'containers/orderingForm/checkIcon/_checkIcon.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Check = React.FC<CheckProps>;
type CheckProps = {
    size: number;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const Check: Check = ({ size }) => {
    /***** RENDER *****/
    return (
        <Flex justify="center" align="center" className="check__container">
            <PhosphorIcons.Check.Bold white size={size} />
        </Flex>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
