import { queryOptions, useQueries, useQuery } from '@tanstack/react-query';
import { katanaQueryKeys } from 'containers/katana/queries/katanaQueryKeys';
import type { KatanaNamespace } from 'containers/katana/types';
import { KATANA_API } from 'utilities/api/katana';

type Params = Parameters<typeof KATANA_API.katana.service_id.preview.page.POST>[0];

function createQueryKey(params: Params) {
    return katanaQueryKeys.katana.service.ID.preview.page(params);
}

function createQueryOptions({ serviceID, pageID, attributes }: Params, enabled = true) {
    return queryOptions({
        queryKey: createQueryKey({ serviceID, pageID, attributes }),
        queryFn: () => KATANA_API.katana.service_id.preview.page.POST({ serviceID, pageID, attributes }),
        enabled: Boolean(serviceID) && Boolean(pageID) && enabled,
        select: (data) => {
            if (typeof data === 'string') {
                return data;
            }
        },
        gcTime: 0,
    });
}

type Page = Omit<KatanaAPI.Katana.ServiceID.Preview.Page.POST.Params, 'serviceID'>;
type UseQueriesParam = {
    serviceID: KatanaNamespace.ServiceId;
    pages: Page[];
};
function _useQueries({ serviceID, pages }: UseQueriesParam) {
    return useQueries({
        queries: pages.map(({ pageID, attributes }) =>
            createQueryOptions({
                serviceID,
                pageID,
                attributes,
            })
        ),
        combine(results) {
            return results.reduce(
                (acc, result, index) => {
                    const pageAttributes = pages[index];
                    if (!pageAttributes) return acc;
                    const pageID = pageAttributes.pageID;
                    if (!pageID) return acc;
                    acc[pageID] = result;
                    return acc;
                },
                {} as Record<KatanaNamespace.PageId, KatanaAPI.Katana.ServiceID.Preview.Page.POST.ReturnType>
            );
        },
    });
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useQuery(data: Params, enabled?: boolean) {
    return useQuery(createQueryOptions(data, enabled));
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
export const getPagePreview = Object.freeze({
    useQuery: _useQuery,
    useQueries: _useQueries,
    createQueryKey,
});
