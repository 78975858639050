import { focusableElementsSelector } from 'components/Lightboxes/Modal/consts';
import type { ModalNamespace } from 'components/Lightboxes/Modal/types';

const modalStackRef: ModalNamespace.StackRefEntry[] = [];

function blockBodyScroll() {
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;

    // Stop body from being scrollable when modal is open
    document.body.style.overflow = 'hidden';
    // Stop body from scrolling on older versions of ios
    document.body.style.position = 'fixed';
    // Stop horizontal shift when modal closes
    document.body.style.width = `calc(100% - ${scrollbarWidth}px)`;
    // This is to get rid of the empty space at the bottom of iPhone screen when url bar is minimized
    document.documentElement.style.height = `${window.innerHeight}px`;
}

function unblockBodyScroll(modalRegistrationRef: ModalNamespace.StackRefEntry) {
    // Reset all the style properties that were added to the body in bodyScrollBlock
    document.body.style.removeProperty('overflow');
    document.body.style.removeProperty('position');
    document.body.style.removeProperty('width');
    document.body.style.removeProperty('top');
    document.documentElement.style.removeProperty('height');
    window.scrollTo(0, modalRegistrationRef.scrollPosition);
}

function unTrapFocus() {
    document.removeEventListener('keydown', focusTrapOnKeyDown);
}

function focusTrapOnKeyDown(e) {
    const currentFocusRef = modalStackRef[modalStackRef.length - 1];

    if (e.key === 'Escape') {
        return currentFocusRef.onClose();
    }

    const overlayModalRef = currentFocusRef?.overlayModalRef?.current;
    if (!overlayModalRef) {
        return;
    }

    const focusableEls = overlayModalRef.querySelectorAll(focusableElementsSelector);
    const index = Array.from(focusableEls).indexOf(e.target);

    if (e.key === 'Tab' && index >= 0) {
        if (index === focusableEls.length - 1 && !e.shiftKey) {
            // Recycle the focus back to the start of the list of focusable elements within the modal
            e.preventDefault();
            focusableEls[0].focus();
        } else if (index === 0 && e.shiftKey) {
            // Recycle from first to last if its shift + tab
            e.preventDefault();
            focusableEls[focusableEls.length - 1].focus();
        }
    }
}

function trapFocus() {
    const currentFocusRef = modalStackRef[modalStackRef.length - 1];
    const overlayModalRef = currentFocusRef?.overlayModalRef?.current;
    const closeButtonRef = currentFocusRef?.closeButtonRef?.current;
    if (!overlayModalRef) {
        return;
    }

    const focusableEls = overlayModalRef.querySelectorAll(focusableElementsSelector);

    const getElToFocus = () => {
        const { initialFocus } = currentFocusRef;
        if (initialFocus === 'close-button') {
            return closeButtonRef;
        }

        const focusableElsArray = Array.from(focusableEls);
        return initialFocus?.current && focusableElsArray.includes(initialFocus.current) ? initialFocus.current : focusableEls[0];
    };

    // Focus the element passed as initialFocus props, or first focusable element in the modal
    const elToFocus = getElToFocus();
    if (elToFocus?.focus) {
        // A few light-boxes strangely don't focus the focusable el on mount, even though the ref is valid. This somehow makes it work.
        setTimeout(() => {
            elToFocus.focus();
        });
    }

    // Catch and modify keydown events to make sure focus stays within the modal
    document.addEventListener('keydown', focusTrapOnKeyDown);
}

function onClose(modalRegistrationRef: ModalNamespace.StackRefEntry) {
    // TODO: Need to add a way to determine the final scroll because if a modal is nested inside another modal, it uses FIFO to apply the close action like it does the open action, it needs FILO. So the scroll position is not always correct.
    if (!modalStackRef.length) {
        // if (!modalRegistrationRef?.preventScrollBlock) {
        unblockBodyScroll(modalRegistrationRef);
        // }
        unTrapFocus();
    }
}

function onOpen(modalRegistrationRef: ModalNamespace.StackRefEntry) {
    if (modalStackRef.length === 1) {
        if (!modalRegistrationRef?.preventScrollBlock) {
            blockBodyScroll();
        }
        trapFocus();
    }
}

export function registerModal(modalRegistrationRef: ModalNamespace.StackRefEntry) {
    const stackItem = modalStackRef.find((stackItem) => stackItem.id === modalRegistrationRef.id);
    const index = modalStackRef.indexOf(stackItem);
    if (index !== -1) {
        modalStackRef[index] = modalRegistrationRef;
    } else {
        modalStackRef.push(modalRegistrationRef);
        onOpen(modalRegistrationRef);
    }
}

export function unregisterModal(modalRegistrationRef: ModalNamespace.StackRefEntry) {
    const stackItem = modalStackRef.find((stackItem) => stackItem.id === modalRegistrationRef.id);
    const index = modalStackRef.indexOf(stackItem);
    if (index !== -1) {
        modalStackRef.splice(index, 1);
        onClose(stackItem);
    }
}
