import _ from 'lodash';
import type { NXQueryUtils } from 'utilities/methods/tanstack/types';
import type { NXUtils } from 'utilities/NXUtils';

// prettier-ignore
/**
 * Creates an optimistic merge method for modifying query cache data by recursively merging the provided value into a specific path.
 *
 * the query data at time of setting the new value can be restored using the returned restore method, useful if an error occurs or the filtered data needs to be reverted.
 */
export function createOptimisticMergeMethod<
	TParams extends any,
	TData extends NXQueryUtils.ApiData
>(
    setQueryData: NXQueryUtils.SetQueryDataMethod<TParams, TData>
) {
    function optimisticMerge<TKey extends NXUtils.Path<TData>>(
        params: TParams,
        mergePath: TKey,
        valueToMergePathWith: NXUtils.RecursivePartial<NXUtils.Choose<TData, TKey>>
    ) {
        let previousData: TData | undefined;

        setQueryData(params, (oldData) => {
            if (oldData?.status !== 200) {
                return;
            }

            const clonedData = _.cloneDeep(oldData);
            if (!_.has(clonedData, mergePath)) {
                return;
            }

            previousData = oldData;

            const mergedValue = _.merge(_.get(clonedData, mergePath), valueToMergePathWith);
            _.set(clonedData, mergePath, mergedValue);
            return clonedData;
        });

        return {
            restore: () => {
                if (!previousData) {
                    return;
                }
                setQueryData(params, () => previousData);
            }
        };
    }

    return optimisticMerge;
}
