import { Store, useStore } from '@tanstack/react-store';
import { _NXBoxTopAccordion as NXBoxTopAccordion } from 'components/NXBox/TopAccordion';
import type { NXBoxTopAccordionNamespace } from 'components/NXBox/TopAccordion/types';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Props = NXBoxTopAccordionNamespace.Props & {
    stateKey?: string;
};
type _NXBoxTopAccordionWithSessionState = React.FC<Props>;

const stateHolder = new Store<Record<string, boolean>>({});

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _NXBoxTopAccordionWithSessionState: _NXBoxTopAccordionWithSessionState = ({ stateKey, isOpenDefault, ...props }) => {
    /***** HOOKS *****/
    const key = stateKey ? stateKey : `${props.title}${props.description}`;
    const _isDefaultOpen = useStore(stateHolder, (state) => state[key] ?? isOpenDefault);

    /***** RENDER *****/
    return (
        <NXBoxTopAccordion
            {...props}
            isOpenDefault={_isDefaultOpen}
            onEmitState={(isOpen) => stateHolder.setState((currentState) => ({ ...currentState, [key]: isOpen }))}
        />
    );
};
