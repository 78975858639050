import type { KatanaNamespace } from 'containers/katana/types';
import { nxAxios } from 'store/nxAxios';

export namespace KATANA_API_V2 {
    export namespace site {
        export namespace service_id {
            export namespace preview {
                export namespace page {
                    export namespace page_id {
                        export namespace GET {
                            export type Params = {
                                serviceId: KatanaNamespace.ServiceId;
                                pageId: KatanaNamespace.PageId;
                            };
                        }
                    }
                }
                export namespace section {
                    export namespace section_id {
                        export namespace GET {
                            export type Params = {
                                serviceId: KatanaNamespace.ServiceId;
                                sectionId: KatanaNamespace.SectionId;
                            };
                        }
                    }

                    export namespace new_section {
                        export namespace GET {
                            export type Params = {
                                serviceId: KatanaNamespace.ServiceId;
                                sectionDefinitionId: KatanaNamespace.SectionDefinitionId;
                                attributes?: {
                                    properties?: KatanaNamespace.PropertiesSignature;
                                };
                            };
                        }
                    }
                }
            }
        }
    }
}

export const KATANA_API_V2 = {
    site: {
        service_id: {
            preview: {
                page: {
                    page_id: {
                        GET: ({
                            serviceId,
                            pageId,
                        }: KATANA_API_V2.site.service_id.preview.page.page_id.GET.Params): Artah.Katana.V2.Site.ID.Preview.Page.ID.GET.NXReturnType => {
                            return nxAxios.get(`/api/katana/v2/site/${serviceId}/preview/page/${pageId}`);
                        },
                    },
                },
                section: {
                    section_id: {
                        GET: ({
                            serviceId,
                            sectionId,
                        }: KATANA_API_V2.site.service_id.preview.section.section_id.GET.Params): Artah.Katana.V2.Site.ID.Preview.Section.ID.GET.NXReturnType => {
                            return nxAxios.get(`/api/katana/v2/site/${serviceId}/preview/section/${sectionId}`);
                        },
                    },

                    new_section: {
                        GET: ({
                            serviceId,
                            sectionDefinitionId,
                            attributes = {},
                        }: KATANA_API_V2.site.service_id.preview.section.new_section.GET.Params): Artah.Katana.V2.Site.ID.Preview.Section.New.ID.GET.NXReturnType => {
                            return nxAxios.get(`/api/katana/v2/site/${serviceId}/preview/section/new/${sectionDefinitionId}`, {
                                data: attributes,
                            });
                        },
                    },
                },
            },
        },
    },
};
