/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { createRoute, redirect } from '@tanstack/react-router';

/**********************************************************************************************************
 *   ROUTER IMPORT
 **********************************************************************************************************/
import { AppRoute } from 'App';

/*   ACTIONS
 *****************************************************/
import { logoutApp } from 'components/Header/action';
import { RootRoute } from 'router/__root';
import { isAuthenticated } from 'router/utils/isAuthenticated/isAuthenticated';

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const ResetPasswordRoute = createRoute({
    getParentRoute: () => RootRoute,
    path: '/reset-password',
    async beforeLoad() {
        if (isAuthenticated()) {
            await logoutApp();
        }

        throw redirect({
            to: '/login'
        });
    }
});

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const ForgotRoute = createRoute({
    getParentRoute: () => AppRoute,
    path: '/forgot',
    async beforeLoad() {
        if (isAuthenticated()) {
            await logoutApp();
        }

        throw redirect({
            to: '/login',
            search: { forgot: true }
        });
    }
});
