import { OutlineButton } from 'components/Buttons/OutlineButton';
import { Modal } from 'components/Lightboxes/Modal';
import { UnsuspensionForm } from 'containers/services/forms/unsuspensionForm';
import { useToggle } from 'usehooks-ts';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const UnsuspendServiceButton = () => {
    /***** STATE *****/
    const [isLightboxOpen, toggleIsLightboxOpen, setIsLightboxOpen] = useToggle(false);

    /***** RENDER HELPERS *****/
    const closeLightbox = () => setIsLightboxOpen(false);

    /***** RENDER *****/
    return (
        <>
            <OutlineButton onClick={toggleIsLightboxOpen} size="medium">
                Unsuspend
            </OutlineButton>
            <Modal isOpen={isLightboxOpen} onClose={closeLightbox} ariaLabel="Unsuspend Web Hosting Service">
                <Modal.Title>Unsuspend Web Hosting Service</Modal.Title>
                <UnsuspensionForm onClose={closeLightbox} />
            </Modal>
        </>
    );
};
