/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_NXBoxDescription.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type NXBoxDescriptionProps = {
    description: React.ReactNode;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const NXBoxDescription: React.FC<NXBoxDescriptionProps> = ({ description }) => {
    /***** RENDER *****/
    return (
        <Text size--s secondary className="NXBoxDescription">
            {description}
        </Text>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
