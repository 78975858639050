import { _ValidationMessage } from 'components/Form/ValidationMessage/_ValidationMessage';
import { _ValidationMessageHookFormDefault as ValidationMessageHookFormDefault } from 'components/Form/ValidationMessage/wrappers/hookForm/Default';
import { _ValidationMessageHookFormKatanaProperty } from 'components/Form/ValidationMessage/wrappers/hookForm/KatanaProperty';
import { _ValidationMessageReduxFormDefault as ValidationMessageReduxFormDefault } from 'components/Form/ValidationMessage/wrappers/reduxform/Default';
import { _ValidationMessageReduxFormFieldArray as ValidationMessageReduxFormFieldArray } from 'components/Form/ValidationMessage/wrappers/reduxform/FieldArray';

export const ValidationMessage = Object.assign(_ValidationMessage, {
    ReduxForm: {
        Default: ValidationMessageReduxFormDefault,
        FieldArray: ValidationMessageReduxFormFieldArray
    },
    HookForm: Object.assign(ValidationMessageHookFormDefault, {
        KatanaProperty: _ValidationMessageHookFormKatanaProperty
    })
});
