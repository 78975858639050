import axios from 'axios';
import { notificationScopes } from 'components/Toast/consts';
import { pushNotification } from 'components/Toast/functions';
import { LOGIN_INITIAL_STATE } from 'containers/login/actionTypes';
import { queryClient } from 'store/queryClient';
import store from 'store/store';
import { API as ACCOUNT } from 'utilities/api/account';
import { API as DASHBOARD } from 'utilities/api/dashboard';
import { getDataFromSuccessResponse, getErrorFromFailResponse } from 'utilities/methods/commonActions';
import {
    APP_ALERT_BANNER_REQUEST,
    APP_ALERT_BANNER_SUCCESS,
    APP_CHANGE_LIVECHAT_VISIBILITY,
    APP_COUNTRIES_ERROR,
    APP_COUNTRIES_REQUEST,
    APP_COUNTRIES_SUCCESS,
    APP_DISPLAY_STATES,
    APP_LOCAL_STATES_ERROR,
    APP_LOCAL_STATES_REQUEST,
    APP_LOCAL_STATES_SUCCESS
} from './actionTypes';

/**********************************************************************************************************
 *   ACTIONS
 **********************************************************************************************************/
export const getCountries = () => {
    const { dispatch } = store;
    dispatch({
        type: APP_COUNTRIES_REQUEST
    });

    ACCOUNT.account.GET.country()
        .then((response) => {
            const app_countries_data = getDataFromSuccessResponse(response);

            dispatch({
                type: APP_COUNTRIES_SUCCESS,
                app_countries_data
            });
        })
        .catch((err) => {
            const app_countries_error = getErrorFromFailResponse(err);
            pushNotification(app_countries_error);

            dispatch({
                type: APP_COUNTRIES_ERROR
            });
        });
};

export const getLocalStates = () => {
    const { dispatch } = store;
    dispatch({
        type: APP_LOCAL_STATES_REQUEST
    });
    ACCOUNT.account.GET.states()
        .then((response) => {
            const rawStatesData = getDataFromSuccessResponse(response);
            const processedOptions = {
                au: [],
                nz: []
            };
            rawStatesData.forEach((item) => {
                const {
                    attributes: { country_code, state, state_code }
                } = item;

                processedOptions[country_code.toLowerCase()]?.push({
                    label: state,
                    value: state_code
                });
            });

            const app_local_states_data = {
                rawStatesData,
                processedOptions
            };
            dispatch({
                type: APP_LOCAL_STATES_SUCCESS,
                app_local_states_data
            });
        })
        .catch(() => {
            dispatch({
                type: APP_LOCAL_STATES_ERROR
            });
        });
};

export const updateViewport = () => {
    const { dispatch } = store;

    const currentWidth = window.innerWidth;
    const xs = 480;
    const sm = 768;
    const md = 1024;
    const lg = 1440;
    let viewport = undefined;
    if (currentWidth <= xs) {
        viewport = 'xs';
    } else if (currentWidth > xs && currentWidth <= sm) {
        viewport = 'sm';
    } else if (currentWidth > sm && currentWidth <= md) {
        viewport = 'md';
    } else if (currentWidth > md && currentWidth <= lg) {
        viewport = 'lg';
    } else if (currentWidth > lg) {
        viewport = 'xl';
    }

    dispatch({
        type: APP_DISPLAY_STATES,
        app_viewport: viewport
    });
};

/**
 * @param {import("@tanstack/react-router").RegisteredRouter} router
 */
export const interceptInvalidToken = (router) => {
    const { dispatch } = store;

    /**
     * This function is needed to make sure that the interceptor doesn't overwrite notifications that are more important. i.e. we don't need to show "Permission Denied", when we're already dispatching a notification about the reason why the permission is denied like we do with approve additional user
     */
    function checkAllowErrorOverride(error) {
        /**
         * First remove any potential query parameters from the url
         */
        const url = error?.config?.url?.split('?')[0];

        /**
         * Can add additional switch inside the case statement to allow for `error.config.method` "get/post" etc...
         */
        switch (url) {
            case '/api/user/service-move/approve':
            case '/api/user/additional-user/approve':
                return false;
            default:
                return true;
        }
    }

    axios.interceptors.response.use(undefined, (error) => {
        const intercept_invalid_token_error = getErrorFromFailResponse(error);

        if (
            intercept_invalid_token_error.status === 401 &&
            intercept_invalid_token_error.code === 'ERR_ACCESS_TOKEN' &&
            checkAllowErrorOverride(error)
        ) {
            dispatch({
                type: LOGIN_INITIAL_STATE
            });

            queryClient.clear();
            router.navigate({
                to: '/login',
                replace: true
            });

            if (intercept_invalid_token_error.details === 'Permission denied. Session Expired.') {
                pushNotification(intercept_invalid_token_error, null, notificationScopes.GLOBAL);
            }
        }

        return Promise.reject(error);
    });
};

export const checkAlertBanner = () => {
    const { dispatch } = store;

    dispatch({
        type: APP_ALERT_BANNER_REQUEST
    });

    return DASHBOARD.dashboard.get
        .alert()
        .then((response) => {
            const app_alert_banner_data = getDataFromSuccessResponse(response);
            dispatch({
                type: APP_ALERT_BANNER_SUCCESS,
                app_alert_banner_data
            });
            return response;
        })
        .catch(() => {});
};

export const livechatVisibilityOptions = {
    MAXIMIZED: { visibility: 'maximized' },
    MINIMIZED: { visibility: 'minimized' }
};

export const changeLivechatVisibility = ({ visibility } = livechatVisibilityOptions.MINIMIZED) => {
    const { dispatch } = store;
    dispatch({
        type: APP_CHANGE_LIVECHAT_VISIBILITY,
        app_livechat_visibility: visibility
    });
};
