import { createBaseQueryKey } from 'utilities/methods/tanstack/createBaseQueryKey';

/**********************************************************************************************************
 *   QUERY KEY TREE START
 **********************************************************************************************************/
export const hostingQueryKeys = {
    hosting: Object.assign(() => createBaseQueryKey(['hosting']), {
        list: Object.assign(() => [...hostingQueryKeys.hosting(), 'list'] as const, {
            filters: (filters?: any) => [...hostingQueryKeys.hosting.list(), filters] as const
        }),
        search: Object.assign(() => [...hostingQueryKeys.hosting(), 'search'] as const, {
            keyword: (keyword?: any) => [...hostingQueryKeys.hosting.search(), keyword as 'KEYWORD'] as const
        }),
        information: Object.assign((serviceId?: any) => [...hostingQueryKeys.hosting(), 'information', serviceId] as const, {})
    })
};
