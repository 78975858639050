import Anchor from 'components/Anchor';
import { PasswordChecker } from 'components/PasswordChecker';
import { increasedStrengthPasswordRequirements, validateIncreasedStrengthRequirements } from 'components/PasswordChecker/default';
import { withBrandStore } from 'config/hooks/useBrandStore';
import { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Field, change, formValueSelector, reduxForm } from 'redux-form';
import {
    RenderField,
    RenderRevealPasswordField,
    RenderSelectField,
    renderButton,
    requiredFieldValidation,
    validateConfirmValidation
} from 'utilities/methods/form';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class LoginSecurity extends Component {
    componentDidMount() {
        const { currentValues, change } = this.props;

        if (currentValues) {
            // Keep the old values if the user goes back a step in the form
            change('password', currentValues.password);
            change('password_confirmation', currentValues.password_confirmation);
            change('question_id', currentValues.security_question_id);
            change('answer', currentValues.security_answer);
        }
    }

    render() {
        const { pristine, submitting, valid, options, handleSubmit, password_input, template, termsUrl } = this.props;

        const passwordField = () => {
            return (
                <Fragment>
                    <div className="form__row">
                        <div className="form__column full">
                            <Field
                                label="Password"
                                name="password"
                                component={RenderRevealPasswordField}
                                validate={[requiredFieldValidation, validateIncreasedStrengthRequirements]}
                                className="form__textfield"
                                parent={this}
                            />
                        </div>
                    </div>
                    <div className="form__row">
                        <div className="form__column full">
                            <Field
                                label="Confirm Password"
                                name="password_confirmation"
                                component={RenderRevealPasswordField}
                                validate={[requiredFieldValidation, validateConfirmValidation]}
                                className="form__textfield"
                            />
                        </div>
                    </div>
                    <div className="form__row">
                        <div className="form__column full">
                            <PasswordChecker input={password_input} requirements={increasedStrengthPasswordRequirements} />
                        </div>
                    </div>
                </Fragment>
            );
        };

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <form className="loginSecurity__form" onSubmit={handleSubmit}>
                {['additional-user', 'move-service'].includes(template) && passwordField()}
                <div className="form__row">
                    <div className="form__column full">
                        <Field
                            label="Security Question"
                            name="question_id"
                            component={RenderSelectField}
                            type="select"
                            validate={[requiredFieldValidation]}
                            className="form__dropdown"
                            options={options}
                        />
                    </div>
                </div>
                <div className="form__row">
                    <div className="form__column full">
                        <Field
                            component={RenderField}
                            label="Security Answer"
                            name="answer"
                            type="text"
                            placeholder=""
                            validate={[requiredFieldValidation]}
                            className="form__textfield"
                        />
                    </div>
                </div>
                <div className="form__row">
                    <div className="form__column full">
                        <div className="form__description">
                            You agree to our{' '}
                            <Anchor target="_blank" href={termsUrl}>
                                Terms, Policies and Agreements
                            </Anchor>{' '}
                            by updating your security details.
                        </div>
                    </div>
                </div>
                <div className="form__row">
                    <div className="form__column full">{renderButton(pristine, submitting, valid, `Continue`)}</div>
                </div>
            </form>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
LoginSecurity = reduxForm({
    form: 'loginSecurity',
    enableReinitialize: true
})(LoginSecurity);

const mapStateToProps = (state) => {
    const selector = formValueSelector(`loginSecurity`);
    const password_input = selector(state, 'password');

    const initialValues = state.login.login_security_information_data
        ? { ...state.login.login_security_information_data, question: state.login.login_security_information_data.question_id }
        : {};

    return {
        initialValues,
        password_input,
        login_security_information_data: state.login.login_security_information_data
    };
};

const mapDispatchToProps = {
    change
};

LoginSecurity = withBrandStore(connect(mapStateToProps, mapDispatchToProps)(LoginSecurity));

export default LoginSecurity;
