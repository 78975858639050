import { SUPER_USER_ACTION_ITEM_KEYS } from 'components/StaffMenu/SuperUser/actionKeys';
import { useActiveSuperUserItems } from 'components/StaffMenu/SuperUser/hooks/useActiveSuperUserItems';
import { UserPreferences } from 'utilities/UserPreferences';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * to determine the new super user actions, we need to compare the active super user items with the viewed items
 * and return the items that are active but not viewed
 */
export function useNewSuperUserActions() {
    /***** HOOKS *****/
    const activeSuperUserItems = useActiveSuperUserItems();

    const [viewedActionItemKeys = []] = UserPreferences.useLocalStorage('SUPER_USER_ACTION_VIEWED');
    const [isShowingAllActions] = UserPreferences.useLocalStorage('SUPER_USER_SHOW_ALL_ACTIONS');

    /***** HOOK RESULTS *****/
    if (isShowingAllActions) {
        return SUPER_USER_ACTION_ITEM_KEYS.filter((key) => !viewedActionItemKeys.includes(key));
    }
    return activeSuperUserItems.filter((key) => !viewedActionItemKeys.includes(key));
}
