/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { QueryClient, type DefaultOptions } from '@tanstack/react-query';
import { MINUTE } from 'utilities/consts';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/

/**
 * Initialise React Query Client
 */
export let queryClient: QueryClient;

/**
 * Initialise React Query Client - This is structured like this so that we can have dependencies
 */
export const initialiseQueryClient = (mutationOptions: DefaultOptions['mutations'] = {}) => {
    queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
                retry: false,
                
                /**
                 * By Default the delay should cap out at 6 times exponential, around 32 seconds per retry attempt
                 * @param {number} attempt
                 */
                retryDelay: (attempt) => Math.min(500 * 2 ** attempt, 500 * 2 ** 6),
                select: (data: any) => data?.data ?? null,
                staleTime: MINUTE * 5
            },
            mutations: mutationOptions
        }
    });
};
