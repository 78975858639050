import { BaseButton } from 'components/Buttons/_BaseButton';
import type { BaseButtonNamespace } from 'components/Buttons/_BaseButton/types';
import React from 'react';
import './_OutlineButton.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * that renders a button with the SolidButton variant
 */
export const _OutlineButton: React.FC<BaseButtonNamespace.BaseProps> = (props) => {
    /***** RENDER *****/
    return <BaseButton variant="OutlineButton" {...props} />;
};
