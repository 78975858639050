import { Tooltip } from '@mui/material';
import classNames from 'classnames';
import Anchor from 'components/Anchor';
import { Input } from 'components/Form/Input';
import { IconifyIcon } from 'components/Icons/Iconify';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import { SUPER_USER_ACTION_ITEMS } from 'components/StaffMenu/SuperUser/actionKeys';
import { SuperUserItem } from 'components/StaffMenu/SuperUser/components/SuperUserItem/SuperUserItem';
import { useActiveSuperUserItems } from 'components/StaffMenu/SuperUser/hooks/useActiveSuperUserItems';
import { useEnabledSuperUserItems } from 'components/StaffMenu/SuperUser/hooks/useEnabledSuperUserItems';
import { useInactiveSuperUserItems } from 'components/StaffMenu/SuperUser/hooks/useInactiveSuperUserItems';
import { useNewSuperUserActions } from 'components/StaffMenu/SuperUser/hooks/useNewSuperUserActions';
import type { SuperUserNamespace } from 'components/StaffMenu/SuperUser/types';
import { useStaffMenu } from 'components/StaffMenu/useStaffMenu';
import { Border } from 'components/Utils/Border';
import { Flex } from 'components/Utils/Flex';
import Gradient from 'components/Utils/Gradient';
import { Hr } from 'components/Utils/Hr';
import Text from 'components/Utils/Text';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useBoolean } from 'usehooks-ts';
import { UserPreferences } from 'utilities/UserPreferences';
import './_SuperUser.scss';

const actionKeySearchFilter = (searchKeyword: string) => (key: SuperUserNamespace.Key) => {
    const lowerKey = key.toLowerCase();
    const lowerSearchKeyword = searchKeyword.toLowerCase();
    if (lowerKey.includes(lowerSearchKeyword)) return true;
    const lowerActionText =
        typeof SUPER_USER_ACTION_ITEMS[key] === 'string'
            ? SUPER_USER_ACTION_ITEMS[key].toLowerCase()
            : Object.values(SUPER_USER_ACTION_ITEMS[key]).join(' ').toLowerCase();
    return lowerActionText.includes(lowerSearchKeyword);
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const SuperUser = () => {
    /***** STATE *****/
    const { value: isSearchOpen, toggle: toggleIsSearchOpen } = useBoolean(false);
    const [searchKeyword, setSearchKeyword] = useState('');

    /***** HOOKS *****/
    const activeSuperUserItems = useActiveSuperUserItems();
    const inactiveSuperUserItems = useInactiveSuperUserItems();
    const newActions = useNewSuperUserActions();
    const { active, toggle, setActive, id, isOpen } = useStaffMenu();
    const enabledSuperUserItems = useEnabledSuperUserItems((keys) => keys.length);
    const [viewedActionItemKeys = [], setViewedActionItemKeys] = UserPreferences.useLocalStorage('SUPER_USER_ACTION_VIEWED');
    const [superUserShowAllActions, setIsShowingAllActions] = UserPreferences.useLocalStorage('SUPER_USER_SHOW_ALL_ACTIONS');

    /***** EFFECTS *****/
    useEffect(() => {
        if (!activeSuperUserItems.length) {
            setActive((previous) => (previous === id ? '' : previous));
        }
    }, [activeSuperUserItems]);

    /***** FUNCTIONS *****/
    function setAllCurrentActionsAsViewed() {
        setViewedActionItemKeys(_.uniq([...viewedActionItemKeys, ...newActions]));
    }

    /***** RENDER HELPERS *****/
    const hasSearchKeyword = Boolean(searchKeyword.length);
    const isSearching = isSearchOpen && hasSearchKeyword;
    const isShowingAllActions = superUserShowAllActions || isSearching;
    const hasNewActions = Boolean(newActions.length);
    const hasEnabledActions = Boolean(enabledSuperUserItems);

    const filteredActiveItems = hasSearchKeyword ? activeSuperUserItems.filter(actionKeySearchFilter(searchKeyword)) : activeSuperUserItems;
    const filteredInactiveSuperUserItems = hasSearchKeyword
        ? inactiveSuperUserItems.filter(actionKeySearchFilter(searchKeyword))
        : inactiveSuperUserItems;

    /***** RENDER *****/
    return (
        <Gradient primary className="SuperUser">
            <Flex fullHeight fullWidth justify="center" align="center">
                <button type="button" style={{ width: '100%', height: '100%' }} onClick={toggle}>
                    <IconifyIcon icon="simple-icons:superuser" white size={28} />

                    {!active && isOpen && hasNewActions && !hasEnabledActions && (
                        <Flex className="SuperUser__hasNewActions" gap={0} justify="center" align="center">
                            <PhosphorIcons.Arrow.Left white size={12} />
                            <Text size--xs white>
                                New!
                            </Text>
                        </Flex>
                    )}
                    {!active && isOpen && hasEnabledActions && (
                        <Flex className="SuperUser__totalActiveActions" gap={1} align="center" justify="center">
                            <Text size--xss white lead--1>
                                {enabledSuperUserItems}
                            </Text>
                        </Flex>
                    )}
                </button>

                <Border all inject>
                    <Flex direction="column" gap={1} className={classNames('SuperUser__actions', { hidden: !active })}>
                        <Border bottom>
                            <Flex align="center" justify="between" gap={1}>
                                <Flex.Child grow>
                                    <Text>Super User Actions</Text>
                                </Flex.Child>

                                {hasNewActions && (
                                    <Anchor
                                        onClick={() => {
                                            setAllCurrentActionsAsViewed();
                                        }}
                                    >
                                        clear new
                                    </Anchor>
                                )}

                                <Flex inject align="center">
                                    <Tooltip title={isSearchOpen ? 'Close search' : 'Open search'} arrow placement="top">
                                        <button
                                            type="button"
                                            onClick={() => {
                                                if (isSearchOpen) {
                                                    setSearchKeyword('');
                                                }
                                                toggleIsSearchOpen();
                                            }}
                                            className="SuperUser__searchButton"
                                        >
                                            <PhosphorIcons.MagnifyingGlass.Bold secondary={!isSearchOpen} notice={isSearchOpen} />
                                        </button>
                                    </Tooltip>
                                </Flex>

                                <Flex inject align="center">
                                    <Tooltip
                                        title={
                                            <Flex direction="column" gap={0} align="stretch">
                                                <Flex gap={1} align="start">
                                                    <PhosphorIcons.FloppyDiskBack.Fill notice />
                                                    <Text>
                                                        Store the state in local storage so that you can keep the settings toggled between
                                                        reloads/refreshes
                                                    </Text>
                                                </Flex>
                                                <Hr />
                                                <Flex gap={1} align="start">
                                                    <PhosphorIcons.Stack.Fill white />
                                                    <Text>
                                                        <span>
                                                            By default actions only show in the list under certain conditions:
                                                            <br />
                                                            1. Hook for the item is currently being rendered.
                                                            <br />
                                                            2. The action is Saved in local storage.
                                                            <br />
                                                            {`3. the item is "Enabled" in the settings.`}
                                                            <br />
                                                        </span>
                                                    </Text>
                                                </Flex>
                                            </Flex>
                                        }
                                        arrow
                                    >
                                        <span>
                                            <PhosphorIcons.Question />
                                        </span>
                                    </Tooltip>
                                </Flex>
                            </Flex>

                            {isSearchOpen && (
                                <Input
                                    size="small"
                                    name="search"
                                    intrinsicProps={{ className: 'SuperUser__searchInput', onChange: (e) => setSearchKeyword(e.target.value) }}
                                    value={searchKeyword}
                                />
                            )}
                        </Border>

                        <Flex direction="column" gap={1} className="SuperUser__actionEntries">
                            {filteredActiveItems.map((actionKey) => {
                                return <SuperUserItem key={actionKey} actionKey={actionKey} />;
                            })}
                        </Flex>

                        {isShowingAllActions && (
                            <>
                                <Border bottom>
                                    <Text size--xs>Inactive items:</Text>
                                </Border>

                                <Flex direction="column" gap={1} className="SuperUser__actionEntries">
                                    {filteredInactiveSuperUserItems.map((actionKey) => {
                                        return <SuperUserItem key={actionKey} actionKey={actionKey} />;
                                    })}
                                </Flex>
                            </>
                        )}

                        {!isSearching && (
                            <Flex align="center" justify="center" inject>
                                <Border all inject>
                                    <Tooltip title={isShowingAllActions ? 'View active actions only' : 'View all actions'} arrow disableInteractive>
                                        <button
                                            type="button"
                                            onClick={() => {
                                                setIsShowingAllActions(!isShowingAllActions);
                                            }}
                                            className={classNames('SuperUser__toggleViewAllActions', {
                                                'SuperUser__toggleViewAllActions--isShowingAllActions': isShowingAllActions,
                                            })}
                                        >
                                            {isShowingAllActions ? <PhosphorIcons.Stack.Fill white /> : <PhosphorIcons.StackSimple.Fill secondary />}
                                        </button>
                                    </Tooltip>
                                </Border>
                            </Flex>
                        )}
                    </Flex>
                </Border>
            </Flex>
        </Gradient>
    );
};
