import { createRoute } from '@tanstack/react-router';
import { VIPSitesPageIDRoute } from 'containers/katana/routes/vip-sites.$katanaServiceId/_content-editor-lightbox/page.$pageId';
import { routerMiddleware } from 'router/utils/middleware';
import { lazyFN } from 'utilities/methods/tanstack/router/lazyFn';

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const VIPSitesPageIdAddSectionRoute = createRoute({
    getParentRoute: () => VIPSitesPageIDRoute,
    path: 'add-section',
    beforeLoad(opts) {
        routerMiddleware.business(this, opts);
        routerMiddleware.authentication('user', opts);
    }
}).lazy(lazyFN(() => import('./add-section.lazy'), 'LazyVIPSitesPageIdAddSectionRoute'));
