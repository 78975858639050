/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useState } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import RequestLoader from 'components/Loaders/Request';
import { NXToggleWrapperContext } from 'components/NXTable/NXTableContext';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Wraps Accordion components to provide functionality to auto close other accordions.
 * Must be part of the same wrapper to work.
 * @param {{ children: React.ReactNode, isLoading?: boolean }} props
 */
function ToggleWrapper({ children, isLoading }) {
    /***** STATE *****/
    const [activeIndex, setActiveIndex] = useState(-1);

    /***** FUNCTIONS *****/
    function onToggle(newActiveIndex) {
        setActiveIndex(newActiveIndex);
    }

    /***** RENDER HELPERS *****/
    const accordionContext = {
        activeIndex,
        onToggle,
        initialised: true
    };

    /***** RENDER *****/
    return <NXToggleWrapperContext.Provider value={accordionContext}>{isLoading ? <RequestLoader /> : children}</NXToggleWrapperContext.Provider>;
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default ToggleWrapper;
