import { queryOptions, useQuery, useSuspenseQuery, type UndefinedInitialDataOptions } from '@tanstack/react-query';
import { useSearch } from '@tanstack/react-router';
import { hostingQueryKeys } from 'containers/hosting/queries/queryKeys';
import _ from 'lodash';
import { queryClient } from 'store/queryClient';
import { API } from 'utilities/api/services';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
export type DefaultSelectResult = Awaited<API.hosting.listNew.ReturnType>;
export type QueryKey = ReturnType<typeof hostingQueryKeys.hosting.list.filters>;
type Select<TResult> = (data: DefaultSelectResult) => TResult;
// prettier-ignore
type QueryOptionsSubset<TData> = Omit<
    UndefinedInitialDataOptions<
        DefaultSelectResult, 
        Error, 
        TData, 
        QueryKey
    >, 
    'queryFn' | 'queryKey'
>;

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
const _createQueryOptions = <TData = DefaultSelectResult>(filters?: API.hosting.listNew.Filters) => {
    // Extract the exact filters from the filters object (in case there are extra keys)
    const exactFilters = _.pick(filters, ['record_per_page', 'page', 'sort', 'sort_by']);

    /***** RESULTS *****/
    return queryOptions({
        queryKey: hostingQueryKeys.hosting.list.filters(exactFilters),
        queryFn: () => API.hosting.listNew(exactFilters),
        select: (d) => d as TData
    });
};

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
const _useQuery = <TData = DefaultSelectResult>(filters?: API.hosting.listNew.Filters, opts?: QueryOptionsSubset<TData>) => {
    return useQuery({
        ..._createQueryOptions<TData>(filters),
        ...opts
    });
};

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
const _useSuspenseQuery = <TData = DefaultSelectResult>(filters?: API.hosting.listNew.Filters, opts?: QueryOptionsSubset<TData>) => {
    return useSuspenseQuery({
        ..._createQueryOptions<TData>(filters),
        ...opts
    });
};

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Abstraction on the base useQuery that syncs the query cache with the search query params. This should be used
 * when the query params come directly from the query params.
 *
 * @SearchParams
 * - record_per_page
 * - page
 * - sort
 * - sort_by
 */
export const _useSearchQuery = <TSelected = DefaultSelectResult>(select: Select<TSelected> = (d) => d as any) => {
    /***** HOOKS *****/
    const { record_per_page = 10, page = 1, sort = null, sort_by = null } = useSearch({ strict: false });

    /***** QUERIES *****/
    return _useQuery({ record_per_page, page, sort, sort_by }, { select });
};

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Abstraction on the base useQuery that syncs the query cache with the search query params. This should be used
 * when the query params come directly from the query params.
 *
 * @SearchParams
 * - record_per_page
 * - page
 * - sort
 * - sort_by
 */
export const _useSearchSuspenseQuery = <TSelected = DefaultSelectResult>(select: Select<TSelected> = (d) => d as any) => {
    /***** HOOKS *****/
    const { record_per_page, page, sort = null, sort_by = null } = useSearch({ strict: false });

    /***** QUERIES *****/
    return _useSuspenseQuery({ record_per_page, page, sort, sort_by }, { select });
};

/***** FUNCTIONS *****/
const invalidateQueries = () => {
    queryClient.invalidateQueries({ queryKey: hostingQueryKeys.hosting.list() });
};

/**********************************************************************************************************
 *  HOOK END
 * **********************************************************************************************************/

export const hostingList = {
    useQuery: _useQuery,
    useSuspenseQuery: _useSuspenseQuery,
    useSearchQuery: _useSearchQuery,
    useSearchSuspenseQuery: _useSearchSuspenseQuery,
    invalidate: invalidateQueries,
    createQueryKey: hostingQueryKeys.hosting.list.filters,
    createQueryOptions: _createQueryOptions
} as const;
