import Anchor from 'components/Anchor';
import { AdvancedList } from 'components/Form/AdvancedList';
import { HookFormButton } from 'components/Form/Button/hookForm';
import { Flex } from 'components/Utils/Flex';
import { Padding } from 'components/Utils/Padding';
import Text from 'components/Utils/Text';
import { useBrandStore } from 'config/hooks/useBrandStore';
import { useLoginContext } from 'containers/login/consts';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import type { API } from 'utilities/api/login';
import { useNXHookForm } from 'utilities/hooks/useNXForm';
import { requiredFieldErrorMessage } from 'utilities/methods/form';
import { NXQuery } from 'utilities/query';
import { z } from 'zod';
import { TwoFactorHeading } from './LoginTwoFactor/components/heading';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type SetBackupMethodParams = API.login.twoFactor.listMethods.Method | 'MANUAL';
type Schema = z.infer<typeof Schema>;
type SelectAlternativeTwoFactorMethod = React.FC<{
    setBackupMethod: (method?: API.login.twoFactor.listMethods.Method) => void;
}>;

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
const select = (data: Awaited<API.login.twoFactor.listMethods.ReturnType>) => {
    return data.data.map(({ attributes, id }) => ({
        ...attributes,
        id
    }));
};

/**********************************************************************************************************
 *   SCHEMA
 **********************************************************************************************************/
const Schema = z.object({
    method: z
        .string()
        .min(1, requiredFieldErrorMessage)
        .refine((value): value is SetBackupMethodParams => ['SMS', 'EMAIL', 'PUSH', 'GAUTH', 'MANUAL'].includes(value), {
            message: 'Method not supported'
        })
});

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const SelectAlternativeTwoFactorMethod: SelectAlternativeTwoFactorMethod = ({ setBackupMethod }) => {
    /***** HOOKS *****/
    const { setView } = useLoginContext();
    const { application } = useBrandStore();

    /***** QUERIES *****/
    const { data } = NXQuery.auth.twoFactor.list.useSuspenseQuery(void 0, { select });

    /***** FORM *****/
    const form = useNXHookForm(Schema, {
        defaultValues: {
            method: ''
        },
        mode: 'all'
    });

    /***** FUNCTIONS *****/
    const handleSubmit = ({ method }: Schema) => {
        if (method === 'MANUAL') {
            setView('recovery');
        } else {
            setBackupMethod(method);
            setView('2fa');
        }
    };

    /***** RENDER *****/
    return (
        <>
            <div className="login__title">
                <TwoFactorHeading>Two-Factor Options</TwoFactorHeading>
                <TwoFactorHeading.Description>
                    If you're having issues or simply cant access your two-factor code, select another way to sign in below
                </TwoFactorHeading.Description>
            </div>

            <FormProvider {...form}>
                <form className="login__form backupForm" onSubmit={form.handleSubmit(handleSubmit)}>
                    <AdvancedList name="method">
                        <AdvancedList.Item.Container>
                            {data.map(({ method, method_value, id }) => {
                                switch (method) {
                                    case 'EMAIL':
                                        return (
                                            <AdvancedList.Item key={id} displayName="Email Verification" value={method}>
                                                Get a verification code sent to <Text secondary>{method_value}</Text>
                                            </AdvancedList.Item>
                                        );
                                    case 'SMS':
                                        return (
                                            <AdvancedList.Item key={id} displayName="Mobile Verification" value={method}>
                                                Get a verification code sent to <Text secondary>{method_value}</Text>
                                            </AdvancedList.Item>
                                        );
                                    case 'GAUTH':
                                        return (
                                            <AdvancedList.Item key={id} displayName="Authenticator App" value={method}>
                                                Use your preferred authentication app and enter the code for {application}
                                            </AdvancedList.Item>
                                        );
                                    case 'PUSH':
                                        return (
                                            <AdvancedList.Item key={id} displayName={`${application} App`} value={method}>
                                                Use {application} app and receive the verification push notification
                                            </AdvancedList.Item>
                                        );
                                    default:
                                        return null;
                                }
                            })}
                            <AdvancedList.Item displayName="Manual Verification" value="MANUAL">
                                Initiate a manual account recovery. This process may take up to 24 hours
                            </AdvancedList.Item>
                        </AdvancedList.Item.Container>
                    </AdvancedList>

                    <HookFormButton>Continue</HookFormButton>
                    <Padding top={2}>
                        <Flex justify="center">
                            <Anchor onClick={() => setView('2fa')}>Go back a step</Anchor>
                        </Flex>
                    </Padding>
                </form>
            </FormProvider>
        </>
    );
};
