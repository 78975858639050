const SUPPORT_ARTICLE_CATEGORIES_DEFAULT = {
    _fields: ['id', 'name', 'slug', 'count', 'articles', 'parent', 'term_order'],
    per_page: 100,
    page: 1
};

const SUPPORT_ARTICLES_DEFAULT = {
    _fields: ['id', 'title', 'slug', 'support-articles-categories', 'content', 'excerpt', 'acf', 'categories', 'tags', 'history'],
    per_page: 100,
    page: 1
};

const SUPPORT_CENTRE_SEARCH = {
    _fields: ['title', 'slug', 'history', 'acf', 'popular', 'link'],
    search: 'domain names',
    page: 1,
    per_page: 5
};

export const SUPPORT_CENTRE_API_DEFAULTS = {
    search: SUPPORT_CENTRE_SEARCH,
    support_article_categories: SUPPORT_ARTICLE_CATEGORIES_DEFAULT,
    support_articles: SUPPORT_ARTICLES_DEFAULT
};
