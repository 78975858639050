import { createRoute, redirect } from '@tanstack/react-router';
import { createKatanaPageID, createKatanaServiceId } from 'containers/katana/methods';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import { VIPSitesContentLightboxLayoutRoute } from 'containers/katana/routes/vip-sites.$katanaServiceId/_content-editor-lightbox';
import type { GetBeforeLoadContextFromRouteId } from 'router/types';
import { routerMiddleware } from 'router/utils/middleware';
import { lazyFN } from 'utilities/methods/tanstack/router/lazyFn';

export const VIPSitesPageIDRoute = createRoute({
    getParentRoute: () => VIPSitesContentLightboxLayoutRoute,
    path: 'page/$pageId',
    async loader({ params }) {
        const serviceID = createKatanaServiceId(params.katanaServiceId);
        const pageID = createKatanaPageID(params.pageId);

        katanaQuery.serviceID.page.prefetchQuery({
            pageID,
            serviceID
        });
    },
    beforeLoad(opts) {
        routerMiddleware.business(this, opts);
        routerMiddleware.authentication('user', opts);
        redirectDirectMatch(opts);
    }
}).lazy(lazyFN(() => import('./page.$pageId.lazy'), 'LazyVIPSitesPageIDRoute'));

/**********************************************************************************************************
 *   ROUTE HELPER FUNCTIONS
 **********************************************************************************************************/
type BeforeLoadContext = GetBeforeLoadContextFromRouteId<'/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox/page/$pageId'>;

function redirectDirectMatch({ buildLocation, location, params }: BeforeLoadContext) {
    const builtLocation = buildLocation({
        to: '/my-services/vip-sites/$katanaServiceId/page/$pageId',
        params
    });

    if (builtLocation === location) {
        throw redirect({
            to: '/my-services/vip-sites/$katanaServiceId/overview',
            hash: 'basic-details',
            params
        });
    }
}
