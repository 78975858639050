import { superUserItemPortalDictionary } from 'components/StaffMenu/SuperUser/components/SuperUserItemPortal/consts';
import type { SuperUserNamespace } from 'components/StaffMenu/SuperUser/types';
import { useLayoutEffect } from 'react';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Props = {
    actionKey: SuperUserNamespace.Key;
    children: React.ReactNode;
};
type SuperUserItemPortal = React.FC<Props>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const SuperUserItemPortal: SuperUserItemPortal = ({ actionKey, children }) => {
    /***** EFFECTS *****/
    useLayoutEffect(() => {
        superUserItemPortalDictionary.setState((state) => ({
            ...state,
            [actionKey]: [...(state[actionKey] || []), children]
        }));

        return () => {
            const currentState = superUserItemPortalDictionary.state;
            const currentNodes = superUserItemPortalDictionary.state[actionKey] || [];
            const filteredNodes = currentNodes.filter((node) => node !== children);
            if (filteredNodes.length) {
                superUserItemPortalDictionary.setState(() => ({ ...currentState, [actionKey]: filteredNodes }));
                return;
            }
            const { [actionKey]: _, ...restState } = currentState;
            superUserItemPortalDictionary.setState(() => restState);
        };
    }, [children]);

    /***** RENDER *****/
    return null;
};
