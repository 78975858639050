/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_FetchComponentError.scss';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { FetchComponentErrorProps } from 'components/Errors/FetchComponentError/types';

/**
 * This component is used to display an error message when a component fails to load along with an exclamation icon to
 * further indicate issue with the component.
 */
const FetchComponentError = (props: FetchComponentErrorProps) => {
    let { error } = props;
    const { className } = props;
    if (!error) {
        error = {
            data: {
                errors: [
                    {
                        details: 'Looks like we encountered an issue. Please try again later or contact our support team.'
                    }
                ]
            }
        };
    }
    const { data } = error;
    const { errors } = data;
    const message = errors[0].details;
    return (
        <div className={`FetchComponentError${className ? ' ' + className : ''}`} data-testid="fetch-component-error">
            <div className="container">
                <div className="FetchComponentError__wrapper">
                    <i className="icon icon-issue"></i>
                    <div className="FetchComponentError__description">
                        {message ? message : 'Looks like we encountered an issue. Please try again later or contact our support team.'}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FetchComponentError;
