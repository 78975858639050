import { dismiss2faPromptMigration } from 'utilities/UserPreferences/migrations/dismiss2faPrompt';
import { feedbackBannerDismissedMigration } from 'utilities/UserPreferences/migrations/feedbackBannerDismissed';
import { onlineGiftNotificationMigration } from 'utilities/UserPreferences/migrations/onlineGiftNotification';
import { pictureInPictureStatesMigration } from 'utilities/UserPreferences/migrations/pictureInPictureStates';
import { promoBannerDismissRecordMigration } from 'utilities/UserPreferences/migrations/promoBannerDismissRecord';
import { remindLater2faMigration } from 'utilities/UserPreferences/migrations/remindLater2fa';
import type { UserPreferencesNamespace } from 'utilities/UserPreferences/types';

// Do not rename any of from/to values, as it's stored based on the combination of them
export const localStorageMigrations: UserPreferencesNamespace.MigrationData[] = [
    {
        from: '${domain_information_data.id}_onlineGiftNotification',
        to: 'USER_PREFERENCE:ONLINE_GIFT_NOTIFICATION',
        method: onlineGiftNotificationMigration
    },
    {
        from: 'feedbackBannerDismissed',
        to: 'USER_PREFERENCE:IS_FEEDBACK_BANNER_DISMISSED',
        method: feedbackBannerDismissedMigration
    },
    {
        from: 'storable__${identifier}',
        to: 'USER_PREFERENCE:PICTURE_IN_PICTURE_STATES',
        method: pictureInPictureStatesMigration
    },
    {
        from: 'dismiss_2fa_prompt',
        to: 'USER_PREFERENCE:IS_2FA_PROMPT_DISMISSED',
        method: dismiss2faPromptMigration
    },
    {
        from: 'remind_later_2fa',
        to: 'USER_PREFERENCE:REMIND_LATER_2FA_CURRENT_DATE',
        method: remindLater2faMigration
    },
    {
        from: '${PromoBannerComponent.dismiss_key}',
        to: 'USER_PREFERENCE:PROMO_BANNER_DISMISSAL_RECORD',
        method: promoBannerDismissRecordMigration
    }
] as const;
