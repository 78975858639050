/**********************************************************************************************************
 *   DECLARATIONS
 **********************************************************************************************************/
export const SUPPORT_INITIAL_STATE = 'support/SUPPORT_INITIAL_STATE';

export const SUPPORT_KNOWLEDGE_BASE_CATEGORY_REFS = 'support/SUPPORT_KNOWLEDGE_BASE_CATEGORY_REFS';

export const SUPPORT_KNOWLEDGE_BASE_REQUEST = 'support/SUPPORT_KNOWLEDGE_BASE_REQUEST';
export const SUPPORT_KNOWLEDGE_BASE_SUCCESS = 'support/SUPPORT_KNOWLEDGE_BASE_SUCCESS';
export const SUPPORT_KNOWLEDGE_BASE_ERROR = 'support/SUPPORT_KNOWLEDGE_BASE_ERROR';

export const SUPPORT_KNOWLEDGE_BASE_ALL = 'support/SUPPORT_KNOWLEDGE_BASE_ALL';

export const SUPPORT_TICKETS_OPEN_REQUEST = 'support/SUPPORT_TICKETS_OPEN_REQUEST';
export const SUPPORT_TICKETS_OPEN_SUCCESS = 'support/SUPPORT_TICKETS_OPEN_SUCCESS';
export const SUPPORT_TICKETS_OPEN_ERROR = 'support/SUPPORT_TICKETS_OPEN_ERROR';

export const SUPPORT_INDIVIDUAL_TICKET_REQUEST = 'support/SUPPORT_INDIVIDUAL_TICKET_REQUEST';
export const SUPPORT_INDIVIDUAL_TICKET_SUCCESS = 'support/SUPPORT_INDIVIDUAL_TICKET_SUCCESS';
export const SUPPORT_INDIVIDUAL_TICKET_ERROR = 'support/SUPPORT_INDIVIDUAL_TICKET_ERROR';

export const SUPPORT_TICKET_UPDATE_FEEDBACK_STATUS_REQUEST = 'support/SUPPORT_TICKET_UPDATE_FEEDBACK_STATUS_REQUEST';
export const SUPPORT_TICKET_UPDATE_FEEDBACK_STATUS_SUCCESS = 'support/SUPPORT_TICKET_UPDATE_FEEDBACK_STATUS_SUCCESS';
export const SUPPORT_TICKET_UPDATE_FEEDBACK_STATUS_ERROR = 'support/SUPPORT_TICKET_UPDATE_FEEDBACK_STATUS_ERROR';

export const SUPPORT_TICKET_CLOSE_REQUEST = 'support/SUPPORT_TICKET_CLOSE_REQUEST';
export const SUPPORT_TICKET_CLOSE_SUCCESS = 'support/SUPPORT_TICKET_CLOSE_SUCCESS';
export const SUPPORT_TICKET_CLOSE_ERROR = 'support/SUPPORT_TICKET_CLOSE_ERROR';

export const SUPPORT_TICKET_WAIT_TIME_REQUEST = 'support/SUPPORT_TICKET_WAIT_TIME_REQUEST';
export const SUPPORT_TICKET_WAIT_TIME_SUCCESS = 'support/SUPPORT_TICKET_WAIT_TIME_SUCCESS';
export const SUPPORT_TICKET_WAIT_TIME_ERROR = 'support/SUPPORT_TICKET_WAIT_TIME_ERROR';

export const SUPPORT_ALL_SERVICES_REQUEST = 'support/SUPPORT_ALL_SERVICES_REQUEST';
export const SUPPORT_ALL_SERVICES_SUCCESS = 'support/SUPPORT_ALL_SERVICES_SUCCESS';
export const SUPPORT_ALL_SERVICES_ERROR = 'support/SUPPORT_ALL_SERVICES_ERROR';

export const SUPPORT_QUESTION_LIST_REQUEST = 'support/SUPPORT_QUESTION_LIST_REQUEST';
export const SUPPORT_QUESTION_LIST_SUCCESS = 'support/SUPPORT_QUESTION_LIST_SUCCESS';
export const SUPPORT_QUESTION_LIST_ERROR = 'support/SUPPORT_QUESTION_LIST_ERROR';

export const SUPPORT_TICKET_FEEDBACK_REQUEST = 'support/SUPPORT_TICKET_FEEDBACK_REQUEST';
export const SUPPORT_TICKET_FEEDBACK_SUCCESS = 'support/SUPPORT_TICKET_FEEDBACK_SUCCESS';
export const SUPPORT_TICKET_FEEDBACK_ERROR = 'support/SUPPORT_TICKET_FEEDBACK_ERROR';

export const SUPPORT_RESOLVED_SURVEY_REQUEST = 'support/SUPPORT_RESOLVED_SURVEY_REQUEST';
export const SUPPORT_RESOLVED_SURVEY_SUCCESS = 'support/SUPPORT_RESOLVED_SURVEY_SUCCESS';
export const SUPPORT_RESOLVED_SURVEY_ERROR = 'support/SUPPORT_RESOLVED_SURVEY_ERROR';

export const SUPPORT_RESOLVED_SURVEY_LOAD_MORE_REQUEST = 'support/SUPPORT_RESOLVED_SURVEY_LOAD_MORE_REQUEST';
export const SUPPORT_RESOLVED_SURVEY_LOAD_MORE_SUCCESS = 'support/SUPPORT_RESOLVED_SURVEY_LOAD_MORE_SUCCESS';
export const SUPPORT_RESOLVED_SURVEY_LOAD_MORE_ERROR = 'support/SUPPORT_RESOLVED_SURVEY_LOAD_MORE_ERROR';

export const SUPPORT_RESOLVED_TICKETS_SEARCH_REQUEST = 'support/SUPPORT_RESOLVED_TICKETS_SEARCH_REQUEST';
export const SUPPORT_RESOLVED_TICKETS_SEARCH_SUCCESS = 'support/SUPPORT_RESOLVED_TICKETS_SEARCH_SUCCESS';
export const SUPPORT_RESOLVED_TICKETS_SEARCH_ERROR = 'support/SUPPORT_RESOLVED_TICKETS_SEARCH_ERROR';
export const SUPPORT_RESOLVED_TICKETS_SEARCH_RESET = 'support/SUPPORT_RESOLVED_TICKETS_SEARCH_RESET';

export const SUPPORT_DOWNLOAD_ATTACHMENT_REQUEST = 'support/SUPPORT_DOWNLOAD_ATTACHMENT_REQUEST';
export const SUPPORT_DOWNLOAD_ATTACHMENT_SUCCESS = 'support/SUPPORT_DOWNLOAD_ATTACHMENT_SUCCESS';
export const SUPPORT_DOWNLOAD_ATTACHMENT_ERROR = 'support/SUPPORT_DOWNLOAD_ATTACHMENT_ERROR';

export const SUPPORT_MIGRATION_MIGRATION_DATES_REQUEST = 'support/SUPPORT_MIGRATION_MIGRATION_DATES_REQUEST';
export const SUPPORT_MIGRATION_MIGRATION_DATES_SUCCESS = 'support/SUPPORT_MIGRATION_MIGRATION_DATES_SUCCESS';
export const SUPPORT_MIGRATION_MIGRATION_DATES_ERROR = 'support/SUPPORT_MIGRATION_MIGRATION_DATES_ERROR';

export const SUPPORT_SUBMIT_MIGRATION_REQUEST_FORM_REQUEST = 'support/SUPPORT_SUBMIT_MIGRATION_REQUEST_FORM_REQUEST';
export const SUPPORT_SUBMIT_MIGRATION_REQUEST_FORM_SUCCESS = 'support/SUPPORT_SUBMIT_MIGRATION_REQUEST_FORM_SUCCESS';
export const SUPPORT_SUBMIT_MIGRATION_REQUEST_FORM_ERROR = 'support/SUPPORT_SUBMIT_MIGRATION_REQUEST_FORM_ERROR';

export const SUPPORT_SUBMIT_MIGRATION_DETAILS_CHECK_SERVICE_RESET = 'support/SUPPORT_SUBMIT_MIGRATION_DETAILS_CHECK_SERVICE_RESET';
export const SUPPORT_SUBMIT_MIGRATION_DETAILS_CHECK_SERVICE_REQUEST = 'support/SUPPORT_SUBMIT_MIGRATION_DETAILS_VALIDATION_REQUEST';
export const SUPPORT_SUBMIT_MIGRATION_DETAILS_CHECK_SERVICE_SUCCESS = 'support/SUPPORT_SUBMIT_MIGRATION_DETAILS_VALIDATION_SUCCESS';
export const SUPPORT_SUBMIT_MIGRATION_DETAILS_CHECK_SERVICE_ERROR = 'support/SUPPORT_SUBMIT_MIGRATION_DETAILS_VALIDATION_ERROR';

export const SUPPORT_FEEDBACK_FORM_REQUEST = 'support/SUPPORT_FEEDBACK_FORM_REQUEST';
export const SUPPORT_FEEDBACK_FORM_SUCCESS = 'support/SUPPORT_FEEDBACK_FORM_SUCCESS';
export const SUPPORT_FEEDBACK_FORM_ERROR = 'support/SUPPORT_FEEDBACK_FORM_ERROR';

export const SUPPORT_SERVICE_STATUS_REQUEST = 'support/SUPPORT_SERVICE_STATUS_REQUEST';
export const SUPPORT_SERVICE_STATUS_SUCCESS = 'support/SUPPORT_SERVICE_STATUS_SUCCESS';
export const SUPPORT_SERVICE_STATUS_ERROR = 'support/SUPPORT_SERVICE_STATUS_ERROR';

export const SUPPORT_SERVICE_STATUS_UPDATE_REQUEST = 'support/SUPPORT_SERVICE_STATUS_UPDATE_REQUEST';
export const SUPPORT_SERVICE_STATUS_UPDATE_SUCCESS = 'support/SUPPORT_SERVICE_STATUS_UPDATE_SUCCESS';
export const SUPPORT_SERVICE_STATUS_UPDATE_ERROR = 'support/SUPPORT_SERVICE_STATUS_UPDATE_ERROR';
