import {
    DOMAIN_AUTO_RENEW_FAIL,
    DOMAIN_AUTO_RENEW_REQUEST,
    DOMAIN_AUTO_RENEW_SUCCESS,
    DOMAIN_BULK_MANAGE_UPDATE,
    DOMAIN_EXPORT_ERROR,
    DOMAIN_EXPORT_REQUEST,
    DOMAIN_EXPORT_SUCCESS,
    DOMAIN_FORM_FAIL,
    DOMAIN_FORM_REQUEST,
    DOMAIN_FORM_SUCCESS,
    DOMAIN_LIST_ERROR,
    DOMAIN_LIST_MORE_ERROR,
    DOMAIN_LIST_MORE_REQUEST,
    DOMAIN_LIST_MORE_SUCCESS,
    DOMAIN_LIST_REQUEST,
    DOMAIN_LIST_RESET,
    DOMAIN_LIST_SUCCESS,
    DOMAIN_SEARCH_ERROR,
    DOMAIN_SEARCH_REQUEST,
    DOMAIN_SEARCH_RESET,
    DOMAIN_SEARCH_SUCCESS,
    EMAIL_LIST_ERROR,
    EMAIL_LIST_MORE_ERROR,
    EMAIL_LIST_MORE_REQUEST,
    EMAIL_LIST_MORE_SUCCESS,
    EMAIL_LIST_REQUEST,
    EMAIL_LIST_RESET,
    EMAIL_LIST_SUCCESS,
    EMAIL_SEARCH_ERROR,
    EMAIL_SEARCH_REQUEST,
    EMAIL_SEARCH_RESET,
    EMAIL_SEARCH_SUCCESS,
    GET_SSL_LIST,
    GET_SSL_LIST_ERROR,
    GET_SSL_LIST_MORE_ERROR,
    GET_SSL_LIST_MORE_REQUEST,
    GET_SSL_LIST_MORE_SUCCESS,
    GET_SSL_LIST_SUCCESS,
    GET_SSL_PRODUCTS,
    GET_SSL_PRODUCTS_ERROR,
    GET_SSL_PRODUCTS_SUCCESS,
    GSUITE_LIST_ERROR,
    GSUITE_LIST_MORE_ERROR,
    GSUITE_LIST_MORE_REQUEST,
    GSUITE_LIST_MORE_SUCCESS,
    GSUITE_LIST_REQUEST,
    GSUITE_LIST_SUCCESS,
    GSUITE_SEARCH_ERROR,
    GSUITE_SEARCH_REQUEST,
    GSUITE_SEARCH_RESET,
    GSUITE_SEARCH_SUCCESS,
    HOSTING_LIST_ERROR,
    HOSTING_LIST_MORE_ERROR,
    HOSTING_LIST_MORE_REQUEST,
    HOSTING_LIST_MORE_SUCCESS,
    HOSTING_LIST_REQUEST,
    HOSTING_LIST_RESET,
    HOSTING_LIST_SUCCESS,
    HOSTING_SEARCH_ERROR,
    HOSTING_SEARCH_REQUEST,
    HOSTING_SEARCH_RESET,
    HOSTING_SEARCH_SUCCESS,
    MS365_LIST_ERROR,
    MS365_LIST_MORE_ERROR,
    MS365_LIST_MORE_REQUEST,
    MS365_LIST_MORE_SUCCESS,
    MS365_LIST_REQUEST,
    MS365_LIST_SUCCESS,
    MS365_SEARCH_ERROR,
    MS365_SEARCH_REQUEST,
    MS365_SEARCH_RESET,
    MS365_SEARCH_SUCCESS,
    PURCHASE_SSL_PRODUCT,
    PURCHASE_SSL_PRODUCT_ERROR,
    PURCHASE_SSL_PRODUCT_RESET,
    PURCHASE_SSL_PRODUCT_SUCCESS,
    SERVICES_INITIAL_STATE,
    SERVICE_CHANGE_PLAN_PATHS_ERROR,
    SERVICE_CHANGE_PLAN_PATHS_REQUEST,
    SERVICE_CHANGE_PLAN_PATHS_SUCCESS,
    SERVICE_MOVE_APPROVE_ERROR,
    SERVICE_MOVE_APPROVE_REQUEST,
    SERVICE_MOVE_APPROVE_REQUEST_RESET,
    SERVICE_MOVE_APPROVE_SUCCESS,
    SERVICE_MOVE_CANCEL_ERROR,
    SERVICE_MOVE_CANCEL_REQUEST,
    SERVICE_MOVE_CANCEL_SUCCESS,
    SERVICE_MOVE_ERROR,
    SERVICE_MOVE_INVITATION_ERROR,
    SERVICE_MOVE_INVITATION_REQUEST,
    SERVICE_MOVE_INVITATION_SUCCESS,
    SERVICE_MOVE_REGISTER_ERROR,
    SERVICE_MOVE_REGISTER_REQUEST,
    SERVICE_MOVE_REGISTER_SUCCESS,
    SERVICE_MOVE_REQUEST,
    SERVICE_MOVE_SUCCESS,
    SERVICE_RESTORE_INVOICE_ERROR,
    SERVICE_RESTORE_INVOICE_REQUEST,
    SERVICE_RESTORE_INVOICE_SUCCESS,
    SERVICE_UNSUSPENSION_ERROR,
    SERVICE_UNSUSPENSION_REASON_ERROR,
    SERVICE_UNSUSPENSION_REASON_REQUEST,
    SERVICE_UNSUSPENSION_REASON_SUCCESS,
    SERVICE_UNSUSPENSION_REQUEST,
    SERVICE_UNSUSPENSION_SUCCESS,
    SSL_LIST_RESET,
    SSL_SEARCH_ERROR,
    SSL_SEARCH_REQUEST,
    SSL_SEARCH_RESET,
    SSL_SEARCH_SUCCESS,
    VPS_LIST_ERROR,
    VPS_LIST_MORE_ERROR,
    VPS_LIST_MORE_REQUEST,
    VPS_LIST_MORE_SUCCESS,
    VPS_LIST_REQUEST,
    VPS_LIST_SUCCESS,
    VPS_SEARCH_ERROR,
    VPS_SEARCH_REQUEST,
    VPS_SEARCH_RESET,
    VPS_SEARCH_SUCCESS
} from './actionTypes';

const initialState = {
    service_move_invitation_status: null,
    service_move_invitation_data: null,
    service_move_invitation_error: null,
    service_move_status: null,
    service_move_data: null,
    service_move_error: null,
    service_move_approve_status: null,
    service_move_approve_data: null,
    service_move_approve_error: null,
    service_move_register_status: null,
    service_move_register_data: null,
    service_move_register_error: null,
    service_move_cancel_status: null,
    service_move_cancel_data: null,
    service_move_cancel_error: null,
    service_unsuspension_reason_status: null,
    service_unsuspension_reason_data: null,
    service_unsuspend_status: null,
    domain_list_status: null,
    domain_list_data: null,
    domain_list_error: null,
    domain_list_meta: null,
    domain_list_more_status: null,
    domain_export_status: null,
    domain_export_data: null,
    domain_export_error: null,
    domain_auto_renew_status: null,
    domain_auto_renew_data: null,
    domain_auto_renew_error: null,
    domain_form_status: null,
    domain_form_data: null,
    domain_form_error: null,
    domain_search_status: null,
    domain_search_data: null,
    domain_search_error: null,
    hosting_list_status: null,
    hosting_list_data: null,
    hosting_list_error: null,
    hosting_list_meta: null,
    hosting_list_more_status: null,
    service_restore_invoice_data: null,
    service_restore_invoice_error: null,
    service_restore_invoice_status: null,
    email_list_status: null,
    email_list_data: null,
    email_list_error: null,
    email_list_meta: null,
    email_list_more_status: null,
    mailbox_search_status: null,
    mailbox_search_data: null,
    mailbox_search_error: null,
    ssl_list_status: null,
    ssl_list_more_status: null,
    ssl_list_data: null,
    ssl_list_error: null,
    ssl_list_meta: null,
    ssl_products_status: null,
    ssl_products_data: null,
    ssl_products_error: null,
    ssl_purchase_status: null,
    ssl_purchase_data: null,
    ssl_purchase_error: null,
    ssl_search_data: null,
    ssl_search_status: null,
    ssl_search_error: null,
    vps_list_status: null,
    vps_list_more_status: null,
    vps_list_data: null,
    vps_list_error: null,
    vps_list_meta: null,
    vps_list_show_more: null,
    vps_search_status: null,
    vps_search_data: null,
    vps_search_error: null,
    gsuite_list_data: null,
    gsuite_list_error: null,
    gsuite_list_status: null,
    gsuite_list_meta: null,
    gsuite_list_more_status: null,
    gsuite_search_status: null,
    gsuite_search_data: null,
    gsuite_search_error: null,
    ms365_list_status: null,
    ms365_list_data: null,
    ms365_list_error: null,
    ms365_list_meta: null,
    ms365_list_more_status: null,
    ms365_search_status: null,
    ms365_search_data: null,
    ms365_search_error: null
};

const servicesReducer = (state = initialState, action) => {
    switch (action.type) {
        /**********************************************************************************************************
         *   SERVICE MOVE STATES
         **********************************************************************************************************/
        case SERVICE_MOVE_INVITATION_REQUEST:
            return {
                ...state,
                service_move_invitation_status: 'loading',
                service_move_invitation_data: null,
                service_move_invitation_error: null
            };

        case SERVICE_MOVE_INVITATION_SUCCESS:
            return {
                ...state,
                service_move_invitation_status: 'success',
                service_move_invitation_data: action.service_move_invitation_data
            };

        case SERVICE_MOVE_INVITATION_ERROR:
            return {
                ...state,
                service_move_invitation_status: 'error',
                service_move_invitation_error: action.service_move_invitation_error
            };

        case SERVICE_MOVE_REQUEST:
            return {
                ...state,
                service_move_status: 'loading',
                service_move_data: null,
                service_move_error: null
            };

        case SERVICE_MOVE_SUCCESS:
            return {
                ...state,
                service_move_status: 'success',
                service_move_data: action.service_move_data
            };

        case SERVICE_MOVE_ERROR:
            return {
                ...state,
                service_move_status: 'error',
                service_move_error: action.service_move_error
            };

        case SERVICE_MOVE_APPROVE_REQUEST:
            return {
                ...state,
                service_move_approve_status: 'loading',
                service_move_approve_data: null,
                service_move_approve_error: null
            };

        case SERVICE_MOVE_APPROVE_SUCCESS:
            return {
                ...state,
                service_move_approve_status: 'success',
                service_move_approve_data: action.service_move_approve_data
            };

        case SERVICE_MOVE_APPROVE_ERROR:
            return {
                ...state,
                service_move_approve_status: 'error',
                service_move_approve_error: action.service_move_approve_error
            };

        case SERVICE_MOVE_APPROVE_REQUEST_RESET:
            return {
                ...state,
                service_move_approve_status: null,
                service_move_approve_data: null,
                service_move_approve_error: null
            };

        case SERVICE_MOVE_REGISTER_REQUEST:
            return {
                ...state,
                service_move_register_status: 'loading',
                service_move_register_data: null,
                service_move_register_error: null
            };

        case SERVICE_MOVE_REGISTER_SUCCESS:
            return {
                ...state,
                service_move_register_status: 'success',
                service_move_register_data: action.service_move_register_data
            };

        case SERVICE_MOVE_REGISTER_ERROR:
            return {
                ...state,
                service_move_register_status: 'error',
                service_move_register_error: action.service_move_register_error
            };

        case SERVICE_MOVE_CANCEL_REQUEST:
            return {
                ...state,
                service_move_cancel_status: 'loading',
                service_move_cancel_data: null,
                service_move_cancel_error: null
            };

        case SERVICE_MOVE_CANCEL_SUCCESS:
            return {
                ...state,
                service_move_cancel_status: 'success',
                service_move_cancel_data: action.service_move_cancel_data
            };

        case SERVICE_MOVE_CANCEL_ERROR:
            return {
                ...state,
                service_move_cancel_status: 'error',
                service_move_cancel_error: action.service_move_cancel_error
            };

        /**
         * SERVICE UNSUSPEND
         */
        case SERVICE_UNSUSPENSION_REASON_REQUEST:
            return {
                ...state,
                service_unsuspension_reason_status: 'loading',
                service_unsuspension_reason_data: null,
                service_unsuspension_reason_error: null
            };

        case SERVICE_UNSUSPENSION_REASON_SUCCESS:
            return {
                ...state,
                service_unsuspension_reason_status: 'success',
                service_unsuspension_reason_data: action.service_unsuspension_reason_data,
                service_unsuspension_reason_error: null
            };

        case SERVICE_UNSUSPENSION_REASON_ERROR:
            return {
                ...state,
                service_unsuspension_reason_status: 'error',
                service_unsuspension_reason_error: action.service_unsuspension_reason_error,
                service_unsuspension_reason_data: null
            };

        case SERVICE_UNSUSPENSION_REQUEST:
            return {
                ...state,
                service_unsuspend_status: 'loading'
            };

        case SERVICE_UNSUSPENSION_SUCCESS:
            return {
                ...state,
                service_unsuspend_status: 'success'
            };

        case SERVICE_UNSUSPENSION_ERROR:
            return {
                ...state,
                service_unsuspend_status: 'error'
            };

        case SERVICE_CHANGE_PLAN_PATHS_REQUEST:
            return {
                ...state,
                service_change_plan_paths_status: 'loading',
                service_change_plan_paths_data: null
            };
        case SERVICE_CHANGE_PLAN_PATHS_SUCCESS:
            return {
                ...state,
                service_change_plan_paths_status: 'success',
                service_change_plan_paths_data: action.service_change_plan_paths_data
            };
        case SERVICE_CHANGE_PLAN_PATHS_ERROR:
            return {
                ...state,
                service_change_plan_paths_status: 'error'
            };

        /**********************************************************************************************************
         *   DOMAIN LIST STATES
         **********************************************************************************************************/
        case DOMAIN_LIST_REQUEST:
            return {
                ...state,
                domain_list_status: 'loading',
                domain_list_data: null,
                domain_list_error: null,
                domain_list_meta: null
            };
        case DOMAIN_LIST_SUCCESS:
            return {
                ...state,
                domain_list_status: 'success',
                domain_list_data: action.domain_list_data,
                domain_list_meta: action.domain_list_meta
            };
        case DOMAIN_LIST_ERROR:
            return {
                ...state,
                domain_list_status: 'error',
                domain_list_error: action.domain_list_error
            };

        case DOMAIN_LIST_MORE_REQUEST:
            return {
                ...state,
                domain_list_more_status: 'loading',
                domain_list_error: null
            };
        case DOMAIN_LIST_MORE_SUCCESS:
            return {
                ...state,
                domain_list_more_status: 'success',
                domain_list_data: action.domain_list_data,
                domain_list_meta: action.domain_list_meta
            };
        case DOMAIN_LIST_MORE_ERROR:
            return {
                ...state,
                domain_list_more_status: 'error',
                domain_list_error: action.domain_list_error
            };

        case DOMAIN_LIST_RESET:
            return {
                ...state,
                domain_list_status: null,
                domain_list_data: null,
                domain_list_error: null,
                domain_list_meta: null
            };

        /*   DOMAINS EXPORT
         *****************************************************/
        case DOMAIN_EXPORT_REQUEST:
            return {
                ...state,
                domain_export_status: 'loading',
                domain_export_data: null,
                domain_export_error: null
            };
        case DOMAIN_EXPORT_SUCCESS:
            return {
                ...state,
                domain_export_status: 'success',
                domain_export_data: action.domain_export_data
            };
        case DOMAIN_EXPORT_ERROR:
            return {
                ...state,
                domain_export_status: 'error',
                domain_export_error: action.domain_export_error
            };

        /*   AUTO RENEW
         *****************************************************/
        case DOMAIN_AUTO_RENEW_REQUEST:
            return {
                ...state,
                domain_auto_renew_status: 'loading',
                domain_auto_renew_data: null,
                domain_auto_renew_error: null
            };

        case DOMAIN_AUTO_RENEW_SUCCESS:
            return {
                ...state,
                domain_auto_renew_status: 'success',
                domain_auto_renew_data: action.domain_auto_renew_data
            };

        case DOMAIN_AUTO_RENEW_FAIL:
            return {
                ...state,
                domain_auto_renew_status: 'error',
                domain_auto_renew_error: action.domain_auto_renew_error
            };

        /*  SUBMIT DOMAIN FORMS
         *****************************************************/
        case DOMAIN_FORM_REQUEST:
            return {
                ...state,
                domain_form_status: 'loading',
                domain_form_data: null,
                domain_form_error: null
            };

        case DOMAIN_FORM_SUCCESS:
            return {
                ...state,
                domain_form_status: 'success',
                domain_form_data: action.domain_form_data
            };

        case DOMAIN_FORM_FAIL:
            return {
                ...state,
                domain_form_status: 'error',
                domain_form_error: action.domain_form_error
            };

        case DOMAIN_SEARCH_REQUEST:
            return {
                ...state,
                domain_search_status: 'loading',
                domain_search_data: null,
                domain_search_error: null
            };

        case DOMAIN_SEARCH_SUCCESS:
            return {
                ...state,
                domain_search_status: 'success',
                domain_search_data: action.domain_search_data
            };

        case DOMAIN_SEARCH_ERROR:
            return {
                ...state,
                domain_search_status: 'error',
                domain_search_error: action.domain_search_error
            };

        case DOMAIN_SEARCH_RESET:
            return {
                ...state,
                domain_search_status: null,
                domain_search_data: null,
                domain_search_error: null
            };

        /**********************************************************************************************************
         *   HOSTING LIST STATES
         **********************************************************************************************************/
        case HOSTING_LIST_REQUEST:
            return {
                ...state,
                hosting_list_status: 'loading',
                hosting_list_data: null,
                hosting_list_error: null,
                hosting_list_meta: null
            };

        case HOSTING_LIST_SUCCESS:
            return {
                ...state,
                hosting_list_status: 'success',
                hosting_list_data: action.hosting_list_data,
                hosting_list_meta: action.hosting_list_meta
            };

        case HOSTING_LIST_ERROR:
            return {
                ...state,
                hosting_list_status: 'error',
                hosting_list_error: action.hosting_list_error
            };

        case HOSTING_LIST_MORE_REQUEST:
            return {
                ...state,
                hosting_list_more_status: 'loading',
                hosting_list_error: null
            };

        case HOSTING_LIST_MORE_SUCCESS:
            return {
                ...state,
                hosting_list_more_status: 'success',
                hosting_list_data: action.hosting_list_data,
                hosting_list_meta: action.hosting_list_meta
            };

        case HOSTING_LIST_MORE_ERROR:
            return {
                ...state,
                hosting_list_more_status: 'error',
                hosting_list_error: action.hosting_list_error
            };

        case HOSTING_LIST_RESET:
            return {
                ...state,
                hosting_list_status: null,
                hosting_list_data: null,
                hosting_list_error: null,
                hosting_list_meta: null
            };

        case HOSTING_SEARCH_RESET:
            return {
                ...state,
                hosting_search_status: null,
                hosting_search_data: null,
                hosting_search_error: null
            };

        case HOSTING_SEARCH_REQUEST:
            return {
                ...state,
                hosting_search_status: 'loading',
                hosting_search_data: null,
                hosting_search_error: null
            };

        case HOSTING_SEARCH_SUCCESS:
            return {
                ...state,
                hosting_search_status: 'success',
                hosting_search_data: action.hosting_search_data
            };

        case HOSTING_SEARCH_ERROR:
            return {
                ...state,
                hosting_search_status: 'error',
                hosting_search_error: action.hosting_search_error
            };

        case SERVICE_RESTORE_INVOICE_REQUEST:
            return {
                ...state,
                service_restore_invoice_status: 'loading',
                service_restore_invoice_data: null,
                service_restore_invoice_error: null
            };
        case SERVICE_RESTORE_INVOICE_SUCCESS:
            return {
                ...state,
                service_restore_invoice_status: 'success',
                service_restore_invoice_data: action.service_restore_invoice_data
            };
        case SERVICE_RESTORE_INVOICE_ERROR:
            return {
                ...state,
                service_restore_invoice_status: 'error',
                service_restore_invoice_error: action.service_restore_invoice_error
            };

        /**********************************************************************************************************
         *   EMAIL LIST STATES
         **********************************************************************************************************/
        case EMAIL_LIST_REQUEST:
            return {
                ...state,
                email_list_status: 'loading',
                email_list_data: null,
                email_list_error: null,
                email_list_meta: null
            };

        case EMAIL_LIST_SUCCESS:
            return {
                ...state,
                email_list_status: 'success',
                email_list_data: action.email_list_data,
                email_list_meta: action.email_list_meta
            };

        case EMAIL_LIST_ERROR:
            return {
                ...state,
                email_list_status: 'error',
                email_list_error: action.email_list_error
            };

        case EMAIL_LIST_MORE_REQUEST:
            return {
                ...state,
                email_list_more_status: 'loading',
                email_list_error: null
            };

        case EMAIL_LIST_MORE_SUCCESS:
            return {
                ...state,
                email_list_more_status: 'success',
                email_list_data: action.email_list_data,
                email_list_meta: action.email_list_meta
            };

        case EMAIL_LIST_MORE_ERROR:
            return {
                ...state,
                email_list_more_status: 'error',
                email_list_error: action.email_list_error
            };

        case EMAIL_LIST_RESET:
            return {
                ...state,
                email_list_status: null,
                email_list_data: null,
                email_list_error: null,
                email_list_meta: null
            };
        
        /**********************************************************************************************************
         *   EMAIL SEARCH STATES
         **********************************************************************************************************/
        case EMAIL_SEARCH_RESET:
            return {
                ...state,
                email_search_status: null,
                email_search_data: null,
                email_search_error: null
            };

        case EMAIL_SEARCH_REQUEST:
            return {
                ...state,
                email_search_status: 'loading',
                email_search_data: null,
                email_search_error: null
            };

        case EMAIL_SEARCH_SUCCESS:
            return {
                ...state,
                email_search_status: 'success',
                email_search_data: action.email_search_data
            };

        case EMAIL_SEARCH_ERROR:
            return {
                ...state,
                email_search_status: 'error',
                email_search_error: action.email_search_error
            };

        /**********************************************************************************************************
         *   SSL LIST STATES
         **********************************************************************************************************/
        case GET_SSL_LIST:
            return {
                ...state,
                ssl_list_status: 'loading',
                ssl_list_data: null,
                ssl_list_error: null
            };
        case GET_SSL_LIST_MORE_REQUEST:
            return {
                ...state,
                ssl_list_more_status: 'loading',
                ssl_list_more_error: null
            };

        case GET_SSL_LIST_MORE_SUCCESS:
            return {
                ...state,
                ssl_list_more_status: 'success',
                ssl_list_data: action.ssl_list_data,
                ssl_list_meta: action.ssl_list_meta
            };
        case GET_SSL_LIST_MORE_ERROR:
            return {
                ...state,
                ssl_list_more_status: 'error',
                ssl_list_error: action.hosting_list_error
            };
        case GET_SSL_LIST_SUCCESS:
            return {
                ...state,
                ssl_list_status: 'success',
                ssl_list_data: action.ssl_list_data,
                ssl_list_meta: action.ssl_list_meta
            };
        case GET_SSL_LIST_ERROR:
            return {
                ...state,
                ssl_list_status: 'error',
                ssl_list_error: action.ssl_list_error
            };
        case SSL_LIST_RESET:
            return {
                ...state,
                ssl_list_status: null,
                ssl_list_data: null,
                ssl_list_error: null,
                ssl_list_meta: null
            };
        case GET_SSL_PRODUCTS:
            return {
                ...state,
                ssl_products_status: 'loading',
                ssl_products_data: null,
                ssl_products_error: null
            };
        case GET_SSL_PRODUCTS_SUCCESS:
            return {
                ...state,
                ssl_products_status: 'success',
                ssl_products_data: action.ssl_products_data
            };
        case GET_SSL_PRODUCTS_ERROR:
            return {
                ...state,
                ssl_products_status: 'error',
                ssl_products_error: action.ssl_products_error
            };

        case PURCHASE_SSL_PRODUCT_RESET:
            return {
                ...state,
                ssl_purchase_status: null,
                ssl_purchase_data: null,
                ssl_purchase_error: null
            };

        case PURCHASE_SSL_PRODUCT:
            return {
                ...state,
                ssl_purchase_status: 'loading',
                ssl_purchase_data: null,
                ssl_purchase_error: null
            };
        case PURCHASE_SSL_PRODUCT_SUCCESS:
            return {
                ...state,
                ssl_purchase_status: 'success',
                ssl_purchase_data: action.ssl_purchase_data
            };
        case PURCHASE_SSL_PRODUCT_ERROR:
            return {
                ...state,
                ssl_purchase_status: 'error',
                ssl_purchase_error: action.ssl_purchase_error
            };

        case SSL_SEARCH_RESET:
            return {
                ...state,
                ssl_search_status: null,
                ssl_search_data: null,
                ssl_search_error: null
            };

        case SSL_SEARCH_REQUEST:
            return {
                ...state,
                ssl_search_status: 'loading',
                ssl_search_data: null,
                ssl_search_error: null
            };

        case SSL_SEARCH_SUCCESS:
            return {
                ...state,
                ssl_search_status: 'success',
                ssl_search_data: action.ssl_search_data
            };

        case SSL_SEARCH_ERROR:
            return {
                ...state,
                ssl_search_status: 'error',
                ssl_search_error: action.ssl_search_error
            };

        /**********************************************************************************************************
         *   VPS
         **********************************************************************************************************/
        case VPS_LIST_REQUEST:
            return {
                ...state,
                vps_list_status: 'loading',
                vps_list_data: null,
                vps_list_error: null
            };
        case VPS_LIST_SUCCESS:
            return {
                ...state,
                vps_list_status: 'success',
                vps_list_data: action.vps_list_data,
                vps_list_meta: action.vps_list_meta
            };
        case VPS_LIST_ERROR:
            return {
                ...state,
                vps_list_status: 'error',
                vps_list_error: action.vps_list_error
            };

        case VPS_LIST_MORE_REQUEST:
            return {
                ...state,
                vps_list_more_status: 'loading',
                vps_list_error: null
            };

        case VPS_LIST_MORE_SUCCESS:
            return {
                ...state,
                vps_list_more_status: 'success',
                vps_list_data: action.vps_list_data,
                vps_list_meta: action.vps_list_meta
            };

        case VPS_LIST_MORE_ERROR:
            return {
                ...state,
                vps_list_more_status: 'error',
                vps_list_error: action.vps_list_error
            };

        case VPS_SEARCH_RESET:
            return {
                ...state,
                vps_search_status: null,
                vps_search_data: null,
                vps_search_error: null
            };

        case VPS_SEARCH_REQUEST:
            return {
                ...state,
                vps_search_status: 'loading',
                vps_search_data: null,
                vps_search_error: null
            };

        case VPS_SEARCH_SUCCESS:
            return {
                ...state,
                vps_search_status: 'success',
                vps_search_data: action.vps_search_data
            };

        case VPS_SEARCH_ERROR:
            return {
                ...state,
                vps_search_status: 'error',
                vps_search_error: action.vps_search_error
            };

        /**********************************************************************************************************
         *   GSuite
         **********************************************************************************************************/
        case GSUITE_LIST_REQUEST:
            return {
                ...state,
                gsuite_list_status: 'loading',
                gsuite_list_data: null,
                gsuite_list_meta: null,
                gsuite_list_error: null
            };
        case GSUITE_LIST_SUCCESS:
            return {
                ...state,
                gsuite_list_status: 'success',
                gsuite_list_data: action.gsuite_list_data,
                gsuite_list_meta: action.gsuite_list_meta
            };
        case GSUITE_LIST_ERROR:
            return {
                ...state,
                gsuite_list_status: 'error',
                gsuite_list_error: action.gsuite_list_error
            };
        case GSUITE_LIST_MORE_REQUEST:
            return {
                ...state,
                gsuite_list_more_status: 'loading',
                gsuite_list_status: 'success'
            };
        case GSUITE_LIST_MORE_SUCCESS:
            return {
                ...state,
                gsuite_list_status: 'success',
                gsuite_list_data: action.gsuite_list_data,
                gsuite_list_meta: action.gsuite_list_meta,
                gsuite_list_more_status: 'success'
            };
        case GSUITE_LIST_MORE_ERROR:
            return {
                ...state,
                gsuite_list_status: 'error',
                gsuite_list_error: action.gsuite_list_error,
                gsuite_list_more_status: 'error'
            };

        case GSUITE_SEARCH_RESET:
            return {
                ...state,
                gsuite_search_status: null,
                gsuite_search_data: null,
                gsuite_search_error: null
            };

        case GSUITE_SEARCH_REQUEST:
            return {
                ...state,
                gsuite_search_status: 'loading',
                gsuite_search_data: null,
                gsuite_search_error: null
            };

        case GSUITE_SEARCH_SUCCESS:
            return {
                ...state,
                gsuite_search_status: 'success',
                gsuite_search_data: action.gsuite_search_data
            };

        case GSUITE_SEARCH_ERROR:
            return {
                ...state,
                gsuite_search_status: 'error',
                gsuite_search_error: action.gsuite_search_error
            };

        /**********************************************************************************************************
         *   MS365
         **********************************************************************************************************/
        case MS365_LIST_REQUEST:
            return {
                ...state,
                ms365_list_status: 'loading',
                ms365_list_data: null,
                ms365_list_meta: null,
                ms365_list_error: null
            };
        case MS365_LIST_SUCCESS:
            return {
                ...state,
                ms365_list_status: 'success',
                ms365_list_data: action.ms365_list_data,
                ms365_list_meta: action.ms365_list_meta
            };
        case MS365_LIST_ERROR:
            return {
                ...state,
                ms365_list_status: 'error',
                ms365_list_error: action.ms365_list_error
            };
        case MS365_LIST_MORE_REQUEST:
            return {
                ...state,
                ms365_list_more_status: 'loading',
                ms365_list_status: 'success'
            };
        case MS365_LIST_MORE_SUCCESS:
            return {
                ...state,
                ms365_list_status: 'success',
                ms365_list_data: action.ms365_list_data,
                ms365_list_meta: action.ms365_list_meta,
                ms365_list_more_status: 'success'
            };
        case MS365_LIST_MORE_ERROR:
            return {
                ...state,
                ms365_list_status: 'error',
                ms365_list_error: action.ms365_list_error,
                ms365_list_more_status: 'error'
            };

        case MS365_SEARCH_RESET:
            return {
                ...state,
                ms365_search_status: null,
                ms365_search_data: null,
                ms365_search_error: null
            };

        case MS365_SEARCH_REQUEST:
            return {
                ...state,
                ms365_search_status: 'loading',
                ms365_search_data: null,
                ms365_search_error: null
            };

        case MS365_SEARCH_SUCCESS:
            return {
                ...state,
                ms365_search_status: 'success',
                ms365_search_data: action.ms365_search_data
            };

        case MS365_SEARCH_ERROR:
            return {
                ...state,
                ms365_search_status: 'error',
                ms365_search_error: action.ms365_search_error
            };

        case DOMAIN_BULK_MANAGE_UPDATE:
            return {
                ...state,
                domain_bulk_manage_update: action.domain_bulk_manage_update
            };

        /**********************************************************************************************************
         *   INITIAL/DEFAULT STATES
         **********************************************************************************************************/
        case SERVICES_INITIAL_STATE:
            return initialState;

        default:
            return state;
    }
};

export default servicesReducer;
