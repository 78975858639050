/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { queryOptions, useQuery } from '@tanstack/react-query';
import { katanaQueryKeys } from 'containers/katana/queries/katanaQueryKeys';
import type { KatanaNamespace } from 'containers/katana/types';
import { queryClient } from 'store/queryClient';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { KATANA_API } from 'utilities/api/katana';

function createQueryKey(serviceID: KatanaNamespace.ServiceId) {
    return katanaQueryKeys.katana.meta.socials();
}

function createQueryOptions(serviceID: KatanaNamespace.ServiceId) {
    return queryOptions({
        queryKey: createQueryKey(serviceID),
        queryFn: () => KATANA_API.katana.site.service_id.meta.socials.GET(serviceID),
        staleTime: Infinity,
        enabled: Boolean(serviceID),
        select: (data) => {
            if (data?.status === 200) {
                return data.data;
            }
        },
    });
}

function prefetchQuery(serviceID: KatanaNamespace.ServiceId) {
    return queryClient.prefetchQuery(createQueryOptions(serviceID));
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Get the socials meta data for the service
 */
function _useQuery(serviceID: KatanaNamespace.ServiceId) {
    return useQuery(createQueryOptions(serviceID));
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const getSocialsDefinitions = Object.freeze({
    useQuery: _useQuery,
    prefetchQuery,
    createQueryKey,
    createQueryOptions,
});
