import { authenticationQueryKeys } from 'containers/login/queries/queryKeys';
import { API } from 'utilities/api/login';
import { createNXQuery } from 'utilities/methods/tanstack/createQuery';

/**********************************************************************************************************
 *   QUERY START
 **********************************************************************************************************/
export const listTwoFactor = createNXQuery(() => ({
    queryFn: API.login.twoFactor.listMethods,
    queryKey: authenticationQueryKeys.listTwoFactor()
}));
