import { handleRecursiveAttributesCaveatController } from 'containers/katana/queries/methods/handleRecursiveAttributesCaveatController';
import { mergeWithAttributeObjectPicker } from 'containers/katana/queries/methods/mergeWithAttributeObjectPicker';
import { recursivelyTransformPropertyAttachmentData } from 'containers/katana/queries/methods/recursivelyTransformPropertyAttachmentData';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import type { KatanaNamespace } from 'containers/katana/types';
import _ from 'lodash';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Params = {
    serviceID: KatanaNamespace.ServiceId;
    sectionDefinitionID?: KatanaNamespace.SectionDefinitionId;
};

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export function useHandleDynamicSectionMethods({ serviceID, sectionDefinitionID }: Params) {
    const { data: section_definitions_data } = katanaQuery.serviceID.meta.sectionDefinitions.useQuery(serviceID);
    const sectionDefinitionData = sectionDefinitionID ? section_definitions_data?.[sectionDefinitionID] : undefined;

    function handleModifyEntireAttributeObject(attributes: Partial<Artah.Katana.Site.ID.Section.ID.GET._200['data']>) {
        if (!_.has(attributes, 'properties')) {
            return attributes;
        }

        const { properties } = attributes;
        if (!properties) {
            return attributes;
        }

        const caveatsModifiedAttributes = handleRecursiveAttributesCaveatController({ sectionDefinitionData, attributes });
        // Convert the values so that we're only sending the attachment id
        const finalPropertiesObject = recursivelyTransformPropertyAttachmentData({
            sectionDefinitionData,
            properties
        });
        const updatedAttributes = _.mergeWith(
            _.cloneDeep(caveatsModifiedAttributes),
            { properties: finalPropertiesObject },
            mergeWithAttributeObjectPicker
        );
        return updatedAttributes;
    }

    /***** HOOK RESULTS *****/
    return {
        handleModifyEntireAttributeObject,
    };
}
