import Text from 'components/Utils/Text';
import { useOrderingFormContext } from 'containers/orderingForm/contexts/useOrderingFormContext';
import { PageHeader } from 'containers/orderingForm/pageHeader';
import { Searchbar } from 'containers/orderingForm/pages/registerPage/searchbar';
import { TransferAvailabilityConfirmation } from 'containers/orderingForm/pages/transferPage/transferAvailabilityConfirmation';
import { TransferSuggestions } from 'containers/orderingForm/pages/transferPage/transferSuggestions';
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type TransferPage = React.FC<TransferPageProps>;
type TransferPageProps = {
    name: string;
    domainSearchValue: string;
    setDomainSearchValue: React.Dispatch<React.SetStateAction<string>>;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const TransferPage: TransferPage = ({ name, domainSearchValue, setDomainSearchValue }) => {
    /***** HOOKS *****/
    const { currentPage } = useOrderingFormContext();
    const isMobile = useAppViewport(['xs', 'sm']);

    /***** RENDER *****/
    if (currentPage !== name) return;

    return (
        <>
            <PageHeader title="Domain Name Transfer" />
            <Searchbar domainSearchValue={domainSearchValue} setDomainSearchValue={setDomainSearchValue} />
            <TransferAvailabilityConfirmation />
            {isMobile ? (
                <Text size--s semiBold secondary align--center>
                    Own Any Of These?
                </Text>
            ) : (
                <Text bold secondary align--center>
                    Own Any Of These?
                </Text>
            )}
            <TransferSuggestions />
        </>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
