import { createBrandTokens } from "config/tokens/types";

/**********************************************************************************************************
 *   TOKEN START
**********************************************************************************************************/
/**
 * @warning DO NOT USE INSIDE CCP
 */
export const _intaserveBrand = createBrandTokens("INT", {
    ExampleComponent: {
        "INT_ExampleComponent__background": "#f5f7f8",
    }
});
