import Text from 'components/Utils/Text';
import AppStore from 'config/containers/promotions/vipcontrolApp/assets/app-store.png';
import PlayStore from 'config/containers/promotions/vipcontrolApp/assets/play-store.png';
import BannerBgRight from 'config/containers/promotions/vipcontrolApp/assets/qr-code.png';
import BannerBgLeft from 'config/containers/promotions/vipcontrolApp/assets/screenshot.png';
import { useBrandStore } from 'config/hooks/useBrandStore';
import { promoBannerDismissKeys } from 'containers/dashboard/Components/consts';
import './_vipcontrolApp.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const _VIPControlAppBanner = () => {
    /***** HOOKS *****/
    const { application } = useBrandStore();

    /***** RENDER *****/
    return (
        <div className="vipcontrolAppBanner">
            <img className="vipcontrolAppBanner__bgImage vipcontrolAppBanner__bgImage--left" src={BannerBgLeft} alt="App Screenshot" />
            <div className="vipcontrolAppBanner__container">
                <div className="vipcontrolAppBanner__tile">
                    <div className="vipcontrolAppBanner__left">
                        <Text size--xl semiBold className="vipcontrolAppBanner__left--heading">
                            Try our new {application} App!
                        </Text>
                        <Text size--m medium className="vipcontrolAppBanner__left--body">
                            Secure your account with app based Two-factor Authentication, check notifications, pay bills and renew your domain names.
                            Have a look and let us know what you think!
                        </Text>
                    </div>
                    <div className="vipcontrolAppBanner__right">
                        <a
                            className="vipcontrolAppBanner__getStartedContainer"
                            href="https://apps.apple.com/us/app/vipcontrol/id6448714430"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img className="vipcontrolAppBanner__store" src={AppStore} alt="Download on the App Store" />
                        </a>
                        <a
                            className="vipcontrolAppBanner__getStartedContainer"
                            href="https://play.google.com/store/apps/details?id=com.vipcontrol"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img className="vipcontrolAppBanner__store" src={PlayStore} alt="GET IT ON Google Play" />
                        </a>
                    </div>
                </div>
            </div>
            <img className="vipcontrolAppBanner__bgImage vipcontrolAppBanner__bgImage--right" src={BannerBgRight} alt="QR Code" />
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export const VIPControlAppBanner = Object.assign(_VIPControlAppBanner, {
    DISMISS_KEY: promoBannerDismissKeys.vipcontrolAppBannerDismiss
});
