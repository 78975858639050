import { useNavigate, useParams } from '@tanstack/react-router';
import { CustomDropdown } from 'components/Dropdowns/CustomDropdown';
import SelectOptions from 'components/Dropdowns/SelectDropdown/Options';
import Title from 'components/Title';
import { pushNotification } from 'components/Toast/functions';
import { renderForBrands } from 'config/brandRenderer/helper';
import { getTicketSubmitUrl } from 'containers/support/methods';
import React from 'react';
import { useSelector } from 'react-redux';
import { isRouteActive } from 'router/utils/isRouteActive';
import { API } from 'utilities/api/hosting';
import { createPopup, getDataFromSuccessResponse, getErrorFromFailResponse } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _HostingSharedTitle = () => {
    /***** HOOKS *****/
    const navigate = useNavigate();
    const hosting_information_data = useSelector((state: any) => state.hosting.hosting_information_data);
    const { serviceId } = useParams({ from: '/my-services/hosting/$serviceId' });

    /***** DERIVED *****/
    const { type, included } = hosting_information_data ?? {};
    const { domain } = hosting_information_data?.attributes ?? {};
    const [product] = included?.filter(({ type }) => type === 'product') ?? [];

    /***** FUNCTIONS *****/
    const handleLogin = () => {
        const { closePopup, goToTargetUrl } = createPopup();

        API.hosting.GET.login
            .cpanel({ id: serviceId })
            .then((response) => {
                const data = getDataFromSuccessResponse(response);
                goToTargetUrl(data.attributes.url);
            })
            .catch((error) => {
                pushNotification(getErrorFromFailResponse(error));
                closePopup();
            });
    };

    /***** RENDER HELPERS *****/
    const cpanelButton = {
        label: 'cPanel Login',
        type: 'onClick',
        color: 'notice',
        onClick: (e) => {
            e.preventDefault();
            handleLogin();
        }
    };

    type TitleActions =
        | (
              | {
                    label: string;
                    type: string;
                    color: string;
                    onClick: (e: any) => void;
                }
              | React.ReactElement
          )[]
        | null;
    const titleActions: TitleActions = renderForBrands(['ventra']) ? [cpanelButton] : null;

    if (isRouteActive({ path: '/support' }) && renderForBrands(['ventra'])) {
        titleActions?.push(
            <CustomDropdown
                className="TitleShared__singleDropdown"
                key="dropdown"
                label={({ labelRef, dropdownTitleOnClick }) => (
                    <button className="TitleShared__singleDropdownButton" ref={labelRef} type="button" onClick={() => dropdownTitleOnClick()}>
                        Web Hosting Help
                        <i className="item__icon icon icon-chevron-down" />
                    </button>
                )}
                render={() => (
                    <SelectOptions
                        options={[
                            {
                                label: 'Web Hosting Help Articles',
                                onSelect: () =>
                                    navigate({
                                        to: '/support/support-centre',
                                        search: {
                                            categories: 'products, web-hosting-products'
                                        }
                                    })
                            },
                            {
                                label: 'Submit a Technical Support eTicket for this Service',
                                onSelect: () => navigate({ to: getTicketSubmitUrl({ department: 'technical-support', service: serviceId }) })
                            },
                            {
                                label: 'Submit a Billing eTicket for this Service',
                                onSelect: () => navigate({ to: getTicketSubmitUrl({ department: 'accounts-billing', service: serviceId }) })
                            }
                        ]}
                    />
                )}
            />
        );
    }

    /***** RENDER *****/
    return <Title serviceTitle={domain} serviceSubTitle={`${product?.attributes?.name} (Shared)`} serviceType={type} action={titleActions} />;
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
