/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { addQueryArgs } from '@wordpress/url';
import type { AxiosResponse } from 'axios';
import { merge } from 'lodash';
import { ventraIPWordpressAxios } from 'store/ventraIPWordpressAxios';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { SUPPORT_CENTRE_API_DEFAULTS } from 'utilities/api/ventraip-website-wp-support-centre/consts';
import type { VentraIPWordpressSupportCentreAPI } from 'utilities/api/ventraip-website-wp-support-centre/types';

const V2_API = '/wp/v2';

/**
 * This query param is added to all the wordpress support articles urls, just so that there is a different cache key for requests coming from vip.ventraip.com.au vs ventraip.com.au
 */
const queryParamForSeparateCacheKey = { src: import.meta.env.VITE_CCP_ENVIRONMENT === 'production' ? 'vip' : 'vip-development' };

/**
 * You might get a CORS error if you're trying to access the VentraIP Wordpress API from your local.
 * You can use https://chromewebstore.google.com/detail/allow-cors-access-control/lhobafahddgcelffkeicbaginigeejlf?pli=1 to bypass this.
 */
export const WP_SUPPORT_CENTRE_API = {
    support_article_categories: {
        GET: (
            params: Partial<VentraIPWordpressSupportCentreAPI.SupportArticleParams>
        ): Promise<AxiosResponse<VentraIPWordpressSupportCentreAPI.SupportArticleCategories.Response>> => {
            const mergedParams = merge({}, SUPPORT_CENTRE_API_DEFAULTS.support_article_categories, params, queryParamForSeparateCacheKey);
            return ventraIPWordpressAxios.get(addQueryArgs(`${V2_API}/support-article-categories`, mergedParams));
        }
    },
    pages: {
        GET: (slug: string): Promise<AxiosResponse<VentraIPWordpressSupportCentreAPI.Pages.Response[]>> => {
            return ventraIPWordpressAxios.get(addQueryArgs(`${V2_API}/pages?slug=${slug}`, queryParamForSeparateCacheKey));
        }
    },
    support_articles: {
        GET: (
            params: Partial<VentraIPWordpressSupportCentreAPI.SupportArticleParams>
        ): Promise<AxiosResponse<VentraIPWordpressSupportCentreAPI.SupportArticles.Response>> => {
            const mergedParams = merge({}, SUPPORT_CENTRE_API_DEFAULTS.support_articles, params, queryParamForSeparateCacheKey);
            return ventraIPWordpressAxios.get(addQueryArgs(`${V2_API}/support-articles`, mergedParams));
        },
        single: {
            GET: (
                params: Partial<VentraIPWordpressSupportCentreAPI.SupportArticleParams> & { slug?: string }
            ): Promise<AxiosResponse<VentraIPWordpressSupportCentreAPI.SupportArticles.Response>> => {
                const mergedParams = merge({}, SUPPORT_CENTRE_API_DEFAULTS.support_articles, params, queryParamForSeparateCacheKey);
                return ventraIPWordpressAxios.get(addQueryArgs(`${V2_API}/support-articles`, mergedParams));
            }
        }
    },
    search: {
        GET: (
            params: Partial<VentraIPWordpressSupportCentreAPI.SearchParams>
        ): Promise<AxiosResponse<VentraIPWordpressSupportCentreAPI.SupportSearchResult[]>> => {
            const mergedParams = merge({}, SUPPORT_CENTRE_API_DEFAULTS.search, params, queryParamForSeparateCacheKey);
            return ventraIPWordpressAxios.get(addQueryArgs(`/support-centre/search`, mergedParams));
        }
    }
} as const;
