import React, { Suspense } from 'react';

import { useSuperUserItem } from 'components/StaffMenu/SuperUser/hooks/useSuperUserItem';

const DevTools =
    process.env.NODE_ENV === 'production'
        ? () => null
        : React.lazy(() =>
        // Lazy load in development
            import('@tanstack/router-devtools').then((res) => ({
                default: res.TanStackRouterDevtools
            }))
        );

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const TanStackRouterDevtools = () => {
    /***** HOOKS *****/
    const isEnabled = useSuperUserItem('TOGGLE_ROUTER_DEVTOOLS');

    /***** RENDER *****/
    return <Suspense>{!!isEnabled && <DevTools />}</Suspense>;
};
