import type { API } from 'utilities/api/services';
import { createBaseQueryKey } from 'utilities/methods/tanstack/createBaseQueryKey';

/**********************************************************************************************************
 *   QUERY KEY TREE START
 **********************************************************************************************************/
// prettier-ignore
export const serviceQueryKeys = {
    // prettier-ignore
    services: Object.assign(() => createBaseQueryKey(['services']), {
        suspensionReason: (id?: API.service.get.suspensionReason.Params) => [...serviceQueryKeys.services(), 'suspensionReason', id] as const,
        userExists: (token: API.service.get.userExists.Params) => [...serviceQueryKeys.services(), 'userExists', token] as const,
        approveMove: () => [...serviceQueryKeys.services(), 'approveMove'] as const
    })
};
