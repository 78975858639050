import { useIsMutating } from '@tanstack/react-query';
import { SolidButton } from 'components/Buttons/SolidButton';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import RequestLoader from 'components/Loaders/Request';
import { Flex } from 'components/Utils/Flex';
import { Padding } from 'components/Utils/Padding';
import Text from 'components/Utils/Text';
import { useOrderingFormContext } from 'containers/orderingForm/contexts/useOrderingFormContext';
import 'containers/orderingForm/pages/transferPage/transferButton/_transferButton.scss';
import type { DomainSuggestions, Item } from 'containers/orderingForm/types';
import { useState } from 'react';
import { NXQuery } from 'utilities/query';

type TransferSuggestionButton = React.FC<TransferSuggestionButtonProps>;
type TransferSuggestionButtonProps = {
    suggestion: DomainSuggestions;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const TransferSuggestionButton: TransferSuggestionButton = ({ suggestion }) => {
    const { domain, tld } = suggestion;

    /*****  HOOKS *****/
    const domainData = {
        domain: domain,
        promos: [],
        tlds: [tld],
        action: 'transfer',
    };

    /***** STATE *****/
    const [isAddedButtonHovered, setIsAddedButtonHovered] = useState(false);

    /***** HOOKS *****/
    const { cartToken, setCartToken } = useOrderingFormContext();

    /***** QUERIES *****/
    const { data: cart_data } = NXQuery.orderingForm.cart.cartId.getCart.useQuery(cartToken);
    const { mutate: createCart, isPending: isCreateCartPending } = NXQuery.orderingForm.cart.createCart.useMutation(setCartToken);
    const { mutate: addCartItems, isPending: isCartItemLoading } = NXQuery.orderingForm.cart.cartId.addItems.useMutation();
    const { mutate: removeCartItem, isPending: isRemoveCartItemLoading } = NXQuery.orderingForm.cart.cartId.removeItems.useMutation();
    const { data: domain_availibility_data } = NXQuery.domain.availability.useQuery(domainData);

    const isAddItemMutating = !!useIsMutating({ mutationKey: ['addCartItem'] });
    const isCreateCartMutating = !!useIsMutating({ mutationKey: ['createCart'] });

    const isButtonDisabled = isAddItemMutating || isCreateCartMutating;

    const name = `${domain_availibility_data?.[0].domain}${domain_availibility_data?.[0].tld}`;
    const item = [
        {
            pending: true,
            billing_cycle_id: 8,
            name,
            order_type: 'transfer',
            id: domain_availibility_data?.[0].product_id,
            auto_renew: true,
            epp: 'invalid epp',
        },
    ];

    const added = cart_data?.cart?.items.some((item: Item) => item.name === name.toLowerCase() && item.order_type === 'transfer');
    const itemId = cart_data?.cart?.items.find((item: Item) => item.name === name.toLowerCase())?.uuid;

    /***** FUNCTIONS *****/
    const handleAddItemOnClick = () => {
        if (!cartToken) {
            createCart(item);
        } else {
            addCartItems(
                { items: item, cartToken },
                {
                    onSuccess: () => setIsAddedButtonHovered(false),
                    onError: (response) => {
                        if (response?.errors?.[0]?.details.includes('No token or active cart found')) {
                            createCart(item);
                        }
                    }
                }
            );
        }
    };

    /***** RENDER HELPERS *****/
    const renderAddedButtonContent = () => {
        if (isRemoveCartItemLoading || isCreateCartPending) {
            return <RequestLoader />;
        }

        if (isAddedButtonHovered) {
            return (
                <>
                    <PhosphorIcons.X.Bold />
                    <Text lead--0 semiBold>
                        Remove
                    </Text>
                </>
            );
        }

        return (
            <>
                <PhosphorIcons.Check.Bold />
                <Text lead--0 semiBold>
                    Added
                </Text>
            </>
        );
    };

    /***** RENDER *****/
    switch (true) {
        case added:
            return (
                <SolidButton
                    type="onClick"
                    width="auto"
                    className="transfer__button--added"
                    onMouseOver={() => setIsAddedButtonHovered(true)}
                    onMouseLeave={() => setIsAddedButtonHovered(false)}
                    onClick={() => itemId && cartToken && removeCartItem({ itemId, cartToken })}
                >
                    <Padding x={3}>
                        <Flex align="center">{renderAddedButtonContent()}</Flex>
                    </Padding>
                </SolidButton>
            );

        case domain_availibility_data?.[0].available === false:
            return (
                <SolidButton width="auto" className="registration__button--unavailable">
                    <Padding x={3}>
                        <Flex align="center">
                            <Text lead--0 semiBold>
                                Unavailable
                            </Text>
                        </Flex>
                    </Padding>
                </SolidButton>
            );

        default:
            return (
                <SolidButton
                    type="onClick"
                    width="auto"
                    className="transfer__button"
                    onClick={() => handleAddItemOnClick()}
                    disabled={isButtonDisabled}
                >
                    <Padding x={3}>
                        <Flex align="center">
                            {isCartItemLoading || isCreateCartPending ? (
                                <RequestLoader width={53} />
                            ) : (
                                <>
                                    <PhosphorIcons.Plus.Bold />
                                    <Text lead--0 semiBold>
                                        Add
                                    </Text>
                                </>
                            )}
                        </Flex>
                    </Padding>
                </SolidButton>
            );
    }
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
