import { AdvancedListContext } from 'components/Form/AdvancedList/context';
import { HookForm } from 'components/Form/HookForm';
import { ValidationMessage } from 'components/Form/ValidationMessage';
import React from 'react';
import { useController } from 'react-hook-form';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type AdvancedListContainer = React.FC<{
    children: React.ReactNode;
    name: string;
}>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * React Hook Form variant of the AdvancedListSlim component - This does not have feature parity with the original ReduceForm
 * variant due to the complexity of the styling the was present in the original component. If you need to use a feature from
 * the original, it will need to be re-added to the component.
 */
export const _AdvancedListContainer: AdvancedListContainer = ({ children, name }) => {
    /***** HOOKS *****/
    const { fieldState } = useController({ name });

    /***** RENDER *****/
    return (
        <div className="AdvancedList">
            <AdvancedListContext.Provider value={name}>{children}</AdvancedListContext.Provider>
            <ValidationMessage.HookForm.Default {...HookForm.getMeta(fieldState)} />
        </div>
    );
};
