import { useMutation } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';
import { getInvoiceActionUrl } from 'components/Lightboxes/OverlayLightbox/Components/invoice/invoiceUrlLightbox/methods';
import { API } from 'utilities/api/ms365';
import { handleDefaultSuccessNotification } from 'utilities/methods/commonActions/handleDefaultSuccessNotification';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useMutation(id: string) {
    const navigate = useNavigate();
    async function mutationFn(attributes: any) {
        const response = await API.ms365.POST.updateConfig(id, attributes);
        return response;
    }

    /***** HOOK RESULTS *****/
    return useMutation({
        mutationFn,
        onSuccess: (response) => {
            handleDefaultSuccessNotification(response);
            navigate({ to: getInvoiceActionUrl('pay', response.data.id) });
        }
    });
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const updateMs365ConfigData = Object.freeze({
    useMutation: _useMutation
});
