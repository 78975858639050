/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { batchActions } from 'redux-batched-actions';

/*   ACTIONS
 *****************************************************/
import { pushNotification } from 'components/Toast/functions';
import { getGSuiteList, getHostingList, getMs365List } from 'containers/services/action';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { API as SERVICES } from 'utilities/api/services';
import { API as VPS } from 'utilities/api/vps';
import { getDataFromSuccessResponse, getErrorFromFailResponse, getIncludedObjBasedOnType } from 'utilities/methods/commonActions';
import {
    GSUITE_DELETE_REQUEST,
    GSUITE_DELETE_SUCCESS,
    GSUITE_REVERSE_CANCEL_ERROR,
    GSUITE_REVERSE_CANCEL_REQUEST,
    GSUITE_REVERSE_CANCEL_SUCCESS,
    MS365_DELETE_SERVICE_ERROR,
    MS365_DELETE_SERVICE_REQUEST,
    MS365_DELETE_SERVICE_SUCCESS,
    MS365_REVERSE_DELETE_SERVICE_ERROR,
    MS365_REVERSE_DELETE_SERVICE_REQUEST,
    MS365_REVERSE_DELETE_SERVICE_SUCCESS,
    SERVICE_CANCEL_ERROR,
    SERVICE_CANCEL_REQUEST,
    SERVICE_CANCEL_SUCCESS,
    SERVICE_CHECK_CANCEL_ERROR,
    SERVICE_CHECK_CANCEL_STATUS,
    SERVICE_CHECK_CANCEL_SUCCESS,
    SERVICE_REVERSE_CANCEL_ERROR,
    SERVICE_REVERSE_CANCEL_REQUEST,
    SERVICE_REVERSE_CANCEL_SUCCESS,
    VPS_CANCEL_ERROR,
    VPS_CANCEL_REQUEST,
    VPS_CANCEL_SUCCESS
} from './actionTypes';
import { handleDefaultSuccessNotification } from 'utilities/methods/commonActions/handleDefaultSuccessNotification';

/**********************************************************************************************************
 *   CANCEL HOSTING
 **********************************************************************************************************/
export const checkCancellationInfo = (id, additional = { additional_services: [] }, forceStop = false) => {
    return (dispatch) => {
        dispatch({
            type: SERVICE_CHECK_CANCEL_STATUS
        });
        SERVICES.service.post
            .cancelInfo(id, additional)
            .then((response) => {
                const service_check_cancel_data = getDataFromSuccessResponse(response);
                let sanitisedData = [];
                const checkArrayData = [];
                const relatedArray = [];

                if (service_check_cancel_data && service_check_cancel_data.related.length > 0) {
                    service_check_cancel_data.related.forEach((item) => {
                        relatedArray.push({
                            type: 'service',
                            id: item.id
                        });
                    });

                    if (!forceStop) dispatch(checkCancellationInfo(id, { additional_services: relatedArray }, true));
                } else if (service_check_cancel_data && service_check_cancel_data.related.length === 0) {
                    sanitisedData = [
                        {
                            ...service_check_cancel_data.check[0].attributes,
                            id: service_check_cancel_data.check[0].attributes.service_id
                        }
                    ];
                }

                if (additional.additional_services.length > 0) {
                    const { check, related } = service_check_cancel_data;
                    const primary = check.find((serv) => Number(serv.attributes.service_id) === Number(id));
                    sanitisedData.push(primary.attributes);

                    Object.keys(check).forEach((key) => {
                        const { attributes } = check[key];
                        if (attributes.service_id !== id) checkArrayData.push({ ...attributes });
                    });

                    Object.keys(related).forEach((key, index) => {
                        const { attributes, included, id } = related[key];
                        const { domain } = attributes;
                        const product = getIncludedObjBasedOnType(included, 'product');

                        sanitisedData[index + 1] = {
                            ...checkArrayData[index],
                            id,
                            domain,
                            name: product.attributes.name
                        };
                    });
                }

                dispatch({
                    type: SERVICE_CHECK_CANCEL_SUCCESS,
                    service_check_cancel_data: sanitisedData
                });
            })
            .catch((error) => {
                const service_check_cancel_error = getErrorFromFailResponse(error);
                pushNotification(service_check_cancel_error);
                dispatch({
                    type: SERVICE_CHECK_CANCEL_ERROR,
                    service_check_cancel_error
                });
            });
    };
};

export const submitCancellationRequest = (id, attributes, additional = [], fetchService) => {
    return (dispatch) => {
        if (attributes) {
            const payload = {
                ...attributes,
                additional_services: [...additional]
            };
            dispatch({
                type: SERVICE_CANCEL_REQUEST
            });
            SERVICES.service.post
                .cancel(id, payload)
                .then((response) => {
                    const service_cancel_data = getDataFromSuccessResponse(response);
                    pushNotification(service_cancel_data);
                    dispatch({
                        type: SERVICE_CANCEL_SUCCESS,
                        service_cancel_data
                    });
                    if (fetchService) {
                        const { history, list } = fetchService;
                        list();
                        history();
                    }
                })
                .catch((error) => {
                    const service_cancel_error = getErrorFromFailResponse(error);
                    pushNotification(service_cancel_error);
                    dispatch({
                        type: SERVICE_CANCEL_ERROR,
                        service_cancel_error
                    });
                });
        } else {
            pushNotification();
        }
    };
};

export const cancelServiceRequest = (id, fetchService) => {
    return (dispatch) => {
        dispatch({
            type: SERVICE_REVERSE_CANCEL_REQUEST
        });
        SERVICES.service.service_id.cancellation
            .DELETE(id)
            .then((response) => {
                handleDefaultSuccessNotification(response);
                dispatch({
                    type: SERVICE_REVERSE_CANCEL_SUCCESS,
                    service_reverse_cancel_data: response
                });
                if (fetchService) {
                    const { history, list } = fetchService;
                    list();
                    history();
                }
            })
            .catch((error) => {
                const service_reverse_cancel_error = getErrorFromFailResponse(error);
                pushNotification(service_reverse_cancel_error);
                dispatch({
                    type: SERVICE_REVERSE_CANCEL_ERROR,
                    service_reverse_cancel_error
                });
            });
    };
};

/**********************************************************************************************************
 *   DELETE GSUITE
 **********************************************************************************************************/
export const deleteGsuite = (id, payload, history) => {
    return (dispatch) => {
        dispatch({
            type: GSUITE_DELETE_REQUEST,
            gsuite_cancel_status: 'loading'
        });
        SERVICES.service.post
            .cancel(id, payload)
            .then((response) => {
                const gsuite_cancel_data = getDataFromSuccessResponse(response);
                pushNotification(gsuite_cancel_data);
                dispatch(
                    batchActions([
                        {
                            type: GSUITE_DELETE_SUCCESS,
                            gsuite_cancel_data
                        },
                        getGSuiteList()
                    ])
                );
                if (history) history.push('/my-services/gsuite');
            })
            .catch((error) => {
                const gsuite_cancel_error = getErrorFromFailResponse(error);
                pushNotification(gsuite_cancel_error);
                dispatch({
                    type: DOMAIN_CANCEL_ERROR,
                    gsuite_cancel_error
                });
            });
    };
};

export const cancelGSuiteRequest = (id) => {
    return (dispatch) => {
        dispatch({
            type: GSUITE_REVERSE_CANCEL_REQUEST
        });
        SERVICES.service.service_id.cancellation
            .DELETE(id)
            .then((response) => {
                handleDefaultSuccessNotification(response);
                dispatch({
                    type: GSUITE_REVERSE_CANCEL_SUCCESS,
                    gsuite_reverse_cancel_data: response
                });
            })
            .catch((error) => {
                const gsuite_reverse_cancel_error = getErrorFromFailResponse(error);
                pushNotification(gsuite_reverse_cancel_error);
                dispatch({
                    type: GSUITE_REVERSE_CANCEL_ERROR,
                    gsuite_reverse_cancel_error
                });
            });
    };
};

/**********************************************************************************************************
 *   MS365 DELETE SERVICE
 **********************************************************************************************************/
export const deleteMs365Service = (id, attributes) => {
    return (dispatch) => {
        dispatch({
            type: MS365_DELETE_SERVICE_REQUEST
        });
        SERVICES.service.post
            .cancel(id, attributes)
            .then(() => {
                dispatch({ type: MS365_DELETE_SERVICE_SUCCESS });
            })
            .catch(() => {
                dispatch({ type: MS365_DELETE_SERVICE_ERROR });
            });
    };
};

export const reverseMs365DeleteRequest = (id) => {
    return (dispatch) => {
        dispatch({
            type: MS365_REVERSE_DELETE_SERVICE_REQUEST
        });
        SERVICES.service.service_id.cancellation
            .DELETE(id)
            .then((response) => {
                handleDefaultSuccessNotification(response);
                dispatch(batchActions([{ type: MS365_REVERSE_DELETE_SERVICE_SUCCESS }, getMs365List()]));
            })
            .catch((error) => {
                pushNotification(getErrorFromFailResponse(error));
                dispatch({ type: MS365_REVERSE_DELETE_SERVICE_ERROR });
            });
    };
};

/**********************************************************************************************************
 *   CANCEL VPS
 **********************************************************************************************************/
export const cancelVPS = (id, attributes) => {
    return (dispatch) => {
        dispatch({
            type: VPS_CANCEL_REQUEST
        });
        VPS.cancelVPS(id, attributes)
            .then((response) => {
                const vps_cancel_data = getDataFromSuccessResponse(response);
                pushNotification(vps_cancel_data);
                dispatch(
                    batchActions([
                        {
                            type: VPS_CANCEL_SUCCESS,
                            vps_cancel_data
                        }
                    ])
                );
                getHostingList();
            })
            .catch((error) => {
                const vps_cancel_error = getErrorFromFailResponse(error);
                pushNotification(vps_cancel_error);
                dispatch({
                    type: VPS_CANCEL_ERROR,
                    vps_cancel_error
                });
            });
    };
};
