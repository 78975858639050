import _ from 'lodash';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Entry = Record<string, any> | Array<any> | string | number;

/**
 * @param predicate - A function that determines if the next entry should be included in the result
 * @returns
 */
export function getAllPaths(obj: Entry, parentKey = '', predicate?: (entry: Entry) => boolean): string[] {
    if (predicate && !predicate(obj)) {
        if (parentKey) {
            return [parentKey];
        }
        return [];
    }
    if (_.isArray(obj)) {
        if (obj.length === 0) {
            return [parentKey];
        }
        return obj.flatMap((value, index) => getAllPaths(value, `${parentKey}[${index}]`, predicate));
    }

    if (_.isObject(obj)) {
        if (_.isEmpty(obj)) {
            return [parentKey];
        }
        return _.keys(obj).flatMap((key) => getAllPaths(obj[key], parentKey ? `${parentKey}.${key}` : key, predicate));
    }

    if (!_.isObject(obj)) {
        return [parentKey];
    }

    return [];
}
