/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useQueryClient, type dataTagSymbol, type QueryKey, type QueryState } from '@tanstack/react-query';
import { shallow } from '@tanstack/react-store';
import { useEffect, useState } from 'react';
import { NXQuery } from 'utilities/query';

/**
 * Returns the current state of a query based on a queryKey - this DOES NOT trigger a query and behaves similar to `getQueryData` but is reactive.
 * To prevent excessive re-renders, this only triggers a re-render when the selected value is updated
 *
 * If this is a pattern that we require more down the future, we will want to add it to NXQuery.utils and have it directly accept a `createNXQuery` object with a select function. I have not done
 * this at the moment because it is not a trivial change.
 */
const useQueryState = <TQueryKey extends QueryKey, TSelected, TData = TQueryKey extends { [dataTagSymbol]: infer Data } ? Data : unknown>(opts: {
    queryKey: TQueryKey;
    select: (result: QueryState<TData, Error> | undefined) => TSelected;
}) => {
    const queryClient = useQueryClient();
    const [state, setState] = useState(opts.select(queryClient.getQueryCache().find<TData>({ queryKey: opts.queryKey })?.state));

    useEffect(() => {
        const unsubscribe = queryClient.getQueryCache().subscribe((listener) => {
            if (shallow(listener.query.queryKey, opts.queryKey)) {
                const selected = opts.select(listener.query.state);

                // only update state when the selected value changes
                if (shallow(selected, state)) {
                    setState(opts.select(listener.query.state));
                }
            }
        });

        return unsubscribe;
    }, [opts.queryKey]);

    return state;
};

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export function useIsVPN() {
    /***** HOOKS *****/
    const is_vpn = useQueryState({
        queryKey: NXQuery.auth.login.checkToken.createQueryKey(),
        select: (state) => state?.data?.data?.attributes?.is_vpn
    });

    /***** HOOK RESULTS *****/
    return Boolean(is_vpn);
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
