import { z } from 'zod';

export const formSchema = (eligibilityFields: Array<string>) => {
    const hasEligibilityNumber = eligibilityFields.includes('eligibilityNumber');

    const dynamicFieldsSchema = eligibilityFields.reduce(
        (acc, field) => {
            if (hasEligibilityNumber) {
                if (field !== 'eligibilityType' && field !== 'eligibilityNumber') {
                    acc[field] = z.string().nullable().optional();
                    return acc;
                }
            }

            if (field === 'whoisOpt-out') {
                acc[field] = z.string().optional();
            } else {
                acc[field] = z.string().min(1, { message: 'Required' });
            }

            return acc;
        },
        {} as Record<string, z.ZodTypeAny>
    );

    return z
        .object({
            registrationPeriod: z.string().min(1, { message: 'Required' }),
            contactDetails: z.string(),
            eligibilityType: z.string(),
            eligibilityNumber: z.string(),
            isUsedForVipSites: z.boolean(),
            autoRenew: z.boolean(),
            idProtection: z.boolean(),
            legalName: z.string(),
            firstName: z.string(),
            lastName: z.string(),
            emailAddress: z.string(),
            phoneNumber: z.string(),
            companyName: z.string(),
            address: z.string(),
            suburb: z.string(),
            country: z.string(),
            state: z.string(),
            postcode: z.string(),
            epp: z.string().optional(),
            ...dynamicFieldsSchema
        })
        .superRefine((data, ctx) => {
            // Check if contactDetails is 'newDetails'
            if (data.contactDetails === 'newDetails') {
                // Conditionally validate the fields
                const requiredFields = ['firstName', 'lastName', 'emailAddress', 'phoneNumber', 'address', 'suburb', 'country', 'state', 'postcode'];

                requiredFields.forEach((field) => {
                    if (data[field].trim() === '') {
                        ctx.addIssue({
                            path: [field],
                            message: 'Required',
                            code: z.ZodIssueCode.custom
                        });
                    }
                });
            }
        });
};
