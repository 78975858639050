import { useQuery } from '@tanstack/react-query';
import { API } from 'utilities/api/email';
import { createBaseQueryKey } from 'utilities/methods/tanstack/createBaseQueryKey';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
function createQueryKey() {
    return createBaseQueryKey(['email', 'details']);
}

function _useQuery() {
    return useQuery({
        queryKey: createQueryKey(),
        queryFn: () => API.email.get.product(),
        select: (response) => {
            if (response?.status === 200) {
                return response.data;
            }
        }
    });
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export const emailProductDetails = Object.freeze({
    useQuery: _useQuery
});
