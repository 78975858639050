/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { ventraSynMigrationBanners, ventraSynMigrationEndOfLifeDate, ventraSynMigrationShowEndOfLifeDate } from 'config/config';
import { useSelector } from 'react-redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { SolidButton } from 'components/Buttons/SolidButton';
import Transition from 'components/Transition';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import '../_ventraSynMigration.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const VentraSynBanner = () => {
    /***** SELECTORS *****/
    const vsm_requires_migration_data = useSelector((state: any) => state.vsm.vsm_requires_migration_data);

    /***** RENDER *****/
    if (!ventraSynMigrationBanners || !vsm_requires_migration_data?.requires_migration) {
        return null;
    }

    return (
        <Transition>
            <div className="vsBanner">
                <div className="vsBanner__text">
                    {ventraSynMigrationShowEndOfLifeDate && (
                        <p className="vsBanner__text--line">
                            Your reseller services will reach their end of life on{' '}
                            <span className="vsBanner__time">{ventraSynMigrationEndOfLifeDate}</span> and will need to be moved to a new provider.
                        </p>
                    )}
                    <p className="vsBanner__text--line">
                        {' '}
                        Find out how we can seamlessly migrate you to our sister company, Synergy Wholesale with up to TWO FREE months of hosting!
                    </p>
                </div>
                <SolidButton to="/synergy-reseller-migration/more-info">More Information</SolidButton>
            </div>
        </Transition>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default VentraSynBanner;
