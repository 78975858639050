import { useLocation } from '@tanstack/react-router';
import Subnav from 'components/Subnav';
import { service_status } from 'config/config';
import { useBrandStore } from 'config/hooks/useBrandStore';
import { useMemo } from 'react';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const SupportSubNav = () => {
    /***** HOOKS *****/
    const { ticketName } = useBrandStore();
    const { pathname } = useLocation();

    /***** RENDER HELPERS *****/
    const navItems = useMemo(
        () => [
            {
                label: 'Support Centre',
                link: '/support/support-centre',
                icon: 'support'
            },
            {
                label: `${ticketName}s`,
                link: '/support/tickets',
                icon: 'eticket'
            },
            {
                label: 'Migration Request',
                link: '/support/migration-request',
                icon: 'icon-migrations'
            },
            {
                label: 'Feedback',
                link: '/support/feedback',
                icon: 'customer-service'
            },
            {
                label: 'Service Status',
                external: true,
                link: service_status,
                icon: 'service-status'
            }
        ],
        []
    );

    /***** RENDER *****/
    return <Subnav items={navItems} pathname={pathname} />;
};
