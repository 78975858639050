import { useQueryClient } from '@tanstack/react-query';
import RequestLoader from 'components/Loaders/Request';
import { useOrderingFormContext } from 'containers/orderingForm/contexts/useOrderingFormContext';
import 'containers/orderingForm/pages/configurationPage/configureDomainsList/_configureDomainsList.scss';
import { ConfigureDomainsListItem } from 'containers/orderingForm/pages/configurationPage/configureDomainsList/configureDomainsListItem';
import type { Item } from 'containers/orderingForm/types';
import { Suspense, useEffect } from 'react';
import { NXQuery } from 'utilities/query';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const ConfigureDomainsList = () => {
    /***** HOOKS *****/
    const { cartToken } = useOrderingFormContext();
    const queryClient = useQueryClient();

    /***** QUERIES *****/
    const { data: cart_data, isLoading: isCartDataLoading } = NXQuery.orderingForm.cart.cartId.getCart.useQuery(cartToken);

    /***** EFFECTS *****/
    // prefetch the registration pricing for all cart items
    useEffect(() => {
        if (cart_data && cart_data?.cart?.items?.length > 0) {
            cart_data?.cart.items.forEach((item) => {
                const domainData = {
                    domain: item?.name.replace(item?.product_name, ''),
                    action: item.order_type,
                    promos: [],
                    tlds: [item?.product_name]
                };

                queryClient.prefetchQuery(NXQuery.domain.availability.createQueryOptions(domainData));
            });
        }
    }, []);

    /***** RENDER *****/
    if (isCartDataLoading) return <RequestLoader />;
    return (
        <Suspense fallback={<RequestLoader />}>
            <ul className="configureDomainsList">
                {cart_data?.cart?.items?.map((item: Item, index: number) => <ConfigureDomainsListItem item={item} key={index} />)}
            </ul>
        </Suspense>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
