/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
export const TEXT_PROP_KEYS = {
    CHILDREN: 'children',
    CLASSNAME: 'className',
    UNDERLINE: 'underline',
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    CONFIRM: 'confirm',
    WARN: 'warn',
    INFO: 'info',
    NOTICE: 'notice',
    WHITE: 'white',
    BLACK: 'black',
    CHARCOAL: 'charcoal',
    UPPERCASE: 'uppercase',
    LOWERCASE: 'lowercase',
    CAPITALIZE: 'capitalize',
    ITALIC: 'italic',
    MEDIUM: 'medium',
    SEMI_BOLD: 'semiBold',
    BOLD: 'bold',
    BOLD_ITALIC: 'boldItalic',
    BLACK_FONT: 'blackFont',
    BLACK_ITALIC: 'blackItalic',
    SIZE_XS: 'size--xs',
    SIZE_XSS: 'size--xss',
    SIZE_S: 'size--s',
    SIZE_M: 'size--m',
    SIZE_XM: 'size--xm',
    SIZE_L: 'size--l',
    SIZE_XL: 'size--xl',
    SIZE_XXXL: 'size--xxxl',
    ALIGN_RIGHT: 'align--right',
    ALIGN_CENTER: 'align--center',
    LEAD_NONE: 'lead--none',
    LEAD_0: 'lead--0',
    LEAD_1: 'lead--1',
    LEAD_XS: 'lead--xs',
    LEAD_S: 'lead--s',
    LEAD_L: 'lead--l',
    LEAD_INHERIT: 'lead--inherit'
} as const;

// Define the interface for TextProps
export type TextProps = {
    /**
     * Text content. Can accept one or a list of react elements, in which case it will forward the classNames of those elements,
     * as well as the text styling classes. Or if you just pass a string, it will default to a <p></p>.
     */
    'children': React.ReactNode;

    /**
     * Renders the content as a span instead of a paragraph
     */
    'span'?: boolean;

    /**
     * Additional Classnames
     */
    'className'?: string;

    /**
     * Text Transforms
     */
    /** Text Decoration Underline */
    'underline'?: boolean;

    /**
     * Text Colors
     */
    /** Color Primary PURPLE */
    'primary'?: boolean;
    
    /** Color Secondary GRAY */
    'secondary'?: boolean;
    
    /** Color Confirm GREEN */
    'confirm'?: boolean;
    
    /** Color Warn RED */
    'warn'?: boolean;
    
    /** Color Info GREEN */
    'info'?: boolean;
    
    /** Color Notice ORANGE */
    'notice'?: boolean;
    
    /** Color White */
    'white'?: boolean;
    
    /** Color Black */
    'black'?: boolean;

    /**
     * Apply text transforms
     */
    /** Transform Text Uppercase */
    'uppercase'?: boolean;
    
    /** Transform Text Lowercase */
    'lowercase'?: boolean;
    
    /** Transform Text Capitalize */
    'capitalize'?: boolean;

    /**
     * Font Weights / style
     */
    /** Font Weight Medium */
    'medium'?: boolean;
    
    /** Font Weight Semi Bold */
    'semiBold'?: boolean;
    
    /** Font Weight Bold */
    'bold'?: boolean;
    
    /** Font Weight Black (Bold Bold) */
    'blackFont'?: boolean; // black is already used by colors
    /** Font Style Italic */
    'italic'?: boolean;

    /**
     * Font sizes
     */
    /** 12px */
    'size--xs'?: boolean;
    
    /** 13px */
    'size--xss'?: boolean;
    
    /** 14px */
    'size--s'?: boolean;
    
    /** 16px */
    'size--m'?: boolean;
    
    /** 17px */
    'size--xm'?: boolean;
    
    /** 18px */
    'size--l'?: boolean;
    
    /** 20px */
    'size--xl'?: boolean;
    
    /** 22px */
    'size--xxl'?: boolean;
    
    /** 25px */
    'size--h2'?: boolean;
    
    /** 28px */
    'size--xxxl'?: boolean;

    /**
     * Text align to the left (Default is inherited)
     */
    'align--left'?: boolean;
    
    /** Text align in the center (Default is inherited) */
    'align--center'?: boolean;
    
    /** Text align to the right (Default is inherited) */
    'align--right'?: boolean;

    /**
     * Line height props
     */
    /** line-height: initial */
    'lead--none'?: boolean;
    
    /** line-height: 0 */
    'lead--0'?: boolean;
    
    /** line-height: 1 */
    'lead--1'?: boolean;
    
    /** line-height: 1.2 */
    'lead--xs'?: boolean;
    
    /** line-height: 1.25 */
    'lead--s'?: boolean;
    
    /** line-height: 1.75 */
    'lead--l'?: boolean;
    
    /** line-height: inherit */
    'lead--inherit'?: boolean;

    /**
     * NON-STANDARD,
     * using the `style` prop is not preferred.
     * please do not use it unless you have no way of overwriting the styling
     * i.e defining styling in other classes and applying those classes to the component.
     */
    'style'?: React.CSSProperties;
};
