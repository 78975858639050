import { getArticle } from 'utilities/api/ventraip-website-wp-support-centre/queries/getArticle';
import { getCategories } from 'utilities/api/ventraip-website-wp-support-centre/queries/getCategories';
import { getPages } from 'utilities/api/ventraip-website-wp-support-centre/queries/getPages';
import { getSearchResults } from 'utilities/api/ventraip-website-wp-support-centre/queries/getSearchResults';
import { getSupportArticles } from 'utilities/api/ventraip-website-wp-support-centre/queries/getSupportArticles';

export const _ventraIpWordpressSupportCentreQuery = Object.freeze({
    getPages,
    getSearchResults,
    getCategories,
    getSupportArticles,
    getArticle
});
