import { animateScroll as scroll } from 'react-scroll';
import store from 'store/store';
import { getSidebarState } from 'utilities/methods/commonActions';
import { ADD_REF } from './actionTypes';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
const firstComponentRefs = /** @type {const} */ ([
    '/account/general/overview/',
    '/my-services/vps/account/overview/',
    '/my-services/vps/manage/power-management/',
    '/my-services/vps/addons/resource-addons/'
]);
const scrollOptions = /** @type {const} */ ({
    duration: 1000,
    delay: 0,
    smooth: 'easeInOutQuint',
    offset: 50,
    isDynamic: true
});

//ignore scroll function if it's first component
const isFirstComponentRef = (ref) => {
    let isFirst = false;
    if (firstComponentRefs.length && firstComponentRefs.length > 0) {
        firstComponentRefs.forEach((item, index) => {
            if (ref.indexOf(item) > -1) {
                isFirst = true;
            }
        });
    }
    return isFirst;
};

export const pushNewSidebarRefs = (sidebarRefs) => {
    return (dispatch) => {
        dispatch({
            type: ADD_REF,
            sidebarRefs
        });
    };
};

/**
 * @param {Record<string, HTMLElement>} sidebarRefsObject - object whos keys are the path(s) of the component and values are the ref to the component
 */
export const upsertSidebarRef = (sidebarRefsObject) => {
    const { dispatch } = store;

    dispatch({
        type: ADD_REF,
        sidebarRefs: { ...getSidebarState(), ...sidebarRefsObject }
    });
};

/**
 * @param {Record<string, HTMLElement>} sidebarRefsObject - object whos keys are the path(s) of the component and values are the ref to the component
 */
export const removeSidebarRef = (sidebarRefsObject) => {
    const { dispatch } = store;

    const currentSidebarRefObjects = getSidebarState();

    Object.keys(sidebarRefsObject).forEach((key) => {
        delete currentSidebarRefObjects[key];
    });

    dispatch({
        type: ADD_REF,
        sidebarRefs: currentSidebarRefObjects
    });
};

export const scrollToElementName = (currentStatus, nextStatus, component, nextprops, name) => {
    return (dispatch) => {
        const { location, path, sidebarRefs } = component.props;

        const { scrollFlag, scrollRef } = component;

        const { pathname } = location;
        const hasRefsAndBound = sidebarRefs && scrollRef && scrollRef.getBoundingClientRect();

        if (currentStatus === 'loading' && nextStatus === 'success') {
            if (hasRefsAndBound) {
                if (path === pathname && (scrollFlag === false || scrollFlag === undefined || scrollFlag === null)) {
                    scroll.scrollTo(name, scrollOptions);
                    component.scrollFlag = true;
                }
                sidebarRefs[path] = scrollRef;
                dispatch(pushNewSidebarRefs(sidebarRefs));
            }
        } else {
            if (pathname !== nextprops.location.pathname) {
                if (hasRefsAndBound) {
                    if (path === nextprops.location.pathname) {
                        scroll.scrollTo(name, scrollOptions);
                    }
                }
            }
        }
    };
};

export const bindSidebarScrollAfterPageLoadEventToComponent = (currentStatus, prevStatus, component, prevprops) => {
    return (dispatch) => {
        const { scrollRefArray } = component;
        const { location, sidebarRefs } = component.props;
        const { pathname } = location;

        if (scrollRefArray && prevStatus === 'loading' && currentStatus === 'success') {
            Object.keys(scrollRefArray).forEach((key) => {
                if (pathname === key) {
                    if (component.scrollFlag === undefined || component.scrollFlag === null || component.scrollFlag === false) {
                        if (isFirstComponentRef(pathname)) {
                            scroll.scrollTo(0, scrollOptions);
                        } else {
                            const doc = document.documentElement;
                            const scrollvar = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
                            const top = scrollRefArray[key].getBoundingClientRect().top + scrollvar;
                            scroll.scrollTo(top, scrollOptions);
                        }
                        component.scrollFlag = true;
                        sidebarRefs[pathname] = scrollRefArray[key];
                        dispatch(pushNewSidebarRefs(sidebarRefs));
                    }
                }
            });
        } else {
            if (pathname !== prevprops.location.pathname) {
                Object.keys(scrollRefArray).forEach((key) => {
                    if (pathname === key) {
                        const doc = document.documentElement;
                        const scrollvar = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
                        const top = scrollRefArray[key].getBoundingClientRect().top + scrollvar;
                        scroll.scrollTo(top, scrollOptions);

                        sidebarRefs[pathname] = scrollRefArray[key];
                        dispatch(pushNewSidebarRefs(sidebarRefs));
                    }
                });
            }
        }
    };
};
