import { Store } from '@tanstack/react-store';
import { pictureInPictureStates } from 'components/PictureInPicture/consts/pictureInPictureStates';
import type { PictureInPictureNamespace } from 'components/PictureInPicture/types';
import { createContext } from 'react';
import { UserPreferences } from 'utilities/UserPreferences';

// These states correlate directly with the local storage zod schema in the `UserPreferences` manager
export function createPictureInPictureState<TIdentifier extends string>(
    defaultState: PictureInPictureNamespace.States = 'hidden',
    identifier?: TIdentifier
): TIdentifier extends string ? PictureInPictureNamespace.CreateStateData : PictureInPictureNamespace.CreateStateDataStore {
    const store = new Store(defaultState);

    if (identifier) {
        const commit: PictureInPictureNamespace.CommitStateData = (value) => {
            UserPreferences.updateItem('PICTURE_IN_PICTURE_STATES', (states) => ({
                ...states,
                [identifier]: value
            }));
        };

        const retrieve: PictureInPictureNamespace.RetrieveStateData = () => {
            const storedValue = UserPreferences.getItem('PICTURE_IN_PICTURE_STATES');

            if (!storedValue) return null;

            const value = storedValue[identifier];

            if (!value) {
                return null;
            }

            return pictureInPictureStates.includes(value) ? value : null;
        };

        return { store, commit, retrieve } as any;
    }

    return { store } as any;
}

export const PictureInPictureContext = createContext<PictureInPictureNamespace.ContextData>({});
