import { dev_api as devAPI } from 'config/config';

export function getProdApi() {
    switch (import.meta.env.VITE_COMPANY) {
        case 'intaserve':
            return 'https://dashboard.intaserve.au';
        case 'ventra':
        default:
            return 'https://vip.ventraip.com.au';
    }
}

export const API_BASE_URL = import.meta.env.VITE_CCP_ENVIRONMENT === 'development' ? devAPI : getProdApi();
