import { useMutation } from '@tanstack/react-query';
import { newSectionTrackerData } from 'containers/katana/modules/presetContent/components/sectionOrganiser/newSectionTrackerData';
import { katanaQueryKeys } from 'containers/katana/queries/katanaQueryKeys';
import { pageSections } from 'containers/katana/queries/serviceID/pageSections';
import { section } from 'containers/katana/queries/serviceID/section';
import { sections } from 'containers/katana/queries/serviceID/sections';
import type { KatanaNamespace } from 'containers/katana/types';
import { queryClient } from 'store/queryClient';
import { KATANA_API } from 'utilities/api/katana';
import { handleDefaultErrorNotification } from 'utilities/methods/commonActions/handleDefaultErrorNotification';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useMutation(serviceID: KatanaNamespace.ServiceId, options = {}) {
    /***** FUNCTIONS *****/
    async function onMutate(sectionID: KatanaNamespace.SectionId) {
        /**
         * Optimistically remove the section from the view.
         * first cancel existing queries, then set the data to the new data.
         */
        newSectionTrackerData.removeID(sectionID);

        const pageID = section.getQueryData({ serviceID, sectionID })?.data?.site_page_id;

        await sections.cancelQueries(serviceID);
        section.removeQueries({ serviceID, sectionID });

        if (!pageID) return;

        await pageSections.cancelQueries({ serviceID, pageID });

        const { restore: sectionsRestore } = sections.optimistic.filter(serviceID, 'data', (section) => section.id !== sectionID);
        const { restore: pageSectionsRestore } = pageSections.optimistic.filter({ serviceID, pageID }, 'data', (section) => section.id !== sectionID);

        return {
            restore: () => {
                sectionsRestore();
                pageSectionsRestore();
            },
        };
    }

    async function mutationFn(sectionID: KatanaNamespace.SectionId) {
        return KATANA_API.katana.site.service_id.section.section_id.DELETE({ serviceID, sectionID });
    }

    /***** HOOK RESULTS *****/
    return useMutation({
        mutationFn,
        onMutate,
        onError: (e, v, context) => {
            context?.restore();
            handleDefaultErrorNotification(e);
        },
        onSettled: (data, error, sectionID) => {
            queryClient.invalidateQueries({
                queryKey: katanaQueryKeys.katana.service.ID.sections(serviceID),
                exact: true,
            });

            queryClient.invalidateQueries({
                queryKey: katanaQueryKeys.katana.service.ID.preview(serviceID),
            });

            queryClient.invalidateQueries({
                queryKey: katanaQueryKeys.katana.service.ID.navigation(serviceID),
            });

            const pageID = section.getQueryData({ serviceID, sectionID })?.data?.site_page_id;
            if (!pageID) return;

            queryClient.invalidateQueries({
                queryKey: katanaQueryKeys.katana.service.ID.pages.ID.sections({ serviceID, pageID }),
                exact: true,
            });

            queryClient.invalidateQueries({
                queryKey: katanaQueryKeys.katana.service.ID.pages.ID.sections.ID({ serviceID, pageID, sectionID }),
                exact: true,
            });
        },
        ...options,
    });
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const deleteSection = Object.freeze({
    useMutation: _useMutation,
});
