import axios from 'axios';
const CancelToken = axios.CancelToken;

export let cancelGetService;
export let cancelGetUsers;
export let cancelGetPricing;
export let cancelGetCustomer;
export let cancelGetData;
export let cancelGetRecord;
export let cancelGetStatus;
export let cancelGetUserData;

export const API = {
    /**********************************************************************************************************
     *   GSUITE API
     **********************************************************************************************************/
    gsuite: {
        GET: {
            service: (id) => {
                return axios({
                    method: 'get',
                    url: `/api/gsuite/${id}`,
                    cancelToken: new CancelToken(function executor(c) {
                        cancelGetService = c;
                    })
                });
            },

            users: (id) => {
                return axios({
                    method: 'get',
                    url: `/api/gsuite/${id}/users`,
                    cancelToken: new CancelToken(function executor(c) {
                        cancelGetUsers = c;
                    })
                });
            },

            mailbox: (id, mailbox) => {
                return axios.get(`/api/gsuite/${id}/users/${mailbox}`);
            },

            pricing: (serviceId, seats, product_id) => {
                //technically a POST, but GETs what we need <3
                const data = {
                    type: 'gsuite',
                    attributes: {
                        seats
                    }
                };

                if (product_id) {
                    data.attributes.product_id = product_id;
                }

                return axios({
                    method: 'post',
                    url: `/api/gsuite/${serviceId}/seat-pricing`,
                    data,
                    cancelToken: new CancelToken(function executor(c) {
                        cancelGetPricing = c;
                    })
                });
            },

            data: (id) => {
                return axios({
                    method: 'get',
                    url: `/api/gsuite/${id}/customer`,
                    cancelToken: new CancelToken(function executor(c) {
                        cancelGetCustomer = c;
                    })
                });
            },

            userData: (id, mailbox) => {
                return axios({
                    method: 'get',
                    url: `/api/gsuite/${id}/users/${mailbox}`,
                    cancelToken: new CancelToken(function executor(c) {
                        cancelGetUserData = c;
                    })
                });
            },

            login: (id) => {
                return axios.get(`/api/gsuite/${id}/login`);
            },

            product: () => {
                return axios.get(`/api/gsuite/product-pricing`);
            },

            customer: (id) => {
                return axios({
                    method: 'get',
                    url: `/api/gsuite/${id}/customer/check`,
                    cancelToken: new CancelToken(function executor(c) {
                        cancelGetData = c;
                    })
                });
            },

            record: (id) => {
                return axios({
                    method: 'get',
                    url: `/api/gsuite/${id}/setup/vertification-record`,
                    cancelToken: new CancelToken(function executor(c) {
                        cancelGetRecord = c;
                    })
                });
            },

            status: (id) => {
                return axios({
                    method: 'get',
                    url: `/api/gsuite/${id}/setup/verification-status`,
                    cancelToken: new CancelToken(function executor(c) {
                        cancelGetStatus = c;
                    })
                });
            },

            billingCycle: (id) => {
                return axios.get(`/api/gsuite/${id}/billing-cycle`);
            }
        },

        PUT: {
            update: (id, mailbox, attributes) => {
                return axios.put(`/api/gsuite/${id}/users/${mailbox}`, {
                    type: 'gsuite-user',
                    id,
                    attributes
                });
            },

            customer: (id, attributes) => {
                return axios.put(`/api/gsuite/${id}/customer`, {
                    type: 'gsuite-customer',
                    id,
                    attributes
                });
            },

            billingCycle: (id, billingCycleID) => {
                return axios.put(`/api/gsuite/${id}/billing-cycle`, {
                    type: 'gsuite',
                    id,
                    attributes: {
                        billing_cycle_id: billingCycleID
                    }
                });
            }
        },

        POST: {
            create: (id, attributes) => {
                return axios.post(`/api/gsuite/${id}/users`, {
                    type: 'gsuite-user',
                    id,
                    attributes
                });
            },

            purchase: (id, seats) => {
                return axios.post(`/api/gsuite/${id}/change-seats`, {
                    type: 'gsuite',
                    id,
                    attributes: {
                        seats
                    }
                });
            },

            upgrade: (id, attributes) => {
                return axios.post(`/api/gsuite/${id}/upgrade`, {
                    type: 'gsuite',
                    id,
                    attributes
                });
            },

            customer: (id, attributes) => {
                return axios.post(`/api/gsuite/${id}/customer`, {
                    type: 'gsuite-customer',
                    id,
                    attributes
                });
            },

            admin: (id, attributes) => {
                return axios.post(`/api/gsuite/${id}/setup/admin-account`, {
                    type: 'gsuite-customer',
                    id,
                    attributes
                });
            },

            verify: (id) => {
                return axios.post(`/api/gsuite/${id}/setup/verify-domain`);
            }
        },

        DELETE: {
            user: (id, mailbox) => {
                return axios.delete(`/api/gsuite/${id}/users/${mailbox}`);
            }
        }
    }
};
