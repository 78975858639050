import classNames from 'classnames';
import type { NXUtils } from 'utilities/NXUtils';
import './_OutlineTag.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type OutlineTagType = React.FC<OutlineTag.Props>;
export namespace OutlineTag {
    export type Props = {
        children: React.ReactNode;
        color?: NXUtils.ValueOf<typeof TAG_COLOURS>;
        className?: string;

        /**
         * How the width should be determined
         * @default 'auto'
         */
        width?: 'fit-content';
    };
}

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
export const TAG_COLOURS = {
    ERROR: 'error',
    WARN: 'warn',
    SUCCESS: 'success',
    CONFIRM: 'confirm',
    WARNING: 'warning',
    NOTICE: 'notice',
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    INFO: 'info',
    CONTENTION: 'contention',
    BRONZE: 'bronze',
    SILVER: 'silver',
    GOLD: 'gold',
    PLATINUM: 'platinum',
    LEGACY: 'legacy'
} as const;
export const tagColors = Object.values(TAG_COLOURS);

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const OutlineTag: OutlineTagType = ({ children, color, className, width }) => {
    const classes = classNames('OutlineTag', className, color, {
        'OutlineTag--fit-content': width === 'fit-content'
    });

    return <div className={classes}>{children}</div>;
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default OutlineTag;
