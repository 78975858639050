import { pushNotification } from 'components/Toast/functions';
import { API } from 'utilities/api/dashboard';
import { getDataFromSuccessResponse, getErrorFromFailResponse } from 'utilities/methods/commonActions';

export const DASHBOARD_INITIAL_STATE = 'dashboard/DASHBOARD_INITIAL_STATE';

export const DASHBOARD_TICKET_REQUEST = 'dashboard/DASHBOARD_TICKET_REQUEST';
export const DASHBOARD_TICKET_SUCCESS = 'dashboard/DASHBOARD_TICKET_SUCCESS';
export const DASHBOARD_TICKET_FAIL = 'dashboard/DASHBOARD_TICKET_FAIL';

export const DASHBOARD_ALERTS_REQUEST = 'dashboard/DASHBOARD_ALERTS_REQUEST';
export const DASHBOARD_ALERTS_SUCCESS = 'dashboard/DASHBOARD_ALERTS_SUCCESS';
export const DASHBOARD_ALERTS_FAIL = 'dashboard/DASHBOARD_ALERTS_FAIL';

export const DASHBOARD_REFERRAL_BANNER_REQUEST = 'dashboard/DASHBOARD_REFERRAL_BANNER_REQUEST';
export const DASHBOARD_REFERRAL_BANNER_SUCCESS = 'dashboard/DASHBOARD_REFERRAL_BANNER_SUCCESS';
export const DASHBOARD_REFERRAL_BANNER_FAIL = 'dashboard/DASHBOARD_REFERRAL_BANNER_FAIL';

export const REFERRAL_CODE_CREATE_REQUEST = 'dashboard/REFERRAL_CODE_CREATE_REQUEST';
export const REFERRAL_CODE_CREATE_SUCCESS = 'dashboard/REFERRAL_CODE_CREATE_SUCCESS';
export const REFERRAL_CODE_CREATE_FAIL = 'dashboard/REFERRAL_CODE_CREATE_FAIL';

export const REFERRAL_CODE_SEND_REQUEST = 'dashboard/REFERRAL_CODE_SEND_REQUEST';
export const REFERRAL_CODE_SEND_SUCCESS = 'dashboard/REFERRAL_CODE_SEND_SUCCESS';
export const REFERRAL_CODE_SEND_FAIL = 'dashboard/REFERRAL_CODE_SEND_FAIL';

/**********************************************************************************************************
 *   RESET
 **********************************************************************************************************/
export const resetDashboard = () => {
    return (dispatch) => {
        dispatch({
            type: DASHBOARD_INITIAL_STATE
        });
    };
};

/**********************************************************************************************************
 *   ETICKETS
 **********************************************************************************************************/
export const getOpenTickets = () => {
    return (dispatch) => {
        dispatch({
            type: DASHBOARD_TICKET_REQUEST
        });
        API.dashboard.get
            .tickets()
            .then((response) => {
                const dashboard_tickets_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: DASHBOARD_TICKET_SUCCESS,
                    dashboard_tickets_data
                });
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                dispatch({
                    type: DASHBOARD_TICKET_FAIL,
                    dashboard_tickets_error: response
                });
            });
    };
};

/**********************************************************************************************************
 *   ALERTS / NORIFICATIONS
 **********************************************************************************************************/
export const getAlerts = () => {
    return (dispatch) => {
        dispatch({
            type: DASHBOARD_ALERTS_REQUEST
        });
        API.dashboard.get
            .alerts()
            .then((response) => {
                const dashboard_alerts_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: DASHBOARD_ALERTS_SUCCESS,
                    dashboard_alerts_data
                });
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                dispatch({
                    type: DASHBOARD_ALERTS_FAIL,
                    dashboard_alerts_error: response
                });
            });
    };
};

/**********************************************************************************************************
 *   REFERRAL
 **********************************************************************************************************/
export const getReferralCode = () => {
    return (dispatch) => {
        dispatch({
            type: DASHBOARD_REFERRAL_BANNER_REQUEST
        });
        API.referral.get
            .code()
            .then((response) => {
                const dashboard_referral_banner_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: DASHBOARD_REFERRAL_BANNER_SUCCESS,
                    dashboard_referral_banner_data
                });
            })
            .catch((error) => {
                const dashboard_referral_banner_error = getErrorFromFailResponse(error);
                dispatch({
                    type: DASHBOARD_REFERRAL_BANNER_FAIL,
                    dashboard_referral_banner_error
                });
            });
    };
};

export const createReferralCode = () => {
    return (dispatch) => {
        dispatch({
            type: REFERRAL_CODE_CREATE_REQUEST
        });
        API.referral.post
            .createCode()
            .then((response) => {
                const referral_code_create_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: REFERRAL_CODE_CREATE_SUCCESS,
                    referral_code_create_data
                });
            })
            .catch((error) => {
                const referral_code_create_error = getErrorFromFailResponse(error);
                dispatch({
                    type: REFERRAL_CODE_CREATE_FAIL,
                    referral_code_create_error
                });
            });
    };
};

export const sendReferralCode = (id, attributes) => {
    return (dispatch) => {
        dispatch({
            type: REFERRAL_CODE_SEND_REQUEST
        });
        API.referral.post
            .sendCode(id, attributes)
            .then((response) => {
                const referral_code_send_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: REFERRAL_CODE_SEND_SUCCESS,
                    referral_code_send_data
                });
                dispatch(createReferralCode());
            })
            .catch((error) => {
                const referral_code_send_error = getErrorFromFailResponse(error);
                pushNotification(referral_code_send_error);
                dispatch({
                    type: REFERRAL_CODE_SEND_FAIL,
                    referral_code_send_error
                });
            });
    };
};
