import { PhosphorIcons } from 'components/Icons/Phosphor';
import { Flex } from 'components/Utils/Flex';
import Text from 'components/Utils/Text';
import { useBrandStore } from 'config/hooks/useBrandStore';
import { useOrderingFormContext } from 'containers/orderingForm/contexts/useOrderingFormContext';
import { IntaserveFormInput } from 'containers/orderingForm/orderingFormInputs';
import type { Item } from 'containers/orderingForm/types';
import { Controller } from 'react-hook-form';
import { NXQuery } from 'utilities/query';
import { UserPreferences } from 'utilities/UserPreferences';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const FormOptionToggles = () => {
    /***** STATE *****/
    const [vipSitesDomain] = UserPreferences.useLocalStorage('CART_VIP_SITES_DOMAIN_NAME');

    /***** HOOKS *****/
    const { currentItem } = useOrderingFormContext();
    const { activeBrand } = useBrandStore();

    const productParams = {
        ids: [currentItem?.id],
    };

    const { data: product_Data } = NXQuery.orderingForm.getProducts.useQuery(productParams);
    const isIdProtectionAllowed = !!product_Data?.[0]?.attributes?.meta?.find((item: Item) => item?.identifier === 'allow_id_protection');

    /***** RENDER *****/
    return (
        <>
            {activeBrand === 'ventra' && currentItem.order_type === 'register' && (
                <Controller
                    name="isUsedForVipSites"
                    render={({ field }) => (
                        <IntaserveFormInput.HookForm.Switch
                            {...field}
                            radio
                            label="Use for my VIPsites website"
                            description="Select this domain name to assign it to your VIPsites Website Builder website."
                            isDisabled={vipSitesDomain === currentItem.name}
                        />
                    )}
                />
            )}
            {activeBrand === 'ventra' ? (
                <>
                    {currentItem?.is_premium ? (
                        <Flex direction="column">
                            <Flex align="center">
                                <Text size--xm bold>
                                    Auto Renew
                                </Text>
                                <PhosphorIcons.LockSimple />
                            </Flex>
                            <Text>Auto renew is disabled for Premium Domains.</Text>
                        </Flex>
                    ) : (
                        <Controller
                            name="autoRenew"
                            render={({ field }) => (
                                <IntaserveFormInput.HookForm.Switch
                                    {...field}
                                    label="Auto Renew"
                                    description="We'll automatically invoice you 14 days before your domain expires and attempt to charge any stored credit card."
                                />
                            )}
                        />
                    )}
                </>
            ) : (
                <Flex direction="column">
                    <Flex align="center">
                        <Text size--xm bold>
                            Auto Renew
                        </Text>
                        <PhosphorIcons.LockSimple />
                    </Flex>
                    <Text>This domain will auto renew by default for your brand security.</Text>
                </Flex>
            )}
            {isIdProtectionAllowed && (
                <Controller
                    name="idProtection"
                    render={({ field }) => (
                        <IntaserveFormInput.HookForm.Switch
                            {...field}
                            label="ID Protection"
                            description="Keep your personal information safe and reduce spam by enabling ID Protection."
                            recommended
                        />
                    )}
                />
            )}
        </>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
