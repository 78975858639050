/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { OutlineButton } from 'components/Buttons/OutlineButton';
import { SolidButton } from 'components/Buttons/SolidButton';
import { BaseDropdown } from 'components/Dropdowns/_BaseDropdown';
import { OutlineDropdown } from 'components/Dropdowns/OutlineDropdown';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
/**
 *  @typedef {Omit<Parameters<typeof BaseDropdown>[number], 'list'>} ListlessDropdownProps
 */

/**
 * Provides an abstraction for creating an action button for use in the NXTAble.
 * This provides the props for a typical dropdown (dropdown or item) or a button.
 *
 * Alternatively, for promotions or more custom implementations that don't follow the standard format,
 * the JSX can be provided directly to the NXtable.
 *
 * @param {Partial<Parameters<typeof OutlineButton>[number]>} baseButtonProps
 */
export const generateCreateActionButton = (baseButtonProps) => {
    /**
     * Create an object with keys for, a button (React.ReactElement), dropdown (dropdown props) or
     * item (dropdown list item). If a dropdown is required, the generateCreateDropdown helper can help
     * generate a dropdown for a table @see {generateCreateDropdown}
     *
     * @template {string} GName
     * @param {{ name: GName, onClick: () => void, color?: ListlessDropdownProps['color'] }} props
     * @param {typeof OutlineButton | typeof SolidButton} [Button]
     */
    return ({ name, onClick, color }, Button = OutlineButton) => {
        return /** @type {const} */ ({
            item: /** @satisfies {DropdownNamespace.ListItem} */ ({
                label: name,
                type: 'onClick',
                onClick
            }),
            dropdown: /** @satisfies {ListlessDropdownProps} */ ({
                size: 'medium',
                title: name,
                variant: 'OutlineDropdown',
                titleOnClick: onClick,
                color
            }),
            button: (
                <Button {...baseButtonProps} color={color} onClick={onClick}>
                    {name}
                </Button>
            )
        });
    };
};

/**
 * Creates a dropdown for an NXTable Action column that is aware of the current view.
 *
 * On mobile resolutions, the dropdown is replaced with a series of buttons.
 *
 * @template {string} GName
 * @template {Record<GName, ReturnType<ReturnType<typeof generateCreateActionButton>>>} TButtons
 * @param {TButtons} buttons
 * @param {boolean} isMobile
 */
export const generateCreateDropdown = (buttons, isMobile) => {
    /**
     * Creates a dropdown with the main button and the items when in a desktop view, otherwise it adds each item as a separate
     * button in a mobile view.
     *
     * @param {keyof typeof buttons} main
     * @param {Array<keyof typeof buttons>} items
     * @param {typeof OutlineDropdown} [Dropdown]
     */
    return (main, items, Dropdown = OutlineDropdown) => {
        if (isMobile) {
            return [buttons[main].button, ...items.map((item) => buttons[item].button)];
        } else {
            return [
                <Dropdown key={`${String(main)}-${items.join('-')}`} {...buttons[main].dropdown} list={items.map((item) => buttons[item].item)} />
            ];
        }
    };
};

/**
 * generator for the function for creating a dropdown option. This is used in the table header for the "Show" dropdown.
 *
 * @param {number | undefined} totalRecords
 * @param {React.Dispatch<number>} setNumberOfRows
 */
export const generateCreateShowDropdownOption = (totalRecords, setNumberOfRows) => {
    /**
     * creates a dropdown option for the "Show" dropdown in the table header.
     *
     * @template {`${number}` | "All"} T
     * @param {T} value
     * @returns {{
     *   label: `Show ${T}`,
     *   onClick: () => void,
     *   value: T
     * }}
     */
    const createShowDropdownOption = (value) => ({
        label: `Show ${value}`,
        onClick: () => setNumberOfRows(value === 'All' ? totalRecords ?? 10 : Number(value)),
        value: value
    });

    return createShowDropdownOption;
};
