import AppLoader from 'components/Loaders/App';
import { useBrandStore } from 'config/hooks/useBrandStore';
import { useLoginContext, type PossibleLoginViews } from 'containers/login/consts';
import { IntaserveLoginAuth } from 'containers/login/forms/IntaserveLogin';
import AccountSelect from 'containers/login/forms/LoginAccountSelect';
import { useLoginActions, type Success } from 'containers/login/hooks';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import type { API } from 'utilities/api/login';
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';
import './_login.scss';
import { LoginForm } from './forms/LoginAuth/LoginAuth';
import { SelectAlternativeTwoFactorMethod } from './forms/LoginBackup';
import LoginForgot from './forms/LoginForgot';
import LoginRecovery from './forms/LoginRecovery';
import LoginReset from './forms/LoginReset';
import { LoginTwoFactor } from './forms/LoginTwoFactor';
import { RecoverySuccess } from './forms/RecoverySuccess';

/**********************************************************************************************************
 *   ROUTE COMPONENT START
 **********************************************************************************************************/
export const LoginRouteComponent = () => {
    /***** SELECTORS *****/
    const login_authentication_status = useSelector((state: any) => state.login.login_authentication_status);
    const login_2fa_recovery_status = useSelector((state: any) => state.login.login_2fa_recovery_status);
    const login_forgot_password_status = useSelector((state: any) => state.login.login_forgot_password_status);
    const login_forgot_email_status = useSelector((state: any) => state.login.login_forgot_email_status);
    const login_recovery_manual_status = useSelector((state: any) => state.login.login_recovery_manual_status);
    const login_reset_password_status = useSelector((state: any) => state.login.login_reset_password_status);

    /***** STATE *****/
    const { setView, currentView } = useLoginContext();
    const [method, setMethod] = useState<API.login.twoFactor.listMethods.Method | undefined>(undefined);
    const [success, setSuccess] = useState<Success>({ details: false });

    /***** HOOKS *****/
    const { activeBrand } = useBrandStore();
    const isMobile = useAppViewport(['xs', 'sm']);
    const { handleForgotSubmit, handleResetLoginArea, handleRecoverySubmit, handleResetSubmit, handleSetMethod } = useLoginActions({
        setMethod,
        setSuccess
    });

    /***** EFFECTS *****/
    useEffect(() => {
        if (login_2fa_recovery_status === 'success') {
            setView('2fa');
        }
    }, [login_2fa_recovery_status]);

    useEffect(() => {
        if (login_authentication_status === 'error') {
            setView('login');
        }
    }, [login_authentication_status]);

    useEffect(() => {
        if (
            login_forgot_password_status === 'success' ||
            login_forgot_email_status === 'success' ||
            login_recovery_manual_status === 'success' ||
            login_reset_password_status === 'success'
        ) {
            setView('success');
        }
    }, [login_forgot_password_status, login_forgot_email_status, login_recovery_manual_status, login_reset_password_status]);

    /***** RENDER HELPERS *****/
    const brandView = activeBrand === "intaserve" ? "intaserve" : currentView;
    const views: Partial<Record<PossibleLoginViews | "intaserve", React.ReactElement>> = {
        'login': <LoginForm />,
        '2fa': <LoginTwoFactor backupMethod={method} />,
        'alternative-2fa': <SelectAlternativeTwoFactorMethod setBackupMethod={handleSetMethod} />,
        'recovery': <LoginRecovery navigation={setView} onSubmit={handleRecoverySubmit} />,
        'forgot': <LoginForgot navigation={setView} onSubmit={handleForgotSubmit} />,
        'success': <RecoverySuccess changeView={setView} view={success.view} details={success.details} resubmit={success.resubmit} />,
        'reset': <LoginReset navigation={setView} onSubmit={handleResetSubmit} />,
        'accountlist': <AccountSelect resetLoginArea={handleResetLoginArea} />,
        'loading': <AppLoader backgroundColor={!isMobile && 'S_color_background_tertiary_base'} />,
        "intaserve": <IntaserveLoginAuth />
    };
    

    /***** RENDER *****/
    return views[brandView] ?? views.loading;
};
