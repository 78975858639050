import { createRoute, redirect } from '@tanstack/react-router';
import { loadAppConfig } from 'App/actions/loadAppConfig';
import AppLoader from 'components/Loaders/App';
import { pushNotification } from 'components/Toast/functions';
import { RootRoute } from 'router/__root';

/**********************************************************************************************************
 *   ROUTE COMPONENT START
 **********************************************************************************************************/
export const GoogleProviderCallbackRoute = createRoute({
    getParentRoute: () => RootRoute,
    path: '/oauth/google',
    pendingMs: 0,
    pendingComponent: () => <AppLoader fullHeight />,
    staticData: {
        preventLoadAppConfig: true
    },
    async loader({ location: { hash }, context: { NXQuery } }) {
        const provider = 'google';
        const urlParams = new URLSearchParams(hash);
        const token = urlParams.get('access_token') as string;

        try {
            await NXQuery.auth.oauth.login.fetch({ provider, token });
        } catch (error) {
            pushNotification(error?.errors?.[0], null, 'global');
            throw redirect({ to: '/login' });
        }

        // Once we have set cookie from oauth.login, we can load the relevant app config and redirect to dashboard
        await loadAppConfig({
            context: 'loader',
            onError: () => {
                throw redirect({
                    to: '/login'
                });
            }
        });
    }
});
