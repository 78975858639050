import {
    VPS_ADDON_CANCEL_FAIL,
    VPS_ADDON_CANCEL_REQUEST,
    VPS_ADDON_CANCEL_SUCCESS,
    VPS_ADDON_FAIL,
    VPS_ADDON_REQUEST,
    VPS_ADDON_SUCCESS,
    VPS_ADD_HARDWARE_ADDON_FAIL,
    VPS_ADD_HARDWARE_ADDON_REQUEST,
    VPS_ADD_HARDWARE_ADDON_SUCCESS,
    VPS_ADD_SOFTWARE_ADDON_FAIL,
    VPS_ADD_SOFTWARE_ADDON_REQUEST,
    VPS_ADD_SOFTWARE_ADDON_SUCCESS,
    VPS_ASTRO_INFORMATION_FAIL,
    VPS_ASTRO_INFORMATION_REQUEST,
    VPS_ASTRO_INFORMATION_SUCCESS,
    VPS_BILLING_CYCLE_FAIL,
    VPS_BILLING_CYCLE_REQUEST,
    VPS_BILLING_CYCLE_SUCCESS,
    VPS_BILLING_CYCLE_UPDATE_FAIL,
    VPS_BILLING_CYCLE_UPDATE_REQUEST,
    VPS_BILLING_CYCLE_UPDATE_SUCCESS,
    VPS_CHANGE_VNC_PASSWORD_FAIL,
    VPS_CHANGE_VNC_PASSWORD_REQUEST,
    VPS_CHANGE_VNC_PASSWORD_SUCCESS,
    VPS_CREATE_ASTRO_VNC_ERROR,
    VPS_CREATE_ASTRO_VNC_REQUEST,
    VPS_CREATE_ASTRO_VNC_SUCCESS,
    VPS_FORMAT_FAIL,
    VPS_FORMAT_REQUEST,
    VPS_FORMAT_SUCCESS,
    VPS_FULLY_UPGRADE_LIST_FAIL,
    VPS_FULLY_UPGRADE_LIST_SUCCESS,
    VPS_HARDWARE_ADDON_PRICING_FAIL,
    VPS_HARDWARE_ADDON_PRICING_REQUEST,
    VPS_HARDWARE_ADDON_PRICING_SUCCESS,
    VPS_INFORMATION_FAIL,
    VPS_INFORMATION_REQUEST,
    VPS_INFORMATION_RESET,
    VPS_INFORMATION_SUCCESS,
    VPS_INITIAL_STATE,
    VPS_LEGACY_ADDON_FAIL,
    VPS_LEGACY_ADDON_REQUEST,
    VPS_LEGACY_ADDON_SUCCESS,
    VPS_LEGACY_UPGRADE_LIST_REQUEST,
    VPS_OS_LIST_FAIL,
    VPS_OS_LIST_REQUEST,
    VPS_OS_LIST_SUCCESS,
    VPS_POWER_STATE_ERROR,
    VPS_POWER_STATE_REQUEST,
    VPS_POWER_STATE_SUCCESS,
    VPS_REBOOT_FAIL,
    VPS_REBOOT_REQUEST,
    VPS_REBOOT_SUCCESS,
    VPS_REMOVE_CANCELLATION_ERROR,
    VPS_REMOVE_CANCELLATION_REQUEST,
    VPS_REMOVE_CANCELLATION_SUCCESS,
    VPS_RENEW_DETAILS_ERROR,
    VPS_RENEW_DETAILS_REQUEST,
    VPS_RENEW_DETAILS_SUCCESS,
    VPS_RENEW_ERROR,
    VPS_RENEW_INFO_ERROR,
    VPS_RENEW_INFO_REQUEST,
    VPS_RENEW_INFO_SUCCESS,
    VPS_RENEW_REQUEST,
    VPS_RENEW_SUCCESS,
    VPS_REVERSE_DNS_FAIL,
    VPS_REVERSE_DNS_REQUEST,
    VPS_REVERSE_DNS_SUCCESS,
    VPS_SELF_UPGRADE_LIST_FAIL,
    VPS_SELF_UPGRADE_LIST_SUCCESS,
    VPS_SHUTDOWN_FAIL,
    VPS_SHUTDOWN_REQUEST,
    VPS_SHUTDOWN_SUCCESS,
    VPS_SOFTWARE_ADDON_PRICING_FAIL,
    VPS_SOFTWARE_ADDON_PRICING_REQUEST,
    VPS_SOFTWARE_ADDON_PRICING_SUCCESS,
    VPS_SOLUS_INFORMATION_FAIL,
    VPS_SOLUS_INFORMATION_REQUEST,
    VPS_SOLUS_INFORMATION_SUCCESS,
    VPS_STARTUP_ERROR,
    VPS_STARTUP_REQUEST,
    VPS_STARTUP_SUCCESS,
    VPS_SUBMIT_JOB_FAIL,
    VPS_SUBMIT_JOB_REQUEST,
    VPS_SUBMIT_JOB_SUCCESS,
    VPS_UPGRADE_FAIL,
    VPS_UPGRADE_LIST_FAIL,
    VPS_UPGRADE_LIST_REQUEST,
    VPS_UPGRADE_LIST_SUCCESS,
    VPS_UPGRADE_REQUEST,
    VPS_UPGRADE_SUCCESS,
    VPS_USER_INFORMATION_FAIL,
    VPS_USER_INFORMATION_REQUEST,
    VPS_USER_INFORMATION_SUCCESS,
    VPS_VNC_INFORMATION_FAIL,
    VPS_VNC_INFORMATION_REQUEST,
    VPS_VNC_INFORMATION_SUCCESS,
    VPS_WHM_LOGIN_FAIL,
    VPS_WHM_LOGIN_REQUEST,
    VPS_WHM_LOGIN_SUCCESS
} from './actionTypes';

const initialState = {
    vps_information_status: null,
    vps_information_data: null,
    vps_information_error: null,
    vps_solus_information_status: null,
    vps_solus_information_data: null,
    vps_astro_information_status: null,
    vps_astro_information_data: null,
    vps_user_information_status: null,
    vps_user_information_data: null,
    vps_user_information_error: null,
    vps_whm_login_status: null,
    vps_whm_login_data: null,
    vps_whm_login_error: null,
    vps_addon_list_data: null,
    vps_addon_list_status: null,
    vps_addon_list_error: null,
    vps_software_addon_pricing_status: null,
    vps_software_addon_pricing_data: null,
    vps_software_addon_pricing_error: null,
    vps_hardware_addon_pricing_status: null,
    vps_hardware_addon_pricing_data: null,
    vps_hardware_addon_pricing_error: null,
    vps_add_hardware_addon_status: null,
    vps_add_hardware_addon_data: null,
    vps_add_hardware_addon_error: null,
    vps_add_software_addon_status: null,
    vps_add_software_addon_data: null,
    vps_add_software_addon_error: null,
    vps_addon_cancel_status: null,
    vps_addon_cancel_data: null,
    vps_addon_cancel_error: null,
    vps_legacy_addon_status: null,
    vps_legacy_addon_data: null,
    vps_legacy_addon_error: null,
    vps_billing_cycle_status: null,
    vps_billing_cycle_data: null,
    vps_billing_cycle_error: null,
    vps_billing_cycle_update_status: null,
    vps_billing_cycle_update_data: null,
    vps_billing_cycle_update_error: null,
    vps_submit_job_status: null,
    vps_submit_job_data: null,
    vps_submit_job_error: null,
    vps_power_state_status: null,
    vps_power_state_data: null,
    vps_reboot_status: null,
    vps_reboot_data: null,
    vps_reboot_error: null,
    vps_startup_status: null,
    vps_shutdown_status: null,
    vps_shutdown_data: null,
    vps_shutdown_error: null,
    vps_vnc_information_status: null,
    vps_vnc_information_data: null,
    vps_vnc_information_error: null,
    vps_change_vnc_password_status: null,
    vps_create_astro_vnc_status: null,
    vps_reverse_dns_status: null,
    vps_reverse_dns_data: null,
    vps_reverse_dns_error: null,
    vps_products_status: null,
    vps_products_data: null,
    vps_products_error: null,
    vps_os_list_status: null,
    vps_os_list_data: null,
    vps_os_list_error: null,
    vps_format_status: null,
    vps_format_data: null,
    vps_format_error: null,
    vps_upgrade_list_status: null,
    vps_upgrade_list_data: null,
    vps_upgrade_list_error: null,
    vps_upgrade_status: null,
    vps_upgrade_data: null,
    vps_upgrade_error: null,
    vps_legacy_upgrade_list_status: null,
    vps_fully_upgrade_list_status: null,
    vps_fully_upgrade_list_data: null,
    vps_fully_upgrade_list_error: null,
    vps_self_upgrade_list_status: null,
    vps_self_upgrade_list_data: null,
    vps_self_upgrade_list_error: null,
    vps_remove_cancellation_status: null,
    vps_remove_cancellation_data: null,
    vps_remove_cancellation_error: null
};

const vpsReducer = (state = initialState, action) => {
    switch (action.type) {
        case VPS_INITIAL_STATE:
            return initialState;

        /**********************************************************************************************************
         *   VPS INFORMATION
         **********************************************************************************************************/
        case VPS_INFORMATION_RESET:
            return {
                ...state,
                vps_information_status: null,
                vps_information_data: null,
                vps_information_error: null
            };

        case VPS_INFORMATION_REQUEST:
            return {
                ...state,
                vps_information_status: 'loading',
                vps_information_data: null,
                vps_information_error: null
            };

        case VPS_INFORMATION_SUCCESS:
            return {
                ...state,
                vps_information_status: 'success',
                vps_information_data: action.vps_information_data,
                vps_information_error: null
            };

        case VPS_INFORMATION_FAIL:
            return {
                ...state,
                vps_information_status: 'error',
                vps_information_error: action.vps_information_error,
                vps_information_data: null
            };

        case VPS_SOLUS_INFORMATION_REQUEST:
            return {
                ...state,
                vps_solus_information_status: 'loading',
                vps_solus_information_data: null
            };

        case VPS_SOLUS_INFORMATION_SUCCESS:
            return {
                ...state,
                vps_solus_information_status: 'success',
                vps_solus_information_data: action.vps_solus_information_data
            };

        case VPS_SOLUS_INFORMATION_FAIL:
            return {
                ...state,
                vps_solus_information_status: 'error'
            };

        case VPS_ASTRO_INFORMATION_REQUEST:
            return {
                ...state,
                vps_astro_information_status: 'loading',
                vps_astro_information_data: null
            };

        case VPS_ASTRO_INFORMATION_SUCCESS:
            return {
                ...state,
                vps_astro_information_status: 'success',
                vps_astro_information_data: action.vps_astro_information_data
            };

        case VPS_ASTRO_INFORMATION_FAIL:
            return {
                ...state,
                vps_astro_information_status: 'error'
            };

        case VPS_USER_INFORMATION_FAIL:
            return {
                ...state,
                vps_user_information_status: 'error',
                vps_user_information_error: action.vps_user_information_error
            };

        case VPS_USER_INFORMATION_SUCCESS:
            return {
                ...state,
                vps_user_information_status: 'success',
                vps_user_information_data: action.vps_user_information_data
            };

        case VPS_USER_INFORMATION_REQUEST:
            return {
                ...state,
                vps_user_information_status: 'loading'
            };

        /**********************************************************************************************************
         *   WHM LOGIN
         **********************************************************************************************************/
        case VPS_WHM_LOGIN_REQUEST:
            return {
                ...state,
                vps_whm_login_status: 'loading',
                vps_whm_login_data: null,
                vps_whm_login_error: null
            };

        case VPS_WHM_LOGIN_SUCCESS:
            return {
                ...state,
                vps_whm_login_status: 'success',
                vps_whm_login_data: action.vps_whm_login_data
            };

        case VPS_WHM_LOGIN_FAIL:
            return {
                ...state,
                vps_whm_login_status: 'error',
                vps_whm_login_error: action.vps_whm_login_error
            };
        
        /**********************************************************************************************************
         *   VPS ADDON
         **********************************************************************************************************/
        case VPS_ADDON_REQUEST:
            return {
                ...state,
                vps_addon_list_status: 'loading',
                vps_addon_list_data: null,
                vps_addon_list_error: null
            };

        case VPS_ADDON_SUCCESS:
            return {
                ...state,
                vps_addon_list_status: 'success',
                vps_addon_list_data: action.vps_addon_list_data
            };

        case VPS_ADDON_FAIL:
            return {
                ...state,
                vps_addon_list_status: 'error',
                vps_addon_list_error: action.vps_addon_list_error
            };

        case VPS_SOFTWARE_ADDON_PRICING_REQUEST:
            return {
                ...state,
                vps_software_addon_pricing_status: 'loading'
            };

        case VPS_SOFTWARE_ADDON_PRICING_SUCCESS:
            return {
                ...state,
                vps_software_addon_pricing_status: 'success',
                vps_software_addon_pricing_data: action.vps_software_addon_pricing_data
            };

        case VPS_SOFTWARE_ADDON_PRICING_FAIL:
            return {
                ...state,
                vps_software_addon_pricing_status: 'error',
                vps_software_addon_pricing_error: action.vps_software_addon_pricing_error
            };

        case VPS_HARDWARE_ADDON_PRICING_REQUEST:
            return {
                ...state,
                vps_hardware_addon_pricing_status: 'loading'
            };

        case VPS_HARDWARE_ADDON_PRICING_SUCCESS:
            return {
                ...state,
                vps_hardware_addon_pricing_status: 'success',
                vps_hardware_addon_pricing_data: action.vps_hardware_addon_pricing_data
            };

        case VPS_HARDWARE_ADDON_PRICING_FAIL:
            return {
                ...state,
                vps_hardware_addon_pricing_status: 'error',
                vps_hardware_addon_pricing_error: action.vps_hardware_addon_pricing_error
            };

        case VPS_ADD_HARDWARE_ADDON_REQUEST:
            return {
                ...state,
                vps_add_hardware_addon_status: 'loading'
            };

        case VPS_ADD_HARDWARE_ADDON_SUCCESS:
            return {
                ...state,
                vps_add_hardware_addon_status: 'success',
                vps_add_hardware_addon_data: action.vps_add_hardware_addon_data
            };

        case VPS_ADD_HARDWARE_ADDON_FAIL:
            return {
                ...state,
                vps_add_hardware_addon_status: 'error',
                vps_add_hardware_addon_data: action.vps_add_hardware_addon_data
            };

        case VPS_ADD_SOFTWARE_ADDON_REQUEST:
            return {
                ...state,
                vps_add_software_addon_status: 'loading'
            };

        case VPS_ADD_SOFTWARE_ADDON_SUCCESS:
            return {
                ...state,
                vps_add_software_addon_status: 'success',
                vps_add_software_addon_data: action.vps_add_software_addon_data
            };

        case VPS_ADD_SOFTWARE_ADDON_FAIL:
            return {
                ...state,
                vps_add_software_addon_status: 'error',
                vps_add_software_addon_data: action.vps_add_software_addon_error
            };

        case VPS_ADDON_CANCEL_REQUEST:
            return {
                ...state,
                vps_addon_cancel_status: 'loading'
            };

        case VPS_ADDON_CANCEL_SUCCESS:
            return {
                ...state,
                vps_addon_cancel_status: 'success',
                vps_addon_cancel_data: action.vps_addon_cancel_data
            };

        case VPS_ADDON_CANCEL_FAIL:
            return {
                ...state,
                vps_addon_cancel_status: 'error',
                vps_addon_cancel_error: action.vps_addon_cancel_error
            };

        case VPS_LEGACY_ADDON_REQUEST:
            return {
                ...state,
                vps_legacy_addon_status: 'loading',
                vps_legacy_addon_data: null,
                vps_legacy_addon_error: null
            };

        case VPS_LEGACY_ADDON_SUCCESS:
            return {
                ...state,
                vps_legacy_addon_status: 'success',
                vps_legacy_addon_data: action.vps_legacy_addon_data
            };

        case VPS_LEGACY_ADDON_FAIL:
            return {
                ...state,
                vps_legacy_addon_status: 'error',
                vps_legacy_addon_error: action.vps_legacy_addon_error
            };

        /**********************************************************************************************************
         *   BILLING
         **********************************************************************************************************/
        case VPS_BILLING_CYCLE_REQUEST:
            return {
                ...state,
                vps_billing_cycle_status: 'loading',
                vps_billing_cycle_data: null,
                vps_billing_cycle_error: null
            };

        case VPS_BILLING_CYCLE_SUCCESS:
            return {
                ...state,
                vps_billing_cycle_status: 'success',
                vps_billing_cycle_data: action.vps_billing_cycle_data
            };

        case VPS_BILLING_CYCLE_FAIL:
            return {
                ...state,
                vps_billing_cycle_status: 'error',
                vps_billing_cycle_error: action.vps_billing_cycle_error
            };

        case VPS_BILLING_CYCLE_UPDATE_REQUEST:
            return {
                ...state,
                vps_billing_cycle_update_status: 'loading',
                vps_billing_cycle_update_data: null,
                vps_billing_cycle_update_error: null
            };

        case VPS_BILLING_CYCLE_UPDATE_SUCCESS:
            return {
                ...state,
                vps_billing_cycle_update_status: 'success',
                vps_billing_cycle_update_data: action.vps_billing_cycle_update_data
            };

        case VPS_BILLING_CYCLE_UPDATE_FAIL:
            return {
                ...state,
                vps_billing_cycle_update_status: 'error',
                vps_billing_cycle_update_error: action.vps_billing_cycle_update_error
            };

        case VPS_RENEW_INFO_REQUEST:
            return {
                ...state,
                vps_renew_info_status: 'loading',
                vps_renew_info_data: null
            };

        case VPS_RENEW_INFO_SUCCESS:
            return {
                ...state,
                vps_renew_info_status: 'success',
                vps_renew_info_data: action.vps_renew_info_data
            };

        case VPS_RENEW_INFO_ERROR:
            return {
                ...state,
                vps_renew_info_status: 'error'
            };

        case VPS_RENEW_DETAILS_REQUEST:
            return {
                ...state,
                vps_renew_details_status: 'loading',
                vps_renew_details_data: null
            };

        case VPS_RENEW_DETAILS_SUCCESS:
            return {
                ...state,
                vps_renew_details_status: 'success',
                vps_renew_details_data: action.vps_renew_details_data
            };

        case VPS_RENEW_DETAILS_ERROR:
            return {
                ...state,
                vps_renew_details_status: 'error'
            };

        case VPS_RENEW_REQUEST:
            return {
                ...state,
                vps_renew_status: 'loading',
                vps_renew_data: null
            };

        case VPS_RENEW_SUCCESS:
            return {
                ...state,
                vps_renew_status: 'success',
                vps_renew_data: action.vps_renew_data
            };

        case VPS_RENEW_ERROR:
            return {
                ...state,
                vps_renew_status: 'error'
            };

        case VPS_REMOVE_CANCELLATION_REQUEST:
            return {
                ...state,
                vps_remove_cancellation_status: 'loading',
                vps_remove_cancellation_data: null
            };

        case VPS_REMOVE_CANCELLATION_SUCCESS:
            return {
                ...state,
                vps_remove_cancellation_status: 'success',
                vps_remove_cancellation_data: action.vps_remove_cancellation_data
            };

        case VPS_REMOVE_CANCELLATION_ERROR:
            return {
                ...state,
                vps_remove_cancellation_status: 'error'
            };

        /**********************************************************************************************************
         *   SERVER JOBS
         **********************************************************************************************************/
        case VPS_SUBMIT_JOB_REQUEST:
            return {
                ...state,
                vps_submit_job_status: 'loading',
                vps_submit_job_data: null,
                vps_submit_job_error: null
            };

        case VPS_SUBMIT_JOB_SUCCESS:
            return {
                ...state,
                vps_submit_job_status: 'success',
                vps_submit_job_data: action.vps_submit_job_data
            };

        case VPS_SUBMIT_JOB_FAIL:
            return {
                ...state,
                vps_submit_job_status: 'error',
                vps_submit_job_error: action.vps_submit_job_error
            };

        /**********************************************************************************************************
         *   VPS POWER STATE
         **********************************************************************************************************/
        case VPS_POWER_STATE_REQUEST:
            return {
                ...state,
                vps_power_state_status: 'loading',
                vps_power_state_data: null
            };

        case VPS_POWER_STATE_SUCCESS:
            return {
                ...state,
                vps_power_state_status: 'success',
                vps_power_state_data: action.vps_power_state_data
            };

        case VPS_POWER_STATE_ERROR:
            return {
                ...state,
                vps_power_state_status: 'error',
                vps_power_state_data: action.vps_power_state_data
            };

        /**********************************************************************************************************
         *   REBOOT VPS SERVER
         **********************************************************************************************************/
        case VPS_REBOOT_REQUEST:
            return {
                ...state,
                vps_reboot_status: 'loading',
                vps_reboot_data: null,
                vps_reboot_error: null
            };

        case VPS_REBOOT_SUCCESS:
            return {
                ...state,
                vps_reboot_status: 'success',
                vps_reboot_data: action.vps_reboot_data
            };

        case VPS_REBOOT_FAIL:
            return {
                ...state,
                vps_reboot_status: 'error',
                vps_reboot_error: action.vps_reboot_error
            };

        /**********************************************************************************************************
         *   STARTUP VPS SERVER
         **********************************************************************************************************/
        case VPS_STARTUP_REQUEST:
            return {
                ...state,
                vps_startup_status: 'loading'
            };

        case VPS_STARTUP_SUCCESS:
            return {
                ...state,
                vps_startup_status: 'success'
            };

        case VPS_STARTUP_ERROR:
            return {
                ...state,
                vps_startup_status: 'error'
            };

        /**********************************************************************************************************
         *   SHUTDOWN VPS SERVER
         **********************************************************************************************************/
        case VPS_SHUTDOWN_REQUEST:
            return {
                ...state,
                vps_shutdown_status: 'loading',
                vps_shutdown_data: null,
                vps_shutdown_error: null
            };

        case VPS_SHUTDOWN_SUCCESS:
            return {
                ...state,
                vps_shutdown_status: 'success',
                vps_shutdown_data: action.vps_shutdown_data
            };

        case VPS_SHUTDOWN_FAIL:
            return {
                ...state,
                vps_shutdown_status: 'error',
                vps_shutdown_error: action.vps_shutdown_error
            };

        /**********************************************************************************************************
         *   UPGRADE
         **********************************************************************************************************/
        case VPS_UPGRADE_LIST_REQUEST:
            return {
                ...state,
                vps_upgrade_list_status: 'loading',
                vps_upgrade_list_data: null,
                vps_upgrade_list_error: null
            };

        case VPS_UPGRADE_LIST_SUCCESS:
            return {
                ...state,
                vps_upgrade_list_status: 'success',
                vps_upgrade_list_data: action.vps_upgrade_list_data
            };

        case VPS_UPGRADE_LIST_FAIL:
            return {
                ...state,
                vps_upgrade_list_status: 'error',
                vps_upgrade_list_error: action.vps_upgrade_list_error
            };

        case VPS_UPGRADE_REQUEST:
            return {
                ...state,
                vps_upgrade_status: 'loading',
                vps_upgrade_data: null,
                vps_upgrade_error: null
            };

        case VPS_UPGRADE_SUCCESS:
            return {
                ...state,
                vps_upgrade_status: 'success',
                vps_upgrade_data: action.vps_upgrade_data
            };

        case VPS_UPGRADE_FAIL:
            return {
                ...state,
                vps_upgrade_status: 'error',
                vps_upgrade_error: action.vps_upgrade_error
            };

        case VPS_LEGACY_UPGRADE_LIST_REQUEST:
            return {
                ...state,
                vps_fully_upgrade_list_status: 'loading',
                vps_self_upgrade_list_status: 'loading',
                vps_fully_upgrade_list_data: null,
                vps_fully_upgrade_list_error: null,
                vps_self_upgrade_list_data: null,
                vps_self_upgrade_list_error: null
            };

        case VPS_FULLY_UPGRADE_LIST_SUCCESS:
            return {
                ...state,
                vps_fully_upgrade_list_status: 'success',
                vps_fully_upgrade_list_data: action.vps_fully_upgrade_list_data
            };

        case VPS_SELF_UPGRADE_LIST_SUCCESS:
            return {
                ...state,
                vps_self_upgrade_list_status: 'success',
                vps_self_upgrade_list_data: action.vps_self_upgrade_list_data
            };

        case VPS_FULLY_UPGRADE_LIST_FAIL:
            return {
                ...state,
                vps_fully_upgrade_list_status: 'error',
                vps_fully_upgrade_list_error: action.vps_fully_upgrade_list_error
            };

        case VPS_SELF_UPGRADE_LIST_FAIL:
            return {
                ...state,
                vps_self_upgrade_list_status: 'error',
                vps_self_upgrade_list_error: action.vps_self_upgrade_list_error
            };

        /**********************************************************************************************************
         *   VNC
         **********************************************************************************************************/
        case VPS_VNC_INFORMATION_REQUEST:
            return {
                ...state,
                vps_vnc_information_status: 'loading',
                vps_vnc_information_data: null,
                vps_vnc_information_error: null
            };

        case VPS_VNC_INFORMATION_SUCCESS:
            return {
                ...state,
                vps_vnc_information_status: 'success',
                vps_vnc_information_data: action.vps_vnc_information_data
            };

        case VPS_VNC_INFORMATION_FAIL:
            return {
                ...state,
                vps_vnc_information_status: 'error',
                vps_vnc_information_error: action.vps_vnc_information_error
            };

        case VPS_CHANGE_VNC_PASSWORD_REQUEST:
            return {
                ...state,
                vps_change_vnc_password_status: 'loading'
            };

        case VPS_CHANGE_VNC_PASSWORD_SUCCESS:
            return {
                ...state,
                vps_change_vnc_password_status: 'success'
            };

        case VPS_CHANGE_VNC_PASSWORD_FAIL:
            return {
                ...state,
                vps_change_vnc_password_status: 'error'
            };

        case VPS_CREATE_ASTRO_VNC_REQUEST:
            return {
                ...state,
                vps_create_astro_vnc_status: 'loading',
                vps_create_astro_vnc_data: null
            };

        case VPS_CREATE_ASTRO_VNC_SUCCESS:
            return {
                ...state,
                vps_create_astro_vnc_status: 'success'
            };

        case VPS_CREATE_ASTRO_VNC_ERROR:
            return {
                ...state,
                vps_create_astro_vnc_status: 'error'
            };

        /**********************************************************************************************************
         *   REVERSE DNS
         **********************************************************************************************************/
        case VPS_REVERSE_DNS_REQUEST:
            return {
                ...state,
                vps_reverse_dns_status: 'loading',
                vps_reverse_dns_data: null,
                vps_reverse_dns_error: null
            };

        case VPS_REVERSE_DNS_SUCCESS:
            return {
                ...state,
                vps_reverse_dns_status: 'success',
                vps_reverse_dns_data: action.vps_reverse_dns_data
            };

        case VPS_REVERSE_DNS_FAIL:
            return {
                ...state,
                vps_reverse_dns_status: 'error',
                vps_reverse_dns_error: action.vps_reverse_dns_error
            };

        /**********************************************************************************************************
         *   FORMAT VPS
         **********************************************************************************************************/
        case VPS_OS_LIST_REQUEST:
            return {
                ...state,
                vps_os_list_status: 'loading',
                vps_os_list_data: null,
                vps_os_list_error: null
            };

        case VPS_OS_LIST_SUCCESS:
            return {
                ...state,
                vps_os_list_status: 'success',
                vps_os_list_data: action.vps_os_list_data
            };

        case VPS_OS_LIST_FAIL:
            return {
                ...state,
                vps_os_list_status: 'error',
                vps_os_list_error: action.vps_os_list_error
            };

        case VPS_FORMAT_REQUEST:
            return {
                ...state,
                vps_format_status: 'loading',
                vps_format_data: null,
                vps_format_error: null
            };

        case VPS_FORMAT_SUCCESS:
            return {
                ...state,
                vps_format_status: 'success',
                vps_format_data: action.vps_format_data
            };

        case VPS_FORMAT_FAIL:
            return {
                ...state,
                vps_format_status: 'error',
                vps_format_error: action.vps_format_error
            };

        default:
            return state;
    }
};

export default vpsReducer;
