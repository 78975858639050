import { _Input as Input } from 'components/Form/Input/_Input';
import type { NXInputNamespace } from 'components/Form/Input/types';
import { useDebounceCallback } from 'usehooks-ts';
import { useStateEffect } from 'utilities/hooks/useStateEffect';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type _InputDebounce = React.FC<NXInputNamespace.Debounce.Props>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _InputDebounce: _InputDebounce = ({ delay = 500, intrinsicProps, value, ...props }) => {
    const { onChange, ...restIntrinsicProps } = intrinsicProps;

    /***** HOOKS *****/
    const [debouncedValue, setValue] = useStateEffect(value);
    const handleDebouncedChange = useDebounceCallback(onChange, delay);

    /***** FUNCTIONS *****/
    const handleOnChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setValue(e.target.value);
        handleDebouncedChange(e);
    };

    /***** RENDER *****/
    return <Input {...{ ...props, value: debouncedValue, intrinsicProps: { ...restIntrinsicProps, onChange: handleOnChange } }} />;
};
