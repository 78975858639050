import { BaseButton } from 'components/Buttons/_BaseButton';
import type { BaseButtonNamespace } from 'components/Buttons/_BaseButton/types';
import React from 'react';
import './_RightBorderButton.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * that renders a button with the RightBorderButton variant
 */
const RightBorderButton: React.FC<BaseButtonNamespace.BaseProps> = (props) => {
    /***** RENDER *****/
    return <BaseButton variant="RightBorderButton" {...props} />;
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default RightBorderButton;
