/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getDaysBetween, toLuxonDate } from 'utilities/methods/commonActions';

/**
 * Returns an expiry info object to be used to display the expiry date in different ways by different components
 */
export function getExpiryInfo(expiry_date) {
    const expiryDays = getDaysBetween(toLuxonDate(expiry_date, 'yyyy-MM-dd'));

    /**
     * @type {string | null}
     */
    let partOne = `Expires in`;
    
    /**
     * @type {string | null}
     */
    let partTwo = `${expiryDays} days`;
    
    /**
     * @type {string | null}
     */
    let formattedExpiryDate = toLuxonDate(expiry_date, 'yyyy-MM-dd').toFormat('ccc LLL d y');

    if (expiryDays === undefined) {
        partOne = null;
        partTwo = null;
        formattedExpiryDate = null;
    } else if (expiryDays < 0) {
        partOne = 'Expired';
        partTwo = expiryDays === -1 ? 'Yesterday' : `${expiryDays.toString().substring(1)} days ago`;
    } else if (expiryDays === 1) {
        partOne = 'Expires';
        partTwo = 'Tomorrow';
    } else if (expiryDays === 0) {
        partOne = 'Expires';
        partTwo = 'Today';
    }

    return {
        days: expiryDays, // Number of days until expiry, eg. 4
        partOne, // Eg. "Expires in"
        partTwo, // Eg. "4 days". Can be null if partOne is something like "Tomorrow"
        formattedExpiryDate, // Eg. "Fri Jul 25 2025"
        isWithin90: typeof expiryDays === 'number' ? expiryDays < 90 : false
    };
}
