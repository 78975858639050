import { applyChildrenProps } from 'components/NXTable/methods';
import { NXTableContext } from 'components/NXTable/NXTableContext';
import SortableButton from 'components/SortableButton';
import React, { useContext, useEffect } from 'react';
import invariant from 'tiny-invariant';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type SortableButtonWrapper = React.FC<{
    children: React.ReactNode;
    initialMethod?: 'asc' | 'desc' | false;
}>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * A wrapper component for the sortable button to easily integrate it into the lifecycle of the table context 😏
 *
 * @param {{ children: React.ReactNode }} props
 */
export const SortableButtonWrapper: SortableButtonWrapper = ({ children }) => {
    /***** HOOKS *****/
    const { _onSort, _currentSort } = useContext(NXTableContext);

    if (!_onSort || !_currentSort) {
        throw new Error('SortableButtonWrapper must be used within a NXTableContext');
    }

    /***** FUNCTIONS *****/
    function onSort(sortData) {
        _onSort(sortData);
    }

    /***** RENDER HELPERS *****/
    const sortableProps = {
        onSort,
        currentSort: _currentSort
    };

    const childrenSortable = applyChildrenProps(children, sortableProps, SortableButton);

    /***** RENDER *****/
    return <>{childrenSortable}</>;
};

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Hook for accessing the sort functionality of the NXTable.
 * This will also automatically set the initial sort method on the table (for this column) if provided.
 */
export const useNXTableSortableContext = (key: string, initialMethod: 'asc' | 'desc' | false = false) => {
    /***** HOOKS *****/
    const context = useContext(NXTableContext);

    invariant(context, 'useNXTableSortable must be used within a NXTableContext');

    /***** EFFECTS *****/
    useEffect(() => {
        // false and asc are essentially the same thing in all current cases, so if it is initially "asc" then we can just treat it as false
        if (initialMethod === 'desc') {
            context._onSort({ sortKey: key, sortMethod: initialMethod });
        }
    }, []);

    /***** HOOK RESULTS *****/
    return {
        onSort: context._onSort,
        currentSort: context._currentSort
    };
};
