/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { redirect } from '@tanstack/react-router';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { isAuthenticated } from 'router/utils/isAuthenticated/isAuthenticated';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { AnyRouteId, GetOptionTypeFromRouteId } from 'router/types';
import { NXQuery } from 'utilities/query';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type AuthenticationLevels = 'user' | 'guest' | 'any';
type AuthenticationMiddleware = (level: AuthenticationLevels, opts: GetOptionTypeFromRouteId<'/', 'beforeLoad'>, callback?: () => void) => void;

/**********************************************************************************************************
 *   MIDDLEWARE START
 **********************************************************************************************************/
export const authenticationMiddleware: AuthenticationMiddleware = (level, opts, callback): void => {
    switch (level) {
        case 'guest':
            if (isAuthenticated()) {
                handleDevelopmentAuthenticationLog(level, opts);
                callback?.();
                throw redirect({ to: '/dashboard' });
            }
            break;
        case 'user':
            if (!isAuthenticated()) {
                handleDevelopmentAuthenticationLog(level, opts);
                callback?.();
                throw redirect({
                    to: '/login',
                    search: { ref: opts.location.pathname }
                });
            }
            break;
        default:
            break;
    }
};

/**********************************************************************************************************
 *   FUNCTION START
 **********************************************************************************************************/
function handleDevelopmentAuthenticationLog(level: AuthenticationLevels, opts: GetOptionTypeFromRouteId<AnyRouteId, 'beforeLoad'>) {
    const options: any = opts;
    if (import.meta.env.DEV) {
        console.warn(
            `[DEBUG | /src/router/utils/middleware/authentication.ts] Attempted to access ${options?.location?.pathname} that user does not have expected authentication for.\n\n`,
            '------------- DEBUG INFORMATION --------------',
            '\nExpected Level                 : ',
            level,
            '\nAuthentication Level           : ',
            isAuthenticated() ? 'user' : 'guest',
            '\noptions                        : ',
            options,
            '\napp_user_data (isAuthenticated): ',
            NXQuery.auth.userData.getData(),
            '\nisAuthenticatedStore.state (isAuthenticated): ',
            isAuthenticated()
        );
    }
}
