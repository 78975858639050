import _ from 'lodash';

export const filterTextProps = (props: object) => {
    return _.omit(props, [
        'span',
        'as',
        'underline',
        'break-word',
        'decoration',
        'primary',
        'secondary',
        'confirm',
        'warn',
        'info',
        'notice',
        'white',
        'black',
        'inactive',
        'color',
        'uppercase',
        'lowercase',
        'capitalize',
        'italic',
        'medium',
        'semiBold',
        'bold',
        'blackFont',
        'size--xs',
        'size--xss',
        'size--s',
        'size--m',
        'size--xm',
        'size--l',
        'size--xl',
        'size--xxl',
        'size--h2',
        'size--xxxl',
        'size--custom',
        'align--left',
        'align--center',
        'align--right',
        'lead--none',
        'lead--0',
        'lead--1',
        'lead--xs',
        'lead--s',
        'lead--l',
        'lead--inherit'
    ]);
};
