import { SkeletonHOC } from 'components/Utils/Text/VMax/Skeleton/_SkeletonHOC';
import type { _TextVMax as TextVMax } from 'components/Utils/Text/VMax/TextVMax';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
export namespace _TextVMaxH2 {
    export type Props = TextVMax.BaseProps;
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _TextVMaxH2 = SkeletonHOC({ size: 16, fontWeight: 'semibold' });
