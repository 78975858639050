import { Actions } from 'components/NXTable/Actions';
import { Base } from 'components/NXTable/Actions/base';
import { Body } from 'components/NXTable/Body';
import { NXTableErrorMessage } from 'components/NXTable/ErrorMessage';
import { NXTableFooter } from 'components/NXTable/Footer';
import { Title } from 'components/NXTable/Header/Title';
import { _TableHeader } from 'components/NXTable/Header/_TableHeader';
import { SortableTitle } from 'components/NXTable/Header/sortableTitle';
import { Row } from 'components/NXTable/Row';
import { SortableButtonWrapper } from 'components/NXTable/SortableButtonWrapper';
import { Table } from 'components/NXTable/Table';
import { NXTableAccordion } from 'components/NXTable/TableAccordion';
import { _useTableSearchSort } from './hooks/useSearchSort';

const NXTable = Object.assign(Table, {
    Accordion: NXTableAccordion,
    Actions: Object.assign(Actions, {
        Base
    }),
    Body,
    ErrorMessage: NXTableErrorMessage,
    Footer: NXTableFooter,
    Header: Object.assign(_TableHeader, { Title, SortableTitle }),
    Row,
    SortableButtonWrapper,

    /**
     * @description
     * Provides a "handleSort" function that will update the sort and sort_by query parameters. This should be used
     * with the NXTable component. To access the query params, use the `useSearch` hook which will provide the
     * type safe params for your route.
     *
     * @note Ensure that the literals that can be sorted by are added as literals in the search schema, otherwise
     * the endpoint may fail since the user can enter any value in the search params.
     */
    useSearchSort: _useTableSearchSort
});

export default NXTable;
