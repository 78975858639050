/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useDispatch } from 'react-redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import { OutlineButton } from 'components/Buttons/OutlineButton';
import NXTable from 'components/NXTable';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useMatchMedia } from 'utilities/hooks/useMatchMedia';
import { getCurrentDate, toLuxonDate } from 'utilities/methods/commonActions';
import { useSSLTableSelector } from '../../own';
import { getInformationFromData } from './helpers';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/

/*   ACTIONS
 *****************************************************/
import { useNavigate } from '@tanstack/react-router';
import { RenderForBrands } from 'config/brandRenderer/component';
import { useBrandStore } from 'config/hooks/useBrandStore';
import { resetSsl } from 'containers/ssl/action';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
/**
 * @typedef {import('../../types').NSSLTable.TSSLActions} TSSLActions
 */

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @private - For use in the _TableBody component.
 * @type {TSSLActions}
 */
export const OwnRenderActions = ({ data }) => {
    /***** SELECTORS *****/
    const { ssl_search_status } = useSSLTableSelector((state) => state.services);

    /***** HOOKS *****/
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isSmallTablet = useMatchMedia({ max: 940 });
    const { activeBrand } = useBrandStore();

    /***** DERIVED *****/
    const { invoice, status, valid_until, id } = getInformationFromData(data);
    const expiryDateLuxon = toLuxonDate(valid_until, 'yyyy-MM-dd');
    const daysTillExpiry = parseInt(String(getCurrentDate().diff(expiryDateLuxon, 'days').toObject().days));
    const isUnpaid = invoice?.attributes?.status === 'unpaid';

    /***** RENDER HELPERS *****/
    const baseButtonProps = /** @type {const} @satisfies {Partial<Parameters<typeof OutlineButton>[number]>} */ ({
        size: /** @type {const} */ ('medium'),
        color: /** @type {const} */ ('primary'),
        type: /** @type {const} */ ('onClick')
    });

    /***** RENDER *****/
    switch (true) {
        case isUnpaid: {
            return (
                <NXTable.Actions>
                    <OutlineButton {...baseButtonProps} color="notice" type="anchor" to={`/billing/invoice/${invoice?.id}/pay`}>
                        Pay Invoice
                    </OutlineButton>
                </NXTable.Actions>
            );
        }
        case status === 'pending':
        case status === 'in progress': {
            const pushConfigure = () => {
                resetSsl()(dispatch);
                navigate({
                    to: '/my-services/ssl/$sslId/configure',
                    params: { sslId: String(id) },
                    hash: 'configuration'
                });
            };

            return (
                <NXTable.Actions>
                    <OutlineButton {...baseButtonProps} onClick={pushConfigure}>
                        Configure
                    </OutlineButton>
                </NXTable.Actions>
            );
        }
        case status === 'pending validation': {
            const pushValidation = () => {
                resetSsl()(dispatch);
                navigate({
                    to: '/my-services/ssl/$sslId/validate',
                    params: { sslId: String(id) },
                    hash: 'validation'
                });
            };

            return (
                <NXTable.Actions>
                    <OutlineButton {...baseButtonProps} onClick={pushValidation}>
                        Validate
                    </OutlineButton>
                </NXTable.Actions>
            );
        }
        case status === 'active':
        case status === 'validated': {
            const pushManage = () => {
                resetSsl()(dispatch);
                navigate({
                    to: `/my-services/ssl/$sslId/manage`,
                    params: { sslId: String(id) },
                    hash: 'overview'
                });
            };

            if (daysTillExpiry !== 0 && !daysTillExpiry && !ssl_search_status) {
                const force = isSmallTablet ? 1.5 : 2.5;
                return (
                    <NXTable.Actions className="SSLOverviewBody__textAction" force={force}>
                        <Text size--s align--right secondary lead--1 italic>
                            An error has occurred retrieving your SSL details.{' '}
                        </Text>

                        <Anchor href={activeBrand === 'intaserve' ? '/contact-support' : '/support/tickets/submit/technical-support'}>
                            Contact us for assistance
                        </Anchor>
                    </NXTable.Actions>
                );
            }

            return (
                <NXTable.Actions>
                    <OutlineButton {...baseButtonProps} onClick={pushManage}>
                        Manage
                    </OutlineButton>
                </NXTable.Actions>
            );
        }
        case status === 'expired': {
            return (
                <NXTable.Actions force={1.5}>
                    <Text secondary size--s>
                        Unable to retrieve status.
                    </Text>
                </NXTable.Actions>
            );
        }
        case status === 'validation submitted': {
            return (
                <NXTable.Actions className="SSLOverviewBody__textAction">
                    <Text size--s align--right secondary lead--1 italic>
                        Validation has been submitted and is awaiting completion.
                    </Text>

                    <RenderForBrands brands={['ventra']}>
                        <Anchor to="/support/support-centre/ssl-certificates/domain-validation-dv-process-ssl-certificates">More Info</Anchor>
                    </RenderForBrands>
                </NXTable.Actions>
            );
        }
        default:
            return (
                <NXTable.Actions className="SSLOverviewBody__textAction">
                    <Text size--s align--right secondary lead--1 italic>
                        Unable to retrieve status.{' '}
                    </Text>
                    <Anchor href="/support/tickets/submit/technical-support">Contact us for assistance</Anchor>
                </NXTable.Actions>
            );
    }
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
