import { useNavigate, useSearch } from '@tanstack/react-router';
import SelectDropdown from 'components/Dropdowns/SelectDropdown';
import { NXSearch } from 'components/Search/NXSearch';
import Text from 'components/Utils/Text';
import { generateCreateShowDropdownOption } from 'containers/services/modules/helpers';
import { NXQuery } from 'utilities/query';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const HostingTableTitleBar = () => {
    /***** HOOKS *****/
    const navigate = useNavigate();
    const searchProps = NXSearch.useQueryParamSearch('search');
    const initialDropdownOption = useSearch({
        strict: false,
        select: ({ record_per_page }) => {
            if (record_per_page === 10) return 0;
            if (record_per_page === 20) return 1;
            if (record_per_page === 50) return 2;
            return undefined;
        }
    });

    /***** QUERIES *****/
    const { data: listData } = NXQuery.hosting.list.useSearchQuery(({ meta }) => meta);

    /***** FUNCTIONS *****/
    const createShowDropdownOption = generateCreateShowDropdownOption(listData?.total, (total) =>
        navigate({
            to: '.',
            replace: true,
            search: (search) => ({
                ...search,
                record_per_page: total
            })
        })
    );

    /***** RENDER *****/
    return (
        <div className="SSLOverviewHeader">
            {/* Title */}
            <Text primary size--xl semiBold>
                Manage Web Hosting
            </Text>

            {/* Filters */}
            <div className="SSLOverviewHeader__filters">
                <NXSearch placeholder="Search for a web hosting service" className="SSLOverviewHeader__search" {...searchProps} />
                <div className="SSLOverviewHeader__actions">
                    <SelectDropdown
                        options={[
                            createShowDropdownOption('10'),
                            createShowDropdownOption('20'),
                            createShowDropdownOption('50'),
                            createShowDropdownOption('All')
                        ]}
                        noSelectionLabel="Show 10"
                        disabled={!!searchProps.value}
                        preselectedOptionIndex={initialDropdownOption || 0}
                    />
                </div>
            </div>
        </div>
    );
};
