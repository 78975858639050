/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { phosphorIconPropKeys } from 'components/Icons/Phosphor/consts';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import type { IconifyIconNamespace } from 'components/Icons/types';
import { createAppliedStylingClasses } from 'components/Utils/methods';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const VIPsitesIcon: IconifyIconNamespace.Component = (props = {}) => {
    const { size = 25 } = props;
    const appliedStylingClasses = createAppliedStylingClasses({
        props,
        keyBoundary: phosphorIconPropKeys,
        componentName: 'Icon',
        delimiter: '--'
    });

    return (
        <svg
            width={size}
            height={size}
            className={classNames('Icon', appliedStylingClasses)}
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 141.7 121.5"
            fill="currentColor"
        >
            <g>
                <g>
                    <path
                        d="M49.7,20.3h-4h-4c-1,0-2-0.4-2.6-1.1c-0.7-0.7-1.1-1.6-1.1-2.6v0v0c0-1,0.4-2,1.1-2.6c0.7-0.7,1.6-1.1,2.6-1.1h4h4
                    c1,0,2,0.4,2.6,1.1c0.7,0.7,1.1,1.6,1.1,2.6v0v0c0,1-0.4,2-1.1,2.6C51.7,19.9,50.8,20.3,49.7,20.3z"
                    />
                    <path
                        d="M26.7,20.3h-4h-4c-1,0-2-0.4-2.6-1.1c-0.7-0.7-1.1-1.6-1.1-2.6v0v0c0-1,0.4-2,1.1-2.6c0.7-0.7,1.6-1.1,2.6-1.1h4h4
                    c1,0,2,0.4,2.6,1.1c0.7,0.7,1.1,1.6,1.1,2.6v0v0c0,1-0.4,2-1.1,2.6C28.7,19.9,27.8,20.3,26.7,20.3z"
                    />
                </g>
                <path
                    d="M131.4,0H70.9H10.3C7.5,0,4.9,1.2,3,3s-3,4.5-3,7.3v50.4v50.4c0,2.9,1.2,5.4,3,7.3s4.5,3,7.3,3h60.5h60.5
                c2.9,0,5.4-1.2,7.3-3c1.9-1.9,3-4.5,3-7.3V60.8V10.3c0-2.9-1.2-5.4-3-7.3C136.8,1.2,134.2,0,131.4,0z M10.3,7.5h60.5h60.5
                c0.8,0,1.5,0.3,2,0.8c0.5,0.5,0.8,1.2,0.8,2V18v7.7H70.9H7.5V18v-7.7c0-0.8,0.3-1.5,0.8-2C8.8,7.8,9.6,7.5,10.3,7.5z M52,114h-8.6
                h-8.6v-3.7v-3.7h8.6H52v3.7V114z M39.6,63.1v18v18h-2.4h-2.4V81v-18h2.4H39.6z M36.5,55.5l3.4-3.4l3.4-3.4l3.4,3.4l3.4,3.4h-3.4
                h-3.4H40H36.5z M52,99h-2.4h-2.4V81v-18h2.4H52v18V99z M98,109.3c0,0.8-0.1,1.7-0.2,2.5c-0.1,0.8-0.2,1.6-0.3,2.3h-3.1h-3.1
                c-0.1-0.8-0.2-1.5-0.3-2.3c-0.1-0.8-0.1-1.6-0.2-2.5c0-0.7-0.1-1.4-0.1-2.1c0-0.7,0-1.4,0-2.1c1.2,0.1,2.4,0.2,3.6,0.2
                c1.2,0,2.4-0.1,3.6-0.2c0,0.7,0,1.4,0,2.1C98.1,107.8,98,108.6,98,109.3z M91.1,97.5c0.1-0.9,0.1-1.7,0.2-2.6
                c0.1-0.8,0.2-1.6,0.3-2.4c1,0.1,1.9,0.2,2.9,0.2c1,0,1.9-0.1,2.9-0.2c0.1,0.8,0.2,1.6,0.3,2.4c0.1,0.8,0.2,1.7,0.2,2.6
                c-1.1,0.2-2.2,0.2-3.4,0.2C93.3,97.7,92.2,97.6,91.1,97.5z M85.6,81.6c-2.4-2.4-3.7-5.6-3.7-8.8s1.2-6.4,3.7-8.8l0,0l0,0l5.5-5.5
                l5.5-5.5c0.6,2.1,1.5,4.1,2.6,6s2.5,3.6,4,5.1c2.4,2.4,3.7,5.6,3.7,8.8s-1.2,6.4-3.7,8.8c-2.4,2.4-5.6,3.7-8.8,3.7S88,84,85.6,81.6
                z M131.4,114h-13.2h-13.2c0.5-4.2,0.6-8.5,0.5-12.6c-0.1-4.1-0.5-8.1-0.9-11.5c4.8-2.8,7.9-7.3,9.2-12.2s0.7-10.4-2.1-15.2
                c-0.4-0.7-0.9-1.4-1.4-2.1c-0.5-0.7-1.1-1.3-1.7-1.9c-1.3-1.3-2.4-2.8-3.2-4.3s-1.4-3.1-1.7-4.8c-0.1-0.7-0.2-1.4-0.3-2.2
                c0-0.7,0-1.5,0.1-2.2c0.2-0.8,0.1-1.6-0.3-2.3c-0.3-0.7-0.9-1.3-1.6-1.7c-0.7-0.4-1.6-0.5-2.3-0.4c-0.8,0.1-1.5,0.5-2.1,1.1
                l-8.4,8.4l-8.4,8.4c-3.9,3.9-5.8,9-5.8,14.2c0,5.1,2,10.2,5.9,14.1c0.6,0.6,1.2,1.1,1.9,1.7c0.7,0.5,1.4,1,2.1,1.4
                c-0.4,3.4-0.8,7.4-0.9,11.5c-0.1,4.1,0,8.4,0.5,12.6H71.7H59.5l0-28.6l0-28.6c0-0.8-0.2-1.6-0.5-2.4c-0.3-0.8-0.8-1.4-1.4-2
                l-4.9-4.9l-4.9-4.9c-1.2-1.2-2.8-1.8-4.4-1.8c-1.6,0-3.2,0.6-4.4,1.8L34,47.4l-4.9,4.9c-0.6,0.6-1,1.3-1.4,2
                c-0.3,0.8-0.5,1.6-0.5,2.4l0,28.6l0,28.6h-8.5h-8.5c-0.8,0-1.5-0.3-2-0.8c-0.5-0.5-0.8-1.2-0.8-2v-39v-39h63.4h63.4v39v39
                c0,0.8-0.3,1.5-0.8,2C132.9,113.7,132.2,114,131.4,114z"
                />
            </g>
        </svg>
    );
};
