import Text from 'components/Utils/Text';
import 'containers/login/forms/IntaserveLogin/oAuthButton/_oAuthButton.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type OAuthButton = React.FC<OAuthButtonProps>;
type OAuthButtonProps = {
    icon: string;
    provider: string;
    onClick: () => void;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const OAuthButton: OAuthButton = ({ icon, provider, onClick }) => {
    return (
        <button type="button" className="oAuthButton" onClick={onClick}>
            <img src={icon} alt="logo" className="oAuthButton__icon" />
            <Text color="S_color_text_primary_base">Sign in with {provider}</Text>
        </button>
    );
};
