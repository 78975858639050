import { zodResolver } from '@hookform/resolvers/zod';
import { CheckBoxList } from 'components/Form/CheckBoxList';
import type { HookFormCheckboxListItem } from 'components/Form/CheckBoxList/_CheckBoxListItem/wrappers/hookform/types';
import { Input } from 'components/Form/Input';
import type { _InputHookFormDefault } from 'components/Form/Input/wrappers/hookForm/Default';
import { useMemo } from 'react';
import { type UseFormProps, useForm } from 'react-hook-form';
import type { NXSchema } from 'utilities/types/zod';
import type { z } from 'zod';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * @description
 * Abstraction on the basic `useForm` hook that is provided by React Hook Form.
 *
 * This abstraction aims to tackle the issue of inference when using a zodSchema with the useForm hook, where
 * it does not actually infer the type when using the `resolver` prop.
 *
 * @problemSolution
 * In the original `useForm` hook, to properly get types for the form, you would need to do the following
 * ```ts
 * useForm<z.input<typeof Schema>, unknown, z.output<typeof Schema>>({ ... });
 * ```
 *
 * Instead, this automatically binds this for you while retaining the context generic.
 *
 * Moving forward, this hook may also have further functionality attached if desired.
 */
export const useNXHookForm = <TSchema extends z.Schema<any, any>, TContext>(
    resolver: TSchema,
    opts: Omit<UseFormProps<TSchema['_input'], TContext>, 'resolver'>
) => {
    const form = useForm<TSchema['_input'], TContext, TSchema['_output']>({
        /** Application specific defaults */
        mode: 'all',
        resolver: zodResolver(resolver),

        /* Form specific options */
        ...opts
    });

    /***** HOOK RESULTS *****/
    return useMemo(() => {
        return Object.assign(form, {
            get Input(): _InputHookFormDefault.NameBoundInputHookForm<NXSchema.InferFormNames<TSchema>> {
                return Input.HookForm;
            },
            get CheckboxList() {
                return {
                    get Item(): HookFormCheckboxListItem.TypeBoundItem<TSchema> {
                        return CheckBoxList.Item.HookForm;
                    }
                };
            }
        });
    }, [form]);
};
