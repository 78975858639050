import { FormItem } from 'components/Form/FormItem';
import { FormItemInner } from 'components/Form/FormItemInner';
import { FormLabel } from 'components/Form/FormLabel';
import { _TextArea as TextArea } from 'components/Form/TextArea/_TextArea';
import type { NXTextAreaNamespace } from 'components/Form/TextArea/types';
import mergeRefs from 'merge-refs';
import React from 'react';
import { useController } from 'react-hook-form';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * NXTextArea HookForm Wrapper
 */
export const _TextAreaHookFormDefault: React.FC<NXTextAreaNamespace.HookForm.Props> = (props) => {
    const {
        label,
        disabled,
        rows,
        isPlaceholderItalic,
        placeholder,
        className,
        intrinsicProps,
        name,
        textAreaRef,
        FormItemInnerVariant = FormItemInner.HookForm
    } = props;

    /***** HOOKS *****/
    const { field } = useController({ name });
    const { value, onChange, onBlur, ref } = field;

    /***** RENDER *****/
    return (
        <FormItem disabled={disabled} name={name} className={className}>
            <FormLabel htmlFor={name || ''}>{label}</FormLabel>
            <FormItemInnerVariant name={name}>
                <TextArea
                    disabled={disabled}
                    value={value}
                    name={name}
                    rows={rows}
                    placeholder={placeholder}
                    isPlaceholderItalic={isPlaceholderItalic}
                    textAreaRef={mergeRefs(ref, textAreaRef)}
                    intrinsicProps={{ ...intrinsicProps, onChange, onBlur }}
                />
            </FormItemInnerVariant>
        </FormItem>
    );
};
