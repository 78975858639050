import classNames from 'classnames';
import { useDropdownWrapperContext } from 'components/Dropdowns/DropdownWrapper/consts';
import type { DropdownWrapperNamespace } from 'components/Dropdowns/DropdownWrapper/types';
import type { DropdownNamespace } from 'components/Dropdowns/types';
import { PhosphorIcons } from 'components/Icons/Phosphor';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Props = {
    variant?: DropdownWrapperNamespace.Variant;
    color?: DropdownNamespace.Color;
    isOpen?: boolean;
    setIsOpen?: (state: boolean) => void;
    className?: string;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _DropdownDotsButton: React.FC<Props> = ({ variant, color, isOpen, setIsOpen, className }) => {
    /***** HOOKS *****/
    const { isOpen: contextIsOpen, setIsOpen: contextSetIsOpen, color: contextColor, variant: contextVariant } = useDropdownWrapperContext();

    /***** RENDER HELPERS *****/
    const _variant = variant || contextVariant;
    const _color = color || contextColor;
    const _isOpen = isOpen || contextIsOpen;
    const _setIsOpen = setIsOpen || contextSetIsOpen;

    function getDotsColour() {
        if ((_color === 'gray' && _variant === 'Solid') || (_color === 'primary' && _variant !== 'Outline')) {
            return 'white';
        }
        return _color;
    }

    const dotsColour = getDotsColour();

    const classes = classNames('DropdownDotsButton DropdownDotsButton__menu', className, {
        open: _isOpen
    });

    const dotsColourProps = dotsColour ? { [dotsColour]: true } : {};

    /***** RENDER *****/
    return (
        <button type="button" className={classes} onClick={() => _setIsOpen(!_isOpen)} data-testid="TESTID_baseDrodownDotsButton">
            <div className="dots">
                <PhosphorIcons.DotsThree.Bold size={24} {...dotsColourProps} />
            </div>
        </button>
    );
};
