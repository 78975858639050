import { useSearch } from '@tanstack/react-router';
import type { AppSchema } from 'App';
import { useEffect } from 'react';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Hook for triggering an action when the page first mounts and has search params relating to the invoice URL Lightbox.
 *
 * This will run the effect if the component mounts and matches the action param, or as long as any invoice action is present
 */
export const useInvoiceActionEffect = (action: AppSchema['invoice-action'] | 'cancel|pay', effect: () => Promise<void> | void) => {
    /***** HOOKS *****/
    const invoiceAction = useSearch({
        strict: false,
        select: ({ 'invoice-action': invoiceAction }) => invoiceAction
    });

    /***** EFFECTS *****/
    useEffect(() => {
        switch (true) {
            case !action:
            case !invoiceAction:
                return;
            case invoiceAction === action:
            case ['cancel', 'pay'].includes(invoiceAction || ''):
                effect();
                return;
            default:
                return;
        }
    }, []);
};
