import { useTabsContext } from 'components/NXBox/Tabs/contexts/useTabContext';
import { createElement } from 'react';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type NXBoxTabContent = React.FC<NXBoxTabContentProps>;
type NXBoxTabContentProps = {
    id: string;
    component: React.FC;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _NXBoxTabContent: NXBoxTabContent = ({ id, component }) => {
    /***** HOOKS *****/
    const { isActive } = useTabsContext(id);

    /***** RENDER *****/
    if (!isActive) return null;

    return (
        <div id={`tabcontent-${id}`} role="tabpanel" aria-labelledby={`tab-${id}`}>
            {createElement(component)}
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
