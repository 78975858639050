import { useMutation } from '@tanstack/react-query';
import { API } from 'utilities/api/ms365';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useMutation(id: string) {
    async function mutationFn(attributes: any) {
        const response = await API.ms365.POST.updateConfigCalculate(id, attributes);
        return response.data.data;
    }

    /***** HOOK RESULTS *****/
    return useMutation({
        mutationFn,
        onSuccess: () => {}
    });
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const updateMs365ConfigCalcuateData = Object.freeze({
    useMutation: _useMutation
});
