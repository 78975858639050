import { useNavigate } from '@tanstack/react-router';
import Cancel from 'components/Cancel';
import { useHostingContext } from 'containers/services/modules/hosting/context';
import { NXQuery } from 'utilities/query';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Note: This component calls the cancel component internally which automatically handles the "cancel" process, however
 * expects the "revertCancellation" mutation to be passed as a parameter. I am not sure why one but not both of these need
 * to be provided, however this handles both the "cancel" and "revert" processes despite it not being obvious.
 */
export const CancellationButton = () => {
    /***** HOOKS *****/
    const { attributes, id } = useHostingContext();
    const navigate = useNavigate();

    /***** QUERIES *****/
    const { mutate: revertServiceCancellation, isSuccess, isPending } = NXQuery.services.revertCancellation.useMutation();

    /***** RENDER *****/
    return (
        <Cancel
            button={{
                type: 'outline',
                label: 'Cancel Service',
                size: 'medium',
                className: 'cancelService__button--long'
            }}
            cancel={{
                id,
                service: 'webHosting',
                status: attributes.domain_status,
                keep: {
                    title: 'Keep Hosting Service',
                    desc: "Are you sure you'd like to cancel this request and keep your service?",
                    action: () =>
                        revertServiceCancellation(id, {
                            onSuccess: () => {
                                // Redirect to hosting list in case we are on dashboard
                                navigate({ to: '/my-services/hosting' });
                            }
                        })
                },
                loading: isPending ? 'loading' : null
            }}
            title="Cancel Service"
            desc="Once you cancel this service, all files, emails, databases and configurations will be deleted. To keep your data, make a full backup before cancelling your service."
            label="Cancel Hosting Service"
            isSuccess={isSuccess}
        />
    );
};
