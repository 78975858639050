import type { SuperUserNamespace } from 'components/StaffMenu/SuperUser/types';
import { UserPreferences } from 'utilities/UserPreferences';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export function useIsNewSuperAction(key: SuperUserNamespace.Key) {
    /***** HOOKS *****/
    const [viewedItems = [], setViewedItems] = UserPreferences.useLocalStorage('SUPER_USER_ACTION_VIEWED');

    /***** FUNCTIONS *****/
    const markAsViewed = () => {
        if (!viewedItems.includes(key)) {
            setViewedItems([...viewedItems, key]);
        }
    };

    /***** RENDER HELPERS *****/
    const isNew = !viewedItems.includes(key);

    /***** HOOK RESULTS *****/
    return [isNew, markAsViewed] as const;
}
