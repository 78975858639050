import {
    LOGIN_FORGOT_EMAIL_ERROR,
    LOGIN_FORGOT_EMAIL_REQUEST,
    LOGIN_FORGOT_EMAIL_SUCCESS,
    LOGIN_FORGOT_PASSWORD_ERROR,
    LOGIN_FORGOT_PASSWORD_REQUEST,
    LOGIN_FORGOT_PASSWORD_SUCCESS,
    LOGIN_INITIAL_STATE,
    LOGIN_LOADING_UPDATE,
    LOGIN_RECOVERY_MANUAL_ERROR,
    LOGIN_RECOVERY_MANUAL_REQUEST,
    LOGIN_RECOVERY_MANUAL_SUCCESS,
    LOGIN_RESET_PASSWORD_ERROR,
    LOGIN_RESET_PASSWORD_REQUEST,
    LOGIN_RESET_PASSWORD_SUCCESS,
    LOGIN_SECURITY_INFORMATION_ERROR,
    LOGIN_SECURITY_INFORMATION_REQUEST,
    LOGIN_SECURITY_INFORMATION_SUCCESS,
    LOGIN_SECURITY_QUESTIONS_ERROR,
    LOGIN_SECURITY_QUESTIONS_REQUEST,
    LOGIN_SECURITY_QUESTIONS_SUCCESS,
    REMOVE_URL_PARAMS,
    SET_URL_PARAMS
} from './actionTypes';

const initialState = {
    login_loading_state: false
};

const loginReducer = (state = initialState, action) => {
    switch (action.type) {
        /**********************************************************************************************************
         *   BASE STATES
         **********************************************************************************************************/
        case LOGIN_LOADING_UPDATE:
            return {
                ...state,
                login_loading_state: action.login_loading_state
            };

        case SET_URL_PARAMS:
            return {
                ...state,
                login_url_params: action.params
            };

        case REMOVE_URL_PARAMS:
            return {
                ...state,
                login_url_params: null
            };

        /**********************************************************************************************************
         *   SECURITY STATES
         **********************************************************************************************************/
        case LOGIN_SECURITY_QUESTIONS_REQUEST:
            return {
                ...state,
                login_security_questions_status: 'loading',
                login_security_questions_data: null
            };

        case LOGIN_SECURITY_QUESTIONS_SUCCESS:
            return {
                ...state,
                login_security_questions_status: 'success',
                login_security_questions_data: action.login_security_questions_data
            };

        case LOGIN_SECURITY_QUESTIONS_ERROR:
            return {
                ...state,
                login_security_questions_status: 'error',
                login_security_questions_data: null
            };

        /**********************************************************************************************************
         *   RECOVERY STATES
         **********************************************************************************************************/
        case LOGIN_RECOVERY_MANUAL_REQUEST:
            return {
                ...state,
                login_recovery_manual_status: 'loading'
            };

        case LOGIN_RECOVERY_MANUAL_SUCCESS:
            return {
                ...state,
                login_recovery_manual_status: 'success'
            };

        case LOGIN_RECOVERY_MANUAL_ERROR:
            return {
                ...state,
                login_recovery_manual_status: 'error'
            };

        /**********************************************************************************************************
         *   FORGOT STATES
         **********************************************************************************************************/
        case LOGIN_FORGOT_PASSWORD_REQUEST:
            return {
                ...state,
                login_forgot_password_status: 'loading'
            };

        case LOGIN_FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                login_forgot_password_status: 'success'
            };

        case LOGIN_FORGOT_PASSWORD_ERROR:
            return {
                ...state,
                login_forgot_password_status: 'error'
            };

        case LOGIN_FORGOT_EMAIL_REQUEST:
            return {
                ...state,
                login_forgot_email_status: 'loading'
            };

        case LOGIN_FORGOT_EMAIL_SUCCESS:
            return {
                ...state,
                login_forgot_email_status: 'success'
            };

        case LOGIN_FORGOT_EMAIL_ERROR:
            return {
                ...state,
                login_forgot_email_status: 'error'
            };

        /**********************************************************************************************************
         *   RESET STATES
         **********************************************************************************************************/
        case LOGIN_RESET_PASSWORD_REQUEST:
            return {
                ...state,
                login_reset_password_status: 'loading'
            };

        case LOGIN_RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                login_reset_password_status: 'success'
            };

        case LOGIN_RESET_PASSWORD_ERROR:
            return {
                ...state,
                login_reset_password_status: 'error'
            };

        /**********************************************************************************************************
         *   ACTIVATE STATES
         **********************************************************************************************************/
        case LOGIN_SECURITY_INFORMATION_REQUEST:
            return {
                ...state,
                login_security_information_status: 'loading',
                login_security_information_data: null
            };

        case LOGIN_SECURITY_INFORMATION_SUCCESS:
            return {
                ...state,
                login_security_information_status: 'success',
                login_security_information_data: action.login_security_information_data
            };

        case LOGIN_SECURITY_INFORMATION_ERROR:
            return {
                ...state,
                login_security_information_status: 'error'
            };

        /**********************************************************************************************************
         *   BASE STATES
         **********************************************************************************************************/
        case LOGIN_INITIAL_STATE:
            return initialState;

        default:
            return state;
    }
};
export default loginReducer;
