/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import type { QueryKey } from '@tanstack/react-query';
import { queryClient } from 'store/queryClient';
import type { NXQueryUtils } from 'utilities/methods/tanstack/types';

export function createGetQueryDataMethod<TParams extends any, TData extends NXQueryUtils.ApiData>(createQueryKey: (params: TParams) => QueryKey) {
    return function getQueryData(params: TParams): TData | undefined {
        return queryClient.getQueryData(createQueryKey(params));
    };
}
