import Text from 'components/Utils/Text';
import { HostingTableRowTitle } from 'containers/services/modules/hosting/components/accordionTitle';
import { HostingTableColumn } from 'containers/services/modules/hosting/components/column';
import { useHostingContext } from 'containers/services/modules/hosting/context';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const OwnTitle = () => {
    /***** HOOKS *****/
    const serviceData = useHostingContext();

    /***** RENDER *****/
    return (
        <HostingTableColumn>
            <HostingTableRowTitle>DOMAIN</HostingTableRowTitle>
            <Text bold size--s lead--1 align--left black>
                {serviceData.attributes.domain}
            </Text>
            <Text secondary uppercase align--left size--xs bold lead--1 className="HostingTable__plan">
                {serviceData.attributes.product.name}
            </Text>
        </HostingTableColumn>
    );
};
