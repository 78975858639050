import { useQueryClient, type MutationObserverOptions, type QueryObserverOptions } from '@tanstack/react-query';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type DefaultQueryOptions = QueryObserverOptions<unknown, Error>;
type DefaultMutationOptions = MutationObserverOptions<unknown, unknown, /* Defaults to void so using unknown to remove errors */ unknown>;
// prettier-ignore
type UseDefaultQueryClientOptionsReturnType = readonly [
    Partial<NonNullable<DefaultQueryOptions>>, 
    Partial<NonNullable<DefaultMutationOptions>>
];

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Hook for accessing the default query client options. This is useful for adding functionality to default
 * options while still calling them (ie. onSuccess for mutations)
 */
export const useDefaultQueryClientOptions = (): UseDefaultQueryClientOptionsReturnType => {
    /***** HOOKS *****/
    const queryClient = useQueryClient();
    const defaults = queryClient.getDefaultOptions();

    /***** HOOK RESULTS *****/
    return [defaults.queries ?? ({} as any), defaults.mutations ?? ({} as any)] as const;
};
