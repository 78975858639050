/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { queryOptions, useQuery } from '@tanstack/react-query';
import { katanaQueryKeys } from 'containers/katana/queries/katanaQueryKeys';
import type { KatanaNamespace } from 'containers/katana/types';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { KATANA_API } from 'utilities/api/katana';

function createQueryKey(serviceID: KatanaNamespace.ServiceId) {
    return katanaQueryKeys.katana.service.ID.domainOptions(serviceID);
}

function createQueryOptions(serviceID: KatanaNamespace.ServiceId) {
    return queryOptions({
        queryKey: createQueryKey(serviceID),
        queryFn: async () => {
            return KATANA_API.katana.service_id.domain_options.GET(serviceID);
        },
        select: (data) => {
            if (data?.status === 200) {
                return data.data;
            }
        },
    });
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useQuery(serviceID: KatanaNamespace.ServiceId) {
    return useQuery(createQueryOptions(serviceID));
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const getDomainOptions = Object.freeze({
    useQuery: _useQuery,
});
