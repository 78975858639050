import { _SelectDropDownHookFormIntaserve } from 'containers/orderingForm/orderingFormInputs/selectDropDown/selectDropdownHookForm';
import { TextInput } from 'containers/orderingForm/orderingFormInputs/textInput';
import { _TextInputHookFormIntaserve } from 'containers/orderingForm/orderingFormInputs/textInput/textInputHookForm';
import { _ToggleSwitchHookFormIntaserve } from 'containers/orderingForm/orderingFormInputs/toggleSwitch/switchHookForm';

export const IntaserveFormInput = {
    Default: { Text: TextInput },
    HookForm: {
        Text: _TextInputHookFormIntaserve,
        Select: _SelectDropDownHookFormIntaserve,
        Switch: _ToggleSwitchHookFormIntaserve
    }
};
