import { authenticationQueryKeys } from 'containers/login/queries/queryKeys';
import { API } from 'utilities/api/login';
import { createNXQuery } from 'utilities/methods/tanstack/createQuery';

/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
export const oAuthLogin = createNXQuery((params: API.login.oAuthSubmit.params) => ({
    queryKey: authenticationQueryKeys.oAuthLogin(params),
    queryFn: () => API.login.oAuthSubmit(params)
}));
