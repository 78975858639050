/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { CheckBox } from 'components/Form/CheckBox';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { useRadioContext } from 'components/Form/Radio/_Radio/methods';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
import type { CheckBoxPanelNamespace } from 'components/Form/CheckBox/wrappers/Panel/types';

type CheckBoxCirclePanelWithRadioProps = Omit<CheckBoxPanelNamespace.Props, 'isChecked' | 'onChange'> & {
    identifier: string;
    
    /**
     * When the `identifier === activeRadioButton`, this function will also be called
     */
    onEqualIdentifierClick?: () => void;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _RadioCheckBoxPanel: React.FC<CheckBoxCirclePanelWithRadioProps> = ({ identifier, onEqualIdentifierClick, ...props }) => {
    /***** HOOKS *****/
    const { activeRadioButton, setActiveRadioButton } = useRadioContext();

    /***** FUNCTIONS *****/
    function handleOnChange() {
        if (identifier === activeRadioButton) {
            onEqualIdentifierClick?.();
            return;
        }
        setActiveRadioButton(identifier);
    }

    /***** RENDER HELPERS *****/
    const isChecked = activeRadioButton === identifier;

    /***** RENDER *****/
    return <CheckBox.Panel isChecked={isChecked} onChange={handleOnChange} {...props} />;
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
