import { PhosphorIcons } from 'components/Icons/Phosphor';
import { Flex } from 'components/Utils/Flex';
import { QuantityButton } from 'containers/services/forms/ssl/productCard/quantityButton';
import type { Product } from 'containers/services/forms/ssl/types';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type QuantitySelector = React.FC<QuantitySelectorProps>;
type QuantitySelectorProps = {
    id: number;
    setSelectedProducts: React.Dispatch<React.SetStateAction<Product[]>>;
    className: string;
    productQty: number;
    product: {
        id: number;
        attributes: {
            billing_cycle_id: number;
            price: string;
        };
    };
    disabled: boolean;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const QuantitySelector: QuantitySelector = ({ id, setSelectedProducts, className, product, productQty, disabled }) => {
    /***** FUNCTIONS *****/
    const increaseSslQuantity = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        setSelectedProducts((prev) => {
            return [...prev, { id: product?.id, billing_cycle_id: product?.attributes?.billing_cycle_id, price: product?.attributes?.price }];
        });
    };

    const decreaseSslQuantity = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        setSelectedProducts((prev) => {
            const indexToRemove = prev.findIndex((product) => product.id === id);

            if (productQty > 1) {
                return prev.filter((_, index) => index !== indexToRemove);
            }

            return prev;
        });
    };

    const handleInputOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newQuantity = Math.max(1, parseInt(e.target.value) || 1);

        const quantityValidation = () => {
            if (newQuantity < 1) return 1;
            if (newQuantity > 99) return 99;

            return newQuantity;
        };

        setSelectedProducts((prev) => {
            const removeProduct = prev.filter((product) => {
                return product.id !== id;
            });

            const updatedSelectedProducts = [...removeProduct];

            for (let i = 0; i < quantityValidation(); i++) {
                updatedSelectedProducts.push({
                    id: product?.id,
                    billing_cycle_id: product?.attributes?.billing_cycle_id,
                    price: product?.attributes?.price
                });
            }

            return updatedSelectedProducts;
        });
    };

    /***** RENDER *****/
    return (
        <Flex className={className}>
            <QuantityButton onClick={decreaseSslQuantity} icon={<PhosphorIcons.Minus.Bold />} disabled={productQty < 2 || disabled} />
            <input
                type="number"
                value={productQty}
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => handleInputOnChange(e)}
                disabled={disabled}
                className="sslProducts__quantityInput"
            />
            <QuantityButton onClick={increaseSslQuantity} icon={<PhosphorIcons.Plus.Bold />} disabled={productQty === 99 || disabled} />
        </Flex>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
