/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Padding } from 'components/Utils/Padding';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { PhosphorIcons } from 'components/Icons/Phosphor';
import { Flex } from 'components/Utils/Flex';
import './_NXTableErrorMessage.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {NErrorMessage.TErrorMessage}
 */
export const NXTableErrorMessage = ({ message, className }) => {
    /***** HOOKS *****/
    const isMobile = useAppViewport(['xs', 'sm']);

    /***** RENDER HELPERS *****/
    const textProps = {
        'lead--1': true,
        'size--s': true,
        'secondary': true,
        'size--xss': isMobile,
        'align--center': true,
        'className': classNames('NXTableErrorMessage', className),
    };

    /***** RENDER *****/
    if (!message) {
        return null;
    }

    return (
        <Padding xy={isMobile ? 5 : 3}>
            <Flex direction="column" align="center">
                <PhosphorIcons.WarningDiamond warn size={30} />
                <Text {...textProps}>{message}</Text>
            </Flex>
        </Padding>
    );
};
