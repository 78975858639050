/**********************************************************************************************************
 *   GLOBAL IMPORTS
 **********************************************************************************************************/
import axios from 'axios';
import store from 'store/store';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { API as ACCOUNT } from 'utilities/api/account';
import { API as LOGIN } from 'utilities/api/login';
import { getDataFromSuccessResponse, getErrorFromFailResponse } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   EXTERNAL ACTIONS
 **********************************************************************************************************/

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { notificationScopes } from 'components/Toast/consts';
import { pushNotification } from 'components/Toast/functions';

import {
    LOGIN_FORGOT_EMAIL_ERROR,
    LOGIN_FORGOT_EMAIL_REQUEST,
    LOGIN_FORGOT_EMAIL_SUCCESS,
    LOGIN_FORGOT_PASSWORD_ERROR,
    LOGIN_FORGOT_PASSWORD_REQUEST,
    LOGIN_FORGOT_PASSWORD_SUCCESS,
    LOGIN_LOADING_UPDATE,
    LOGIN_RECOVERY_MANUAL_ERROR,
    LOGIN_RECOVERY_MANUAL_REQUEST,
    LOGIN_RECOVERY_MANUAL_SUCCESS,
    LOGIN_RESET_PASSWORD_ERROR,
    LOGIN_RESET_PASSWORD_REQUEST,
    LOGIN_RESET_PASSWORD_SUCCESS,
    LOGIN_SECURITY_INFORMATION_ERROR,
    LOGIN_SECURITY_INFORMATION_REQUEST,
    LOGIN_SECURITY_INFORMATION_SUCCESS,
    LOGIN_SECURITY_QUESTIONS_ERROR,
    LOGIN_SECURITY_QUESTIONS_REQUEST,
    LOGIN_SECURITY_QUESTIONS_SUCCESS,
    REMOVE_URL_PARAMS,
    SET_URL_PARAMS
} from './actionTypes';

/**********************************************************************************************************
 *   ACTIONS
 **********************************************************************************************************/
export const setLoadingState = (state) => {
    return (dispatch) => {
        dispatch({
            type: LOGIN_LOADING_UPDATE,
            login_loading_state: state
        });
    };
};

export const removeUrlParams = () => {
    return (dispatch) => {
        dispatch({
            type: REMOVE_URL_PARAMS
        });
    };
};

export const setUrlParams = (params) => {
    return (dispatch) => {
        dispatch({
            type: SET_URL_PARAMS,
            params
        });
    };
};

export const getSecurityQuestions = () => {
    return (dispatch) => {
        dispatch({
            type: LOGIN_SECURITY_QUESTIONS_REQUEST
        });

        ACCOUNT.account.GET.security
            .list()
            .then((response) => {
                const login_security_questions_data = getDataFromSuccessResponse(response);

                dispatch({
                    type: LOGIN_SECURITY_QUESTIONS_SUCCESS,
                    login_security_questions_data
                });
            })
            .catch(() => {
                dispatch({
                    type: LOGIN_SECURITY_QUESTIONS_ERROR
                });
            });
    };
};

export const getUserSecurityInformation = () => {
    return (dispatch) => {
        dispatch({
            type: LOGIN_SECURITY_INFORMATION_REQUEST
        });

        ACCOUNT.account.GET.security
            .question()
            .then((response) => {
                const processedResponse = getDataFromSuccessResponse(response);
                const { attributes, id } = processedResponse;
                const login_security_information_data = {
                    ...attributes,
                    question_id: id
                };

                dispatch({
                    type: LOGIN_SECURITY_INFORMATION_SUCCESS,
                    login_security_information_data
                });
            })
            .catch(() => {
                dispatch({
                    type: LOGIN_SECURITY_INFORMATION_ERROR
                });
            });
    };
};

export const resetPassword = (token, values) => {
    return (dispatch) => {
        dispatch(setLoadingState(true));
        dispatch({
            type: LOGIN_RESET_PASSWORD_REQUEST
        });

        ACCOUNT.account.POST.passwordReset(token, values)
            .then((response) => {
                const login_reset_password_data = getDataFromSuccessResponse(response);

                pushNotification(login_reset_password_data, null, notificationScopes.GUEST);

                dispatch({
                    type: LOGIN_RESET_PASSWORD_SUCCESS
                });
                dispatch(setLoadingState(false));
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);

                pushNotification(response, null, notificationScopes.GUEST);
                dispatch({
                    type: LOGIN_RESET_PASSWORD_ERROR
                });
                dispatch(setLoadingState(false));
            });
    };
};

export const forgotPasswordMethod = (values) => {
    return (dispatch) => {
        dispatch(setLoadingState(true));
        dispatch({
            type: LOGIN_FORGOT_PASSWORD_REQUEST
        });

        LOGIN.login.forgot
            .password(values)
            .then((response) => {
                const login_forgot_password_data = getDataFromSuccessResponse(response);

                pushNotification(login_forgot_password_data, null, notificationScopes.GUEST);
                dispatch(setLoadingState(false));
                dispatch({
                    type: LOGIN_FORGOT_PASSWORD_SUCCESS
                });
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);

                pushNotification(response, null, notificationScopes.GUEST);
                dispatch(setLoadingState(false));
                dispatch({
                    type: LOGIN_FORGOT_PASSWORD_ERROR
                });
            });
    };
};

export const forgotEmailMethod = (attributes) => {
    return (dispatch) => {
        dispatch(setLoadingState(true));
        dispatch({
            type: LOGIN_FORGOT_EMAIL_REQUEST
        });

        axios
            .post('/api/forgot/email', {
                type: 'forgot',
                attributes
            })
            .then((response) => {
                const login_forgot_email_data = getDataFromSuccessResponse(response);

                pushNotification(login_forgot_email_data, null, notificationScopes.GUEST);
                dispatch(setLoadingState(false));
                dispatch({
                    type: LOGIN_FORGOT_EMAIL_SUCCESS
                });
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);

                pushNotification(response, null, notificationScopes.GUEST);
                dispatch(setLoadingState(false));
                dispatch({
                    type: LOGIN_FORGOT_EMAIL_ERROR
                });
            });
    };
};

export const submitRecoveryForm = (values) => {
    const { dispatch } = store;
    dispatch(setLoadingState(true));
    dispatch({
        type: LOGIN_RECOVERY_MANUAL_REQUEST
    });

    LOGIN.login.recovery
        .submit({
            name: values.name,
            security_question_id: values.security_question_id,
            security_answer: values.security_answer,
            dob: values.dob,
            phone: values.phone,
            postcode: values.postcode,
            message: values.message,
            contact_email: values.contact_email
        })
        .then(() => {
            dispatch({
                type: LOGIN_RECOVERY_MANUAL_SUCCESS
            });

            dispatch(setLoadingState(false));
        })
        .catch((error) => {
            const response = getErrorFromFailResponse(error);

            pushNotification(response, null, notificationScopes.GUEST);
            dispatch({
                type: LOGIN_RECOVERY_MANUAL_ERROR
            });
            dispatch(setLoadingState(false));
        });
};
