import { RegistrationOrderingFormContext } from 'App/components/templates/contexts/registrationOrderingFormContext/context';
import { useContext } from 'react';

export const useRegistrationOrderingFormContext = () => {
    const context = useContext(RegistrationOrderingFormContext);
    if (!context) {
        throw new Error('useRegistrationOrderingFormContext must be used within a Intaserve Outlet Provider');
    }
    return context;
};
