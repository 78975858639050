import { createContext, useContext } from 'react';
import invariant from 'tiny-invariant';
import type { API } from 'utilities/api/services';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
// prettier-ignore
type HostingContext = 
    | Awaited<API.hosting.listNew.ReturnType>['data'][number]
    | Awaited<API.hosting.searchNew.ReturnType>['data'][number];

export const HostingContext = createContext<HostingContext | null>(null);

/**
 * Accesses the data used to render the hosting table. This can come from one of the following endpoints based on 
 * whether the user is searching or not
 * 
 * - `NXQuery.hosting.list.useQuery`
 * - `NXQuery.hosting.search.useQuery`
 */
export const useHostingContext = () => {
    const context = useContext(HostingContext);

    invariant(context, 'useHostingContext must be used within a HostingContextProvider');

    return context;
};
