import { useStore } from '@tanstack/react-store';
import { superUserSuperActionsState } from 'components/StaffMenu/SuperUser/consts';
import type { SuperUserNamespace } from 'components/StaffMenu/SuperUser/types';
import type { Entries } from 'type-fest';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export function useEnabledSuperUserItems<TReturnValue = SuperUserNamespace.Key[]>(
    selector?: (state: SuperUserNamespace.Key[]) => TReturnValue
): TReturnValue {
    /***** HOOK RESULTS *****/
    return useStore(superUserSuperActionsState, (record) => {
        const filteredValues = (Object.entries(record) as Entries<typeof record>).filter(([, isEnabled]) => isEnabled).map(([key]) => key);

        return selector ? selector(filteredValues) : filteredValues;
    }) as TReturnValue;
}
