export const SERVICE_MOVE_INVITATION_REQUEST = 'services/SERVICE_MOVE_INVITATION_REQUEST';
export const SERVICE_MOVE_INVITATION_SUCCESS = 'services/SERVICE_MOVE_INVITATION_SUCCESS';
export const SERVICE_MOVE_INVITATION_ERROR = 'services/SERVICE_MOVE_INVITATION_ERROR';
export const SERVICE_MOVE_REQUEST = 'services/SERVICE_MOVE_REQUEST';
export const SERVICE_MOVE_SUCCESS = 'services/SERVICE_MOVE_SUCCESS';
export const SERVICE_MOVE_ERROR = 'services/SERVICE_MOVE_ERROR';
export const SERVICE_MOVE_APPROVE_REQUEST = 'service/SERVICE_MOVE_APPROVE_REQUEST';
export const SERVICE_MOVE_APPROVE_SUCCESS = 'service/SERVICE_MOVE_APPROVE_SUCCESS';
export const SERVICE_MOVE_APPROVE_ERROR = 'service/SERVICE_MOVE_APPROVE_ERROR';
export const SERVICE_MOVE_APPROVE_REQUEST_RESET = 'service/SERVICE_MOVE_APPROVE_REQUEST_RESET';
export const SERVICE_MOVE_REGISTER_REQUEST = 'service/SERVICE_MOVE_REGISTER_REQUEST';
export const SERVICE_MOVE_REGISTER_SUCCESS = 'service/SERVICE_MOVE_REGISTER_SUCCESS';
export const SERVICE_MOVE_REGISTER_ERROR = 'service/SERVICE_MOVE_REGISTER_ERROR';
export const SERVICE_MOVE_CANCEL_REQUEST = 'service/SERVICE_MOVE_CANCEL_REQUEST';
export const SERVICE_MOVE_CANCEL_SUCCESS = 'service/SERVICE_MOVE_CANCEL_SUCCESS';
export const SERVICE_MOVE_CANCEL_ERROR = 'service/SERVICE_MOVE_CANCEL_ERROR';

export const DOMAIN_LIST_REQUEST = 'services/DOMAIN_LIST_REQUEST';
export const DOMAIN_LIST_SUCCESS = 'services/DOMAIN_LIST_SUCCESS';
export const DOMAIN_LIST_ERROR = 'services/DOMAIN_LIST_ERROR';
export const DOMAIN_LIST_MORE_REQUEST = 'services/DOMAIN_LIST_MORE_REQUEST';
export const DOMAIN_LIST_MORE_SUCCESS = 'services/DOMAIN_LIST_MORE_SUCCESS';
export const DOMAIN_LIST_MORE_ERROR = 'services/DOMAIN_LIST_MORE_ERROR';
export const DOMAIN_LIST_RESET = 'services/DOMAIN_LIST_RESET';

export const DOMAIN_EXPORT_REQUEST = 'services/DOMAIN_EXPORT_REQUEST';
export const DOMAIN_EXPORT_SUCCESS = 'services/DOMAIN_EXPORT_SUCCESS';
export const DOMAIN_EXPORT_ERROR = 'services/DOMAIN_EXPORT_ERROR';

export const DOMAIN_AUTO_RENEW_REQUEST = 'services/DOMAIN_AUTO_RENEW_REQUEST';
export const DOMAIN_AUTO_RENEW_SUCCESS = 'services/DOMAIN_AUTO_RENEW_SUCCESS';
export const DOMAIN_AUTO_RENEW_FAIL = 'services/DOMAIN_AUTO_RENEW_FAIL';

export const DOMAIN_FORM_REQUEST = 'services/DOMAIN_FORM_REQUEST';
export const DOMAIN_FORM_SUCCESS = 'services/DOMAIN_FORM_SUCCESS';
export const DOMAIN_FORM_FAIL = 'services/DOMAIN_FORM_FAIL';

export const DOMAIN_SEARCH_RESET = 'services/DOMAIN_SEARCH_RESET';
export const DOMAIN_SEARCH_REQUEST = 'services/DOMAIN_SEARCH_REQUEST';
export const DOMAIN_SEARCH_SUCCESS = 'services/DOMAIN_SEARCH_SUCCESS';
export const DOMAIN_SEARCH_ERROR = 'services/DOMAIN_SEARCH_ERROR';

export const HOSTING_LIST_REQUEST = 'services/HOSTING_LIST_REQUEST';
export const HOSTING_LIST_SUCCESS = 'services/HOSTING_LIST_SUCCESS';
export const HOSTING_LIST_ERROR = 'services/HOSTING_LIST_ERROR';
export const HOSTING_LIST_MORE_REQUEST = 'services/HOSTING_LIST_MORE_REQUEST';
export const HOSTING_LIST_MORE_SUCCESS = 'services/HOSTING_LIST_MORE_SUCCESS';
export const HOSTING_LIST_MORE_ERROR = 'services/HOSTING_LIST_MORE_ERROR';
export const HOSTING_LIST_RESET = 'services/HOSTING_LIST_RESET';

export const HOSTING_SEARCH_RESET = 'services/HOSTING_SEARCH_RESET';
export const HOSTING_SEARCH_REQUEST = 'services/HOSTING_SEARCH_REQUEST';
export const HOSTING_SEARCH_SUCCESS = 'services/HOSTING_SEARCH_SUCCESS';
export const HOSTING_SEARCH_ERROR = 'services/HOSTING_SEARCH_ERROR';

export const SERVICE_RESTORE_INVOICE_SUCCESS = 'services/SERVICE_RESTORE_INVOICE_SUCCESS';
export const SERVICE_RESTORE_INVOICE_ERROR = 'services/SERVICE_RESTORE_INVOICE_ERROR';
export const SERVICE_RESTORE_INVOICE_REQUEST = 'services/SERVICE_RESTORE_INVOICE_REQUEST';

export const SERVICE_UNSUSPENSION_REASON_REQUEST = 'services/SERVICE_UNSUSPENSION_REASON_REQUEST';
export const SERVICE_UNSUSPENSION_REASON_SUCCESS = 'services/SERVICE_UNSUSPENSION_REASON_SUCCESS';
export const SERVICE_UNSUSPENSION_REASON_ERROR = 'services/SERVICE_UNSUSPENSION_REASON_ERROR';

export const SERVICE_UNSUSPENSION_REQUEST = 'services/SERVICE_UNSUSPENSION_REQUEST';
export const SERVICE_UNSUSPENSION_SUCCESS = 'services/SERVICE_UNSUSPENSION_SUCCESS';
export const SERVICE_UNSUSPENSION_ERROR = 'services/SERVICE_UNSUSPENSION_ERROR';

export const EMAIL_LIST_REQUEST = 'services/EMAIL_LIST_REQUEST';
export const EMAIL_LIST_SUCCESS = 'services/EMAIL_LIST_SUCCESS';
export const EMAIL_LIST_ERROR = 'services/EMAIL_LIST_ERROR';
export const EMAIL_LIST_MORE_REQUEST = 'services/EMAIL_LIST_MORE_REQUEST';
export const EMAIL_LIST_MORE_SUCCESS = 'services/EMAIL_LIST_MORE_SUCCESS';
export const EMAIL_LIST_MORE_ERROR = 'services/EMAIL_LIST_MORE_ERROR';
export const EMAIL_LIST_RESET = 'services/EMAIL_LIST_RESET';

export const EMAIL_SEARCH_RESET = 'services/EMAIL_SEARCH_RESET';
export const EMAIL_SEARCH_REQUEST = 'services/EMAIL_SEARCH_REQUEST';
export const EMAIL_SEARCH_SUCCESS = 'services/EMAIL_SEARCH_SUCCESS';
export const EMAIL_SEARCH_ERROR = 'services/EMAIL_SEARCH_ERROR';

export const GET_SSL_LIST = 'services/GET_SSL_LIST';
export const GET_SSL_LIST_MORE_REQUEST = 'services/GET_SSL_LIST_MORE_REQUEST';
export const GET_SSL_LIST_MORE_SUCCESS = 'services/GET_SSL_LIST_MORE_SUCCESS';
export const GET_SSL_LIST_MORE_ERROR = 'services/GET_SSL_LIST_MORE_ERROR';

export const GET_SSL_LIST_SUCCESS = 'services/GET_SSL_LIST_SUCCESS';
export const GET_SSL_LIST_ERROR = 'services/GET_SSL_LIST_ERROR';
export const SSL_LIST_RESET = 'services/SSL_LIST_RESET';

export const GET_SSL_PRODUCTS = 'services/GET_SSL_PRODUCTS';
export const GET_SSL_PRODUCTS_SUCCESS = 'services/GET_SSL_SUCCESS';
export const GET_SSL_PRODUCTS_ERROR = 'services/GET_SSL_ERROR';

export const PURCHASE_SSL_PRODUCT = 'services/PURCHASE_SSL_PRODUCT';
export const PURCHASE_SSL_PRODUCT_SUCCESS = 'services/PURCHASE_SSL_PRODUCT_SUCCESS';
export const PURCHASE_SSL_PRODUCT_ERROR = 'services/PURCHASE_SSL_PRODUCT_ERROR';
export const PURCHASE_SSL_PRODUCT_RESET = 'services/PURCHASE_SSL_PRODUCT_RESET';

export const SSL_SEARCH_RESET = 'services/SSL_SEARCH_RESET';
export const SSL_SEARCH_REQUEST = 'services/SSL_SEARCH_REQUEST';
export const SSL_SEARCH_SUCCESS = 'services/SSL_SEARCH_SUCCESS';
export const SSL_SEARCH_ERROR = 'services/SSL_SEARCH_ERROR';

export const VPS_LIST_REQUEST = 'services/VPS_LIST_REQUEST';
export const VPS_LIST_SUCCESS = 'services/VPS_LIST_SUCCESS';
export const VPS_LIST_ERROR = 'services/VPS_LIST_ERROR';

export const VPS_LIST_MORE_REQUEST = 'services/VPS_LIST_MORE_REQUEST';
export const VPS_LIST_MORE_SUCCESS = 'services/VPS_LIST_MORE_SUCCESS';
export const VPS_LIST_MORE_ERROR = 'services/VPS_LIST_MORE_ERROR';

export const VPS_SEARCH_RESET = 'services/VPS_SEARCH_RESET';
export const VPS_SEARCH_REQUEST = 'services/VPS_SEARCH_REQUEST';
export const VPS_SEARCH_SUCCESS = 'services/VPS_SEARCH_SUCCESS';
export const VPS_SEARCH_ERROR = 'services/VPS_SEARCH_ERROR';

export const GSUITE_LIST_REQUEST = 'services/GSUITE_LIST_REQUEST';
export const GSUITE_LIST_SUCCESS = 'services/GSUITE_LIST_SUCCESS';
export const GSUITE_LIST_ERROR = 'services/GSUITE_LIST_ERROR';
export const GSUITE_LIST_MORE_REQUEST = 'services/GSUITE_LIST_MORE_REQUEST';
export const GSUITE_LIST_MORE_SUCCESS = 'services/GSUITE_LIST_MORE_SUCCESS';
export const GSUITE_LIST_MORE_ERROR = 'services/GSUITE_LIST_MORE_ERROR';

export const GSUITE_SEARCH_RESET = 'services/GSUITE_SEARCH_RESET';
export const GSUITE_SEARCH_REQUEST = 'services/GSUITE_SEARCH_REQUEST';
export const GSUITE_SEARCH_SUCCESS = 'services/GSUITE_SEARCH_SUCCESS';
export const GSUITE_SEARCH_ERROR = 'services/GSUITE_SEARCH_ERROR';

export const MS365_LIST_REQUEST = 'services/MS365_LIST_REQUEST';
export const MS365_LIST_SUCCESS = 'services/MS365_LIST_SUCCESS';
export const MS365_LIST_ERROR = 'services/MS365_LIST_ERROR';
export const MS365_LIST_MORE_REQUEST = 'services/MS365_LIST_MORE_REQUEST';
export const MS365_LIST_MORE_SUCCESS = 'services/MS365_LIST_MORE_SUCCESS';
export const MS365_LIST_MORE_ERROR = 'services/MS365_LIST_MORE_ERROR';

export const MS365_SEARCH_RESET = 'services/MS365_SEARCH_RESET';
export const MS365_SEARCH_REQUEST = 'services/MS365_SEARCH_REQUEST';
export const MS365_SEARCH_SUCCESS = 'services/MS365_SEARCH_SUCCESS';
export const MS365_SEARCH_ERROR = 'services/MS365_SEARCH_ERROR';

export const SERVICES_INITIAL_STATE = 'services/SERVICES_INITIAL_STATE';

export const DOMAIN_BULK_MANAGE_UPDATE = 'services/DOMAIN_BULK_MANAGE_UPDATE';

export const SERVICE_CHANGE_PLAN_PATHS_REQUEST = 'services/SERVICE_CHANGE_PLAN_PATHS_REQUEST';
export const SERVICE_CHANGE_PLAN_PATHS_SUCCESS = 'services/SERVICE_CHANGE_PLAN_PATHS_SUCCESS';
export const SERVICE_CHANGE_PLAN_PATHS_ERROR = 'services/SERVICE_CHANGE_PLAN_PATHS_ERROR';
