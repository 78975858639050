import {
    AUDIRECT_APPLY_ERROR,
    AUDIRECT_APPLY_REQUEST,
    AUDIRECT_APPLY_SUCCESS,
    AUDIRECT_CONTENTION_DOMAINS_ERROR,
    AUDIRECT_CONTENTION_DOMAINS_REQUEST,
    AUDIRECT_CONTENTION_DOMAINS_SUCCESS,
    AUDIRECT_CONTESTED_DOMAINS_ERROR,
    AUDIRECT_CONTESTED_DOMAINS_REQUEST,
    AUDIRECT_CONTESTED_DOMAINS_SUCCESS,
    AUDIRECT_REGISTER_ERROR,
    AUDIRECT_REGISTER_REQUEST,
    AUDIRECT_REGISTER_RESET,
    AUDIRECT_REGISTER_SUCCESS,
    AUDIRECT_STATUS_ERROR,
    AUDIRECT_STATUS_REQUEST,
    AUDIRECT_STATUS_RESET,
    AUDIRECT_STATUS_SUCCESS,
    AUDIRECT_UPDATE_APPLICATION_ERROR,
    AUDIRECT_UPDATE_APPLICATION_REQUEST,
    AUDIRECT_UPDATE_APPLICATION_SUCCESS
} from './actionTypes';

const initialState = {
    audirect_register_status: null,
    audirect_register_data: null,
    audirect_register_error: null,

    audirect_status_status: null,
    audirect_status_data: null,

    audirect_contested_domains_status: null,
    audirect_contested_domains_data: null,

    audirect_contention_domains_status: null,
    audirect_contention_domains_data: null,

    audirect_apply_status: null,
    audirect_apply_data: null,

    audirect_update_application_status: null,
    audirect_update_application_data: null
};

const auDirectReducer = (state = initialState, action) => {
    switch (action.type) {
        /**********************************************************************************************************
         *   REGISTER SINGLE CONTESTED
         **********************************************************************************************************/
        case AUDIRECT_REGISTER_REQUEST:
            return {
                ...state,
                audirect_register_status: 'loading',
                audirect_register_data: null,
                audirect_register_error: null
            };

        case AUDIRECT_REGISTER_SUCCESS:
            return {
                ...state,
                audirect_register_status: 'success',
                audirect_register_data: action.audirect_register_data
            };

        case AUDIRECT_REGISTER_ERROR:
            return {
                ...state,
                audirect_register_status: 'error',
                audirect_register_error: action.audirect_register_error
            };

        case AUDIRECT_REGISTER_RESET:
            return {
                ...state,
                audirect_register_status: null,
                audirect_register_data: null,
                audirect_register_error: null
            };

        /**********************************************************************************************************
         *   DOMAIN AU DIRECT STATUS
         **********************************************************************************************************/
        case AUDIRECT_STATUS_REQUEST:
            return {
                ...state,
                audirect_status_status: 'loading',
                audirect_status_data: null
            };

        case AUDIRECT_STATUS_SUCCESS:
            return {
                ...state,
                audirect_status_status: 'success',
                audirect_status_data: action.audirect_status_data
            };

        case AUDIRECT_STATUS_ERROR:
            return {
                ...state,
                audirect_status_status: 'error'
            };

        case AUDIRECT_STATUS_RESET:
            return {
                ...state,
                audirect_status_status: null,
                audirect_status_data: null
            };

        /**********************************************************************************************************
         *   GET CONTESTED DOMAINS LISTS
         **********************************************************************************************************/
        case AUDIRECT_CONTESTED_DOMAINS_REQUEST:
            return {
                ...state,
                audirect_contested_domains_status: 'loading',
                audirect_contested_domains_data: null
            };

        case AUDIRECT_CONTESTED_DOMAINS_SUCCESS:
            return {
                ...state,
                audirect_contested_domains_status: 'success',
                audirect_contested_domains_data: action.audirect_contested_domains_data
            };

        case AUDIRECT_CONTESTED_DOMAINS_ERROR:
            return {
                ...state,
                audirect_contested_domains_status: 'error'
            };

        /**********************************************************************************************************
         *   GET MULTI CONTESTED CONTENTION DOMAINS
         **********************************************************************************************************/
        case AUDIRECT_CONTENTION_DOMAINS_REQUEST:
            return {
                ...state,
                audirect_contention_domains_status: 'loading',
                audirect_contention_domains_datas: null
            };

        case AUDIRECT_CONTENTION_DOMAINS_SUCCESS:
            return {
                ...state,
                audirect_contention_domains_status: 'success',
                audirect_contention_domains_data: action.audirect_contention_domains_data
            };

        case AUDIRECT_CONTENTION_DOMAINS_ERROR:
            return {
                ...state,
                audirect_contention_domains_status: 'error'
            };

        /**********************************************************************************************************
         *   SUBMIT MULTI CONTESTED APPLICATION
         **********************************************************************************************************/
        case AUDIRECT_APPLY_REQUEST:
            return {
                ...state,
                audirect_apply_status: 'loading',
                audirect_apply_data: null
            };

        case AUDIRECT_APPLY_SUCCESS:
            return {
                ...state,
                audirect_apply_status: 'success',
                audirect_apply_data: action.audirect_apply_data
            };

        case AUDIRECT_APPLY_ERROR:
            return {
                ...state,
                audirect_apply_status: 'error'
            };

        /**********************************************************************************************************
         *   UPDATE MULTI CONTESTED APPLICATION DETAILS
         **********************************************************************************************************/
        case AUDIRECT_UPDATE_APPLICATION_REQUEST:
            return {
                ...state,
                audirect_update_application_status: 'loading',
                audirect_update_application_data: null
            };

        case AUDIRECT_UPDATE_APPLICATION_SUCCESS:
            return {
                ...state,
                audirect_update_application_status: 'success',
                audirect_update_application_data: action.audirect_update_application_data
            };

        case AUDIRECT_UPDATE_APPLICATION_ERROR:
            return {
                ...state,
                audirect_update_application_status: 'error'
            };

        default:
            return state;
    }
};

export default auDirectReducer;
