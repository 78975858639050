export const DOMAIN_BULK_LIST_REQUEST = 'services/DOMAIN_BULK_LIST_REQUEST';
export const DOMAIN_BULK_LIST_SUCCESS = 'services/DOMAIN_BULK_LIST_SUCCESS';
export const DOMAIN_BULK_LIST_FAIL = 'services/DOMAIN_BULK_LIST_FAIL';

export const DOMAIN_BULK_INFO_RESET = 'services/DOMAIN_BULK_INFO_RESET';
export const DOMAIN_BULK_INFO_REQUEST = 'services/DOMAIN_BULK_INFO_REQUEST';
export const DOMAIN_BULK_INFO_SUCCESS = 'services/DOMAIN_BULK_INFO_SUCCESS';
export const DOMAIN_BULK_INFO_FAIL = 'services/DOMAIN_BULK_INFO_FAIL';

export const DOMAIN_BULK_ACTION_REQUEST = 'services/DOMAIN_BULK_ACTION_REQUEST';
export const DOMAIN_BULK_ACTION_SUCCESS = 'services/DOMAIN_BULK_ACTION_SUCCESS';
export const DOMAIN_BULK_ACTION_FAIL = 'services/DOMAIN_BULK_ACTION_FAIL';

export const DOMAIN_BULK_CONTACT_ACTION_REQUEST = 'services/DOMAIN_BULK_CONTACT_ACTION_REQUEST';
export const DOMAIN_BULK_CONTACT_ACTION_SUCCESS = 'services/DOMAIN_BULK_CONTACT_ACTION_SUCCESS';
export const DOMAIN_BULK_CONTACT_ACTION_FAIL = 'services/DOMAIN_BULK_CONTACT_ACTION_FAIL';

export const DOMAIN_BULK_INITIAL_STATE = 'services/DOMAIN_BULK_INITIAL_STATE';
