import { useEffect, useState } from 'react';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Automatically updates the value of the state when the original input value changes.
 * useful in places where the original input value takes precedence over the state value.
 */
export function useStateEffect<T>(value: T, dependencies: React.DependencyList = []) {
    /***** STATE *****/
    const [_value, _setValue] = useState(value);

    /***** EFFECTS *****/
    useEffect(() => {
        _setValue(value);
    }, [value, ...dependencies]);

    /***** HOOK RESULTS *****/
    return [_value, _setValue] as const;
}
