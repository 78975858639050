import { Padding } from 'components/Utils/Padding';
import Text from 'components/Utils/Text';
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type HostingTableRowTitleType = React.FC<{
    children: React.ReactNode;
}>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Renders when on mobile inside of an accordion.
 */
export const HostingTableRowTitle: HostingTableRowTitleType = ({ children }) => {
    /***** HOOKS *****/
    const isMobile = useAppViewport(['xs', 'sm']);

    /***** RENDER *****/
    return (
        <>
            {isMobile && (
                <Padding y={1}>
                    <Text bold secondary size--xss align--left lead--1>
                        {children}
                    </Text>
                </Padding>
            )}
        </>
    );
};
