export const promoBannerDismissKeys = {
    JACKPOT_DISMISS_KEY: 'JACKPOT_DISMISS_KEY',
    DOT_STORE_RADIX_DISMISS_KEY: 'DOT_STORE_RADIX_DISMISS_KEY',
    ADDITIONAL_USER_DISMISS_KEY: 'ADDITIONAL_USER_DISMISS_KEY',
    DOT_ONLINE_RADIX_DISMISS_KEY: 'DOT_ONLINE_RADIX_DISMISS_KEY',
    vipcontrolAppBannerDismiss: 'vipcontrolAppBannerDismiss',
    PROMOTIONAL_VIPSITES_DISMISS_KEY: 'PROMOTIONAL_VIPSITES_DISMISS_KEY',
    REFERRAL_DISMISS_KEY: 'REFERRAL_DISMISS_KEY',
    CYBER_MONDAY_DISMISS_KEY: 'CYBER_MONDAY_DISMISS_KEY',
    BLACK_FRIDAY_DISMISS_KEY: 'BLACK_FRIDAY_DISMISS_KEY',
    CYBER_MONDAY_VIPREWARDS_DISMISS_KEY: 'CYBER_MONDAY_VIPREWARDS_DISMISS_KEY',
    SURVEY_DISMISS_KEY: 'SURVEY_DISMISS_KEY',
    SUMMER_OF_DISCOUNTS_DISMISS_KEY: 'SUMMER_OF_DISCOUNTS_DISMISS_KEY'
} as const;
export const promoBannerDismissKeyValues = Object.values(promoBannerDismissKeys);
export type PromoBannerDismissKeys = (typeof promoBannerDismissKeyValues)[number];

/**
 * @deprecated keys are not used but should be kept for reference so that storage can be cleaned up;
 */
export const inactivePromoBannerDismissKeys = {
    MEGAMAY24_DISMISS_KEY: 'MEGAMAY24_DISMISS_KEY',
    PROMOTIONAL_VIPSITES_DISSMISS_KEY: 'PROMOTIONAL_VIPSITES_DISSMISS_KEY',
    MEGAMAY24_DOMAINS_NEW_COM_AU_DOMAIN_DISMISS_KEY: 'MEGAMAY24_DOMAINS_NEW_COM_AU_DOMAIN_DISMISS_KEY',
    MEGAMAY24_DOMAINS_NEW_AU_DIRECT_DOMAIN_DISMISS_KEY: 'MEGAMAY24_DOMAINS_NEW_AU_DIRECT_DOMAIN_DISMISS_KEY',
    MEGAMAY24_DOMAINS_NEW_COM_DOMAIN_DISMISS_KEY: 'MEGAMAY24_DOMAINS_NEW_COM_DOMAIN_DISMISS_KEY',
    MEGAMAY24_HOSTING_RENEWALS_DISMISS_KEY: 'MEGAMAY24_HOSTING_RENEWALS_DISMISS_KEY',
    MEGAMAY24_HOSTING_UPGRADE_DISMISS_KEY: 'MEGAMAY24_HOSTING_UPGRADE_DISMISS_KEY',
    MEGAMAY24_NEW_HOSTING_DISMISS_KEY: 'MEGAMAY24_NEW_HOSTING_DISMISS_KEY',
    MEGAMAY24_NEW_VPS_DISMISS_KEY: 'MEGAMAY24_NEW_VPS_DISMISS_KEY',
    MEGAMAY24_VPS_RENEWALS_DISMISS_KEY: 'MEGAMAY24_VPS_RENEWALS_DISMISS_KEY',
    MEGAMAY24_EMAIL_HOSTING_DISMISS_KEY: 'MEGAMAY24_EMAIL_HOSTING_DISMISS_KEY',
    MEGAMAY24_SSL_DISMISS_KEY: 'MEGAMAY24_SSL_DISMISS_KEY'
} as const;
