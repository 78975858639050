import { billingCycle } from './emailId/useGetBillingCycleQuery';
import { mailboxUsage } from './emailId/useGetMailboxUsageQuery';
import { upgradeEmailService } from './emailId/useUpgradeEmailServiceMutation';
import { emailProductDetails } from './useGetProductDetailsQuery';

export const _emailQuery = Object.freeze({
    emailProductDetails,
    emailId: {
        billingCycle,
        mailboxUsage,
        upgradeEmailService
    }
});
