import { Store, useStore } from '@tanstack/react-store';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Ventra = typeof ventra;
type Intaserve = typeof intaserve;
type BrandStore = Ventra | Intaserve;
export type BrandName = Ventra['activeBrand'] | Intaserve['activeBrand'];

export type Brand = {
    /**
     * Used to determine which brand CCP is currently running
     */
    activeBrand: 'ventra' | 'intaserve';

    /**
     * Name of the application for the currently active brand
     */
    application: 'VIPcontrol' | 'Intaserve' | (string & {});

    /**
     * Company name for the currently active brand
     */
    company: string;

    /**
     * Full version of company name. Displayed at bottom of login page
     */
    companyFull: string;

    /**
     * Company ABN
     */
    abn: string;

    /**
     * Contact URL for the business
     */
    contactUrl: string;

    /**
     * Brand T's and C's URL
     */
    termsUrl: string;

    /**
     * Recovery email
     */
    recoveryEmail: string;

    /**
     * BPAY Biller Code
     */
    bpayBillerCode: string;

    /**
     * All EFT details for the brand business
     */
    eftDetails: {
        name: string;
        bsb: string;
        account: string;
    };

    /**
     * All PayID details for the brand business
     */
    payIdDetails: {
        payId: string;
        name: string;
    };

    /**
     * Phone number for customer support
     */
    supportNumber: string;

    /**
     * Email address for customer support
     */
    supportEmail: string;

    /**
     * URL to look up whois results for a domain
     */
    whoisLookupUrl: string | null;

    /**
     * Website URL
     */
    websiteUrl: string;

    /**
     * Name of support tickets
     */
    ticketName: string | null;
};

/**********************************************************************************************************
*   CONSTS
**********************************************************************************************************/
const ventra: Brand = {
    activeBrand: 'ventra',
    application: 'VIPcontrol',
    company: 'VentraIP',
    companyFull: 'VentraIP Australia Pty Ltd',
    abn: '91 137 543 018',
    contactUrl: 'https://ventraip.com.au/contact-us/',
    termsUrl: 'https://ventraip.com.au/terms-policies-agreements/',
    recoveryEmail: 'recovery@ventraip.com.au',
    bpayBillerCode: '556407',
    eftDetails: {
        name: 'VentraIP',
        bsb: '083 376',
        account: '294 737 269'
    },
    payIdDetails: {
        payId: 'payments@ventraip.com.au',
        name: 'VentraIP Australia'
    },
    supportNumber: '13 24 85',
    supportEmail: '',
    whoisLookupUrl: 'https://ventraip.com.au/whois/?whois=',
    websiteUrl: 'https://ventraip.com.au/',
    ticketName: 'eTicket'
};

const intaserve: Brand = {
    activeBrand: 'intaserve',
    application: 'Intaserve',
    company: 'Intaserve',
    companyFull: 'Nexigen Digital Pty Ltd t/a Intaserve',
    abn: '91 137 543 018',
    contactUrl: 'https://intaserve.au/#contact-form',
    termsUrl: 'https://intaserve.au/',
    recoveryEmail: '',
    bpayBillerCode: '457143',
    eftDetails: {
        name: '',
        bsb: '',
        account: ''
    },
    payIdDetails: {
        payId: '',
        name: ''
    },
    supportNumber: '',
    supportEmail: 'support@intaserve.au',
    whoisLookupUrl: null,
    websiteUrl: 'https://intaserve.au/',
    ticketName: null
};

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
function getBrandState(brandName?: BrandName): BrandStore {
    const brand = brandName || import.meta.env.VITE_COMPANY;

    switch (brand) {
        case 'intaserve':
            return intaserve;
        case 'ventra':
        default:
            return ventra;
    }
}

export const brandStore = new Store<BrandStore>(getBrandState());

export function useBrandStore<TSelected = Brand>(selector?: (state: Brand) => TSelected) {
    return useStore(brandStore, selector);
}

export function withBrandStore(Component: any) {
    return function ComponentWithBrandStoreProps(props: any) {
        const brandStoreData = useBrandStore();

        return <Component {...brandStoreData} {...props} />;
    };
}

export function setBrand(brandName: BrandName) {
    brandStore.setState(() => getBrandState(brandName));
}
