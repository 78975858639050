import type { QueryKey } from '@tanstack/react-query';
import { queryOptions, useQuery } from '@tanstack/react-query';
import { katanaQueryKeys } from 'containers/katana/queries/katanaQueryKeys';
import { _newSection } from 'containers/katana/queries/serviceID/sections/predicates/_newSection';
import type { KatanaNamespace } from 'containers/katana/types';
import _ from 'lodash';
import { queryClient } from 'store/queryClient';
import { KATANA_API } from 'utilities/api/katana';
import { SECOND } from 'utilities/consts';
import { handleDefaultErrorNotification } from 'utilities/methods/commonActions/handleDefaultErrorNotification';
import { createGetQueryDataMethod } from 'utilities/methods/tanstack/createGetQueryDataMethod';
import { createGetQueryStateMethod } from 'utilities/methods/tanstack/createGetQueryStateMethod';
import { createSetQueryDataMethod } from 'utilities/methods/tanstack/createSetQueryDataMethod';
import { createUseQuerySelectorMethod } from 'utilities/methods/tanstack/createUseQuerySelectorMethod';
import { createOptimisticMethods } from 'utilities/methods/tanstack/optimistic/createOptimisticMethods';
import { createOptimisticResponseObject } from 'utilities/methods/tanstack/optimistic/createOptimisticResponseObject';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type TData = Awaited<ReturnType<typeof KATANA_API.katana.site.service_id.sections.GET>>;

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
function createQueryKey(serviceID: KatanaNamespace.ServiceId) {
    return katanaQueryKeys.katana.service.ID.sections(serviceID) as QueryKey;
}

function createQueryOptions(serviceID: KatanaNamespace.ServiceId) {
    return queryOptions({
        queryKey: createQueryKey(serviceID),
        queryFn: async () => {
            const result = await KATANA_API.katana.site.service_id.sections.GET(serviceID).catch((e) => {
                handleDefaultErrorNotification(e);
                throw e;
            });

            // Seed the individual section data - https://tkdodo.eu/blog/seeding-the-query-cache
            _.each(result.data, (section) => {
                // IDEA: opportunity for automated chaining and updating of dependant data. kind of like events?, i.e. if this data updates, it updates the child, if the child data updates and the parent exists, it updates the parent
                queryClient.setQueryData(
                    katanaQueryKeys.katana.service.ID.sections.ID({ serviceID, sectionID: section.id }),
                    createOptimisticResponseObject<TData['data'][number]>(section)
                );
            });

            return result;
        },
        staleTime: SECOND * 25,
        enabled: Boolean(serviceID),
        refetchOnMount: false,
        select: (data) => {
            if (data?.status === 200) {
                return data.data;
            }
        },
    });
}

const getQueryData = createGetQueryDataMethod<KatanaNamespace.ServiceId, TData>(createQueryKey);
const setQueryData = createSetQueryDataMethod<KatanaNamespace.ServiceId, TData>(createQueryKey);
const getQueryState = createGetQueryStateMethod<KatanaNamespace.ServiceId, TData>(createQueryKey);

function prefetchQuery(serviceID: KatanaNamespace.ServiceId) {
    return queryClient.prefetchQuery(createQueryOptions(serviceID));
}

function cancelQueries(serviceID: KatanaNamespace.ServiceId) {
    return queryClient.cancelQueries({ queryKey: createQueryKey(serviceID) });
}

function useIsFetching(serviceID: KatanaNamespace.ServiceId) {
    return queryClient.isFetching({ queryKey: createQueryKey(serviceID) });
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Gets the sections on the site
 */
function _useQuery(serviceID: KatanaNamespace.ServiceId) {
    return useQuery(createQueryOptions(serviceID));
}
const useQuerySelector = createUseQuerySelectorMethod<KatanaNamespace.ServiceId, TData, typeof createQueryOptions>(createQueryOptions);

function useGetIDByDefinitionID(serviceID: KatanaNamespace.ServiceId, sectionDefinitionID: KatanaNamespace.SectionDefinitionId) {
    const query = _useQuery(serviceID);
    const { data: get_katana_site_sections_data } = query;

    if (!_.isArray(get_katana_site_sections_data)) {
        return { sectionID: null, query };
    }

    const section = get_katana_site_sections_data.find((section) => section.section_id === sectionDefinitionID);

    return { sectionID: section?.id ?? null, query };
}

/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const sections = Object.freeze({
    useQuery: _useQuery,
    useGetIDByDefinitionID,
    useIsFetching,
    optimistic: Object.assign(createOptimisticMethods(setQueryData), {
        predicates: {
            newSection: _newSection,
        },
    }),
    cancelQueries,
    prefetchQuery,
    getQueryData,
    getQueryState,
    setQueryData,
    createQueryKey,
    useQuerySelector,
    createQueryOptions,
});
