import { useMutation } from '@tanstack/react-query';
import { newPageTrackerData } from 'containers/katana/components/pageOrganiser/newPageTracker';
import { katanaQueryKeys } from 'containers/katana/queries/katanaQueryKeys';
import { queryClient } from 'store/queryClient';
import { KATANA_API } from 'utilities/api/katana';
import { handleDefaultSuccessNotification } from 'utilities/methods/commonActions/handleDefaultSuccessNotification';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Params = Parameters<typeof KATANA_API.katana.site.service_id.pages.page_id.duplicate.POST>[0];

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useMutation(serviceID: Params['serviceID']) {
    async function onMutate() {
        await Promise.all([
            queryClient.cancelQueries({
                queryKey: katanaQueryKeys.katana.service.ID.pages(serviceID)
            }),
            queryClient.cancelQueries({
                queryKey: katanaQueryKeys.katana.service.ID.sections(serviceID)
            })
        ]);
    }

    function handleMutation(pageID: Params['pageID']) {
        return KATANA_API.katana.site.service_id.pages.page_id.duplicate.POST({ serviceID, pageID });
    }

    return useMutation({
        onMutate,
        mutationFn: handleMutation,
        onSuccess: (e) => {
            if (e.status === 200) {
                newPageTrackerData.addNewID(e.data.id);
            }
            handleDefaultSuccessNotification(e);
        },

        onSettled: (data, error, params) => {
            queryClient.invalidateQueries({
                queryKey: katanaQueryKeys.katana.service.ID.preview(serviceID)
            });

            queryClient.invalidateQueries({
                queryKey: katanaQueryKeys.katana.service.ID.pages(serviceID),
                exact: true
            });

            queryClient.invalidateQueries({
                queryKey: katanaQueryKeys.katana.service.ID.sections(serviceID),
                exact: true
            });
        }
    });
}

export const duplicatePage = Object.freeze({
    useMutation: _useMutation
});
