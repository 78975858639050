import Text from 'components/Utils/Text';
import 'containers/orderingForm/pages/registerPage/searchbar/_searchbar.scss';
import { useRef } from 'react';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Searchbar = React.FC<SearchbarProps>;
type SearchbarProps = {
    domainSearchValue: string;
    setDomainSearchValue: React.Dispatch<React.SetStateAction<string>>;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const Searchbar: Searchbar = ({ domainSearchValue, setDomainSearchValue }) => {
    /***** STATE *****/
    const domainRef = useRef<HTMLInputElement>(null);

    /***** FUNCTIONS *****/
    const handleDomainChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
        const sanitizedValue = inputValue.replace(/[^a-zA-Z0-9-.]/g, '');

        setDomainSearchValue(sanitizedValue);
    };

    /***** RENDER *****/
    return (
        <>
            <div className="domainRegistrationSearchbar">
                <Text size--xl className="domainRegistrationSearchbar__prefix">
                    www.
                </Text>
                <input ref={domainRef} type="text" value={domainSearchValue} onChange={handleDomainChange} placeholder=" start your search here" />
            </div>
        </>
    );
};
