/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { createRoute, stripSearchParams } from '@tanstack/react-router';
import { zodValidator } from '@tanstack/zod-adapter';
import { AppRoute } from 'App';
import FetchPageError from 'components/Errors/FetchPageError';
import RequestLoader from 'components/Loaders/Request';
import SideBarPage from 'components/SideBarPage';
import { RenderForBrands } from 'config/brandRenderer/component';
import { ventraSynMigrationBanners } from 'config/config';
import { Invoices } from 'containers/billing/modules/invoices';
import { checkRequiresMigration } from 'containers/ventraSynMigration/action';
import VentraSynBanner from 'containers/ventraSynMigration/modules/banner';
import { useRef } from 'react';
import { routerMiddleware } from 'router/utils/middleware';
import { NXQuery } from 'utilities/query';
import { z } from 'zod';
import './_dashboard.scss';
import { Account } from './Components/account';
import Tickets from './Components/etickets';
import { ExpressServiceManager } from './Components/expressServiceManager';
import OverdueInvoices from './Components/overdueInvoices';

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
/**
 * @note - The schema and default schema should match for this route and the MyServicesHostingRoute route as they share some
 * common search params for the hosting table (and other tables coming), so ensure the defaults for these are the same. Note
 * that this route uses literal numbers instead of z.number() for the record_per_page and page search params since these
 * values are fixed on this page.
 */
const defaultSearch = {
    // Matches default for my-services routes so that data already exists when navigating to said routes
    record_per_page: 10,
    page: 1,
    sort: 'asc',
    sort_by: 'name'
} as const;

const dashboardSchema = z.object({
    record_per_page: z.literal(10).optional().default(defaultSearch.record_per_page).catch(defaultSearch.record_per_page),
    page: z.literal(1).optional().default(defaultSearch.page).catch(defaultSearch.page),
    sort: z
        .union([z.literal('asc'), z.literal('desc')])
        .default(defaultSearch.sort)
        .catch(defaultSearch.sort),
    sort_by: z.literal('name').default(defaultSearch.sort_by).catch(defaultSearch.sort_by)
});

export const DashboardRoute = createRoute({
    getParentRoute: () => AppRoute,
    path: 'dashboard',
    loader({ cause, context }) {
        if (cause === 'enter' && ventraSynMigrationBanners) {
            checkRequiresMigration()(context.store.dispatch);
        }
    },
    beforeLoad(opts) {
        routerMiddleware.business(this, opts);
        routerMiddleware.authentication('user', opts);
    },
    component: Dashboard,
    validateSearch: zodValidator(dashboardSchema),
    search: {
        middlewares: [stripSearchParams(defaultSearch)]
    }
});

/**********************************************************************************************************
 *   ROUTE COMPONENT START
 **********************************************************************************************************/
function Dashboard() {
    /***** SELECTORS *****/
    const { status } = NXQuery.auth.userData.useQuery();

    /***** HOOKS *****/
    const promoDealsRef = useRef(null);

    /***** RENDER *****/
    switch (status) {
        case 'error':
            return <FetchPageError />;

        case 'success':
            return (
                <SideBarPage.Wrapper className="dashboard">
                    <RenderForBrands brands={['ventra']}>
                        <VentraSynBanner />
                    </RenderForBrands>
                    <Account promoDealsRef={promoDealsRef} />
                    <RenderForBrands brands={['ventra']}>
                        <OverdueInvoices />
                    </RenderForBrands>
                    <RenderForBrands brands={['ventra', 'intaserve']}>
                        <ExpressServiceManager />
                    </RenderForBrands>
                    <RenderForBrands brands={['ventra']}>
                        <Tickets />
                    </RenderForBrands>
                    <Invoices />
                </SideBarPage.Wrapper>
            );

        case 'pending':
        default:
            return <RequestLoader />;
    }
}
