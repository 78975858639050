import { useNavigate } from '@tanstack/react-router';
import { CustomDropdown } from 'components/Dropdowns/CustomDropdown';
import SelectOptions from 'components/Dropdowns/SelectDropdown/Options';
import OutlineTag from 'components/Tags/OutlineTag';
import Title from 'components/Title';
import { pushNotification } from 'components/Toast/functions';
import { renderForBrands } from 'config/brandRenderer/helper';
import { getTicketSubmitUrl } from 'containers/support/methods';
import { createAstroVNCSession, WHMVPSLogin } from 'containers/vps/action';
import { vpsMethods } from 'containers/vps/methods';
import { useDispatch, useSelector } from 'react-redux';
import type { Services } from 'utilities/api/_services';
import { useSelectableSelector } from 'utilities/hooks/redux/useSelectableSelector';
import { getIncludedObjBasedOnType } from 'utilities/methods/commonActions';

const selector = (state) => state.vps.vps_information_data as Services.VPS.TListData['data'][number];

export const VPSTitle = () => {
    /***** SELECTORS *****/
    const vps_information_status = useSelector((state: any) => state.vps.vps_information_status);
    const vps_power_state_data = useSelector((state: any) => state.vps.vps_power_state_data);
    const { domain, has_whm, vps_type, type, id, name, isAstro } = useSelectableSelector(selector, {
        select({ attributes, included, type, id }) {
            const product = getIncludedObjBasedOnType(included, 'product') as any;
            const isAstro = vpsMethods.getVpsServerType({ included }) === 'astro';

            return {
                ...attributes,
                type,
                id,
                name: product?.attributes.name,
                isAstro
            };
        }
    });

    /***** HOOKS *****/
    const dispatch = useDispatch();
    const navigate = useNavigate();

    /***** RENDER HELPERS *****/
    const getName = () => {
        if (vps_type === 'legacy') {
            return (
                <div className="vps-page__legacy-tag-wrapper">
                    {name}{' '}
                    <OutlineTag size="small" className="vps-page__legacy-tag">
                        Legacy
                    </OutlineTag>
                </div>
            );
        } else {
            return name;
        }
    };

    const getActions = () => {
        if (!renderForBrands(['ventra'])) {
            return null;
        }

        const buttons = {
            whm: {
                label: 'WHM Login',
                type: 'onClick',
                color: 'notice',
                onClick: (e) => {
                    e.preventDefault();
                    WHMVPSLogin(id)(dispatch);
                }
            },
            vncLogin: {
                label: 'VNC Login',
                type: 'onClick',
                color: 'notice',
                size: 'medium',
                onClick() {
                    if (vps_power_state_data?.state === 'shutdown') {
                        pushNotification('Your VPS must be running to enable VNC access.');
                    } else {
                        createAstroVNCSession(id)(dispatch);
                    }
                }
            },
            vpsHelp: (
                <CustomDropdown
                    className="TitleShared__singleDropdown"
                    label={({ labelRef, dropdownTitleOnClick }) => (
                        <button className="TitleShared__singleDropdownButton" ref={labelRef} type="button" onClick={() => dropdownTitleOnClick()}>
                            VPS Help
                            <i className="item__icon icon icon-chevron-down" />
                        </button>
                    )}
                    key="dropdown"
                    render={() => (
                        <SelectOptions
                            options={[
                                {
                                    label: 'VPS Help Articles',
                                    onSelect: () =>
                                        navigate({
                                            to: '/support/support-centre',
                                            search: {
                                                categories: 'products, virtual-private-servers-vps'
                                            }
                                        })
                                },
                                {
                                    label: 'Submit a Technical Support eTicket for this Service',
                                    onSelect: () => navigate({ to: getTicketSubmitUrl({ department: 'technical-support', service: id }) })
                                },
                                {
                                    label: 'Submit a Billing eTicket for this Service',
                                    onSelect: () => navigate({ to: getTicketSubmitUrl({ department: 'accounts-billing', service: id }) })
                                }
                            ]}
                        />
                    )}
                />
            )
        };

        // If its astro self managed, add the VNC login button
        if (isAstro && vps_type !== 'fully_managed') {
            if (has_whm) {
                return [buttons.whm, buttons.vncLogin, buttons.vpsHelp];
            }

            return [buttons.vncLogin, buttons.vpsHelp];
        }

        // If its not astro self managed, dont add VNC login button
        if (has_whm) {
            return [buttons.whm, buttons.vpsHelp];
        }

        return buttons.vpsHelp;
    };

    /***** RENDER *****/
    return (
        <>
            {vps_information_status === 'success' && (
                <Title //
                    serviceTitle={domain}
                    serviceSubTitle={getName()}
                    serviceType={type}
                    action={getActions()}
                />
            )}
        </>
    );
};
