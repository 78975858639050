import { superUserSuperActionsState } from 'components/StaffMenu/SuperUser/consts';
import type { SuperUserNamespace } from 'components/StaffMenu/SuperUser/types';
import { useLayoutEffect } from 'react';
import { useTanstackStoreKey } from 'utilities/hooks/tanstack-store/useStoreKey';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * The `useState` for a super user item.
 */
export function useSuperUserItemState(key: SuperUserNamespace.Key, defaultState?: boolean) {
    /***** HOOKS *****/
    const store = useTanstackStoreKey(superUserSuperActionsState, key);

    /***** EFFECTS *****/
    useLayoutEffect(() => {
        if (defaultState) {
            store.set(defaultState);
        }
    }, []);

    /***** RESULTS *****/
    return store;
}
