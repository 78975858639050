/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { infiniteQueryOptions, useInfiniteQuery } from '@tanstack/react-query';
import { merge } from 'lodash';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { WP_SUPPORT_CENTRE_API } from 'utilities/api/ventraip-website-wp-support-centre';
import { SUPPORT_CENTRE_API_DEFAULTS } from 'utilities/api/ventraip-website-wp-support-centre/consts';
import type { VentraIPWordpressSupportCentreAPI } from 'utilities/api/ventraip-website-wp-support-centre/types';
import { handleDefaultErrorNotification } from 'utilities/methods/commonActions/handleDefaultErrorNotification';
import { createBaseQueryKey } from 'utilities/methods/tanstack/createBaseQueryKey';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type PartialParams = Partial<VentraIPWordpressSupportCentreAPI.SupportArticleParams>;

function createQueryKey(params: PartialParams) {
    return createBaseQueryKey(['ventraip-website-wp-support-centre', 'support-articles', params]);
}

function createQueryOptions(params: PartialParams) {
    return infiniteQueryOptions({
        queryKey: createQueryKey(params),
        queryFn: async (queryFunctionContext) => {
            const { pageParam = params } = queryFunctionContext;
            return WP_SUPPORT_CENTRE_API.support_articles.GET(pageParam).catch((e) => {
                handleDefaultErrorNotification(e);
                throw e;
            });
        },
        initialPageParam: params,
        getNextPageParam: () => {
            return merge({}, params, { page: params.page || 0 + 1 });
        },
        staleTime: Infinity,
        select: (data) => {
            const dataFlatMap = data.pages.map((page) => page.data);
            return {
                ...data,
                pages: dataFlatMap
            };
        }
    });
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useInfiniteQuery(params: PartialParams = SUPPORT_CENTRE_API_DEFAULTS.support_articles) {
    return useInfiniteQuery(createQueryOptions(params));
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export const getSupportArticles = Object.freeze({
    useInfiniteQuery: _useInfiniteQuery
});
