import { PhosphorIcons } from 'components/Icons/Phosphor';
import RequestLoader from 'components/Loaders/Request';
import { Flex } from 'components/Utils/Flex';
import { Padding } from 'components/Utils/Padding';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type FieldValidation = React.FC<FieldValidationProps>;
type FieldValidationProps = {
    isError?: boolean;
    isSuccess?: boolean;
    isLoading?: boolean;
    message?: string;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const FieldValidation: FieldValidation = ({ isError, isSuccess, isLoading, message }) => {
    /***** RENDER HELPERS *****/
    const renderEligibilityValidationTag = () => {
        switch (true) {
            case isError:
                return (
                    <>
                        <PhosphorIcons.X.Bold warn size={15} />
                        <Text size--s lead--1 color="S_color_text_warn_base">
                            {message}
                        </Text>
                    </>
                );
            case isSuccess:
                return (
                    <>
                        <PhosphorIcons.Check.Bold confirm size={15} />
                        <Text size--s lead--1 color="S_color_text_confirm_base">
                            {message}
                        </Text>
                    </>
                );
            case isLoading:
                return <RequestLoader align-start fillHeight={15} height={10} />;

            default:
                return null;
        }
    };

    /***** RENDER *****/
    return (
        <Padding left={2} top={1}>
            <Flex align="start">{renderEligibilityValidationTag()}</Flex>
        </Padding>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
