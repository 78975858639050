import { useRegistrationOrderingFormContext } from 'App/components/templates/contexts/registrationOrderingFormContext/useRegistrationOrderingFormContext';
import { ServiceOverviewBanner } from 'components/ServiceOverviewBanner/';
import { Flex } from 'components/Utils/Flex';
import { useBrandStore } from 'config/hooks/useBrandStore';
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';
import './_domains.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const IntaserveDomainsBanner = () => {
    /***** HOOKS *****/
    const { company } = useBrandStore();
    const isTablet = useAppViewport('md');
    const isMobile = useAppViewport(['xs', 'sm']);
    const { setIsDomainRegistrationFormOpen, setIsRegistration } = useRegistrationOrderingFormContext();

    /***** FUNCTIONS *****/
    const handleRegisterOnClick = () => {
        setIsRegistration(true);
        setIsDomainRegistrationFormOpen(true);
    };

    const handleRTransferOnClick = () => {
        setIsRegistration(false);
        setIsDomainRegistrationFormOpen(true);
    };

    /***** RENDER *****/
    return (
        <Flex fullWidth={isMobile} direction={isMobile ? 'column' : 'row'} gap={isTablet ? 3 : 5}>
            <ServiceOverviewBanner className="DomainsBanner__intaserve" type="manual">
                <ServiceOverviewBanner.ContentWrapper>
                    <ServiceOverviewBanner.Heading>Expanding Your Portfolio?</ServiceOverviewBanner.Heading>
                    <ServiceOverviewBanner.Description>
                        Secure your brand by registering new domain names instantly with Intaserve
                    </ServiceOverviewBanner.Description>
                    <ServiceOverviewBanner.Button size="large" type="onClick" onClick={handleRegisterOnClick}>
                        Register Domains
                    </ServiceOverviewBanner.Button>
                </ServiceOverviewBanner.ContentWrapper>
            </ServiceOverviewBanner>

            <ServiceOverviewBanner className="DomainsBanner__intaserve--second" type="manual">
                <ServiceOverviewBanner.ContentWrapper>
                    <ServiceOverviewBanner.Heading primary>Transfer Your Domains</ServiceOverviewBanner.Heading>
                    <ServiceOverviewBanner.Description primary>
                        Leverage our full suite of domain management by transferring your portfolio to Intaserve
                    </ServiceOverviewBanner.Description>
                    <ServiceOverviewBanner.Button size="large" type="onClick" color="primary" onClick={handleRTransferOnClick}>
                        Transfer Domains
                    </ServiceOverviewBanner.Button>
                </ServiceOverviewBanner.ContentWrapper>
            </ServiceOverviewBanner>
        </Flex>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
