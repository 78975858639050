import { serviceQueryKeys } from "containers/services/queries/keys";
import { API } from "utilities/api/services";
import { createNXQuery } from "utilities/methods/tanstack/createQuery";

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
export const userExists = createNXQuery((params: API.service.get.userExists.Params) => ({
    queryKey: serviceQueryKeys.services.userExists(params),
    queryFn: () => API.service.get.userExists(params)
}));
