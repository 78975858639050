/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'utilities/methods/tanstack/router/withRouter';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import Cancel from 'components/Cancel';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';
import { StatusTag } from 'components/StatusTag';
import Table from 'components/Table';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { GoogleBanner } from './banners/google';

/*   ACTIONS
 *****************************************************/
import { cancelGSuiteRequest } from 'components/Cancel/action';
import { getGSuiteLogin } from 'containers/gsuite/action';
import { servicesMethods } from 'containers/services/methods';
import { cancelSearchGSuite } from 'utilities/api/services';
import { getIncludedObjBasedOnType, textLowerCase, truncateText } from 'utilities/methods/commonActions';
import { getGSuiteList, gsuiteSearch, resetGSuiteSearch } from '../action';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class GSuiteOverview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            current_page: null,
            total_pages: null,
            total_all_records: null,
            total_records: null,
            sortBy: null,
            orderBy: null,
            record_per_page: undefined,
            showInvoiceLightbox: false,
            invoiceId: null,
            cancelLightbox: {
                open: false,
                id: undefined
            },
            currentKeyword: null
        };
        this.openPayInvoice = this.openPayInvoice.bind(this);
        this.closePayInvoice = this.closePayInvoice.bind(this);
        this.closePayInvoiceSuccess = this.closePayInvoiceSuccess.bind(this);
        this.setSort = this.setSort.bind(this);
        this.setOrder = this.setOrder.bind(this);
        this.setShow = this.setShow.bind(this);
        this.monitorKeyword = this.monitorKeyword.bind(this);
        this.toggleCancelLightbox = this.toggleCancelLightbox.bind(this);
    }

    /******************* SETUP SEARCH HANDLER ****************/
    monitorKeyword(keyword = null) {
        this.setState({
            currentKeyword: keyword
        });
    }
    
    /************** OPEN/CLOSE LIGHTBOX **************/
    openPayInvoice(id) {
        this.setState({
            showInvoiceLightbox: true,
            invoiceId: id
        });
    }

    closePayInvoice() {
        this.setState({
            showInvoiceLightbox: false,
            invoiceId: null
        });
    }

    closePayInvoiceSuccess() {
        const { getGSuiteList } = this.props;

        this.setState(
            {
                showInvoiceLightbox: false,
                invoiceID: null
            },
            () => {
                getGSuiteList();
            }
        );
    }

    toggleCancelLightbox(mailboxId) {
        this.setState({
            cancelLightbox: {
                open: !this.state.cancelLightbox.open,
                id: mailboxId || undefined
            }
        });
    }

    /************** DISPLAY SERVICES **************/
    setSort(sort) {
        const { getGSuiteList } = this.props;
        let params;

        this.setState(
            {
                sortBy: sort
            },
            () => {
                const { sortBy, orderBy, recordsPerPage } = this.state;
                params = {
                    sort_by: sortBy,
                    sort: orderBy,
                    record_per_page: recordsPerPage
                };
                getGSuiteList(params);
            }
        );
    }

    setOrder(order) {
        const { getGSuiteList } = this.props;
        let params;

        this.setState(
            {
                orderBy: order
            },
            () => {
                const { sortBy, orderBy, recordsPerPage } = this.state;

                params = {
                    sort_by: sortBy,
                    sort: orderBy,
                    record_per_page: recordsPerPage
                };
                getGSuiteList(params);
            }
        );
    }

    setShow(recordsPerPage) {
        const { getGSuiteList } = this.props;
        let params;

        this.setState(
            {
                recordsPerPage: recordsPerPage
            },
            () => {
                const { sortBy, orderBy, recordsPerPage } = this.state;

                params = {
                    sort_by: sortBy,
                    sort: orderBy,
                    record_per_page: recordsPerPage
                };
                getGSuiteList(params);
            }
        );
    }

    componentDidMount() {
        const { getGSuiteList } = this.props;

        getGSuiteList();
    }

    componentDidUpdate(prevProps) {
        const {
            gsuite_list_status,
            gsuite_list_data,
            gsuite_list_meta,
            gsuite_list_more_status,
            gsuite_reverse_cancel_status,
            service_cancel_status,
            getGSuiteList
        } = this.props;
        const { toggleCancelLightbox } = this;

        if (gsuite_list_meta) {
            if (gsuite_list_status === 'success' && prevProps.gsuite_list_status === 'loading') {
                const { current_page, total_pages, total_all_records, total_records } = gsuite_list_meta;

                this.setState({
                    current_page,
                    total_pages,
                    total_all_records,
                    total_records,
                    current_list_view: gsuite_list_data
                });
            }

            if (gsuite_list_more_status === 'success' && prevProps.gsuite_list_more_status === 'loading') {
                const { current_page, total_pages, total_all_records, total_records } = gsuite_list_meta;

                this.setState({
                    current_page,
                    total_pages,
                    total_all_records,
                    total_records,
                    current_list_view: gsuite_list_data
                });
            }
        }

        if ((service_cancel_status === 'success' || service_cancel_status === 'error') && prevProps.service_cancel_status === 'loading') {
            toggleCancelLightbox();
        }

        if (gsuite_reverse_cancel_status === 'success' && prevProps.gsuite_reverse_cancel_status === 'loading') {
            getGSuiteList();
        }
    }

    render() {
        const {
            isExpressServiceManager,
            history,
            getGSuiteList,
            gsuite_search_status,
            gsuite_search_data,
            gsuite_list_status,
            gsuite_list_data,
            gsuite_list_more_status,
            service_cancel_status,
            gsuiteSearch,
            resetGSuiteSearch,
            cancelGSuiteRequest,
            getGSuiteLogin
        } = this.props;
        const { current_page, total_pages, showInvoiceLightbox, invoiceId, cancelLightbox, currentKeyword } = this.state;
        const { closePayInvoice, closePayInvoiceSuccess, setShow, monitorKeyword, toggleCancelLightbox } = this;

        const renderButton = (id, status, setup, validated, invoice, users) => {
            let secondButton, buttons;
            switch (status) {
                case 'active':
                    return [
                        {
                            label: 'Google Workspace',
                            size: 'medium',
                            color: 'notice',
                            type: 'onClick',
                            onClick: (e) => {
                                e.preventDefault();
                                getGSuiteLogin(id);
                            }
                        },
                        {
                            label: 'Manage',
                            size: 'medium',
                            color: 'primary',
                            type: 'onClick',
                            onClick: (e) => {
                                e.preventDefault();
                                history.push(`/my-services/google/${id}/manage#overview`);
                            }
                        }
                    ];

                case 'pending cancellation':
                    return (
                        <Cancel
                            button={{ type: 'outline', label: `Cancel Service` }}
                            cancel={{
                                id: id,
                                service: 'gsuite',
                                date: invoice,
                                status: status,
                                keep: {
                                    title: 'Keep Google Workspace Service',
                                    desc: `Are you sure you'd like to cancel this request and keep your service?`,
                                    action: (e) => {
                                        e.preventDefault();
                                        cancelGSuiteRequest(id);
                                    }
                                }
                            }}
                            title="Cancel Service"
                            desc="Once you cancel this service, all files, emails, databases and configurations will be deleted. To keep your data, make a full backup before cancelling your service."
                            label="Cancel Google Workspace Service"
                        />
                    );

                case 'pending':
                    secondButton = {
                        label: 'Cancel',
                        button: 'Outline',
                        type: 'onClick',
                        className: '',
                        color: 'warn',
                        size: 'medium',
                        onClick: (e) => {
                            e.preventDefault();
                            toggleCancelLightbox(id);
                        }
                    };

                    if (users && users.length === 1 && !users[0].agreed_to_terms && !setup) {
                        return [
                            {
                                label: 'Google Workspace',
                                size: 'medium',
                                color: 'notice',
                                type: 'onClick',
                                onClick: (e) => {
                                    e.preventDefault();
                                    getGSuiteLogin(id);
                                }
                            },
                            secondButton
                        ];
                    }

                    if (setup) {
                        return [
                            {
                                label: 'Setup',
                                size: 'medium',
                                type: 'onClick',
                                button: 'Solid',
                                onClick: (e) => {
                                    e.preventDefault();
                                    history.push(`/my-services/google/${id}/setup`);
                                }
                            },
                            secondButton
                        ];
                    }

                    if (!validated) {
                        return [
                            {
                                label: 'Verify',
                                size: 'medium',
                                type: 'onClick',
                                button: 'Solid',
                                onClick: (e) => {
                                    e.preventDefault();
                                    history.push(`/my-services/google/${id}/setup`);
                                }
                            },
                            secondButton
                        ];
                    }

                    if (invoice) {
                        return [
                            {
                                label: 'Pay Invoice',
                                type: 'onClick',
                                size: 'medium',
                                onClick: (e) => {
                                    e.preventDefault();
                                    this.openPayInvoice(invoice.id);
                                },
                                list: [
                                    {
                                        label: 'View Invoice',
                                        type: 'onClick',
                                        onClick: (e) => {
                                            const { history } = this.props;
                                            history.push(`/billing/invoice/${id}`);
                                        }
                                    }
                                ]
                            },
                            secondButton
                        ];
                    }

                    return [
                        <Anchor
                            key={1}
                            href={null}
                            className="contact__link"
                            onClick={() => {
                                history.push('/support/tickets/submit');
                            }}
                        >
                            Contact us for assistance
                        </Anchor>,
                        secondButton
                    ];

                case 'suspended':
                    buttons = [
                        {
                            label: 'Cancel',
                            button: 'Outline',
                            type: 'onClick',
                            color: 'warn',
                            size: 'medium',
                            onClick: (e) => {
                                e.preventDefault();
                                toggleCancelLightbox(id);
                            }
                        }
                    ];

                    if (invoice)
                        buttons.push({
                            label: 'Pay Invoice',
                            type: 'onClick',
                            size: 'medium',
                            onClick: (e) => {
                                e.preventDefault();
                                this.openPayInvoice(invoice.id);
                            }
                        });

                    return buttons;

                default:
                    return false;
            }
        };

        const renderStatus = (status, pending_accounts, validated, terms, setup, id, users) => {
            const lowerCaseStatus = textLowerCase(status);

            if (['suspended', 'pending cancellation'].includes(lowerCaseStatus))
                return <StatusTag status={lowerCaseStatus} className="table__solidtag" />;

            const isUserAccountPending = () => {
                let isUserAccountPending = false;

                if (pending_accounts && pending_accounts > 0) {
                    isUserAccountPending = true;
                }

                return isUserAccountPending;
            };

            if (isUserAccountPending()) {
                if (validated) {
                    return (
                        <div className="GSuiteOverview__pending-setup">
                            User Accounts Pending Setup{' '}
                            <Anchor className="GSuiteOverview__pending-setup-link" to={`/my-services/google/${id}/manage#overview`}>
                                ({pending_accounts})
                            </Anchor>
                        </div>
                    );
                }
            }

            if (users && users.length === 1) {
                if (!users[0].agreed_to_terms) return 'Pending Google Workspace ToS Agreement';
            }

            return <StatusTag status={lowerCaseStatus} className="table__solidtag" />;
        };

        const renderServiceName = (domain, product) => {
            const getName = () => {
                const { attributes } = product;
                const { name } = attributes;
                return name;
            };

            return (
                <div className="sharedTable__column--title">
                    <div className="primary">{domain ? truncateText(domain, 35, '...') : 'Not Available'}</div>
                    <div className="secondary">{product && getName() ? getName() : 'Not Available'}</div>
                </div>
            );
        };

        const renderSeats = (id, seats, status, setup, validated) => {
            switch (status) {
                case 'active':
                    if (setup) {
                        return seats;
                    }

                    if (validated) {
                        return <Anchor to={`/my-services/google/${id}/manage#overview`}>{seats}</Anchor>;
                    }

                    return seats;

                case 'pending':
                case 'suspended':
                default:
                    return seats;
            }
        };

        const isLastPage = () => {
            return !!(current_page && total_pages && current_page === total_pages);
        };

        const handleTableMatrix = (data) => {
            if (!data) {
                return false;
            }

            const gsuiteTempMatrix = Array(data.length).fill();

            Object.keys(gsuiteTempMatrix).forEach((value) => {
                const { id, included, attributes } = data[value];
                const { users, number_of_seats, domain, requires_setup, pending_accounts, is_domain_verified, agreed_to_terms } = attributes;
                const product = getIncludedObjBasedOnType(included, 'product');
                const invoice = getIncludedObjBasedOnType(included, 'invoice');
                const status = textLowerCase(attributes.status);

                gsuiteTempMatrix[value] = {
                    domain: renderServiceName(domain, product),
                    status: renderStatus(status, pending_accounts, is_domain_verified, agreed_to_terms, requires_setup, id, users),
                    seats: renderSeats(id, number_of_seats, status, requires_setup, is_domain_verified),
                    actions: renderButton(id, textLowerCase(status), requires_setup, is_domain_verified, invoice, users)
                };
            });

            return gsuiteTempMatrix;
        };

        /*   CREATE TABLE MATRIX
         **********************************************************************************************************/
        const gsuiteMatrix = handleTableMatrix(servicesMethods.table.handleMatrixCondition(currentKeyword, gsuite_list_data, gsuite_search_data));

        const handleTableRender = () => {
            let conditionalTableProps = {
                embedded: true
            };

            if (!isExpressServiceManager) {
                conditionalTableProps = servicesMethods.table.getSharedConditionalProps({
                    title: `Manage Google Workspace`,
                    search: {
                        data: gsuite_search_data,
                        placeholder: `Search for a Google Workspace account`,
                        status: gsuite_search_status,
                        cancel: cancelSearchGSuite,
                        search: gsuiteSearch,
                        reset: resetGSuiteSearch,
                        keyword: monitorKeyword
                    },
                    paginationStatus: gsuite_list_more_status,
                    setShow,
                    isLastPage,
                    matrix: gsuiteMatrix
                });
            }

            if (isExpressServiceManager && gsuiteMatrix && gsuiteMatrix.length > 5) {
                conditionalTableProps = {
                    showAll: {
                        label: 'show all google workspace',
                        status: gsuite_list_status,
                        conditions: (gsuiteMatrix && gsuiteMatrix.length > 5) || !isLastPage(),
                        onClick: () => history.push('/my-services/google')
                    },
                    embedded: true
                };
            }

            const getNoServicesTitle = () => {
                if (isExpressServiceManager) return 'You have no Google Workspace accounts.';
                if (currentKeyword) return 'No Google Workspace accounts matched your search.';
                return "You have no Google Workspace accounts. Why don't you purchase some below to get started!";
            };

            return (
                <Table
                    className="service__table--gsuite"
                    sort={{
                        function: getGSuiteList
                    }}
                    header={[
                        {
                            title: `Domain / Plan`,
                            sort: `name`,
                            className: `domain`
                        },
                        {
                            title: `Status`,
                            sort: `status`,
                            className: `status`
                        },
                        {
                            title: `Seats`,
                            className: `seats`
                        },
                        {
                            action: true
                        }
                    ]}
                    loading={servicesMethods.table.handleLoading(gsuite_list_status, gsuite_search_status)}
                    matrix={isExpressServiceManager ? gsuiteMatrix && gsuiteMatrix.slice(0, 5) : gsuiteMatrix}
                    error={getNoServicesTitle()}
                    stacked={true}
                    dynamicActions={true}
                    {...conditionalTableProps}
                />
            );
        };

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        if (isExpressServiceManager) {
            // Return this if this component called in the Express Service Manager
            return (
                <Fragment>
                    {handleTableRender()}
                    {showInvoiceLightbox ? (
                        <OverlayLightbox
                            invoiceid={invoiceId}
                            title={`Pay Invoice #` + invoiceId}
                            onOpen={showInvoiceLightbox}
                            onClose={closePayInvoice}
                            onSuccessClose={closePayInvoiceSuccess}
                        />
                    ) : (
                        ''
                    )}
                    {cancelLightbox.open && (
                        <OverlayLightbox
                            title="Cancel Service"
                            onOpen={cancelLightbox.open}
                            onClose={toggleCancelLightbox}
                            cancel={{
                                id: cancelLightbox.id,
                                service: 'gsuite',
                                closeAction: toggleCancelLightbox,
                                fromDashboard: true
                            }}
                            loading={service_cancel_status}
                        />
                    )}
                </Fragment>
            );
        }

        return (
            <div className="ServiceOverview">
                {handleTableRender()}

                <GoogleBanner />

                {showInvoiceLightbox ? (
                    <OverlayLightbox
                        invoiceid={invoiceId}
                        title={`Pay Invoice #` + invoiceId}
                        onOpen={showInvoiceLightbox}
                        onClose={closePayInvoice}
                        onSuccessClose={closePayInvoiceSuccess}
                    />
                ) : (
                    ''
                )}
                {cancelLightbox.open && (
                    <OverlayLightbox
                        title="Cancel Service"
                        onOpen={cancelLightbox.open}
                        onClose={toggleCancelLightbox}
                        cancel={{
                            id: cancelLightbox.id,
                            service: 'gsuite',
                            closeAction: toggleCancelLightbox
                        }}
                        loading={service_cancel_status}
                    />
                )}
            </div>
        );
    }
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default withRouter(
    connect(
        (state) => ({
            gsuite_list_status: state.services.gsuite_list_status,
            gsuite_list_data: state.services.gsuite_list_data,
            gsuite_list_meta: state.services.gsuite_list_meta,
            gsuite_list_error: state.services.gsuite_list_error,
            gsuite_list_more_status: state.services.gsuite_list_more_status,
            gsuite_search_status: state.services.gsuite_search_status,
            gsuite_search_data: state.services.gsuite_search_data,
            gsuite_cancel_status: state.cancel.gsuite_cancel_status,
            gsuite_reverse_cancel_status: state.cancel.gsuite_reverse_cancel_status,
            service_cancel_status: state.cancel.service_cancel_status,
            app_viewport: state.app.app_viewport
        }),
        {
            cancelGSuiteRequest,
            gsuiteSearch,
            resetGSuiteSearch,
            getGSuiteLogin,
            getGSuiteList
        }
    )(GSuiteOverview)
);
