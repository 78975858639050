import HoverTooltip from 'components/Tooltip/HoverTooltip';
import Text from 'components/Utils/Text';
import { HostingTableRowTitle } from 'containers/services/modules/hosting/components/accordionTitle';
import { HostingTableColumn } from 'containers/services/modules/hosting/components/column';
import { useHostingContext } from 'containers/services/modules/hosting/context';
import { getDaysBetween, toLuxonDate } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const OwnRenew = () => {
    /***** HOOKS *****/
    const serviceData = useHostingContext();

    /***** RENDER HELPERS *****/
    const renew = getDaysBetween(toLuxonDate(serviceData?.attributes.next_due_date, 'yyyy-MM-dd TT'));
    const renewFormat = toLuxonDate(serviceData?.attributes.next_due_date, 'yyyy-MM-dd TT').toFormat('ccc LLL dd y');
    const isPending = serviceData?.attributes.domain_status.toLowerCase() === 'pending';
    const isOneTimeBilling = serviceData?.attributes.billing_cycle.name === 'One Time';
    const hasRenew = typeof renew === 'number' || !!renew;
    const isWarn = Number(renew) <= 1;
    const isNotice = Number(renew) < 90 && Number(renew) > 1;
    const isSecondary = Number(renew) >= 90;

    /***** RENDER *****/
    return (
        <HostingTableColumn>
            {!isOneTimeBilling && hasRenew && !isPending && (
                <>
                    <HostingTableRowTitle>RENEWS</HostingTableRowTitle>
                    <HoverTooltip className="HostingTable__Tooltip" noWrap content={renewFormat}>
                        <Text warn={isWarn} bold={isWarn} notice={isNotice} secondary={isSecondary} size--xss lead--1 align--left>
                            {(() => {
                                switch (true) {
                                    case renew < 0 && renew !== -1:
                                        return `Overdue by ${renew.toString().substring(1)} days`;
                                    case renew === 0:
                                        return 'Today';
                                    case renew === 1:
                                        return 'Tomorrow';
                                    case renew === -1:
                                        return 'Overdue Yesterday';
                                    default:
                                        return `In ${renew} days`;
                                }
                            })()}
                        </Text>
                    </HoverTooltip>
                </>
            )}
        </HostingTableColumn>
    );
};
