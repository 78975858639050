import classNames from 'classnames';
import React from 'react';
import './_NXTableFooter.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<{
 *   children: React.ReactNode,
 *   className?: string
 * }>}
 */
export const _NXTableFooter = ({ children, className }) => <div className={classNames('NXTableFooter', className)}>{children}</div>;
