import { EftPaymentMethod } from 'components/Lightboxes/OverlayLightbox/Components/invoice/paymentMethods/eft';
import { PayIdPaymentMethod } from 'components/Lightboxes/OverlayLightbox/Components/invoice/paymentMethods/payId';
import { PaymentMethodsError } from 'components/Lightboxes/OverlayLightbox/Components/invoice/paymentMethods/PaymentMethodsError';
import type { SelectedPaymentMethodProps } from 'components/Lightboxes/OverlayLightbox/Components/invoice/paymentMethods/types';
import RequestLoader from 'components/Loaders/Request';
import { useGetAvailablePaymentMethodsQuery } from 'containers/billing/queries/useGetAvailablePaymentMethodsQuery';
import { useGetDefaultPaymentMethodQuery } from 'containers/billing/queries/useGetDefaultPaymentMethodQuery';
import { useGetUserPaymentMethodDataListQuery } from 'containers/billing/queries/useGetUserPaymentMethodDataListQuery';
import { useEffect, useState } from 'react';
import './_paymentMethods.scss';
import { ApplyCreditPaymentUtility } from './applyCreditUtility';
import { BPayPaymentMethod } from './bpay';
import { PAYMENT_METHODS, PAYMENT_METHODS_VALUES } from './consts';
import { CreditCardPaymentMethod } from './creditCard';
import { PayPalPaymentMethod } from './paypal';
import { SavedCreditCardPaymentMethod } from './savedCreditCard';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * This React component is a payment methods selector that allows the user to choose a payment method for a specific invoice.
 * It fetches the available payment methods, including credit cards, PayPal, BPay, PayID, and saved credit cards,
 * and displays them in a list for the user to select from.
 * It also handles loading states while fetching the payment methods.
 */
export const LightboxPaymentMethods: React.FC<SelectedPaymentMethodProps> = ({ invoiceID, mutationOptions }) => {
    /***** STATE *****/
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string | null>(null);

    /***** QUERIES *****/
    /**
     * Get filtered payment methods by credit card only
     */
    const { data: get_user_payment_method_data_list_data, isLoading: isGetUserPaymentMethodDataListLoading } = useGetUserPaymentMethodDataListQuery({
        select: useGetUserPaymentMethodDataListQuery.selectFilters.getPaymentMethodType(PAYMENT_METHODS.CREDIT_CARD)
    });

    const { data: get_default_payment_method_data, isLoading: isGetDefaultPaymentMethodLoading } = useGetDefaultPaymentMethodQuery();
    const {
        isLoading: isGetAvailablePaymentMethodsLoading,
        isError: isGetAvailablePaymentMethodsError,
        data: availablePaymentMethodsData
    } = useGetAvailablePaymentMethodsQuery();

    // Only show the payment method tabs for methods that are available (not deprecated) in Mercury
    const availablePaymentMethodNames = Array.isArray(availablePaymentMethodsData)
        ? availablePaymentMethodsData.map((method) => method.name.toLowerCase())
        : [];

    const paymentMethodComponents: React.FC<SelectedPaymentMethodProps>[] = [];
    if (availablePaymentMethodNames.includes('credit card')) {
        paymentMethodComponents.push(SavedCreditCardPaymentMethod, CreditCardPaymentMethod);
    }
    if (availablePaymentMethodNames.includes('paypal')) {
        paymentMethodComponents.push(PayPalPaymentMethod);
    }
    if (availablePaymentMethodNames.includes('bpay')) {
        paymentMethodComponents.push(BPayPaymentMethod);
    }
    if (availablePaymentMethodNames.includes('payid')) {
        paymentMethodComponents.push(PayIdPaymentMethod);
    }
    if (availablePaymentMethodNames.includes('eft')) {
        paymentMethodComponents.push(EftPaymentMethod);
    }
    paymentMethodComponents.push(ApplyCreditPaymentUtility);

    /***** EFFECTS *****/
    useEffect(() => {
        if (!get_default_payment_method_data || !get_user_payment_method_data_list_data?.length) {
            return;
        }

        const defaultPaymentMethod = get_default_payment_method_data?.attributes?.default_payment_method;

        if (
            defaultPaymentMethod === PAYMENT_METHODS.CREDIT_CARD &&
            get_user_payment_method_data_list_data.length > 0 &&
            get_user_payment_method_data_list_data.find(({ attributes: { is_default } }) => is_default)
        ) {
            /**
             * If the default payment method is credit card and we have a default card on file, select Credit Saved Credit card
             */
            setSelectedPaymentMethod(PAYMENT_METHODS.SAVED_CREDIT_CARD);
        } else if (PAYMENT_METHODS_VALUES.includes(defaultPaymentMethod)) {
            setSelectedPaymentMethod(defaultPaymentMethod);
        }
    }, [get_default_payment_method_data, get_user_payment_method_data_list_data]);

    /***** RENDER HELPERS *****/
    function renderPaymentMethodContent() {
        if (isGetUserPaymentMethodDataListLoading || isGetDefaultPaymentMethodLoading || isGetAvailablePaymentMethodsLoading) {
            return <RequestLoader message="Loading payment methods" fillHeight={100} />;
        }

        return paymentMethodComponents.map((PaymentMethodComponent, index) => (
            <PaymentMethodComponent
                key={index}
                selectedPaymentMethod={selectedPaymentMethod}
                setSelectedPaymentMethod={setSelectedPaymentMethod}
                invoiceID={invoiceID}
                mutationOptions={mutationOptions}
            />
        ));
    }

    /***** RENDER *****/
    return (
        <div className="PaymentMethods">
            {isGetAvailablePaymentMethodsError && <PaymentMethodsError invoiceID={invoiceID} />}
            {renderPaymentMethodContent()}
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
