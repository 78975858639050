import { addItems } from 'containers/orderingForm/queries/cartId/addItems';
import { createOrder } from 'containers/orderingForm/queries/cartId/createOrder';
import { editItem } from 'containers/orderingForm/queries/cartId/editItem';
import { getCart } from 'containers/orderingForm/queries/cartId/getCart';
import { removeItems } from 'containers/orderingForm/queries/cartId/removeItems';
import { createCart } from 'containers/orderingForm/queries/createCart';
import { createCartInternal } from 'containers/orderingForm/queries/createCartInternal';
import { getPaymentMethods } from 'containers/orderingForm/queries/getPaymentMethods';
import { getProducts } from 'containers/orderingForm/queries/getProducts';
import { mutateGetProducts } from 'containers/orderingForm/queries/mutateGetProducts';

export const _orderingFormQuery = Object.freeze({
    getProducts,
    mutateGetProducts,
    cart: {
        createCart,
        createCartInternal,
        getPaymentMethods,
        cartId: {
            getCart,
            addItems,
            removeItems,
            editItem,
            createOrder
        }
    }
});
