import { useIsMutating } from '@tanstack/react-query';
import { SolidButton } from 'components/Buttons/SolidButton';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import RequestLoader from 'components/Loaders/Request';
import { Flex } from 'components/Utils/Flex';
import { Padding } from 'components/Utils/Padding';
import Text from 'components/Utils/Text';
import { useOrderingFormContext } from 'containers/orderingForm/contexts/useOrderingFormContext';
import 'containers/orderingForm/pages/transferPage/transferButton/_transferButton.scss';
import type { DomainSuggestions } from 'containers/orderingForm/types';
import React, { useState } from 'react';
import { NXQuery } from 'utilities/query';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type TransferButton = React.FC<TransferButtonProps>;
type TransferButtonProps = {
    domainAvalibility?: DomainSuggestions[];
    suggestion?: DomainSuggestions;
    isSuggestionButton?: boolean;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const TransferButton: TransferButton = ({ domainAvalibility }) => {
    const { domain, tld, product_id } = domainAvalibility?.[0] ?? {};

    /***** STATE *****/
    const [isAddedButtonHovered, setIsAddedButtonHovered] = useState(false);

    /***** HOOKS *****/
    const { cartToken, setCartToken } = useOrderingFormContext();

    /***** QUERIES *****/
    const { data: cart_data } = NXQuery.orderingForm.cart.cartId.getCart.useQuery(cartToken);
    const { mutate: createCart, isPending: isCreateCartPending } = NXQuery.orderingForm.cart.createCart.useMutation(setCartToken);
    const { mutate: addCartItems, isPending: isCartItemLoading } = NXQuery.orderingForm.cart.cartId.addItems.useMutation();
    const { mutate: removeCartItem, isPending: isRemoveCartItemLoading } = NXQuery.orderingForm.cart.cartId.removeItems.useMutation();

    const isAddItemMutating = !!useIsMutating({ mutationKey: ['addCartItem'] });
    const isCreateCartMutating = !!useIsMutating({ mutationKey: ['createCart'] });

    const isButtonDisabled = isAddItemMutating || isCreateCartMutating;

    const name = `${domain}${tld}`;
    const item = [
        {
            pending: true,
            billing_cycle_id: 8,
            name,
            order_type: 'transfer',
            id: product_id,
            auto_renew: true,
            epp: 'invalid epp',
        },
    ];

    const added = cart_data?.cart?.items.some((item) => item.name === name.toLowerCase());
    const itemId = cart_data?.cart?.items.find((item) => item.name === name.toLowerCase())?.uuid;

    /***** FUNCTIONS *****/
    const handleAddItemOnClick = () => {
        if (!cartToken) {
            createCart(item);
        } else {
            addCartItems(
                { items: item, cartToken },
                {
                    onSuccess: () => setIsAddedButtonHovered(false)
                }
            );
        }
    };

    /***** RENDER HELPERS *****/
    const renderAddedButtonContent = () => {
        if (isRemoveCartItemLoading || isCreateCartPending) {
            return <RequestLoader />;
        }

        if (isAddedButtonHovered) {
            return (
                <>
                    <PhosphorIcons.X.Bold />
                    <Text lead--0 semiBold>
                        Remove
                    </Text>
                </>
            );
        }

        return (
            <>
                <PhosphorIcons.Check.Bold />
                <Text lead--0 semiBold>
                    Added
                </Text>
            </>
        );
    };

    /***** RENDER *****/
    switch (true) {
        case added:
            return (
                <SolidButton
                    type="onClick"
                    width="auto"
                    className="transfer__button--added"
                    onMouseOver={() => setIsAddedButtonHovered(true)}
                    onMouseLeave={() => setIsAddedButtonHovered(false)}
                    onClick={() => itemId && cartToken && removeCartItem({ itemId, cartToken })}
                >
                    <Padding x={3}>
                        <Flex align="center">{renderAddedButtonContent()}</Flex>
                    </Padding>
                </SolidButton>
            );

        default:
            return (
                <SolidButton
                    type="onClick"
                    width="auto"
                    className="transfer__button"
                    onClick={() => handleAddItemOnClick()}
                    disabled={isButtonDisabled}
                >
                    <Padding x={3}>
                        <Flex align="center">
                            {isCartItemLoading || isCreateCartPending ? (
                                <RequestLoader width={53} />
                            ) : (
                                <>
                                    <PhosphorIcons.Plus.Bold />
                                    <Text lead--0 semiBold>
                                        Add
                                    </Text>
                                </>
                            )}
                        </Flex>
                    </Padding>
                </SolidButton>
            );
    }
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
