import { useMutation } from '@tanstack/react-query';
import { useNXQuery } from 'components/NXQueryProvider';
import { API } from 'utilities/api/services';
import { useDefaultQueryClientOptions } from 'utilities/hooks/tanstack-query/useDefaultQueryClientOptions';
import type { NXQueryUtils } from 'utilities/methods/tanstack/types';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type UseMutationOpts<TContext> = NXQueryUtils.TMutationOptions<typeof API.hosting.restore, TContext>;

/**********************************************************************************************************
 *   QUERY KEY
 **********************************************************************************************************/
const mutationKey = ['useCreateRestoreInvoiceMutation'];

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Hook that returns the default OnSuccess function for the mutation. This will call the default onSuccess from
 * the queryClient and then invalidate any queries that contain the serviceId.
 */
const useDefaultOnSuccess = <TContext>() => {
    const NXQuery = useNXQuery();
    const [, { onSuccess }] = useDefaultQueryClientOptions();

    return (response: any, serviceId: API.hosting.restore.Params, context: TContext) => {
        onSuccess?.(response, serviceId, context);
        NXQuery.hosting.invalidate(serviceId);
    };
};

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
const _useMutation = <TContext>(opts?: UseMutationOpts<TContext>) => {
    /***** HOOKS *****/
    const defaultOnSuccess = useDefaultOnSuccess();

    /***** QUERIES *****/
    return useMutation({
        mutationKey,
        mutationFn: API.hosting.restoreNew,
        onSuccess: defaultOnSuccess,
        ...opts
    });
};

export const createRestoreInvoice = Object.freeze({
    useMutation: _useMutation,
    defaults: {
        useOnSuccess: useDefaultOnSuccess
    }
});
