export const EMAIL_INITIAL_STATE = 'email/EMAIL_INITIAL_STATE';

export const EMAIL_INFORMATION_REQUEST = 'email/EMAIL_INFORMATION_REQUEST';
export const EMAIL_INFORMATION_SUCCESS = 'email/EMAIL_INFORMATION_SUCCESS';
export const EMAIL_INFORMATION_FAIL = 'email/EMAIL_INFORMATION_FAIL';

export const EMAIL_RESTORE_COST_REQUEST = 'email/EMAIL_RESTORE_COST_REQUEST';
export const EMAIL_RESTORE_COST_SUCCESS = 'email/EMAIL_RESTORE_COST_SUCCESS';
export const EMAIL_RESTORE_COST_FAIL = 'email/EMAIL_RESTORE_COST_FAIL';

export const EMAIL_INFORMATION_MAILBOX_REQUEST = 'email/EMAIL_INFORMATION_MAILBOX_REQUEST';
export const EMAIL_INFORMATION_MAILBOX_SUCCESS = 'email/EMAIL_INFORMATION_MAILBOX_SUCCESS';
export const EMAIL_INFORMATION_MAILBOX_FAIL = 'email/EMAIL_INFORMATION_MAILBOX_FAIL';

export const EMAIL_PRODUCT_LIST_REQUEST = 'email/EMAIL_PRODUCT_LIST_REQUEST';
export const EMAIL_PRODUCT_LIST_SUCCESS = 'email/EMAIL_PRODUCT_LIST_SUCCESS';
export const EMAIL_PRODUCT_LIST_FAIL = 'email/EMAIL_PRODUCT_LIST_FAIL';

export const EMAIL_MAILBOX_LIST_REQUEST = 'email/EMAIL_MAILBOX_LIST_REQUEST';
export const EMAIL_MAILBOX_LIST_SUCCESS = 'email/EMAIL_MAILBOX_LIST_SUCCESS';
export const EMAIL_MAILBOX_LIST_FAIL = 'email/EMAIL_MAILBOX_LIST_FAIL';

export const EMAIL_MAILBOX_SEARCH_REQUEST = 'email/EMAIL_MAILBOX_SEARCH_REQUEST';
export const EMAIL_MAILBOX_SEARCH_SUCCESS = 'email/EMAIL_MAILBOX_SEARCH_SUCCESS';
export const EMAIL_MAILBOX_SEARCH_ERROR = 'email/EMAIL_MAILBOX_SEARCH_ERROR';
export const EMAIL_MAILBOX_SEARCH_RESET = 'email/EMAIL_MAILBOX_SEARCH_RESET';

export const EMAIL_MAILBOX_LIST_MORE_REQUEST = 'email/EMAIL_MAILBOX_LIST_MORE_REQUEST';
export const EMAIL_MAILBOX_LIST_MORE_SUCCESS = 'email/EMAIL_MAILBOX_LIST_MORE_SUCCESS';
export const EMAIL_MAILBOX_LIST_MORE_FAIL = 'email/EMAIL_MAILBOX_LIST_MORE_FAIL';

export const EMAIL_KEEP_REQUEST = 'email/EMAIL_KEEP_REQUEST';
export const EMAIL_KEEP_SUCCESS = 'email/EMAIL_KEEP_SUCCESS';
export const EMAIL_KEEP_FAIL = 'email/EMAIL_KEEP_FAIL';

export const EMAIL_DELETE_REQUEST = 'email/EMAIL_DELETE_REQUEST';
export const EMAIL_DELETE_SUCCESS = 'email/EMAIL_DELETE_SUCCESS';
export const EMAIL_DELETE_FAIL = 'email/EMAIL_DELETE_FAIL';

export const EMAIL_MAILBOX_UPDATE_REQUEST = 'email/EMAIL_MAILBOX_UPDATE_REQUEST';
export const EMAIL_MAILBOX_UPDATE_SUCCESS = 'email/EMAIL_MAILBOX_UPDATE_SUCCESS';
export const EMAIL_MAILBOX_UPDATE_FAIL = 'email/EMAIL_MAILBOX_UPDATE_FAIL';

export const EMAIL_DISKBLOCK_RESET = 'email/EMAIL_DISKBLOCK_RESET';
export const EMAIL_DISKBLOCK_LIST_REQUEST = 'email/EMAIL_DISKBLOCK_LIST_REQUEST';
export const EMAIL_DISKBLOCK_LIST_SUCCESS = 'email/EMAIL_DISKBLOCK_LIST_SUCCESS';
export const EMAIL_DISKBLOCK_LIST_FAIL = 'email/EMAIL_DISKBLOCK_LIST_FAIL';

export const EMAIL_DISKBLOCK_PURCHASE_REQUEST = 'email/EMAIL_DISKBLOCK_PURCHASE_REQUEST';
export const EMAIL_DISKBLOCK_PURCHASE_SUCCESS = 'email/EMAIL_DISKBLOCK_PURCHASE_SUCCESS';
export const EMAIL_DISKBLOCK_PURCHASE_FAIL = 'email/EMAIL_DISKBLOCK_PURCHASE_FAIL';

export const EMAIL_DISKBLOCK_REQUEST = 'email/EMAIL_DISKBLOCK_REQUEST';
export const EMAIL_DISKBLOCK_SUCCESS = 'email/EMAIL_DISKBLOCK_SUCCESS';
export const EMAIL_DISKBLOCK_FAIL = 'email/EMAIL_DISKBLOCK_FAIL';

export const EMAIL_UPGRADE_LIST_REQUEST = 'email/EMAIL_UPGRADE_LIST_REQUEST';
export const EMAIL_UPGRADE_LIST_SUCCESS = 'email/EMAIL_UPGRADE_LIST_SUCCESS';
export const EMAIL_UPGRADE_LIST_FAIL = 'email/EMAIL_UPGRADE_LIST_FAIL';

export const EMAIL_UPGRADE_REQUEST = 'email/EMAIL_UPGRADE_REQUEST';
export const EMAIL_UPGRADE_SUCCESS = 'email/EMAIL_UPGRADE_SUCCESS';
export const EMAIL_UPGRADE_FAIL = 'email/EMAIL_UPGRADE_FAIL';

export const EMAIL_UPGRADE_DETAILS_REQUEST = 'email/EMAIL_UPGRADE_DETAILS_REQUEST';
export const EMAIL_UPGRADE_DETAILS_SUCCESS = 'email/EMAIL_UPGRADE_DETAILS_SUCCESS';
export const EMAIL_UPGRADE_DETAILS_ERROR = 'email/EMAIL_UPGRADE_DETAILS_ERROR';

export const EMAIL_DOWNGRADE_REQUEST = 'email/EMAIL_DOWNGRADE_REQUEST';
export const EMAIL_DOWNGRADE_SUCCESS = 'email/EMAIL_DOWNGRADE_SUCCESS';
export const EMAIL_DOWNGRADE_FAIL = 'email/EMAIL_DOWNGRADE_FAIL';

export const EMAIL_BILLINGCYCLE_REQUEST = 'email/EMAIL_BILLINGCYCLE_REQUEST';
export const EMAIL_BILLINGCYCLE_SUCCESS = 'email/EMAIL_BILLINGCYCLE_SUCCESS';
export const EMAIL_BILLINGCYCLE_FAIL = 'email/EMAIL_BILLINGCYCLE_FAIL';

export const EMAIL_BILLING_REQUEST = 'email/EMAIL_BILLING_REQUEST';
export const EMAIL_BILLING_SUCCESS = 'email/EMAIL_BILLING_SUCCESS';
export const EMAIL_BILLING_FAIL = 'email/EMAIL_BILLING_FAIL';

export const EMAIL_MAILBOX_LOGIN_REQUEST = 'email/EMAIL_MAILBOX_LOGIN_REQUEST';
export const EMAIL_MAILBOX_LOGIN_SUCCESS = 'email/EMAIL_MAILBOX_LOGIN_SUCCESS';
export const EMAIL_MAILBOX_LOGIN_FAIL = 'email/EMAIL_MAILBOX_LOGIN_FAIL';

export const EMAIL_DOMAIN_GROUP_DETAILS_REQUEST = 'email/EMAIL_DOMAIN_GROUP_DETAILS_REQUEST';
export const EMAIL_DOMAIN_GROUP_DETAILS_SUCCESS = 'email/EMAIL_DOMAIN_GROUP_DETAILS_SUCCESS';
export const EMAIL_DOMAIN_GROUP_DETAILS_ERROR = 'email/EMAIL_DOMAIN_GROUP_DETAILS_ERROR';

export const EMAIL_SEND_DETAILS_REQUEST = 'email/EMAIL_SEND_DETAILS_REQUEST';
export const EMAIL_SEND_DETAILS_SUCCESS = 'email/EMAIL_SEND_DETAILS_SUCCESS';
export const EMAIL_SEND_DETAILS_FAIL = 'email/EMAIL_SEND_DETAILS_FAIL';

export const EMAIL_PURCHASE_MAILBOX_RESET = 'email/EMAIL_PURCHASE_MAILBOX_RESET';
export const EMAIL_PURCHASE_MAILBOX_REQUEST = 'email/EMAIL_PURCHASE_MAILBOX_REQUEST';
export const EMAIL_PURCHASE_MAILBOX_SUCCESS = 'email/EMAIL_PURCHASE_MAILBOX_SUCCESS';
export const EMAIL_PURCHASE_MAILBOX_FAIL = 'email/EMAIL_PURCHASE_MAILBOX_FAIL';

export const EMAIL_MAILBOX_SETUP_REQUEST = 'email/EMAIL_MAILBOX_SETUP_REQUEST';
export const EMAIL_MAILBOX_SETUP_SUCCESS = 'email/EMAIL_MAILBOX_SETUP_SUCCESS';
export const EMAIL_MAILBOX_SETUP_FAIL = 'email/EMAIL_MAILBOX_SETUP_FAIL';

export const EMAIL_ALIASES_LIST_REQUEST = 'email/EMAIL_ALIASES_LIST_REQUEST';
export const EMAIL_ALIASES_LIST_SUCCESS = 'email/EMAIL_ALIASES_LIST_SUCCESS';
export const EMAIL_ALIASES_LIST_FAIL = 'email/EMAIL_ALIASES_LIST_FAIL';

export const EMAIL_ALIASES_REQUEST = 'email/EMAIL_ALIASES_REQUEST';
export const EMAIL_ALIASES_SUCCESS = 'email/EMAIL_ALIASES_SUCCESS';
export const EMAIL_ALIASES_FAIL = 'email/EMAIL_ALIASES_FAIL';

export const EMAIL_ALIASES_DELETE_REQUEST = 'email/EMAIL_ALIASES_DELETE_REQUEST';
export const EMAIL_ALIASES_DELETE_SUCCESS = 'email/EMAIL_ALIASES_DELETE_SUCCESS';
export const EMAIL_ALIASES_DELETE_FAIL = 'email/EMAIL_ALIASES_DELETE_FAIL';

export const EMAIL_ACTIVE_SYNC_REQUEST = 'email/EMAIL_ACTIVE_SYNC_REQUEST';
export const EMAIL_ACTIVE_SYNC_SUCCESS = 'email/EMAIL_ACTIVE_SYNC_SUCCESS';
export const EMAIL_ACTIVE_SYNC_FAIL = 'email/EMAIL_ACTIVE_SYNC_FAIL';

export const EMAIL_ACTIVE_SYNC_TOGGLE_REQUEST = 'email/EMAIL_ACTIVE_SYNC_TOGGLE_REQUEST';
export const EMAIL_ACTIVE_SYNC_TOGGLE_SUCCESS = 'email/EMAIL_ACTIVE_SYNC_TOGGLE_SUCCESS';
export const EMAIL_ACTIVE_SYNC_TOGGLE_FAIL = 'email/EMAIL_ACTIVE_SYNC_TOGGLE_FAIL';

export const EMAIL_RESTORE_MAILBOX_REQUEST = 'email/EMAIL_RESTORE_MAILBOX_REQUEST';
export const EMAIL_RESTORE_MAILBOX_SUCCESS = 'email/EMAIL_RESTORE_MAILBOX_SUCCESS';
export const EMAIL_RESTORE_MAILBOX_FAIL = 'email/EMAIL_RESTORE_MAILBOX_FAIL';

export const EMAIL_VALIDATE_REQUEST = 'email/EMAIL_VALIDATE_REQUEST';
export const EMAIL_VALIDATE_SUCCESS = 'email/EMAIL_VALIDATE_SUCCESS';
export const EMAIL_VALIDATE_ERROR = 'email/EMAIL_VALIDATE_ERROR';
export const EMAIL_VALIDATE_RESET = 'email/EMAIL_VALIDATE_RESET';

export const EMAIL_USAGE_REQUEST = 'email/EMAIL_USAGE_REQUEST';
export const EMAIL_USAGE_SUCCESS = 'email/EMAIL_USAGE_SUCCESS';
export const EMAIL_USAGE_ERROR = 'email/EMAIL_USAGE_ERROR';
