/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useEffect, useState } from 'react';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Only delay unmounting if the children are going to change to a falsy value
 */
const NXTransitionDelayedUnmount = ({ children, delay = 300 }) => {
    /***** STATE *****/
    const [activeChild, setActiveChild] = useState(children);

    /***** EFFECTS *****/
    useEffect(() => {
        if (!children) return;

        const timeout = setTimeout(() => {
            setActiveChild(children);
        }, delay);

        return () => clearTimeout(timeout);
    }, [children, delay]);

    /***** HOOK RESULTS *****/
    return children || activeChild;
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { NXTransitionDelayedUnmount };
