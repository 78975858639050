import type { ConditionalParams, Params, RoutePaths } from 'router/types';
import { getRouteConfig } from 'router/utils/getRouteConfig';
import { resolveRouteConfig } from 'router/utils/resolveRouteConfig';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
//prettier-ignore
type IsRouteActiveOpts<TRoutePath extends RoutePaths> = 
    ConditionalParams<TRoutePath> & 
    {
        path: TRoutePath;
        hash?: string;
    }

/** attempts to access params on opts, or returns an empty object */
const safeParams = <TRoutePath extends RoutePaths>(opts: IsRouteActiveOpts<TRoutePath>): Params<{ path: TRoutePath }> => (opts as any)?.params ?? {};

/**
 * Function for determining if a route is active. This accepts a similar (cut down) api to tanstack router functions such as
 * redirect, notFound and buildLocation, and will use the route config to determine whether or not the route is active
 */
export const isRouteActive = <TRoutePath extends RoutePaths>(opts: IsRouteActiveOpts<TRoutePath>): boolean => {
    const retrievedConfig = getRouteConfig({ path: opts.path, hash: opts.hash });
    const resolvedConfig = resolveRouteConfig(retrievedConfig, safeParams(opts));

    return resolvedConfig.active;
};
