/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useMutation } from '@tanstack/react-query';
import { queryClient } from 'store/queryClient';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { API as ACCOUNT_API } from 'utilities/api/account';
import { cloneObject } from 'utilities/methods/commonActions';
import { getUserPaymentMethodDataListBoilerPlate } from './useGetUserPaymentMethodDataListQuery';

/**********************************************************************************************************
 *   User Payment Method
 **********************************************************************************************************/
/***** React Queries and mutations *****/
export const CREATE_PAYMENT_METHOD_DATA = 'billing/CREATE_PAYMENT_METHOD_DATA';

/**
 * Create new payment_method_data for specified payment_method_id
 */
export function useCreatePaymentMethodDataMutation(options = {}) {
    return useMutation({
        mutationKey: [CREATE_PAYMENT_METHOD_DATA],
        mutationFn: ACCOUNT_API.user.payment_method_data.POST,
        ...options
    });
}

// const GET_PAYMENT_METHOD_DATA = 'billing/GET_PAYMENT_METHOD_DATA';
/**
 * Get User Payment Method Data - Single
 */
// function useGetPaymentMethodDataQuery(id, options = {}) {
//     return useQuery({
//         queryKey: [GET_PAYMENT_METHOD_DATA, id],
//         queryFn: () => ACCOUNT_API.user.payment_method_data.single.GET(id),
//         ...options
//     });
// }

export const SET_PAYMENT_METHOD_DATA_AS_DEFAULT = 'billing/SET_PAYMENT_METHOD_DATA_AS_DEFAULT';

/**
 * User set payment method data id as the default for that payment method
 */
export function useSetPaymentMethodDataAsDefaultMutation(options = {}) {
    return useMutation({
        mutationKey: [SET_PAYMENT_METHOD_DATA_AS_DEFAULT],
        mutationFn: ACCOUNT_API.user.payment_method_data.single.PUT,
        onMutate: (id) => {
            queryClient.setQueryData(getUserPaymentMethodDataListBoilerPlate.createQueryKey(), (oldQueryResponse) => {
                if (!oldQueryResponse) return;
                try {
                    const clonedOldQueryResponse = cloneObject(oldQueryResponse);
                    clonedOldQueryResponse.data.find((paymentData) => paymentData.id === id).isDataUpdating = true;
                    clonedOldQueryResponse.data.find((paymentData) => paymentData.attributes.is_default).isDataUpdating = true;
                    return clonedOldQueryResponse;
                } catch (error) {
                    return;
                }
            });
        },
        onSettled: () => {
            getUserPaymentMethodDataListBoilerPlate.invalidate();
        },
        ...options
    });
}

export const DELETE_PAYMENT_METHOD_DATA = 'billing/DELETE_PAYMENT_METHOD_DATA';

/**
 * Deletes the payment method data id from the payment method data
 */
export function useDeletePaymentMethodDataMutation(options = {}) {
    return useMutation({
        mutationKey: [DELETE_PAYMENT_METHOD_DATA],
        mutationFn: ACCOUNT_API.user.payment_method_data.single.DELETE,
        ...options
    });
}

/**
 * User sets default Payment Method
 */
export function useSetDefaultPaymentMethodMutation(options = {}) {
    return useMutation({ mutationFn: ACCOUNT_API.user.payment_method.PUT, ...options });
}

/**
 * User Payment Methods Callback
 */
export function useHandlePaymentMethodCallbackMutation(options = {}) {
    return useMutation({ mutationFn: ACCOUNT_API.user.payment_method.callback.POST, ...options });
}
