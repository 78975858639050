import { PhosphorIcons } from 'components/Icons/Phosphor';
import { Flex } from 'components/Utils/Flex';
import 'containers/orderingForm/exclamationMarkIcon/_exclamationMarkIcon.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type ExclamationMark = React.FC<ExclamationMarkProps>;
type ExclamationMarkProps = {
    size: number;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const ExclamationMark: ExclamationMark = ({ size }) => {
    /***** RENDER *****/
    return (
        <Flex justify="center" align="center" className="exclamationMark__container">
            <PhosphorIcons.ExclamationMark.Bold white size={size} />
        </Flex>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
