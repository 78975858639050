import AppLoader from 'components/Loaders/App';
import React, { useEffect } from 'react';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Props = {
    resetError: () => void;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Renders a loader for a short period after an error occurs before triggering the onReset function provided by the
 * error boundary.
 */
export const LoginErrorBoundaryFallback = ({ resetError }: Props): React.ReactElement => {
    /***** EFFECTS *****/
    useEffect(() => {
        const timeoutRef = setTimeout(() => {
            resetError();
        }, 1000);

        return () => {
            clearTimeout(timeoutRef);
        };
    }, []);

    /***** RENDER *****/
    return <AppLoader />;
};
