import NXBox from 'components/NXBox';
import { Flex } from 'components/Utils/Flex';
import { Padding } from 'components/Utils/Padding';
import Text from 'components/Utils/Text';
import { useOrderingFormContext } from 'containers/orderingForm/contexts/useOrderingFormContext';
import 'containers/orderingForm/pages/registerPage/registrationAvailabilityConfirmation/registerErrorConfirmation/_registerErrorConfirmation.scss';
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';
import { truncateText } from 'utilities/methods/commonActions';
import { NXQuery } from 'utilities/query';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const RegisterErrorConfirmation = () => {
    /***** HOOKS *****/
    const { domainData } = useOrderingFormContext();
    const isMobile = useAppViewport(['xs', 'sm']);

    const truncateLength = isMobile ? 30 : 40;

    /***** QUERIES *****/
    const { data: domain_availibility_data } = NXQuery.domain.availability.useQuery(domainData);

    /***** RENDER *****/
    return (
        <NXBox className="registerErrorConfirmation__container">
            <Padding xy={1}>
                <Text size--m align--center className="registerSuccessConfirmation__containerText">
                    Sorry! This domain is not available
                </Text>
                <Flex fullWidth align="center" justify="center" className="registerErrorConfirmation__information">
                    <Text size--xm bold align--center>
                        {truncateText(domain_availibility_data?.[0]?.domain.toLowerCase(), truncateLength, '...')}
                        {domain_availibility_data?.[0]?.tld}
                    </Text>
                </Flex>
            </Padding>
        </NXBox>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
