import classNames from 'classnames';
import React from 'react';
import './_NXBoxBottom.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
type NXBoxBottomProps = {
    children: React.ReactNode;
    className?: string;
};

/**
 * Provides the grey background that often appears at the bottom of Box instances
 */
export const _NXBoxBottom: React.FC<NXBoxBottomProps> = ({ children, className }) => (
    <div className={classNames('NXBoxBottom', className)}>
        <div className="NXBoxBottom__container">{children}</div>
    </div>
);
