import { OutlineButton } from 'components/Buttons/OutlineButton';
import InvoiceURLLightbox from 'components/Lightboxes/OverlayLightbox/Components/invoice/invoiceUrlLightbox';
import { useHostingContext } from 'containers/services/modules/hosting/context';
import { NXQuery } from 'utilities/query';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const PayInvoiceButton = () => {
    /***** HOOKS *****/
    const serviceData = useHostingContext();

    /***** EFFECTS *****/
    InvoiceURLLightbox.useInvoiceActionEffect('pay', NXQuery.hosting.invalidate);

    /***** RENDER HELPERS *****/
    const invoiceId = getInvoiceId(serviceData);

    /***** RENDER *****/
    return (
        <>
            {!!invoiceId && (
                <OutlineButton.Link to="/billing/invoice/$invoiceId/pay" params={{ invoiceId: String(invoiceId) }} size="medium">
                    Pay Invoice
                </OutlineButton.Link>
            )}
        </>
    );
};

/**********************************************************************************************************
 *   FUNCTIONS
 **********************************************************************************************************/
function getInvoiceId(serviceData: ReturnType<typeof useHostingContext>) {
    const invoice = serviceData?.included?.find((item) => item.type === 'invoice');

    if (invoice && 'id' in invoice) {
        return invoice.id ?? null;
    }

    return null;
}
