import { Padding } from 'components/Utils/Padding';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type ComponentWithChildren = React.FC<{
    children: React.ReactNode;
}>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const _TwoFactorHeading: ComponentWithChildren = ({ children }) => {
    /***** RENDER *****/
    return (
        <Padding bottom={5} inject>
            <Text align--center primary size--h2 bold>
                {children}
            </Text>
        </Padding>
    );
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const _TwoFactorDescription: ComponentWithChildren = ({ children }) => {
    return (
        <Padding bottom={5} inject>
            <Text align--center secondary lead--xs size--s>
                {children}
            </Text>
        </Padding>
    );
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const _TwoFactorDestination: ComponentWithChildren = ({ children }) => {
    return (
        <Text bold secondary>
            {children}
        </Text>
    );
};

/***** EXPORTS *****/
export const TwoFactorHeading = Object.assign(_TwoFactorHeading, {
    Description: _TwoFactorDescription,
    Destination: _TwoFactorDestination
});
