import { type MutationKey, type MutationOptions } from '@tanstack/react-query';
import { InternalMutationFactory } from 'utilities/methods/tanstack/createMutation/factory';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type DefaultMutationOptions<TData, TError, TVariables, TContext> = MutationOptions<TData, TError, TVariables, TContext> & {
    mutationKey: MutationKey;
};

type Meta = {
    error: any;
    data: any;
    variables: any;
};
type TagResponse<TResult, TMeta extends Meta> = TResult & {
    /** Type of the errors based on historical artah types - can be used to type .catch errors */
    _error: TMeta['error'];
    
    /** Type of the data based on historical artah types - can be used to type return values */
    _data: TMeta['data'];
    
    /** Type of the variables based on historical artah types - can be used to type parameters */
    _variables: TMeta['variables'];
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @summary "Like createNXQuery, but for mutations" - Lleyton Morris 2025
 */
export const createNXMutation = <TData = unknown, TError = ErrorFromNXTaggedResponse<TData>, TVariables = void, TContext = unknown>(
    defaultMutationOptions: DefaultMutationOptions<TData, TError, TVariables, TContext>
) => {
    const mutationFactory = new InternalMutationFactory<TData, TError, TVariables, TContext>();

    /**********************************************************************************************************
     *   UTILITIES
     **********************************************************************************************************/
    const _createMutationOptions = mutationFactory.createMutationOptions(defaultMutationOptions);
    const _useMutation = mutationFactory.useStandardMutation(_createMutationOptions);
    const _useSelectMutationState = mutationFactory.useSelectMutationState(_createMutationOptions);
    const _useSelectLatestMutationState = mutationFactory.useSelectLatestMutationState(_createMutationOptions);
    const _execute = mutationFactory.executeMutation(_createMutationOptions);

    /**********************************************************************************************************
     *   EXPORTS
     **********************************************************************************************************/
    const result = {
        useMutation: _useMutation,
        useSelectMutationState: _useSelectMutationState,
        useSelectLatestMutationState: _useSelectLatestMutationState,
        createMutationOptions: _createMutationOptions,
        execute: _execute
    };

    /**********************************************************************************************************
     *   TYPE DEFINITIONS
     **********************************************************************************************************/
    // prettier-ignore
    type TaggedResponse = TagResponse<typeof result, {
        error: TError;
        data: TData;
        variables: TVariables;
    }>;

    return result as TaggedResponse;
};
