import { renderForBrands } from 'config/brandRenderer/helper';
import { useBrandStore, type Brand, type BrandName } from 'config/hooks/useBrandStore';
import type { ReactNode } from 'react';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type RenderForBrandsProps = {
    children: ReactNode | ((config: Brand) => ReactNode);
    brands: BrandName[];
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export function RenderForBrands({ children, brands }: RenderForBrandsProps) {
    /***** HOOKS *****/
    const brandConfig = useBrandStore();

    /***** RENDER HELPERS *****/
    const shouldRender = renderForBrands(brands);

    /***** RENDER *****/
    if (!shouldRender) return null;

    if (typeof children === 'function') {
        return children(brandConfig);
    }

    return children;
}
