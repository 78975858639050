/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { createContext, useContext } from 'react';
import { useSelector as _useSelector } from 'react-redux';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
/**
 * @typedef {import('./types').NSSLTable.TContext} TContext
 * @typedef {import('./types').NSSLTable.TStore} TStore
 * @typedef {import('react-redux').TypedUseSelectorHook<TStore>} TUseSelector
 */
const defaultContextValues = /** @type {TContext} */ ({
    isExpressServiceManager: false,
    numberOfRows: null,
    setNumberOfRows: () => {},
    showExpiredSSLs: false,
    setShowExpiredSSLs: () => {},
    search: {
        isSearching: false,
        keyword: null,
        status: null
    },
    setSearch: () => {},
    initialized: false
});

export const SSLOverviewContext = createContext(defaultContextValues);
export const useSSLOverviewContext = () => useContext(SSLOverviewContext);
export const useSSLTableSelector = /** @type {TUseSelector} */ (_useSelector);
