import _ from 'lodash';

export function remindLater2faMigration() {
    const currentlyStoredData = localStorage.getItem('remind_later_2fa');
    if (!currentlyStoredData) return;

    const parsedValue = JSON.parse(currentlyStoredData);

    if (!_.isNil(parsedValue)) {
        localStorage.setItem('USER_PREFERENCE:REMIND_LATER_2FA_CURRENT_DATE', JSON.stringify(parsedValue));
    }
}
