import classNames from 'classnames';
import { _FormItemInnerPrependedText as FormItemInnerPrependedText } from 'components/Form/FormItemInner/_PrependedText/prependedText';
import type { FormItemInnerNamespace } from 'components/Form/FormItemInner/types';
import { HookForm } from 'components/Form/HookForm';
import { ValidationMessage } from 'components/Form/ValidationMessage';
import { WrapperBox } from 'components/Form/WrapperBox';
import { createAppliedStylingClasses } from 'components/Utils/methods';
import React from 'react';
import { useFormContext, useFormState } from 'react-hook-form';
import { returnErrorAndWarnClass } from 'utilities/methods/form';
import '../_FormItemInner.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _FormItemInnerHookForm: React.FC<FormItemInnerNamespace.HookForm.Props> = (props) => {
    const { children, wrapperboxClasses, prepend, validated, disabled, fullWidth, name } = props;

    /***** HOOKS *****/
    const { getFieldState } = useFormContext();
    const formState = useFormState();
    const fieldState = getFieldState(name, formState);

    /***** RENDER HELPERS *****/
    const appliedStylingClasses = createAppliedStylingClasses({
        props,
        keyBoundary: ['noBackground'],
        componentName: 'FormItemInner',
        delimiter: '--'
    });

    const meta = HookForm.getMeta(fieldState);
    const { isTouched, error } = meta;

    const formItemInnerClass = classNames('FormItemInner form__item__inner', appliedStylingClasses);

    /***** RENDER *****/
    return (
        <div className={formItemInnerClass}>
            <WrapperBox
                fullWidth={fullWidth}
                className={wrapperboxClasses}
                error={returnErrorAndWarnClass(isTouched, error, null, null) && !validated}
                isPrepended={!!prepend}
                disabled={disabled}
                validatedValid={returnErrorAndWarnClass(isTouched, validated?.valid, null, null)}
                validatedInvalid={returnErrorAndWarnClass(isTouched, validated && !validated.valid, null, null)}
            >
                <FormItemInnerPrependedText>{prepend}</FormItemInnerPrependedText>
                {children}
            </WrapperBox>
            <ValidationMessage.HookForm {...meta} validated={validated} />
        </div>
    );
};
