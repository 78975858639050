import { zodResolver } from '@hookform/resolvers/zod';
import { AcknowledgementBox } from 'components/AcknowledgementBox';
import Anchor from 'components/Anchor';
import { HookFormButton } from 'components/Form/Button/hookForm';
import { CheckBoxList } from 'components/Form/CheckBoxList';
import { DialogNotification } from 'components/Notifications/DialogNotification';
import SimpleTable from 'components/SimpleTable';
import Text from 'components/Utils/Text';
import { useBrandStore } from 'config/hooks/useBrandStore';
import { FormProvider, useForm } from 'react-hook-form';
import { refiners } from 'utilities/methods/form/validations/zod/refiners';
import { NXQuery } from 'utilities/query';
import { z } from 'zod';
import { useHostingContext } from '../modules/hosting/context';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Schema = z.infer<typeof schema>;
type OwnDialogueNotification = React.FC<{
    code: string | number;
    details?: string;
    closeLightbox: () => void;
}>;
type UnsuspensionForm = React.FC<{
    onClose: () => void;
}>;

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
const schema = z.object({
    fixConfirm: z.boolean().refine(refiners.true.function, refiners.true.options)
});

/**********************************************************************************************************
 *   CONSTS - JSX
 **********************************************************************************************************/
const OwnDialogueNotification: OwnDialogueNotification = ({ code, details, closeLightbox }) => {
    /***** HOOKS *****/
    const { application } = useBrandStore();

    /***** RENDER *****/
    return (
        <>
            <DialogNotification tail={{ pos: 'top' }} outline={false} type="warning">
                {code === 'ERR_UNSUSPEND_BLOCKED' && details ? (
                    <>
                        PLEASE NOTE: You cannot unsuspend this service at this time. Please&nbsp;
                        <Anchor to="/support/tickets/submit">submit an eTicket</Anchor>&nbsp; through {application} and our team will be happy to
                        assist.
                    </>
                ) : (
                    <>
                        PLEASE NOTE: You have exceeded the number of times you can unsuspend this service. Please&nbsp;
                        <Anchor to="/support/tickets/submit">submit an eTicket</Anchor>
                        <br />
                        &nbsp; through {application} and our team will be happy to assist.
                    </>
                )}
            </DialogNotification>

            <Anchor onClick={closeLightbox} color="primary" className="unsuspendReturn__button">
                Go Back
            </Anchor>
        </>
    );
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const UnsuspensionForm: UnsuspensionForm = ({ onClose }) => {
    /***** HOOKS *****/
    const { company, application } = useBrandStore();
    const serviceData = useHostingContext();
    const defaultUnsuspendServiceOnSuccess = NXQuery.services.unsuspendService.defaults.useOnSuccess();

    /***** QUERIES *****/
    const { mutate: unsuspendService, isPending: isUnsuspending } = NXQuery.services.unsuspendService.useMutation({
        onSuccess: (data, variables, context) => {
            onClose();
            defaultUnsuspendServiceOnSuccess(data, variables, context);
        }
    });

    //prettier-ignore
    const {
        data: suspensionReason,
        isLoading,
        isError,
        error: suspensionReasonError
    } = NXQuery.services.suspensionReason.useQuery(serviceData.id, {
        enabled: !isUnsuspending
    });

    /***** FORM *****/
    const form = useForm<Schema>({
        resolver: zodResolver(schema),
        mode: 'all'
    });

    const getData = (): Partial<Artah.Service.ID.Unsuspend.Reason.GET._200['data']> => {
        if (suspensionReasonError && 'data' in suspensionReasonError) {
            return suspensionReasonError.data;
        }
        return suspensionReason ?? {};
    };

    /***** RENDER HELPERS *****/
    const { code, status, errors } = suspensionReasonError ?? {};
    const { reason, instructions } = getData();
    const isExpectedErrorCode = ['ERR_UNSUSPEND_BLOCKED', 'ERR_UNSUSPEND_MAX_LIMIT_REACHED'].includes(code!);
    const isExpectedErrorStatus = status === 403;
    const details = errors?.[0]?.details;

    /***** RENDER *****/
    if (code === 'ERR_SERVICE_NOT_FOUND') {
        return (
            <div className="UnsuspensionForm">
                <p>Unfortunately, this service with us has been suspended.</p>
                <OwnDialogueNotification details={details} code={code} closeLightbox={onClose} />
            </div>
        );
    }

    /***** RENDER *****/
    return (
        <div className="UnsuspensionForm">
            <p>
                Unfortunately, this service with us has been suspended. <br />
                The details of the suspension are below:
            </p>
            <SimpleTable>
                <SimpleTable.Row>
                    <h5>PRIMARY DOMAIN</h5>
                    <p>{serviceData.attributes.domain}</p>
                </SimpleTable.Row>
                <SimpleTable.Row>
                    <h5>PLAN/SERVICE</h5>
                    <p>{serviceData.attributes.product.name}</p>
                </SimpleTable.Row>
                <SimpleTable.Row>
                    <h5>SUSPENSION REASON</h5>
                    <Text.Skeleton isLoading={isLoading}>{reason}</Text.Skeleton>
                </SimpleTable.Row>
                <SimpleTable.Row>
                    <h5>SUSPENSION INFORMATION</h5>
                    <Text.Skeleton isLoading={isLoading}>{instructions}</Text.Skeleton>
                </SimpleTable.Row>
                <SimpleTable.Row>
                    <p className="ventra-text--primary text--center__important">
                        This suspension information was supplied by the {company} support team.
                    </p>
                </SimpleTable.Row>
            </SimpleTable>

            <p className="additional-support-copy">
                If you need any further information or assistance, please &nbsp;
                <Anchor to="/support/tickets/submit" className="submitTicket__button">
                    Submit an eTicket
                </Anchor>
                <br />
                &nbsp; through {application} and our team will be happy to assist.
            </p>

            <FormProvider {...form}>
                {isError && isExpectedErrorStatus && isExpectedErrorCode ? (
                    <div className="unsuspensionActionForm">
                        <HookFormButton>Unsuspend Service</HookFormButton>
                        <OwnDialogueNotification code={403} details={details} closeLightbox={onClose} />
                    </div>
                ) : (
                    <form className="unsuspensionActionForm" onSubmit={form.handleSubmit(() => unsuspendService(serviceData.id))}>
                        <AcknowledgementBox.Compact title="Confirmation">
                            <CheckBoxList.Item.HookForm name="fixConfirm">
                                <Text align--left>
                                    I confirm that I am aware of the issue mentioned above and will resolve the issue immediately, otherwise the
                                    service will be at risk of being suspended again.
                                </Text>
                            </CheckBoxList.Item.HookForm>
                        </AcknowledgementBox.Compact>
                        <HookFormButton loading={isUnsuspending}>Unsuspend Service</HookFormButton>
                    </form>
                )}
            </FormProvider>
        </div>
    );
};
