import avalonAirshowImg from 'assets/images/promo/tiles/avalonAirshow.jpg';
import duaLipa from 'assets/images/promo/tiles/duaLipaTour.jpg';
import gracieAbramsTour from 'assets/images/promo/tiles/gracieAbramsTour.jpg';
import lesMis from 'assets/images/promo/tiles/lesMis.jpg';
import monsterTruckMania from 'assets/images/promo/tiles/monsterTruckMania.jpg';
import nordPass from 'assets/images/promo/tiles/nordPass.jpg';
import nordVPN from 'assets/images/promo/tiles/nordVPN.jpg';
import offSpringTour from 'assets/images/promo/tiles/offSpringTour.jpg';
import youfoodz from 'assets/images/promo/tiles/youfoodz.png';
import Text from 'components/Utils/Text';
import { _OneClickCard as Card } from 'containers/vipRewards/modules/rewardCard/cards/oneClickCard/oneClickCard';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const OneClickCards = {
    DuaLipaTour: () => (
        <Card
            promoId="dua-lipa-radical-optimism-tour-2025"
            tag="GIVEAWAY"
            title="Win tickets to Dua Lipa, Radical Optimism Tour"
            description="Dua Lipa is bringing her Radical Optimism Tour to Melbourne on Sunday, 23rd March, and you could be in the crowd!"
            image={duaLipa}
            terms="https://ventraip.com.au/terms-policies-agreements/viprewards-2025-dua-lipa-radical-optimism-tour-giveaway/"
            modalContent={
                <>
                    <Text align--center secondary size--s>
                        {`You've successfully entered for your chance to win tickets to Dua Lipa's Radical Optimism Tour!  We're giving nine lucky winners the chance to score 2 tickets each to see her live at Rod Laver Arena on Sunday, 23 March 2025. Even better—you'll watch the show in style from an exclusive box!`}
                    </Text>
                    <Text align--center secondary size--s>
                        Winners will be contacted via email on 14 March 2025. Good luck!
                    </Text>
                    <Text italic align--center secondary size--s>
                        <Text bold secondary italic size--s>
                            Please Note:{' '}
                        </Text>
                        {`The prize consists of two corporate box tickets to Dua Lipa's "Radical Optimism Tour" at Rod Laver Arena on 23 March 2025. Travel, accommodation, and food are not included.`}
                    </Text>
                </>
            }
        />
    ),
    AvalonAirshow: () => (
        <Card
            promoId="avalon-australian-international-airshow-2025"
            tag="GIVEAWAY"
            title="Win tickets to the 2025 Avalon Airshow!"
            description="The Avalon Australian International Airshow is back this March and you could be there! Experience breathtaking performances from some of the best aircrafts in the world!"
            image={avalonAirshowImg}
            terms="https://ventraip.com.au/terms-policies-agreements/viprewards-2025-avalon-airshow-giveaway/"
            modalContent={
                <>
                    <Text align--center secondary size--s>
                        {`You've successfully entered for your chance to win tickets to the 2025 Avalon Airshow!`}
                    </Text>
                    <Text align--center secondary size--s>
                        Winners will be contacted via email on 17 March 2025. Good luck!
                    </Text>
                </>
            }
        />
    ),
    NordVPN: () => (
        <Card
            promoId="null"
            tag="SPECIAL OFFER"
            title="Up to 72% OFF NordVPN + 6 months free"
            description="Get up to 72% off on 2-year plans + 6 months FREE! Available on Ultra, Ultimate, Complete, Plus, and Standard plans. Offer ends 19th March!"
            image={nordVPN}
            buttonText="Learn More"
            buttonLink="https://analytics.nexigen.digital/redirect?mtm_source=ccp&mtm_campaign=nordvpn&mtm_medium=viprewards&siteId=8&redirect=https://nordvpn.com/coupon/deal/?coupon=ventraip5&utm_medium=affiliate&utm_term=&utm_content&utm_campaign=off15&utm_source=aff116548"
        />
    ),
    NordPass: () => (
        <Card
            promoId="null"
            tag="SPECIAL OFFER"
            title="Protect More, Save More with NordPass"
            description="Get 55% off + 3 months FREE on the Premium plan or 53% off the Family plan! Protect yourself and your loved ones with top-tier security."
            image={nordPass}
            buttonText="Learn More"
            buttonLink="https://analytics.nexigen.digital/redirect?mtm_source=ccp&mtm_campaign=nordpass&mtm_medium=viprewards&siteId=8&redirect=https://nordpass.com/special/?utm_medium=affiliate&utm_term&utm_content&utm_campaign=off488&utm_source=aff116548&aff_free"
        />
    ),
    GracieAbramsTour: () => (
        <Card
            promoId="gracie-abrams-the-secret-life-of-us-tour-2025"
            tag="GIVEAWAY"
            title="Win x2 tickets to Gracie Abrams The Secret life of Us Tour"
            description="Get ready for an unforgettable night with Gracie Abrams at The Secret Life of Us Tour! We're giving 8 lucky winners the chance to score 2 tickets each to experience the magic from an exclusive VIP box at Rod Laver Arena on Saturday, 10 May 2025! "
            image={gracieAbramsTour}
            terms="https://ventraip.com.au/terms-policies-agreements/viprewards-2025-gracie-abrams-the-secret-life-of-us-tour-giveaway/"
            modalContent={
                <>
                    <Text align--center secondary size--s>
                        {`You've successfully entered for your chance to win tickets to Gracie Abrams The Secret life of Us Tour"`}
                    </Text>
                    <Text align--center secondary size--s>
                        Winners will be contacted via email on 2 April 2025. Good luck!
                    </Text>
                </>
            }
        />
    ),
    LesMis: () => (
        <Card
            promoId="les-miserables-the-arena-spectacular-2025"
            tag="GIVEAWAY"
            title="Win x2 tickets to Les Misérables: The Arena Spectacular"
            description="Experience the epic story of Les Misérables like never before! We're giving 8 lucky winners the chance to score 2 tickets each to witness Les Misérables: The Arena Spectacular from an exclusive VIP box at Rod Laver Arena on Sunday, 18 May 2025!"
            image={lesMis}
            terms="https://ventraip.com.au/terms-policies-agreements/viprewards-2025-les-miserables-the-arena-spectacular-giveaway/"
            modalContent={
                <>
                    <Text align--center secondary size--s>
                        {`You've successfully entered for your chance to win tickets to Les Misérables: The Arena Spectacular`}
                    </Text>
                    <Text align--center secondary size--s>
                        Winners will be contacted via email on 28 April 2025. Good luck!
                    </Text>
                </>
            }
        />
    ),
    OffSpringTour: () => (
        <Card
            promoId="the-offspring-supercharged-worldwide-in-25-tour-2025"
            tag="GIVEAWAY"
            title="Win x2 tickets to see The OffSpring Supercharged Worldwide in '25"
            description="Get ready to rock at The Offspring Live, and you could be there in style! We're giving one lucky winner the chance to score 2 tickets to experience the energy from an exclusive VIP box at Rod Laver Arena on Thursday, 8 May 2025!"
            image={offSpringTour}
            terms="https://ventraip.com.au/terms-policies-agreements/viprewards-2025-offspring-supercharged-worldwide-giveaway/"
            modalContent={
                <>
                    <Text align--center secondary size--s>
                        {`You've successfully entered for your chance to win tickets to The OffSpring Supercharged Worldwide in '25 Tour!`}
                    </Text>
                    <Text align--center secondary size--s>
                        Winners will be contacted via email on 2 April 2025. Good luck!
                    </Text>
                </>
            }
        />
    ),
    MonsterTruckMania: () => (
        <Card
            promoId="monster-truck-mania-2025"
            tag="GIVEAWAY"
            title="Win x2 tickets to Monster Truck Mania Live!"
            description="Get ready for an adrenaline-pumping night at Monster Truck Mania Live, and you could be there in style! We're giving 3 lucky winners the chance to score 2 tickets each to experience the action from an exclusive VIP box at Rod Laver Arena on Sunday, 4 May 2025"
            image={monsterTruckMania}
            terms="https://ventraip.com.au/terms-policies-agreements/viprewards-2025-monster-truck-mania-giveaway/"
            modalContent={
                <>
                    <Text align--center secondary size--s>
                        {`You've successfully entered for your chance to win tickets to Monster Truck Mania Live`}
                    </Text>
                    <Text align--center secondary size--s>
                        Winners will be contacted via email on 2 April 2025. Good luck!
                    </Text>
                </>
            }
        />
    ),
    Youfoodz: () => (
        <Card
            promoId="null"
            tag="SPECIAL OFFER"
            title="Get up to $210 off Youfoodz"
            description="Say no to meal planning, shopping and cooking with meals delivered fresh to your doorstep. Save up to $210 on your first five boxes of healthy, tasty Youfoodz meals."
            terms="http://youfoodz.com/terms"
            image={youfoodz}
            buttonText="Claim Now"
            buttonLink="https://analytics.nexigen.digital/redirect?mtm_source=ccp&mtm_campaign=youfoodz&mtm_medium=viprewards&siteId=8&redirect=https://www.youfoodz.com/plans?featured=plan:everyday&c=YF210VP"
        />
    )
};
