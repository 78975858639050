import BannerOutlineDesktop from 'assets/images/account/additonalUserBanner/additionalUserBannerDesktopLeft.svg';
import BannerOutlineMobile from 'assets/images/account/additonalUserBanner/additionalUserBannerMobileLeft.svg';
import BannerOutlineTablet220 from 'assets/images/account/additonalUserBanner/additionalUserBannerTablet220.svg';
import BannerOutlineTablet from 'assets/images/account/additonalUserBanner/additionalUserBannerTabletLeft.svg';
import classNames from 'classnames';
import { OutlineButton } from 'components/Buttons/OutlineButton';
import { SolidButton } from 'components/Buttons/SolidButton';
import Text from 'components/Utils/Text';
import { useBrandStore } from 'config/hooks/useBrandStore';
import { promoBannerDismissKeys } from 'containers/dashboard/Components/consts';
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';
import { useMatchMedia } from 'utilities/hooks/useMatchMedia';
import './_AdditionalUserBanner.scss';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
const banner = {
    xs: BannerOutlineMobile,
    sm: BannerOutlineTablet,
    md: BannerOutlineTablet220,
    lg: BannerOutlineDesktop,
    xl: BannerOutlineDesktop
} as const;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const _AdditionalUserBanner = () => {
    /***** HOOKS *****/
    const { application } = useBrandStore();
    const viewport = useAppViewport();
    const isDesktopSmall = useMatchMedia({ max: 1125, min: 1024 });
    const isDesktopLarge = useMatchMedia({ min: 1125 });
    const isTablet = useAppViewport('md');
    const isMobile = useAppViewport(['sm', 'xs']);

    /***** RENDER HELPERS *****/
    const classes = {
        outer: classNames({
            'additionalUserBanner': true,
            'additionalUserBanner--tablet': isTablet,
            'additionalUserBanner--desktopSmall': isDesktopSmall,
            'additionalUserBanner--mobile': isMobile
        })
    };

    /***** RENDER *****/
    return (
        <div className={classes.outer}>
            <div className="bulbOutline">
                <img src={banner[viewport]} className="bulbOutline__bulb" alt="outline with information bulb" />
                <div className="bulbOutline__content">
                    <div className="bulbOutline__contentInner">
                        <div className="additionalUserBanner__left">
                            <Text bold className="additionalUserBanner__heading">
                                Did you know?
                            </Text>
                            <div className="additionalUserBanner__content">
                                <Text
                                    size--l={isDesktopLarge}
                                    size--m={isDesktopSmall || isMobile}
                                    lead--s
                                    className="additionalUserBanner__contentFirst"
                                >
                                    {application} supports the ability to add additional users.
                                </Text>
                                <Text size--s={!isDesktopSmall} size--xs={isDesktopSmall} lead--s className="additionalUserBanner__contentSecond">
                                    Easily add others to access your account, manage services, pay invoices and more.
                                </Text>
                            </div>
                        </div>
                        <div className="additionalUserBanner__buttons">
                            <SolidButton to="/account/general#additional-users">Add Users</SolidButton>
                            <OutlineButton type="anchor" target="_blank" href="/support/support-centre/all-about-additional-users/">
                                Learn More
                            </OutlineButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export const AdditionalUserBanner = Object.assign(_AdditionalUserBanner, {
    DISMISS_KEY: promoBannerDismissKeys.ADDITIONAL_USER_DISMISS_KEY
});
