import { useRegistrationOrderingFormContext } from 'App/components/templates/contexts/registrationOrderingFormContext/useRegistrationOrderingFormContext';
import Anchor from 'components/Anchor';
import { Flex } from 'components/Utils/Flex';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const DomainOrderingFormLinks = () => {
    /***** HOOKS *****/
    const { setIsDomainRegistrationFormOpen, setIsRegistration } = useRegistrationOrderingFormContext();

    /***** RENDER *****/
    return (
        <Flex gap={5}>
            <Anchor
                onClick={() => {
                    setIsRegistration(true);
                    setIsDomainRegistrationFormOpen(true);
                }}
            >
                Register Domain Name
            </Anchor>
            <Anchor
                onClick={() => {
                    setIsRegistration(false);
                    setIsDomainRegistrationFormOpen(true);
                }}
            >
                Transfer Domain Name
            </Anchor>
        </Flex>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
