/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { useHistory } from 'utilities/hooks/router/useHistory';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import VipRewardsHomePurchaseHostingForm from './purchaseHostingForm';
import { PurchaseCustomHostingResourceSelect } from './resourceSelect';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import FetchComponentError from 'components/Errors/FetchComponentError';
import Icons from 'components/Icons';
import RequestLoader from 'components/Loaders/Request';
import PricingTable from 'components/PricingTable';
import { nodeStates, ProgressStepsNode } from 'components/ProgressSteps/Node';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { pushNotification } from 'components/Toast/functions';
import { calculateAddHostingCost } from 'containers/domain/action';
import { getHostingProducts } from 'containers/hosting/state/accountActions';
import { addBestPromo, createCart, createOrder, getHostingResourceConfig } from 'containers/hosting/state/baseActions';
import { gaPurchaseEvent } from 'router/google';
import { getInitiallySelectedPlanName, getLowerCasePlanNameFromProduct } from './methods';
import useCustomHostingConfigs from './resourceSelect/hooks/useCustomHostingConfigs';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { VipRewardsHomePurchaseHostingLightboxProps } from 'containers/vipRewards/sections/home/purchaseHostingLightbox/types';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { billingCycles } from 'config/config';
import { hostingTypes, planNames } from './consts';

import FreedomImg from 'assets/images/hosting/upgradeFreedom.svg';
import PremierImg from 'assets/images/hosting/upgradePremier.svg';
import StarterImg from 'assets/images/hosting/upgradeStarter.svg';

import './_vipRewardsHomePurchaseHostingLightbox.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let VipRewardsHomePurchaseHostingLightbox: React.FC<VipRewardsHomePurchaseHostingLightboxProps> = ({
    initiallySelectedHostingType,
    initialPrimaryDomain,
    ...props
}) => {
    const {
        hosting_upgrade_list_status,
        hosting_upgrade_list_data,
        hosting_resource_config_status,
        domain_add_hosting_calculate_status,
        domain_add_hosting_calculate_data,
        hosting_create_cart_status,
        hosting_add_best_promo_status,
        hosting_create_order_status,
        formValues,
        getHostingProducts,
        getHostingResourceConfig,
        calculateAddHostingCost,
        createCart,
        createOrder,
        addBestPromo
    } = props as any;

    /***** HOOKS *****/
    const history = useHistory();

    const {
        selectedConfigs: customHostingSelectedConfigs,
        setSelectedConfigs: setCustomHostingSelectedConfigs,
        availableConfigs: customHostingAvailableConfigs,
        labels: customHostingLabels
    } = useCustomHostingConfigs();

    /***** STATE *****/
    const [selectedPlanName, setSelectedPlanName] = useState(getInitiallySelectedPlanName(initiallySelectedHostingType));

    /***** FUNCTIONS *****/
    function changeActiveTab(tab) {
        switch (tab) {
            case hostingTypes.CUSTOM:
                setSelectedPlanName(planNames.CUSTOM);
                break;
            case hostingTypes.WORDPRESS:
                setSelectedPlanName(planNames.WORDPRESS);
                break;
            default:
                setSelectedPlanName(planNames.FREEDOM_PLUS);
                break;
        }
    }

    function getPricePreview() {
        if (activeTab === hostingTypes.BUSINESS || activeTab === hostingTypes.WORDPRESS) {
            const product = hosting_upgrade_list_data?.find((product) => getLowerCasePlanNameFromProduct(product) === selectedPlanName);
            const productId = product?.id;

            if (!productId) {
                return;
            }

            const config = product?.attributes?.config || [];

            const attributes = {
                product_id: productId,
                config,
                billing_cycle: formValues?.billing_cycle || ''
            };

            return calculateAddHostingCost(productId, attributes);
        }

        if (activeTab === hostingTypes.CUSTOM) {
            const productId = hosting_upgrade_list_data?.[0]?.id;

            if (!productId) {
                return;
            }

            const attributes = {
                product_id: productId,
                config: customHostingSelectedConfigs,
                billing_cycle: formValues?.billing_cycle || ''
            };

            return calculateAddHostingCost(productId, attributes);
        }
    }

    function submitOrder(values) {
        function placeOrder(items) {
            createCart(items, {
                onSuccess: (createCartData) => {
                    addBestPromo(createCartData.token, {
                        onSuccess: () => {
                            // Stop the price calculate request firing again after order success. Store the plan name so it can be reset again if order requests errors
                            const frozenSelectedPlanName = selectedPlanName;
                            setSelectedPlanName(null);

                            createOrder(createCartData.token, {
                                onSuccess: (createOrderData) => {
                                    gaPurchaseEvent({
                                        invoiceId: createOrderData?.invoice_id,
                                        item_brand: 'Select Hosting',
                                        item_category: 'shared_hosting'
                                    });

                                    pushNotification({ details: 'Order successfully completed.', status: 200 });

                                    history.push(`/billing/invoice/${createOrderData?.invoice_id}/pay`);
                                },
                                onError: () => {
                                    setSelectedPlanName(frozenSelectedPlanName);
                                }
                            });
                        }
                    });
                }
            });
        }

        if (activeTab === hostingTypes.BUSINESS || activeTab === hostingTypes.WORDPRESS) {
            const product = hosting_upgrade_list_data?.find((product) => getLowerCasePlanNameFromProduct(product) === selectedPlanName);
            const productId = product?.id;
            const config = product?.attributes?.config || [];

            const items = [
                {
                    id: productId,
                    billing_cycle_id: domain_add_hosting_calculate_data?.billing_cycle_id,
                    name: values?.domain || '',
                    addons: [],
                    config
                }
            ];

            return placeOrder(items);
        }

        if (activeTab === hostingTypes.CUSTOM) {
            const productId = hosting_upgrade_list_data?.[0]?.id;

            const items = [
                {
                    id: productId,
                    billing_cycle_id: domain_add_hosting_calculate_data?.billing_cycle_id,
                    name: values?.domain || '',
                    addons: [],
                    config: customHostingSelectedConfigs
                }
            ];

            return placeOrder(items);
        }
    }

    /***** EFFECTS *****/
    // Fetch product data on mount
    useEffect(() => {
        // Pre-select a plan once the available plans have loaded
        getHostingProducts('shared');
        getHostingResourceConfig();
    }, []);

    // Re-calculate pricing when plan or billing cycle changes
    useEffect(() => {
        if (formValues?.billing_cycle && selectedPlanName && (customHostingSelectedConfigs || selectedPlanName !== planNames.CUSTOM)) {
            getPricePreview();
        }
    }, [formValues?.billing_cycle, selectedPlanName, customHostingSelectedConfigs]);

    /***** RENDER HELPERS *****/
    // Derive the active tab based on which plan is selected
    function getActiveTab() {
        switch (selectedPlanName) {
            case planNames.CUSTOM:
                return hostingTypes.CUSTOM;
            case planNames.WORDPRESS:
                return hostingTypes.WORDPRESS;
            default:
                return hostingTypes.BUSINESS;
        }
    }

    const activeTab = getActiveTab();

    const renderPlans = () => {
        const renderOnePlan = (planData) => {
            const { id, attributes } = planData || {};

            if (!id || !attributes) {
                return '';
            }

            const { name, price, details } = attributes;
            const billingCyclePricing = price[formValues?.billing_cycle] || '';

            const lowerCasePlanName = getLowerCasePlanNameFromProduct(planData);

            let image = '';
            let color = '';

            switch (lowerCasePlanName) {
                case planNames.STARTER_PLUS:
                    image = <img src={StarterImg} alt={name} />;
                    color = 'yellow';
                    break;
                case planNames.FREEDOM_PLUS:
                    image = <img src={FreedomImg} alt={name} />;
                    color = 'blue';
                    break;
                case planNames.PREMIER_PLUS:
                    image = <img src={PremierImg} alt={name} />;
                    color = 'pink';
                    break;
                case planNames.WORDPRESS:
                    image = <Icons.WordpressHosting />;
                    color = 'blue';
                    break;
                default:
                    return '';
            }

            return (
                <button
                    className={classNames('vipRewardsHomePurchaseHosting__plan', `vipRewardsHomePurchaseHosting__plan--${color}`, {
                        'vipRewardsHomePurchaseHosting__plan--selected': selectedPlanName === lowerCasePlanName
                    })}
                    type="button"
                    key={lowerCasePlanName}
                    onClick={() => setSelectedPlanName(lowerCasePlanName)}
                >
                    {image}
                    {selectedPlanName === lowerCasePlanName ? <ProgressStepsNode state={nodeStates.COMPLETE} /> : ''}
                    <Text black semiBold size--xl>
                        {name}
                    </Text>
                    {formValues?.billing_cycle ? (
                        <Text className="vipRewardsHomePurchaseHosting__planPrice" primary semiBold size--l>
                            <p>
                                ${billingCyclePricing}
                                {billingCycles[formValues.billing_cycle] || ''}
                            </p>
                        </Text>
                    ) : (
                        ''
                    )}
                    {(details || []).map((detail) => {
                        if (typeof detail !== 'string') {
                            return '';
                        }

                        const split = detail.split(' ');

                        return (
                            <Text key={detail} secondary size--s lead--l>
                                <p>
                                    <span>{split[0] || ''}</span>
                                    {split.slice(1).join(' ') || ''}
                                </p>
                            </Text>
                        );
                    })}
                </button>
            );
        };

        const renderBusinessPlans = () => {
            const businessPlanList = (hosting_upgrade_list_data || []).filter(
                (plan) => getLowerCasePlanNameFromProduct(plan) !== planNames.WORDPRESS
            );

            return businessPlanList.map((planData) => renderOnePlan(planData));
        };

        const renderCustom = () => {
            if (!customHostingSelectedConfigs) {
                return '';
            }

            return (
                <PurchaseCustomHostingResourceSelect
                    billingCycle={formValues?.billing_cycle || ''}
                    labels={customHostingLabels}
                    availableConfigs={customHostingAvailableConfigs}
                    selectedConfigs={customHostingSelectedConfigs}
                    setSelectedConfigs={setCustomHostingSelectedConfigs}
                />
            );
        };

        const renderWp = () => {
            return renderOnePlan((hosting_upgrade_list_data || []).find((plan) => getLowerCasePlanNameFromProduct(plan) === 'wordpress'));
        };

        switch (activeTab) {
            case hostingTypes.BUSINESS:
                return renderBusinessPlans();
            case hostingTypes.CUSTOM:
                return renderCustom();
            case hostingTypes.WORDPRESS:
                return renderWp();
            default:
                return '';
        }
    };

    const renderPricingTable = () => {
        if (domain_add_hosting_calculate_status === 'loading') {
            return <RequestLoader />;
        }

        const isDiscount =
            typeof domain_add_hosting_calculate_data?.discount_amount === 'string' && domain_add_hosting_calculate_data.discount_amount !== '0.00';

        const discountRows = [
            {
                label: 'Subtotal',
                amount: `$${domain_add_hosting_calculate_data?.sub_total || ''}`
            },
            {
                label: 'Discount Amount',
                amount: `$${domain_add_hosting_calculate_data?.discount_amount || ''}`
            }
        ];

        return (
            <PricingTable
                rows={isDiscount ? discountRows : null}
                total={{
                    label: 'Total Due Today',
                    amount: `$${domain_add_hosting_calculate_data?.total || ''}`
                }}
            />
        );
    };

    /***** RENDER *****/
    if (!hosting_upgrade_list_status || !hosting_resource_config_status) {
        return '';
    }

    if ([hosting_upgrade_list_status, hosting_resource_config_status, domain_add_hosting_calculate_status].includes('error')) {
        return <FetchComponentError />;
    }

    if (
        [
            hosting_upgrade_list_status,
            hosting_resource_config_status,
            hosting_create_cart_status,
            hosting_add_best_promo_status,
            hosting_create_order_status
        ].includes('loading')
    ) {
        return <RequestLoader />;
    }

    return (
        <div className="vipRewardsHomePurchaseHosting">
            <div className="vipRewardsHomePurchaseHosting__tabs">
                {Object.values(hostingTypes).map((tab) => (
                    <button
                        type="button"
                        key={tab}
                        className={classNames('vipRewardsHomePurchaseHosting__tab', {
                            'vipRewardsHomePurchaseHosting__tab--active': tab === activeTab
                        })}
                        onClick={() => changeActiveTab(tab)}
                    >
                        <Text secondary={tab !== activeTab} white={tab === activeTab}>
                            {tab}
                        </Text>
                    </button>
                ))}
            </div>

            <div className="vipRewardsHomePurchaseHosting__form">
                <VipRewardsHomePurchaseHostingForm initialPrimaryDomain={initialPrimaryDomain} onSubmit={submitOrder}>
                    <div className="vipRewardsHomePurchaseHosting__plansContainer">
                        <div className="vipRewardsHomePurchaseHosting__plans">{renderPlans()}</div>
                    </div>
                    {renderPricingTable()}
                </VipRewardsHomePurchaseHostingForm>
            </div>
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

const mapStateToProps = (state: any) => {
    const formValues = getFormValues('vipRewardsHomePurchaseHostingForm')(state);

    return {
        hosting_upgrade_list_status: state.hosting.hosting_upgrade_list_status,
        hosting_upgrade_list_data: state.hosting.hosting_upgrade_list_data,
        hosting_resource_config_status: state.hosting.hosting_resource_config_status,
        domain_add_hosting_calculate_status: state.domain.domain_add_hosting_calculate_status,
        domain_add_hosting_calculate_data: state.domain.domain_add_hosting_calculate_data,
        hosting_create_cart_status: state.hosting.hosting_create_cart_status,
        hosting_add_best_promo_status: state.hosting.hosting_add_best_promo_status,
        hosting_create_order_status: state.hosting.hosting_create_order_status,
        formValues
    };
};

const mapDispatchToProps = {
    getHostingProducts,
    getHostingResourceConfig,
    calculateAddHostingCost,
    createCart,
    createOrder,
    addBestPromo
};

VipRewardsHomePurchaseHostingLightbox = connect(mapStateToProps, mapDispatchToProps)(VipRewardsHomePurchaseHostingLightbox);

export default VipRewardsHomePurchaseHostingLightbox;
