import { useMutation } from '@tanstack/react-query';
import { orderingFormQueryKeys } from 'containers/orderingForm/queries/queryKeys';
import { ORDERING_API } from 'utilities/api/ordering';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useMutation() {
    return useMutation({
        mutationFn: (id: number) => ORDERING_API.products.POST({ ids: [id] }),
        mutationKey: orderingFormQueryKeys.productsMutation(),
        onSuccess: () => {}
    });
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const mutateGetProducts = Object.freeze({
    useMutation: _useMutation
});
