import type { Payload } from 'containers/orderingForm/types';

//edit item in cart
export function getPayload({ formData, item, customFields }: Payload) {
    const domainContactFields =
        formData.contactDetails === 'newDetails'
            ? {
                firstname: formData.firstName,
                lastname: formData.lastName,
                email: formData.emailAddress,
                phone: formData.phoneNumber.split(' ').join(''),
                companyName: formData.companyName,
                address1: formData.address,
                suburb: formData.suburb,
                state: formData.state,
                postcode: formData.postcode,
                country: formData.country
            }
            : [];

    const custom =
        item.order_type === 'register' && customFields
            ? customFields.map((field) => {
                const name = field.name
                    .split(' ')
                    .map((word: string, index: number) => (index === 0 ? word.toLowerCase() : word))
                    .join('');
                return { id: field?.id, value: formData[name] ?? '' };
            })
            : [];

    const registerPayload = {
        action: 'edit',
        auto_renew: formData.autoRenew,
        id_protection: formData.idProtection,
        billing_cycle_id: Number(formData.registrationPeriod),
        custom_fields: custom,
        domain_contact_fields: domainContactFields,
        id: item.id,
        name: item.name,
        order_type: item.order_type,
        uuid: item.uuid
    };

    const transferPayload = { ...registerPayload, epp: formData?.epp };

    const payload = item.order_type === 'register' ? registerPayload : transferPayload;
    return payload;
}

export function eppValidationMessage(epp_data: { is_transferable: boolean; reason: string }) {
    switch (true) {
        case epp_data?.is_transferable === true:
            return { isSuccess: true, message: 'EPP code is valid' };

        case epp_data?.is_transferable === false &&
            ['epp_invalid', 'all_ready_in_account', 'transfer_lock'].some((str) => epp_data?.reason.includes(str)):
            return { isSuccess: false, message: 'EPP code does not appear to be valid' };

        case epp_data?.is_transferable === false && ['all_ready_in_account', 'transfer_lock'].some((str) => epp_data?.reason.includes(str)):
            return { isSuccess: false, message: 'Domain is already in account' };

        case epp_data?.is_transferable === false && epp_data?.reason.includes('transfer_lock'):
            return { isSuccess: false, message: 'Transfer lock is enabled' };

        default:
            return;
    }
}
