/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import isValidDomain from 'is-valid-domain';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import type { RichTextData } from 'containers/katana/containers/ContentEditorLightbox/methods/processSectionProperties/isRichTextData';
import {
    isRichTextData,
    isTipTapDocumentJSON
} from 'containers/katana/containers/ContentEditorLightbox/methods/processSectionProperties/isRichTextData';
import { regexes } from './regexes';

/**********************************************************************************************************
 *   All validator functions
 **********************************************************************************************************/

/**
 * @typedef {*}ValidatorDeprecation
 * Use the validator Functions to check whether the evaluation is true or false first.
 * Then based on the result interpret a string instead.
 */

/**
 * Checks if the value exists or not false
 * @param value
 * @return {boolean}
 */
export const validatorRequired = (value) => {
    return value || value === false;
};

/**
 * Checks that the value is true
 */
export const validatorCheckboxRequired = (value) => {
    return value === true;
};

/**
 * Checks if the value is on of the accepted values
 * @param value
 * @return {boolean}
 */
export const validatorRequiredAcceptedValidation = (value) => {
    return [1, true, 'yes', 'on'].includes(value);
};

/**
 * Checks if the value is a valid Date Format
 * @param {unknown} value
 * @return {boolean}
 */
export const validatorValidDate = (value) => {
    return value instanceof Date;
};

/**
 * Checks if a date field is in the valid format dd/mm/yyyy
 * @param value
 * @return {boolean}
 */
export const validatorDateField = (value) => {
    return /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{4})$/i.test(
        value
    );
};

/**
 * Checks whether a phone number is valid
 * @param value
 * @return {boolean}
 */
export const validatorPhoneNumber = (value) => {
    return /^(?:\+|\.| |[0-9])*$/.test(value);
};

/**
 * Checks if a phone number starts with australian or New zealand extension
 * @param value
 * @return {boolean}
 */
export const validatorPhoneNumberStartsWithAUNZ = (value) => {
    return /^(\+61)|^(\+64)|^(61)|^(64)|^0/.test(value);
};

/**
 * Checks if the value is not a digit
 * @param value
 * @return {boolean}
 */
export const validatorNonDigit = (value) => {
    return /\D/.test(value);
};

/**
 * Returns a validator function specific for the country
 * @param {string} country
 * @returns {boolean}
 */
export const validatorCountryPostCode = (country, value) => {
    const isAuNZ = country === 'AU' || country === 'NZ';
    return isAuNZ ? /^\d{4}$/i.test(value) : /^[\d\w\s-]{3,10}$/.test(value);
};

/**
 * Checks if the value is a normal name.
 * @param value
 * @return {boolean}
 */
export const validatorNameField = (value) => {
    return /^[a-z ,.'-]+$/i.test(value);
};

/**
 * Checks if the string is a valid second level domain:
 * Correct: ventra-ip, google, amazon123
 * Not: www.ventra-ip, www.ventra-ip.com, ventra-ip.com.au
 * @param {string} value
 * @returns
 */
export const validatorSecondLevelDomain = (value) => {
    return /^[a-z0-9-]+$/i.test(value);
};

/**
 * Check if the value is a valid domain name
 * @param value
 * @return {boolean}
 */
export const validatorDomainName = (value) => {
    return /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/g.test(value);
};

export const validatorSocialMediaURLS = (value) => {
    return /^(https:\/\/|www\.)?([a-zA-Z0-9-]+\.){1,}[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/.test(value);
};

/**
 * Check if the value is a valid domain and doesn't contain a subdomain
 * @param value
 * @return {boolean}
 */
export const validatorDomainNameNoWWW = (value) => {
    return !value.startsWith('www.') && validatorDomainName(value);
};

/**
 * Check if the value in lower case is a valid domain name
 * @param value
 * @return {boolean}
 */
export const validatorDomainNameLowerCased = (value) => {
    return validatorDomainName(value.toLowerCase());
};

/**
 * Checks if the value has a valid http protocol at the start
 * @param value
 * @return {boolean}
 */
export const validatorURLProtocol = (value) => {
    return value.toLowerCase().startsWith('http://') || value.toLowerCase().startsWith('https://');
};

/**
 * Check if value is a VISA card
 * @param value
 * @return {boolean}
 */
export const validatorCardTypeVISA = (value) => {
    return /^4[0-9]{12}(?:[0-9]{3})?$/.test(value);
};

/**
 * Check if value is a MASTERCARD card
 * @param value
 * @return {boolean}
 */
export const validatorCardTypeMASTERCARD = (value) => {
    return /^5[1-5][0-9]{14}$/.test(value);
};

/**
 * Check if value is a AMEX card
 * @param value
 * @return {boolean}
 */
export const validatorCardTypeAMEX = (value) => {
    return /^3[47][0-9]{13}$/.test(value);
};

/**
 * Check if value is a number
 * @param value
 * @return {boolean}
 */
export const validatorNumber = (value) => {
    return /^\d+$/i.test(value);
};

/**
 * Check if the value is Alpha Numeric (a-z, A-Z, 0-9)
 * @param value
 * @return {boolean}
 */
export const validatorAlphaNumeric = (value) => {
    return /^[a-zA-Z0-9]+$/.test(value);
};

/**
 * Check if the value has non-alphanumeric trailing characters
 * @param value
 * @return {boolean}
 */
export const validatorTrailingCharacters = (value) => {
    return /[^a-zA-Z0-9]+$/.test(value);
};

/**
 * Check if the value is a valid BSB number
 * @param value
 * @return {boolean}
 */
export const validatorBSBNumber = (value) => {
    return /^[\d]{3}(-|\s)?[\d]{3}/i.test(value);
};

/**
 * Check if the value is a valid Account number
 * @param value
 * @return {boolean}
 */
export const validatorAccountNumer = (value) => {
    return /^[\d\s]+$/i.test(value);
};

/**
 * Check if the value is a valid email address
 * @param value
 * @return {boolean}
 */
export const validatorEmailAddress = (value) => {
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(
        value
    );
};

/**
 * Object with validation to determine if a string is a valid email prefix
 */
export const validatorEmailPrefix = {
    /**
     * Check that the value is a string and less than 30 characters
     * @param {string} [value]
     * @return {boolean}
     */
    stringLessThan30: (value) => typeof value === 'string' && value.length <= 30,

    /**
     * Check if the value starts with a letter (case insensitive)
     * @param {string} [value]
     * @returns {boolean}
     */
    startsWithLetter: (value) => /^[a-zA-Z]/.test(value),

    /**
     * Check if the value only contains letters, numbers, underscore, dash or dot
     * @param {string} [value]
     * @returns {boolean}
     */
    onlyContainsLettersNumbersUnderscoreDashOrDot: (value) => /^[a-zA-Z0-9._-]+$/.test(value),

    /**
     * Check if the value does not end with a dot
     * @param {string} [value]
     * @returns {boolean}
     */
    doesNotEndInDot: (value) => !/\.$/.test(value),

    /**
     * Check that the value does not have consecutive dots
     * @param {string} [value]
     * @returns {boolean}
     */
    doesNotHaveConsecutiveDots: (value) => !/(\.{2,})/.test(value)
};

/**
 * Check if the value is a valid IPV4 address
 * @param value
 * @return {boolean}
 */
export const validatorIPV4 = (value) => {
    return /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/i.test(
        value
    );
};

/**
 * Check if the value is a valid IPV6 address
 * @param value
 * @return {boolean}
 */
export const validatorIPV6 = (value) => {
    return /(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))/gi.test(
        value
    );
};

/**
 * Check if the value is a valid unix username, ie not starting with numbers and alphanumeric
 * @param value
 * @return {boolean}
 */
export const validatorUNIXUsername = (value) => {
    return /^[a-z][-a-z0-9]*\$/.test(value);
};

/**
 * Validate any string to be a valid phone number
 * @param {string} value
 * @returns {boolean}
 */
export const validatorPhoneNumberEDOT164 = (value) => {
    return /^[\+]?[(]?[0-9]{1,3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(value);
};

/**
 * Validate any string to be a valid abn format
 * @param {string} value
 * @returns {boolean}
 */
export const validatorABNFormat = (value) => {
    return /^(\d *?){11}$/.test(value);
};

/** ACN Weights */
const ACNweights = [8, 7, 6, 5, 4, 3, 2, 1];

/**
 * Validate any string to be valid ACN format
 * @param {string} rawAcn
 * @returns {boolean}
 */
export const validatorACNFormat = (rawAcn) => {
    if (!rawAcn) {
        return false;
    }

    // strip non-alphanumeric characters
    const acn = rawAcn.toString().replace(/[^a-z\d]/gi, '');

    // check if length is 9 digits
    if (acn.length !== 9) {
        return false;
    }

    // apply ato check method
    let sum = 0;
    for (let position = 0; position < ACNweights.length; position += 1) {
        const weight = ACNweights[position];
        const digit = parseInt(acn[position], 10);
        sum += weight * digit;
    }

    const checksum = (10 - (sum % 10)) % 10;

    return checksum === parseInt(acn[8], 10);
};

export const validatorABNorACN = (value) => {
    return validatorABNFormat(value) || validatorACNFormat(value);
};

/**
 * Check if the domain is valid when it's check allows subdomains, and not valid when it doesn't allow subdomains, that indicates that there's a sub domain.
 * @param {string} value
 * @returns {boolean}
 */
export const validatorDomainHasSubdomain = (value) => {
    return isValidDomain(value, { subdomain: true }) && !isValidDomain(value, { subdomain: false });
};

export const validatorDomainAndSubDomain = (value) => {
    return isValidDomain(value, { subdomain: true });
};

/**
 * Provide a list of single characters to match against within a string.
 * Useful for validating a string contains at least one of a set of characters.
 *
 * @param {string} value
 * @param {string[]} characters - Array of characters to match
 * @returns
 */
export function validatorCustomCharacters(value, characters) {
    const regex = RegExp(
        characters.reduce((prev, curr, index) => {
            const combined = prev + curr;
            return index === characters.length - 1 ? combined + '].*$' : combined;
        }, '.*[')
    );

    return regex.test(value);
}

/**
 * Matches the subdomain domain section of a domain.
 *
 * This has been split into separate checks to simplify the regex. (and because negativelookbehinds do not have full browser support)
 */
export const validatorDomainSubDomainCharacters = (value) => {
    // Basic Match
    if (!/^([a-zA-Z](?!.*-\.)[a-zA-Z\d-]*\.?)*[a-zA-Z\d]$/g.test(value)) return false;

    // Cannot have digit directly after dot
    if (/\.\d/g.test(value)) return false;

    //cannot be just a digit
    if (/^\d$/g.test(value)) return false;

    return true;
};

export function validatorLowerLettersAndDigits(value) {
    return /^[a-z0-9]+$/.test(value);
}

export function validatorCPanelStartSpecs(value) {
    return /^(?![0-9]|test).*$/.test(value);
}

/**
 * @param {unknown} value
 * @param {number} minLength
 * @returns {boolean}
 */
export function validatorMinArrayLength(value, minLength) {
    return Array.isArray(value) && value.length >= minLength;
}
export function validatorMinLength(value, minLength) {
    return typeof value === 'string' && value.length >= minLength;
}

export function validatorMaxLength(value, maxLength) {
    return typeof value === 'string' && value.length <= maxLength;
}

export function validatorLengthBetween(value, min, max) {
    return validatorMinLength(value, min) && validatorMaxLength(value, max);
}

export function validatorUpperAndLowerRegexTest(value) {
    return /^(?=.*[A-Z])(?=.*[a-z]).*$/.test(value);
}

export function validatorNumberCheck(value) {
    return /^.*[0-9].*$/.test(value);
}

export function validatorSpecialCharRegexTest(value) {
    return /^.*(?=.*[/_\-*@#$%!^&+="'();:?><,.~|[\]\\`{}]).*$/.test(value);
}

export function validatorSpecialCharAxigenRegexTest(value) {
    return /^.*(?=.*[\/_\-*@%!^&+="'();:?><,.~|\\`{}]).*$/.test(value);
}

export function validatorSpecialCharOrNumberRegexTest(value) {
    return /^.*([/_/-/*@#$%!^&+=])|(?=.*[0-9]).*$/.test(value);
}

export function validatorExcludeDoubleQuotes(value) {
    return value === undefined || value?.includes('"') === false;
}

export function validatorExcludeSingleQuotes(value) {
    return value === undefined || value?.includes("'") === false;
}

export function validatorExcludeBackticks(value) {
    return value === undefined || value?.includes('`') === false;
}

export function validatorExcludeBackslashes(value) {
    return value === undefined || value?.includes('\\') === false;
}

export function validatorExcludeCurlyBraces(value) {
    return value === undefined || (value?.includes('{') === false && value?.includes('}') === false);
}

export function validatorExcludesSquareBraces(value) {
    return value === undefined || (value?.includes('[') === false && value?.includes(']') === false);
}

export function validatorExcludeAmpersands(value) {
    return value === undefined || value?.includes('&') === false;
}

export function validatorExcludeWhitespace(value) {
    return value === undefined || value?.includes(' ') === false;
}

export function validatorExcludePercentages(value) {
    return value === undefined || value?.includes('%') === false;
}

export function validatorExcludeDollar(value) {
    return value === undefined || value?.includes('$') === false;
}

export function validatorExcludeHash(value) {
    return value === undefined || value?.includes('#') === false;
}

export function validatorExcludeAxigen(value) {
    return (
        validatorExcludeDoubleQuotes(value) &&
        validatorExcludeSingleQuotes(value) &&
        validatorExcludeBackticks(value) &&
        validatorExcludeBackslashes(value) &&
        validatorExcludeCurlyBraces(value) &&
        validatorExcludeAmpersands(value) &&
        validatorExcludeWhitespace(value) &&
        validatorExcludePercentages(value) &&
        validatorExcludeDollar(value) &&
        validatorExcludeHash(value) &&
        validatorExcludesSquareBraces(value)
    );
}

export const validatorRegularCharactersSpacesHyphensApostrophesAccents = (value) => {
    return regexes.regularCharactersSpacesHyphensApostrophesAccentsNegate().test(value);
};

export function validatorASCII(value) {
    return regexes.ASCIINegate().test(value);
}

export type MinMaxValidationValue = number | string | Array<unknown> | File | RichTextData;

export function validateMinimum(value: MinMaxValidationValue, minValue: number) {
    if (isRichTextData(value) || isTipTapDocumentJSON(value)) {
        return JSON.stringify(value).length >= minValue;
    }

    if (typeof value === 'string' || Array.isArray(value)) {
        return value.length >= minValue;
    }

    if (typeof value === 'number') {
        return value >= minValue;
    }

    if (value instanceof File) {
        // Assuming 'value' is a File object, you can validate its size if needed
        // For simplicity, let's just return true here
        return true;
    }

    // Unsupported type, you might want to handle this differently based on your requirements
    return false;
}

export function validateMaximum(value: MinMaxValidationValue, maxValue: number) {
    if (isRichTextData(value) || isTipTapDocumentJSON(value)) {
        return JSON.stringify(value).length <= maxValue;
    }

    if (typeof value === 'string' || Array.isArray(value)) {
        return value.length <= maxValue;
    }

    if (typeof value === 'number') {
        return value <= maxValue;
    }

    if (value instanceof File) {
        // Assuming 'value' is a File object, you can validate its size if needed
        // For simplicity, let's just return true here
        return true;
    }

    // Unsupported type, you might want to handle this differently based on your requirements
    return false;
}
