// Mutations will go here
/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useMutation } from '@tanstack/react-query';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import { queryClient } from 'store/queryClient';
import { API } from 'utilities/api/services';
import { createBaseQueryKey } from 'utilities/methods/tanstack/createBaseQueryKey';
import type { NXQueryUtils } from 'utilities/methods/tanstack/types';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type UseMutationOpts<TContext> = NXQueryUtils.TMutationOptions<(args: { serviceId: number; attributes: unknown }) => Promise<unknown>, TContext>;

/**********************************************************************************************************
 *   Cancel a service
 **********************************************************************************************************/
const mutationKey = ['useCancelServiceMutation'];

/**
 * Resets all queries that contain the cancelled serviceId
 */
const defaultOnSuccess = (_: unknown, { serviceId }: { serviceId: number }) => {
    queryClient.resetQueries({
        queryKey: createBaseQueryKey(),
        predicate: (query) => query.queryKey.includes(serviceId)
    });
};

/**
 * Invalidates the katana site list
 */
const defaultOnSettled = () => katanaQuery.getSiteList.invalidateQueries();

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
const _useMutation = <TContext>(options: UseMutationOpts<TContext> = {}) => {
    /***** QUERIES *****/
    return useMutation({
        mutationKey,
        mutationFn: ({ serviceId, attributes }) => API.service.post.cancel(serviceId, attributes),
        onSuccess: defaultOnSuccess,
        onSettled: defaultOnSettled,
        ...options
    });
};

export const cancel = Object.freeze({
    useMutation: _useMutation,
    mutationKey,
    defaults: {
        onSuccess: defaultOnSuccess,
        onSettled: defaultOnSettled
    }
});
