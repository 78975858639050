import { KatanaPropertyContext } from 'containers/katana/components/dynamicFormFieldRenderer/PropertyContext/consts';
import { useContext } from 'react';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Rather than prop drilling `property` everywhere, this hook allows us to access the property from the context which should hopefully reduce the amount of prop drilling and make the code cleaner and easier to read.
 *
 * The property gets assigned to a context from the `PropertiesRenderer` component.
 */
export function useKatanaProperty() {
    const property = useContext(KatanaPropertyContext);
    if (!property) {
        throw new Error('useKatanaProperty must be used within a KatanaPropertyContext.Provider');
    }
    return property;
}
