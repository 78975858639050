import type { PropInjectorProps } from 'components/Utils/PropInjector/types';
import { injectChildPropsByRecord } from 'utilities/methods/reactChildren';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const PropInjector: React.FC<PropInjectorProps> = (props) => {
    const { children, injectableProps, inject, injectable } = props;

    /***** RENDER *****/
    if (inject && injectableProps) {
        const injectedChildren = injectChildPropsByRecord(injectable, injectableProps);
        if (injectedChildren) {
            return injectedChildren;
        }
    }
    return children;
};
