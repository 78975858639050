/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import htmr from 'htmr';
import { Component, createRef } from 'react';
import { connect } from 'react-redux';
import Tooltip from 'react-tooltip-lite';
import { withRouter } from 'utilities/methods/tanstack/router/withRouter';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { getAlerts } from '../../action';

/*   RESET FUNCTIONS
 **********************************************************************************************************/
import { resetDomainsState } from 'containers/domain/action';
import { resetEmailState } from 'containers/email/action';
import { resetHostingAccount } from 'containers/hosting/state/accountActions';
import { resetHostingAdmin } from 'containers/hosting/state/adminActions';
import { resetHostingConfiguration } from 'containers/hosting/state/configActions';
import { resetResellerState } from 'containers/hosting/state/resellerActions';
import { resetHostingSecurity } from 'containers/hosting/state/securityActions';
import { expiryTextFormatter, getCurrentDate, toLuxonDate } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_alerts.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class Alerts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expiredDomains: null,
            expiringDomains: null,
            suspendedWebHosting: null,
            suspendedVps: null,
            domainsPendingInfo: null,
            overdueInvoice: null,
            dueInvoices: null,
            showAlertDropdown: false,
            showAlertCount: 0
        };
        this.toggleAlertDropdown = this.toggleAlertDropdown.bind(this);
        this.getAlertAmount = this.getAlertAmount.bind(this);
        this.clickAway = this.clickAway.bind(this);

        this.buttonRef = createRef();
    }

    toggleAlertDropdown() {
        const { showAlertDropdown } = this.state;

        this.setState({
            showAlertDropdown: !showAlertDropdown
        });
    }

    getAlertAmount() {
        const { dashboard_alerts_data } = this.props;

        let numberOfAlerts = 0;
        const processAlerts = (data) => {
            Object.keys(data).forEach((key) => {
                if (
                    (key === 'expiredDomains' && data[key] && data[key] > 0) ||
                    (key === 'domainsPendingInfo' && data[key] && data[key] > 0) ||
                    (key === 'expiringDomains' && data[key] && data[key].length > 0) ||
                    (key === 'suspendedWebHosting' && data[key] && data[key].length > 0) ||
                    (key === 'suspendedVps' && data[key] && data[key].length > 0) ||
                    (key === 'overdueInvoice' && data[key] && data[key] > 0)
                ) {
                    if (key === 'suspendedWebHosting' || key === 'suspendedVps' || key === 'expiredDomains' || key === 'expiringDomains') {
                        numberOfAlerts = numberOfAlerts + data[key].length;
                    } else {
                        numberOfAlerts++;
                    }
                }
            });
        };

        processAlerts(dashboard_alerts_data);

        return numberOfAlerts;
    }

    componentDidMount() {
        const { getAlerts } = this.props;
        const { clickAway } = this;

        getAlerts();

        document.addEventListener('click', clickAway);
    }

    componentWillUnmount() {
        const { clickAway } = this;

        document.removeEventListener('click', clickAway);
    }

    clickAway(e) {
        const { buttonRef } = this;

        if (buttonRef.current && !buttonRef.current.contains(e.target)) {
            this.setState({
                showAlertDropdown: false
            });
        }
    }

    componentDidUpdate(prevProps) {
        const { dashboard_alerts_status, dashboard_alerts_data } = this.props;
        const { getAlertAmount } = this;

        if (dashboard_alerts_status === 'success' && prevProps.dashboard_alerts_status === 'loading') {
            this.setState({
                ...dashboard_alerts_data,
                showAlertCount: getAlertAmount()
            });
        }
    }

    render() {
        const {
            resetDomainsState,
            resetHostingAccount,
            resetHostingAdmin,
            resetHostingConfiguration,
            resetResellerState,
            resetHostingSecurity,
            resetEmailState,
            history
        } = this.props;

        const {
            expiredDomains,
            expiringDomains,
            suspendedWebHosting,
            suspendedVps,
            domainsPendingInfo,
            overdueInvoice,
            showAlertDropdown,
            showAlertCount
        } = this.state;

        const { toggleAlertDropdown, buttonRef } = this;

        /*   RENDER ITEMS
         **********************************************************************************************************/
        const handleAlertItem = (data) => {
            const { key, status, message, action } = data;
            return (
                <div key={key} className={`alert ${status ? status : ''}`}>
                    <button
                        className="alert__message"
                        onClick={(e) => {
                            action.onClick(e);
                            toggleAlertDropdown();
                        }}
                    >
                        {htmr(message)}
                    </button>
                </div>
            );
        };

        /*   PROCESS ALERTS
         **********************************************************************************************************/
        const handleAlertRender = () => {
            function expiredDomainsMapperWarn(alert, index) {
                const { attributes } = alert;
                const { domain } = attributes;
                const data = {
                    key: `expiredDomains-${index}`,
                    status: `warn`,
                    icon: `instant-activation`,
                    message: `Your domain name <span className='highlight'>${domain}</span> has expired.`,
                    action: {
                        label: 'Renew Now',
                        color: 'warn',
                        onClick: (e) => {
                            e.preventDefault();
                            resetDomainsState();
                            history.push(`/my-services/domains/${alert.id}/general#renew`);
                        }
                    }
                };
                return handleAlertItem(data);
            }

            function expiredDomainsMapperInfo(alert, index) {
                const { attributes } = alert;
                const { domain, expiry_date } = attributes;

                const expiry = parseInt(
                    getCurrentDate().diff(toLuxonDate(expiry_date, 'yyyy-MM-dd').setZone('Australia/Sydney'), 'days').toObject()['days']
                );

                let text = `Your domain name <span className='highlight'>${domain}</span> is going to expire ${expiryTextFormatter(expiry)}`;

                if (expiry === -1) {
                    text = `Your domain name <span className='highlight'>${domain}</span> is going to expire in 1 day.`;
                }

                if (expiry === 0) {
                    text = `Your domain name <span className='highlight'>${domain}</span> is going to expire today.`;
                }

                const data = {
                    key: `expiringDomains-${index}`,
                    status: 'info',
                    icon: `instant-activation`,
                    message: text,
                    action: {
                        label: 'Renew Now',
                        color: 'info',
                        style: 'outline',
                        onClick: (e) => {
                            e.preventDefault();
                            resetDomainsState();
                            history.push(`/my-services/domains/${alert.id}/general#renew`);
                        }
                    }
                };
                return handleAlertItem(data);
            }

            function expiredDomainsMapperNotice(alert, index) {
                const { attributes } = alert;
                const { domain } = attributes;
                const data = {
                    key: `suspendedWebHosting-${index}`,
                    status: 'notice',
                    icon: `instant-activation`,
                    message: `Your hosting service <span className='highlight'>${domain}</span> is currently suspended.`,
                    action: {
                        label: 'Manage',
                        color: 'notice',
                        style: 'outline',
                        onClick: (e) => {
                            e.preventDefault();
                            resetHostingAccount();
                            resetHostingAdmin();
                            resetHostingConfiguration();
                            resetResellerState();
                            resetHostingSecurity();
                            resetEmailState();
                            history.push(`/my-services/hosting`);
                        }
                    }
                };
                return handleAlertItem(data);
            }

            function suspendVPSMapper(alert, index) {
                const { attributes } = alert;
                const { domain } = attributes;
                const data = {
                    key: `suspendedVps-${index}`,
                    status: 'notice',
                    icon: `instant-activation`,
                    message: `Your virtual private server <span className='highlight'>${domain}</span> is currently suspended.`,
                    action: {
                        label: 'Manage',
                        color: 'notice',
                        style: 'outline',
                        onClick: (e) => {
                            e.preventDefault();
                            history.push(`/my-services/vps/${alert.id}/account#overview`);
                        }
                    }
                };
                return handleAlertItem(data);
            }

            const alertItemHandlerDataManage = {
                key: `expiringDomains`,
                status: 'info',
                icon: `instant-activation`,
                message: `You have <span className='highlight'>${expiringDomains.length}</span> expiring domains.`,
                action: {
                    label: 'Manage',
                    color: 'notice',
                    style: 'outline',
                    onClick: (e) => {
                        e.preventDefault();
                        resetDomainsState();
                        history.push(`/my-services/domains`);
                    }
                }
            };

            const alertItemHandlerDataInfo = {
                key: `domainsPendingInfo`,
                status: 'info',
                icon: `instant-activation`,
                message: `You have <span className='highlight'>${domainsPendingInfo}</span> domain(s) that require your attention`,
                action: {
                    label: 'Manage',
                    color: 'notice',
                    style: 'outline',
                    onClick: (e) => {
                        e.preventDefault();
                        resetDomainsState();
                        history.push(`/my-services/domains`);
                    }
                }
            };

            const alertItemHandlerDataWarn = {
                key: `suspendedWebHosting`,
                status: 'warn',
                icon: `instant-activation`,
                message: `You have <span className='highlight'>${suspendedWebHosting.length}</span> suspended hosting services.`,
                action: {
                    label: 'Manage',
                    color: 'warn',
                    onClick: (e) => {
                        e.preventDefault();
                        resetHostingAccount();
                        resetHostingAdmin();
                        resetHostingConfiguration();
                        resetResellerState();
                        resetHostingSecurity();
                        resetEmailState();
                        history.push(`/my-services/hosting`);
                    }
                }
            };

            const alertItemHandlerVPSwarn = {
                key: `suspendedVps`,
                status: 'warn',
                icon: `instant-activation`,
                message: `You have <span className='highlight'>${suspendedWebHosting.length}</span> suspended virtual private servers.`,
                action: {
                    label: 'Manage',
                    color: 'warn',
                    onClick: (e) => {
                        e.preventDefault();
                        history.push(`/my-services/vps`);
                    }
                }
            };

            const alertItemHandlerOverdueInvoiceNotice = {
                key: 'overdueInvoice',
                status: 'notice',
                icon: `doc`,
                message: `You have an overdue invoice.`,
                action: {
                    label: 'Manage Invoices',
                    color: 'notice',
                    style: 'outline',
                    onClick: (e) => {
                        e.preventDefault();
                        history.push(`/billing/invoices`);
                    }
                }
            };

            const alertItemHandlerOverdueInvoiceWarn = {
                key: 'overdueInvoices',
                status: 'warn',
                icon: `dns-records`,
                message: `You have <span className='highlight'>${overdueInvoice}</span> overdue invoices`,
                action: {
                    label: 'Manage Invoices',
                    color: 'warn',
                    onClick: (e) => {
                        e.preventDefault();
                        history.push(`/billing/invoices`);
                    }
                }
            };

            function renderExpiringDomains() {
                if (expiringDomains && expiringDomains.length <= 5) {
                    return expiringDomains.map(expiredDomainsMapperInfo);
                } else if (expiringDomains && expiringDomains.length > 5) {
                    return handleAlertItem(alertItemHandlerDataManage);
                }
                return '';
            }

            function renderSuspendedWebHosting() {
                if (suspendedWebHosting && suspendedWebHosting.length <= 5) {
                    return suspendedWebHosting.map(expiredDomainsMapperNotice);
                } else if (suspendedWebHosting && suspendedWebHosting.length >= 5) {
                    return handleAlertItem(alertItemHandlerDataWarn);
                }
                return '';
            }

            function renderSuspendedVPS() {
                if (suspendedVps && suspendedVps.length <= 5) {
                    return suspendedVps.map(suspendVPSMapper);
                } else if (suspendedVps && suspendedVps.length >= 5) {
                    return handleAlertItem(alertItemHandlerVPSwarn);
                }
                return '';
            }

            function renderOverdueInvoice() {
                if (overdueInvoice && overdueInvoice === 1) {
                    return handleAlertItem(alertItemHandlerOverdueInvoiceNotice);
                } else if (overdueInvoice && overdueInvoice > 1) {
                    return handleAlertItem(alertItemHandlerOverdueInvoiceWarn);
                }
                return '';
            }

            function iconHeaderNotificationClick(e) {
                e.preventDefault();
                toggleAlertDropdown();
            }

            return (
                <Tooltip
                    className="alerts"
                    isOpen={showAlertDropdown}
                    direction="down-end"
                    distance={0}
                    arrowSize={0}
                    content={
                        <div className="alerts__container">
                            {expiredDomains ? expiredDomains.map(expiredDomainsMapperWarn) : ''}
                            {domainsPendingInfo && domainsPendingInfo > 0 ? handleAlertItem(alertItemHandlerDataInfo) : ''}
                            {renderExpiringDomains()}
                            {renderSuspendedWebHosting()}
                            {renderSuspendedVPS()}
                            {renderOverdueInvoice()}
                        </div>
                    }
                >
                    <button ref={buttonRef} type="button" onClick={iconHeaderNotificationClick}>
                        <i className="icon icon-header-notif-alt">
                            {showAlertCount > 0 ? <div className="alerts__count">{showAlertCount}</div> : ''}
                        </i>
                    </button>
                </Tooltip>
            );
        };

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return showAlertCount > 0 ? handleAlertRender() : '';
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => {
    return {
        dashboard_alerts_status: state.dashboard.dashboard_alerts_status,
        dashboard_alerts_data: state.dashboard.dashboard_alerts_data
    };
};

const mapDispatchToProps = {
    getAlerts,
    resetDomainsState,
    resetHostingAccount,
    resetHostingAdmin,
    resetHostingConfiguration,
    resetResellerState,
    resetHostingSecurity,
    resetEmailState
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Alerts));
