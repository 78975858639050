import classNames from 'classnames';
import { useRevealer } from 'components/Revealer/hooks/useRevealer';
import React from 'react';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type _List = React.FC<{
    children: React.ReactNode;
    childHolderRef: React.RefObject<HTMLDivElement>;
    overflow?: 'hidden' | 'visible';
}>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

/**
 * List component for the dropdown.
 */
const _List: _List = ({ children, childHolderRef, overflow = 'hidden' }) => {
    /***** HOOKS *****/
    const { isOpen } = useRevealer();

    /***** RENDER *****/
    return (
        <div
            className={classNames('RevealerListContainer', {
                'RevealerListContainer--open': isOpen
            })}
        >
            <div className="RevealerListContainer__childHolder" style={{ overflow }} ref={childHolderRef}>
                {children}
            </div>
        </div>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { _List };
