import { _useQueryParamSearch } from 'components/Search/useQueryParamSearch';
import SolidTag from 'components/Tags/SolidTag';
import { useStateEffect } from 'utilities/hooks/useStateEffect';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type NXSearch = React.FC<{
    /**
     * Additional class to give to the search component
     */
    className?: string;

    /**
     * Placeholder text for the search input
     */
    placeholder: string;

    /**
     * Value of the search input
     * @default ""
     */
    value?: string;

    /**
     * Function to reset the search input
     */
    onReset: () => void;

    /**
     * Function to handle the search input value change
     */
    onChange: (value: string) => void;

    /**
     * Intrinsic input props to pass to the input element
     */
    intrinsic?: Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value' | 'className' | 'placeholder'>;
}>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Standard Search component.
 *
 * This is a stripped down version of the <Search /> component which can have functionality extended as needed in a cleaner more composable
 * way. For example, if / when a dropdown for the search is needed, this can be added as a compound component and manually added.
 *
 * @note This component stores an internal state so that the field updates immediately on keystroke (but will remain in sync with the externally provided
 * value when it updates), it is recommended to use a debounced onChange function so that searches are not made on every keystroke.
 */
const _NXSearch: NXSearch = ({ className, placeholder, value = '', onReset, onChange, intrinsic }) => {
    /***** STATE *****/
    // internal state is required so that the search input is instant, even if the parent component is debounced.
    // useStateEffect will ensure that when the parent does update the value, the internal value will update as well.
    const [internalValue, setInternalValue] = useStateEffect(value);

    /***** RENDER *****/
    return (
        <div className={className}>
            <div className="search__form">
                <input
                    type="text"
                    value={internalValue}
                    onChange={(e) => {
                        setInternalValue(e.target.value);
                        onChange(e.target.value);
                    }}
                    placeholder={placeholder}
                    {...intrinsic}
                />
                {value ? (
                    <button type="button" onClick={() => onReset()}>
                        <SolidTag color="info">Clear</SolidTag>
                    </button>
                ) : (
                    <span className="icon icon-search"></span>
                )}
            </div>
        </div>
    );
};

export const NXSearch = Object.assign(_NXSearch, {
    useQueryParamSearch: _useQueryParamSearch
});
