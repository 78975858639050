/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Flex } from 'components/Utils/Flex';
import { WrapWithComponent } from 'components/WrapWithComponent';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import BackgroundImage from 'components/BackgroundImage';
import './__ServiceOverviewBanner.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {NServiceOverviewBanner.ServiceOverviewBanner}
 */
const _ServiceOverviewBanner = ({ children, className, slim, ...props }) => {
    /***** HOOKS *****/
    const isMedium = useAppViewport('md');
    const isMobile = useAppViewport(['xs', 'sm']);

    /***** RENDER HELPERS *****/
    const outerClassName = classNames('NXServiceOverviewBanner', className, {
        'NXServiceOverviewBanner--medium': isMedium,
        'NXServiceOverviewBanner--mobile': isMobile,
        'NXServiceOverviewBanner--slim': slim,
    });

    /***** RENDER *****/
    return (
        <div className={outerClassName}>
            <WrapWithComponent wrap={props.type !== 'manual'} component={BackgroundImage} cover imageURL={'src' in props && props.src}>
                <Flex fullHeight fullWidth justify="center" align="center" className="NXServiceOverviewBanner__children">
                    {children}
                </Flex>
            </WrapWithComponent>
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { _ServiceOverviewBanner };
