// Mutations will go here
/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useMutation } from '@tanstack/react-query';
import { useNXQuery } from 'components/NXQueryProvider';
import { API } from 'utilities/api/services';
import { useDefaultQueryClientOptions } from 'utilities/hooks/tanstack-query/useDefaultQueryClientOptions';
import type { NXQueryUtils } from 'utilities/methods/tanstack/types';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Endpoint = typeof API.service.service_id.cancellation.DELETE;
type Params = Parameters<Endpoint>[0];
type UseMutationOpts<TContext> = NXQueryUtils.TMutationOptions<Endpoint, TContext>;

/**********************************************************************************************************
 *   Cancel a service
 **********************************************************************************************************/
const mutationKey = ['useRevertCancelServiceMutation'];

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Returns an onSuccess function that would normally be called by the useMutation. This way, the default onSuccess can
 * be called and then custom behaviour can also be added.
 */
const useDefaultOnSuccess = () => {
    const [, { onSuccess }] = useDefaultQueryClientOptions();
    const NXQuery = useNXQuery();

    /**
     * Resets all queries that contain the cancelled serviceId and calls the default onSuccess
     * function from the queryClient (toast notification)
     */
    return (data: unknown, serviceId: any, context: unknown) => {
        onSuccess?.(data, serviceId, context);
        NXQuery.hosting.invalidate(serviceId);
    };
};

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
const _useMutation = <TContext>(options: UseMutationOpts<TContext> = {}) => {
    /***** HOOKS *****/
    const onSuccess = useDefaultOnSuccess();

    /***** QUERIES *****/
    return useMutation({
        mutationKey,
        mutationFn: API.service.service_id.cancellation.DELETE,
        onSuccess,
        ...options
    });
};

export const revertCancellation = Object.freeze({
    useMutation: _useMutation,
    mutationKey,
    defaults: {
        useOnSuccess: useDefaultOnSuccess
    }
});
