import { redirect, type NavigateFn } from '@tanstack/react-router';
import { APP_MOUNTING } from 'App/actionTypes';
import store from 'store/store';
import { NXQuery } from 'utilities/query';
import { z } from 'zod';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type LoaderOpts = {
    context: 'loader';
    onError?: (error: z.SafeParseReturnType<Schema, Schema>) => void;
    preventSuccessRedirect?: boolean;
};

type ReactOpts = {
    context: 'react';
    dependencies: {
        navigate: NavigateFn;
    };
    preventAppMounting?: boolean;
    preventSuccessRedirect?: boolean;
    onError?: (error: z.SafeParseReturnType<Schema, Schema>) => void;
};

type LoadAppConfigOpts = LoaderOpts | ReactOpts;
type Schema = z.infer<typeof schema>;

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
const schema = z.object({
    status: z.literal(401),
    code: z.string()
});

/**
 * Makes the necessary API calls to login to the app (check_token, account_list, user_data) and redirects to the correct route
 * @authenticated - dashboard
 * @guest - login
 */
export const loadAppConfig = async (opts: LoadAppConfigOpts) => {
    const { dispatch } = store;

    if (opts.context !== 'loader' && !opts.preventAppMounting) {
        dispatch({
            type: APP_MOUNTING,
            app_mounting: true
        });
    }

    const onError = (error: unknown) => {
        const parseResult = schema.safeParse(error);
        if (parseResult.success) {
            if (parseResult.data.code === 'ERR_VERIFICATION_REQUIRED') {
                if (opts.context === 'loader') {
                    throw redirect({
                        to: '/login',
                        search: (search) => ({ ...search, 'login-view': '2fa' })
                    });
                }

                opts.dependencies.navigate({
                    to: '/login',
                    search: (search) => ({ ...search, 'login-view': '2fa' })
                });
            }
        }

        dispatch({
            type: APP_MOUNTING,
            app_mounting: false
        });

        opts.onError?.(parseResult);
    };

    const onSuccess = () => {
        if (opts.preventSuccessRedirect) return;
        if (opts.context === 'loader') {
            throw redirect({ to: '/dashboard' });
        }

        opts.dependencies.navigate({
            to: '/dashboard',
            search: (search) => ({ ...search })
        });
    };

    let success = false;
    try {
        // must invalidate + fetch instead of refetch since the queries may not have been run yet
        // prettier-ignore
        await Promise.all([
            NXQuery.auth.login.checkToken.forceFetch(),
            NXQuery.auth.accountList.forceFetch()
        ]);
        await NXQuery.auth.userData.fetch();

        dispatch({
            type: APP_MOUNTING,
            app_mounting: false
        });

        success = true;
    } catch (error) {
        NXQuery.auth.accountList.remove();
        NXQuery.auth.userData.remove();
        NXQuery.auth.login.checkToken.remove();
        onError(error);
    }

    if (success) {
        onSuccess();
    }
};
