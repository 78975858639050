import { authenticationMutationKeys } from 'containers/login/queries/queryKeys';
import { API } from 'utilities/api/login';
import { createNXMutation } from 'utilities/methods/tanstack/createMutation';

/**********************************************************************************************************
 *   QUERY START
 **********************************************************************************************************/
export const requestDefaultTwoFactor = createNXMutation({
    mutationKey: authenticationMutationKeys.requestDefaultTwoFactor(),
    mutationFn: API.login.twoFactor.requestDefault
});
