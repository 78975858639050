import { _ventraBrand } from 'config/tokens/ventra/brand';
import { _ventraComponent } from 'config/tokens/ventra/component';
import { _ventraPrimitive } from 'config/tokens/ventra/primitive';
import { _ventraSemantic } from 'config/tokens/ventra/semantic';

/***** EXPORTS *****/
export const __DO_NOT_USE_IN_PRODUCTION_BUILD_OR_YOU_WILL_BE_FIRED__VENTRA__CONFIG__ = {
    primitive: _ventraPrimitive,
    semantic: _ventraSemantic,
    component: _ventraComponent,
    brand: _ventraBrand
};
