import { Store } from '@tanstack/react-store';
import type { SuperUserNamespace } from 'components/StaffMenu/SuperUser/types';
import _ from 'lodash';
import { UserPreferences } from 'utilities/UserPreferences';

type SuperUserItemDisplay = Partial<Record<SuperUserNamespace.Key, number>>;
export const superUserSuperActionsState = new Store<Partial<Record<SuperUserNamespace.Key, boolean>>>(
    UserPreferences.getItem('SUPER_USER_LOCKED_ITEMS') || {}
);
export const superUserItemRenderTracker = new Store<SuperUserItemDisplay>({});

/**
 * Given there's changes for the super user action items, we need to update the "SUPER_USER_LOCKED_ITEMS" to reflect this
 */
superUserSuperActionsState.subscribe(() => {
    const lockedItemsRecord = UserPreferences.getItem('SUPER_USER_LOCKED_ITEMS');
    const lockedItemKeys = _.keys(lockedItemsRecord);
    if (!lockedItemKeys.length) return;

    const actionEntries = _.entries(superUserSuperActionsState.state);
    const filteredActionEntries = actionEntries.filter(([key]) => lockedItemKeys.includes(key));

    UserPreferences.setItem('SUPER_USER_LOCKED_ITEMS', Object.fromEntries(filteredActionEntries));
});
