/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useIsMutating } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import InactiveButton from 'components/Buttons/InactiveButton';
import { OutlineButton } from 'components/Buttons/OutlineButton';
import RenderDateTimePicker from 'components/Form/RenderDateTimePicker';
import RequestLoader from 'components/Loaders/Request';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { BULK_EMAIL_INVOICES } from '../queries/invoice';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { RenderSelectField, dateValidation, renderButton, requiredFieldValidation } from 'utilities/methods/form';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let BulkEmailInvoiceForm = ({
    onSubmit,
    
    /**
     * Redux Props
     */
    handleSubmit,
    pristine,
    submitting,
    valid,
    status,
    to_date,
    from_date,
    range_by
}) => {
    /***** STATE *****/
    const [isCSV, setIsCSV] = useState(false);

    /***** QUERIES *****/
    const isBulkEmailInvoicesMutating = useIsMutating({ mutationKey: [BULK_EMAIL_INVOICES] });

    /***** FUNCTIONS *****/
    function handleDownloadToCSV() {
        const attributes = {
            csv: true,
            status,
            range_by,
            from_date,
            to_date
        };

        onSubmit(attributes);
        setIsCSV(true);
    }

    /***** EFFECTS *****/
    useEffect(() => {
        if (!isBulkEmailInvoicesMutating && isCSV) setIsCSV(false);
    }, [isBulkEmailInvoicesMutating]);

    /***** RENDER *****/
    if (isBulkEmailInvoicesMutating) {
        return (
            <div className="bulkEmailInvoice__container bulkEmailInvoice">
                <div className="bulkEmailInvoice__description">
                    <Text align--center>{`We're ${
                        isCSV ? 'getting' : 'sending'
                    } your requested invoices now. This may take some time. Please do not close this window.`}</Text>
                </div>
                <div className="bulkEmailInvoice__loader">
                    <RequestLoader />
                </div>
            </div>
        );
    }

    return (
        <form className="bulkEmailInvoice__container bulkEmailInvoice" onSubmit={handleSubmit}>
            <div className="bulkEmailInvoice__description">
                This tool allows you to email all invoices between specific dates.
                <div>
                    <strong>Please note this will send 1 email per invoice.</strong>
                </div>
            </div>
            <div className="bulkEmailInvoice__input">
                <Field
                    label="Range Date By *"
                    name="range_by"
                    component={RenderSelectField}
                    type="select"
                    validate={requiredFieldValidation}
                    className="form__dropdown"
                    options={[
                        {
                            label: 'Due Date',
                            value: 'due'
                        },
                        {
                            label: 'Date Issued',
                            value: 'issued'
                        }
                    ]}
                />
            </div>
            <div className="bulkEmailInvoice__input">
                <Field
                    label="From Date *"
                    name="from_date"
                    component={RenderDateTimePicker}
                    dateFormat="d/M/yyyy"
                    validate={[requiredFieldValidation, dateValidation]}
                    className="form__textfield"
                    maxDate={to_date}
                />
            </div>
            <div className="bulkEmailInvoice__input">
                <Field
                    label="To Date *"
                    name="to_date"
                    component={RenderDateTimePicker}
                    dateFormat="d/M/yyyy"
                    validate={[requiredFieldValidation, dateValidation]}
                    className="form__textfield"
                    minDate={from_date}
                />
            </div>
            <div className="bulkEmailInvoice__input">
                <Field
                    label="Status *"
                    name="status"
                    component={RenderSelectField}
                    type="select"
                    validate={requiredFieldValidation}
                    className="form__dropdown"
                    options={[
                        {
                            label: 'All',
                            value: 'all'
                        },
                        {
                            label: 'Paid',
                            value: 'paid'
                        },
                        {
                            label: 'Unpaid',
                            value: 'unpaid'
                        }
                    ]}
                />
            </div>
            <div className="bulkEmailInvoice__input form__column--full">
                {renderButton(pristine, submitting, valid, 'Send Bulk Invoice Email', 'primary')}
            </div>
            <div className="bulkEmailInvoice__input form__column--full">
                <div className="bulkEmailInvoice__input--alternative">OR</div>
            </div>
            <div className="bulkEmailInvoice__input form__column--full">
                {status && to_date && from_date && range_by ? (
                    <OutlineButton type="onClick" onClick={handleDownloadToCSV}>
                        Download CSV
                    </OutlineButton>
                ) : (
                    <InactiveButton>Download CSV</InactiveButton>
                )}
            </div>
        </form>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
BulkEmailInvoiceForm = reduxForm({
    form: 'BulkEmailInvoiceForm'
})(BulkEmailInvoiceForm);

const mapStateToProps = (state) => {
    const selector = formValueSelector('BulkEmailInvoiceForm');
    const status = selector(state, 'status');
    const to_date = selector(state, 'to_date');
    const from_date = selector(state, 'from_date');
    const range_by = selector(state, 'range_by');

    return {
        status,
        to_date,
        from_date,
        range_by
    };
};

BulkEmailInvoiceForm = connect(mapStateToProps)(BulkEmailInvoiceForm);

export default BulkEmailInvoiceForm;
