/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { Link } from '@tanstack/react-router';
import MoneyPattern from 'assets/images/dashboard/referral/money-pattern.png';
import classNames from 'classnames';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { trackingParams } from 'router/google';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useVipRewards } from 'containers/vipRewards/hooks';
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { promoBannerDismissKeys } from 'containers/dashboard/Components/consts';
import './_ReferralBanner.scss';
const needsMixBlendModeFallback = !window.getComputedStyle(document.body).mixBlendMode;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const _ReferralBanner = () => {
    /***** HOOKS *****/
    const app_viewport = useAppViewport();
    const { isAtOrAboveTier } = useVipRewards();

    /***** RENDER *****/
    return (
        <div className="referralBanner">
            <div className="referralBanner__info--wrapper">
                <div className="referralBanner__info--title">
                    Refer a Friend to VentraIP Australia and receive {['md', 'sm', 'xs'].includes(app_viewport) ? <br /> : ''}
                    <span className="referralBanner__info--highlight">${isAtOrAboveTier('silver') ? '75' : '50'} CASH</span>
                </div>
                <div className="referralBanner__info--container">
                    <Link
                        className="referralBanner__info--button"
                        to={`/account/general?referral=true&${trackingParams.dashboardBanners.referral}#referral`}
                    >
                        FIND OUT HOW
                    </Link>
                    <div className="referralBanner__info--reward">Get paid for each new customer who purchases a Domain Name and Web Hosting.</div>
                </div>
            </div>
            <img className={classNames('referralBanner__image', { fallback: needsMixBlendModeFallback })} src={MoneyPattern} alt="Cash" />
        </div>
    );
};

export const ReferralBanner = Object.assign(_ReferralBanner, {
    DISMISS_KEY: promoBannerDismissKeys.REFERRAL_DISMISS_KEY
});
