/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { Component } from 'react';
import { connect } from 'react-redux';
import { Field, change, formValueSelector, reduxForm, reset } from 'redux-form';

import { audaPriorityApplicationProcessUrl, audaPriorityTokenRetrievalToolUrl } from 'config/config';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import InactiveButton from 'components/Buttons/InactiveButton';
import { SolidButton } from 'components/Buttons/SolidButton';
import FetchComponentError from 'components/Errors/FetchComponentError';
import RequestLoader from 'components/Loaders/Request';
import PricingTable from 'components/PricingTable';
import OutlineTag from 'components/Tags/OutlineTag';
import HoverTooltip from 'components/Tooltip/HoverTooltip';

/*   ACTIONS
 *****************************************************/
import { ReduxFormButton } from 'components/Form/Button/reduxForm';
import { getContentionDomains, submitApplication, updateApplicationDetails } from 'containers/auDirect/action';
import { ControlledCheckbox, RenderField, RenderSelectField, alphaNumericValidation, requiredFieldValidation } from 'utilities/methods/form';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class AuApplicationForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            step: 'one',
            domains: [],
            dontHoldAny: false
        };

        this.setStep = this.setStep.bind(this);
        this.isApplicationDomainWithUs = this.isApplicationDomainWithUs.bind(this);
        this.toggleDomain = this.toggleDomain.bind(this);
        this.toggleDontHoldAny = this.toggleDontHoldAny.bind(this);
        this.handleApplicationSubmit = this.handleApplicationSubmit.bind(this);
    }

    setStep(step = 'one') {
        this.setState(
            {
                step
            },
            () => {
                const { audirect_contention_domains_data, change, reset } = this.props;
                const { isApplicationDomainWithUs } = this;

                if (step === 'one') return reset();

                if (isApplicationDomainWithUs()) {
                    const { priority_contact_id, priority_authinfo, eligibility_type, eligibility_number } =
                        audirect_contention_domains_data.vip_primary_domain;

                    change('priority_contact_id', priority_contact_id);
                    change('priority_authinfo', priority_authinfo);

                    if (eligibility_type && eligibility_number) {
                        change('eligibility_type', eligibility_type);
                        change('eligibility_number', eligibility_number);
                    }
                }
            }
        );
    }

    isApplicationDomainWithUs() {
        const { audirect_contention_domains_data } = this.props;
        const { domains } = this.state;

        return audirect_contention_domains_data.vip_primary_domain &&
            audirect_contention_domains_data.vip_primary_domain.domain === domains.filter((domain) => domain.held)[0]?.domain
            ? true
            : false;
    }

    toggleDomain(index) {
        this.setState({
            domains: this.state.domains.map((domain, i) => (i === index ? { ...domain, held: !domain.held } : domain)),
            dontHoldAny: false
        });
    }

    toggleDontHoldAny() {
        this.setState({
            dontHoldAny: !this.state.dontHoldAny,
            domains: this.state.dontHoldAny ? this.state.domains : this.state.domains.map((domain) => ({ ...domain, held: false }))
        });
    }

    handleApplicationSubmit() {
        const {
            // auDirectDomainId will be provided IF the .au domain already exists in mercury (its been paid for on the website). It will be undefined if its a fresh application through CCP.
            auDirectDomainId,
            submitApplication,
            updateApplicationDetails,
            priority_contact_id,
            priority_authinfo,
            eligibility_type,
            eligibility_number,
            audirect_contention_domains_data
        } = this.props;
        const { domains } = this.state;

        const application_domain = {
            domain: domains.filter((domain) => domain.held)[0].domain,
            priority_contact_id,
            priority_authinfo,
            eligibility_type,
            eligibility_number: eligibility_number || ''
        };

        if (auDirectDomainId) updateApplicationDetails(auDirectDomainId, { application_domain });
        else submitApplication({ application_domain, au_direct_domain: audirect_contention_domains_data?.au_direct_domain });
    }

    /************** LIFECYCLE METHODS **************/
    componentDidMount() {
        const { getContentionDomains, auDirectDomain } = this.props;

        getContentionDomains(auDirectDomain);
    }

    componentDidUpdate(prevProps, prevState) {
        const { audirect_contention_domains_status, audirect_contention_domains_data, eligibility_type, change } = this.props;

        if (audirect_contention_domains_status === 'success' && prevProps.audirect_contention_domains_status === 'loading') {
            this.setState({
                domains: audirect_contention_domains_data.registered_domains
                    .sort((a, b) => (a.priority_ranking < b.priority_ranking ? -1 : 1))
                    .map(({ domain, registered_with_us, priority_category }) => ({
                        domain,
                        held: registered_with_us,
                        registered_with_us,
                        priority_category
                    }))
            });
        }

        if (eligibility_type !== prevProps.eligibility_type && eligibility_type === 'Citizen/Resident') change('eligibility_number', '');
    }

    render() {
        const {
            toggleApplicationLightbox,
            audirect_contention_domains_status,
            audirect_contention_domains_data,
            audirect_apply_status,
            audirect_update_application_status,
            auDirectDomainId,
            eligibility_type
        } = this.props;
        const { step, domains, dontHoldAny } = this.state;
        const { setStep, toggleDomain, toggleDontHoldAny, handleApplicationSubmit } = this;

        const renderPriorityFields = () => {
            return (
                <div className="auApplyForm__priorityInfo">
                    <h3 className="auApplyForm__selectTitle">{domains.filter((domain) => domain.held)[0].domain}</h3>
                    <p className="auApplyForm__desc">
                        Please supply the .AU priority information for {domains.filter((domain) => domain.held)[0].domain} for proof that you hold the
                        domain name.
                    </p>
                    <div className="auApplyForm__note">
                        The information will be prefilled if the domain name listed above is with VentraIP. If the domain is not with VentraIP,
                        Priority Contact ID and Priority authInfo can be generated using{' '}
                        <Anchor href={audaPriorityTokenRetrievalToolUrl} target="_blank">
                            auDA&apos;s tool
                        </Anchor>
                        .
                    </div>
                    <Field
                        label=".AU Priority Contact ID"
                        tooltip={`<div>To proceed with the .au direct registration, we require this information to confirm you hold the existing domain name. <a href="${audaPriorityTokenRetrievalToolUrl}" target="_blank">Where do I find this?</a></div>`}
                        name="priority_contact_id"
                        component={RenderField}
                        type="text"
                        validate={[requiredFieldValidation, alphaNumericValidation]}
                    />
                    <Field
                        label=".AU Priority authInfo"
                        tooltip={`<div>To proceed with the .au direct registration, we require this information to confirm you hold the existing domain name. <a href="${audaPriorityTokenRetrievalToolUrl}" target="_blank">Where do I find this?</a></div>`}
                        name="priority_authinfo"
                        component={RenderField}
                        type="text"
                        validate={[requiredFieldValidation]}
                    />
                </div>
            );
        };

        const renderEligibilityFields = () => {
            const eligibilityTypeOptions = audirect_contention_domains_data?.eligibility_options || fallbackEligibilityOptions;

            return (
                <div className="auApplyForm__eligibility">
                    <h3 className="auApplyForm__selectTitle">Eligibility Information</h3>
                    <p className="auApplyForm__desc">Please supply the eligibility information for your .AU domain application.</p>
                    <Field
                        label="Eligibility Type"
                        name="eligibility_type"
                        component={RenderSelectField}
                        type="select"
                        validate={[requiredFieldValidation]}
                        className="form__dropdown"
                        options={eligibilityTypeOptions}
                    />
                    {eligibility_type === 'Citizen/Resident' ? (
                        <p className="auApplyForm__eligibilityCitizenNote">
                            To process this registration, you will be required to perform either a Driver&apos;s Licence, Passport or Medicare Card ID
                            verification. Once you have paid for your order, we will contact you via email to complete the required ID verification.
                        </p>
                    ) : (
                        <Field
                            label="Eligibility Details"
                            name="eligibility_number"
                            component={RenderField}
                            type="text"
                            validate={[requiredFieldValidation]}
                            className="form__textfield"
                        />
                    )}
                </div>
            );
        };

        const renderStepOne = () => {
            return (
                <>
                    <h3 className="auApplyForm__selectTitle">Select the domain/s you currently hold</h3>
                    <ul className="auApplyForm__selectList">
                        {domains.map((domain, index) => (
                            <li key={index} className="auApplyForm__selectListItem">
                                <ControlledCheckbox
                                    label={
                                        <div>
                                            {domain.domain}
                                            {domain.registered_with_us ? <small>(VIPAU)</small> : ''}
                                        </div>
                                    }
                                    disabled={domain.registered_with_us}
                                    checked={domain.held}
                                    onClick={() => toggleDomain(index)}
                                />
                            </li>
                        ))}
                        <li className="auApplyForm__selectListItem">
                            <ControlledCheckbox
                                label={`I don't own any of these domain names`}
                                disabled={domains.filter((domain) => domain.registered_with_us).length > 0}
                                checked={dontHoldAny}
                                onClick={() => toggleDontHoldAny()}
                            />
                        </li>
                    </ul>
                    {dontHoldAny || domains.filter((domain) => domain.held).length > 0 ? (
                        <SolidButton type="onClick" onClick={() => setStep('two')}>
                            Confirm
                        </SolidButton>
                    ) : (
                        <InactiveButton>Confirm</InactiveButton>
                    )}
                </>
            );
        };

        const renderStepTwo = () => {
            const renderNoDomains = () => {
                return (
                    <>
                        <div className="auApplyForm__noDomains">
                            <i className="icon icon-x" />
                            <div>
                                Unfortunately, we cannot submit this application for you if you do not own any of the existing .au domain names for
                                this keyword.{' '}
                                <Anchor href={audaPriorityApplicationProcessUrl} target="_blank">
                                    Learn more about the .au direct priority allocation process.
                                </Anchor>
                            </div>
                        </div>
                        <Anchor onClick={toggleApplicationLightbox}>Go back</Anchor>
                    </>
                );
            };

            const renderWithDomains = () => {
                const applicationCost = audirect_contention_domains_data?.pricing?.[0]?.price || '';
                const submitButtonText = auDirectDomainId ? 'Submit Details' : 'Continue to payment';

                const renderPrioCategories = () => {
                    if (
                        domains.filter((domain) => domain.held)[0].priority_category &&
                        domains.filter((domain) => domain.held)[0].priority_category !== 'N/A'
                    ) {
                        return (
                            <div className="auApplyForm__detailsListItem">
                                <div className="auApplyForm__detailsListItemHeading">Priority category</div>
                                <div className="auApplyForm__detailsListItemContent">
                                    <HoverTooltip
                                        alignment="left"
                                        content={
                                            domains.filter((domain) => domain.held)[0].priority_category === '1' ? (
                                                <div>
                                                    Category 1 - Domain name licences with a creation date <span>ON OR BEFORE</span> the Priority
                                                    Status cut-off date of the 4th of February, 2018.
                                                </div>
                                            ) : (
                                                <div>
                                                    Category 2 - Domain name licences with a creation date <span>AFTER</span> the Priority Status
                                                    cut-off date of the 4th of February, 2018.
                                                </div>
                                            )
                                        }
                                    >
                                        <span>Category {domains.filter((domain) => domain.held)[0].priority_category}</span>
                                    </HoverTooltip>
                                </div>
                            </div>
                        );
                    }
                    return '';
                };

                return (
                    <>
                        <h3 className="auApplyForm__selectTitle">Application details</h3>
                        <div className="auApplyForm__detailsList">
                            <div className="auApplyForm__detailsListItem">
                                <div className="auApplyForm__detailsListItemHeading">.au domain name</div>
                                <p className="auApplyForm__detailsListItemContent">{audirect_contention_domains_data.au_direct_domain}</p>
                            </div>
                            <div className="auApplyForm__detailsListItem">
                                <div className="auApplyForm__detailsListItemHeading">
                                    Domain/s you hold
                                    <Anchor onClick={() => setStep('one')}>Revise</Anchor>
                                </div>
                                <ul className="auApplyForm__detailsListItemContent">
                                    {domains
                                        .filter((domain) => domain.held)
                                        .map((domain, index) => (
                                            <li
                                                key={index}
                                                className={`auApplyForm__detailsListDomainHeld${
                                                    index === 0 ? ' auApplyForm__detailsListDomainHeld--primary' : ''
                                                }`}
                                            >
                                                {domain.domain}
                                                {index === 0 ? (
                                                    <>
                                                        {domains.filter((domain) => domain.held).length !== 1 ? (
                                                            <OutlineTag color="primary">primary</OutlineTag>
                                                        ) : (
                                                            ''
                                                        )}
                                                        <small>
                                                            This domain name represents your application to register{' '}
                                                            {audirect_contention_domains_data.au_direct_domain}.
                                                        </small>
                                                    </>
                                                ) : (
                                                    ''
                                                )}
                                            </li>
                                        ))}
                                    {domains.filter((domain) => domain.held).length !== 1 ? (
                                        <li className="auApplyForm__detailsListWithdraw">
                                            Your oldest domain name has been selected as your primary domain name. After you&apos;ve submitted your
                                            application, you will need to remove any non-primary domain names from contention by declining to apply on{' '}
                                            <Anchor href={audaPriorityTokenRetrievalToolUrl} target="_blank">
                                                auDA&apos;s website
                                            </Anchor>
                                            . Learn more{' '}
                                            <Anchor
                                                href="https://ventraip.com.au/faq/article/withdrawing-and-declining-an-application-for-a-contested-au-direct-domain-name/"
                                                target="_blank"
                                            >
                                                here
                                            </Anchor>
                                            .
                                        </li>
                                    ) : (
                                        ''
                                    )}
                                </ul>
                            </div>
                            {renderPrioCategories()}
                            {!auDirectDomainId ? (
                                <div className="auApplyForm__detailsListItem">
                                    <div className="auApplyForm__detailsListItemHeading">Application cost</div>
                                    <div className="auApplyForm__detailsListItemContent">
                                        <HoverTooltip
                                            alignment="left"
                                            content="Application fees paid today are non-refundable and will be used to submit your priority application. If your application is successful, you will be granted a 1-year domain name licence for the applied domain name."
                                        >
                                            <span>${applicationCost}</span>
                                        </HoverTooltip>
                                    </div>
                                </div>
                            ) : (
                                ''
                            )}
                        </div>

                        <form className="auApplyForm__form">
                            {renderPriorityFields()}
                            {renderEligibilityFields()}
                            {auDirectDomainId ? (
                                ''
                            ) : (
                                <PricingTable
                                    total={{
                                        label: 'Total Due Today',
                                        amount: `$${applicationCost} AUD`
                                    }}
                                />
                            )}

                            <ReduxFormButton form="auApplicationForm" onClick={handleApplicationSubmit}>
                                {submitButtonText}
                            </ReduxFormButton>
                        </form>
                    </>
                );
            };

            return dontHoldAny ? renderNoDomains() : renderWithDomains();
        };

        const renderMainForm = () => {
            if (
                audirect_contention_domains_status === 'loading' ||
                audirect_apply_status === 'loading' ||
                audirect_update_application_status === 'loading'
            )
                return <RequestLoader />;
            if (audirect_contention_domains_status === 'error') return <FetchComponentError />;

            return step === 'one' ? renderStepOne() : renderStepTwo();
        };

        /*  RENDER COMPONENT
         **********************************************************************************************************/
        return <div className="auApplyForm">{renderMainForm()}</div>;
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
AuApplicationForm = reduxForm({
    form: 'auApplicationForm'
})(AuApplicationForm);

const selector = formValueSelector('auApplicationForm');

export default connect(
    (state) => ({
        audirect_contention_domains_status: state.auDirect.audirect_contention_domains_status,
        audirect_contention_domains_data: state.auDirect.audirect_contention_domains_data,
        audirect_apply_status: state.auDirect.audirect_apply_status,
        audirect_apply_data: state.auDirect.audirect_apply_data,
        audirect_update_application_status: state.auDirect.audirect_update_application_status,
        priority_contact_id: selector(state, 'priority_contact_id'),
        priority_authinfo: selector(state, 'priority_authinfo'),
        eligibility_type: selector(state, 'eligibility_type'),
        eligibility_number: selector(state, 'eligibility_number')
    }),
    {
        getContentionDomains,
        submitApplication,
        updateApplicationDetails,
        change,
        reset
    }
)(AuApplicationForm);

export const fallbackEligibilityOptions = [
    {
        label: 'ABN',
        value: 'ABN'
    },
    {
        label: 'ACN',
        value: 'ACN'
    },
    {
        label: 'ACT BN',
        value: 'ACT BN'
    },
    {
        label: 'NSW BN',
        value: 'NSW BN'
    },
    {
        label: 'NT BN',
        value: 'NT BN'
    },
    {
        label: 'QLD BN',
        value: 'QLD BN'
    },
    {
        label: 'SA BN',
        value: 'SA BN'
    },
    {
        label: 'TAS BN',
        value: 'TAS BN'
    },
    {
        label: 'VIC BN',
        value: 'VIC BN'
    },
    {
        label: 'WA BN',
        value: 'WA BN'
    },
    {
        label: 'Trademark',
        value: 'Trademark'
    },
    {
        label: 'Charity',
        value: 'Charity'
    },
    {
        label: 'Club',
        value: 'Club'
    },
    {
        label: 'Commercial Statutory Body',
        value: 'Commercial Statutory Body'
    },
    {
        label: 'Company',
        value: 'Company'
    },
    {
        label: 'Incorporated Association',
        value: 'Incorporated Association'
    },
    {
        label: 'Industry Body',
        value: 'Industry Body'
    },
    {
        label: 'Non-profit Organisation',
        value: 'Non-profit Organisation'
    },
    {
        label: 'Other',
        value: 'Other'
    },
    {
        label: 'Pending TM Owner',
        value: 'Pending TM Owner'
    },
    {
        label: 'Political Party',
        value: 'Political Party'
    },
    {
        label: 'Religious/Church Group',
        value: 'Religious/Church Group'
    },
    {
        label: 'Trade Union',
        value: 'Trade Union'
    },
    {
        label: 'Trademark Owner',
        value: 'Trademark Owner'
    },
    {
        label: 'Child Care Centre',
        value: 'Child Care Centre'
    },
    {
        label: 'Government School',
        value: 'Government School'
    },
    {
        label: 'Higher Education Institution',
        value: 'Higher Education Institution'
    },
    {
        label: 'National Body',
        value: 'National Body'
    },
    {
        label: 'Non-Government School',
        value: 'Non-Government School'
    },
    {
        label: 'Pre-school',
        value: 'Pre-school'
    },
    {
        label: 'Research Organisation',
        value: 'Research Organisation'
    },
    {
        label: 'Training Organisation',
        value: 'Training Organisation'
    },
    {
        label: 'Citizen/Resident',
        value: 'Citizen/Resident'
    }
];
