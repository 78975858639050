/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { _CompactModal } from 'components/Lightboxes/Modal/_CompactModal';
import { _ModalContent } from 'components/Lightboxes/Modal/_Content';
import { _Modal } from 'components/Lightboxes/Modal/_Modal';
import { _ModalBackLink } from 'components/Lightboxes/Modal/BackLink';
import { _ModalTitle } from 'components/Lightboxes/Modal/Title';

const Modal = Object.assign(_Modal, {
    Compact: _CompactModal,
    Title: _ModalTitle,
    BackLink: _ModalBackLink,
    Content: _ModalContent
});

export { Modal };
