import Anchor from 'components/Anchor';
import { SolidButton } from 'components/Buttons/SolidButton';
import { COPY_closeInvoice, COPY_payInvoiceLater } from 'components/Lightboxes/OverlayLightbox/Components/consts';
import { InvoiceItems } from 'components/Lightboxes/OverlayLightbox/Components/invoice/invoiceItems';
import { LightboxPaymentMethods } from 'components/Lightboxes/OverlayLightbox/Components/invoice/paymentMethods';
import { Flex } from 'components/Utils/Flex';
import { Padding } from 'components/Utils/Padding';
import Text from 'components/Utils/Text';
import { useGetInvoiceQuery } from 'containers/billing/queries/invoice/useGetInvoiceQuery';
import { capitalize, getDataFromSuccessResponse } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type OverlayInvoiceContentProps = {
    invoiceID: number;
    handleSuccessResponse: any;
    closeInvoice: () => void;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const OverlayInvoiceContent: React.FC<OverlayInvoiceContentProps> = ({ invoiceID, handleSuccessResponse, closeInvoice }) => {
    /***** STATE *****/

    /***** QUERIES *****/
    const { data: get_invoice_data } = useGetInvoiceQuery(invoiceID);

    /***** RENDER HELPERS *****/
    const {
        amount_due,
        subtotal,
        credit,
        total,
        total_discount,
        tax: hardCodedTax,
        tax_breakdown,
        tax_rule_id,
        tax_pricing_mode
    } = get_invoice_data?.attributes || {
        amount_due: '0.00',
        subtotal: '0.00',
        credit: '0.00',
        total_discount: '0.00',
        tax: '0.00'
    };

    //Use the ID to find the corresponding tax rule
    const { name } = tax_breakdown?.items?.find((item) => item.id === tax_rule_id) ?? {};

    //If there is a tax rule name use that, esle use default 'GST'
    const taxRuleName = name || 'GST';

    const mutationOptions = {
        onSuccess: (response) => {
            const paymentData = getDataFromSuccessResponse(response);
            handleSuccessResponse(paymentData);
        }
    };

    /***** RENDER *****/
    return (
        <div className="OverlayInvoice">
            <div className="OverlayInvoice__wrapper">
                <div className="OverlayInvoice__details">
                    {get_invoice_data?.included ? (
                        <div className="OverlayInvoice__items">
                            <InvoiceItems items={get_invoice_data.included} />
                        </div>
                    ) : (
                        <div className="OverlayInvoice__items">
                            <div className="item__row">
                                <div className="item__column--description"></div>
                            </div>
                        </div>
                    )}
                    <Padding x={4} top={4}>
                        <Flex justify="between">
                            <Text secondary bold>
                                Subtotal
                            </Text>
                            <Text secondary bold>
                                ${subtotal} AUD
                            </Text>
                        </Flex>
                    </Padding>
                </div>
                <div className="OverlayInvoice__options">
                    <div className="Options__overviewContainer">
                        {total_discount !== '0.00' ? (
                            <Flex justify="between">
                                <Text secondary size--xss>
                                    Discount Applied
                                </Text>
                                <Text secondary size--xss>
                                    -${total_discount} AUD
                                </Text>
                            </Flex>
                        ) : null}
                        <Padding top={2}>
                            <Flex justify="between">
                                <Text secondary bold>
                                    Grand Total
                                </Text>
                                <Text primary bold>
                                    ${total} AUD
                                </Text>
                            </Flex>
                        </Padding>
                        {hardCodedTax && hardCodedTax !== '0.00' ? (
                            <Padding top={1}>
                                <Flex justify="between">
                                    <Text secondary size--xss>
                                        {taxRuleName} {capitalize(tax_pricing_mode || 'inclusive')}
                                    </Text>
                                    <Text secondary size--xss>
                                        ${hardCodedTax?.toFixed(2)} AUD
                                    </Text>
                                </Flex>
                            </Padding>
                        ) : null}
                        {credit !== '0.00' ? (
                            <Padding top={1}>
                                <Flex justify="between">
                                    <Text secondary size--xss>
                                        Credit Applied
                                    </Text>
                                    <Text secondary size--xss>
                                        -${credit} AUD
                                    </Text>
                                </Flex>
                            </Padding>
                        ) : null}
                        <Padding top={2}>
                            <Flex justify="between">
                                <Text secondary bold>
                                    Total Amount Due
                                </Text>
                                <Text primary bold>
                                    ${amount_due} AUD
                                </Text>
                            </Flex>
                        </Padding>
                    </div>
                    {amount_due === '0.00' ? (
                        <div className="Options__completed">
                            <div className="overview__action">
                                <SolidButton to={`/billing/invoice/${invoiceID}`}>View Invoice Details</SolidButton>
                            </div>
                        </div>
                    ) : (
                        <LightboxPaymentMethods invoiceID={invoiceID} mutationOptions={mutationOptions} />
                    )}
                </div>
            </div>
            <Anchor className="OverlayInvoice__commonlink" onClick={closeInvoice}>
                {amount_due === '0.00' ? COPY_closeInvoice : COPY_payInvoiceLater}
            </Anchor>
        </div>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
