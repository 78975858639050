import { createRoute, redirect } from '@tanstack/react-router';
import { loadAppConfig } from 'App/actions/loadAppConfig';
import AppLoader from 'components/Loaders/App';
import { RootRoute } from 'router/__root';
import { queryClient } from 'store/queryClient';
import { NXQuery } from 'utilities/query';
import { z } from 'zod';

/**********************************************************************************************************
 *   ROUTE COMPONENT START
 **********************************************************************************************************/
export const MicrosoftProviderSignupRoute = createRoute({
    getParentRoute: () => RootRoute,
    path: '/user/additional-user/microsoft/signup',
    pendingMs: 0,
    pendingComponent: () => <AppLoader fullHeight />,
    staticData: {
        preventLoadAppConfig: true
    },
    validateSearch: z.object({
        code: z.string(),
        state: z.object({
            token: z.string(),
            redirectUri: z.string()
        })
    }),
    loaderDeps: ({ search }) => ({
        accessToken: search.code,
        additionalUserToken: search.state.token,
        redirectUrl: search.state.redirectUri
    }),
    async loader({ deps: { accessToken, additionalUserToken, redirectUrl } }) {
        const provider = 'microsoft';

        await queryClient.fetchQuery(
            NXQuery.auth.oauth.additionalUserLogin.createQueryOptions({
                provider,
                accessToken,
                redirectUrl,
                token: additionalUserToken
            })
        );

        // Once we have checked our token is valid, we can load the relevant app config and redirect to dashboard
        await loadAppConfig({
            context: 'loader',
            onError: () => redirect({ to: '/login', throw: true })
        });
    }
});
