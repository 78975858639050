import { inactivePromoBannerDismissKeys, promoBannerDismissKeys } from 'containers/dashboard/Components/consts';

export function promoBannerDismissRecordMigration() {
    const bannerKeys = [...Object.values(inactivePromoBannerDismissKeys), ...Object.values(promoBannerDismissKeys)];
    const collectedBannerKeys = bannerKeys.reduce<Record<string, boolean>>((current, key) => {
        const storedValue = window.localStorage.getItem(key);

        if (!storedValue) {
            return current;
        }

        current[key] = JSON.parse(storedValue);

        window.localStorage.removeItem(key);

        return current;
    }, {});

    if (Object.keys(collectedBannerKeys).length) {
        localStorage.setItem('USER_PREFERENCE:PROMO_BANNER_DISMISSAL_RECORD', JSON.stringify(collectedBannerKeys));
    }
}
