import { getAllKeys } from 'utilities/UserPreferences/methods/getAllKeys';

export function onlineGiftNotificationMigration() {
    const currentlyStoredData = localStorage.getItem('USER_PREFERENCE:ONLINE_GIFT_NOTIFICATION');
    const parsedStoredData = currentlyStoredData ? JSON.parse(currentlyStoredData) : {};

    const keys = getAllKeys();
    const onlineGiftNotificationKeys = keys.filter((key) => key.includes('_onlineGiftNotification'));
    const migrationResult = onlineGiftNotificationKeys.reduce<Record<string, boolean>>((current, key) => {
        const [domain_id] = key.split('_');
        const storedValue = window.localStorage.getItem(key);

        if (!storedValue) {
            return current;
        }
        const parsedValue = JSON.parse(storedValue);

        current[domain_id] = parsedValue;

        window.localStorage.removeItem(key);

        return current;
    }, parsedStoredData);

    if (Object.keys(migrationResult).length) {
        localStorage.setItem('USER_PREFERENCE:ONLINE_GIFT_NOTIFICATION', JSON.stringify(migrationResult));
    }
}
