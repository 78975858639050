/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { createRoute } from '@tanstack/react-router';

/**********************************************************************************************************
 *   ROUTE IMPORTS
 **********************************************************************************************************/
import { SupportRoute } from 'containers/support';
import { routerMiddleware } from 'router/utils/middleware';
import { lazyFN } from 'utilities/methods/tanstack/router/lazyFn';
import { z } from 'zod';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
const searchSchema = z.object({
    categories: z.string().optional()
});

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const SupportCentreRoute = createRoute({
    getParentRoute: () => SupportRoute,
    path: 'support-centre',
    validateSearch: searchSchema,
    beforeLoad(opts) {
        routerMiddleware.business(this, opts);
        routerMiddleware.authentication('user', opts);
    }
}).lazy(lazyFN(() => import('./index.lazy'), 'LazySupportCentreRoute'));
