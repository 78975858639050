/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { PhosphorIcons } from 'components/Icons/Phosphor';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getSlots } from 'components/Slot/methods';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { useNXTableSortableContext } from 'components/NXTable/SortableButtonWrapper';
import './_SortableButton.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type SortableButtonProps = {
    sortKey: string;
    children: React.ReactNode;
    onSort?: (sortData: { sortKey: string; sortMethod: string | boolean }) => void;
    currentSort?:
        | {
              sortKey: string | false;
              sortMethod: string | false;
          }
        | {};
    initialMethod?: 'asc' | 'desc' | false;
};

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
const getChevronDirection = (sortMethod: 'asc' | 'desc' | false) => {
    if (sortMethod === 'asc') return 'up';
    if (sortMethod === 'desc') return 'down';
    return false;
};

const getNext = (sortMethod: 'asc' | 'desc' | false) => {
    if (sortMethod === 'asc') return 'desc';
    if (sortMethod === 'desc') return false;
    return 'asc';
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const SortableButtonIcon = ({ sortMethod }) => {
    const chevronDirection = getChevronDirection(sortMethod);

    if (!chevronDirection) return null;
    return <PhosphorIcons.Chevron className="sortableButton__icon" state={chevronDirection} />;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Sortable Button allows you to easily get a desired Key + Sorting direction to help guide what you should sort
 *
 * Note: The onSort and currentSort methods on the Sortable Button should NOT be provided by the user if used within the
 * NXTable Component, but instead get injected by the SortableButtonWrapper component. The Sortable Button Wrapper retrieves
 * these from the NXTable Context
 */
export const SortableButton: React.FC<SortableButtonProps> = ({ sortKey, children, initialMethod }) => {
    /***** HOOKS *****/
    const { currentSort, onSort } = useNXTableSortableContext(sortKey, initialMethod);    

    /***** FUNCTIONS *****/
    function toggleSort() {
        onSort({
            sortKey,
            sortMethod: getNext(currentSort.sortMethod)
        });
    }

    /***** RENDER HELPERS *****/
    const { defaultChildren, afterIcon } = getSlots(['defaultChildren', 'afterIcon'], children);

    /***** RENDER *****/
    return (
        <div className="sortableButton">
            <button className="sortableButton__clickWrapper" onClick={toggleSort}>
                <Text bold uppercase size--xs>
                    {defaultChildren}
                </Text>
                <SortableButtonIcon sortMethod={currentSort.sortMethod} />
            </button>
            {afterIcon}
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default SortableButton;
