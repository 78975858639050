/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import ReactGA from 'react-ga4';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { brandStore } from 'config/hooks/useBrandStore';
import { queryClient } from 'store/queryClient';
import { API as ACCOUNT } from 'utilities/api/account';
import { getDataFromSuccessResponse } from 'utilities/methods/commonActions';
import { NXQuery } from 'utilities/query';

export const trackingParams = {
    referral: {
        email: 'utm_source=ccp&utm_medium=email&utm_campaign=Referral+Offer',
        sms: 'utm_source=ccp&utm_medium=sms&utm_campaign=Referral+Offer',
        qr: 'utm_source=ccp&utm_medium=qrcode&utm_campaign=Referral+Offer'
    },
    dashboardBanners: {
        auDirect: 'utm_source=ccp&utm_medium=banner&utm_campaign=ccp-audirect',
        referral: 'utm_source=ccp&utm_medium=banner&utm_campaign=ccp-refer',
        dotOnline: 'utm_source=ccp&utm_medium=banner&utm_campaign=ccp-online',
        dotStore: 'utm_source=ccp&utm_medium=banner&utm_campaign=ccp-store'
    }
};

// Records a purchase event in Google Anayltics
export const gaPurchaseEvent = ({ invoiceId, item_brand = undefined, item_category = undefined, item_category2 = undefined } = {}) => {
    ACCOUNT.user.invoice.single
        .GET(invoiceId)
        .then((response) => {
            const { queryKey } = NXQuery.auth.userData.createQueryOptions();
            const { email } = queryClient.getQueryData(queryKey)?.data?.attributes ?? {};
            const { id, attributes } = getDataFromSuccessResponse(response) ?? {};
            const { total, tax, invoice_items } = attributes ?? {};

            if (!attributes) return;
            if (!Array.isArray(invoice_items)) return;
            if (!email) return;

            const getBillingCycle = (id) => {
                switch (id) {
                    case 2:
                        return 'Monthly';
                    case 3:
                        return 'Quarterly';
                    case 4:
                        return 'Semi-Annually';
                    case 5:
                        return 'Annually';
                    case 6:
                        return 'Biennially';
                    case 7:
                        return 'Triennially';
                    case 8:
                        return 'One Year';
                    default:
                        return '';
                }
            };

            const options = {
                transaction_id: id,
                value: Number(total),
                tax: Number(tax),
                currency: 'AUD',
                email,
                items: invoice_items.map((invoiceItem, index) => {
                    if (!invoiceItem) return {};

                    const { product_id, discount, billing_cycle_id, subtotal } = invoiceItem;

                    return {
                        item_id: product_id,
                        item_name: item_brand,
                        affiliation: brandStore.state.application,
                        discount: Number(discount) || 0,
                        index,
                        item_variant: undefined,
                        item_brand,
                        item_category,
                        item_category2,
                        price: Number(subtotal) || 0,
                        billing_cycle: getBillingCycle(billing_cycle_id)
                    };
                })
            };

            ReactGA.event('purchase', options);
        })
        .catch(() => {});
};
