import { _ValidationMessageHookFormDefault as ValidationMessageHookFormDefault } from 'components/Form/ValidationMessage/wrappers/hookForm/Default';
import { useFieldStateWithUnregisteredChildren } from 'containers/katana/components/dynamicFormFieldRenderer/hooks/useFieldStateWithUnregisteredChildren';
import { useKatanaProperty } from 'containers/katana/components/dynamicFormFieldRenderer/PropertyContext/useKatanaProperty';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _ValidationMessageHookFormKatanaProperty = () => {
    /***** HOOKS *****/
    const { path } = useKatanaProperty();
    const { isTouched, error } = useFieldStateWithUnregisteredChildren(path);

    /***** RENDER *****/
    return <ValidationMessageHookFormDefault isTouched={isTouched} error={typeof error?.message === 'string' ? error.message : undefined} />;
};
