import { checkDomain } from 'containers/katana/queries/checkDomain';
import { getDomainsList } from 'containers/katana/queries/getDomainsList';
import { getSiteList } from 'containers/katana/queries/getSiteList';
import { getTrialDomains } from 'containers/katana/queries/getTrailDomains';
import { searchDomainsList } from 'containers/katana/queries/searchDomainsList';
import { searchUnsplash } from 'containers/katana/queries/searchUnsplash';
import { addPageSection } from 'containers/katana/queries/serviceID/addPageSection';
import { addSection } from 'containers/katana/queries/serviceID/addSection';
import { cancel } from 'containers/katana/queries/serviceID/cancel';
import { createPage } from 'containers/katana/queries/serviceID/createPage';
import { createPageSection } from 'containers/katana/queries/serviceID/createPageSection';
import { createTheme } from 'containers/katana/queries/serviceID/createTheme';
import { deletePage } from 'containers/katana/queries/serviceID/deletePage';
import { deletePageSection } from 'containers/katana/queries/serviceID/deletePageSection';
import { deleteSection } from 'containers/katana/queries/serviceID/deleteSection';
import { deleteSocialType } from 'containers/katana/queries/serviceID/deleteSocialType';
import { dns } from 'containers/katana/queries/serviceID/dns';
import { domainServiceDetails } from 'containers/katana/queries/serviceID/domainServiceDetails';
import { addDomain } from 'containers/katana/queries/serviceID/domains/add';
import { duplicatePage } from 'containers/katana/queries/serviceID/duplicatePage';
import { duplicatePageSection } from 'containers/katana/queries/serviceID/duplicatePageSection';
import { getBusinessInfo } from 'containers/katana/queries/serviceID/getBusinessInfo';
import { getDomainOptions } from 'containers/katana/queries/serviceID/getDomainOptions';
import { getNavigation } from 'containers/katana/queries/serviceID/getNavigation';
import { getPagePreview } from 'containers/katana/queries/serviceID/getPagePreview';
import { getSectionPreview } from 'containers/katana/queries/serviceID/getSectionPreview';
import { getSectionSitePreview } from 'containers/katana/queries/serviceID/getSectionSitePreview';
import { getSingleFileInfo } from 'containers/katana/queries/serviceID/getSingleFile';
import { getSiteInfo } from 'containers/katana/queries/serviceID/getSiteInfo';
import { getSitePreview } from 'containers/katana/queries/serviceID/getSitePreview';
import { getSocialAccount } from 'containers/katana/queries/serviceID/getSocialAccount';
import { getSocials } from 'containers/katana/queries/serviceID/getSocials';
import { listSocialAccounts } from 'containers/katana/queries/serviceID/listSocialAccounts';
import { getColours } from 'containers/katana/queries/serviceID/meta/getColours';
import { getFonts } from 'containers/katana/queries/serviceID/meta/getFonts';
import { getSocialsDefinitions } from 'containers/katana/queries/serviceID/meta/getSocialsDefinitions';
import { pagePresets } from 'containers/katana/queries/serviceID/meta/pagePresets';
import { palette } from 'containers/katana/queries/serviceID/meta/palette';
import { sectionDefinitions } from 'containers/katana/queries/serviceID/meta/sectionDefinitions';
import { sitePresets } from 'containers/katana/queries/serviceID/meta/sitePresets';
import { moveSection } from 'containers/katana/queries/serviceID/moveSection';
import { navigationItems } from 'containers/katana/queries/serviceID/navigationItems';
import { page } from 'containers/katana/queries/serviceID/page';
import { pagePresetPreview } from 'containers/katana/queries/serviceID/pagePresetPreview';
import { pageSections } from 'containers/katana/queries/serviceID/pageSections';
import { pages } from 'containers/katana/queries/serviceID/pages';
import { removeCancellation } from 'containers/katana/queries/serviceID/removeCancellation';
import { reorderPageSections } from 'containers/katana/queries/serviceID/reorderPageSections';
import { replaceNavigationItems } from 'containers/katana/queries/serviceID/replaceNavigationItems';
import { resetPage } from 'containers/katana/queries/serviceID/resetPage';
import { section } from 'containers/katana/queries/serviceID/section';
import { sections } from 'containers/katana/queries/serviceID/sections';
import { service } from 'containers/katana/queries/serviceID/service';
import { sitePresetPreview } from 'containers/katana/queries/serviceID/sitePresetPreview';
import { socialAuthorisationCallback } from 'containers/katana/queries/serviceID/socialAuthorisationCallback';
import { socialsAuthorisationLink } from 'containers/katana/queries/serviceID/socialsAuthorisationLink';
import { triggerUnsplashDownload } from 'containers/katana/queries/serviceID/triggerUnsplashDownload';
import { unlinkSocialID } from 'containers/katana/queries/serviceID/unlinkSocialID';
import { updatePage } from 'containers/katana/queries/serviceID/updatePage';
import { updateSection } from 'containers/katana/queries/serviceID/updateSection';
import { uploadFile } from 'containers/katana/queries/uploadFile';

export const katanaQuery = {
    checkDomain,
    getDomainsList,
    searchDomainsList,
    getSiteList,
    getTrialDomains,
    searchUnsplash,
    serviceID: {
        addPageSection,
        addSection,
        cancel,
        createPage,
        createPageSection,
        createTheme,
        deletePage,
        deletePageSection,
        deleteSection,
        deleteSocialType,
        dns,
        domains: {
            add: addDomain
        },
        domainServiceDetails,
        duplicatePage,
        duplicatePageSection,
        getBusinessInfo,
        getDomainOptions,
        getNavigation,
        navigationItems,
        getSectionPreview,
        getSectionSitePreview,
        getPagePreview,
        getSingleFileInfo,
        getSiteInfo,
        getSitePreview,
        getSocialAccount,
        getSocials,
        listSocialAccounts,
        meta: {
            getColours,
            getFonts,
            sectionDefinitions,
            getSocialsDefinitions,
            pagePresets,
            palette,
            sitePresets
        },
        moveSection,
        page,
        pagePresetPreview,
        pages,
        pageSections,
        removeCancellation,
        reorderPageSections,
        resetPage,
        section,
        replaceNavigationItems,
        sections,
        service,
        sitePresetPreview,
        socialAuthorisationCallback,
        socialsAuthorisationLink,
        triggerUnsplashDownload,
        unlinkSocialID,
        updatePage,
        updateSection,
        uploadFile
    }
} as const;
