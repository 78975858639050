import { Flex } from 'components/Utils/Flex';
import { Padding } from 'components/Utils/Padding';
import Text from 'components/Utils/Text';
import { useBrandStore } from 'config/hooks/useBrandStore';
import { useOrderingFormContext } from 'containers/orderingForm/contexts/useOrderingFormContext';
import { IntaserveFormInput } from 'containers/orderingForm/orderingFormInputs';
import type { EditFormValues, Errors, Register, Watch } from 'containers/orderingForm/orderingFormInputs/types';
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';
import { NXQuery } from 'utilities/query';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type RegistrationFormFields = React.FC<RegistrationFormFieldsProps>;
type RegistrationFormFieldsProps = {
    productData?: Artah.Products.POST._200['data'];
    register: Register<EditFormValues>;
    watch: Watch<EditFormValues>;
    errors: Errors<EditFormValues>;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const RegistrationFormFields: RegistrationFormFields = ({ watch, register, errors }) => {
    /***** HOOKS *****/
    const { currentItem } = useOrderingFormContext();
    const isMobile = useAppViewport(['xs', 'sm']);
    const { data: is_user_detail_completed } = NXQuery.auth.login.checkToken.useSelectSuspenseQuery(void 0, ({ data }) => {
        return data.attributes.is_user_detail_completed;
    });
    const { activeBrand } = useBrandStore();

    const domainData = {
        domain: currentItem?.name?.replace(currentItem?.product_name, ''),
        action: 'register',
        promos: [],
        tlds: [currentItem?.product_name]
    };

    const { data: domain_availibility_data } = NXQuery.domain.availability.useQuery(domainData);

    const registrationPeriodOptions = domain_availibility_data?.[0]?.price.map((price) => ({
        label: `${price.name} - $${price.price}`,
        value: price.billing_cycle_id
    }));
    const contactDetailOptions = [
        is_user_detail_completed || activeBrand === 'intaserve' ? { label: 'Use my account details (default)', value: 'currentDetails' } : null,
        { label: 'Use new details', value: 'newDetails' }
    ].filter(Boolean) as Array<{
        label: string;
        value: string;
    }>;

    const australianStatesAndTerritories = [
        'New South Wales',
        'Victoria',
        'Queensland',
        'Western Australia',
        'South Australia',
        'Tasmania',
        'Australian Capital Territory',
        'Northern Territory'
    ];

    const newZealandRegions = [
        'Northland',
        'Auckland',
        'Waikato',
        'Bay of Plenty',
        'Gisborne',
        "Hawke's Bay",
        'Taranaki',
        'Manawatū-Whanganui',
        'Wellington',
        'Tasman',
        'Nelson',
        'Marlborough',
        'West Coast',
        'Canterbury',
        'Otago',
        'Southland'
    ];

    const contactDetailsValue = watch('contactDetails');

    const country = watch('country');

    const stateOptions = country === 'AU' ? australianStatesAndTerritories : newZealandRegions;

    const stateFieldOptions = stateOptions?.map((option) => {
        return {
            label: option,
            value: option
        };
    });

    /***** RENDER HELPERS *****/
    const renderRegistrationPeriodField = () => {
        if (currentItem?.order_type === 'transfer') return;

        if (registrationPeriodOptions?.length && registrationPeriodOptions?.length < 2)
            return (
                <Flex direction="column">
                    <Text semiBold>Registration Period</Text>
                    <Padding left={4}>
                        <Text>{registrationPeriodOptions[0]?.label}</Text>
                    </Padding>
                </Flex>
            );

        return (
            <IntaserveFormInput.HookForm.Select
                register={register}
                watch={watch}
                name="registrationPeriod"
                placeholder="Please Select"
                options={registrationPeriodOptions}
                label="Registration Period"
            />
        );
    };

    /***** RENDER *****/
    return (
        <>
            {/* Registration fields */}
            {renderRegistrationPeriodField()}
            <IntaserveFormInput.HookForm.Select
                register={register}
                watch={watch}
                name="contactDetails"
                options={contactDetailOptions}
                label="Registrant Contact Details"
            />
            {contactDetailsValue === 'newDetails' && (
                <Flex direction="column">
                    <Padding bottom={2}>
                        <Text secondary>Please enter the registrant contact information for this domain name</Text>
                    </Padding>

                    <Flex direction={isMobile && 'column'}>
                        <IntaserveFormInput.HookForm.Text
                            register={register}
                            name="firstName"
                            label="First Name"
                            validationErrors={errors}
                            errorBorder={!!errors?.firstName}
                            placeholder="Enter first name"
                        />
                        <IntaserveFormInput.HookForm.Text
                            register={register}
                            name="lastName"
                            label="Last Name"
                            validationErrors={errors}
                            errorBorder={!!errors?.lastName}
                            placeholder="Enter last name"
                        />
                    </Flex>
                    <Flex direction={isMobile && 'column'}>
                        <IntaserveFormInput.HookForm.Text
                            register={register}
                            name="emailAddress"
                            autoComplete="Address line 1"
                            label="Email Address"
                            type="email"
                            validationErrors={errors}
                            errorBorder={!!errors?.emailAddress}
                            disabled={activeBrand === 'ventra'}
                            placeholder="Enter an email address"
                        />
                    </Flex>
                    <Flex direction={isMobile && 'column'}>
                        <IntaserveFormInput.HookForm.Text
                            register={register}
                            name="phoneNumber"
                            label="Phone Number"
                            type="tel"
                            validationErrors={errors}
                            errorBorder={!!errors?.phoneNumber}
                            placeholder="Enter a phone number"
                        />
                        <IntaserveFormInput.HookForm.Text
                            register={register}
                            name="companyName"
                            label="Company Name (optional)"
                            placeholder="Enter a company"
                        />
                    </Flex>
                    <Flex>
                        <IntaserveFormInput.HookForm.Text
                            register={register}
                            name="address"
                            label="Address"
                            type="address"
                            validationErrors={errors}
                            errorBorder={!!errors?.address}
                            placeholder="Enter an address"
                        />
                    </Flex>
                    <Flex direction={isMobile && 'column'}>
                        <IntaserveFormInput.HookForm.Text
                            register={register}
                            name="suburb"
                            label="Suburb"
                            validationErrors={errors}
                            errorBorder={!!errors?.suburb}
                            placeholder="Enter a suburb"
                        />
                        <IntaserveFormInput.HookForm.Select
                            register={register}
                            watch={watch}
                            name="country"
                            options={[
                                { label: 'Australia', value: 'AU' },
                                { label: 'New Zealand', value: 'NZ' }
                            ]}
                            label="Country"
                            validationErrors={errors}
                            errorBorder={!!errors?.country}
                            placeholder="Select a country"
                        />
                    </Flex>
                    <Flex direction={isMobile && 'column'}>
                        <IntaserveFormInput.HookForm.Select
                            register={register}
                            watch={watch}
                            name="state"
                            options={stateFieldOptions}
                            label="State"
                            validationErrors={errors}
                            disabled={!country}
                            errorBorder={!!errors?.state}
                            placeholder="Select a state"
                        />
                        <IntaserveFormInput.HookForm.Text
                            register={register}
                            name="postcode"
                            label="Postcode"
                            validationErrors={errors}
                            errorBorder={!!errors?.postcode}
                            placeholder="Enter a postcode"
                        />
                    </Flex>
                </Flex>
            )}
        </>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
