import type { ParsedLocation } from '@tanstack/react-router';
import { useLocation, useRouter } from '@tanstack/react-router';
import { createElement, type ComponentType } from 'react';
import { useHistory } from 'utilities/hooks/router/useHistory';

type History = {
    push: (to: string, state?: any) => void;
};

type InjectedProps = {
    history: History;
    location: ParsedLocation<{}>;
};

/**
 * Legacy method to provide features from Tanstack Router to classComponents, use hooks.
 * This specifically for Header to ensure no useless re-renders on location change.
 */
export const withHeaderRouter = <P,>(component: ComponentType<P & InjectedProps>) => {
    const InternalComponent = (props: Omit<P, keyof InjectedProps>) => {
        const router = useRouter();
        const location = useLocation();
        const history = useHistory();

        return createElement(
            component,
            //@ts-ignore
            {
                ...props,
                router,
                history,
                location
            },
            (props as any).children
        );
    };

    return InternalComponent;
};
