/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { queryOptions, useQuery } from '@tanstack/react-query';
import { WP_SUPPORT_CENTRE_API } from 'utilities/api/ventraip-website-wp-support-centre';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { createBaseQueryKey } from 'utilities/methods/tanstack/createBaseQueryKey';

function createQueryKey() {
    return createBaseQueryKey(['ventraip-website-wp-support-centre', 'pages']);
}

function createQueryOptions() {
    return queryOptions({
        queryKey: createQueryKey(),
        queryFn: () => WP_SUPPORT_CENTRE_API.pages.GET('support-centre'),
        staleTime: Infinity,
        select: (data) => {
            return data?.data[0]?.acf?.tiles;
        }
    });
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useQuery() {
    return useQuery(createQueryOptions());
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export const getPages = Object.freeze({
    useQuery: _useQuery
});
