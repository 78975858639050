import { useNavigate, useSearch } from '@tanstack/react-router';
import { HookFormButton } from 'components/Form/Button/hookForm';
import { Input } from 'components/Form/Input';
import { Padding } from 'components/Utils/Padding';
import { useLoginContext } from 'containers/login/consts';
import { FormProvider } from 'react-hook-form';
import { useNXHookForm } from 'utilities/hooks/useNXForm';
import { useWebOTP } from 'utilities/hooks/useWebOTP';
import { requiredFieldErrorMessage } from 'utilities/methods/form';
import { NXQuery } from 'utilities/query';
import { z } from 'zod';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type FormSchema = z.infer<typeof formSchema>;

/**********************************************************************************************************
 *   SCHEMA
 **********************************************************************************************************/
const formSchema = z.object({
    twofactorcode: z.string().min(1, requiredFieldErrorMessage)
});

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const DefaultTwoFactorForm = () => {
    /***** FORM *****/
    const form = useNXHookForm(formSchema, {
        defaultValues: {
            twofactorcode: ''
        },
        mode: 'all'
    });

    /***** HOOKS *****/
    const { searchRef, approveToken, moveToken } = useSearch({
        from: '/_login',
        select: (search) => ({
            searchRef: search.ref,
            approveToken: search['approve-token'],
            moveToken: search['move-token']
        })
    });
    const { setView } = useLoginContext();
    const navigate = useNavigate();
    const { inputRef } = useWebOTP({
        onSuccess(OTP) {
            if (OTP) {
                form.setValue('twofactorcode', OTP?.code);
                form.handleSubmit(handleSubmit);
            }
        }
    });

    /***** QUERIES *****/
    const { mutateAsync: submitTwoFactorAsync } = NXQuery.auth.twoFactor.submit.useMutation({ onSuccess: () => void 0 });

    /***** FUNCTIONS *****/
    async function handleSubmit(value: FormSchema) {
        try {
            // submit code so that following requests to token/list can be made
            await submitTwoFactorAsync(value.twofactorcode);

            // prettier-ignore
            const [accountList] = await Promise.all([
                NXQuery.auth.accountList.forceFetch(), 
                NXQuery.auth.login.checkToken.forceFetch()
            ]);

            // Need to go to the account route to finish the approve user flow if this is in the URL.
            if (approveToken) {
                navigate({ to: '/approve-user/$token', params: { token: approveToken } });
            }
            if (moveToken) {
                navigate({ to: '/move-service/$moveToken', params: { moveToken } });
            }

            if (accountList.data.length === 1) {
                // fetch the user data if we are going to go straight through to the application from here
                await NXQuery.auth.userData.fetch();
                navigate({ to: searchRef ?? '/dashboard' });
            } else {
                setView('accountlist');
            }
        } catch (e) {
            // If any of these requests fail, the user is going to retry this form (or go elsewhere in the login area), in such a case,
            // we need the caches reset so that we can ensure we are not using cached token/list data.
            NXQuery.auth.login.checkToken.reset();
            NXQuery.auth.accountList.reset();
        }
    }

    /***** RENDER *****/
    return (
        <FormProvider {...form}>
            <form className="twoFactor__form" onSubmit={form.handleSubmit(handleSubmit)}>
                <Padding bottom={5}>
                    <Input.HookForm
                        placeholder="Enter Authentication Code"
                        name="twofactorcode"
                        label="Enter Authentication Code"
                        inputRef={inputRef}
                        intrinsicProps={{
                            autoComplete: 'one-time-code',
                            inputMode: 'numeric'
                        }}
                    />
                </Padding>
                <HookFormButton>Verify and Login</HookFormButton>
            </form>
        </FormProvider>
    );
};
