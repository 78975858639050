export const AU_NZ_STATES = {
    'Victoria': 'VIC',
    'New South Wales': 'NSW',
    'Queensland': 'QLD',
    'South Australia': 'SA',
    'Tasmania': 'TAS',
    'Western Australia': 'WA',
    'Northern Territory': 'NT',
    'Australian Capital Territory': 'ACT',
    'Northland': 'NTL',
    'Auckland': 'AUK',
    'Waikato': 'WKO',
    'Bay of Plenty': 'BOP',
    'Gisborne': 'GIS',
    "Hawke's Bay": 'HKB',
    'Taranaki': 'TKI',
    'Manawatu-Whanganui': 'MWT',
    'Wellington': 'WGN',
    'Tasman': 'TAS',
    'Nelson': 'NSN',
    'Marlborough': 'MBH',
    'West Coast': 'WTC',
    'Canterbury': 'CAN',
    'Otago': 'OTA',
    'Southland': 'STL'
} as const;

export const NON_ELIGIBILITY_FIELD_VALUES = ['Citizen/Resident', 'Charity', 'Trademark'] as const;
