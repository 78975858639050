import { VentraUserRouteTemplate } from 'App/components/templates/ventra-user';
import { useBrandStore } from 'config/hooks/useBrandStore';
import React from 'react';
import { IntaserveUserRouteTemplate } from './templates/intaserve-user';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type AppRouteTemplateComponent = React.FC<{
    children: React.ReactNode; // Outlet at the moment
}>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Expects an Outlet as children for clarity on where the outlet is being rendered. I think it is clearer when you
 * can clearly see the Outlet directly in the route component so you don't have to go looking for the outlet at each layer of the app.
 * (Lleyton's highly volatile opinion - 26/07/2024)
 */
export const AppRouteTemplate: AppRouteTemplateComponent = ({ children }) => {
    /***** HOOKS *****/
    const { application } = useBrandStore();

    /***** RENDER *****/
    switch (application) {
        case 'VIPcontrol': {
            return <VentraUserRouteTemplate outlet={children} />;
        }
        case 'Intaserve': {
            return <IntaserveUserRouteTemplate outlet={children} />;
        }
        default: {
            return children;
        }
    }
};
