import { NXTooltip } from 'components/NXTooltip';
import { Flex } from 'components/Utils/Flex';
import Text from 'components/Utils/Text';
import { RenderForBrands } from 'config/brandRenderer/component';
import { useBrandStore } from 'config/hooks/useBrandStore';
import { InfoBubble } from 'containers/orderingForm/infoBubble';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Pricing = React.FC<{
    price: string;
    premium: boolean;
    oldPrice?: string;
}>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const Pricing: Pricing = ({ price, premium, oldPrice }) => {
    /***** HOOKS *****/
    const { activeBrand } = useBrandStore();

    const percent = oldPrice ? ((1 - Number(price) / Number(oldPrice)) * 100).toFixed(0) : null;
    const filteredOldPrice = oldPrice && oldPrice !== '0.00' ? oldPrice : null;

    /***** RENDER *****/
    return (
        <Flex align="center">
            <RenderForBrands brands={['ventra']}>
                {filteredOldPrice && <Text strikethrough secondary>{`$${filteredOldPrice}/yr`}</Text>}
            </RenderForBrands>
            <NXTooltip
                title={
                    filteredOldPrice && activeBrand === 'ventra'
                        ? '*Discount applies to the first year on new standard registrations only. Premium registrations are not included'
                        : ''
                }
                tooltipClassName="recommendedDomains__informationTooltip"
            >
                <Text
                    className={filteredOldPrice && activeBrand === 'ventra' ? 'recommendedDomains__informationPrice' : ''}
                >{`$${price}/yr${filteredOldPrice && activeBrand === 'ventra' ? '*' : ''}`}</Text>
            </NXTooltip>
            <RenderForBrands brands={['ventra']}>
                {filteredOldPrice && <InfoBubble label={`Save ${percent}%`} className="recommendedDomains__informationPill" />}
            </RenderForBrands>
            {premium && (
                <NXTooltip
                    title="We offer Premium domains via our registry partners. Premium domain names are usually valued higher because they are brandable keywords, short in length, and/or dictionary words."
                    tooltipClassName="recommendedDomains__informationTooltip"
                >
                    <InfoBubble label="Premium" />
                </NXTooltip>
            )}
        </Flex>
    );
};
