import noitemimg from 'assets/images/support/eticket_banner_icon.svg';
import Anchor from 'components/Anchor';
import Box from 'components/Box';
import { SolidButton } from 'components/Buttons/SolidButton';
import Table from 'components/Table';
import { withBrandStore } from 'config/hooks/useBrandStore';
import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';
import { withRouter } from 'utilities/methods/tanstack/router/withRouter';
import { getOpenTickets } from '../action';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class Tickets extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openTickets: false,
            ticketMatrix: null
        };
    }

    componentDidMount() {
        const { getOpenTickets } = this.props;
        getOpenTickets();
    }

    componentDidUpdate(prevProps) {
        const { dashboard_tickets_status, dashboard_tickets_data, history } = this.props;
        registerScrollEvents(this, dashboard_tickets_status === 'success' && prevProps.dashboard_tickets_status === 'loading');

        if (dashboard_tickets_status === 'success' && prevProps.dashboard_tickets_status === 'loading') {
            if (dashboard_tickets_data && dashboard_tickets_data.length > 0) {
                const stagedMatrix = Array(dashboard_tickets_data.length).fill();
                Object.keys(stagedMatrix).forEach((value) => {
                    const { attributes } = dashboard_tickets_data[value];
                    const { subject, mask, department, staffLastResponse, updated_date, worker, lastMessage } = attributes;

                    const trimmedSubject = () => {
                        if (subject && subject.length > 149) {
                            return subject.substring(0, 149) + '...';
                        } else {
                            return subject;
                        }
                    };

                    const trimmedMessage = () => {
                        if (lastMessage && lastMessage.length > 50) {
                            return lastMessage.substring(0, 50) + '...';
                        } else {
                            return lastMessage;
                        }
                    };

                    stagedMatrix[value] = {
                        rowClass: `${staffLastResponse ? 'unread' : ''}`,
                        heading: (
                            <div className="OpenTickets__list-item-details">
                                <div className="OpenTickets__list-item-ticket">
                                    <Anchor
                                        onClick={() => {
                                            history.push(`/support/tickets/view/${mask}`);
                                        }}
                                        className="OpenTickets__list-item-heading"
                                    >
                                        {trimmedSubject()}
                                    </Anchor>
                                    <div className="OpenTickets__list-item-mask">
                                        <div className="OpenTickets__list-item-department">{department}</div>
                                        <div className="OpenTickets__list-item-circle" />
                                        <div className="OpenTickets__list-item-ticket-id">{mask}</div>
                                    </div>
                                </div>
                                <div className="OpenTickets__list-item-crumbs">
                                    <div className={`OpenTickets__list-item-worker ${worker ? 'staff-response' : ''} `}>
                                        {worker ? worker + ' replied' : 'You said'}
                                    </div>
                                    <div className="OpenTickets__list-item-message">
                                        <div className="OpenTickets__list-item-lastUpdate">{updated_date}:</div>
                                        {trimmedMessage().replace(/<\/?[^>]+(>|$)/g, '')}
                                    </div>
                                </div>
                            </div>
                        ),
                        actions: {
                            label: 'View',
                            type: 'onClick',
                            size: 'small',
                            color: 'primary',
                            onClick: (e) => {
                                e.preventDefault();
                                history.push(`/support/tickets/view/${mask}`);
                            },
                            list: [
                                {
                                    label: 'Reply',
                                    type: 'onClick',
                                    onClick: (e) => {
                                        e.preventDefault();
                                        history.push(`/support/tickets/view/${mask}?reply=true`);
                                    }
                                }
                            ]
                        }
                    };
                });

                this.setState({
                    openTickets: true,
                    ticketMatrix: stagedMatrix
                });
            } else {
                this.setState({
                    openTickets: false
                });
            }
        }
    }

    render() {
        const { dashboard_tickets_status, history, ticketName } = this.props;
        const { openTickets, ticketMatrix } = this.state;

        const renderTitle = () => {
            if (openTickets) {
                return {
                    title: `${ticketName}s`,
                    subtitle: (
                        <Anchor className="viewAll" to="/support/tickets">
                            See all {ticketName}s
                        </Anchor>
                    ),
                    action: {
                        label: `Submit new ${ticketName}`,
                        className: `submitTicket`,
                        type: 'onClick',
                        size: 'medium',
                        color: 'primary',
                        buttonType: 'Solid',
                        onClick: (e) => {
                            e.preventDefault();
                            history.push('/support/tickets/submit');
                        }
                    }
                };
            }

            return {
                title: `${ticketName}s`,
                subtitle: (
                    <Anchor className="viewAll" to="/support/tickets">
                        See all {ticketName}s
                    </Anchor>
                )
            };
        };

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <div
                ref={(el) => {
                    this.scrollRef = el;
                }}
                className="dashboardTickets"
            >
                <Box
                    request={{
                        action: getOpenTickets,
                        args: false,
                        status: dashboard_tickets_status
                    }}
                    className="dashboardTickets__box"
                    title={renderTitle()}
                    status={dashboard_tickets_status}
                    custom={{
                        render: (
                            <div className={`dashboardTickets__bottom ${openTickets ? '' : 'empty'}`}>
                                {openTickets ? (
                                    <Table
                                        embedded={true}
                                        className="dashboardTickets__table"
                                        header={false}
                                        matrix={ticketMatrix}
                                        layered={true}
                                        hasRowClass={true}
                                    />
                                ) : (
                                    <div className="dashboardTickets__bottom--wrapper">
                                        <img className="empty__image" src={noitemimg} alt="No Items" />
                                        <div className="empty__desc">You have no current {ticketName}s.</div>
                                        <SolidButton
                                            color="primary"
                                            className="empty__action"
                                            type="onClick"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                history.push('/support/tickets/submit');
                                            }}
                                        >
                                            Submit a New {ticketName}
                                        </SolidButton>
                                    </div>
                                )}
                            </div>
                        ),
                        pos: 'bottom'
                    }}
                />
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => {
    return {
        dashboard_tickets_status: state.dashboard.dashboard_tickets_status,
        dashboard_tickets_data: state.dashboard.dashboard_tickets_data,
        dashboard_tickets_error: state.dashboard.dashboard_tickets_error,
        sidebarRefs: state.sidebar.sidebarRefs
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getOpenTickets
        },
        dispatch
    );

export default withBrandStore(withRouter(connect(mapStateToProps, mapDispatchToProps)(Tickets)));
