import { useNavigate } from '@tanstack/react-router';
import { OutlineButton } from 'components/Buttons/OutlineButton';
import { OutlineDropdown } from 'components/Dropdowns/OutlineDropdown';
import { clearSubAccountData, forceResetHostingInfo, resetHostingAccount } from 'containers/hosting/state/accountActions';
import { resetHostingAdmin } from 'containers/hosting/state/adminActions';
import { resetHostingConfiguration } from 'containers/hosting/state/configActions';
import { resetResellerState } from 'containers/hosting/state/resellerActions';
import { resetHostingSecurity } from 'containers/hosting/state/securityActions';
import { useHostingContext } from 'containers/services/modules/hosting/context';
import { useDispatch } from 'react-redux';
import type { API } from 'utilities/api/services';
import { NXQuery } from 'utilities/query';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type ListServiceProduct = Awaited<API.hosting.listNew.ReturnType>['data'][number]['attributes']['product'];
type SearchServiceProduct = Awaited<API.hosting.searchNew.ReturnType>['data'][number]['attributes']['product'];

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
const getIsReseller = (serviceProduct: ListServiceProduct | SearchServiceProduct) =>
    Boolean(serviceProduct && 'is_reseller' in serviceProduct && serviceProduct?.is_reseller);

const getIsPlesk = (serviceProduct: ListServiceProduct | SearchServiceProduct) =>
    Boolean(serviceProduct && 'server_type' in serviceProduct && serviceProduct?.server_type?.startsWith('plesk'));

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const LoginButton = () => {
    /***** HOOKS *****/
    const { attributes, id } = useHostingContext();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    /***** QUERIES *****/
    const [loginCpanel] = NXQuery.Utils.usePopupMutation(NXQuery.hosting.loginCpanel);
    const [loginPlesk] = NXQuery.Utils.usePopupMutation(NXQuery.hosting.loginPlesk);
    const [loginWhm] = NXQuery.Utils.usePopupMutation(NXQuery.hosting.loginWHM);

    /***** FUNCTIONS *****/
    const handleListAccounts = () => {
        resetHostingAccount()(dispatch);
        resetHostingSecurity()(dispatch);
        resetHostingConfiguration()(dispatch);
        resetHostingAdmin()(dispatch);
        resetResellerState()(dispatch);
        clearSubAccountData();
        forceResetHostingInfo()(dispatch);
        navigate({
            to: '/my-services/hosting/$serviceId/reseller',
            params: { serviceId: String(id) },
            hash: 'subaccounts'
        });
    };

    /***** RENDER *****/
    if (getIsReseller(attributes?.product)) {
        return (
            <OutlineDropdown
                title="WHM"
                size="medium"
                color="warning"
                titleOnClick={() => loginWhm(id)}
                list={[
                    {
                        label: 'List Accounts',
                        type: 'onClick',
                        onClick: handleListAccounts
                    }
                ]}
            />
        );
    }

    if (getIsPlesk(attributes?.product)) {
        return (
            <OutlineButton onClick={() => loginPlesk(id)} size="medium" color="warning">
                Login
            </OutlineButton>
        );
    }

    return (
        <OutlineButton onClick={() => loginCpanel({ id })} size="medium" color="warning">
            cPanel
        </OutlineButton>
    );
};
