import { _Modal as Modal } from 'components/Lightboxes/Modal/_Modal';
import type { ModalNamespace } from 'components/Lightboxes/Modal/types';
import React from 'react';
import './_CompactModal.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type _CompactModal = React.FC<ModalNamespace.BaseProps>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _CompactModal: _CompactModal = ({ isOpen, onClose, children, ariaLabel }) => (
    <Modal className="CompactModal" isOpen={isOpen} onClose={onClose} ariaLabel={ariaLabel} preventScrollBlock>
        {children}
    </Modal>
);
