import { useRegistrationOrderingFormContext } from 'App/components/templates/contexts/registrationOrderingFormContext/useRegistrationOrderingFormContext';
import classNames from 'classnames';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import { Flex } from 'components/Utils/Flex';
import { Padding } from 'components/Utils/Padding';
import Text from 'components/Utils/Text';
import { useBrandStore } from 'config/hooks/useBrandStore';
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type PageHeader = React.FC<{
    backButton?: boolean;
    title?: string;
    backOnClick?: () => void;
}>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const PageHeader: PageHeader = ({ backButton = false, title, backOnClick = () => {} }) => {
    /***** HOOKS *****/
    const { setIsDomainRegistrationFormOpen } = useRegistrationOrderingFormContext();
    const isMobile = useAppViewport(['xs', 'sm']);
    const { activeBrand } = useBrandStore();

    /***** RENDER *****/
    return (
        <Padding bottom={isMobile ? 0 : 8}>
            <Flex justify="between" align={isMobile ? 'start' : 'center'}>
                <Flex direction={isMobile ? 'column' : 'row'} align={isMobile ? 'start' : 'center'}>
                    {backButton && (
                        <button onClick={() => backOnClick()} className={classNames({ circleActionButton: activeBrand === 'intaserve' })}>
                            <PhosphorIcons.Caret.Left.Bold size={25} />
                        </button>
                    )}
                    <Text size--custom={isMobile ? 20 : 25} bold>
                        {title}
                    </Text>
                </Flex>
                <button
                    onClick={() => setIsDomainRegistrationFormOpen(false)}
                    className={classNames({ circleActionButton: activeBrand === 'intaserve' })}
                >
                    <PhosphorIcons.X.Bold size={25} />
                </button>
            </Flex>
        </Padding>
    );
};
