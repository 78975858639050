import { queryOptions, useQuery, useSuspenseQuery } from '@tanstack/react-query';
import type { OrderingApi } from 'utilities/api/ordering';
import { ORDERING_API } from 'utilities/api/ordering';
import { createBaseQueryKey } from 'utilities/methods/tanstack/createBaseQueryKey';

function createQueryKey(params: OrderingApi.Products.POST.Params) {
    return createBaseQueryKey(['products', params.ids, params.promo] as const);
}

function createQueryOptions(params: OrderingApi.Products.POST.Params) {
    return queryOptions({
        queryKey: createQueryKey(params),
        queryFn: () => ORDERING_API.products.POST(params),
        staleTime: Infinity,
        enabled: Boolean(params.ids.length && params.ids[0]),
        select: (data) => data.data
    });
}

function _useSuspenseQuery(params: OrderingApi.Products.POST.Params) {
    return useSuspenseQuery(createQueryOptions(params));
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useQuery(params: OrderingApi.Products.POST.Params) {
    return useQuery(createQueryOptions(params));
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const getProducts = Object.freeze({
    useQuery: _useQuery,
    useSuspenseQuery: _useSuspenseQuery
});
