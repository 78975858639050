/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { RegistrationOrderingFormContext } from 'App/components/templates/contexts/registrationOrderingFormContext/context';
import { LiveChat } from 'components/LiveChat';
import PostLoginChecklist from 'containers/dashboard/Components/postLoginChecklist';
import { OrderingForm } from 'containers/orderingForm';
import { useState } from 'react';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import Header from 'components/Header';
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';
import './_intaserveUser.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type IntaserveUserRouteTemplateComponent = React.FC<{
    outlet: React.ReactNode;
}>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Renders User related components when the route is authenticated.
 */
export const IntaserveUserRouteTemplate: IntaserveUserRouteTemplateComponent = ({ outlet }) => {
    /***** STATE *****/
    const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);

    /***** HOOKS *****/
    const [isDomainRegistrationFormOpen, setIsDomainRegistrationFormOpen] = useState(false);
    const [isRegistration, setIsRegistration] = useState(true);
    const isMobile = useAppViewport(['xs', 'sm']);
    const isTablet = useAppViewport(['md']);

    /***** RENDER HELPERS *****/
    function getColumns() {
        if (isTablet) return '235px minmax(100px, 1250px)';

        return '300px minmax(100px, 1250px)';
    }

    /***** RENDER *****/
    return (
        <div className="UserLayout">
            <Header />
            {/* IF mobile show a top navbar */}
            {/* {isMobile && <MobileHeader setIsMobileSidebarOpen={setIsMobileSidebarOpen} />} */}
            {/* IF not mobile and not login page THEN wrap in a grid */}
            {/* <WrapWithComponent component={Grid} wrap={!isMobile && !isLoginRoute} columns={getColumns()} alignItems--start className="intaserveUser">
                <IntaserveSideNavbar isMobileSidebarOpen={isMobileSidebarOpen} setIsMobileSidebarOpen={setIsMobileSidebarOpen} /> */}
            <RegistrationOrderingFormContext.Provider
                value={{ isDomainRegistrationFormOpen, setIsDomainRegistrationFormOpen, isRegistration, setIsRegistration }}
            >
                {outlet}
                <OrderingForm />
            </RegistrationOrderingFormContext.Provider>
            {/* </WrapWithComponent> */}
            <LiveChat />
            <PostLoginChecklist />
        </div>
    );
};
