import classNames from 'classnames';
import { PropInjector } from 'components/Utils/PropInjector';
import { createAppliedStylingClasses } from 'components/Utils/methods';
import type { AllTokens, ColorTokens, ExcludePrimitiveToken } from 'config/tokens/base';
import React, { createElement } from 'react';
import { useStyle } from 'utilities/hooks/useStyle';
import { useTheme } from 'utilities/hooks/useTheme';
import './_Border.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type RadiusTokens<T> = T extends `${string}border-radius${string}` ? T : never;
type BorderColors = 'notice' | 'warn' | 'primary';

type BorderComponentProps = {
    'children'?: React.ReactNode;
    'inject'?: boolean;
    'colour'?: BorderColors | ExcludePrimitiveToken<ColorTokens>;
    'radius'?: RadiusTokens<AllTokens>;
    'top'?: boolean | number;
    'right'?: boolean | number;
    'bottom'?: boolean | number;
    'left'?: boolean | number;
    'all'?: boolean | number;
    'dashed'?: boolean;
    'dotted'?: boolean;
    'className'?: string;
    'data-testid'?: string;
};

type BorderComponent = <TAs extends keyof HTMLElementTagNameMap | React.ComponentType<any> = 'div'>(
    props: BorderComponentProps & {
        as?: TAs;
    },
    ref: React.ForwardedRef<TAs extends keyof HTMLElementTagNameMap ? HTMLElementTagNameMap[TAs] : never>
) => JSX.Element;

const appliedStylingPropTypeKeys = ['top', 'right', 'bottom', 'left', 'all', 'dotted', 'dashed', 'inject', 'radius'];

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 *  Adds a border to the child component or wraps in a div.
 */
const _Border: BorderComponent = ({ children, className, as: component = 'div', ...props }, ref) => {
    const { inject, colour, radius, ...restProps } = props;

    /***** HOOKS *****/
    const themeStyles = useTheme<BorderColors>({
        '--Border-color': colour,
        '--Border-radius': radius
    });

    const dynamicStyles = useStyle({
        '--Border-thickness-top': props.top,
        '--Border-thickness-right': props.right,
        '--Border-thickness-bottom': props.bottom,
        '--Border-thickness-left': props.left,
        '--Border-thickness-all': props.all
    });

    /***** RENDER HELPERS *****/
    const appliedStylingClasses = createAppliedStylingClasses({
        props,
        keyBoundary: appliedStylingPropTypeKeys,
        componentName: 'Border',
        delimiter: '--'
    });

    const styles = {
        ...themeStyles,
        ...dynamicStyles
    };

    const borderClasses = classNames('Border', className, appliedStylingClasses, {
        [`Border--colour-${colour}`]: colour
    });

    /***** RENDER *****/
    return (
        <PropInjector inject={inject} injectableProps={{ className: borderClasses, ref, ...restProps }} injectable={children}>
            {createElement(component as any, { className: borderClasses, style: styles, ref, ...restProps }, children)}
        </PropInjector>
    );
};

function fixedForwardRef<T, P = {}>(
    render: (props: P, ref: React.Ref<T>) => React.ReactNode
): (props: P & React.RefAttributes<T>) => React.ReactNode {
    return React.forwardRef(render) as any;
}

export const Border = fixedForwardRef(_Border);
