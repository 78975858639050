import { ErrorBoundary } from '@sentry/react';
import classNames from 'classnames';
import FetchComponentError from 'components/Errors/FetchComponentError';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import RequestLoader from 'components/Loaders/Request';
import { TabsContext } from 'components/NXBox/Tabs/contexts/useTabContext';
import Text from 'components/Utils/Text';
import React, { Suspense, useRef, useState } from 'react';
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';
import './_NXBoxTabsManager.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type NXBoxTabManager = React.FC<TabsManagerProps>;
type TabsManagerProps = {
    children: React.ReactNode;
    active?: string;
    setActive?: React.Dispatch<React.SetStateAction<string>>;
    defaultSelection: string;
    defaultMobileTitle: string;
    className?: string;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _NXBoxTabManager: NXBoxTabManager = ({
    children,
    active: propActive,
    setActive: propSetActive,
    defaultSelection = '',
    defaultMobileTitle = '',
    className
}) => {
    /***** STATE *****/
    const [internalActive, internalSetActive] = useState(defaultSelection);
    const [showMobileTabs, setShowMobileTabs] = useState(false);

    /***** HOOKS *****/
    const tabRefs = useRef<HTMLButtonElement[]>([]);
    const portalRef = useRef(null);
    const isMobile = useAppViewport(['xs', 'sm']);

    const active = propActive ?? internalActive;
    const setActive = propSetActive ?? internalSetActive;
    const currentTab = tabRefs.current.find((ref) => ref?.dataset.name === String(active));

    /***** FUNCTIONS *****/
    const handleKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
        if (!currentTab) return;
        const currentIndex = tabRefs.current.indexOf(currentTab);

        function nextIndex() {
            if (currentIndex === tabRefs.current.length - 1) return 0;
            return currentIndex + 1;
        }

        function prevIndex() {
            if (currentIndex === 0) return tabRefs.current.length - 1;
            return currentIndex - 1;
        }

        switch (e.key) {
            case 'ArrowRight':
                setActive(tabRefs.current[nextIndex()]?.dataset.name || defaultSelection);
                tabRefs.current[nextIndex()]?.focus();
                break;
            case 'ArrowLeft':
                setActive(tabRefs.current[prevIndex()]?.dataset.name || defaultSelection);
                tabRefs.current[prevIndex()]?.focus();
                break;
            case 'Home':
                setActive(tabRefs.current[0]?.dataset.name || defaultSelection);
                tabRefs.current[0]?.focus();
                break;
            case 'End':
                setActive(tabRefs.current.at(-1)?.dataset.name || defaultSelection);
                tabRefs.current.at(-1)?.focus();
                break;
            default:
                break;
        }
    };

    const tabManagerClasses = classNames('tabsManager', className);

    /***** RENDER *****/
    return (
        <TabsContext.Provider value={{ tabRefs, active, setActive, portalRef, showMobileTabs, setShowMobileTabs, isMobile, handleKeyDown }}>
            <ErrorBoundary fallback={<FetchComponentError />}>
                <Suspense fallback={<RequestLoader />}>
                    <div className={tabManagerClasses}>
                        <Text
                            as="button"
                            onClick={() => setShowMobileTabs(!showMobileTabs)}
                            className="tabsManager__mobileTabTitle"
                            color="S_color_text_inverse_base"
                            bold
                            size--xss
                        >
                            <div ref={portalRef}>
                                {!active && <div className="tabsManager__mobileTabTitle--defaultMessage">{defaultMobileTitle}</div>}
                            </div>
                            <PhosphorIcons.Caret.Down.Bold className=" icon chevronIcon" />
                        </Text>
                        {children}
                    </div>
                </Suspense>
            </ErrorBoundary>
        </TabsContext.Provider>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
