import { useSearch } from '@tanstack/react-router';
import intaserveLogo from 'assets/images/intaserve-header-logo.svg';
import googleIcon from 'assets/images/login/google-oAuth-icon.png';
import microsoftIcon from 'assets/images/login/microsoft-oAuth-icon.png';
import Anchor from 'components/Anchor';
import { Flex } from 'components/Utils/Flex';
import Text from 'components/Utils/Text';
import 'containers/login/forms/IntaserveLogin/_intaserveLogin.scss';
import { OAuthButton } from 'containers/login/forms/IntaserveLogin/oAuthButton';

// Google's OAuth 2.0 endpoint for requesting an access token
const googleOAuthEndpoint = 'https://accounts.google.com/o/oauth2/v2/auth?';

// Microsofts's OAuth 2.0 endpoint for requesting an access code
const microsoftOAuthEndpoint = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?`;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const IntaserveLoginAuth = () => {
    /***** HOOKS *****/
    const { token } = useSearch({ from: '/_login/login' });

    /***** FUNCTIONS *****/
    function handleGoogleOAuth() {
        const clientId = import.meta.env.VITE_GOOGLE_CLIENT_ID;
        const redirectUri = token ? import.meta.env.VITE_GOOGLE_ADDITIONAL_USER_REDIRECT_URL : import.meta.env.VITE_GOOGLE_REDIRECT_URL;

        if (!clientId || !redirectUri) {
            return console.error('missing environment variables');
        }

        const params = new URLSearchParams({
            client_id: clientId,
            redirect_uri: redirectUri,
            response_type: 'token',
            scope: 'openid email profile',
            include_granted_scopes: 'true',
            state: token || null,
        });

        return (window.location.href = `${googleOAuthEndpoint}${params.toString()}`);
    }

    function handleMicrosoftOAuth() {
        const clientId = import.meta.env.VITE_MICROSOFT_CLIENT_ID;
        const redirectUri = token ? import.meta.env.VITE_MICROSOFT_ADDITIONAL_USER_REDIRECT_URL : import.meta.env.VITE_MICROSOFT_REDIRECT_URL;

        const stateObj = { token: token || null, redirectUri };

        const params = new URLSearchParams({
            client_id: clientId,
            response_type: 'code',
            redirect_uri: redirectUri,
            scope: 'User.Read',
            state: JSON.stringify(stateObj),
        });

        if (!clientId || !redirectUri) {
            return console.error('missing environment variables for Microsoft OAuth');
        }

        return (window.location.href = `${microsoftOAuthEndpoint}${params.toString()}`);
    }

    /***** RENDER *****/
    return (
        <Flex direction="column" justify="center" align="center" className="intaserveLogin__container">
            <img src={intaserveLogo} alt="logo" className="intaserveLogin__logo" />
            <Text size--custom={15}>{token ? 'Accept invitation with your organisation account' : 'Sign in to continue to your dashboard'}</Text>
            <Flex direction="column" fullWidth>
                <OAuthButton icon={googleIcon} provider="Google" onClick={handleGoogleOAuth} />
                <OAuthButton icon={microsoftIcon} provider="Microsoft" onClick={handleMicrosoftOAuth} />
            </Flex>
            <Text size--custom={12}>
                By continuing, you agree to Intaserve's{' '}
                <Anchor href="https://intaserve.au/terms-of-service" target="_blank">
                    <Text semiBold size--custom={12}>
                        Terms of Service.
                    </Text>
                </Anchor>
                <br />
                Read our{' '}
                <Anchor href="https://intaserve.au/privacy-policy" target="_blank">
                    {' '}
                    <Text semiBold size--custom={12}>
                        Privacy Policy
                    </Text>
                </Anchor>
            </Text>
        </Flex>
    );
};
