/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useDispatch } from 'react-redux';
import { useMicrosoft365TableSelector } from '../own';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import SelectDropdown from 'components/Dropdowns/SelectDropdown';
import Search from 'components/Search';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { generateCreateShowDropdownOption } from 'containers/services/modules/helpers';
import { cancelSearchMs365 } from 'utilities/api/services';
import { useMicrosoft365TableContext } from '../own';

/*   ACTIONS
 *****************************************************/
import { ms365Search, resetMs365Search } from 'containers/services/action';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
/**
 * @typedef {import('../types').NMicrosoft365Table.TMicrosoft365TableTitleBar} TTitleBar
 */

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {TTitleBar}
 */
export const Microsoft365TableTitleBar = ({ title }) => {
    /***** HOOKS *****/
    const { ms365_list_meta, ms365_list_status } = useMicrosoft365TableSelector((state) => state.services);
    const { setNumberOfRows, setSearch } = useMicrosoft365TableContext();
    const dispatch = useDispatch();

    /***** FUNCTIONS *****/
    const createShowDropdownOption = generateCreateShowDropdownOption(ms365_list_meta?.total_all_records, setNumberOfRows);

    /***** RENDER *****/
    return (
        <div className="Microsoft365TableHeader">
            {/* Title */}
            <Text primary size--xl semiBold>
                {title}
            </Text>

            {/* Filters */}
            <div className="VPSTableHeader__filters">
                <Search
                    className="VPSTableHeader__search"
                    functions={{
                        cancel: cancelSearchMs365,
                        search: (search) => ms365Search(search)(dispatch),
                        reset: () => {
                            resetMs365Search()(dispatch);
                            setSearch();
                        }
                    }}
                    render={{ placeholder: 'Search for a Microsoft 365 subscription' }}
                    helpers={{ keyword: setSearch }}
                />
                <div className="VPSTableHeader__actions">
                    <SelectDropdown
                        options={[
                            createShowDropdownOption('10'),
                            createShowDropdownOption('20'),
                            createShowDropdownOption('50'),
                            createShowDropdownOption('All')
                        ]}
                        noSelectionLabel="Show"
                        disabled={ms365_list_status === 'loading'}
                    />
                </div>
            </div>
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
