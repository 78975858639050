import classNames from 'classnames';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import { Flex } from 'components/Utils/Flex';
import Text from 'components/Utils/Text';
import { useBrandStore } from 'config/hooks/useBrandStore';
import { InfoBubble } from 'containers/orderingForm/infoBubble';
import 'containers/orderingForm/infoBubble/_infoBubble.scss';
import 'containers/orderingForm/orderingFormInputs/toggleSwitch/_orderingFormToggleSwitchIntaserve.scss';
import { forwardRef, type ForwardedRef } from 'react';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type ToggleSwitchProps = {
    value: boolean;
    onChange: (value: boolean) => void;
    label?: string;
    description?: string;
    recommended?: boolean;
    radio?: boolean;
    isDisabled?: boolean;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _ToggleSwitchHookFormIntaserve = forwardRef<HTMLButtonElement, ToggleSwitchProps>(
    ({ value, onChange, label, description, recommended, radio, isDisabled }, ref: ForwardedRef<HTMLButtonElement>) => {
        /***** HOOKS *****/
        const { activeBrand } = useBrandStore();

        /***** FUNCTIONS *****/
        const handleClick = (e: React.MouseEvent<HTMLButtonElement> | React.ChangeEvent<HTMLInputElement>) => {
            e.preventDefault();
            onChange(!value);
        };

        /***** RENDER HELPERS *****/
        const renderLabel = () => {
            if (label && recommended) {
                return (
                    <Flex align="center">
                        <Text size--xm bold>
                            {label}
                        </Text>
                        <InfoBubble
                            className={classNames('infoBubble__labelGreen', { intaserve: activeBrand === 'intaserve' })}
                            label="Recommended"
                        />
                    </Flex>
                );
            }

            if (label) {
                return (
                    <Text size--xm bold>
                        {label}
                    </Text>
                );
            }
            return;
        };

        /***** RENDER *****/
        return (
            <Flex align="center" gap={10}>
                <Flex direction="column">
                    {renderLabel()}
                    {description && <Text>{description}</Text>}
                </Flex>
                {radio ? (
                    <button
                        disabled={isDisabled}
                        ref={ref}
                        className={`toggleRadio ${value ? 'toggleRadio--on' : 'toggleRadio--off'}`}
                        onClick={handleClick}
                    >
                        {value && <PhosphorIcons.Check.Bold />}
                    </button>
                ) : (
                    <button
                        disabled={isDisabled}
                        ref={ref}
                        className={`toggleSwitch ${value ? 'toggleSwitch--on' : 'toggleSwitch--off'}`}
                        onClick={handleClick}
                    >
                        <div className={classNames('toggleSwitch__thumb', { 'toggleSwitch__thumb--active': value })} />
                    </button>
                )}
            </Flex>
        );
    }
);

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
_ToggleSwitchHookFormIntaserve.displayName = 'ToggleSwitchIntaserve';
