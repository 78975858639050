import classNames from 'classnames';
import Grid from 'components/Grid';
import { NXTableContext } from 'components/NXTable/NXTableContext';
import React, { useContext } from 'react';
import './_NXTableRow.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Table row, automatically gets the columns passed.
 *
 * If the developer wishes to override the `justify-self` property on one of the children, The developer will only need to do it where they are defining the child with a single `!important`.
 * @param {{
 *   children: React.ReactNode,
 *   className?: string,
 *   gap?: number
 * }} props
 */
export function Row({ children, className, gap }) {
    /***** HOOKS *****/
    const { columns } = useContext(NXTableContext);

    const rowClassNames = classNames('NXTableRow', 'NXTableRow__alternator', className);

    /***** RENDER *****/
    return (
        <Grid className={rowClassNames} columns={columns} gap={gap}>
            {children}
        </Grid>
    );
}
