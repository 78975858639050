import { queryOptions, useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { katanaQueryKeys } from 'containers/katana/queries/katanaQueryKeys';
import type { KatanaNamespace } from 'containers/katana/types';
import _ from 'lodash';
import { queryClient } from 'store/queryClient';
import { KATANA_API } from 'utilities/api/katana';
import { SECOND } from 'utilities/consts';
import { createGetQueryDataMethod } from 'utilities/methods/tanstack/createGetQueryDataMethod';
import { createSetQueryDataMethod } from 'utilities/methods/tanstack/createSetQueryDataMethod';
import { createOptimisticMethods } from 'utilities/methods/tanstack/optimistic/createOptimisticMethods';
import { createOptimisticResponseObject } from 'utilities/methods/tanstack/optimistic/createOptimisticResponseObject';
import type { NXQueryUtils } from 'utilities/methods/tanstack/types';

type TData = Awaited<ReturnType<typeof KATANA_API.katana.site.service_id.pages.GET>>;

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
function createQueryKey(serviceID: KatanaNamespace.ServiceId) {
    return katanaQueryKeys.katana.service.ID.pages(serviceID);
}

function createQueryOptions(serviceID: KatanaNamespace.ServiceId) {
    return queryOptions({
        queryKey: createQueryKey(serviceID),
        queryFn: async () => {
            const result = await KATANA_API.katana.site.service_id.pages.GET(serviceID);

            // Seed the individual page data - https://tkdodo.eu/blog/seeding-the-query-cache
            _.each(result.data, (page) => {
                // IDEA: opportunity for automated chaining and updating of dependant data. kind of like events?, i.e. if this data updates, it updates the child, if the child data updates and the parent exists, it updates the parent
                queryClient.setQueryData(
                    katanaQueryKeys.katana.service.ID.pages.ID({ pageID: page.id, serviceID }),
                    createOptimisticResponseObject<TData['data'][number]>(page)
                );
            });

            return result;
        },
        staleTime: SECOND * 25,
        enabled: Boolean(serviceID),
        select: (data) => {
            return data.data;
        },
    });
}

const setQueryData = createSetQueryDataMethod<KatanaNamespace.ServiceId, NXQueryUtils.ApiData200<TData>>(createQueryKey);
const getQueryData = createGetQueryDataMethod<KatanaNamespace.ServiceId, NXQueryUtils.ApiData200<TData>>(createQueryKey);

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Gets the pages on the service
 */
/**
 * NEEDS TO BE REFACTORED TO `createNXQuery`
 */
function _useQuery(serviceID: KatanaNamespace.ServiceId, opts: object = {}) {
    return useQuery({ ...createQueryOptions(serviceID), ...opts });
}

function _useSuspenseQuery(serviceID: KatanaNamespace.ServiceId) {
    return useSuspenseQuery(createQueryOptions(serviceID));
}

function useIsMultiPage(serviceID: KatanaNamespace.ServiceId) {
    const { data: pages } = _useQuery(serviceID);
    return pages ? pages.length > 1 : false;
}

export const pages = {
    useQuery: _useQuery,
    useSuspenseQuery: _useSuspenseQuery,
    getQueryData,
    optimistic: createOptimisticMethods(setQueryData),
    useIsMultiPage,
} as const;
