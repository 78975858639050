/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import htmr from 'htmr';
import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'utilities/methods/tanstack/router/withRouter';

import { DialogNotification } from 'components/Notifications/DialogNotification/index';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import { SolidButton } from 'components/Buttons/SolidButton';
import RequestLoader from 'components/Loaders/Request';
import { formatDescription } from 'utilities/methods/commonActions';

import { getHostingInformation } from 'containers/hosting/state/accountActions';
import { serviceRestoreInvoice } from 'containers/services/action';
import { COPY_restoreConfirmDialogNotification } from './consts';

/**********************************************************************************************************
 *   OVERLAY RESTORE COMPONENT START
 **********************************************************************************************************/
class OverlayRestore extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const { getHostingInformation, id } = this.props;
        getHostingInformation(id);
    }

    render() {
        const { history, serviceRestoreInvoice, hosting_information_status, hosting_information_data } = this.props;

        switch (hosting_information_status) {
            case 'success':
                return (
                    <div className="OverlayRestore">
                        <div className="OverlayRestore__wrapper">
                            <div className="OverlayRestore__domain">{hosting_information_data.attributes.name}</div>

                            <div className="OverlayRestore__row-spacing OverlayRestore__text">
                                Services that are terminated within 28 days are able to be restored back to the previous state using the Acronis
                                Backup system.
                            </div>
                            <div className={`OverlayRestore__row-spacing OverlayRestore__text--link `}>
                                <Anchor
                                    className="OverlayRestore__row-spacing OverlayRestore__text--link"
                                    to="/support/support-centre/web-hosting/ventraip-australia-take-backups-web-hosting-services"
                                >
                                    {' '}
                                    Read more about Acronis here!
                                </Anchor>
                            </div>
                            <div className="OverlayRestore__details">
                                <div className="OverlayRestore__items">
                                    <div className="item__row">
                                        <div className="item__column--description">
                                            {htmr(formatDescription(hosting_information_data.attributes.product.name))}
                                        </div>
                                        <div className="item__column--price">
                                            ${hosting_information_data.attributes.recurring_price}/{hosting_information_data.attributes.billing_cycle}
                                        </div>
                                    </div>
                                    <div className="item__row">
                                        <div className="item__column--description">
                                            {htmr(formatDescription(hosting_information_data.attributes.name))}
                                        </div>
                                    </div>
                                </div>
                                <div className="OverlayRestore__items">
                                    <div className="item__row">
                                        <div className="item__column--description">Recovery Fee</div>
                                        <div className="item__column--price">${hosting_information_data.attributes.recovery_fee}</div>
                                    </div>
                                </div>
                            </div>
                            <DialogNotification tail type="warning">
                                {COPY_restoreConfirmDialogNotification}
                            </DialogNotification>

                            <SolidButton
                                type="onClick"
                                className="OverlayRestore__button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    serviceRestoreInvoice(hosting_information_data.id);
                                }}
                            >
                                Restore
                            </SolidButton>
                        </div>

                        <div></div>
                    </div>
                );

            case 'error':
                return (
                    <div className="OverlayRestore">
                        <div className="OverlayRestore__error">
                            We&apos;re unable to retrieve the requested hosting details. Please try again later. If you believe there may be an issue
                            and require assistance, feel free to submit a{' '}
                            <Anchor
                                className="support-link"
                                onClick={() => {
                                    history.push(`/support/tickets/submit`);
                                }}
                            >
                                support ticket
                            </Anchor>
                        </div>
                    </div>
                );

            case 'loading':
            default:
                return (
                    <div className="OverlayRestore">
                        <RequestLoader />
                    </div>
                );
        }
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => {
    return {
        hosting_information_status: state.hosting.hosting_information_status,
        hosting_information_data: state.hosting.hosting_information_data
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            serviceRestoreInvoice,
            getHostingInformation
        },
        dispatch
    );

const connected = connect(mapStateToProps, mapDispatchToProps)(OverlayRestore);
const routed = withRouter(connected);

export default routed;
