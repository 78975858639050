import { FormItem } from 'components/Form/FormItem';
import { FormItemInner } from 'components/Form/FormItemInner';
import { FormLabel } from 'components/Form/FormLabel';
import { _ActiveRadioRender as ActiveRadioRender } from 'components/Form/Radio/_Circle/_RadioCircleButton/_ActiveRadioRender/_ActiveRadioRender';
import type { ActiveRadioRenderNamespace } from 'components/Form/Radio/_Circle/_RadioCircleButton/_ActiveRadioRender/types';
import React from 'react';
import { useController } from 'react-hook-form';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _ActiveRadioRenderHookFormDefault: React.FC<ActiveRadioRenderNamespace.HookForm.Default.Props> = ({
    label,
    disabled,
    children,
    onSelect,
    className,
    fullWidth,
    name,
    FormItemInnerVariant = FormItemInner.HookForm
}) => {
    /***** HOOKS *****/
    const { field } = useController({ name });

    /***** FUNCTIONS *****/
    function handleChange(v: string) {
        onSelect?.(v);
        field.onChange(v);
    }

    /***** RENDER *****/
    return (
        <FormItem disabled={disabled} className={className}>
            <FormLabel htmlFor={name}>{label}</FormLabel>

            <FormItemInnerVariant name={name} noBackground fullWidth={fullWidth}>
                <ActiveRadioRender value={field.value} onChange={handleChange}>
                    {children}
                </ActiveRadioRender>
            </FormItemInnerVariant>
        </FormItem>
    );
};
