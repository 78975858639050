import React from 'react';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type NXBoxTabContentContainer = React.FC<NXBoxTabContentContainerProps>;
type NXBoxTabContentContainerProps = {
    children: React.ReactNode;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _NXBoxTabContentContainer: NXBoxTabContentContainer = ({ children }) => {
    /***** RENDER *****/
    return <div className="NXBoxTabs__content">{children}</div>;
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
