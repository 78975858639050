// https://icon-sets.iconify.design/ph/

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Chevron from 'components/Icons/Chevron';
import { Iconify } from 'components/Icons/Iconify';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { preloadIcons } from 'components/Icons/methods';
import { RowsFill2 } from 'components/Icons/RowsFill2/rows-fill2';
import { Section } from 'components/Icons/Section/section';

const Arrow = /** @type {const} */ {
    Left: Object.assign(Iconify('ph:arrow-left'), {
        Bold: Iconify('ph:arrow-left-bold'),
    }),
    Right: {
        Bold: Iconify('ph:arrow-right-bold'),
    },
};

const Caret = /** @type {const} */ {
    Up: Object.assign(Iconify('ph:caret-up'), {
        Bold: Iconify('ph:caret-up-bold'),
    }),
    Down: Object.assign(Iconify('ph:caret-down'), {
        Bold: Iconify('ph:caret-down-bold'),
        Fill: Iconify('ph:caret-down-fill'),
    }),
    Left: Object.assign(Iconify('ph:caret-left'), {
        Bold: Iconify('ph:caret-left-bold'),
        Fill: Iconify('ph:caret-left-fill'),
    }),
    Right: Object.assign(Iconify('ph:caret-right'), {
        Bold: Iconify('ph:caret-right-bold'),
        Fill: Iconify('ph:caret-right-fill'),
    }),
    UpDown: Object.assign(Iconify('ph:caret-up-down'), {
        Fill: Iconify('ph:caret-up-down-fill'),
    }),
};

const PhosphorIcons = Object.freeze({
    Amex: Iconify('formkit:amex'),
    Arrow,
    ArrowArcLeft: {
        Bold: Iconify('ph:arrow-arc-left-bold'),
    },
    ArrowArcRight: {
        Bold: Iconify('ph:arrow-arc-right-bold'),
    },
    ArrowElbowDownRight: {
        Bold: Iconify('ph:arrow-elbow-down-right-bold'),
    },
    ArrowFatLineRight: Object.assign(Iconify('ph:arrow-fat-line-right'), {
        Bold: Iconify('ph:arrow-fat-line-right-bold'),
    }),
    ArrowLineLeft: Iconify('ph:arrow-line-left'),
    ArrowLineRight: Iconify('ph:arrow-line-right'),
    ArrowsCounterClockwise: Object.assign(Iconify('ph:arrows-counter-clockwise'), {
        Bold: Iconify('ph:arrows-counter-clockwise-bold'),
    }),
    ArrowSquareOut: Iconify('ph:arrow-square-out'),
    Backspace: Iconify('ph:backspace'),
    BookmarkSimple: Object.assign(Iconify('ph:bookmark-simple'), {
        Bold: Iconify('ph:bookmark-simple-bold'),
    }),
    BookOpenText: {
        Fill: Iconify('ph:book-open-text-fill'),
    },
    Briefcase: {
        Fill: Iconify('ph:briefcase-fill'),
    },
    Caret,
    ChatCircle: {
        Fill: Iconify('ph:chat-circle-fill'),
    },
    ChatCircleDots: Iconify('ph:chat-circle-dots'),
    Check: Object.assign(Iconify('ph:check'), {
        Bold: Iconify('ph:check-bold'),
    }),
    CheckCircle: Object.assign(Iconify('ph:check-circle'), {
        Fill: Iconify('ph:check-circle-fill'),
        Bold: Iconify('ph:check-circle-bold'),
    }),
    CheckFat: {
        Fill: Iconify('ph:check-fat-fill'),
    },
    Chevron,
    ClipboardText: Object.assign(Iconify('ph:clipboard-text'), {
        Bold: Iconify('ph:clipboard-text-bold'),
    }),
    Copy: Iconify('ph:copy'),
    CornersIn: Object.assign(Iconify('ph:corners-in'), {
        Fill: Iconify('ph:corners-in-fill'),
    }),
    CornersOut: Object.assign(Iconify('ph:corners-out'), {
        Fill: Iconify('ph:corners-out-fill'),
    }),
    CreditCard: Iconify('ph:credit-card'),
    Desktop: {
        DuoTone: Iconify('ph:desktop-duotone'),
    },
    DeviceMobile: {
        DuoTone: Iconify('ph:device-mobile-duotone'),
    },
    DeviceTablet: {
        DuoTone: Iconify('ph:device-tablet-duotone'),
    },
    DotsSixVertical: {
        Bold: Iconify('ph:dots-six-vertical-bold'),
    },
    DotsThree: {
        Bold: Iconify('ph:dots-three-bold'),
    },
    ExclamationMark: Object.assign(Iconify('ph:exclamation-mark'), {
        Bold: Iconify('ph:exclamation-mark-bold'),
        Fill: Iconify('ph:exclamation-mark-fill'),
    }),
    Eye: Iconify('ph:eye'),
    EyeDropperFill: Iconify('ph:eyedropper-fill'),
    EyeSlash: Iconify('ph:eye-slash'),
    FastForward: Iconify('ph:fast-forward'),
    File: {
        Fill: Iconify('ph:file-fill'),
    },
    FileArrowDown: Object.assign(Iconify('ph:file-arrow-down'), {
        Bold: Iconify('ph:file-arrow-down-bold'),
        Fill: Iconify('ph:file-arrow-down-fill'),
    }),
    FloppyDiskBack: {
        Fill: Iconify('ph:floppy-disk-back-fill'),
    },
    Folders: Object.assign(Iconify('ph:folders'), {
        Fill: Iconify('ph:folders-fill'),
    }),
    GearFine: Object.assign(Iconify('ph:gear-fine'), {
        Fill: Iconify('ph:gear-fine-fill'),
    }),
    Gift: Object.assign(Iconify('ph:gift'), {
        Fill: Iconify('ph:gift-fill'),
    }),
    Globe: Object.assign(Iconify('ph:globe'), {
        Fill: Iconify('ph:globe-fill'),
        Bold: Iconify('ph:globe-bold'),
    }),
    Highlighter: {
        Bold: Iconify('ph:highlighter-bold'),
    },
    House: Object.assign(Iconify('ph:house'), {
        Fill: Iconify('ph:house-fill'),
    }),
    HouseLine: {
        Fill: Iconify('ph:house-line-fill'),
    },
    Image: Object.assign(Iconify('ph:image'), {
        Fill: Iconify('ph:image-fill'),
    }),
    Images: Object.assign(Iconify('ph:images'), {
        DuoTone: Iconify('ph:images-duotone'),
    }),
    Info: Object.assign(Iconify('ph:info'), {
        Bold: Iconify('ph:info-bold'),
    }),
    Layout: Object.assign(Iconify('ph:layout'), {
        Bold: Iconify('ph:layout-bold'),
    }),
    Link: {
        Bold: Iconify('ph:link-bold'),
    },
    LinkBreak: {
        Bold: Iconify('ph:link-break-bold'),
    },
    List: {
        Bold: Iconify('ph:list-bold'),
    },
    ListBullets: {
        Bold: Iconify('ph:list-bullets-bold'),
    },
    ListNumbers: {
        Bold: Iconify('ph:list-numbers-bold'),
    },
    Lock: Object.assign(Iconify('ph:lock'), {
        Fill: Iconify('ph:lock-fill'),
    }),
    LockKey: {
        Fill: Iconify('ph:lock-key-fill'),
    },
    LockKeyOpen: {
        Fill: Iconify('ph:lock-key-open-fill'),
    },
    LockSimple: Iconify('ph:lock-simple'),
    LockSimpleOpen: Iconify('ph:lock-simple-open'),
    LucideCheckCircle: Iconify('lucide:check-circle'),
    MagnifyingGlass: {
        Bold: Iconify('ph:magnifying-glass-bold'),
    },
    MaskHappy: Iconify('ph:mask-happy'),
    MaskSad: Iconify('ph:mask-sad'),
    MasterCard: Iconify('formkit:mastercard'),
    Megaphone: Iconify('ph:megaphone'),
    Minus: Object.assign(Iconify('ph:minus'), {
        Bold: Iconify('ph:minus-bold'),
    }),
    MoonStars: Iconify('ph:moon-stars'),
    NavigationArrow: Object.assign(Iconify('ph:navigation-arrow'), {
        Bold: Iconify('ph:navigation-arrow-bold'),
    }),
    NotePencil: Iconify('ph:note-pencil'),
    PaintBucket: Object.assign(Iconify('ph:paint-bucket'), {
        Fill: Iconify('ph:paint-bucket-fill'),
    }),
    Palette: Object.assign(Iconify('ph:palette'), {
        Bold: Iconify('ph:palette-bold'),
    }),
    Paragraph: {
        Bold: Iconify('ph:paragraph-bold'),
    },
    PencilSimple: Object.assign(Iconify('ph:pencil-simple'), {
        Fill: Iconify('ph:pencil-simple-fill'),
    }),
    Phone: {
        Fill: Iconify('ph:phone-fill'),
    },
    PhoneVibrate: Iconify('bi:phone-vibrate'),
    Plus: Object.assign(Iconify('ph:plus'), {
        Bold: Iconify('ph:plus-bold'),
    }),
    Question: Object.assign(Iconify('ph:question'), {
        Bold: Iconify('ph:question-bold'),
    }),
    QuestionMark: Iconify('ph:question-mark'),
    RocketLaunch: Object.assign(Iconify('ph:rocket-launch'), {
        Bold: Iconify('ph:rocket-launch-bold'),
    }),
    RowsFill2,
    SealQuestion: {
        Fill: Iconify('ph:seal-question-fill'),
    },
    Section,
    ShieldCheckered: {
        Fill: Iconify('ph:shield-checkered-fill'),
    },
    ShieldChevron: Object.assign(Iconify('ph:shield-chevron'), {
        Fill: Iconify('ph:shield-chevron-fill'),
    }),
    Stack: {
        Fill: Iconify('ph:stack-fill'),
    },
    StackSimple: {
        Fill: Iconify('ph:stack-simple-fill'),
    },
    Sun: Iconify('ph:sun'),
    TerminalWindow: Iconify('ph:terminal-window'),
    TextAa: Object.assign(Iconify('ph:text-aa'), {
        Bold: Iconify('ph:text-aa-bold'),
    }),
    TextBold: {
        Bold: Iconify('ph:text-b-bold'),
    },
    TextHeading1: {
        Bold: Iconify('ph:text-h-one-bold'),
    },
    TextHeading2: {
        Bold: Iconify('ph:text-h-two-bold'),
    },
    TextHeading3: {
        Bold: Iconify('ph:text-h-three-bold'),
    },
    TextHeading4: {
        Bold: Iconify('ph:text-h-four-bold'),
    },
    TextHeading5: {
        Bold: Iconify('ph:text-h-five-bold'),
    },
    TextHeading6: {
        Bold: Iconify('ph:text-h-six-bold'),
    },
    TextItalic: {
        Bold: Iconify('ph:text-italic-bold'),
    },
    TextStrikeThrough: {
        Bold: Iconify('ph:text-strikethrough-bold'),
    },
    TextUnderline: {
        Bold: Iconify('ph:text-underline-bold'),
    },
    Ticket: Iconify('ph:ticket'),
    Trash: Object.assign(Iconify('ph:trash'), {
        Bold: Iconify('ph:trash-bold'),
        Fill: Iconify('ph:trash-fill'),
    }),
    UploadSimple: Iconify('ph:upload-simple'),
    UsersThree: {
        Fill: Iconify('ph:users-three-fill'),
    },
    Video: {
        Light: Iconify('ph:video-light'),
    },
    Visa: Iconify('formkit:visa'),
    WarningCircle: Object.assign(Iconify('ph:warning-circle'), {
        Bold: Iconify('ph:warning-circle-bold'),
    }),
    WarningDiamond: Iconify('ph:warning-diamond'),
    X: Object.assign(Iconify('ph:x'), {
        Bold: Iconify('ph:x-bold'),
    }),
    XCircle: Iconify('ph:x-circle'),
    ZoomIn: Iconify('ph:magnifying-glass-plus'),
    ZoomOut: Iconify('ph:magnifying-glass-minus'),
});

preloadIcons();

export { PhosphorIcons };
