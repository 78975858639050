import { Tooltip } from '@mui/material';
import classNames from 'classnames';
import { CheckBoxList } from 'components/Form/CheckBoxList';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import { SUPER_USER_ACTION_ITEMS } from 'components/StaffMenu/SuperUser/actionKeys';
import { SuperUserItemPortalConsumer } from 'components/StaffMenu/SuperUser/components/SuperUserItem/SuperUserItemPortalConsumer';
import { useIsNewSuperAction } from 'components/StaffMenu/SuperUser/hooks/useIsNewSuperAction';
import { useSuperUserActionLockState } from 'components/StaffMenu/SuperUser/hooks/useSuperUserActionLockState';
import { useSuperUserItemState } from 'components/StaffMenu/SuperUser/hooks/useSuperUserItemState';
import type { SuperUserNamespace } from 'components/StaffMenu/SuperUser/types';
import { Flex } from 'components/Utils/Flex';
import Text from 'components/Utils/Text';
import './_SuperUserItem.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Props = {
    actionKey: SuperUserNamespace.Key;
};
type SuperUserItem = React.FC<Props>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const SuperUserItem: SuperUserItem = ({ actionKey }) => {
    /***** HOOKS *****/
    const { value: isEnabled, set: setIsEnabled } = useSuperUserItemState(actionKey);
    const { isLocked, toggleLock } = useSuperUserActionLockState(actionKey);
    const [isNew, markAsViewed] = useIsNewSuperAction(actionKey);

    /***** FUNCTIONS *****/
    function handleOnChange(isChecked: boolean) {
        setIsEnabled(isChecked);
        markAsViewed();
    }

    /***** RENDER HELPERS *****/
    const actionItem = SUPER_USER_ACTION_ITEMS[actionKey];
    const hasDescription = typeof actionItem === 'object' && !!actionItem.description;
    const hasActionItem = Boolean(actionItem);

    function getTitle() {
        if (!hasActionItem) return actionKey;
        return typeof actionItem === 'string' ? actionItem : actionItem.title;
    }
    const title = getTitle();

    /***** RENDER *****/
    return (
        <Flex direction="column" gap={1}>
            <Flex
                className={classNames('SuperUserItem', {
                    'SuperUserItem--isNew': isNew,
                    'SuperUserItem--isLocked': isLocked,
                })}
                gap={1}
                align="center"
            >
                <Tooltip title={isLocked ? `Disable Save` : 'Enable Save'} placement="top" arrow disableInteractive>
                    <button
                        type="button"
                        onClick={toggleLock}
                        className={classNames('SuperUserItem__lock', { 'SuperUserItem__lock--isLocked': isLocked })}
                    >
                        <PhosphorIcons.FloppyDiskBack.Fill notice={isLocked} secondary={!isLocked} />
                    </button>
                </Tooltip>
                <Flex.Child grow inject>
                    <CheckBoxList.Item key={actionKey} isChecked={isEnabled} onChange={handleOnChange}>
                        <Flex className="SuperUserItem__content" align="center" justify="between" fullWidth>
                            <Flex.Child grow>
                                <Text>{title}</Text>
                            </Flex.Child>
                            {hasDescription && (
                                <Tooltip title={actionItem.description} placement="top" arrow disableInteractive>
                                    <span>
                                        <PhosphorIcons.SealQuestion.Fill info />
                                    </span>
                                </Tooltip>
                            )}
                            {isNew && (
                                <Text size--xs white className="SuperUserItem__contentNewText" lead--1>
                                    New!
                                </Text>
                            )}
                        </Flex>
                    </CheckBoxList.Item>
                </Flex.Child>
            </Flex>
            {isEnabled && <SuperUserItemPortalConsumer actionKey={actionKey} />}
        </Flex>
    );
};
