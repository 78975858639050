import { _TextArea } from 'components/Form/TextArea/_TextArea';
import { _TextAreaDebounce as TextAreaDebounce } from 'components/Form/TextArea/wrappers/debounce/Default';
import { _TextAreaHookFormDebounce as TextAreaHookFormDebounce } from 'components/Form/TextArea/wrappers/hookForm/Debounce';
import { _TextAreaHookFormDefault as TextAreaHookFormDefault } from 'components/Form/TextArea/wrappers/hookForm/Default';
import { _TextAreaReduxFormDefault as TextAreaReduxFormDefault } from 'components/Form/TextArea/wrappers/reduxform/Default';

export const TextArea = Object.assign(_TextArea, {
    Debounce: TextAreaDebounce,
    ReduxForm: TextAreaReduxFormDefault,
    HookForm: Object.assign(TextAreaHookFormDefault, {
        Debounce: TextAreaHookFormDebounce
    })
});
