import { configureSentryEmailScope } from 'App/methods';
import { authenticationQueryKeys } from 'containers/login/queries/queryKeys';
import { createElement } from 'react';
import { API } from 'utilities/api/login';
import { createNXQuery } from 'utilities/methods/tanstack/createQuery';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
const _userData = createNXQuery(() => ({
    queryKey: authenticationQueryKeys.userData(),
    async queryFn() {
        const result = await API.user.get();

        // Side effect to register the users scope with sentry after we have that information.
        configureSentryEmailScope(result.data.attributes.email);

        return result;
    },
    staleTime: Infinity,
    
    /**
     * Prevents queries from refetching on mount. Since this data should only be refetched explicitly, we do not want
     * queries refetching when they mount.
     */
    refetchOnMount: false
}));

export const userData = Object.assign(_userData, {
    withData<P = {}>(component: React.Component<P>): React.FC<P> {
        return (props: P) => {
            const { data: app_user_data } = _userData.useQuery();

            // @ts-ignore
            return createElement(component, {
                ...props,
                app_user_data
            });
        };
    }
});
