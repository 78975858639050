import { PhosphorIcons } from 'components/Icons/Phosphor';
import { DeveloperThemeContext } from 'components/StaffMenu/Theme/context';
import { _DeveloperThemeCompanySelector as CompanySelector } from 'components/StaffMenu/Theme/toggle';
import { _DeveloperThemeTokenPreview as TokenPreview } from 'components/StaffMenu/Theme/tokens';
import { useStaffMenu } from 'components/StaffMenu/useStaffMenu';
import { Flex } from 'components/Utils/Flex';
import Gradient from 'components/Utils/Gradient';
import type { BrandName } from 'config/hooks/useBrandStore';
import { setBrand } from 'config/hooks/useBrandStore';
import { __DO_NOT_USE_IN_PRODUCTION_OR_YOU_WILL_BE_FIRED__INTASERVE__CONFIG__ as CONFIG_INTASERVE } from 'config/tokens/intaserve';
import { getFlatTokens } from 'config/tokens/methods';
import { __DO_NOT_USE_IN_PRODUCTION_BUILD_OR_YOU_WILL_BE_FIRED__VENTRA__CONFIG__ as CONFIG_VENTRA } from 'config/tokens/ventra';
import { useEffect, useRef, useState } from 'react';
import { isPointerEventWithinRefList } from 'utilities/methods/isPointerEventWithinInRefList';
import './_Theme.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
const themeMap = {
    ventra: CONFIG_VENTRA,
    intaserve: CONFIG_INTASERVE,
};

/**
 * useClickAway, but it can't fire on blur in this instance because there is a file picker input inside the theme dev tool, which fires the onblur event, stopping it from working
 */
function useClickAwayWithoutOnBlur(refs: React.MutableRefObject<HTMLElement | null>[], onClose: (e: PointerEvent | FocusEvent) => void) {
    /***** FUNCTIONS *****/
    const clickAway = (e: MouseEvent) => {
        const shouldClose = !isPointerEventWithinRefList(e, refs);

        if (shouldClose) {
            onClose(e);
        }
    };

    /***** EFFECTS *****/
    useEffect(() => {
        document.addEventListener('click', clickAway);

        return () => {
            document.removeEventListener('click', clickAway);
        };
    }, [onClose, refs]);
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const DeveloperTheme = () => {
    /***** STATE *****/
    const [theme, setTheme] = useState<BrandName>((import.meta.env.VITE_COMPANY ?? 'ventra') as BrandName);
    const developerThemeRef = useRef<HTMLDivElement>(null);

    /***** HOOKS *****/
    const { active, toggle } = useStaffMenu();
    useClickAwayWithoutOnBlur([developerThemeRef], () => active && toggle());

    /***** FUNCTIONS *****/
    /** Returns an object with all tokens relevant for the current theme. This will include all brand tokens. */
    const getAllThemedTokens = (theme: BrandName) => {
        return {
            ...themeMap[theme]?.semantic,
            ...themeMap[theme]?.primitive,
            ...getFlatTokens(themeMap[theme]?.component),
            ...getFlatTokens(themeMap.intaserve.brand),
            ...getFlatTokens(themeMap.ventra.brand)
        };
    };

    const _setTheme = (theme: BrandName) => {
        const [root_theme] = Array.from(document.querySelectorAll<HTMLElement>(':root'));

        if (!root_theme) return;

        const allTokens = getAllThemedTokens(theme);

        /*
        It's continuously using the value of a token as the key of a different token, until it finds a token that points to a valid css value (ie. doesn't point to another token). I had to do this because some component tokens (`C_...`) point directly to primitive tokens, but some point to semantic tokens (`S_...`), so you don't know how many levels you will have to go down.

        Take the example:
        'C_OutlineButton_color_background_primary_hover': 'S_color_background_primary_hover'   =>
        'S_color_background_primary_hover': 'P_color_primary_hover'   =>
        'P_color_primary_hover': '#b52578'
        
        In this case the while loop would execute 2 times until it resolves that "C_OutlineButton_color_background_primary_hover" points to "#b52578".

        I had to change the structure of the tokens so that they have the string of the token that they point to as the value, rather than how Lleyton had it originally, so that my theme builder thing would work.
        */
        Object.entries(allTokens).forEach(([token, value]) => {
            let finalValue = value;

            while (finalValue.startsWith('S_') || finalValue.startsWith('P_')) {
                // @ts-ignore
                finalValue = allTokens[finalValue];
            }

            root_theme.style.setProperty(`--${token}`, finalValue);
        });

        setTheme(theme);
        setBrand(theme);
    };

    const resetTheme = (theme: BrandName) => {
        const [root_theme] = Array.from(document.querySelectorAll<HTMLElement>(':root'));

        if (!root_theme) return;

        Object.keys(getAllThemedTokens(themeMap[theme])).forEach((token) => {
            root_theme.style.removeProperty(`--${token}`);
        });
    };

    /***** EFFECTS *****/
    useEffect(() => {
        // If the component mounts and the theme is different to the VITE_COMPANY, then we know that vite is triggering the remount (as the state has been updated).
        // We only want to set the theme in this case when the selected theme does not match the default, otherwise we don't need to update the styles of the dom
        // as these styles are already set in the style sheet

        // When the component unmounts, we can remove all theme from the styles so that it can either be re-added with the relevant style when the component remounts,
        // or not add the style again at all and use the default style sheet styles
        if (import.meta.env.VITE_COMPANY !== theme) {
            _setTheme(theme);
        }
        return () => resetTheme(theme);
    }, []);

    /***** RENDER *****/
    if (!import.meta.env.DEV) return null;

    return (
        <Gradient development-pink className="DeveloperTheme">
            <div ref={developerThemeRef} style={{ width: '100%' }}>
                <Flex fullHeight fullWidth justify="center" align="center">
                    <button type="button" className="DeveloperTheme__button" onClick={toggle}>
                        <PhosphorIcons.PaintBucket className="DeveloperTheme__icon" size={26} />
                    </button>
                    {active && (
                        <DeveloperThemeContext.Provider value={{ theme, config: getAllThemedTokens(theme), setTheme: _setTheme }}>
                            <Flex className="DeveloperTheme__actions" direction="column">
                                <CompanySelector />
                                <TokenPreview />
                            </Flex>
                        </DeveloperThemeContext.Provider>
                    )}
                </Flex>
            </div>
        </Gradient>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
