/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useMutation } from '@tanstack/react-query';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { KATANA_API } from 'utilities/api/katana';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
import { katanaQueryKeys } from 'containers/katana/queries/katanaQueryKeys';
import type { KatanaNamespace } from 'containers/katana/types';
import type { NXQueryUtils } from 'utilities/methods/tanstack/types';

type UploadFileMutationOptions = NXQueryUtils.TMutationOptions<typeof KATANA_API.katana.service_id.uploads.POST>;

function createMutationKey(serviceID: KatanaNamespace.ServiceId) {
    return katanaQueryKeys.katana.service.ID.uploadFile(serviceID);
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useMutation(serviceID: KatanaNamespace.ServiceId, options: UploadFileMutationOptions = {}) {
    function mutationFn(formData: KATANA_API.katana.service_id.uploads.POST.Params['formData']) {
        return KATANA_API.katana.service_id.uploads.POST({ serviceID, formData });
    }

    /***** HOOK RESULTS *****/
    return useMutation({
        mutationFn,
        mutationKey: createMutationKey(serviceID),
        ...options,
    });
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const uploadFile = Object.freeze({
    useMutation: _useMutation,
    createMutationKey,
});
