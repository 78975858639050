import classNames from 'classnames';
import NXTable from 'components/NXTable';
import { renderForBrands } from 'config/brandRenderer/helper';
import { AssistanceLink } from 'containers/services/modules/hosting/components/body/actions/assistanceButton';
import { CancellationButton } from 'containers/services/modules/hosting/components/body/actions/cancelButton';
import { LoginButton } from 'containers/services/modules/hosting/components/body/actions/loginButton';
import { ManageServiceButton } from 'containers/services/modules/hosting/components/body/actions/manageButton';
import { PayInvoiceButton } from 'containers/services/modules/hosting/components/body/actions/payInvoiceButton';
import { RenewServiceButton } from 'containers/services/modules/hosting/components/body/actions/renewServiceButton';
import { RestoreServiceButton } from 'containers/services/modules/hosting/components/body/actions/restoreServiceButton/restoreServiceButton';
import { UnsuspendServiceButton } from 'containers/services/modules/hosting/components/body/actions/unsuspendServiceButton';
import { useHostingContext } from 'containers/services/modules/hosting/context';
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';
import { textLowerCase } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Status = 'active' | 'expiring' | 'expired' | 'pending cancellation' | 'suspended' | 'pending' | 'cancelled' | 'terminated' | undefined;

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
const HostingButtons = {
    LoginButton,
    ManageServiceButton,
    PayInvoiceButton,
    RenewServiceButton,
    CancellationButton,
    AssistanceLink,
    UnsuspendServiceButton,
    RestoreServiceButton
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const OwnActions = () => {
    /***** HOOKS *****/
    const isMobile = useAppViewport(['xs', 'sm']);
    const serviceData = useHostingContext();

    /***** DERIVED *****/
    const invoice = serviceData?.included?.find((item) => item.type === 'invoice');
    const status = textLowerCase(serviceData?.attributes.domain_status) as Status;

    /***** RENDER *****/
    return (
        <NXTable.Actions className={classNames({ 'HostingTable__Actions--mobile': isMobile })}>
            {(() => {
                switch (status) {
                    case 'active':
                    case 'expiring':
                        return (
                            <>
                                {renderForBrands(['ventra']) && <HostingButtons.LoginButton />}
                                <HostingButtons.ManageServiceButton />
                            </>
                        );
                    case 'suspended':
                        return (
                            <>
                                <HostingButtons.CancellationButton />
                                {!!invoice && !serviceData?.attributes.staff_manual_suspend && <HostingButtons.PayInvoiceButton />}
                                {!!serviceData?.attributes.staff_manual_suspend && <HostingButtons.UnsuspendServiceButton />}
                            </>
                        );
                    case 'expired':
                        return <HostingButtons.RenewServiceButton />;
                    case 'pending cancellation':
                        return <HostingButtons.CancellationButton />;
                    case 'pending':
                        return getInvoiceId(serviceData) ? <HostingButtons.PayInvoiceButton /> : <HostingButtons.AssistanceLink />;
                    case 'cancelled':
                    case 'terminated':
                        return getInvoiceId(serviceData) ? <HostingButtons.PayInvoiceButton /> : <HostingButtons.RestoreServiceButton />;
                    default:
                        return null;
                }
            })()}
        </NXTable.Actions>
    );
};

/**********************************************************************************************************
 *   FUNCTIONS
 **********************************************************************************************************/
function getInvoiceId(serviceData: ReturnType<typeof useHostingContext>) {
    const invoice = serviceData?.included?.find((item) => item.type === 'invoice');

    if (invoice && 'id' in invoice) {
        return invoice.id ?? null;
    }

    return null;
};

