/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Border } from 'components/Utils/Border';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { Link } from '@tanstack/react-router';
import { useExpressServiceManagerContext } from 'containers/dashboard/Components/expressServiceManager/context';
import './_showMore.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Show more footer to show when a table is present on the express service manager and has more than 5 total rows.
 *
 * @param {{
 *   isLoading: boolean;
 *   totalRows?: number;
 *   link: `/my-services/${string}`;
 *   children: string;
 * }} props
 */
export const ShowMore = ({ isLoading, totalRows, link, children }) => {
    /***** HOOKS *****/
    const isExpressServiceManager = useExpressServiceManagerContext();

    /***** RENDER *****/
    if (!isExpressServiceManager) return null;
    if (isLoading) return null;
    if (!totalRows || totalRows <= 5) return null;

    return (
        <Border top>
            <Link to={link} search={(search) => ({ ...search })} className="expressServiceManager__showMore">
                <Text as="div" primary align--center uppercase size--s medium>
                    {children}
                </Text>
            </Link>
        </Border>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
