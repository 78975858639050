/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useNavigate } from '@tanstack/react-router';
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import NXTable from 'components/NXTable';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { generateCreateActionButton } from 'containers/services/modules/helpers';
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';
import { createPopup } from 'utilities/methods/commonActions';
import { useMicrosoft365TableContext } from '../../own';
import { getMS365InformationFromData } from './helpers';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { ms365PortalUrl } from 'containers/ms365/consts';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
/**
 * @typedef {import('../../types').NMicrosoft365Table.TStore} TStore
 * @typedef {import('../../types').NMicrosoft365Table.TMicrosoft365Actions} TMicrosoft365Actions
 */
export const STATUS = /** @type {const} */ ({
    ACTIVE: 'active',
    PENDING_CANCELLATION: 'pending cancellation',
    PENDING: 'pending',
    SUSPENDED: 'suspended'
});

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {TMicrosoft365Actions}
 */
export const MS365TableRenderActions = ({ data }) => {
    /***** HOOKS *****/
    const isMobile = useAppViewport(['xs', 'sm']);
    const navigate = useNavigate();
    const { setCancelService, setKeepService } = useMicrosoft365TableContext();

    /***** RENDER HELPERS *****/
    const { status, id } = getMS365InformationFromData(data);

    /***** RENDER HELPERS *****/
    const createActionButton = generateCreateActionButton({
        size: 'medium',
        type: 'onClick',
        className: classNames('VPSTableBody__actionButton', {
            'VPSTableBody__actionButton--mobile': isMobile
        })
    });

    const buttons = /** @type {const} */ ({
        manage: createActionButton({
            name: 'Manage',
            onClick: () => navigate({ to: `/my-services/microsoft-365/$microsoftId/manage`, params: { microsoftId: String(id) }, hash: 'overview' })
        }),
        microsoft: createActionButton({
            name: 'Microsoft 365',
            onClick: () => createPopup(ms365PortalUrl),
            color: 'warn'
        }),
        cancel: createActionButton({
            name: 'Cancel',
            onClick: () => setCancelService(id),
            color: 'warn'
        }),
        keep: createActionButton({
            name: 'Keep Service',
            onClick: () => setKeepService(id)
        }),
        submitEticket: {
            anchor: <Anchor onClick={() => navigate({ to: '/support/tickets/submit' })}>Contact us for assistance</Anchor>
        }
    });

    const className = classNames('Microsoft365TableBody__actions', {
        'Microsoft365TableBody__actions--mobile': false
    });

    const _status = status?.toLowerCase() ?? status;
    const { manage, microsoft, cancel, keep, submitEticket } = buttons;

    /***** RENDER *****/
    return (
        <NXTable.Actions className={className}>
            {React.Children.toArray(
                (() => {
                    switch (_status) {
                        case STATUS.ACTIVE:
                            return [microsoft.button, manage.button];
                        case STATUS.PENDING_CANCELLATION:
                            return keep.button;
                        case STATUS.PENDING:
                            return [submitEticket.anchor, cancel.button];
                        case STATUS.SUSPENDED:
                            return submitEticket.anchor;
                        default:
                            return null;
                    }
                })()
            )}
        </NXTable.Actions>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
