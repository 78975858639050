/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { DialogNotificationButtonsContainer } from './DialogNotificationButtonsContainer';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_DialogNotification.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type DialogNotificationProps = {
    children: React.ReactNode;
    
    /**
     * Sets the color of the box background
     */
    type: 'warning' | 'info' | 'error' | 'success';
    outline?: boolean;
    className?: string;
    
    /**
     * Removes the margin from the box
     */
    noMargin?: boolean;
    
    /**
     * The position of the tail on the box
     */
    tail?: {
        pos: 'top' | 'bottom';
    } | null;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const DialogNotification: React.FC<DialogNotificationProps> & { ButtonsContainer: typeof DialogNotificationButtonsContainer } = ({
    children,
    type,
    outline,
    className,
    tail = null,
    noMargin
}) => {
    /***** RENDER HELPERS *****/
    const dialogNotificationClassName = classNames('DialogNotificationPage', className, type, {
        'DialogNotificationPage--topTail': tail && !outline && tail.pos === 'top',
        'DialogNotificationPage--bottomTail': tail && !outline && tail.pos !== 'top',
        'outline': outline,
        'DialogNotificationPage--noMargin': noMargin
    });

    /***** RENDER *****/
    return (
        <div className={dialogNotificationClassName}>
            <div className="text">{children}</div>
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

/***** Compound Components START *****/
DialogNotification.ButtonsContainer = DialogNotificationButtonsContainer;

/***** Compound Components END *****/
