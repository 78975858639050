import { createContext, useContext } from 'react';
import invariant from 'tiny-invariant';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Name = string;

/**********************************************************************************************************
 *   CONTEXT START
 **********************************************************************************************************/
export const AdvancedListContext = createContext<Name | null>(null);
export const useAdvancedListContext = () => {
    const context = useContext(AdvancedListContext);

    invariant(context, 'useAdvancedListContext must be used within a AdvancedListContext.Provider');

    return context;
};
