import _ from 'lodash';

export function feedbackBannerDismissedMigration() {
    const currentlyStoredData = localStorage.getItem('feedbackBannerDismissed');
    if (!currentlyStoredData) return;
    const parsedStoredData = JSON.parse(currentlyStoredData);

    if (!_.isNil(parsedStoredData)) {
        localStorage.setItem('USER_PREFERENCE:IS_FEEDBACK_BANNER_DISMISSED', JSON.stringify(parsedStoredData));
    }
}
