import { AxiosError } from 'axios';
import { pushNotification } from 'components/Toast/functions';
import { authenticationMutationKeys } from 'containers/login/queries/queryKeys';
import { API } from 'utilities/api/login';
import { getMessageFromErrorsData } from 'utilities/methods/commonActions/getMessageFromErrorsData';
import { parseAxiosErrorWithErrorsResponse } from 'utilities/methods/commonActions/parseAxiosErrorWithErrorsResponse';
import { createNXMutation } from 'utilities/methods/tanstack/createMutation';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
export const submitTwoFactor = createNXMutation({
    mutationKey: authenticationMutationKeys.submitTwoFactor(),
    mutationFn: API.login.twoFactor.submit,
    onError(error: unknown) {
        if (error instanceof AxiosError) {
            const parsed = parseAxiosErrorWithErrorsResponse(error);

            if (parsed.success) {
                const { status } = parsed.data.response.data;
                const details = getMessageFromErrorsData(parsed.data.response.data);

                pushNotification({ details, status }, null, 'global');
            }
        }
    }
});
