/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { createRoute } from '@tanstack/react-router';
import { createKatanaServiceId } from 'containers/katana/methods';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import * as z from 'zod';

/**********************************************************************************************************
 *   ROUTE IMPORTS
 **********************************************************************************************************/
import { VIPSitesRoute } from 'containers/katana/routes/vip-sites.$katanaServiceId';
import { routerMiddleware } from 'router/utils/middleware';
import { lazyFN } from 'utilities/methods/tanstack/router/lazyFn';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
const searchSchema = z.object({
    'add-domains': z.literal('open').optional(),
    'vip-express-add-domains': z.string().min(1).optional()
});

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const VIPSitesOverviewRoute = createRoute({
    getParentRoute: () => VIPSitesRoute,
    path: 'overview',
    validateSearch: searchSchema,
    async loader({ params }) {
        await katanaQuery.serviceID.service.prefetchQuery(createKatanaServiceId(params.katanaServiceId));
    },
    beforeLoad(opts) {
        routerMiddleware.business(this, opts);
        routerMiddleware.authentication('user', opts);
    }
}).lazy(lazyFN(() => import('./overview.lazy'), 'LazyVIPSitesOverviewRoute'));
