import classNames from 'classnames';
import DismissButton from 'components/DismissButton';
import type { PromoBannerDismissKeys } from 'containers/dashboard/Components/consts';
import { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';
import { UserPreferences } from 'utilities/UserPreferences';
import './_BannerCarousel.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type ArrowProps = {
    className?: string;
    style?: React.CSSProperties;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
const NextArrow: React.FC<ArrowProps> = ({ className, style, onClick }) => {
    return (
        <button className={className} style={style} onClick={onClick}>
            <i className="icon icon-chevron-right" />
            <i className="icon icon-chevron-right" />
        </button>
    );
};

const PrevArrow: React.FC<ArrowProps> = ({ className, style, onClick }) => {
    return (
        <button className={className} style={style} onClick={onClick}>
            <i className="icon icon-chevron-left" />
            <i className="icon icon-chevron-left" />
        </button>
    );
};

type ProgressBarProps = {
    active: boolean;
    width: number;
};

const ProgressBar: React.FC<ProgressBarProps> = ({ active, width }) => {
    return (
        <div className={classNames('BannerCarousel__progress', { 'BannerCarousel__progress--active': active })}>
            <div style={{ display: active ? 'block' : 'none', transform: `translateX(${width}%)` }} className="BannerCarousel__progressFill" />
        </div>
    );
};

type Banner = {
    /**
     * The array key
     */
    key: number;

    /**
     * Ui rendering element
     */
    ui: React.ReactNode;

    /**
     * Dismiss key for local storage.
     */
    dismissKey: PromoBannerDismissKeys;

    /**
     * Whether to show the banner or not.
     */
    showCondition: boolean;

    /**
     * Whether or not to show the dots representing which is the current slide
     */
    dots: boolean;
};

type BannerCarouselProps = {
    start: () => void;
    restart: () => void;
    pause: () => void;
    resume: () => void;
    elapsed: number;
    onDismiss?: () => void;
    arrows: boolean;
    dots: boolean;
    initialBanners: Banner[];
};

const autoplayInterval = 5000;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const BannerCarousel: React.FC<BannerCarouselProps> = ({
    initialBanners,
    restart,
    start,
    pause,
    resume,
    elapsed,
    arrows,
    dots,
    onDismiss
}) => {
    /***** HOOKS *****/
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);
    const sliderRef = useRef<Slider>(null);
    const [bannerDismissRecords] = UserPreferences.useLocalStorage('PROMO_BANNER_DISMISSAL_RECORD', {});

    function dismissBanner(keyToDismiss: PromoBannerDismissKeys) {
        UserPreferences.updateItem('PROMO_BANNER_DISMISSAL_RECORD', (keys) => ({
            ...keys,
            [keyToDismiss]: true
        }));
        restart();
    }

    const banners = initialBanners.filter(({ dismissKey, showCondition }) => {
        if (!showCondition) return false;
        if (!dismissKey) return true;
        if (!bannerDismissRecords) return true;
        if (bannerDismissRecords[dismissKey]) return false;
        return true;
    });

    function updateActiveSlideIndex(newIndex: number) {
        restart();
        setActiveSlideIndex(newIndex);
    }

    useEffect(() => {
        start();
    }, []);

    useEffect(() => {
        if (elapsed >= autoplayInterval) {
            sliderRef.current?.slickNext();
        }
    }, [elapsed]);

    const isMobileTablet = useAppViewport(['md', 'sm', 'xs']);
    const isShowingArrows = typeof arrows === 'boolean' ? arrows : !isMobileTablet;

    /*  RENDER COMPONENT
     **********************************************************************************************************/
    if (!banners.length) return null;

    return (
        <div className="BannerCarousel__container">
            <div className="BannerCarousel" onMouseEnter={pause} onMouseLeave={resume}>
                <Slider
                    ref={sliderRef}
                    arrows={isShowingArrows}
                    infinite
                    speed={500}
                    pauseOnHover={false}
                    slidesToShow={1}
                    slidesToScroll={1}
                    nextArrow={<NextArrow />}
                    prevArrow={<PrevArrow />}
                    beforeChange={(_, next) => updateActiveSlideIndex(next)}
                    dots={!!dots}
                >
                    {banners.map(({ key, ui, dismissKey }) => (
                        <div key={key} className="BannerCarousel__banner">
                            {ui}
                            {dismissKey ? (
                                <DismissButton
                                    background
                                    onClick={() => {
                                        dismissBanner(dismissKey);
                                        onDismiss?.();
                                    }}
                                />
                            ) : (
                                ''
                            )}
                        </div>
                    ))}
                </Slider>
            </div>
            {banners.length > 1 ? (
                <div className="BannerCarousel__progressContainer">
                    {banners.map(({ key }, index) => (
                        <ProgressBar key={key} active={index === activeSlideIndex} width={(elapsed / autoplayInterval) * 100} />
                    ))}
                </div>
            ) : (
                ''
            )}
        </div>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
