import { authenticationMutationKeys } from 'containers/login/queries/queryKeys';
import { noop } from 'lodash';
import { API } from 'utilities/api/account';
import { createNXMutation } from 'utilities/methods/tanstack/createMutation';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
export const staffLogin = createNXMutation({
    mutationKey: authenticationMutationKeys.login(),
    mutationFn: API.account.GET.mercury.bypass,
    onSuccess: noop // do not attempt to push toast
});
