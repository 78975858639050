import { useMutation } from '@tanstack/react-query';
import * as _ from 'lodash';
import { KATANA_API } from 'utilities/api/katana';
import { service } from '../service';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useMutation() {
    return useMutation({
        mutationFn: KATANA_API.katana.service_id.domains.POST,
        onSuccess: (res, variables) => {
            service.invalidateQueries(variables.serviceID);
        },
        onError: _.noop
    });
}

export const addDomain = Object.freeze({
    useMutation: _useMutation
});
