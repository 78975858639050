import { getAllKeys } from 'utilities/UserPreferences/methods/getAllKeys';

export function pictureInPictureStatesMigration() {
    const currentlyStoredData = localStorage.getItem('USER_PREFERENCE:PICTURE_IN_PICTURE_STATES');
    const parsedStoredData = currentlyStoredData ? JSON.parse(currentlyStoredData) : {};

    const keys = getAllKeys();
    const pictureInPictureStateKeys = keys.filter((key) => key.includes('storable__'));
    const migrationResult = pictureInPictureStateKeys.reduce<Record<string, boolean>>((current, key) => {
        const [, identifier] = key.split('__');
        const storedValue = window.localStorage.getItem(key);

        if (!storedValue || !identifier) {
            return current;
        }
        const parsedValue = JSON.parse(storedValue);

        current[identifier] = parsedValue;

        window.localStorage.removeItem(key);

        return current;
    }, parsedStoredData);

    if (Object.keys(migrationResult).length) {
        localStorage.setItem('USER_PREFERENCE:PICTURE_IN_PICTURE_STATES', JSON.stringify(migrationResult));
    }
}
