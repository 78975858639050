/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { TextVMax } from 'components/Utils/Text/VMax';
import { _Heading as Heading } from 'components/Utils/Text/_Heading';
import { _TextSkeleton as TextSkeleton } from 'components/Utils/Text/_Skeleton';
import { _Text } from 'components/Utils/Text/_Text';
import { _TableContent as TableContent } from 'components/Utils/Text/_tableContent';

const Text = Object.assign(_Text, {
    Heading,
    TableContent,
    Skeleton: TextSkeleton,
    VMax: TextVMax
});

export default Text;
