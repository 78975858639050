/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { createContext } from 'react';
import type { SectionModuleNamespace } from 'utilities/methods/types';

export const SectionModuleContext = createContext<SectionModuleNamespace.Context>({
    path: null
});

export const useSectionModuleContext = () => React.useContext(SectionModuleContext);
