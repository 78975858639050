/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type AdvanceListItemContainer = React.FC<{
    children: React.ReactNode;
    readonly?: boolean;
}>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _AdvanceListItemContainer: AdvanceListItemContainer = ({ children, readonly }) => {
    return <div className="AdvancedList__itemContainer">{children}</div>;
};
