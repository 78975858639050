/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useState } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import NXTable from 'components/NXTable';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Provides a simple abstraction on useState for managing the state of sorting. This includes
 * the state ({ sort_by, sort }) and a function to set the sorting.
 *
 * @param {object} props
 * @param {boolean} props.disabled - if true, the sorting will be disabled
 */
export const useSort = ({ disabled }) => {
    /** @typedef {{ sort_by: string | null, sort: string | null }} TFilters */
    const [filters, setFilters] = useState(
        
        /** @type {TFilters}*/ ({
            sort_by: null,
            sort: null
        })
    );

    /***** FUNCTIONS *****/
    /**
     * @type {Parameters<typeof NXTable>[0]['onSort']}
     */
    const handleSort = ({ sortKey, sortMethod }) => {
        if (disabled) {
            return;
        }

        if (sortMethod === false) {
            return void setFilters((prev) => ({
                ...prev,
                sort: null,
                sort_by: null
            }));
        }

        if (typeof sortMethod === 'boolean' || typeof sortKey === 'boolean') {
            return;
        }

        setFilters((prev) => ({
            ...prev,
            sort: sortMethod,
            sort_by: sortKey
        }));
    };

    return /** @type {const} */ ([filters, setFilters, { handleSort }]);
};
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
