export const APP_MOUNTING = 'app/APP_MOUNTING';
export const APP_DISPLAY_STATES = 'app/APP_DISPLAY_STATES';

export const APP_COUNTRIES_REQUEST = 'app/APP_COUNTRIES_REQUEST';
export const APP_COUNTRIES_SUCCESS = 'app/APP_COUNTRIES_SUCCESS';
export const APP_COUNTRIES_ERROR = 'app/APP_COUNTRIES_ERROR';

export const APP_LOCAL_STATES_REQUEST = 'app/APP_LOCAL_STATES_REQUEST';
export const APP_LOCAL_STATES_SUCCESS = 'app/APP_LOCAL_STATES_SUCCESS';
export const APP_LOCAL_STATES_ERROR = 'app/APP_LOCAL_STATES_ERROR';

export const APP_ALERT_BANNER_REQUEST = 'app/APP_ALERT_BANNER_REQUEST';
export const APP_ALERT_BANNER_SUCCESS = 'app/APP_ALERT_BANNER_SUCCESS';

export const APP_CHANGE_LIVECHAT_VISIBILITY = 'app/APP_CHANGE_LIVECHAT_VISIBILITY';
