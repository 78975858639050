import { serviceQueryKeys } from "containers/services/queries/keys";
import { API } from "utilities/api/services";
import { createNXMutation } from "utilities/methods/tanstack/createMutation";

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
export const approveMove = createNXMutation({
    mutationKey: serviceQueryKeys.services.approveMove(),
    mutationFn: API.service.get.approveMove
});
