/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Radio } from 'components/Form/Radio';
import { Flex } from 'components/Utils/Flex';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './__RadioCircleButton.scss';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { RadioCircleButtonNamespace } from 'components/Form/Radio/_Circle/_RadioCircleButton/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _RadioCircleButton: React.FC<RadioCircleButtonNamespace.Props> = ({
    isActive,
    onClick,
    children,
    color = 'confirm',
    disabled,
    className,
}) => (
    <Flex align="center" inject>
        <button type="button" className={classNames('RadioCircleButton', className)} onClick={onClick} disabled={disabled}>
            <Radio.Circle color={color} isActive={isActive} />
            <Text semiBold black size--m align--left>
                {children}
            </Text>
        </button>
    </Flex>
);
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
