import type { VentraIPWordpressSupportCentreAPI } from 'utilities/api/ventraip-website-wp-support-centre/types';

export function composeSupportCentreLink(slug: string) {
    return `/support/support-centre/${slug}`;
}

type Category = VentraIPWordpressSupportCentreAPI.SupportArticleCategories.Response;

export const createNestedCategories = (categories: Category[]) => {
    const nestedCategories: { [key: string]: Category } = {};
    Array.isArray(categories) &&
        categories.forEach((category) => {
            if (category?.parent === 0) {
                nestedCategories[category.slug] = category;
            } else {
                const parent = categories.find((c) => c.id === category.parent);
                if (parent) {
                    if (!parent?.children) parent.children = {};
                    parent.children[category.slug] = category;
                    delete parent.articles;
                }
            }
        });
    return nestedCategories;
};
