import { useParams } from '@tanstack/react-router';
import { forgotEmailMethod, forgotPasswordMethod, resetPassword, submitRecoveryForm } from 'containers/login/action';
import { useLoginContext } from 'containers/login/consts';
import { DateTime } from 'luxon';
import { useDispatch } from 'react-redux';
import type { API } from 'utilities/api/login';
import { NXQuery } from 'utilities/query';

export type Success = {
    details?: any;
    view?: 'manual' | 'password' | 'reset';
    resubmit?: (...args: any[]) => void;
};

type UseLoginActionsProps = {
    setMethod: (method?: API.login.twoFactor.listMethods.Method) => void;
    setSuccess: (success: Success) => void;
};

/**
 * Hook providing existing login action functionality. These functions are typed with any as they should be removed when
 * the corresponding form is being refactored to RHF.
 */
export const useLoginActions = ({ setSuccess, setMethod }: UseLoginActionsProps) => {
    /***** HOOKS *****/
    const dispatch = useDispatch();
    const { setView } = useLoginContext();
    const { token } = useParams({ strict: false });

    /***** QUERIES *****/
    const { mutate: logout } = NXQuery.auth.logout.useMutation();

    /***** FUNCTIONS *****/
    const handleSetMethod = (method?: API.login.twoFactor.listMethods.Method) => setMethod(method);

    /**
     * Once we have a dedicated route for each login view, the `setMethod` will not be required (since this would
     * be a query parameter on the 2FA route) and this method can be hoisted into the loginContext to do both the route
     * change and logout methods
     */
    const handleResetLoginArea = () => {
        setMethod(undefined);
        setView('login');
        logout();
    };

    const handleForgotSubmit = (values: any) => {
        const dob = values?.dob ? DateTime.fromJSDate(values.dob).toFormat('dd/MM/yyyy') : null;
        const modifiedValues = { ...values, dob: dob };

        if (values.method === 'email') {
            setSuccess({
                details: modifiedValues,
                view: 'manual'
            });
            forgotEmailMethod(modifiedValues)(dispatch);
        } else {
            setSuccess({
                details: modifiedValues,
                view: 'password',
                resubmit: (values: unknown) => forgotPasswordMethod(values)(dispatch)
            });
            forgotPasswordMethod(values)(dispatch);
        }
    };

    const handleRecoverySubmit = (values: any) => {
        const dob = values?.dob ? DateTime.fromJSDate(values.dob).toFormat('dd/MM/yyyy') : null;
        const modifiedValues = { ...values, dob: dob };

        setSuccess({
            details: modifiedValues,
            view: 'manual'
        });
        submitRecoveryForm(modifiedValues);
    };

    const handleResetSubmit = (values: any) => {
        setSuccess({
            details: false,
            view: 'reset'
        });
        resetPassword(token, values)(dispatch);
    };

    return { handleForgotSubmit, handleResetLoginArea, handleRecoverySubmit, handleResetSubmit, handleSetMethod };
};
