import { accountQueryKeys } from 'containers/account/queries/queryKeys';
import { API } from 'utilities/api/account';
import { createNXQuery } from 'utilities/methods/tanstack/createQuery';

/**********************************************************************************************************
 *   QUERY START
 **********************************************************************************************************/
export const supportPIN = createNXQuery(() => ({
    queryFn: API.user.auth.code.GET,
    queryKey: accountQueryKeys.user.auth.code(),
    staleTime: 7200 //2 hours
}));
