import { nxAxios } from 'store/nxAxios';

export declare namespace OrderingApi {
    export namespace Products {
        export namespace POST {
            type Params = { ids: number[]; promo?: string };
        }
    }
}

export const ORDERING_API = {
    products: {
        POST: (params: OrderingApi.Products.POST.Params): Artah.Products.POST.NXReturnType => {
            return nxAxios.post(`/api/products`, params);
        }
    }
} as const;
