import { createBaseQueryKey } from 'utilities/methods/tanstack/createBaseQueryKey';

/**********************************************************************************************************
 *   QUERY KEY TREE START
 **********************************************************************************************************/
export const accountQueryKeys = Object.assign(() => createBaseQueryKey(['account']), {
    checkSubaccountExists: (token: string) => [...accountQueryKeys(), 'checkSubaccountExists', token as '$token'] as const,
    user: Object.assign(() => [...accountQueryKeys(), 'user'] as const, {
        auth: Object.assign(() => [...accountQueryKeys.user(), 'auth'] as const, {
            code: () => [...accountQueryKeys.user.auth(), 'code'] as const
        })
    })
});

export const accountMutationKeys = Object.assign(() => createBaseQueryKey(['account']), {
    approveSubaccount: () => [...accountQueryKeys(), 'approveSubaccount'] as const
});
