import { _CheckBox } from 'components/Form/CheckBox/_CheckBox';
import { _CheckBoxVisual } from 'components/Form/CheckBox/_Visual';
import { _CheckBoxCircle } from 'components/Form/CheckBox/wrappers/CheckBoxCircle/_CheckBoxCircle';
import { _CheckBoxPanel } from 'components/Form/CheckBox/wrappers/Panel';

export const CheckBox = Object.assign(_CheckBox, {
    Circle: _CheckBoxCircle,
    Panel: _CheckBoxPanel,
    Visual: _CheckBoxVisual
});
