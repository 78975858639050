import { useBrandStore } from 'config/hooks/useBrandStore';
import { TwoFactorHeading } from 'containers/login/forms/LoginTwoFactor/components/heading';
import type { API } from 'utilities/api/login';
import { NXQuery } from 'utilities/query';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type TwoFactorTemplateHeading = React.FC<{
    template?: API.login.twoFactor.listMethods.Method;
}>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const TwoFactorTemplateHeading: TwoFactorTemplateHeading = ({ template }) => {
    /***** HOOKS *****/
    const { application } = useBrandStore();

    /***** QUERIES *****/
    const { data: methodValue } = NXQuery.auth.twoFactor.list.useSuspenseQuery(void 0, {
        select: ({ data }) => data.find(({ attributes }) => attributes?.method === template)?.attributes.method_value
    });

    /***** RENDER *****/
    return (
        <div className="twoFactor__title">
            {(() => {
                switch (template) {
                    case 'EMAIL':
                        return (
                            <>
                                <TwoFactorHeading>Email Two-Factor Authentication</TwoFactorHeading>
                                <TwoFactorHeading.Description>
                                    Your verification code has been sent to <TwoFactorHeading.Destination>{methodValue}</TwoFactorHeading.Destination>
                                    . Please enter it below to log into {application}
                                </TwoFactorHeading.Description>
                            </>
                        );
                    case 'SMS':
                        return (
                            <>
                                <TwoFactorHeading>Mobile Two-Factor Authentication</TwoFactorHeading>
                                <TwoFactorHeading.Description>
                                    Your verification code has been sent to <TwoFactorHeading.Destination>{methodValue}</TwoFactorHeading.Destination>
                                    . Please enter it below to log into {application}
                                </TwoFactorHeading.Description>
                            </>
                        );
                    case 'PUSH':
                        return (
                            <>
                                <TwoFactorHeading>Push Notification</TwoFactorHeading>
                                <TwoFactorHeading.Description>
                                    We sent a notification to your device from the {application} app. Tap ACCEPT when requested to complete the
                                    verification request and confirm it's really you.
                                </TwoFactorHeading.Description>
                            </>
                        );
                    case 'GAUTH':
                        return (
                            <>
                                <TwoFactorHeading>Two-Factor Authentication</TwoFactorHeading>
                                <TwoFactorHeading.Description>
                                    In order to continue with your login to {application}, you'll need to complete the authentication process.
                                </TwoFactorHeading.Description>
                            </>
                        );
                    default:
                        return null;
                }
            })()}
        </div>
    );
};
