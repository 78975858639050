import { SolidButton } from 'components/Buttons/SolidButton';
import { pushNotification } from 'components/Toast/functions';
import { useEnterDrawMutation, useGetDrawEligibilityQuery } from 'config/containers/promotions/queries';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type ButtonComponent = {
    promoId: string;
    toggleModal: () => void;
};

type ErrorDataProps = {
    already_redeemed: boolean;
    is_promo_finished: boolean;
    is_promo_started: boolean;
    is_vip_reward_member: boolean;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const RewardCardDrawButton = ({ promoId, toggleModal }: ButtonComponent) => {
    /***** HOOKS *****/
    const {
        isError: isGetDrawEligibilityError,
        error: get_draw_eligibility_error,
        isPending: isPromoQueryPending
    } = useGetDrawEligibilityQuery(promoId);
    const { mutate: enterDraw, isPending: isEnterDrawPending } = useEnterDrawMutation(promoId);

    /***** RENDER HELPERS *****/
    function renderButtonContents() {
        if (!isGetDrawEligibilityError) return 'Enter Now';

        const errorData: ErrorDataProps = (get_draw_eligibility_error as any)?.data;

        if (!errorData) return 'Error: Invalid';
        if (errorData.is_promo_finished) return 'Promo Finished';
        if (errorData.already_redeemed) return 'Entered';
        if (!errorData.is_promo_started) return 'Promo Not Started';
        if (!errorData.is_vip_reward_member) return 'Not a VIP Member';
    }

    const handleEnterDraw = () => {
        enterDraw(undefined, {
            onSuccess: () => {
                toggleModal();
            },
            onError: (error) => {
                pushNotification(error?.errors?.[0] || { details: 'An error occurred. Please try again later.', status: 400 });
            }
        });
    };

    /***** PROPS *****/
    const props = {
        ...(promoId && {
            isLoading: isPromoQueryPending || isEnterDrawPending,
            disabled: isPromoQueryPending || isGetDrawEligibilityError,
            onClick: handleEnterDraw
        })
    };

    /***** RENDER *****/
    return (
        <SolidButton color="primary" {...props}>
            {renderButtonContents()}
        </SolidButton>
    );
};
