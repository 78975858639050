import { _TextVMaxSkeleton as TextVMaxSkeleton } from 'components/Utils/Text/VMax/Skeleton';
import { _TextVMax as TextVMax } from 'components/Utils/Text/VMax/TextVMax';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export function SkeletonHOC({ size, fontWeight, ...props }: Omit<TextVMax.Props, 'children'>) {
    const _TextHOC = ({ ...restProps }: TextVMax.BaseProps) => <TextVMax size={size} fontWeight={fontWeight} {...props} {...restProps} />;

    const _TextVMaxSkeletonHOC = ({ ...restProps }: TextVMax.BaseProps & TextVMaxSkeleton.BaseProps) => (
        <TextVMaxSkeleton size={size} fontWeight={fontWeight} {...props} {...props} {...restProps} />
    );

    /***** HOOK RESULTS *****/
    return Object.assign(_TextHOC, {
        Skeleton: _TextVMaxSkeletonHOC
    });
}
