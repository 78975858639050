import { queryOptions, useQuery } from '@tanstack/react-query';
import { katanaQueryKeys } from 'containers/katana/queries/katanaQueryKeys';
import type { KatanaNamespace } from 'containers/katana/types';
import { queryClient } from 'store/queryClient';
import { KATANA_API } from 'utilities/api/katana';
import { MINUTE } from 'utilities/consts';
import { handleDefaultErrorNotification } from 'utilities/methods/commonActions/handleDefaultErrorNotification';
import { createSetQueryDataMethod } from 'utilities/methods/tanstack/createSetQueryDataMethod';
import { createOptimisticMethods } from 'utilities/methods/tanstack/optimistic/createOptimisticMethods';

type TData = Artah.Katana.ID.Info.GET._200;

function createQueryKey(serviceID: KatanaNamespace.ServiceId) {
    return katanaQueryKeys.katana.service.ID.info(serviceID);
}

function createQueryOptions(serviceID: KatanaNamespace.ServiceId) {
    return queryOptions({
        queryKey: createQueryKey(serviceID),
        queryFn: () =>
            KATANA_API.katana.service_id.info.GET(serviceID).catch((e) => {
                handleDefaultErrorNotification(e);
                throw e;
            }),
        staleTime: MINUTE * 2.5,
        enabled: Boolean(serviceID),
        select: (data) => {
            if (data?.status === 200) {
                return data.data;
            }
        },
    });
}

const setQueryData = createSetQueryDataMethod<KatanaNamespace.ServiceId, TData>(createQueryKey);

export function cancelQueries(serviceID: KatanaNamespace.ServiceId) {
    return queryClient.cancelQueries({
        queryKey: createQueryKey(serviceID),
    });
}

function resetQueries(serviceID: KatanaNamespace.ServiceId) {
    return queryClient.resetQueries({
        queryKey: createQueryKey(serviceID),
    });
}

function prefetchQuery(serviceID: KatanaNamespace.ServiceId) {
    return queryClient.prefetchQuery(createQueryOptions(serviceID));
}

function invalidateQueries(serviceID: KatanaNamespace.ServiceId) {
    return queryClient.invalidateQueries({ queryKey: createQueryKey(serviceID) });
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Gets the site info
 */
function _useQuery(serviceID: KatanaNamespace.ServiceId) {
    return useQuery(createQueryOptions(serviceID));
}

/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const getSiteInfo = Object.freeze({
    useQuery: _useQuery,
    setQueryData,
    cancelQueries,
    resetQueries,
    prefetchQuery,
    invalidateQueries,
    optimistic: createOptimisticMethods(setQueryData),
    createQueryKey,
    createQueryOptions,
});
