import { DotSiteRewardCard } from 'containers/vipRewards/modules/rewardCard/cards/dotsite';
import { HelloFreshAUOfferCard } from 'containers/vipRewards/modules/rewardCard/cards/helloFreshAUOffer';
import { HelloFreshNZOfferCard } from 'containers/vipRewards/modules/rewardCard/cards/helloFreshNZOffer';
import { OneClickCards } from 'containers/vipRewards/modules/rewardCard/cards/oneClickCard';
import './_rewardCard.scss';
import { DotOnlineRewardCard } from './cards/dotonline';
import { DotStoreReferralCard } from './cards/dotstore';
import { DotXyzReferralCard } from './cards/dotxyz';
import { ReferralRewardCard } from './cards/referral';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const RewardCard = {
    Referral: ReferralRewardCard,
    FreeDotStore2024: DotStoreReferralCard,
    FreeDotOnline2024: DotOnlineRewardCard,
    FreeDotXyz2024: DotXyzReferralCard,
    FreeDotSite2024: DotSiteRewardCard,
    HelloFreshNZ: HelloFreshNZOfferCard,
    HelloFreshAU: HelloFreshAUOfferCard,
    ...OneClickCards
};
