import NXBox from 'components/NXBox';
import { Padding } from 'components/Utils/Padding';
import type { PaddingNamespace } from 'components/Utils/Padding/types';
import { RenderForBrands } from 'config/brandRenderer/component';
import { serviceNav } from 'config/config';
import { useBrandStore } from 'config/hooks/useBrandStore';
import { ExpressServiceManagerContext } from 'containers/dashboard/Components/expressServiceManager/context';
import { DomainOrderingFormLinks } from 'containers/dashboard/Components/expressServiceManager/domainOrderingFormLinks';
import { getVPSList } from 'containers/services/action';
import DomainTable from 'containers/services/modules/domains';
import EmailHostingTable from 'containers/services/modules/email';
import GSuiteTable from 'containers/services/modules/gsuite';
import { HostingTable } from 'containers/services/modules/hosting';
import KatanaServiceListTable from 'containers/services/modules/katanaServicesList/katanaServiceListTable';
import { Microsoft365Table } from 'containers/services/modules/ms365';
import { SSLTable } from 'containers/services/modules/ssl';
import { VPSTable } from 'containers/services/modules/vps';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isRouteActive } from 'router/utils/isRouteActive';
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';
import type { NXUtils } from 'utilities/NXUtils';
import { ExpressServiceManagerPurchaseLink } from './purchaseLink';
import { ExpressServiceManagerTabs } from './serviceTabs';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type ServiceNavKey = NXUtils.ValueOf<typeof serviceNav>['key'];

/**
 * @typedef {import("utilities/types").NXUtils.ValueOf<typeof serviceNav>['key']} ServiceNavKey
 */

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const ExpressServiceManager = () => {
    /***** STATE *****/
    const [view, setView] = useState<ServiceNavKey>(serviceNav['Domain Names'].key);

    /***** SELECTORS *****/
    const vps_list_meta = useSelector((state: any) => state.services.vps_list_meta);

    /***** HOOKS *****/
    const isMobile = useAppViewport(['xs', 'sm']);
    const { activeBrand } = useBrandStore();

    /***** EFFECTS *****/
    useEffect(() => {
        // For Intaserve, fetch VPS list if it hasn't been fetched already. So that we can determine whether or not to show the VPS tab
        if (isRouteActive({ path: '/my-services/vps', params: {} }) && activeBrand === 'intaserve' && !vps_list_meta) {
            getVPSList();
        }
    }, []);

    /***** RENDER HELPERS *****/
    const tables = {
        [serviceNav['cPanel Hosting'].key]: <HostingTable />,
        [serviceNav['Email Hosting'].key]: <EmailHostingTable isExpressServiceManager />,
        [serviceNav['Google Workspace'].key]: <GSuiteTable isExpressServiceManager />,
        [serviceNav['VPS'].key]: <VPSTable isExpressServiceManager />,
        [serviceNav['SSL Certificates'].key]: <SSLTable isExpressServiceManager />,
        [serviceNav['Microsoft 365'].key]: <Microsoft365Table isExpressServiceManager />,
        [serviceNav['VIPsites'].key]: <KatanaServiceListTable />,
        [serviceNav['Domain Names'].key]: <DomainTable isExpressServiceManager />
    };

    const paddingProps = {
        outer: {
            x: isMobile ? 0 : 6,
            y: isMobile ? 0 : 5
        },
        title: {
            bottom: isMobile ? 0 : 3,
            xy: isMobile ? 5 : 0
        }
    } satisfies Record<string, Omit<PaddingNamespace.Props, 'children'>>;

    /***** RENDER *****/
    return (
        <div className="dashboardExpressServiceManager">
            <ExpressServiceManagerContext.Provider value>
                <NXBox>
                    {/* Header */}
                    <Padding {...paddingProps.outer}>
                        <Padding className="dashboardExpressServiceManager__titleRow" {...paddingProps.title}>
                            <NXBox.Title title="Express Service Manager" />
                            <RenderForBrands brands={['intaserve']}>
                                <DomainOrderingFormLinks />
                            </RenderForBrands>
                            <RenderForBrands brands={['ventra']}>
                                <ExpressServiceManagerPurchaseLink view={view} />
                            </RenderForBrands>
                        </Padding>
                        <ExpressServiceManagerTabs view={view} setView={setView} />
                    </Padding>

                    {/* Render Tables */}
                    {tables[view] ?? tables[serviceNav['Domain Names'].key]}
                </NXBox>
            </ExpressServiceManagerContext.Provider>
        </div>
    );
};
