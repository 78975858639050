import Text from 'components/Utils/Text';
import React from 'react';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type NXBoxTitle = React.FC<{
    title?: React.ReactNode;
    children?: React.ReactNode;
    className?: string;
    style?: React.CSSProperties;
}>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Provides primary color and some other text styling such as font size and weight
 *
 * Using children is recommended, however title is temporarily retained for existing components.
 */
export const NXBoxTitle: NXBoxTitle = ({ title, className, children, style }) => {
    /***** RENDER *****/
    return (
        <Text size--xl primary semiBold className={className} style={style}>
            <h3 className="NXBoxTitle">{children ?? title}</h3>
        </Text>
    );
};
