import classNames from 'classnames';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import Text from 'components/Utils/Text';
import React from 'react';
import './_DisplayToggle.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Props = {
    isOpen: boolean;
    handleOpen: () => void;
    handleClose: () => void;
    
    /**
     * On what side of the element to anchor the component onto
     */
    anchor?: 'right' | 'left';
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _DisplayToggle: React.FC<Props> = ({ isOpen, handleOpen, handleClose, anchor = 'right' }) => {
    const className = classNames('DevTool__displayToggle', {
        'DevTool__displayToggle--isOpen': isOpen,
        [`DevTool__displayToggle--anchor-${anchor}`]: anchor
    });

    /***** RENDER *****/
    return (
        <div className={className}>
            <button type="button" className="DevTool__displayToggle__show" onClick={handleOpen}>
                <PhosphorIcons.ArrowLineRight white size={12} />
            </button>
            <button type="button" className="DevTool__displayToggle__hide" onClick={handleClose}>
                <PhosphorIcons.ArrowLineLeft white size={12} />
                <Text size--xs lead--1 white>
                    hide
                </Text>
            </button>
        </div>
    );
};
