/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { Icon } from '@iconify-icon/react';
import classNames from 'classnames';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { registerIconsForPreload } from 'components/Icons/methods';
import { createAppliedStylingClassesWithImportant } from 'components/Utils/methods';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { phosphorIconPropKeys } from 'components/Icons/Phosphor/consts';
import './_IconifyIcon.scss';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { IconifyIconNamespace } from 'components/Icons/types';
import { useStyle } from 'utilities/hooks/useStyle';
import { useTheme } from 'utilities/hooks/useTheme';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const IconifyIcon: IconifyIconNamespace.Component = (props) => {
    const {
        className,

        /**
         * This does not have a default size, as we're setting that in the css, so that we can override the size based on context. See VMAxBaseButton
         */
        size,
        icon,
        color,
        svgProps,
    } = props;

    /***** RENDER HELPERS *****/
    const appliedStylingClasses = createAppliedStylingClassesWithImportant({
        props,
        keyBoundary: phosphorIconPropKeys,
        componentName: 'Icon',
        delimiter: '--',
    });
    const _IconClassNames = classNames('Icon', `Icon--${icon}`, appliedStylingClasses, className, svgProps?.className);

    const hasSize = typeof size === 'number';

    const styles = useStyle({
        '--Icon-size': hasSize ? `${size}px` : undefined,
    });

    const themeStyles = useTheme({
        '--Icon-color': color,
    });

    const _size = hasSize ? size : '100%';

    /***** RENDER *****/
    return <Icon icon={icon} {...svgProps} className={_IconClassNames} width={_size} height={_size} style={{ ...styles, ...themeStyles }} />;
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

/**
 * If loading the names and preloading ends up causing display issues we can always resort to manual import of the svg data using icon data:
 * @link https://www.npmjs.com/package/@iconify-icon/react#using-icon-data
 */
const Iconify = (icon: string) => {
    const IconifyIconWrapper: IconifyIconNamespace.Component = (props) => {
        return <IconifyIcon icon={icon} {...props} />;
    };

    registerIconsForPreload(icon);

    return IconifyIconWrapper;
};

export { Iconify, IconifyIcon };
