/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useEffect, useState } from 'react';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { VipRewardsTierBar } from './tierBar';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import InactiveButton from 'components/Buttons/InactiveButton';
import { SolidButton } from 'components/Buttons/SolidButton';
import { DialogNotification } from 'components/Notifications/DialogNotification';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useBrandStore } from 'config/hooks/useBrandStore';
import { createDomainAvailabilityQueryKey, postDomainAvailability } from 'containers/domain/action';
import { useVipRewards } from 'containers/vipRewards/hooks';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { getPurchaseLink } from 'containers/services/consts';
import './_vipRewardsHomeRegisterDomainLightbox.scss';

const tlds = ['.au', '.com.au', '.com', '.net.au', '.online', '.store'];

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function VipRewardsHomeRegisterDomainLightbox() {
    /***** HOOKS *****/
    const { application } = useBrandStore();
    const { currentTier, rewardsTiers, promoCodes } = useVipRewards();

    const currentTierPromoCodes = promoCodes[currentTier?.lowercase] || [];
    const domainRegoPromoCode = currentTierPromoCodes.find(
        ({ apply_to, invoice_types }) => apply_to === 'domain' && Array.isArray(invoice_types) && invoice_types?.includes('new')
    )?.code;

    const domainAvailabilityRequestParams = {
        domain: 'wqeriuwqeruweqrosfuiyewquriqweroewiruwqeoriuweorqwerwqe',
        tlds,
        promos: [domainRegoPromoCode],
        action: 'register'
    };
    const domainAvailabilityQueryKey = createDomainAvailabilityQueryKey(domainAvailabilityRequestParams);

    /***** STATE *****/
    const [domainValue, setDomainValue] = useState('');

    /***** FUNCTIONS *****/
    function fetchTldExamplePricing() {
        // The domain availability endpoint returns an error if the customer already owns the domain, so use a domain that is unlikely to be owned by anyone to check generic tld pricing
        postDomainAvailability(domainAvailabilityRequestParams, domainAvailabilityQueryKey);
    }

    /***** EFFECTS *****/
    useEffect(fetchTldExamplePricing, []);

    /***** RENDER *****/
    return (
        <div className="vipRewardsHomeRegisterDomain">
            <div className="vipRewardsHomeRegisterDomain__domainSearch">
                <div className="form__item">
                    <div className="form__item__inner">
                        <div className="wrapperbox">
                            <input type="text" value={domainValue} onChange={(e) => setDomainValue(e.target.value)} />
                        </div>
                        {domainValue ? (
                            <SolidButton
                                type="anchor"
                                href={`${getPurchaseLink('Domain Names')}?a=add&query=${domainValue}&domain=register`}
                                target="_blank"
                            >
                                Search
                            </SolidButton>
                        ) : (
                            <InactiveButton>Search</InactiveButton>
                        )}
                    </div>
                </div>
            </div>

            <div className="vipRewardsHomeRegisterDomain__main">
                <VipRewardsTierBar
                    tier={currentTier?.lowercase}
                    discount={`${rewardsTiers?.[currentTier?.lowercase]?.new_domain_benefit || ''}% off`}
                />

                <DialogNotification type="warning" tail={{ pos: 'top' }}>
                    PLEASE NOTE: All discounts will be displayed during the ordering process after you have logged in to your {application} account.
                </DialogNotification>

                <div className="vipRewardsHomeRegisterDomain__tandcContainer">
                    <Text secondary italic size--xs align--right lead--s>
                        *Discount applies to new standard registrations only. Discounts cannot be combined with pre-existing discounts. Only one
                        discount can be applied per domain name per purchase.
                    </Text>
                </div>
            </div>
        </div>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default VipRewardsHomeRegisterDomainLightbox;
