import classNames from 'classnames';
import './_SimpleTable.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type SimpleTableProps = {
    /**
     * the row that show the content of the table which must include a key i.e.:
     * [<p key="foo">foo</p>,<p key="bar">bar</p>]
     */
    rows?: React.ReactNode[];
    alignRowItems?: 'start';
    className?: string;
    children?: React.ReactNode;
};

type SimpleTableRow = React.FC<{
    children: React.ReactNode;
}>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const SimpleTableRow: SimpleTableRow = ({ children }) => {
    return <li className="SimpleTable__row">{children}</li>;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const _SimpleTable: React.FC<SimpleTableProps> = ({ rows, alignRowItems, className, children }) => {
    /***** RENDER HELPERS *****/
    const classes = classNames('SimpleTable', className, {
        [`SimpleTable--align-row-items--${alignRowItems}`]: alignRowItems
    });

    /***** RENDER *****/
    return <ul className={classes}>{children || rows?.map((columns, i) => <SimpleTableRow>{columns}</SimpleTableRow>)}</ul>;
};

const SimpleTable = Object.assign(_SimpleTable, {
    Row: SimpleTableRow
});

export default SimpleTable;
