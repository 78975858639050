import { useRouter } from '@tanstack/react-router';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import type { KatanaNamespace } from 'containers/katana/types';
import _ from 'lodash';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type UseKatanaChecklistDataProps = {
    iconSize?: number;
    serviceID?: KatanaNamespace.ServiceId;
};

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export function useKatanaChecklistData(props: UseKatanaChecklistDataProps = {}) {
    const { iconSize = 26, serviceID } = props;

    /***** HOOKS *****/
    const { katanaServiceId } = useKatanaParams();
    const ID = _.has(props, 'serviceID') ? serviceID : katanaServiceId;
    const { buildLocation } = useRouter();

    /***** QUERIES *****/
    const { data: get_katana_service_data } = katanaQuery.serviceID.service.useQuery(ID);
    const launch_checklist = get_katana_service_data?.attributes?.launch_checklist ?? [];

    // Basic Details
    const editorBasicDetailsHref = buildLocation({
        to: '/my-services/vip-sites/$katanaServiceId/editor/basic-details',
        params: {
            katanaServiceId: ID,
        },
    }).href;
    const setupBasicDetailsHref = buildLocation({
        to: '/my-services/vip-sites/$katanaServiceId/setup/basic-details',
        params: {
            katanaServiceId: ID,
        },
    }).href;

    // Template and Content
    const setupPresetContentHref = buildLocation({
        to: '/my-services/vip-sites/$katanaServiceId/setup/template-content',
        params: {
            katanaServiceId: ID,
        },
    }).href;
    const addSectionHref = buildLocation({
        to: '/my-services/vip-sites/$katanaServiceId/add-section',
        params: {
            katanaServiceId: ID,
        },
    }).href;

    // Colours and fonts
    const setupColourFontsHref = buildLocation({
        to: '/my-services/vip-sites/$katanaServiceId/setup/colours-fonts',
        params: {
            katanaServiceId: ID,
        },
    }).href;
    const editorColourFontsHref = buildLocation({
        to: '/my-services/vip-sites/$katanaServiceId/editor/colours-fonts',
        params: {
            katanaServiceId: ID,
        },
    }).href;

    // finishing touches
    const setupFinishingTouchesHref = buildLocation({
        to: '/my-services/vip-sites/$katanaServiceId/setup/finalise',
        params: {
            katanaServiceId: ID,
        },
    }).href;
    const editorFinishingTouchesHref = buildLocation({
        to: '/my-services/vip-sites/$katanaServiceId/editor/finalise',
        params: {
            katanaServiceId: ID,
        },
    }).href;

    type CheckListData = {
        icon: JSX.Element;
        title: string;
        to: string;
        checkListKey: KatanaNamespace.SiteSetupStages;
    };

    /***** HOOK RESULTS *****/
    const checkListData: CheckListData[] = [
        {
            icon: <PhosphorIcons.ClipboardText black size={iconSize} />,
            title: 'Basic Information',
            to: launch_checklist.includes('webpage-info-completed') ? editorBasicDetailsHref : setupBasicDetailsHref,
            checkListKey: 'webpage-info-completed',
        },
        {
            icon: <PhosphorIcons.Palette black size={iconSize} />,
            title: 'Colours and Fonts',
            to: launch_checklist.includes('theme-and-color-completed') ? editorColourFontsHref : setupColourFontsHref,
            checkListKey: 'theme-and-color-completed',
        },
        {
            icon: <PhosphorIcons.Layout black size={iconSize} />,
            title: 'Template and Content',
            to: launch_checklist.includes('layout-and-content-completed') ? addSectionHref : setupPresetContentHref,
            checkListKey: 'layout-and-content-completed',
        },
        {
            icon: <PhosphorIcons.RocketLaunch black size={iconSize} />,
            title: 'Finishing Touches',
            to: launch_checklist.includes('finishing-touches-completed') ? editorFinishingTouchesHref : setupFinishingTouchesHref,
            checkListKey: 'finishing-touches-completed',
        },
    ];

    return checkListData;
}
