import { _TextVMaxH1 } from 'components/Utils/Text/VMax/H1';
import { _TextVMaxH2 } from 'components/Utils/Text/VMax/H2';
import { _TextVMaxParagraph } from 'components/Utils/Text/VMax/Paragraph';
import { _TextVMaxSkeleton } from 'components/Utils/Text/VMax/Skeleton';
import { _TextVMaxSmall } from 'components/Utils/Text/VMax/Small';
import { _TextVMax } from 'components/Utils/Text/VMax/TextVMax';

export const TextVMax = Object.assign(_TextVMax, {
    H1: _TextVMaxH1,
    H2: _TextVMaxH2,
    Paragraph: _TextVMaxParagraph,
    Small: _TextVMaxSmall,
    Skeleton: _TextVMaxSkeleton
});
