import { authenticationQueryKeys } from 'containers/login/queries/queryKeys';
import { API } from 'utilities/api/login';
import { createNXMutation } from 'utilities/methods/tanstack/createMutation';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
export const submitLogin = createNXMutation({
    mutationKey: authenticationQueryKeys.login(),
    mutationFn: API.login.submit
});
