import { useNavigate } from '@tanstack/react-router';
import { CustomDropdown } from 'components/Dropdowns/CustomDropdown';
import SelectOptions from 'components/Dropdowns/SelectDropdown/Options';
import Title from 'components/Title';
import { pushNotification } from 'components/Toast/functions';
import { getHostingProduct, getHostingProductName } from 'containers/hosting/methods';
import { prepStateForSubaccount } from 'containers/hosting/state/accountActions';
import { getTicketSubmitUrl } from 'containers/support/methods';
import { useDispatch, useSelector } from 'react-redux';
import { API } from 'utilities/api/hosting';
import { createPopup, getDataFromSuccessResponse, getErrorFromFailResponse } from 'utilities/methods/commonActions';

export const _HostingResellerSubaccountTitle = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const hosting_information_data = useSelector((state: any) => state.hosting.hosting_information_data);

    /***** DERIVED *****/
    const { type, id } = hosting_information_data;
    const { domain } = hosting_information_data.attributes;
    const product = getHostingProduct(hosting_information_data);
    const productName = getHostingProductName(product);

    /***** FUNCTIONS *****/
    const handleLogin = () => {
        const { closePopup, goToTargetUrl } = createPopup();

        API.hosting.GET.login
            .whm(id)
            .then((response) => {
                const data = getDataFromSuccessResponse(response);
                goToTargetUrl(data.attributes.url);
            })
            .catch((error) => {
                pushNotification(getErrorFromFailResponse(error));
                closePopup();
            });
    };

    /***** RENDER *****/
    return (
        <Title
            serviceTitle={domain}
            serviceSubTitle={`${productName} (Reseller)`}
            serviceType={type}
            action={[
                {
                    className: 'listAccount__action',
                    label: 'Manage',
                    color: 'primary',
                    type: 'onClick',
                    onClick: (e) => {
                        e.preventDefault();
                        prepStateForSubaccount()(dispatch);
                        navigate({ to: `/my-services/hosting/${id}/account#overview` });
                    }
                },
                {
                    className: 'listAccount__action',
                    label: 'WHM Login',
                    color: 'notice',
                    type: 'onClick',
                    onClick: (e) => {
                        e.preventDefault();
                        handleLogin();
                    }
                },
                <CustomDropdown
                    className="TitleShared__singleDropdown"
                    label={({ labelRef, dropdownTitleOnClick }) => (
                        <button className="TitleShared__singleDropdownButton" ref={labelRef} type="button" onClick={() => dropdownTitleOnClick()}>
                            Web Hosting Help
                            <i className="item__icon icon icon-chevron-down" />
                        </button>
                    )}
                    key="dropdown"
                    render={() => (
                        <SelectOptions
                            options={[
                                {
                                    label: 'Web Hosting Help Articles',
                                    onSelect: () =>
                                        navigate({
                                            to: '/support/support-centre',
                                            search: {
                                                categories: 'products, web-hosting-products'
                                            }
                                        })
                                },
                                {
                                    label: 'Submit a Technical Support eTicket for this Service',
                                    onSelect: () => navigate({ to: getTicketSubmitUrl({ department: 'technical-support', service: id }) })
                                },
                                {
                                    label: 'Submit a Billing eTicket for this Service',
                                    onSelect: () => navigate({ to: getTicketSubmitUrl({ department: 'accounts-billing', service: id }) })
                                }
                            ]}
                        />
                    )}
                />
            ]}
        />
    );
};
