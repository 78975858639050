/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import _ from 'lodash';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { NXUtils } from 'utilities/NXUtils';

/**
 * Lodash _.get abstraction that has correct typing for nested object keys.
 * 
 * This will correctly infer the key type as well as the value type for the provided key.
 */
export const typedGet = <T extends object, K extends NXUtils.Path<T>>(obj: T, key: K): NXUtils.Choose<T, K> => _.get(obj, key) as unknown as any;

/** 
 * Lodash _.set abstraction that has correct typing for nested object keys. 
 * 
 * This will correctly infer the key type as well as the value type for the provided key.
 * 
 * @note This does not clone the object, so if a new reference is needed to trigger a re-render, etc. use `typedSetClone` instead.
 */
export const typedSet = <T extends object, K extends NXUtils.Path<T>>(obj: T, key: K, value: NXUtils.Choose<T, K>) =>
    _.set(obj, key, value) as unknown as any;

/** 
 * Lodash _.set abstraction (with an internal deep clone on the passed object) that has correct typing for nested object keys. 
 * 
 * This will correctly infer the key type as well as the value type for the provided key.
 */
export const typedSetClone = <T extends object, K extends NXUtils.Path<T>>(obj: T, key: K, value: NXUtils.Choose<T, K>) =>
    _.set(_.cloneDeep(obj), key, value);
