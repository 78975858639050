/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { queryOptions, useQuery } from '@tanstack/react-query';
import { WP_SUPPORT_CENTRE_API } from 'utilities/api/ventraip-website-wp-support-centre';
import type { VentraIPWordpressSupportCentreAPI } from 'utilities/api/ventraip-website-wp-support-centre/types';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { createBaseQueryKey } from 'utilities/methods/tanstack/createBaseQueryKey';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type PartialParams = Partial<VentraIPWordpressSupportCentreAPI.SearchParams>;

function createQueryKey(params: PartialParams) {
    return createBaseQueryKey(['ventraip-website-wp-support-centre', 'search', params]);
}

function createQueryOptions(params: PartialParams) {
    return queryOptions({
        queryKey: createQueryKey(params),
        queryFn: () => WP_SUPPORT_CENTRE_API.search.GET(params),
        staleTime: Infinity,
        enabled: !!params.search,
        select: (data) => {
            return data?.data;
        }
    });
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useQuery(params: PartialParams) {
    return useQuery(createQueryOptions(params));
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export const getSearchResults = Object.freeze({
    useQuery: _useQuery
});
