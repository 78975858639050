export const APP_MOUNTING = 'app/APP_MOUNTING';
export const ACCOUNT_INITIAL_STATE = 'account/ACCOUNT_INITIAL_STATE';
export const ACCOUNT_USER_REQUEST = 'account/ACCOUNT_USER_REQUEST';
export const ACCOUNT_USER_SUCCESS = 'account/ACCOUNT_USER_SUCCESS';
export const ACCOUNT_USER_ERROR = 'account/ACCOUNT_USER_ERROR';
export const ACCOUNT_UPDATE_EMAIL_REQUEST = 'account/ACCOUNT_UPDATE_EMAIL_REQUEST';
export const ACCOUNT_UPDATE_EMAIL_SUCCESS = 'account/ACCOUNT_UPDATE_EMAIL_SUCCESS';
export const ACCOUNT_UPDATE_EMAIL_ERROR = 'account/ACCOUNT_UPDATE_EMAIL_ERROR';
export const ACCOUNT_UPDATE_CLIENT_TYPE_REQUEST = 'account/ACCOUNT_UPDATE_CLIENT_TYPE_REQUEST';
export const ACCOUNT_UPDATE_CLIENT_TYPE_SUCCESS = 'account/ACCOUNT_UPDATE_CLIENT_TYPE_SUCCESS';
export const ACCOUNT_UPDATE_CLIENT_TYPE_ERROR = 'account/ACCOUNT_UPDATE_CLIENT_TYPE_ERROR';
export const ACCOUNT_CONTACT_LIST_REQUEST = 'account/ACCOUNT_CONTACT_LIST_REQUEST';
export const ACCOUNT_CONTACT_LIST_SUCCESS = 'account/ACCOUNT_CONTACT_LIST_SUCCESS';
export const ACCOUNT_CONTACT_LIST_ERROR = 'account/ACCOUNT_CONTACT_LIST_ERROR';
export const ACCOUNT_ADD_CONTACT_REQUEST = 'account/ACCOUNT_ADD_CONTACT_REQUEST';
export const ACCOUNT_ADD_CONTACT_SUCCESS = 'account/ACCOUNT_ADD_CONTACT_SUCCESS';
export const ACCOUNT_ADD_CONTACT_ERROR = 'account/ACCOUNT_ADD_CONTACT_ERROR';
export const ACCOUNT_UPDATE_CONTACT_REQUEST = 'account/ACCOUNT_UPDATE_CONTACT_REQUEST';
export const ACCOUNT_UPDATE_CONTACT_SUCCESS = 'account/ACCOUNT_UPDATE_CONTACT_SUCCESS';
export const ACCOUNT_UPDATE_CONTACT_ERROR = 'account/ACCOUNT_UPDATE_CONTACT_ERROR';
export const ACCOUNT_REMOVE_CONTACT_REQUEST = 'account/ACCOUNT_REMOVE_CONTACT_REQUEST';
export const ACCOUNT_REMOVE_CONTACT_SUCCESS = 'account/ACCOUNT_REMOVE_CONTACT_SUCCESS';
export const ACCOUNT_REMOVE_CONTACT_ERROR = 'account/ACCOUNT_REMOVE_CONTACT_ERROR';
export const ACCOUNT_UPDATE_USER_REQUEST = 'account/ACCOUNT_UPDATE_USER_REQUEST';
export const ACCOUNT_UPDATE_USER_SUCCESS = 'account/ACCOUNT_UPDATE_USER_SUCCESS';
export const ACCOUNT_UPDATE_USER_ERROR = 'account/ACCOUNT_UPDATE_USER_ERROR';
export const ACCOUNT_PROVIDE_MISSING_DETAILS_REQUEST = 'account/ACCOUNT_PROVIDE_MISSING_DETAILS_REQUEST';
export const ACCOUNT_PROVIDE_MISSING_DETAILS_SUCCESS = 'account/ACCOUNT_PROVIDE_MISSING_DETAILS_SUCCESS';
export const ACCOUNT_PROVIDE_MISSING_DETAILS_ERROR = 'account/ACCOUNT_PROVIDE_MISSING_DETAILS_ERROR';

export const ACCOUNT_PERMISSION_REQUEST = 'account/ACCOUNT_PERMISSION_REQUEST';
export const ACCOUNT_PERMISSION_SUCCESS = 'account/ACCOUNT_PERMISSION_SUCCESS';
export const ACCOUNT_PERMISSION_ERROR = 'account/ACCOUNT_PERMISSION_ERROR';
export const ACCOUNT_ADDITIONAL_USER_LIST_REQUEST = 'account/ACCOUNT_ADDITIONAL_USER_LIST_REQUEST';
export const ACCOUNT_ADDITIONAL_USER_LIST_SUCCESS = 'account/ACCOUNT_ADDITIONAL_USER_LIST_SUCCESS';
export const ACCOUNT_ADDITIONAL_USER_LIST_ERROR = 'account/ACCOUNT_ADDITIONAL_USER_LIST_ERROR';
export const ACCOUNT_ADD_ADDITIONAL_USER_REQUEST = 'account/ACCOUNT_ADD_ADDITIONAL_USER_REQUEST';
export const ACCOUNT_ADD_ADDITIONAL_USER_SUCCESS = 'account/ACCOUNT_ADD_ADDITIONAL_USER_SUCCESS';
export const ACCOUNT_ADD_ADDITIONAL_USER_ERROR = 'account/ACCOUNT_ADD_ADDITIONAL_USER_ERROR';
export const ACCOUNT_REMOVE_ADDITIONAL_USER_REQUEST = 'account/ACCOUNT_REMOVE_ADDITIONAL_USER_REQUEST';
export const ACCOUNT_REMOVE_ADDITIONAL_USER_SUCCESS = 'account/ACCOUNT_REMOVE_ADDITIONAL_USER_SUCCESS';
export const ACCOUNT_REMOVE_ADDITIONAL_USER_ERROR = 'account/ACCOUNT_REMOVE_ADDITIONAL_USER_ERROR';
export const ACCOUNT_RESEND_INVITATION_REQUEST = 'account/ACCOUNT_RESEND_INVITATION_REQUEST';
export const ACCOUNT_RESEND_INVITATION_SUCCESS = 'account/ACCOUNT_RESEND_INVITATION_SUCCESS';
export const ACCOUNT_RESEND_INVITATION_ERROR = 'account/ACCOUNT_RESEND_INVITATION_ERROR';
export const ACCOUNT_CANCEL_INVITATION_REQUEST = 'account/ACCOUNT_CANCEL_INVITATION_REQUEST';
export const ACCOUNT_CANCEL_INVITATION_SUCCESS = 'account/ACCOUNT_CANCEL_INVITATION_SUCCESS';
export const ACCOUNT_CANCEL_INVITATION_ERROR = 'account/ACCOUNT_CANCEL_INVITATION_ERROR';
export const ACCOUNT_REVOKE_ACCESS_REQUEST = 'account/ACCOUNT_REVOKE_ACCESS_REQUEST';
export const ACCOUNT_REVOKE_ACCESS_SUCCESS = 'account/ACCOUNT_REVOKE_ACCESS_SUCCESS';
export const ACCOUNT_REVOKE_ACCESS_ERROR = 'account/ACCOUNT_REVOKE_ACCESS_ERROR';
export const ACCOUNT_UPDATE_ADDITIONAL_USER_PERMISSION_REQUEST = 'account/ACCOUNT_UPDATE_ADDITIONAL_USER_PERMISSION_REQUEST';
export const ACCOUNT_UPDATE_ADDITIONAL_USER_PERMISSION_SUCCESS = 'account/ACCOUNT_UPDATE_ADDITIONAL_USER_PERMISSION_SUCCESS';
export const ACCOUNT_UPDATE_ADDITIONAL_USER_PERMISSION_ERROR = 'account/ACCOUNT_UPDATE_ADDITIONAL_USER_PERMISSION_ERROR';
export const ACCOUNT_APPROVE_ADDITIONAL_USER_REQUEST = 'account/ACCOUNT_APPROVE_ADDITIONAL_USER_REQUEST';
export const ACCOUNT_APPROVE_ADDITIONAL_USER_SUCCESS = 'account/ACCOUNT_APPROVE_ADDITIONAL_USER_SUCCESS';
export const ACCOUNT_APPROVE_ADDITIONAL_USER_ERROR = 'account/ACCOUNT_APPROVE_ADDITIONAL_USER_ERROR';
export const ACCOUNT_APPROVE_ADDITIONAL_USER_RESET = 'account/ACCOUNT_APPROVE_ADDITIONAL_USER_RESET';
export const ACCOUNT_APPROVE_ADDITIONAL_USER_SIGNUP_REQUEST = 'account/ACCOUNT_APPROVE_ADDITIONAL_USER_SIGNUP_REQUEST';
export const ACCOUNT_APPROVE_ADDITIONAL_USER_SIGNUP_SUCCESS = 'account/ACCOUNT_APPROVE_ADDITIONAL_USER_SIGNUP_SUCCESS';
export const ACCOUNT_APPROVE_ADDITIONAL_USER_SIGNUP_ERROR = 'account/ACCOUNT_APPROVE_ADDITIONAL_USER_SIGNUP_ERROR';

export const ACCOUNT_SEND_BULK_INVOICES_REQUEST = 'account/ACCOUNT_SEND_BULK_INVOICES_REQUEST';
export const ACCOUNT_SEND_BULK_INVOICES_SUCCESS = 'account/ACCOUNT_SEND_BULK_INVOICES_SUCCESS';
export const ACCOUNT_SEND_BULK_INVOICES_ERROR = 'account/ACCOUNT_SEND_BULK_INVOICES_ERROR';
export const ACCOUNT_SLA_CLAIM_SERVICES_REQUEST = 'account/ACCOUNT_SLA_CLAIM_SERVICES_REQUEST';
export const ACCOUNT_SLA_CLAIM_SERVICES_SUCCESS = 'account/ACCOUNT_SLA_CLAIM_SERVICES_SUCCESS';
export const ACCOUNT_SLA_CLAIM_SERVICES_ERROR = 'account/ACCOUNT_SLA_CLAIM_SERVICES_ERROR';
export const ACCOUNT_SLA_CLAIM_EVENTS_REQUEST = 'account/ACCOUNT_SLA_CLAIM_EVENTS_REQUEST';
export const ACCOUNT_SLA_CLAIM_EVENTS_SUCCESS = 'account/ACCOUNT_SLA_CLAIM_EVENTS_SUCCESS';
export const ACCOUNT_SLA_CLAIM_EVENTS_ERROR = 'account/ACCOUNT_SLA_CLAIM_EVENTS_ERROR';
export const ACCOUNT_SLA_CLAIM_SUBMIT_REQUEST = 'account/ACCOUNT_SLA_CLAIM_SUBMIT_REQUEST';
export const ACCOUNT_SLA_CLAIM_SUBMIT_SUCCESS = 'account/ACCOUNT_SLA_CLAIM_SUBMIT_SUCCESS';
export const ACCOUNT_SLA_CLAIM_SUBMIT_ERROR = 'account/ACCOUNT_SLA_CLAIM_SUBMIT_ERROR';

export const ACCOUNT_SUBSCRIPTION_REQUEST = 'account/ACCOUNT_SUBSCRIPTION_REQUEST';
export const ACCOUNT_SUBSCRIPTION_SUCCESS = 'account/ACCOUNT_SUBSCRIPTION_SUCCESS';
export const ACCOUNT_SUBSCRIPTION_ERROR = 'account/ACCOUNT_SUBSCRIPTION_ERROR';
export const ACCOUNT_SUBSCRIPTION_UPDATE_REQUEST = 'account/ACCOUNT_SUBSCRIPTION_UPDATE_REQUEST';
export const ACCOUNT_EMAILS_REQUEST = 'account/ACCOUNT_EMAILS_REQUEST';
export const ACCOUNT_EMAILS_SUCCESS = 'account/ACCOUNT_EMAILS_SUCCESS';
export const ACCOUNT_EMAILS_ERROR = 'account/ACCOUNT_EMAILS_ERROR';
export const ACCOUNT_EMAILS_SEARCH_REQUEST = 'account/ACCOUNT_EMAILS_SEARCH_REQUEST';
export const ACCOUNT_EMAILS_SEARCH_SUCCESS = 'account/ACCOUNT_EMAILS_SEARCH_SUCCESS';
export const ACCOUNT_EMAILS_SEARCH_ERROR = 'account/ACCOUNT_EMAILS_SEARCH_ERROR';
export const ACCOUNT_EMAILS_SEARCH_RESET = 'account/ACCOUNT_EMAILS_SEARCH_RESET';
export const ACCOUNT_EMAILS_VIEW_REQUEST = 'account/ACCOUNT_EMAILS_VIEW_REQUEST';
export const ACCOUNT_EMAILS_VIEW_SUCCESS = 'account/ACCOUNT_EMAILS_VIEW_SUCCESS';
export const ACCOUNT_EMAILS_VIEW_ERROR = 'account/ACCOUNT_EMAILS_VIEW_ERROR';
export const ACCOUNT_EMAILS_RESEND_REQUEST = 'account/ACCOUNT_EMAILS_RESEND_REQUEST';
export const ACCOUNT_EMAILS_RESEND_SUCCESS = 'account/ACCOUNT_EMAILS_RESEND_SUCCESS';
export const ACCOUNT_EMAILS_RESEND_ERROR = 'account/ACCOUNT_EMAILS_RESEND_ERROR';

export const ACCOUNT_UPDATE_PASSWORD_REQUEST = 'account/ACCOUNT_UPDATE_PASSWORD_REQUEST';
export const ACCOUNT_UPDATE_PASSWORD_SUCCESS = 'account/ACCOUNT_UPDATE_PASSWORD_SUCCESS';
export const ACCOUNT_UPDATE_PASSWORD_ERROR = 'account/ACCOUNT_UPDATE_PASSWORD_ERROR';
export const ACCOUNT_LAST_UPDATE_PASSWORD_REQUEST = 'account/ACCOUNT_LAST_UPDATE_PASSWORD_REQUEST';
export const ACCOUNT_LAST_UPDATE_PASSWORD_SUCCESS = 'account/ACCOUNT_LAST_UPDATE_PASSWORD_SUCCESS';
export const ACCOUNT_LAST_UPDATE_PASSWORD_ERROR = 'account/ACCOUNT_LAST_UPDATE_PASSWORD_ERROR';
export const ACCOUNT_SECURITY_INFORMATION_REQUEST = 'account/ACCOUNT_SECURITY_INFORMATION_REQUEST';
export const ACCOUNT_SECURITY_INFORMATION_SUCCESS = 'account/ACCOUNT_SECURITY_INFORMATION_SUCCESS';
export const ACCOUNT_SECURITY_INFORMATION_ERROR = 'account/ACCOUNT_SECURITY_INFORMATION_ERROR';
export const ACCOUNT_SECURITY_QUESTIONS_REQUEST = 'account/ACCOUNT_SECURITY_QUESTIONS_REQUEST';
export const ACCOUNT_SECURITY_QUESTIONS_SUCCESS = 'account/ACCOUNT_SECURITY_QUESTIONS_SUCCESS';
export const ACCOUNT_SECURITY_QUESTIONS_ERROR = 'account/ACCOUNT_SECURITY_QUESTIONS_ERROR';
export const ACCOUNT_SECURITY_UPDATE_REQUEST = 'account/ACCOUNT_SECURITY_UPDATE_REQUEST';
export const ACCOUNT_SECURITY_UPDATE_SUCCESS = 'account/ACCOUNT_SECURITY_UPDATE_SUCCESS';
export const ACCOUNT_SECURITY_UPDATE_ERROR = 'account/ACCOUNT_SECURITY_UPDATE_ERROR';
export const ACCOUNT_TWOFACTOR_REQUEST = 'account/ACCOUNT_TWOFACTOR_REQUEST';
export const ACCOUNT_TWOFACTOR_SUCCESS = 'account/ACCOUNT_TWOFACTOR_SUCCESS';
export const ACCOUNT_TWOFACTOR_ERROR = 'account/ACCOUNT_TWOFACTOR_ERROR';
export const ACCOUNT_QR_CODE_REQUEST = 'account/ACCOUNT_QR_CODE_REQUEST';
export const ACCOUNT_QR_CODE_SUCCESS = 'account/ACCOUNT_QR_CODE_SUCCESS';
export const ACCOUNT_QR_CODE_ERROR = 'account/ACCOUNT_QR_CODE_ERROR';
export const ACCOUNT_TWOFACTOR_ENABLE_REQUEST = 'account/ACCOUNT_TWOFACTOR_ENABLE_REQUEST';
export const ACCOUNT_TWOFACTOR_ENABLE_SUCCESS = 'account/ACCOUNT_TWOFACTOR_ENABLE_SUCCESS';
export const ACCOUNT_TWOFACTOR_ENABLE_ERROR = 'account/ACCOUNT_TWOFACTOR_ENABLE_ERROR';
export const ACCOUNT_TWOFACTOR_CONFIG_REQUEST = 'account/ACCOUNT_TWOFACTOR_CONFIG_REQUEST';
export const ACCOUNT_TWOFACTOR_CONFIG_SUCCESS = 'account/ACCOUNT_TWOFACTOR_CONFIG_SUCCESS';
export const ACCOUNT_TWOFACTOR_CONFIG_ERROR = 'account/ACCOUNT_TWOFACTOR_CONFIG_ERROR';
export const ACCOUNT_TWOFACTOR_VERIFY_REQUEST = 'account/ACCOUNT_TWOFACTOR_VERIFY_REQUEST';
export const ACCOUNT_TWOFACTOR_VERIFY_SUCCESS = 'account/ACCOUNT_TWOFACTOR_VERIFY_SUCCESS';
export const ACCOUNT_TWOFACTOR_VERIFY_ERROR = 'account/ACCOUNT_TWOFACTOR_VERIFY_ERROR';
export const ACCOUNT_TWOFACTOR_DELETE_REQUEST = 'account/ACCOUNT_TWOFACTOR_DELETE_REQUEST';
export const ACCOUNT_TWOFACTOR_DELETE_SUCCESS = 'account/ACCOUNT_TWOFACTOR_DELETE_SUCCESS';
export const ACCOUNT_TWOFACTOR_DELETE_ERROR = 'account/ACCOUNT_TWOFACTOR_DELETE_ERROR';
export const ACCOUNT_TWOFACTOR_PRIMARY_REQUEST = 'account/ACCOUNT_TWOFACTOR_PRIMARY_REQUEST';
export const ACCOUNT_TWOFACTOR_PRIMARY_SUCCESS = 'account/ACCOUNT_TWOFACTOR_PRIMARY_SUCCESS';
export const ACCOUNT_TWOFACTOR_PRIMARY_ERROR = 'account/ACCOUNT_TWOFACTOR_PRIMARY_ERROR';
export const ACCOUNT_LOGIN_HISTORY_REQUEST = 'account/ACCOUNT_LOGIN_HISTORY_REQUEST';
export const ACCOUNT_LOGIN_HISTORY_SUCCESS = 'account/ACCOUNT_LOGIN_HISTORY_SUCCESS';
export const ACCOUNT_LOGIN_HISTORY_ERROR = 'account/ACCOUNT_LOGIN_HISTORY_ERROR';
export const ACCOUNT_EXPIRED_HISTORY_REQUEST = 'account/ACCOUNT_EXPIRED_HISTORY_REQUEST';
export const ACCOUNT_EXPIRED_HISTORY_SUCCESS = 'account/ACCOUNT_EXPIRED_HISTORY_SUCCESS';
export const ACCOUNT_EXPIRED_HISTORY_ERROR = 'account/ACCOUNT_EXPIRED_HISTORY_ERROR';
export const ACCOUNT_END_SPECIFIC_REQUEST = 'account/ACCOUNT_END_SPECIFIC_REQUEST';
export const ACCOUNT_END_SPECIFIC_SUCCESS = 'account/ACCOUNT_END_SPECIFIC_SUCCESS';
export const ACCOUNT_END_SPECIFIC_ERROR = 'account/ACCOUNT_END_SPECIFIC_ERROR';
export const ACCOUNT_END_SESSIONS_REQUEST = 'account/ACCOUNT_END_SESSIONS_REQUEST';
export const ACCOUNT_END_SESSIONS_SUCCESS = 'account/ACCOUNT_END_SESSIONS_SUCCESS';
export const ACCOUNT_END_SESSIONS_ERROR = 'account/ACCOUNT_END_SESSIONS_ERROR';

export const ACCOUNT_CLOSE_REQUEST = 'account/ACCOUNT_CLOSE_REQUEST';
export const ACCOUNT_CLOSE_SUCCESS = 'account/ACCOUNT_CLOSE_SUCCESS';
export const ACCOUNT_CLOSE_ERROR = 'account/ACCOUNT_CLOSE_ERROR';
