import { useNavigate } from '@tanstack/react-router';
import type NXTable from 'components/NXTable';
import { useCallback } from 'react';
import type { NXUtils } from 'utilities/NXUtils';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type HandleSort = NonNullable<NXUtils.PropsFrom<typeof NXTable>['onSort']>;
type UseSearchSort = (props: { disabled: boolean }) => { handleSort: HandleSort };

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export const _useTableSearchSort: UseSearchSort = ({ disabled }) => {
    /***** HOOKS *****/
    const navigate = useNavigate();

    /***** FUNCTIONS *****/
    const handleSort = useCallback<HandleSort>(
        ({ sortKey, sortMethod }) => {
            if (disabled) {
                return;
            }

            if (sortMethod === false) {
                return void navigate({
                    to: '.',
                    search: (prev) => ({
                        ...prev,
                        sort: undefined,
                        sort_by: undefined
                    })
                });
            }

            if (typeof sortKey === 'boolean') {
                return;
            }

            return void navigate({
                to: '.',
                search: (prev) => ({
                    ...prev,

                    // depending on the route, this value is going to be a set of literal values and should have the appropriate
                    // catch in place if this string is not valid for the route.
                    sort: sortMethod as any,

                    // depending on the route, this value is going to be a set of literal values and should have the appropriate
                    // catch in place if this string is not valid for the route.
                    sort_by: sortKey as any
                })
            });
        },
        [navigate, disabled]
    );

    /***** HOOK RESULTS *****/
    return { handleSort };
};
