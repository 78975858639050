import { pictureInPictureStates } from 'components/PictureInPicture/consts/pictureInPictureStates';
import { SUPER_USER_ACTION_ITEMS } from 'components/StaffMenu/SuperUser/actionKeys';
import type { SuperUserNamespace } from 'components/StaffMenu/SuperUser/types';
import type { PromoBannerDismissKeys } from 'containers/dashboard/Components/consts';
import { promoBannerDismissKeyValues } from 'containers/dashboard/Components/consts';
import _ from 'lodash';
import { z } from 'zod';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
const ZodUnixTimestamp = z.number().int().min(0);
export const historicMigrationsSchema = z.record(z.string(), z.number());

// prettier-ignore
/**
 * These keys are used to track what local storage keys should exist, any that are not in this record will get cleaned up
 *
 * Please note that no every data structure is currently supported in a type-safe manner.
 *
 * Only primitives and records have typescript support.
 */
export const userPreferencesDictionary = {
    IS_STAFF_MENU_OPEN: z.boolean(),
    REMIND_LATER_2FA_CURRENT_DATE: ZodUnixTimestamp,
    IS_FEEDBACK_BANNER_DISMISSED: z.boolean(),
    PROMO_BANNER_DISMISSAL_RECORD: z.record(
        z.enum(promoBannerDismissKeyValues as [`${PromoBannerDismissKeys}`]),
        z.boolean()
    ),
    IS_2FA_PROMPT_DISMISSED: z.boolean(),
    PICTURE_IN_PICTURE_STATES: z.record(
        z.string(),
        z.enum(pictureInPictureStates)
    ),
    ONLINE_GIFT_NOTIFICATION: z.record(
        z.coerce.number(),
        z.boolean()
    ),
    CART_TOKEN: z.string(),
    CART_VIP_SITES_DOMAIN_NAME: z.string(),
    VIP_SITES_EXPRESS_ADD_DOMAIN_DESKTOP_PROMPT: z.boolean(),

    // Dev/Staff related LocalStorage keys
    SUPER_USER_ACTION_VIEWED: z.array(z.enum(_.keys(SUPER_USER_ACTION_ITEMS) as [`${SuperUserNamespace.Key}`])).catch((ctx)=>{
        // Filter out any keys that don't exist
        if (Array.isArray(ctx.input)) return ctx.input.filter((item) => _.keys(SUPER_USER_ACTION_ITEMS).includes(item));
        return [];
    }),
    SUPER_USER_LOCKED_ITEMS: z.record(z.enum(_.keys(SUPER_USER_ACTION_ITEMS) as [`${SuperUserNamespace.Key}`]), z.boolean()).catch((ctx)=>{
        // Filter out any keys that don't exist
        if (typeof ctx.input === 'object' && ctx.input !== null) {
            return _.pickBy(ctx.input, (value, key) => _.keys(SUPER_USER_ACTION_ITEMS).includes(key));
        }
        return {};
    }),
    SUPER_USER_SHOW_ALL_ACTIONS: z.boolean(),

    // These keys are used for unit tests
    _TEST: z.boolean(),
    _TEST_RECORD: z.record(z.string(), z.boolean()),
} as const;

export const userPreferencesDictionaryKeys = Object.keys(userPreferencesDictionary);
