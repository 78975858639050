import type { LinkComponent, LinkComponentProps } from '@tanstack/react-router';
import { Link } from '@tanstack/react-router';
import classNames from 'classnames';
import { BaseButton } from 'components/Buttons/_BaseButton';
import type { BaseButtonNamespace } from 'components/Buttons/_BaseButton/types';
import { Padding } from 'components/Utils/Padding';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type LinkProps = LinkComponentProps<'a'>;

type IntrinsicProps = Omit<LinkProps, 'children' | 'preload'>;

type Props = Omit<BaseButtonNamespace.ClassProps, 'variant'> & {
    intrinsicProps?: Omit<IntrinsicProps, 'onClick'>;
    onClick?: IntrinsicProps['onClick'];
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _SolidButtonLink: LinkComponent<React.FC<Props>> = ({
    className,
    color,
    isLoading,
    disabled,
    size,
    width,
    shape,
    intrinsicProps,
    ...props
}) => {
    /***** RENDER HELPERS *****/
    const classes = classNames(BaseButton.createClasses({ variant: 'SolidButton', color, isLoading, disabled, size, width, shape }), className);

    /***** RENDER *****/
    return (
        <Padding x={4} inject>
            {/* @ts-ignore */}
            <Link {...props} {...intrinsicProps} className={classes} as="a" />
        </Padding>
    );
};
