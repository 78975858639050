/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useEffect, useLayoutEffect, useRef, useState } from 'react';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export function useResetDateTimeValue(ref, value, parentRefCurrent, clearClasses = []) {
    /***** STATE *****/
    const [dateTimeValue, setDateTimeValue] = useState(value);

    /***** HOOKS *****/
    const resetValueRef = useRef('');

    /***** FUNCTIONS *****/
    /** Reset the value for the date time picker */
    function resetValue() {
        const resetRef = new Date();
        resetValueRef.current = resetRef;
        setDateTimeValue(resetRef);
    }

    /***** EFFECTS *****/
    useLayoutEffect(() => {
        if (dateTimeValue === resetValueRef.current) {
            setDateTimeValue(null);
        }
    }, [dateTimeValue]);

    useEffect(() => {
        /**
         * Handles checking of clear button click on this form item.
         */
        function clickHandler(e) {
            const classList = Array.from(e.target.classList);
            if (classList.includes('react-datetime-picker__clear-button')) {
                resetValue();
            }
        }

        if (ref && ref.current) {
            ref.current.addEventListener('click', clickHandler, false);
            return function cleanup() {
                ref?.current?.removeEventListener('click', clickHandler, false);
            };
        }
    }, []);

    useEffect(() => {
        /**
         * Check if the the target clicked includes any of the possible clear classes.
         * this can be a partial string of the class that needs to be checked to perform the clear. as long as it's inside the form, for example if there's a button in the form with the class 'clearAllFilters' and in clearClasses there's 'clearAll' it will work
         */
        function clickHandlerForm(e) {
            const classList = Array.from(e.target.classList);
            if (clearClasses.some((clearButtonClass) => classList.some((className) => className.includes(clearButtonClass)))) {
                resetValue();
            }
        }

        if (!parentRefCurrent) return;

        parentRefCurrent.addEventListener('click', clickHandlerForm, false);
        return function cleanup() {
            parentRefCurrent?.removeEventListener('click', clickHandlerForm, false);
        };
    }, [parentRefCurrent]);

    useEffect(() => {
        setDateTimeValue(value);
    }, [value]);

    return dateTimeValue;
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
