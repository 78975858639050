/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useSelector } from 'react-redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { SolidButton } from 'components/Buttons/SolidButton';
import { FreeDomainTile } from '../../CompanyBanner/FreeDomainTile';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getTldValue } from 'components/Promo/FreeDomain/ClaimForm/methods';
import { createDomainAvailabilityQueryKey } from 'containers/domain/action';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import Cafe from 'assets/images/promo/dotOnlineBanner/cafe.jpeg';
import Dots from 'assets/images/promo/dotOnlineBanner/dots.svg';
import OnlineText from 'assets/images/promo/dotOnlineBanner/onlineText.svg';
import Stuff from 'assets/images/promo/dotOnlineBanner/stuff.svg';
import vipRewardsLogo from 'assets/images/vipRewards/bannerLogo.png';
import Text from 'components/Utils/Text';
import { promoBannerDismissKeys } from 'containers/dashboard/Components/consts';
import { trackingParams } from 'router/google';
import { dotStoreOnlineAndXYZAvailabilityRequestParams } from '../DotStoreBanner';
import './_DotOnlineBanner.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const _DotOnlineBanner = () => {
    /***** HOOKS *****/
    const domain_availability_data = useSelector((state) => state.domain.domain_availability_data);

    const domainAvailabilityQueryKey = createDomainAvailabilityQueryKey(dotStoreOnlineAndXYZAvailabilityRequestParams);

    const domainAvailabilityData = domain_availability_data[domainAvailabilityQueryKey]?.data;

    /***** RENDER HELPERS *****/
    const tldValue = getTldValue('.online', domainAvailabilityData);

    /***** RENDER *****/
    return (
        <section className="OnlineBanner">
            <div className="OnlineBanner__background">
                <div className="OnlineBanner__shapes">
                    <img className="OnlineBanner__dots" src={Dots} alt="Background" />
                    <img className="OnlineBanner__stuff" src={Stuff} alt="Background" />
                </div>
                <img className="OnlineBanner__cafe" src={Cafe} alt="People in the background" />
            </div>
            <div className="OnlineBanner__container">
                <div className="OnlineBanner__text">
                    <div className="OnlineBanner__headerContainer">
                        <img src={vipRewardsLogo} alt="vip rewards logo" />
                        <img src={OnlineText} alt=".online" />
                    </div>
                    <p className="OnlineBanner__desc">
                        Claim your FREE .online domain name {tldValue ? `valued at $${tldValue} ` : ''}
                        today!*
                    </p>
                    <Text className="OnlineBanner__tandc">*Terms and Conditions apply.</Text>
                </div>
                <FreeDomainTile
                    text="Build a stunning online brand on a .online domain name"
                    button={<SolidButton to={`/vip-rewards?free-domain=online&${trackingParams.dashboardBanners.dotOnline}`}>Claim Now</SolidButton>}
                />
            </div>
        </section>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export const DotOnlineBanner = Object.assign(_DotOnlineBanner, {
    DISMISS_KEY: promoBannerDismissKeys.DOT_ONLINE_RADIX_DISMISS_KEY
});
