import type { KatanaNamespace } from 'containers/katana/types';
import { createContext, useContext } from 'react';
import type { ZodTypeAny } from 'zod';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type SectionDefinitionContextType = {
    sectionDefinitionID: KatanaNamespace.SectionDefinitionId;
    sectionDefinition: KatanaNamespace.SectionDefinition.SectionData;
    zodSchema: ZodTypeAny;
};

export const SectionDefinitionContext = createContext<SectionDefinitionContextType | null>(null);

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export function useSectionDefinitionContext() {
    const context = useContext(SectionDefinitionContext);
    if (!context) {
        throw new Error('useSectionDefinitionContext must be used within a SectionDefinitionContextProvider');
    }
    return context;
}
