import { useNavigate, useSearch } from '@tanstack/react-router';
import NXTable from 'components/NXTable';
import { Border } from 'components/Utils/Border';
import { WrapWithComponent } from 'components/WrapWithComponent';
import { useExpressServiceManagerContext } from 'containers/dashboard/Components/expressServiceManager/context';
import { ShowAll } from 'containers/services/modules/helpers/showAll';
import { ShowMore } from 'containers/services/modules/helpers/showMore';
import { HostingTableBody } from 'containers/services/modules/hosting/components/body';
import { OwnActions } from 'containers/services/modules/hosting/components/body/actions';
import { OwnRenew } from 'containers/services/modules/hosting/components/body/renew';
import { OwnStatus } from 'containers/services/modules/hosting/components/body/status';
import { OwnTitle } from 'containers/services/modules/hosting/components/body/title';
import { HostingTableTitleBar } from 'containers/services/modules/hosting/components/titlebar';
import { HostingContext } from 'containers/services/modules/hosting/context';
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';
import { NXQuery } from 'utilities/query';
import './_HostingTable.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const HostingTable = () => {
    /***** HOOKS *****/
    const isExpressServiceManager = useExpressServiceManagerContext();
    const isMobile = useAppViewport(['xs', 'sm']);
    const isTablet = useAppViewport(['md']);
    const isLarge = useAppViewport(['lg', 'xl']);
    const navigate = useNavigate();
    const { search, sort } = useSearch({ strict: false });
    const { handleSort } = NXTable.useSearchSort({ disabled: !!search });

    /***** QUERIES *****/
    const { data: searchData, isLoading: isSearchLoading } = NXQuery.hosting.search.useSearchQuery();
    const { data: listData, isLoading: isListLoading } = NXQuery.hosting.list.useSearchQuery(({ data, meta }) => ({
        data,
        total: meta.total
    }));

    /***** FUNCTIONS *****/
    const handleShowAll = () => {
        navigate({
            to: '.',
            replace: true,
            search: (search) => ({
                ...search,
                record_per_page: listData?.total
            })
        });
    };

    /***** RENDER HELPERS *****/
    const isLoading = isSearchLoading || isListLoading;
    const data = searchData?.data || listData?.data || [];
    const total = searchData?.data.length || listData?.total;
    const slicedData = isExpressServiceManager ? data?.slice(0, 5) : data;
    const domainColumnWidth = isLarge ? '330px' : '260px';
    const renewColumnWidth = isTablet ? '' : '1fr';
    const columns = `${domainColumnWidth} 1fr ${renewColumnWidth} NXActions`;

    /***** RENDER *****/
    return (
        <div className="HostingTable">
            {!isExpressServiceManager && <HostingTableTitleBar />}
            <NXTable columns={columns} onSort={handleSort} activePromotion="cyberMondayVipRewards2024">
                <WrapWithComponent component={Border} wrap={!isExpressServiceManager} all colour="C_NXBox_color_border">
                    {!isMobile && (
                        <NXTable.Header>
                            <NXTable.Header.SortableTitle sortKey="name" initialMethod={sort} disabled={!!search}>
                                PRIMARY DOMAIN / PLAN
                            </NXTable.Header.SortableTitle>
                            <NXTable.Header.Title>STATUS</NXTable.Header.Title>
                            {!isTablet && <NXTable.Header.Title>Renews</NXTable.Header.Title>}
                            <NXTable.Header.Title />
                        </NXTable.Header>
                    )}
                    <HostingTableBody>
                        <WrapWithComponent component={NXTable.Accordion.ToggleWrapper} wrap={isMobile}>
                            {slicedData?.map((datum) => (
                                <HostingContext.Provider value={datum} key={datum.id}>
                                    {isMobile ? (
                                        <NXTable.Accordion index={datum.id} content={OwnActions}>
                                            <OwnTitle />
                                            <OwnStatus />
                                            <OwnRenew />
                                        </NXTable.Accordion>
                                    ) : (
                                        <NXTable.Row>
                                            <OwnTitle />
                                            <OwnStatus />
                                            {!isTablet && <OwnRenew />}
                                            <OwnActions />
                                        </NXTable.Row>
                                    )}
                                </HostingContext.Provider>
                            ))}
                        </WrapWithComponent>
                    </HostingTableBody>

                    <ShowAll isLoading={isLoading} numberOfRows={slicedData?.length} totalRows={total} onClick={handleShowAll} />
                    <ShowMore isLoading={isLoading} link="/my-services/hosting" totalRows={total}>
                        show all hosting
                    </ShowMore>
                </WrapWithComponent>
            </NXTable>
        </div>
    );
};
