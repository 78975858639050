import NXBox from 'components/NXBox';
import { Flex } from 'components/Utils/Flex';
import { Padding } from 'components/Utils/Padding';
import Text from 'components/Utils/Text';
import { useOrderingFormContext } from 'containers/orderingForm/contexts/useOrderingFormContext';
import 'containers/orderingForm/pages/registerPage/registrationAvailabilityConfirmation/registerSuccessConfirmation/_registerSuccessConfirmation.scss';
import { RegistrationButton } from 'containers/orderingForm/pages/registerPage/registrationButton';
import { Pricing } from 'containers/orderingForm/pricing';
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';
import { truncateText } from 'utilities/methods/commonActions';
import { NXQuery } from 'utilities/query';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const RegisterSuccessConfirmation = () => {
    /***** HOOKS *****/
    const { domainData } = useOrderingFormContext();
    const isMobile = useAppViewport(['xs', 'sm']);

    const truncateLength = isMobile ? 30 : 40;

    /***** QUERIES *****/
    const { data: domain_availibility_data } = NXQuery.domain.availability.useQuery(domainData);

    /***** RENDER *****/
    return (
        <NXBox className="registerSuccessConfirmation__container">
            <Padding xy={1}>
                <Text align--center className="registerSuccessConfirmation__containerText">
                    Great news! This domain is available
                </Text>
                {isMobile ? (
                    <Flex direction="column" gap={0} className="registerSuccessConfirmation__information">
                        <Text size--custom={15} semiBold>
                            {truncateText(domain_availibility_data?.[0]?.domain.toLowerCase(), truncateLength, '...')}
                            {domain_availibility_data?.[0]?.tld}
                        </Text>
                        <Flex justify="between">
                            <Pricing
                                premium={domain_availibility_data?.[0]?.premium || false}
                                price={domain_availibility_data?.[0]?.price[0]?.price ?? ''}
                                oldPrice={domain_availibility_data?.[0]?.price[0]?.old_price}
                            />
                            <RegistrationButton domainAvalibility={domain_availibility_data} />
                        </Flex>
                    </Flex>
                ) : (
                    <Flex align="center" className="registerSuccessConfirmation__information">
                        <Flex direction="column" gap={0} fullWidth>
                            <Text size--xm bold>
                                {truncateText(domain_availibility_data?.[0]?.domain.toLowerCase(), truncateLength, '...')}
                                {domain_availibility_data?.[0]?.tld}
                            </Text>
                            <Pricing
                                premium={domain_availibility_data?.[0]?.premium || false}
                                price={domain_availibility_data?.[0]?.price[0]?.price ?? ''}
                                oldPrice={domain_availibility_data?.[0]?.price[0]?.old_price}
                            />
                        </Flex>
                        <RegistrationButton domainAvalibility={domain_availibility_data} />
                    </Flex>
                )}
            </Padding>
        </NXBox>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
