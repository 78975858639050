import { accountQueryKeys } from "containers/account/queries/queryKeys";
import { API } from "utilities/api/account";
import { createNXQuery } from "utilities/methods/tanstack/createQuery";

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
export const checkSubaccountExists = createNXQuery((token: string) => ({
    queryKey: accountQueryKeys.checkSubaccountExists(token),
    queryFn: () => API.account.GET.subaccount.exists(token)
}));
