import { _TextArea as TextArea } from 'components/Form/TextArea/_TextArea';
import type { NXTextAreaNamespace } from 'components/Form/TextArea/types';
import { useDebounceCallback } from 'usehooks-ts';
import { useStateEffect } from 'utilities/hooks/useStateEffect';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type _TextAreaDebounce = React.FC<NXTextAreaNamespace.Debounce.Props>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _TextAreaDebounce: _TextAreaDebounce = ({ delay = 500, intrinsicProps, value, ...props }) => {
    const { onChange, ...restIntrinsicProps } = intrinsicProps;

    /***** HOOKS *****/
    const [debouncedValue, setValue] = useStateEffect(value);
    const handleDebouncedChange = useDebounceCallback(onChange, delay, {});

    /***** FUNCTIONS *****/
    const handleOnChange: React.ChangeEventHandler<HTMLTextAreaElement> = (e) => {
        setValue(e.target.value);
        handleDebouncedChange(e);
    };

    /***** RENDER *****/
    return <TextArea {...{ ...props, value: debouncedValue, intrinsicProps: { ...restIntrinsicProps, onChange: handleOnChange } }} />;
};
