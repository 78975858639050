import { authenticationMutationKeys } from 'containers/login/queries/queryKeys';
import { API } from 'utilities/api/login';
import { createNXMutation } from 'utilities/methods/tanstack/createMutation';

/**********************************************************************************************************
 *   MUTATIONS
 **********************************************************************************************************/
export const selectAccount = createNXMutation({
    mutationKey: authenticationMutationKeys.selectAccount(),
    mutationFn: API.user.account.set
});
