import { useRegistrationOrderingFormContext } from 'App/components/templates/contexts/registrationOrderingFormContext/useRegistrationOrderingFormContext';
import Text from 'components/Utils/Text';
import { useOrderingFormContext } from 'containers/orderingForm/contexts/useOrderingFormContext';
import { PageHeader } from 'containers/orderingForm/pageHeader';
import { ConfigureDomainsList } from 'containers/orderingForm/pages/configurationPage/configureDomainsList';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type ConfigurationPage = React.FC<ConfigurationPageProps>;
type ConfigurationPageProps = {
    name: string;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const ConfigurationPage: ConfigurationPage = ({ name }) => {
    /***** HOOKS *****/
    const { currentPage, goToPage } = useOrderingFormContext();
    const { isRegistration } = useRegistrationOrderingFormContext();

    /***** RENDER *****/
    if (currentPage !== name) return;
    return (
        <>
            <PageHeader title="Domain configuration" backButton backOnClick={() => goToPage(isRegistration ? 'register' : 'transfer')} />
            <Text secondary>Please review and configure your domain names below.</Text>
            <ConfigureDomainsList />
        </>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
