import Anchor from 'components/Anchor';
import { useBrandStore } from 'config/hooks/useBrandStore';
import { useHostingContext } from 'containers/services/modules/hosting/context';
import type { AnyRouteId } from 'router/types';
import type { NXUtils } from 'utilities/NXUtils';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const AssistanceLink = () => {
    /***** HOOKS *****/
    const { activeBrand } = useBrandStore();
    const serviceData = useHostingContext();

    /***** RENDER HELPERS *****/
    const getSupportLink = (): NXUtils.AllowAnyString<AnyRouteId> => {
        switch (activeBrand) {
            case 'ventra':
                return '/support/tickets/submit';
            case 'intaserve':
            default:
                return '/contact-support';
        }
    };

    /***** RENDER *****/
    return (
        <div className="contact__link" key="contact_link">
            <div className="contact__note">{serviceData?.attributes.note} </div>
            <Anchor to={getSupportLink()}>Contact us for assistance</Anchor>
        </div>
    );
};
