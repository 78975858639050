import RequestLoader from 'components/Loaders/Request';
import { Padding } from 'components/Utils/Padding';
import Text from 'components/Utils/Text';
import { useOrderingFormContext } from 'containers/orderingForm/contexts/useOrderingFormContext';
import 'containers/orderingForm/pages/registerPage/registrationAvailabilityConfirmation/_registrationAvailabilityConfirmation.scss';
import { TransferErrorConfirmation } from 'containers/orderingForm/pages/transferPage/transferAvailabilityConfirmation/transferErrorConfirmation';
import type { DomainData } from 'containers/orderingForm/types';
import { NXQuery } from 'utilities/query';
import { TransferSuccessConfirmation } from './transferSuccessConfirmation';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const TransferAvailabilityConfirmation = () => {
    /***** HOOKS *****/
    const { domainData } = useOrderingFormContext();

    const transferDomainData = {
        ...domainData,
        action: 'transfer'
    } as DomainData;

    /***** QUERIES *****/
    const {
        data: domain_transfer_availability_data,
        status: domainAvailabilityDataStatus,
        isError: isDomainTransferAvailabilityError,
        error: domain_transfer_availability_error
    } = NXQuery.domain.availability.useQuery(transferDomainData);

    /***** RENDER HELPERS *****/
    const renderAvailabilityConfirmation = () => {
        if (domainAvailabilityDataStatus === 'pending' && domainData.domain !== '') return <RequestLoader />;
        if (domainAvailabilityDataStatus === 'error')
            return (
                <Text align--center color="S_color_text_warn_base">
                    {domain_transfer_availability_error?.response?.data?.errors[0]?.details}
                </Text>
            );
        if (isDomainTransferAvailabilityError || domain_transfer_availability_data?.[0]?.available === false)
            return <TransferErrorConfirmation transferDomainData={transferDomainData} />;
        if (domain_transfer_availability_data?.[0]?.available === true)
            return <TransferSuccessConfirmation transferDomainData={transferDomainData} />;
        return null;
    };

    /***** RENDER *****/
    return (
        <Padding y={5} className="registrationAvailabilityConfirmation__placeholder">
            {renderAvailabilityConfirmation()}
        </Padding>
    );
};
