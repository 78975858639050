/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useSelector } from 'react-redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { SolidButton } from 'components/Buttons/SolidButton';
import { FreeDomainTile } from '../../CompanyBanner/FreeDomainTile';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { createDomainAvailabilityQueryKey } from 'containers/domain/action';
import { getTldValue } from '../ClaimForm/methods';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import DottedCurve from 'assets/images/promo/dotStoreBanner/dottedCurve.jpg';
import DottedCurveInverted from 'assets/images/promo/dotStoreBanner/dottedCurveUpsideDown.jpg';
import Store from 'assets/images/promo/dotStoreBanner/store.jpg';
import StoreText from 'assets/images/promo/dotStoreBanner/storeText.svg';
import vipRewardsLogo from 'assets/images/vipRewards/bannerLogo.png';
import Text from 'components/Utils/Text';
import { promoBannerDismissKeys } from 'containers/dashboard/Components/consts';
import { trackingParams } from 'router/google';
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';
import './_DotStoreBanner.scss';

export const dotStoreOnlineAndXYZAvailabilityRequestParams = {
    domain: 'wqeriuwqeruweqrosfuiyewquriqweroewiruwqeoriuweorqwerwqe',
    tlds: ['.store', '.online', '.xyz', '.site'],
    promos: [],
    action: 'renew'
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const _DotStoreBanner = () => {
    /***** HOOKS *****/
    const domain_availability_data = useSelector((state) => state.domain.domain_availability_data);

    const domainAvailabilityQueryKey = createDomainAvailabilityQueryKey(dotStoreOnlineAndXYZAvailabilityRequestParams);

    const domainAvailabilityData = domain_availability_data[domainAvailabilityQueryKey]?.data;

    const isMobile = useAppViewport(['sm', 'xs']);

    /***** RENDER HELPERS *****/
    const tldValue = getTldValue('.store', domainAvailabilityData);

    /***** RENDER *****/
    return (
        <section className="StoreBanner">
            <img className="StoreBanner__dottedCurve" src={DottedCurve} alt="Decoration" />
            <div className="StoreBanner__bgRightSide" />
            {isMobile && <img className="StoreBanner__dottedCurveInverted" src={DottedCurveInverted} alt="Decoration" />}
            <div className="StoreBanner__container">
                <div className="StoreBanner__text">
                    <div className="StoreBanner__headerContainer">
                        <img src={vipRewardsLogo} alt="vip rewards logo" />
                        <img src={StoreText} alt=".store" />
                    </div>
                    <p className="StoreBanner__desc">
                        Claim your FREE .store domain name {tldValue ? `valued at $${tldValue} ` : ''}
                        today!*
                    </p>
                    <Text className="StoreBanner__tandc">*Terms and Conditions apply.</Text>
                </div>
                <div className="StoreBanner__storeContainer">
                    <img className="StoreBanner__dottedCurveInverted" src={DottedCurveInverted} alt="Decoration" />
                    <img className="StoreBanner__store" src={Store} alt="A store" />
                </div>
                <div className="StoreBanner__tileContainer">
                    <FreeDomainTile
                        text=".store tells the world you are #OpenForBusiness"
                        button={
                            <SolidButton to={`/vip-rewards?free-domain=store&${trackingParams.dashboardBanners.dotStore}`}>Claim Now</SolidButton>
                        }
                    />
                </div>
            </div>
        </section>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export const DotStoreBanner = Object.assign(_DotStoreBanner, {
    DISMISS_KEY: promoBannerDismissKeys.DOT_STORE_RADIX_DISMISS_KEY
});
