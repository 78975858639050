/**********************************************************************************************************
 *   DECLARATIONS
 **********************************************************************************************************/
export const VPS_INITIAL_STATE = 'vps/VPS_INITIAL_STATE';

export const VPS_INFORMATION_RESET = 'vps/VPS_INFORMATION_RESET';
export const VPS_INFORMATION_REQUEST = 'vps/VPS_INFORMATION_REQUEST';
export const VPS_INFORMATION_SUCCESS = 'vps/VPS_INFORMATION_SUCCESS';
export const VPS_INFORMATION_FAIL = 'vps/VPS_INFORMATION_FAIL';

export const VPS_SOLUS_INFORMATION_REQUEST = 'vps/VPS_SOLUS_INFORMATION_REQUEST';
export const VPS_SOLUS_INFORMATION_SUCCESS = 'vps/VPS_SOLUS_INFORMATION_SUCCESS';
export const VPS_SOLUS_INFORMATION_FAIL = 'vps/VPS_SOLUS_INFORMATION_FAIL';

export const VPS_ASTRO_INFORMATION_REQUEST = 'vps/VPS_ASTRO_INFORMATION_REQUEST';
export const VPS_ASTRO_INFORMATION_SUCCESS = 'vps/VPS_ASTRO_INFORMATION_SUCCESS';
export const VPS_ASTRO_INFORMATION_FAIL = 'vps/VPS_ASTRO_INFORMATION_FAIL';

export const VPS_USER_INFORMATION_REQUEST = 'vps/VPS_USER_INFORMATION_REQUEST';
export const VPS_USER_INFORMATION_SUCCESS = 'vps/VPS_USER_INFORMATION_SUCCESS';
export const VPS_USER_INFORMATION_FAIL = 'vps/VPS_USER_INFORMATION_FAIL';

export const VPS_WHM_LOGIN_REQUEST = 'vps/VPS_WHM_LOGIN_REQUEST';
export const VPS_WHM_LOGIN_SUCCESS = 'vps/VPS_WHM_LOGIN_SUCCESS';
export const VPS_WHM_LOGIN_FAIL = 'vps/VPS_WHM_LOGIN_FAIL';

export const VPS_ADDON_REQUEST = 'vps/VPS_ADDON_REQUEST';
export const VPS_ADDON_SUCCESS = 'vps/VPS_ADDON_SUCCESS';
export const VPS_ADDON_FAIL = 'vps/VPS_ADDON_FAIL';

export const VPS_SOFTWARE_ADDON_PRICING_REQUEST = 'vps/VPS_SOFTWARE_ADDON_PRICING_REQUEST';
export const VPS_SOFTWARE_ADDON_PRICING_SUCCESS = 'vps/VPS_SOFTWARE_ADDON_PRICING_SUCCESS';
export const VPS_SOFTWARE_ADDON_PRICING_FAIL = 'vps/VPS_SOFTWARE_ADDON_PRICING_FAIL';

export const VPS_HARDWARE_ADDON_PRICING_REQUEST = 'vps/VPS_HARDWARE_ADDON_PRICING_REQUEST';
export const VPS_HARDWARE_ADDON_PRICING_SUCCESS = 'vps/VPS_HARDWARE_ADDON_PRICING_SUCCESS';
export const VPS_HARDWARE_ADDON_PRICING_FAIL = 'vps/VPS_HARDWARE_ADDON_PRICING_FAIL';

export const VPS_ADD_HARDWARE_ADDON_REQUEST = 'vps/VPS_ADD_HARDWARE_ADDON_REQUEST';
export const VPS_ADD_HARDWARE_ADDON_SUCCESS = 'vps/VPS_ADD_HARDWARE_ADDON_SUCCESS';
export const VPS_ADD_HARDWARE_ADDON_FAIL = 'vps/VPS_ADD_HARDWARE_ADDON_FAIL';

export const VPS_ADD_SOFTWARE_ADDON_REQUEST = 'vps/VPS_ADD_SOFTWARE_ADDON_REQUEST';
export const VPS_ADD_SOFTWARE_ADDON_SUCCESS = 'vps/VPS_ADD_SOFTWARE_ADDON_SUCCESS';
export const VPS_ADD_SOFTWARE_ADDON_FAIL = 'vps/VPS_ADD_SOFTWARE_ADDON_FAIL';

export const VPS_ADDON_CANCEL_REQUEST = 'vps/VPS_ADDON_CANCEL_REQUEST';
export const VPS_ADDON_CANCEL_SUCCESS = 'vps/VPS_ADDON_CANCEL_SUCCESS';
export const VPS_ADDON_CANCEL_FAIL = 'vps/VPS_ADDON_CANCEL_FAIL';

export const VPS_LEGACY_ADDON_REQUEST = 'vps/VPS_LEGACY_ADDON_REQUEST';
export const VPS_LEGACY_ADDON_SUCCESS = 'vps/VPS_LEGACY_ADDON_SUCCESS';
export const VPS_LEGACY_ADDON_FAIL = 'vps/VPS_LEGACY_ADDON_FAIL';

export const VPS_SUBMIT_JOB_REQUEST = 'vps/VPS_SUBMIT_JOB_REQUEST';
export const VPS_SUBMIT_JOB_SUCCESS = 'vps/VPS_SUBMIT_JOB_SUCCESS';
export const VPS_SUBMIT_JOB_FAIL = 'VPS/VPS_SUBMIT_JOB_FAIL';

export const VPS_POWER_STATE_REQUEST = 'vps/VPS_POWER_STATE_REQUEST';
export const VPS_POWER_STATE_SUCCESS = 'vps/VPS_POWER_STATE_SUCCESS';
export const VPS_POWER_STATE_ERROR = 'vps/VPS_POWER_STATE_ERROR';

export const VPS_REBOOT_REQUEST = 'vps/VPS_REBOOT_REQUEST';
export const VPS_REBOOT_SUCCESS = 'vps/VPS_REBOOT_SUCCESS';
export const VPS_REBOOT_FAIL = 'vps/VPS_REBOOT_FAIL';

export const VPS_STARTUP_REQUEST = 'vps/VPS_STARTUP_REQUEST';
export const VPS_STARTUP_SUCCESS = 'vps/VPS_STARTUP_SUCCESS';
export const VPS_STARTUP_ERROR = 'vps/VPS_STARTUP_ERROR';

export const VPS_SHUTDOWN_REQUEST = 'vps/VPS_SHUTDOWN_REQUEST';
export const VPS_SHUTDOWN_SUCCESS = 'vps/VPS_SHUTDOWN_SUCCESS';
export const VPS_SHUTDOWN_FAIL = 'vps/VPS_SHUTDOWN_FAIL';

export const VPS_VNC_INFORMATION_REQUEST = 'vps/VPS_VNC_INFORMATION_REQUEST';
export const VPS_VNC_INFORMATION_SUCCESS = 'vps/VPS_VNC_INFORMATION_SUCCESS';
export const VPS_VNC_INFORMATION_FAIL = 'vps/VPS_VNC_INFORMATION_FAIL';

export const VPS_CREATE_ASTRO_VNC_REQUEST = 'vps/VPS_CREATE_ASTRO_VNC_REQUEST';
export const VPS_CREATE_ASTRO_VNC_SUCCESS = 'vps/VPS_CREATE_ASTRO_VNC_SUCCESS';
export const VPS_CREATE_ASTRO_VNC_ERROR = 'vps/VPS_CREATE_ASTRO_VNC_ERROR';

export const VPS_CHANGE_VNC_PASSWORD_REQUEST = 'vps/VPS_CHANGE_VNC_PASSWORD_REQUEST';
export const VPS_CHANGE_VNC_PASSWORD_SUCCESS = 'vps/VPS_CHANGE_VNC_PASSWORD_SUCCESS';
export const VPS_CHANGE_VNC_PASSWORD_FAIL = 'vps/VPS_CHANGE_VNC_PASSWORD_FAIL';

export const VPS_REVERSE_DNS_REQUEST = 'vps/VPS_REVERSE_DNS_REQUEST';
export const VPS_REVERSE_DNS_SUCCESS = 'vps/VPS_REVERSE_DNS_SUCCESS';
export const VPS_REVERSE_DNS_FAIL = 'vps/VPS_REVERSE_DNS_FAIL';

export const VPS_BILLING_CYCLE_REQUEST = 'vps/VPS_BILLING_CYCLE_REQUEST';
export const VPS_BILLING_CYCLE_SUCCESS = 'vps/VPS_BILLING_CYCLE_SUCCESS';
export const VPS_BILLING_CYCLE_FAIL = 'vps/VPS_BILLING_CYCLE_FAIL';

export const VPS_BILLING_CYCLE_UPDATE_REQUEST = 'vps/VPS_BILLING_CYCLE_UPDATE_REQUEST';
export const VPS_BILLING_CYCLE_UPDATE_SUCCESS = 'vps/VPS_BILLING_CYCLE_UPDATE_SUCCESS';
export const VPS_BILLING_CYCLE_UPDATE_FAIL = 'vps/VPS_BILLING_CYCLE_UPDATE_FAIL';

export const VPS_RENEW_INFO_REQUEST = 'vps/VPS_RENEW_INFO_REQUEST';
export const VPS_RENEW_INFO_SUCCESS = 'vps/VPS_RENEW_INFO_SUCCESS';
export const VPS_RENEW_INFO_ERROR = 'vps/VPS_RENEW_INFO_ERROR';

export const VPS_RENEW_DETAILS_REQUEST = 'vps/VPS_RENEW_DETAILS_REQUEST';
export const VPS_RENEW_DETAILS_SUCCESS = 'vps/VPS_RENEW_DETAILS_SUCCESS';
export const VPS_RENEW_DETAILS_ERROR = 'vps/VPS_RENEW_DETAILS_ERROR';

export const VPS_RENEW_REQUEST = 'vps/VPS_RENEW_REQUEST';
export const VPS_RENEW_SUCCESS = 'vps/VPS_RENEW_SUCCESS';
export const VPS_RENEW_ERROR = 'vps/VPS_RENEW_ERROR';

export const VPS_OS_LIST_REQUEST = 'vps/VPS_OS_LIST_REQUEST';
export const VPS_OS_LIST_SUCCESS = 'vps/VPS_OS_LIST_SUCCESS';
export const VPS_OS_LIST_FAIL = 'vps/VPS_OS_LIST_FAIL';

export const VPS_FORMAT_REQUEST = 'vps/VPS_FORMAT_REQUEST';
export const VPS_FORMAT_SUCCESS = 'vps/VPS_FORMAT_SUCCESS';
export const VPS_FORMAT_FAIL = 'vps/VPS_FORMAT_FAIL';

export const VPS_UPGRADE_LIST_REQUEST = 'vps/VPS_UPGRADE_LIST_REQUEST';
export const VPS_UPGRADE_LIST_SUCCESS = 'vps/VPS_UPGRADE_LIST_SUCCESS';
export const VPS_UPGRADE_LIST_FAIL = 'vps/VPS_UPGRADE_LIST_FAIL';

export const VPS_UPGRADE_REQUEST = 'vps/VPS_UPGRADE_REQUEST';
export const VPS_UPGRADE_SUCCESS = 'vps/VPS_UPGRADE_SUCCESS';
export const VPS_UPGRADE_FAIL = 'vps/VPS_UPGRADE_FAIL';

export const VPS_LEGACY_UPGRADE_LIST_REQUEST = 'vps/VPS_LEGACY_UPGRADE_LIST_REQUEST';
export const VPS_SELF_UPGRADE_LIST_SUCCESS = 'vps/VPS_SELF_UPGRADE_LIST_SUCCESS';
export const VPS_SELF_UPGRADE_LIST_FAIL = 'vps/VPS_SELF_UPGRADE_LIST_ERROR';
export const VPS_FULLY_UPGRADE_LIST_SUCCESS = 'vps/VPS_FULLY_UPGRADE_LIST_SUCCESS';
export const VPS_FULLY_UPGRADE_LIST_FAIL = 'vps/VPS_FULLY_UPGRADE_LIST_ERROR';

export const VPS_REMOVE_CANCELLATION_REQUEST = 'vps/VPS_REMOVE_CANCELLATION_REQUEST';
export const VPS_REMOVE_CANCELLATION_SUCCESS = 'vps/VPS_REMOVE_CANCELLATION_SUCCESS';
export const VPS_REMOVE_CANCELLATION_ERROR = 'vps/VPS_REMOVE_CANCELLATION_ERROR';
