import { useStore } from '@tanstack/react-store';
import { SolidButton } from 'components/Buttons/SolidButton';
import { Flex } from 'components/Utils/Flex';
import Text from 'components/Utils/Text';
import { useOrderingFormContext } from 'containers/orderingForm/contexts/useOrderingFormContext';
import { ExclamationMark } from 'containers/orderingForm/exclamationMarkIcon';
import { OrderingFormStore } from 'containers/orderingForm/store';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const PremiumFormFields = () => {
    /***** HOOKS *****/
    const { currentItem } = useOrderingFormContext();
    const isPremiumAcknowledged = useStore(OrderingFormStore, (state) => !!state.isPremiumAcknowledgedMap?.[currentItem.uuid]);

    /***** RENDER *****/
    return (
        <Flex direction="column" gap={4} className="editItemConfig__eligibilityForm">
            <Text size--xm bold>
                Premium Acknowledgment
            </Text>
            <Flex direction="column" className="editItemConfig__eligibilityFormInfoNotice">
                <Flex fullWidth justify="between">
                    <Flex align="center">
                        <ExclamationMark size={15} />
                        <Text size--xm bold>
                            Acknowledgment Required
                        </Text>
                    </Flex>
                </Flex>
                <Text size--s>
                    This domain is classified as a Premium Domain, which means the Registry has deemed it to be of high value. Premium Domains can
                    often come at a higher cost than standard registrations due to multiple factors, including, but not limited to, its brandability,
                    short length, and whether it is a dictionary word.
                    <br />
                    <br />
                    The classification and renewal cost of these domains may change at the Registry's discretion.
                    <br />
                    <br />
                    Acknowledgment is required to proceed with this registration.
                </Text>
                <SolidButton
                    className="editItemConfig__premiumAcknowledgeButton"
                    disabled={isPremiumAcknowledged}
                    onClick={() =>
                        OrderingFormStore.setState((state) => ({
                            ...state,
                            isPremiumAcknowledgedMap: {
                                ...state.isPremiumAcknowledgedMap,
                                [currentItem.uuid]: !isPremiumAcknowledged,
                            },
                        }))
                    }
                >
                    <Text semiBold size--s>
                        {isPremiumAcknowledged ? 'Acknowledged' : 'I Acknowledge'}
                    </Text>
                </SolidButton>
            </Flex>
        </Flex>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
