import { loadAppConfig } from 'App/actions/loadAppConfig';
import { APP_MOUNTING } from 'App/actionTypes';
import { notificationScopes } from 'components/Toast/consts';
import { pushNotification } from 'components/Toast/functions';
import { LOGIN_INITIAL_STATE } from 'containers/login/actionTypes';
import { noop } from 'lodash';
import { queryClient } from 'store/queryClient';
import store from 'store/store';
import { API, cancelGetContact } from 'utilities/api/account';
import { getDataFromSuccessResponse, getErrorFromFailResponse, getMetaFromSuccessResponse } from 'utilities/methods/commonActions';
import { typedSet } from 'utilities/methods/objectMethods';
import { NXQuery } from 'utilities/query';

import {
    ACCOUNT_ADDITIONAL_USER_LIST_ERROR,
    ACCOUNT_ADDITIONAL_USER_LIST_REQUEST,
    ACCOUNT_ADDITIONAL_USER_LIST_SUCCESS,
    ACCOUNT_ADD_ADDITIONAL_USER_ERROR,
    ACCOUNT_ADD_ADDITIONAL_USER_REQUEST,
    ACCOUNT_ADD_ADDITIONAL_USER_SUCCESS,
    ACCOUNT_ADD_CONTACT_ERROR,
    ACCOUNT_ADD_CONTACT_REQUEST,
    ACCOUNT_ADD_CONTACT_SUCCESS,
    ACCOUNT_APPROVE_ADDITIONAL_USER_SIGNUP_ERROR,
    ACCOUNT_APPROVE_ADDITIONAL_USER_SIGNUP_REQUEST,
    ACCOUNT_APPROVE_ADDITIONAL_USER_SIGNUP_SUCCESS,
    ACCOUNT_CANCEL_INVITATION_ERROR,
    ACCOUNT_CANCEL_INVITATION_REQUEST,
    ACCOUNT_CANCEL_INVITATION_SUCCESS,
    ACCOUNT_CLOSE_ERROR,
    ACCOUNT_CLOSE_REQUEST,
    ACCOUNT_CLOSE_SUCCESS,
    ACCOUNT_CONTACT_LIST_ERROR,
    ACCOUNT_CONTACT_LIST_REQUEST,
    ACCOUNT_CONTACT_LIST_SUCCESS,
    ACCOUNT_EMAILS_ERROR,
    ACCOUNT_EMAILS_REQUEST,
    ACCOUNT_EMAILS_RESEND_ERROR,
    ACCOUNT_EMAILS_RESEND_REQUEST,
    ACCOUNT_EMAILS_RESEND_SUCCESS,
    ACCOUNT_EMAILS_SEARCH_ERROR,
    ACCOUNT_EMAILS_SEARCH_REQUEST,
    ACCOUNT_EMAILS_SEARCH_RESET,
    ACCOUNT_EMAILS_SEARCH_SUCCESS,
    ACCOUNT_EMAILS_SUCCESS,
    ACCOUNT_EMAILS_VIEW_ERROR,
    ACCOUNT_EMAILS_VIEW_REQUEST,
    ACCOUNT_EMAILS_VIEW_SUCCESS,
    ACCOUNT_END_SESSIONS_ERROR,
    ACCOUNT_END_SESSIONS_REQUEST,
    ACCOUNT_END_SESSIONS_SUCCESS,
    ACCOUNT_END_SPECIFIC_ERROR,
    ACCOUNT_END_SPECIFIC_REQUEST,
    ACCOUNT_END_SPECIFIC_SUCCESS,
    ACCOUNT_EXPIRED_HISTORY_ERROR,
    ACCOUNT_EXPIRED_HISTORY_REQUEST,
    ACCOUNT_EXPIRED_HISTORY_SUCCESS,
    ACCOUNT_LAST_UPDATE_PASSWORD_ERROR,
    ACCOUNT_LAST_UPDATE_PASSWORD_REQUEST,
    ACCOUNT_LAST_UPDATE_PASSWORD_SUCCESS,
    ACCOUNT_LOGIN_HISTORY_ERROR,
    ACCOUNT_LOGIN_HISTORY_REQUEST,
    ACCOUNT_LOGIN_HISTORY_SUCCESS,
    ACCOUNT_PERMISSION_ERROR,
    ACCOUNT_PERMISSION_REQUEST,
    ACCOUNT_PERMISSION_SUCCESS,
    ACCOUNT_PROVIDE_MISSING_DETAILS_ERROR,
    ACCOUNT_PROVIDE_MISSING_DETAILS_REQUEST,
    ACCOUNT_PROVIDE_MISSING_DETAILS_SUCCESS,
    ACCOUNT_QR_CODE_ERROR,
    ACCOUNT_QR_CODE_REQUEST,
    ACCOUNT_QR_CODE_SUCCESS,
    ACCOUNT_REMOVE_ADDITIONAL_USER_ERROR,
    ACCOUNT_REMOVE_ADDITIONAL_USER_REQUEST,
    ACCOUNT_REMOVE_ADDITIONAL_USER_SUCCESS,
    ACCOUNT_REMOVE_CONTACT_ERROR,
    ACCOUNT_REMOVE_CONTACT_REQUEST,
    ACCOUNT_REMOVE_CONTACT_SUCCESS,
    ACCOUNT_RESEND_INVITATION_ERROR,
    ACCOUNT_RESEND_INVITATION_REQUEST,
    ACCOUNT_RESEND_INVITATION_SUCCESS,
    ACCOUNT_REVOKE_ACCESS_ERROR,
    ACCOUNT_REVOKE_ACCESS_REQUEST,
    ACCOUNT_REVOKE_ACCESS_SUCCESS,
    ACCOUNT_SECURITY_INFORMATION_ERROR,
    ACCOUNT_SECURITY_INFORMATION_REQUEST,
    ACCOUNT_SECURITY_INFORMATION_SUCCESS,
    ACCOUNT_SECURITY_QUESTIONS_ERROR,
    ACCOUNT_SECURITY_QUESTIONS_REQUEST,
    ACCOUNT_SECURITY_QUESTIONS_SUCCESS,
    ACCOUNT_SECURITY_UPDATE_ERROR,
    ACCOUNT_SECURITY_UPDATE_REQUEST,
    ACCOUNT_SECURITY_UPDATE_SUCCESS,
    ACCOUNT_TWOFACTOR_CONFIG_ERROR,
    ACCOUNT_TWOFACTOR_CONFIG_REQUEST,
    ACCOUNT_TWOFACTOR_CONFIG_SUCCESS,
    ACCOUNT_TWOFACTOR_DELETE_ERROR,
    ACCOUNT_TWOFACTOR_DELETE_REQUEST,
    ACCOUNT_TWOFACTOR_DELETE_SUCCESS,
    ACCOUNT_TWOFACTOR_ENABLE_ERROR,
    ACCOUNT_TWOFACTOR_ENABLE_REQUEST,
    ACCOUNT_TWOFACTOR_ENABLE_SUCCESS,
    ACCOUNT_TWOFACTOR_ERROR,
    ACCOUNT_TWOFACTOR_PRIMARY_ERROR,
    ACCOUNT_TWOFACTOR_PRIMARY_REQUEST,
    ACCOUNT_TWOFACTOR_PRIMARY_SUCCESS,
    ACCOUNT_TWOFACTOR_REQUEST,
    ACCOUNT_TWOFACTOR_SUCCESS,
    ACCOUNT_TWOFACTOR_VERIFY_ERROR,
    ACCOUNT_TWOFACTOR_VERIFY_REQUEST,
    ACCOUNT_TWOFACTOR_VERIFY_SUCCESS,
    ACCOUNT_UPDATE_ADDITIONAL_USER_PERMISSION_ERROR,
    ACCOUNT_UPDATE_ADDITIONAL_USER_PERMISSION_REQUEST,
    ACCOUNT_UPDATE_ADDITIONAL_USER_PERMISSION_SUCCESS,
    ACCOUNT_UPDATE_CLIENT_TYPE_ERROR,
    ACCOUNT_UPDATE_CLIENT_TYPE_REQUEST,
    ACCOUNT_UPDATE_CONTACT_ERROR,
    ACCOUNT_UPDATE_CONTACT_REQUEST,
    ACCOUNT_UPDATE_CONTACT_SUCCESS,
    ACCOUNT_UPDATE_EMAIL_ERROR,
    ACCOUNT_UPDATE_EMAIL_REQUEST,
    ACCOUNT_UPDATE_EMAIL_SUCCESS,
    ACCOUNT_UPDATE_PASSWORD_ERROR,
    ACCOUNT_UPDATE_PASSWORD_REQUEST,
    ACCOUNT_UPDATE_PASSWORD_SUCCESS,
    ACCOUNT_UPDATE_USER_ERROR,
    ACCOUNT_UPDATE_USER_REQUEST,
    ACCOUNT_UPDATE_USER_SUCCESS
} from './actionTypes';

/**********************************************************************************************************
 *   ACTIONS - GENERAL
 **********************************************************************************************************/
export const updateEmailAddress = (values) => {
    const { dispatch } = store;

    dispatch({
        type: ACCOUNT_UPDATE_EMAIL_REQUEST
    });
    API.account.PUT.email(values)
        .then((response) => {
            const account_update_email_data = getDataFromSuccessResponse(response);
            pushNotification(account_update_email_data);

            const completeEmailUpdate = () => {
                dispatch({
                    type: ACCOUNT_UPDATE_EMAIL_SUCCESS,
                    account_update_email_data
                });

                dispatch({
                    type: APP_MOUNTING,
                    app_mounting: false
                });
            };

            // App mounting because we want to show the transition
            dispatch({
                type: APP_MOUNTING,
                app_mounting: true
            });

            // after details are updated in account section, also update the profile dropdown
            NXQuery.auth.accountList.forceFetch().catch(noop);
            NXQuery.auth.userData.forceFetch().then(() => {
                completeEmailUpdate();
            });
        })
        .catch((error) => {
            const account_update_email_error = getErrorFromFailResponse(error);
            pushNotification(account_update_email_error);
            dispatch({
                type: ACCOUNT_UPDATE_EMAIL_ERROR,
                account_update_email_error
            });
        });
};

export const updateClientType = (attributes) => {
    const { dispatch } = store;
    dispatch({
        type: ACCOUNT_UPDATE_CLIENT_TYPE_REQUEST
    });

    API.account.PUT.clientType(attributes)
        .then((response) => {
            const account_update_client_type_data = getDataFromSuccessResponse(response);
            pushNotification(account_update_client_type_data);

            // App mounting because we want to show the transition
            dispatch({
                type: APP_MOUNTING,
                app_mounting: true
            });

            NXQuery.auth.userData.invalidate(void 0, { type: 'all' }).finally(() => {
                dispatch({
                    type: APP_MOUNTING,
                    app_mounting: false
                });
            });
        })
        .catch((error) => {
            const account_update_client_type_error = getErrorFromFailResponse(error);
            pushNotification(account_update_client_type_error);
            dispatch({
                type: ACCOUNT_UPDATE_CLIENT_TYPE_ERROR,
                account_update_client_type_error
            });
        });
};

export const getContactsList = () => {
    return (dispatch) => {
        if (cancelGetContact) {
            cancelGetContact();
        }
        dispatch({
            type: ACCOUNT_CONTACT_LIST_REQUEST
        });
        API.account.GET.contact()
            .then((response) => {
                const account_contact_list_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: ACCOUNT_CONTACT_LIST_SUCCESS,
                    account_contact_list_data
                });
            })
            .catch((error) => {
                const account_contact_list_error = getErrorFromFailResponse(error);
                dispatch({
                    type: ACCOUNT_CONTACT_LIST_ERROR,
                    account_contact_list_error
                });
            });
    };
};

export const updateContact = (id, values) => {
    return (dispatch) => {
        dispatch({
            type: ACCOUNT_UPDATE_CONTACT_REQUEST
        });
        API.account.PUT.contact
            .update(id, values)
            .then((response) => {
                const account_update_contact_data = getDataFromSuccessResponse(response);
                pushNotification(account_update_contact_data);
                dispatch({
                    type: ACCOUNT_UPDATE_CONTACT_SUCCESS,
                    account_update_contact_data
                });
            })
            .catch((error) => {
                const account_update_contact_error = getErrorFromFailResponse(error);
                pushNotification(account_update_contact_error);
                dispatch({
                    type: ACCOUNT_UPDATE_CONTACT_ERROR,
                    account_update_contact_error
                });
            });
    };
};

export const addContact = (values) => {
    return (dispatch) => {
        dispatch({
            type: ACCOUNT_ADD_CONTACT_REQUEST
        });
        API.account.POST.contact
            .add(values)
            .then((response) => {
                const account_add_contact_data = getDataFromSuccessResponse(response);
                pushNotification(account_add_contact_data);
                dispatch({
                    type: ACCOUNT_ADD_CONTACT_SUCCESS,
                    account_add_contact_data
                });
            })
            .catch((error) => {
                const account_add_contact_error = getErrorFromFailResponse(error);
                pushNotification(account_add_contact_error);
                dispatch({
                    type: ACCOUNT_ADD_CONTACT_ERROR,
                    account_add_contact_error
                });
            });
    };
};

export const removeContact = (id) => {
    return (dispatch) => {
        dispatch({
            type: ACCOUNT_REMOVE_CONTACT_REQUEST
        });
        API.account.POST.contact
            .delete(id)
            .then((response) => {
                const account_remove_contact_data = getDataFromSuccessResponse(response);
                pushNotification(account_remove_contact_data);
                dispatch({
                    type: ACCOUNT_REMOVE_CONTACT_SUCCESS,
                    account_remove_contact_data
                });
            })
            .catch((error) => {
                const account_remove_contact_error = getErrorFromFailResponse(error);
                pushNotification(account_remove_contact_error);
                dispatch({
                    type: ACCOUNT_REMOVE_CONTACT_ERROR,
                    account_remove_contact_error
                });
            });
    };
};

/**
 *
 * @param {object} values
 */
export const updateUserDetails = (values) => {
    const { dispatch } = store;

    const attributes = {
        ...values
    };

    dispatch({
        type: ACCOUNT_UPDATE_USER_REQUEST
    });
    API.account.PUT.update
        .user(attributes)
        .then(async (response) => {
            const account_update_user_data = getDataFromSuccessResponse(response);
            pushNotification(account_update_user_data);

            NXQuery.auth.userData.invalidate(void 0, { type: 'all' }).then(() => {
                dispatch({
                    type: ACCOUNT_UPDATE_USER_SUCCESS,
                    account_update_user_data
                });
            });

            NXQuery.auth.login.checkToken
                .refetch()
                .then(() => {
                    dispatch({
                        type: APP_MOUNTING,
                        app_mounting: false
                    });
                })
                .catch(noop);

            // after details are updated in account section, also update the profile dropdown
            NXQuery.auth.accountList.invalidate();
        })
        .catch((error) => {
            const response = getErrorFromFailResponse(error);
            pushNotification(response);
            dispatch({
                type: ACCOUNT_UPDATE_USER_ERROR
            });
            dispatch({
                type: APP_MOUNTING,
                app_mounting: false
            });
        });
};

/**
 * Used to update the user information and security information from the post login checklist
 */
export const provideMissingDetails = (updateUserDetailsAttributes, updateSecurityDetailsAttributes, options) => {
    const { dispatch } = store;

    dispatch({
        type: ACCOUNT_PROVIDE_MISSING_DETAILS_REQUEST
    });

    // prettier-ignore
    Promise.all([
        API.account.PUT.update.user(updateUserDetailsAttributes), 
        API.account.PUT.security.question(updateSecurityDetailsAttributes)
    ])
        .then(() => {
            const currentStoreState = store.getState();

            // Manually update the user data with the payload values
            const userDataQueryKey = NXQuery.auth.userData.createQueryKey();
            const previousUserData = queryClient.getQueryData(userDataQueryKey);

            NXQuery.auth.userData.optimistic.setWith(void 0, 'data.attributes', (data) => ({
                ...data,
                ...updateUserDetailsAttributes
            }));

            // Manually update the security info with the payload values
            const currentSecurityInfo = currentStoreState.account.account_security_information_data;
            const securityQuestionsList = currentStoreState.account.account_security_questions_data;
            const newSecurityInfo = {
                ...currentSecurityInfo,
                ...updateSecurityDetailsAttributes,
                question: securityQuestionsList.find(({ id }) => id === updateSecurityDetailsAttributes.question_id)?.attributes?.question || ''
            };

            dispatch({
                type: ACCOUNT_SECURITY_UPDATE_SUCCESS,
                account_security_update_data: newSecurityInfo
            });

            // Manually update the correct account in the account list data with the payload values
            NXQuery.auth.accountList.invalidate();
            NXQuery.auth.accountList.optimistic.setWith(void 0, 'data', (data) => {
                return data.map((account) => {
                    if (account.id !== previousUserData?.data.id) return account;

                    return typedSet(account, 'attributes', {
                        ...account.attributes,
                        company: updateUserDetailsAttributes.company,
                        firstname: updateUserDetailsAttributes.firstname,
                        lastname: updateUserDetailsAttributes.lastname
                    });
                });
            });

            // Normal notification and dispatch for this action
            pushNotification({ status: 200, details: 'Account details successfully updated.' });
            dispatch({
                type: ACCOUNT_PROVIDE_MISSING_DETAILS_SUCCESS
            });

            options?.onSuccess?.();
        })
        .catch((error) => {
            const errorNotification = getErrorFromFailResponse(error);
            pushNotification(errorNotification);
            dispatch({
                type: ACCOUNT_PROVIDE_MISSING_DETAILS_ERROR
            });
        });
};

export const getPermissionList = () => {
    return (dispatch) => {
        dispatch({
            type: ACCOUNT_PERMISSION_REQUEST
        });
        API.account.GET.permission()
            .then((response) => {
                const account_permission_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: ACCOUNT_PERMISSION_SUCCESS,
                    account_permission_data
                });
            })
            .catch((error) => {
                const account_permission_error = getErrorFromFailResponse(error);
                dispatch({
                    type: ACCOUNT_PERMISSION_ERROR,
                    account_permission_error
                });
            });
    };
};

export const getAdditionalUsers = () => {
    return (dispatch) => {
        dispatch({
            type: ACCOUNT_ADDITIONAL_USER_LIST_REQUEST
        });
        API.account.GET.subaccount
            .list()
            .then((response) => {
                const account_additional_user_list_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: ACCOUNT_ADDITIONAL_USER_LIST_SUCCESS,
                    account_additional_user_list_data
                });
            })
            .catch((error) => {
                const account_additional_user_list_error = getErrorFromFailResponse(error);
                dispatch({
                    type: ACCOUNT_ADDITIONAL_USER_LIST_ERROR,
                    account_additional_user_list_error
                });
            });
    };
};

export const addAdditionalUser = (email, group) => {
    return (dispatch) => {
        dispatch({
            type: ACCOUNT_ADD_ADDITIONAL_USER_REQUEST
        });
        API.account.POST.subaccount
            .add(email, group)
            .then((response) => {
                const account_add_additional_user_data = getDataFromSuccessResponse(response);
                pushNotification(account_add_additional_user_data);
                dispatch({
                    type: ACCOUNT_ADD_ADDITIONAL_USER_SUCCESS,
                    account_add_additional_user_data
                });
                dispatch(getAdditionalUsers());
            })
            .catch((error) => {
                const account_add_additional_user_error = getErrorFromFailResponse(error);
                pushNotification(account_add_additional_user_error);
                dispatch({
                    type: ACCOUNT_ADD_ADDITIONAL_USER_ERROR,
                    account_add_additional_user_error
                });
            });
    };
};

export const removeAdditionalUser = (id) => {
    return (dispatch) => {
        dispatch({
            type: ACCOUNT_REMOVE_ADDITIONAL_USER_REQUEST
        });
        API.account.DELETE.subaccount
            .delete(id)
            .then((response) => {
                const account_remove_additional_user_data = getDataFromSuccessResponse(response);
                pushNotification(account_remove_additional_user_data);
                dispatch({
                    type: ACCOUNT_REMOVE_ADDITIONAL_USER_SUCCESS,
                    account_remove_additional_user_data
                });
                dispatch(getAdditionalUsers());
            })
            .catch((error) => {
                const account_remove_additional_user_error = getErrorFromFailResponse(error);
                pushNotification(account_remove_additional_user_error);
                dispatch({
                    type: ACCOUNT_REMOVE_ADDITIONAL_USER_ERROR,
                    account_remove_additional_user_error
                });
            });
    };
};

export const resendInvitation = (email) => {
    return (dispatch) => {
        dispatch({
            type: ACCOUNT_RESEND_INVITATION_REQUEST
        });
        API.account.POST.subaccount
            .resendInvitation(email)
            .then((response) => {
                const account_resend_invitation_data = getDataFromSuccessResponse(response);
                pushNotification(account_resend_invitation_data);
                dispatch({
                    type: ACCOUNT_RESEND_INVITATION_SUCCESS,
                    account_resend_invitation_data
                });
            })
            .catch((error) => {
                const account_resend_invitation_error = getErrorFromFailResponse(error);
                pushNotification(account_resend_invitation_error);
                dispatch({
                    type: ACCOUNT_RESEND_INVITATION_ERROR,
                    account_resend_invitation_error
                });
            });
    };
};

export const cancelInvitation = (email) => {
    return (dispatch) => {
        dispatch({
            type: ACCOUNT_CANCEL_INVITATION_REQUEST
        });
        API.account.DELETE.subaccount
            .cancelInvitation(email)
            .then((response) => {
                const account_cancel_invitation_data = getDataFromSuccessResponse(response);
                pushNotification(account_cancel_invitation_data);
                dispatch({
                    type: ACCOUNT_CANCEL_INVITATION_SUCCESS,
                    account_cancel_invitation_data
                });
                dispatch(getAdditionalUsers());
            })
            .catch((error) => {
                const account_cancel_invitation_error = getErrorFromFailResponse(error);
                pushNotification(account_cancel_invitation_error);
                dispatch({
                    type: ACCOUNT_CANCEL_INVITATION_ERROR,
                    account_cancel_invitation_error
                });
            });
    };
};

export const revokeAccountAccess = (id) => {
    return (dispatch) => {
        dispatch({
            type: ACCOUNT_REVOKE_ACCESS_REQUEST
        });
        API.account.DELETE.accountAccess(id)
            .then(async (response) => {
                const account_revoke_access_data = getDataFromSuccessResponse(response);

                try {
                    await NXQuery.auth.accountList.invalidate();
                    await NXQuery.auth.accountList.fetch();
                    dispatch({
                        type: ACCOUNT_REVOKE_ACCESS_SUCCESS,
                        account_revoke_access_data
                    });
                } finally {
                    pushNotification(account_revoke_access_data);
                }
            })
            .catch((error) => {
                const account_revoke_access_error = getErrorFromFailResponse(error);
                pushNotification(account_revoke_access_error);
                dispatch({
                    type: ACCOUNT_REVOKE_ACCESS_ERROR,
                    account_revoke_access_error
                });
            });
    };
};

export const updateSubaccountPermission = (id, permission) => {
    return (dispatch) => {
        dispatch({
            type: ACCOUNT_UPDATE_ADDITIONAL_USER_PERMISSION_REQUEST
        });
        API.account.PUT.subaccount
            .update(id, permission)
            .then((response) => {
                const account_update_additional_user_permission_data = getDataFromSuccessResponse(response);
                pushNotification(account_update_additional_user_permission_data);
                dispatch({
                    type: ACCOUNT_UPDATE_ADDITIONAL_USER_PERMISSION_SUCCESS,
                    account_update_additional_user_permission_data
                });
            })
            .catch((error) => {
                const account_update_additional_user_permission_error = getErrorFromFailResponse(error);
                pushNotification(account_update_additional_user_permission_error);
                dispatch({
                    type: ACCOUNT_UPDATE_ADDITIONAL_USER_PERMISSION_ERROR,
                    account_update_additional_user_permission_error
                });
            });
    };
};

export const approveSignup = (router, user, token) => {
    return (dispatch) => {
        dispatch({
            type: ACCOUNT_APPROVE_ADDITIONAL_USER_SIGNUP_REQUEST
        });
        API.account.POST.subaccount
            .signup(user, token)
            .then((response) => {
                const account_approve_additional_user_signup_data = getDataFromSuccessResponse(response);
                pushNotification(
                    {
                        ...account_approve_additional_user_signup_data,
                        details: 'Sign up successful and invitation to manage account accepted.'
                    },
                    null,
                    notificationScopes.GUEST
                );
                dispatch({
                    type: ACCOUNT_APPROVE_ADDITIONAL_USER_SIGNUP_SUCCESS,
                    account_approve_additional_user_signup_data
                });
                loadAppConfig({ context: 'react', dependencies: { navigate: router.navigate } });
            })
            .catch((error) => {
                const account_approve_additional_user_signup_error = getErrorFromFailResponse(error);
                pushNotification(account_approve_additional_user_signup_error, null, notificationScopes.GUEST);
                dispatch({
                    type: ACCOUNT_APPROVE_ADDITIONAL_USER_SIGNUP_ERROR,
                    account_approve_additional_user_signup_error
                });
            });
    };
};

export const closeAccount = () => {
    return (dispatch) => {
        dispatch({
            type: ACCOUNT_CLOSE_REQUEST
        });
        API.account.POST.close()
            .then((response) => {
                const account_close_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: ACCOUNT_CLOSE_SUCCESS,
                    account_close_data
                });
                dispatch({
                    type: LOGIN_INITIAL_STATE
                });
                queryClient.clear();
                pushNotification(account_close_data);
            })
            .catch((error) => {
                const account_close_error = getErrorFromFailResponse(error);
                pushNotification(account_close_error);
                dispatch({
                    type: ACCOUNT_CLOSE_ERROR,
                    account_close_error
                });
            });
    };
};

/**********************************************************************************************************
 *   ACTIONS - EMAIL
 **********************************************************************************************************/
export const getAccountEmails = (page, prevPage) => {
    return (dispatch) => {
        dispatch({
            type: ACCOUNT_EMAILS_REQUEST
        });
        if (page) {
            API.account.GET.email
                .list(page)
                .then((response) => {
                    const account_emails_data = getDataFromSuccessResponse(response);
                    const account_emails_meta = getMetaFromSuccessResponse(response);
                    dispatch({
                        type: ACCOUNT_EMAILS_SUCCESS,
                        account_emails_data: prevPage ? [...prevPage, ...account_emails_data] : account_emails_data,
                        account_emails_meta
                    });
                })
                .catch((error) => {
                    const account_emails_error = getErrorFromFailResponse(error);
                    dispatch({
                        type: ACCOUNT_EMAILS_ERROR,
                        account_emails_error
                    });
                });
        } else {
            API.account.GET.email
                .list()
                .then((response) => {
                    const account_emails_data = getDataFromSuccessResponse(response);
                    const account_emails_meta = getMetaFromSuccessResponse(response);
                    dispatch({
                        type: ACCOUNT_EMAILS_SUCCESS,
                        account_emails_data,
                        account_emails_meta
                    });
                })
                .catch((error) => {
                    const account_emails_error = getErrorFromFailResponse(error);
                    dispatch({
                        type: ACCOUNT_EMAILS_ERROR,
                        account_emails_error
                    });
                });
        }
    };
};

export const searchAccountEmails = (keyword) => {
    return (dispatch) => {
        dispatch({
            type: ACCOUNT_EMAILS_SEARCH_REQUEST
        });
        API.account.GET.email
            .search(keyword)
            .then((response) => {
                const account_emails_search_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: ACCOUNT_EMAILS_SEARCH_SUCCESS,
                    account_emails_search_data
                });
            })
            .catch((error) => {
                const account_emails_search_error = getErrorFromFailResponse(error);
                if (account_emails_search_error?.details !== 'Response Cancelled') {
                    dispatch({
                        type: ACCOUNT_EMAILS_SEARCH_ERROR,
                        account_emails_search_error
                    });
                }
            });
    };
};

export const resetSearchAccountEmails = () => {
    return (dispatch) => {
        dispatch({
            type: ACCOUNT_EMAILS_SEARCH_RESET
        });
    };
};

export const viewAccountEmail = (keyword) => {
    return (dispatch) => {
        dispatch({
            type: ACCOUNT_EMAILS_VIEW_REQUEST
        });
        API.account.GET.email
            .details(keyword)
            .then((response) => {
                const account_emails_view_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: ACCOUNT_EMAILS_VIEW_SUCCESS,
                    account_emails_view_data
                });
            })
            .catch((error) => {
                const account_emails_view_error = getErrorFromFailResponse(error);
                dispatch({
                    type: ACCOUNT_EMAILS_VIEW_ERROR,
                    account_emails_view_error
                });
            });
    };
};

export const resendAccountEmail = (id) => {
    return (dispatch) => {
        dispatch({
            type: ACCOUNT_EMAILS_RESEND_REQUEST
        });
        API.account.POST.email
            .resend(id)
            .then((response) => {
                const account_emails_resend_data = getDataFromSuccessResponse(response);
                pushNotification(account_emails_resend_data);
                dispatch({
                    type: ACCOUNT_EMAILS_RESEND_SUCCESS,
                    account_emails_resend_data
                });
            })
            .catch((error) => {
                const account_emails_resend_error = getErrorFromFailResponse(error);
                pushNotification(account_emails_resend_error);
                dispatch({
                    type: ACCOUNT_EMAILS_RESEND_ERROR,
                    account_emails_resend_error
                });
            });
    };
};

/**********************************************************************************************************
 *   ACTIONS - SECURITY
 **********************************************************************************************************/
export const updatePassword = (old_password, password, password_confirmation) => {
    return (dispatch) => {
        dispatch({
            type: ACCOUNT_UPDATE_PASSWORD_REQUEST
        });
        API.account.PUT.update
            .password(old_password, password, password_confirmation)
            .then((response) => {
                const account_update_password_data = getDataFromSuccessResponse(response);
                pushNotification(account_update_password_data);
                dispatch({
                    type: ACCOUNT_UPDATE_PASSWORD_SUCCESS,
                    account_update_password_data
                });
                dispatch(getLastPasswordUpdate());
            })
            .catch((error) => {
                const account_update_password_error = getErrorFromFailResponse(error);
                pushNotification(account_update_password_error);
                dispatch({
                    type: ACCOUNT_UPDATE_PASSWORD_ERROR,
                    account_update_password_error
                });
            });
    };
};

export const getLastPasswordUpdate = () => {
    return (dispatch) => {
        dispatch({
            type: ACCOUNT_LAST_UPDATE_PASSWORD_REQUEST
        });
        API.account.GET.password()
            .then((response) => {
                const account_last_update_password_data = getDataFromSuccessResponse(response);
                const { attributes } = account_last_update_password_data;
                const { password_updated_at } = attributes;

                dispatch({
                    type: ACCOUNT_LAST_UPDATE_PASSWORD_SUCCESS,
                    account_last_update_password_data: password_updated_at
                });
            })
            .catch((error) => {
                const account_last_update_password_error = getErrorFromFailResponse(error);
                dispatch({
                    type: ACCOUNT_LAST_UPDATE_PASSWORD_ERROR,
                    account_last_update_password_error
                });
            });
    };
};

export const getUserSecurityInformation = () => {
    return (dispatch) => {
        dispatch({
            type: ACCOUNT_SECURITY_INFORMATION_REQUEST
        });
        API.account.GET.security
            .question()
            .then((response) => {
                const processedResponse = getDataFromSuccessResponse(response);
                const { attributes, id } = processedResponse;
                const account_security_information_data = {
                    ...attributes,
                    question_id: id
                };

                dispatch({
                    type: ACCOUNT_SECURITY_INFORMATION_SUCCESS,
                    account_security_information_data
                });
            })
            .catch((error) => {
                const account_security_information_error = getErrorFromFailResponse(error);
                dispatch({
                    type: ACCOUNT_SECURITY_INFORMATION_ERROR,
                    account_security_information_error
                });
            });
    };
};

export const getSecurityQuestions = () => {
    return (dispatch) => {
        dispatch({
            type: ACCOUNT_SECURITY_QUESTIONS_REQUEST
        });
        API.account.GET.security
            .list()
            .then((response) => {
                const account_security_questions_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: ACCOUNT_SECURITY_QUESTIONS_SUCCESS,
                    account_security_questions_data
                });
            })
            .catch((error) => {
                const account_security_questions_error = getErrorFromFailResponse(error);
                dispatch({
                    type: ACCOUNT_SECURITY_QUESTIONS_ERROR,
                    account_security_questions_error
                });
            });
    };
};

export const updateSecurityInformation = (attributes) => {
    return (dispatch) => {
        dispatch({
            type: ACCOUNT_SECURITY_UPDATE_REQUEST
        });
        API.account.PUT.security
            .question(attributes)
            .then((response) => {
                const account_security_update_data = getDataFromSuccessResponse(response);
                pushNotification(account_security_update_data);
                dispatch({
                    type: ACCOUNT_SECURITY_UPDATE_SUCCESS,
                    account_security_update_data
                });
            })
            .catch((error) => {
                const account_security_update_error = getErrorFromFailResponse(error);
                pushNotification(account_security_update_error);
                dispatch({
                    type: ACCOUNT_SECURITY_UPDATE_ERROR,
                    account_security_update_error
                });
            });
    };
};

export const getTwoFactor = () => {
    return (dispatch) => {
        dispatch({
            type: ACCOUNT_TWOFACTOR_REQUEST
        });
        API.account.GET.twofactor()
            .then((response) => {
                const account_twofactor_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: ACCOUNT_TWOFACTOR_SUCCESS,
                    account_twofactor_data
                });
            })
            .catch((error) => {
                const account_twofactor_error = getErrorFromFailResponse(error);
                dispatch({
                    type: ACCOUNT_TWOFACTOR_ERROR,
                    account_twofactor_error
                });
            });
    };
};

export const getQRCode = () => {
    return (dispatch) => {
        dispatch({
            type: ACCOUNT_QR_CODE_REQUEST
        });
        API.account.GET.qrCode()
            .then((response) => {
                const account_qr_code_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: ACCOUNT_QR_CODE_SUCCESS,
                    account_qr_code_data
                });
            })
            .catch((error) => {
                const account_qr_code_error = getErrorFromFailResponse(error);
                pushNotification(account_qr_code_error);
                dispatch({
                    type: ACCOUNT_QR_CODE_ERROR
                });
            });
    };
};

export const configureTwoFactor = (id, attributes) => {
    return (dispatch) => {
        dispatch({
            type: ACCOUNT_TWOFACTOR_CONFIG_REQUEST
        });
        API.account.PUT.twofactor
            .configure(id, attributes)
            .then((response) => {
                const account_twofactor_config_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: ACCOUNT_TWOFACTOR_CONFIG_SUCCESS,
                    account_twofactor_config_data
                });
            })
            .catch((error) => {
                const account_twofactor_config_error = getErrorFromFailResponse(error);
                dispatch({
                    type: ACCOUNT_TWOFACTOR_CONFIG_ERROR,
                    account_twofactor_config_error
                });
            });
    };
};

export const verifyTwoFactor = (attributes) => {
    return (dispatch) => {
        dispatch({
            type: ACCOUNT_TWOFACTOR_VERIFY_REQUEST
        });
        API.account.POST.twofactor
            .verify(attributes)
            .then((response) => {
                const account_twofactor_verify_data = getDataFromSuccessResponse(response);
                pushNotification(account_twofactor_verify_data);
                dispatch({
                    type: ACCOUNT_TWOFACTOR_VERIFY_SUCCESS,
                    account_twofactor_verify_data
                });
            })
            .catch((error) => {
                const account_twofactor_verify_error = getErrorFromFailResponse(error);
                pushNotification(account_twofactor_verify_error);
                dispatch({
                    type: ACCOUNT_TWOFACTOR_VERIFY_ERROR,
                    account_twofactor_verify_error
                });
            });
    };
};

export const enableTwoFactor = (attributes) => {
    return (dispatch) => {
        dispatch({
            type: ACCOUNT_TWOFACTOR_ENABLE_REQUEST
        });
        API.account.PUT.twofactor
            .enable(attributes)
            .then((response) => {
                const account_twofactor_enable_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: ACCOUNT_TWOFACTOR_ENABLE_SUCCESS,
                    account_twofactor_enable_data
                });
            })
            .catch((error) => {
                const account_twofactor_enable_error = getErrorFromFailResponse(error);
                pushNotification(account_twofactor_enable_error);
                dispatch({
                    type: ACCOUNT_TWOFACTOR_ENABLE_ERROR,
                    account_twofactor_enable_error
                });
            });
    };
};

export const disableTwoFactor = (id) => {
    return (dispatch) => {
        dispatch({
            type: ACCOUNT_TWOFACTOR_DELETE_REQUEST
        });
        API.account.DELETE.twofactor(id)
            .then((response) => {
                const account_delete_twofactor_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: ACCOUNT_TWOFACTOR_DELETE_SUCCESS,
                    account_delete_twofactor_data
                });
                dispatch(getTwoFactor());
            })
            .catch((error) => {
                const account_delete_twofactor_error = getErrorFromFailResponse(error);
                dispatch({
                    type: ACCOUNT_TWOFACTOR_DELETE_ERROR,
                    account_delete_twofactor_error
                });
            });
    };
};

export const setPrimaryTwoFactor = (id) => {
    return (dispatch) => {
        dispatch({
            type: ACCOUNT_TWOFACTOR_PRIMARY_REQUEST
        });
        API.account.PUT.twofactor
            .set(id)
            .then((response) => {
                const account_primary_twofactor_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: ACCOUNT_TWOFACTOR_PRIMARY_SUCCESS,
                    account_primary_twofactor_data
                });
                dispatch(getTwoFactor());
            })
            .catch((error) => {
                const account_primary_twofactor_error = getErrorFromFailResponse(error);
                dispatch({
                    type: ACCOUNT_TWOFACTOR_PRIMARY_ERROR,
                    account_primary_twofactor_error
                });
            });
    };
};

export const getActiveLoginHistory = () => {
    return (dispatch) => {
        dispatch({
            type: ACCOUNT_LOGIN_HISTORY_REQUEST
        });
        API.account.GET.history
            .active()
            .then((response) => {
                const account_active_history_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: ACCOUNT_LOGIN_HISTORY_SUCCESS,
                    account_active_history_data
                });
            })
            .catch((error) => {
                const account_active_history_error = getErrorFromFailResponse(error);
                dispatch({
                    type: ACCOUNT_LOGIN_HISTORY_ERROR,
                    account_active_history_error
                });
            });
    };
};

export const getExpiredLoginHistory = () => {
    return (dispatch) => {
        dispatch({
            type: ACCOUNT_EXPIRED_HISTORY_REQUEST
        });
        API.account.GET.history
            .expired()
            .then((response) => {
                const account_expired_history_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: ACCOUNT_EXPIRED_HISTORY_SUCCESS,
                    account_expired_history_data
                });
            })
            .catch((error) => {
                const account_expired_history_error = getErrorFromFailResponse(error);
                dispatch({
                    type: ACCOUNT_EXPIRED_HISTORY_ERROR,
                    account_expired_history_error
                });
            });
    };
};

export const endSpecificSession = (id) => {
    return (dispatch) => {
        dispatch({
            type: ACCOUNT_END_SPECIFIC_REQUEST
        });
        API.account.DELETE.history
            .session(id)
            .then((response) => {
                const account_end_specific_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: ACCOUNT_END_SPECIFIC_SUCCESS,
                    account_end_specific_data
                });
            })
            .catch((error) => {
                const account_end_specific_error = getErrorFromFailResponse(error);
                dispatch({
                    type: ACCOUNT_END_SPECIFIC_ERROR,
                    account_end_specific_error
                });
            });
    };
};

export const endAllSessions = () => {
    return (dispatch) => {
        dispatch({
            type: ACCOUNT_END_SESSIONS_REQUEST
        });
        API.account.DELETE.history
            .all()
            .then((response) => {
                const account_end_sessions_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: ACCOUNT_END_SESSIONS_SUCCESS,
                    account_end_sessions_data
                });
            })
            .catch((error) => {
                const account_end_sessions_error = getErrorFromFailResponse(error);
                dispatch({
                    type: ACCOUNT_END_SESSIONS_ERROR,
                    account_end_sessions_error
                });
            });
    };
};
