import { useStore } from '@tanstack/react-store';
import classNames from 'classnames';
import { SolidButton } from 'components/Buttons/SolidButton';
import { Flex } from 'components/Utils/Flex';
import { Padding } from 'components/Utils/Padding';
import Text from 'components/Utils/Text';
import { useOrderingFormContext } from 'containers/orderingForm/contexts/useOrderingFormContext';
import 'containers/orderingForm/pages/configurationPage/configureDomainsList/_configureDomainsList.scss';
import { OrderingFormStore } from 'containers/orderingForm/store';
import type { Item } from 'containers/orderingForm/types';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type ConfigureDomainButton = React.FC<ConfigureDomainButtonProps>;
type ConfigureDomainButtonProps = {
    item: Item;
    eligibilityRequired: () => 'Eligibility required' | 'Eligibility met' | 'EPP required' | JSX.Element | 'EPP skipped' | undefined;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const ConfigureDomainsButton: ConfigureDomainButton = ({ item, eligibilityRequired }) => {
    /***** HOOKS *****/
    const { goToPage, setCurrentItem } = useOrderingFormContext();
    const isPremiumAcknowledged = useStore(OrderingFormStore, (state) => !!state.isPremiumAcknowledgedMap?.[item.uuid]);

    /***** FUNCTIONS *****/
    const handleOnClick = () => {
        setCurrentItem(item);
        goToPage('editItemConfiguration');
    };

    /***** RENDER HELPERS *****/
    const renderButtonText = () => {
        if (eligibilityRequired() === 'Eligibility required' || eligibilityRequired() === 'EPP required') return 'Enter Info';

        if (item?.is_premium && !isPremiumAcknowledged) return 'Acknowledge';

        return 'Edit';
    };

    /***** RENDER *****/
    return (
        <SolidButton
            className={classNames('configureDomainsList__listItemButton--edit', {
                'configureDomainsList__listItemButton--enterInfo':
                    eligibilityRequired() === 'Eligibility required' ||
                    eligibilityRequired() === 'EPP required' ||
                    (item?.is_premium && !isPremiumAcknowledged)
            })}
            onClick={handleOnClick}
        >
            <Padding x={3}>
                <Flex align="center">
                    <Text lead--0 semiBold>
                        {renderButtonText()}
                    </Text>
                </Flex>
            </Padding>
        </SolidButton>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
