import { _CheckBoxListItem as CheckBoxListItem } from 'components/Form/CheckBoxList/_CheckBoxListItem';
import { FormItemInner } from 'components/Form/FormItemInner';
import React from 'react';
import { useController } from 'react-hook-form';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type HookFormCheckboxItem = React.FC<{
    children?: React.ReactNode;
    name: string;
    inverse?: boolean;
    className?: string;
    disabled?: boolean;
    onChange?: (value: boolean) => void;
    FormItemInnerVariant?: typeof FormItemInner.HookForm | typeof FormItemInner.KatanaProperty;
}>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const CheckBoxListItemReactHookFormDefault: HookFormCheckboxItem = ({
    children,
    name,
    inverse,
    className,
    disabled,
    onChange,
    FormItemInnerVariant = FormItemInner.HookForm
}) => {
    /***** HOOKS *****/
    const { field } = useController({ name });

    /***** FUNCTIONS *****/
    const handleChange = (value: boolean) => {
        if (disabled) {
            return;
        }
        const changeValueTransform = inverse ? !value : value;

        field.onChange(changeValueTransform);
        field.onBlur();

        onChange?.(value);
    };

    /***** RENDER HELPERS *****/
    const valueTransform = inverse ? !field.value : field.value;

    /***** RENDER *****/
    return (
        <FormItemInnerVariant name={name} noBackground>
            <CheckBoxListItem isChecked={valueTransform} onChange={handleChange} className={className} disabled={disabled}>
                {children}
            </CheckBoxListItem>
        </FormItemInnerVariant>
    );
};
