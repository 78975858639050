import { _TableHeader } from 'components/NXTable/Header/_TableHeader';
import React from 'react';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/

/**
 * Applies Props to children of specific type
 * @param {React.ReactElement | React.ReactElement[] | React.ReactNode | React.ReactNodeArray} children
 * @param {Object} props
 * @param {React.ReactElement | null} [type]
 * @returns {React.ReactElement[]}
 */
export function applyChildrenProps(children, props, type) {
    return React.Children.map(children, (child) => {
        if (React.isValidElement(child) && (type ? child.type === type : true)) {
            return React.cloneElement(child, props);
        }
        return child;
    });
}

export function checkContextOverrideProps({ children, context }) {
    const checkContextPropOverrides = ['columns'];

    return React.Children.map(children, (child) => {
        if (child.type !== _TableHeader) {
            return child;
        }

        const propOverrides = checkContextPropOverrides.reduce((acc, prop) => {
            if (!child.props[prop]) {
                acc[prop] = context[prop];
            }
            return acc;
        }, {});

        return Object.values(propOverrides).length > 0 ? React.cloneElement(child, propOverrides) : child;
    });
}
