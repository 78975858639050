/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import BronzeTier from 'assets/images/vipRewards/vipreward-bronze.svg';
import GoldTier from 'assets/images/vipRewards/vipreward-gold.svg';
import PlatinumTier from 'assets/images/vipRewards/vipreward-platinum.svg';
import SilverTier from 'assets/images/vipRewards/vipreward-silver.svg';
import VipTier from 'assets/images/vipRewards/vipreward-vip.svg';
import { tiers } from 'containers/vipRewards/consts';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { NXUtils } from 'utilities/NXUtils';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
export type Tier = NXUtils.ValueOf<typeof tiers>;

type VipRewardsTierIconProps = {
    tier: Tier;
    className?: string;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const VipRewardsTierIcon: React.FC<VipRewardsTierIconProps> = ({ tier, className }) => {
    /***** RENDER HELPERS *****/
    const getTierIconImageSrc = () => {
        switch (tier) {
            case tiers.VIP:
                return VipTier;
            case tiers.BRONZE:
                return BronzeTier;
            case tiers.SILVER:
                return SilverTier;
            case tiers.GOLD:
                return GoldTier;
            case tiers.PLATINUM:
                return PlatinumTier;
            default:
                return '';
        }
    };

    /***** RENDER *****/
    return <img src={getTierIconImageSrc()} className={className} alt={tier} />;
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
