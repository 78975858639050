import { CaveatTypes } from 'containers/katana/formFields/caveatTypes';
import { getCaveat } from 'containers/katana/formFields/methods/getCaveat';
import type { KatanaNamespace } from 'containers/katana/types';
import { cloneDeep } from 'lodash';
import type { PartialDeep } from 'type-fest';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
type Params = {
    sectionDefinitionData: KatanaNamespace.SectionDefinitions.Values;
    attributes: PartialDeep<Artah.Katana.Site.ID.Section.ID.GET._200['data']>;
};

export function handleRecursiveAttributesCaveatController({ sectionDefinitionData, attributes }: Params) {
    function removeValueRecursively(path: string[] = [], properties: KatanaNamespace.PropertiesSignature) {
        const newPath = [...path];
        const key = newPath.shift();
        if (!key) return;

        const value = properties[key];
        if (!value) return;

        if (Array.isArray(value)) {
            value.forEach((_value) => {
                removeValueRecursively(newPath, _value);
            });
        } else if (newPath.length && typeof value === 'object') {
            removeValueRecursively(newPath, value);
        } else {
            delete properties[key];
        }
    }

    const modifiedProperties = cloneDeep(attributes.properties);

    function mapNewlyModifiedValues(_properties: KatanaNamespace.SectionDefinitions.Values['properties'], path: string[] = []) {
        _properties.forEach((property) => {
            const hideOnSectionStyle = getCaveat(CaveatTypes.HIDE_ON_SECTION_STYLE, property.caveats);
            const shouldHideOnSectionStyle = hideOnSectionStyle?.includes(attributes.style);
            const newPath = [...path, property.key];

            if (shouldHideOnSectionStyle) {
                removeValueRecursively(newPath, modifiedProperties);
            }

            if ('properties' in property) {
                mapNewlyModifiedValues(property.properties, newPath);
            }
        });
    }

    mapNewlyModifiedValues(sectionDefinitionData.properties);

    const newAttributes = cloneDeep(attributes);
    delete newAttributes.properties;
    newAttributes.properties = modifiedProperties;

    return newAttributes;
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
