import { OrderingFormContext } from 'containers/orderingForm/contexts/orderingFormContext';
import { useContext } from 'react';

export const useOrderingFormContext = () => {
    const context = useContext(OrderingFormContext);
    if (!context) {
        throw new Error('useOrderingFormContext must be used within a OrderingFormProvider');
    }
    return context;
};
