import { createRoute } from '@tanstack/react-router';
import { routerMiddleware } from 'router/utils/middleware';
import { lazyFN } from 'utilities/methods/tanstack/router/lazyFn';
import { OrderRoute } from '..';

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const OrderIdRoute = createRoute({
    getParentRoute: () => OrderRoute,
    path: '$orderId',
    beforeLoad(opts) {
        routerMiddleware.business(this, opts);
        routerMiddleware.authentication('user', opts);
    }
}).lazy(lazyFN(() => import('./index.lazy'), 'LazyOrderIdRoute'));
