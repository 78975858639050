import classNames from 'classnames';
import { _FlexChild } from 'components/Utils/Flex/_Child';
import { PropInjector } from 'components/Utils/PropInjector';
import { isBoolean } from 'lodash';
import React from 'react';
import './_Flex.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
export namespace Flex {
    export type Props = {
        className?: string;
        gap?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
        children: React.ReactNode | React.ReactElement;
        direction?: 'row' | 'column' | 'row-reverse' | 'column-reverse' | boolean | '';
        justify?: 'center' | 'start' | 'end' | 'between' | 'around' | 'evenly';
        align?: 'start' | 'end' | 'center' | 'baseline' | 'stretch';
        wrap?: 'wrap' | 'nowrap' | 'wrap-reverse' | boolean;
        alignChildren?: 'stretch';
        fullWidth?: any;
        fullHeight?: any;
        relative?: boolean;
        inject?: any;
        style?: React.CSSProperties;

        /**
         * Applies inline flex styles to the component.
         */
        inline?: boolean;
    };
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const _Flex = React.forwardRef<HTMLDivElement, Flex.Props>(
    (
        { className, gap = 2, children, direction, justify, wrap, align, alignChildren, fullWidth, fullHeight, relative, inject, inline, style },
        ref
    ) => {
        /***** FUNCTIONS *****/
        const createClass = <TProp extends any>(prefix: string, prop: TProp, fallback: any) =>
            `${prefix}-${isBoolean(prop) || prop === '' ? fallback : direction}`;

        /***** RENDER HELPERS *****/
        const flexClasses = classNames(className, 'Flex', `Flex--gap-${gap}`, {
            [createClass('Flex--direction', direction, 'row')]: direction,
            [`Flex--align-${align}`]: align,
            [`Flex--justify-${justify}`]: justify,
            [`Flex--wrap-${wrap === true ? 'wrap' : wrap}`]: wrap,
            [`Flex--align-children-${alignChildren}`]: alignChildren,
            'Flex--fullWidth': fullWidth,
            'Flex--fullHeight': fullHeight,
            'Flex--relative': relative,
            'Flex--inject': inject,
            'Flex--inline': inline,
        });

        /***** RENDER *****/
        return (
            <PropInjector inject={inject} injectableProps={{ className: flexClasses, style }} injectable={children}>
                <div ref={ref} className={flexClasses} style={style}>
                    {children}
                </div>
            </PropInjector>
        );
    }
);

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
_Flex.displayName = 'Flex';

export const Flex = Object.assign(_Flex, {
    Child: _FlexChild,
});
