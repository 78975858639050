import { API } from 'utilities/api/hosting';
import { createPopup, getDataFromSuccessResponse, getErrorFromFailResponse } from 'utilities/methods/commonActions';
import {
    HOSTING_SUBACCOUNT_USERNAME,
    HOSTING_SUBACCOUNT_META,
    HOSTING_RESELLER_INITIAL_STATE,
    HOSTING_CPANEL_SUBACCOUNT_REQUEST,
    HOSTING_CPANEL_SUBACCOUNT_SUCCESS,
    HOSTING_CPANEL_SUBACCOUNT_FAIL
} from './actionTypes';



/**********************************************************************************************************
 *   RESELLER
 **********************************************************************************************************/
export const storeUsername = (username) => {
    return (dispatch) => {
        dispatch({
            type: HOSTING_SUBACCOUNT_USERNAME,
            hosting_subaccount_username: username
        });
    };
};

export const storeMeta = (meta) => {
    return (dispatch) => {
        dispatch({
            type: HOSTING_SUBACCOUNT_META,
            hosting_subaccount_meta: meta
        });
    };
};

export const resetResellerState = () => {
    return (dispatch) => {
        dispatch({
            type: HOSTING_RESELLER_INITIAL_STATE
        });
    };
};

export const cpanelSubAccountLogin = (id, username) => {
    return (dispatch) => {
        dispatch({
            type: HOSTING_CPANEL_SUBACCOUNT_REQUEST
        });

        const { closePopup, goToTargetUrl } = createPopup();

        API.hosting.subaccount.GET.cpanel(id, username)
            .then((response) => {
                const hosting_cpanel_subaccount_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: HOSTING_CPANEL_SUBACCOUNT_SUCCESS,
                    hosting_cpanel_subaccount_data
                });

                goToTargetUrl(hosting_cpanel_subaccount_data.attributes.url);
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                dispatch({
                    type: HOSTING_CPANEL_SUBACCOUNT_FAIL,
                    hosting_cpanel_subaccount_error: response
                });

                closePopup();
            });
    };
};
