import { superUserItemRenderTracker } from 'components/StaffMenu/SuperUser/consts';
import { useSuperUserItemState } from 'components/StaffMenu/SuperUser/hooks/useSuperUserItemState';
import type { SuperUserNamespace } from 'components/StaffMenu/SuperUser/types';
import { useEffect } from 'react';
import { useTanstackStoreKey } from 'utilities/hooks/tanstack-store/useStoreKey';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export function useSuperUserItem(key: SuperUserNamespace.Key, defaultState?: boolean) {
    /***** HOOKS *****/
    const store = useSuperUserItemState(key, defaultState);
    const { update: updateSuperUserItemCount } = useTanstackStoreKey(superUserItemRenderTracker, key);

    /**
     * Update the number of renders of this key
     */
    useEffect(() => {
        updateSuperUserItemCount((count) => (count ? count + 1 : 1));
        return () => {
            // Maybe also do some other cleanup here
            updateSuperUserItemCount((count) => (count ? count - 1 : 0));
        };
    }, []);

    /***** RESULTS *****/
    return Boolean(store.value);
}
