import { Link } from '@tanstack/react-router';
import classNames from 'classnames';
import { _DropdownDotsButton as DropdownDotsButton } from 'components/Dropdowns/DropdownDotsButton';
import type { DropdownNamespace } from 'components/Dropdowns/types';
import React, { useRef, useState } from 'react';
import { isElement } from 'react-is';
import Tooltip from 'react-tooltip-lite';
import { useClickAway } from 'utilities/hooks/useClickAway';
import './_BaseDropdown.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * This component is only used to provide basic functionality to more advanced dropdowns.
 * This component does not have any styling, and should not be used on it's own.
 * If you need a new dropdown type use this as it's base.
 */
export const BaseDropdown: React.FC<DropdownNamespace.BaseProps> = ({
    variant,
    className,
    list = [],
    size,
    title,
    titleOnClick,
    to,
    color = 'primary',
    onMouseOver
}) => {
    /***** STATE *****/
    const [isDropdownMenuOpen, setIsDropdownMenuOpen] = useState(false);

    /***** HOOKS *****/
    // Ref
    const dropdownRef = useRef(null);

    // Click away
    useClickAway([dropdownRef], () => setIsDropdownMenuOpen(false));

    /***** FUNCTIONS *****/
    const toggleDropdownMenu = () => {
        setIsDropdownMenuOpen((prev) => !prev);
    };

    /***** RENDER HELPERS *****/
    const renderList = () => {
        if (list.length <= 0) return '';

        return list.map((item, index) => {
            if (isElement(item)) {
                const { onClick, ...restItemProps } = item?.props || {};
                const handleItemOnClick = (e) => {
                    toggleDropdownMenu();
                    if (!item) {
                        return;
                    }

                    onClick?.(e);
                };

                const modifiedProps = {
                    ...restItemProps,
                    onClick: handleItemOnClick
                };

                return React.cloneElement(item, modifiedProps);
            }

            const { type, onMouseOver, label } = item;

            const interactionProps = {
                onMouseOver,
                onFocus: onMouseOver
            };

            function renderListItemAnchor(_to: string) {
                return (
                    <li key={index}>
                        <Link to={_to} {...interactionProps}>
                            {label}
                        </Link>
                    </li>
                );
            }

            if (item?.to) return renderListItemAnchor(item?.to);

            switch (type) {
                case 'anchor':
                case 'internal':
                    return renderListItemAnchor(item.link);

                case 'external':
                    return (
                        <li key={index}>
                            <a href={item.link} {...interactionProps}>
                                {label}
                            </a>
                        </li>
                    );

                case 'onClick': {
                    const { disabled = false, disabledTooltip, className: itemClass, onClick } = item as DropdownNamespace.OnClickListItem;

                    const listItemOnClick = (e) => {
                        if (!disabled) {
                            onClick?.(e);
                            toggleDropdownMenu();
                        }
                    };

                    const renderItemButton = () => (
                        <li key={index}>
                            <button
                                {...interactionProps}
                                className={classNames(`${variant}__list--button`, itemClass, { disabled })}
                                onClick={listItemOnClick}
                            >
                                {label}
                            </button>
                        </li>
                    );

                    return disabled && disabledTooltip && disabledTooltip.length > 0 ? (
                        <Tooltip
                            className="BaseDropdown__disabledListItemTooltip"
                            direction="up"
                            distance={10}
                            arrowSize={5}
                            content={<div className="SolidDropdown__tooltip">{disabledTooltip}</div>}
                        >
                            {' '}
                            {renderItemButton()}{' '}
                        </Tooltip>
                    ) : (
                        renderItemButton()
                    );
                }

                default:
                    return '';
            }
        });
    };

    const renderDropdownMenu = () => {
        if (!isDropdownMenuOpen) {
            return '';
        }

        return (
            <div className={`BaseDropdown__bottom ${variant}__bottom`}>
                <ul className={`BaseDropdown__list ${variant}__list`}>{renderList()}</ul>
            </div>
        );
    };

    const renderMainButton = () => {
        const interactionProps = {
            onMouseOver,
            onFocus: onMouseOver
        };

        if (to) {
            return (
                <Link className="title" to={to} {...interactionProps}>
                    {title}
                </Link>
            );
        }

        return (
            <button type="button" onClick={titleOnClick} className="title" {...interactionProps}>
                {title}
            </button>
        );
    };

    const baseDropdownClasses = classNames('BaseDropdown', variant, className, size, color, {
        nolist: !list || list.length === 0
    });

    /***** RENDER *****/
    return (
        <div ref={dropdownRef} className={baseDropdownClasses}>
            <div className={`BaseDropdown__top ${variant}__top  top`}>
                {renderMainButton()}
                <DropdownDotsButton
                    variant={variant.replace('Dropdown', '')}
                    color={color}
                    isOpen={isDropdownMenuOpen}
                    setIsOpen={setIsDropdownMenuOpen}
                    className="menu"
                />
            </div>

            {renderDropdownMenu()}
        </div>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export const baseDropDownColourOptions = ['primary', 'secondary', 'success', 'confirm', 'error', 'warn', 'warning', 'notice', 'gray'];
