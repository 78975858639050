import { useMutation } from '@tanstack/react-query';
import { API } from 'utilities/api/hosting';
import { handleDefaultSuccessNotification } from 'utilities/methods/commonActions/handleDefaultSuccessNotification';
import { NXQuery } from 'utilities/query';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
const _useMutation = (serviceId: string) => {
    return useMutation({
        mutationFn: (ip_address: string) => API.hosting.POST.unblockFirewall(serviceId, { ip_address }),
        onSuccess: (response) => {
            handleDefaultSuccessNotification(response);
            NXQuery.hosting.serviceId.checkFirewall.invalidateQueries(serviceId);
        }
    });
};
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const unblockIPAddress = Object.freeze({
    useMutation: _useMutation
});
