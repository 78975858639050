import { useSearch } from '@tanstack/react-router';
import { RegistrationOrderingFormContext } from 'App/components/templates/contexts/registrationOrderingFormContext/context';
import classNames from 'classnames';
import Header from 'components/Header';
import { LiveChat } from 'components/LiveChat';
import PostLoginChecklist from 'containers/dashboard/Components/postLoginChecklist';
import { OrderingForm } from 'containers/orderingForm';
import { useEffect, useState } from 'react';
import { ApplicationAlertBanner } from '../AppAlertBanner';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type VentraUserRouteTemplateComponent = React.FC<{
    outlet: React.ReactNode;
}>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Renders User related components when the route is authenticated.
 */
export const VentraUserRouteTemplate: VentraUserRouteTemplateComponent = ({ outlet }) => {
    /***** HOOKS *****/
    const [isDomainRegistrationFormOpen, setIsDomainRegistrationFormOpen] = useState(false);
    const [isRegistration, setIsRegistration] = useState(true);

    // Open the ordering form via the VIPsites express flow. When we do this, the url will be `/my-services/vip-sites/$katanaServiceId/overview`, and throughout the ordering form we can use the katana service id to grab info about the katana site that the domain is being added to.
    const isBeingOpenedByUrl = useSearch({ strict: false, select: (search) => !!search['vip-express-add-domains'] });

    /***** EFFECTS *****/
    useEffect(() => {
        if (isBeingOpenedByUrl && !isDomainRegistrationFormOpen) {
            setIsDomainRegistrationFormOpen(true);
        }
    }, [isBeingOpenedByUrl]);

    /***** RENDER *****/
    return (
        <div className={classNames('UserLayout', { 'UserLayout--noScroll': isDomainRegistrationFormOpen })}>
            <ApplicationAlertBanner />
            <Header />
            <RegistrationOrderingFormContext.Provider
                value={{ isDomainRegistrationFormOpen, setIsDomainRegistrationFormOpen, isRegistration, setIsRegistration }}
            >
                {outlet}
                <OrderingForm />
            </RegistrationOrderingFormContext.Provider>
            <LiveChat />
            <PostLoginChecklist />
        </div>
    );
};
