/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useInfiniteQuery } from '@tanstack/react-query';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { API as ACCOUNT_API } from 'utilities/api/account';
import { handleDefaultErrorNotification } from 'utilities/methods/commonActions/handleDefaultErrorNotification';
import { getNextPageParamDefault } from 'utilities/methods/queries/getNextPageParamDefault';
import { infiniteQuerySelectDefault } from 'utilities/methods/queries/infiniteQuerySelectDefault';
import { createQueryBoilerPlate } from 'utilities/methods/tanstack';

const generateEmptySearchInvoicePaginatedResponse = () => ({
    data: [],
    meta: {
        current_page: 1,
        first_page_url: '',
        from: null,
        last_page: 1,
        last_page_url: '',
        links: [],
        next_page_url: null,
        path: '',
        per_page: 15,
        prev_page_url: null,
        to: null,
        total: 0,
        total_all_records: 0,
        total_pages: 1,
        total_extended_invoices: 0,
        invoices_extension_limit: 3
    }
});

/**
 * Since this endpoint currently returns a 403 when the invoice is for another user, we need to just handle that like an empty response
 *
 * @template T
 * @param {() => Promise<T>} endpoint
 * @returns {Promise<T | ReturnType<typeof generateEmptySearchInvoicePaginatedResponse>>}
 */
const handle403Error = async (endpoint) => {
    try {
        return await endpoint();
    } catch (error) {
        if (error?.status === 403) {
            return generateEmptySearchInvoicePaginatedResponse();
        }
    }
};

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
/**
 * @deprecated use v5 format, see katanaQuery
 */
const useSearchInvoiceByKeywordBoilerPlate = createQueryBoilerPlate({
    service: 'billing',
    serviceKey: 'SEARCH_INVOICE_BY_KEYWORD',
    APIRoute: (filters) => {
        const keyword = filters?.filter;
        const type = isNaN(Number(keyword)) ? 'name' : 'id';

        return async ({ pageParam = filters, signal }) => {
            return await handle403Error(() => ACCOUNT_API.user.invoice.search.GET(pageParam, signal, type));
        };
    },
    defaultOptions: {
        enabled: (filters) => Boolean(filters?.filter),
        onError: () => handleDefaultErrorNotification,
        select: () => infiniteQuerySelectDefault,
        getNextPageParam: (filters) => (lastPage) => getNextPageParamDefault({ lastPage, filters })
    }
});

const { getDefaults } = useSearchInvoiceByKeywordBoilerPlate;

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * @deprecated use v5 format, see katanaQuery
 * Search keywords in an invoice
 */
function useSearchInvoiceByKeywordQuery(keyword, options = {}) {
    return useInfiniteQuery({
        ...getDefaults(keyword),
        ...options
    });
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export { useSearchInvoiceByKeywordBoilerPlate, useSearchInvoiceByKeywordQuery };

