
import { authenticationMutationKeys } from 'containers/login/queries/queryKeys';
import { queryClient } from 'store/queryClient';
import { API } from 'utilities/api/login';
import { createNXMutation } from 'utilities/methods/tanstack/createMutation';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
export const logout = createNXMutation({
    mutationFn: API.logout,
    mutationKey: authenticationMutationKeys.logout(),
    onSettled: () => queryClient.clear()
});
