/**********************************************************************************************************
 *   GLOBAL IMPORTS
 **********************************************************************************************************/
import store from 'store/store';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { API } from 'utilities/api/vipRewards';
import { getDataFromSuccessResponse, getErrorFromFailResponse } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { pushNotification } from 'components/Toast/functions';

/**********************************************************************************************************
 *   EXTERNAL ACTION TYPES
 **********************************************************************************************************/
import { typedSet } from 'utilities/methods/objectMethods';
import { NXQuery } from 'utilities/query';
import {
    REWARDS_TIERS_LIST_REQUEST,
    REWARDS_TIERS_LIST_SUCCESS,
    REWARDS_TIERS_LIST_ERROR,
    REWARDS_SIGNUP_REQUEST,
    REWARDS_SIGNUP_SUCCESS,
    REWARDS_SIGNUP_ERROR
} from './actionTypes';



/**
 * Get a list of all VIPrewards tiers and associated data
 */
export function getRewardTiersList() {
    const { dispatch } = store;

    dispatch({
        type: REWARDS_TIERS_LIST_REQUEST
    });

    return API.vipRewards.GET.tiers()
        .then((response) => {
            dispatch({
                type: REWARDS_TIERS_LIST_SUCCESS,
                rewards_tiers_list_data: getDataFromSuccessResponse(response)
            });
        })
        .catch(() => {
            dispatch({
                type: REWARDS_TIERS_LIST_ERROR
            });
        });
}

/**
 * Sign up to the VIPrewards program
 */
export const vipRewardsSignup = (options) => {
    const { dispatch } = store;

    dispatch({ type: REWARDS_SIGNUP_REQUEST });

    API.vipRewards.POST.signUp()
        .then(async (response) => {
            const rewards_signup_data = getDataFromSuccessResponse(response);

            dispatch({ type: REWARDS_SIGNUP_SUCCESS });

            // Update the rewards tier on the account list
            const newRewardTier = typeof rewards_signup_data?.tier_assigned === 'string' ? rewards_signup_data.tier_assigned.toLowerCase() : null;

            NXQuery.auth.accountList.invalidate();
            NXQuery.auth.accountList.optimistic.setWith(void 0, 'data', (old) => {
                return old.map((accountData) => {
                    if (accountData.id !== options?.accountId) return accountData;

                    return typedSet(accountData, 'attributes.reward_tier', newRewardTier);
                });
            });

            options?.onSuccess?.();
        })
        .catch((error) => {
            pushNotification(getErrorFromFailResponse(error));
            dispatch({ type: REWARDS_SIGNUP_ERROR });
        });
};
