import { loginCpanelMutation } from 'containers/hosting/queries/mutations/cpanelLogin';
import { loginPleskMutation } from 'containers/hosting/queries/mutations/pleskLogin';
import { loginWHMMutation } from 'containers/hosting/queries/mutations/whmLogin';
import { hostingList } from 'containers/hosting/queries/queries/listHosting';
import { hostingSearch } from 'containers/hosting/queries/queries/searchHosting';
import { hostingInformation } from 'containers/hosting/queries/queries/serviceId';
import { checkFirewall } from 'containers/hosting/queries/serviceId/checkFirewall';
import { createRestoreInvoice } from 'containers/hosting/queries/serviceId/createRestoreInvoice';
import { unblockIPAddress } from 'containers/hosting/queries/serviceId/unblockIPAddress';
import type { API } from 'utilities/api/hosting';
import { hostingPrepayInfo } from './serviceId/hostingPrepayInfo';

/**
 * Object with all hosting related tanstack queries/mutations.
 */
export const _HostingQuery = {
    loginCpanel: loginCpanelMutation,
    loginWHM: loginWHMMutation,
    loginPlesk: loginPleskMutation,
    serviceId: Object.assign(hostingInformation, {
        checkFirewall,
        unblockIPAddress,
        createRestoreInvoice
    }),
    renewDetails: hostingPrepayInfo,
    list: hostingList,
    search: hostingSearch,

    /**
     * Invalidate function that will invalidate all hosting related queries (list, search and all queries that contain the serviceId if provided).
     */
    invalidate
};

/**
 * @see {@link _HostingQuery.invalidate} for more information.
 */
function invalidate(serviceId?: API.hosting.GET.information.nxAxios.Params) {
    hostingList.invalidate();
    hostingSearch.invalidate();
    if (serviceId) {
        hostingInformation.invalidate(serviceId);
    }
}
