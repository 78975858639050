/**********************************************************************************************************
 *   FUNCTION START
 **********************************************************************************************************/
import { notFound, redirect } from '@tanstack/react-router';
import type { ResolvedRouteConfig } from 'router/types';

/**
 * Handles scenario where route is not active.
 */
export function handleInactive(config: ResolvedRouteConfig) {
    if (!config.active) {
        switch (config.type) {
            case 'notFound':
                throw notFound();
            case 'redirect':
            default:
                throw redirect({ to: config.redirect });
        }
    }
}
