import React from 'react';
import { ServiceOverviewBanner } from 'components/ServiceOverviewBanner/';
import { Flex } from 'components/Utils/Flex';
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';
import upgradeBackgroundImage from 'assets/images/vps/vps_banner_background.jpeg';
import { domain_transfer_url, getPurchaseLink } from 'containers/services/consts';
import './_domains.scss';
import { useBrandStore } from 'config/hooks/useBrandStore';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const DomainsBanner = () => {
    /***** HOOKS *****/
    const { company } = useBrandStore();
    const isTablet = useAppViewport('md');
    const isMobile = useAppViewport(['xs', 'sm']);

    /***** RENDER *****/
    return (
        <Flex fullWidth={isMobile} direction={isMobile ? 'column' : 'row'} gap={isTablet ? 3 : 5}>
            <ServiceOverviewBanner className="DomainsBanner" src={upgradeBackgroundImage}>
                <ServiceOverviewBanner.ContentWrapper>
                    <ServiceOverviewBanner.Heading>Need more domains?</ServiceOverviewBanner.Heading>
                    <ServiceOverviewBanner.Description>Register your new domain name instantly with {company}!</ServiceOverviewBanner.Description>
                    <ServiceOverviewBanner.Button href={getPurchaseLink('Domain Names', { utmMedium: 'service-banner' })} target="_blank">
                        Register Domains
                    </ServiceOverviewBanner.Button>
                </ServiceOverviewBanner.ContentWrapper>
            </ServiceOverviewBanner>

            <ServiceOverviewBanner className="DomainsBanner DomainsBanner--second" type="manual">
                <ServiceOverviewBanner.ContentWrapper>
                    <ServiceOverviewBanner.Heading primary white={false}>
                        Transfer Your Domains
                    </ServiceOverviewBanner.Heading>
                    <ServiceOverviewBanner.Description primary white={false}>
                        Transferring your existing domain name to {company} has never been easier!
                    </ServiceOverviewBanner.Description>
                    <ServiceOverviewBanner.Button size="large" color="primary" href={domain_transfer_url} target="_blank">
                        Transfer Domains
                    </ServiceOverviewBanner.Button>
                </ServiceOverviewBanner.ContentWrapper>
            </ServiceOverviewBanner>
        </Flex>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
