import classNames from 'classnames';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import type { IconifyIconNamespace } from 'components/Icons/types';
import 'containers/orderingForm/footer/cartButton/_cartButton.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type CartButton = React.FC<CartButtonProps>;
type CartButtonProps = {
    icon: React.FC<IconifyIconNamespace.Props> & {
        Bold: React.FC<IconifyIconNamespace.Props>;
    };
    iconSize?: number;
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    isCartListOpen: boolean;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const CartButton: CartButton = ({ iconSize = 20, onClick, isCartListOpen }) => {
    const cartButtonClassNames = classNames('cartButton', {
        'cartButton--active': isCartListOpen
    });

    /***** RENDER *****/
    return (
        <>
            <button type="button" onClick={onClick} className={cartButtonClassNames}>
                {isCartListOpen ? <PhosphorIcons.Minus white size={iconSize} /> : <PhosphorIcons.Plus black size={iconSize} />}
            </button>
        </>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
