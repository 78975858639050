import { PhosphorIcons } from 'components/Icons/Phosphor';
import { useModuleStoreContext } from 'components/ModuleRenderer/context';
import { useSuperUserItem } from 'components/StaffMenu/SuperUser/hooks/useSuperUserItem';
import { Flex } from 'components/Utils/Flex';
import Text from 'components/Utils/Text';
import React from 'react';
import { useToggle } from 'usehooks-ts';
import { useIsVPN } from 'utilities/hooks/redux/useIsVPN';
import './_ModuleRenderer.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type TDevelopmentDebugModuleRenderer = React.FC<{
    children: string;
    potentialResolutions?: Array<string>;
}>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Used internally in the ModuleRenderer to display debug information regarding modules.
 *
 * This can be toggled on and off using the superUserActions side menu
 */
const _DevelopmentDebugModuleRenderer: TDevelopmentDebugModuleRenderer = ({ children, potentialResolutions }) => {
    /***** HOOKS *****/
    const { hash, href, reason } = useModuleStoreContext();
    const [isOpen, toggleIsOpen] = useToggle(false);
    const isEnabled = useSuperUserItem('TOGGLE_MODULE_REASON');
    const isVPN = useIsVPN();

    if (!isEnabled || !isVPN) {
        return null;
    }

    /***** RENDER *****/
    return (
        <div className="DevelopmentDebugModuleRenderer">
            <div className="DevelopmentDebugModuleRenderer__extension" />
            <button className="DevelopmentDebugModuleRenderer__label" data-open={isOpen} onClick={toggleIsOpen}>
                <Text white size--xs bold>
                    <Flex align="center" justify="between" gap={1}>
                        #{hash} <PhosphorIcons.Chevron state={isOpen ? 'up' : 'down'} size={18} />
                    </Flex>
                </Text>
                {isOpen && (
                    <div>
                        <Text white size--xs align--left>
                            <Text bold>High Level Reason:</Text> {children}
                        </Text>
                        {!!reason && (
                            <Text white size--xs align--left>
                                <Text bold>Provided Reason:</Text> {reason}
                            </Text>
                        )}
                        {!!potentialResolutions && (
                            <Text white size--xs align--left>
                                <Text bold>Potential Resolutions:</Text>
                                <ul className="DevelopmentDebugModuleRenderer__li">
                                    {potentialResolutions.map((resolution, index) => (
                                        <li key={index}>
                                            <Text>{resolution}</Text>
                                        </li>
                                    ))}
                                </ul>
                            </Text>
                        )}
                        <Text white size--xs align--left>
                            <Text bold>Href:</Text> {href}
                        </Text>
                    </div>
                )}
            </button>
        </div>
    );
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const BuiltLocationDisabledInConfig = () => {
    return (
        <_DevelopmentDebugModuleRenderer
            potentialResolutions={[
                'Ensure that the the correct path is provided for this route',
                'Check that the correct hash is used for this route',
                'Ensure that the path & hash are present (and enabled) in /src/router/config.ts',
            ]}
        >
            An active route was not found in the route config
        </_DevelopmentDebugModuleRenderer>
    );
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const MismatchedPathParams = () => {
    return (
        <_DevelopmentDebugModuleRenderer
            potentialResolutions={[
                'This Module may be intended to render on a sub-route with extra params, check the path in the ModuleRenderer',
                'Check that the path is correct in the ModuleRenderer',
            ]}
        >
            When comparing route params to this routes path params, they were not all matched.
        </_DevelopmentDebugModuleRenderer>
    );
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const StoreCondition = () => {
    return (
        <_DevelopmentDebugModuleRenderer
            potentialResolutions={[
                'Ensure the correct option is being selected in the ModuleRenderer file',
                'Check the useCondition hook has the correct logic for setting the enabled key',
                'Check that the default state in the ModuleStore is set to true',
            ]}
        >
            The enabled property selected from the module store was not true.
        </_DevelopmentDebugModuleRenderer>
    );
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const SearchParamModule = () => {
    return (
        <_DevelopmentDebugModuleRenderer>
            This module was not rendered because module was provided in the search params, but this module did not match the requested module name
        </_DevelopmentDebugModuleRenderer>
    );
};

export const DevelopmentDebugModuleRenderer = Object.assign(_DevelopmentDebugModuleRenderer, {
    BuiltLocationDisabledInConfig,
    MismatchedPathParams,
    StoreCondition,
    SearchParamModule,
});
