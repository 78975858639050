import { Border } from 'components/Utils/Border';
import Text from 'components/Utils/Text';
import { useExpressServiceManagerContext } from 'containers/dashboard/Components/expressServiceManager/context';
import './_ShowAll.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type ShowAll = React.FC<{
    onClick: () => void;
    isLoading?: boolean;
    totalRows?: number;
    numberOfRows?: number;
}>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Show all button that is showed below a service table. This automatically determines if the button is inside the express service manager
 * and if so, will remain hidden.
 */
export const ShowAll: ShowAll = ({ isLoading, totalRows, numberOfRows, onClick }) => {
    const isExpressServiceManager = useExpressServiceManagerContext();

    /***** RENDER *****/
    if (isLoading) return null;
    if (!totalRows) return null;
    if (isExpressServiceManager) return null;
    if (!numberOfRows) return null;
    if (totalRows <= numberOfRows) return null;
    if (!numberOfRows && totalRows <= 10) return null;

    return (
        <Border top>
            <button onClick={onClick} className="expressServiceManager__showAll">
                <Text primary align--center uppercase size--s medium>
                    Show All
                </Text>
            </button>
        </Border>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
