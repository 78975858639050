import { submitLogin } from 'containers/login/queries/mutations/login';
import { logout } from 'containers/login/queries/mutations/logout';
import { requestAlternativeTwoFactor } from 'containers/login/queries/mutations/requestAlternateTwoFactor';
import { requestDefaultTwoFactor } from 'containers/login/queries/mutations/requestDefaultTwoFactor';
import { selectAccount } from 'containers/login/queries/mutations/selectAccount';
import { staffLogin } from 'containers/login/queries/mutations/staffLogin';
import { submitTwoFactor } from 'containers/login/queries/mutations/submitTwoFactor';
import { accountList } from 'containers/login/queries/queries/accountList';
import { additionalUserOAuthLogin } from 'containers/login/queries/queries/additionalUserOAuthLogin';
import { checkToken } from 'containers/login/queries/queries/checkToken';
import { listTwoFactor } from 'containers/login/queries/queries/listTwoFactor';
import { oAuthLogin } from 'containers/login/queries/queries/oAuthlogin';
import { userData } from 'containers/login/queries/queries/userData';

export const _AuthQuery = {
    selectAccount,
    userData,
    logout,
    accountList,
    login: {
        submit: submitLogin,
        checkToken,
        staff: staffLogin
    },
    oauth: {
        login: oAuthLogin,
        additionalUserLogin: additionalUserOAuthLogin
    },
    twoFactor: {
        list: listTwoFactor,
        requestAlternate: requestAlternativeTwoFactor,
        requestDefault: requestDefaultTwoFactor,
        submit: submitTwoFactor
    }
};
