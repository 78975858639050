import { useQuery, useSuspenseQuery, type QueryKey, type UndefinedInitialDataOptions } from '@tanstack/react-query';
import type { CreateQueryOptions } from 'utilities/methods/tanstack/createQuery/factories/types';
import type { NXQueryUtils } from 'utilities/methods/tanstack/types';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type QueryOptionsSubset<TInternalData, TQueryFnData extends NXQueryUtils.ApiData, TError, TQueryKey extends QueryKey> = Omit<
    UndefinedInitialDataOptions<TQueryFnData, TError, TInternalData, TQueryKey>,
    'queryFn' | 'queryKey'
>;

/**********************************************************************************************************
 *   FACTORY START
 **********************************************************************************************************/
// prettier-ignore
export class InternalQueryHookFactory<TParams, TQueryFnData extends NXQueryUtils.ApiData, TError, TData, TQueryKey extends QueryKey> {
    constructor(private createQueryOptions: CreateQueryOptions<TParams, TQueryFnData, TError, TData, TQueryKey>) {}

    public useQuery = <TInternalData = TData>(params: TParams, opts?: QueryOptionsSubset<TInternalData, TQueryFnData, TError, TQueryKey>) => {
        return useQuery({
            ...this.createQueryOptions<TInternalData>(params),
            ...opts
        });
    };

    public useSuspenseQuery = <TInternalData = TData>(params: TParams, opts?: QueryOptionsSubset<TInternalData, TQueryFnData, TError, TQueryKey>) => {
        return useSuspenseQuery({
            ...this.createQueryOptions<TInternalData>(params),
            ...opts
        });
    };

    public useSelectQuery = <TInternalData = TData>(params: TParams, select: (data: TQueryFnData) => TInternalData) => {
        return useQuery({
            ...this.createQueryOptions<TInternalData>(params),
            select
        });
    };

    public useSelectSuspenseQuery = <TInternalData = TData>(params: TParams, select: (data: TQueryFnData) => TInternalData) => {
        return useSuspenseQuery({
            ...this.createQueryOptions<TInternalData>(params),
            select
        });
    };
}
