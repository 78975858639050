import { useNavigate, useParams } from '@tanstack/react-router';
import { CustomDropdown } from 'components/Dropdowns/CustomDropdown';
import SelectOptions from 'components/Dropdowns/SelectDropdown/Options';
import Title from 'components/Title';
import { mailboxLogin, resetEmailState } from 'containers/email/action';
import { getTicketSubmitUrl } from 'containers/support/methods';
import { useDispatch } from 'react-redux';
import { useSelectableSelector } from 'utilities/hooks/redux/useSelectableSelector';
import { getIncludedObjBasedOnType } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const IndividualMailboxTitle = () => {
    /***** SELECTORS *****/
    const { baseDomain, domain, product, type } = useSelectableSelector((state: any) => state.email.email_information_mailbox_data, {
        select: (email_information_mailbox_data) => {
            const { attributes, included, type } = email_information_mailbox_data || {};
            const { domain } = attributes || {};

            const product: any = getIncludedObjBasedOnType(included, 'product');
            const baseDomain = domain?.split('@')[1] ?? '';

            return {
                email_information_mailbox_data,
                domain,
                baseDomain,
                product,
                type
            };
        }
    });

    /***** HOOKS *****/
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { groupId, mailboxId } = useParams({ strict: false });

    /***** RENDER *****/
    return (
        <Title
            useSubtitle
            serviceTitle={baseDomain}
            serviceSubTitle={product?.attributes?.name || ''}
            serviceType={type}
            subtitleData={{
                title: (
                    <div className="emailHosting__title">
                        <div className="service">{domain || ''}</div>
                    </div>
                ),
                action: {
                    label: 'Login to Mailbox',
                    onClick: () => {
                        mailboxLogin(mailboxId)(dispatch);
                    }
                }
            }}
            action={[
                {
                    className: 'listAccount__action',
                    label: 'List Mailboxes',
                    type: 'onClick',
                    color: 'secondary',
                    onClick: (e) => {
                        e.preventDefault();
                        resetEmailState()(dispatch);
                        navigate({
                            to: '/my-services/email-hosting/$groupId/general',
                            params: { groupId: groupId! },
                            hash: 'mailboxes'
                        });
                    }
                },
                <CustomDropdown
                    className="TitleShared__singleDropdown"
                    label={({ labelRef, dropdownTitleOnClick }) => (
                        <button className="TitleShared__singleDropdownButton" ref={labelRef} type="button" onClick={() => dropdownTitleOnClick()}>
                            Email Hosting Help
                            <i className="item__icon icon icon-chevron-down" />
                        </button>
                    )}
                    key="dropdown"
                    render={() => (
                        <SelectOptions
                            options={[
                                {
                                    label: 'Email Hosting Help Articles',
                                    onSelect: () =>
                                        navigate({
                                            to: '/support/support-centre',
                                            search: {
                                                categories: 'products, email-hosting-products'
                                            }
                                        })
                                },
                                {
                                    label: 'Submit a Technical Support eTicket for this Service',
                                    onSelect: () => {
                                        navigate({
                                            to: getTicketSubmitUrl({
                                                department: 'technical-support',
                                                service: mailboxId
                                            })
                                        });
                                    }
                                },
                                {
                                    label: 'Submit a Billing eTicket for this Service',
                                    onSelect: () => {
                                        navigate({
                                            to: getTicketSubmitUrl({
                                                department: 'accounts-billing',
                                                service: mailboxId
                                            })
                                        });
                                    }
                                }
                            ]}
                        />
                    )}
                />
            ]}
        />
    );
};
