import type { QueryKey, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useCallback, useState } from 'react';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type QueryOptionsSubset<TQueryFnData, TError, TData, TQueryKey extends QueryKey> = Omit<
    UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
    'queryFn' | 'queryKey'
>;
type Query<TParams, TQueryFnData, TError, TData, TQueryKey extends QueryKey> = (
    params: TParams,
    opts: QueryOptionsSubset<TQueryFnData, TError, TData, TQueryKey>
) => UseQueryResult<TData, TError>;
type QueryObjectOrFunction<TParams, TQueryFnData, TError, TData, TQueryKey extends QueryKey> =
    | Query<TParams, TQueryFnData, TError, TData, TQueryKey>
    | { useQuery: Query<TParams, TQueryFnData, TError, TData, TQueryKey> };

type TriggerQuery<TParams> = (params: TParams) => void;
type ResetQuery = () => void;
type LazyQueryResults<TParams, TData, TError> = UseQueryResult<TData, TError> & { params: TParams | null };

// prettier-ignore
type UseLazyQueryResult<TParams, TError, TData> = [
    TriggerQuery<TParams>, 
    ResetQuery, 
    LazyQueryResults<TParams, TData, TError>
];

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Utility hook that will return a function for triggering a query (and resetting); Therefore the query will
 * only be active when the returned function is called, and disabled again when reset is called.
 * The passed function must accept the "params" for the query as the first parameter and a UseQueryParams object as the second param.
 *
 * For example, the following query API will work with this hook:
 *
 * ```ts
 * const useQuery = (params: any, opts: unknown) => ...
 * ```
 *
 * @example
 * ```ts
 * const [triggerQuery, resetQuery, { data, error, isLoading }] = useLazyQuery(NXQuery.hosting.searchNew);
 * ```
 *
 * This is an implementation similar to RTK queries LazyQuery which has documentation here for reference:
 * @link https://redux-toolkit.js.org/rtk-query/api/created-api/hooks#uselazyquery
 */
export const _useLazyQuery = <TParams, TQueryFnData, TError, TData, TQueryKey extends QueryKey>(
    queryTreeLeafNode: QueryObjectOrFunction<TParams, TQueryFnData, TError, TData, TQueryKey>,
    opts?: QueryOptionsSubset<TQueryFnData, TError, TData, TQueryKey>
): UseLazyQueryResult<TParams, TError, TData> => {
    /***** STATE *****/
    const [params, setParams] = useState<null | TParams>(null);

    /***** FUNCTIONS *****/
    const triggerQuery = useCallback((params: TParams) => {
        setParams(() => params);
    }, []);

    const resetQuery = useCallback(() => {
        setParams(null);
    }, []);

    /***** QUERY *****/
    const useQuery =
        typeof queryTreeLeafNode === 'function'
            ? queryTreeLeafNode //
            : queryTreeLeafNode.useQuery;

    /***** QUERIES *****/
    const queryResults = useQuery(params!, {
        ...opts,
        enabled: !!params && (opts?.enabled ?? true)
    });

    const results = Object.assign(queryResults, {
        params
    });

    /***** HOOK RESULTS *****/
    return [triggerQuery, resetQuery, results] as const;
};
