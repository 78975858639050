import { DisabledEvent } from 'components/Buttons/DisabledEvent';
import InactiveButton from 'components/Buttons/InactiveButton';
import { SolidButton } from 'components/Buttons/SolidButton';
import type { HookFormButtonNamespace } from 'components/Form/Button/hookForm/types';
import { handleOnDisabledClick } from 'components/Form/Button/reduxForm/methods';
import type { RFButton } from 'components/Form/Button/reduxForm/types';
import { useNXForm } from 'components/Form/NXForm/consts';
import { isEqual } from 'lodash';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { isPristine, isSubmitting, isValid } from 'redux-form';

export const REDUX_FORM_BUTTON_STATE = {
    ACTIVE: 'active',
    INACTIVE: 'inactive'
} as const;

export const selectorFields: RFButton.SelectorFields = (form) => (state) => ({ fields: Object.keys(state.form[form]?.registeredFields ?? {}) });

export const selectorValues: RFButton.SelectorValues = (form) => (state) => ({ values: state.form[form]?.values ?? {} });

export const selectorSyncErrors: RFButton.SelectorSyncErrors = (form) => (state) => ({ syncErrors: state.form[form]?.syncErrors ?? {} });

const selector: RFButton.Selector = (form) => (state) => ({
    pristine: isPristine(form)(state),
    submitting: isSubmitting(form)(state),
    valid: isValid(form)(state),
    exists: !!state.form[form],
    fields: Object.keys(state.form[form]?.registeredFields ?? {})
});

export const selectorEqualityCheckFields: RFButton.SelectorEqualityCheck = (prev, next) => isEqual(prev.fields, next.fields);
export const selectorEqualityCheckValues: RFButton.SelectorEqualityCheckValues = (prev, next) => isEqual(prev.values, next.values);

export const selectorEqualityCheckSyncErrors: RFButton.SelectorEqualityCheckValues = (prev, next) => isEqual(prev.syncErrors, next.syncErrors);

/**
 * Custom equality check for this selector due to the fact that the fields object is a new object every time
 * due to Object.keys being used. This will cause the selector to always return a new object, which will cause
 * the component to re-render every time the form is touched if we don't manually do this check.
 */
const selectorEqualityCheck: RFButton.SelectorEqualityCheck = (prev, next) =>
    prev.pristine === next.pristine &&
    prev.submitting === next.submitting &&
    prev.valid === next.valid &&
    prev.exists === next.exists &&
    isEqual(prev.fields, next.fields);

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @description
 * Button to be used within a ReduxForm as the submit button. This button will automatically disable itself if the form is invalid,
 * pristine, or submitting. If the form is not valid, the button will be disabled and clicking it will trigger the form to be touched,
 * which will display the errors to the user.
 *
 * If the form that this button is associated with has no initial fields, ensure that initial values are provided to the form otherwise
 * the button will not be able to determine if the form exists or not.
 *
 * While in development mode, instead of displaying the default text when the form does not exist, a warning will be displayed to the developer.
 */
export const ReduxFormButton: RFButton.TReduxFormButton = ({
    form: _form,
    onClick,
    children,
    color,
    className,
    loading,
    force,
    ignorePristine,
    submitButtonClickEvent
}) => {
    const { form = _form } = useNXForm();
    const { pristine, submitting, valid, exists, fields } = useSelector(selector(form), {
        equalityFn: selectorEqualityCheck
    });

    const onDisabledClick: HookFormButtonNamespace.OnInactiveClick = useCallback(
        (e) => {
            handleOnDisabledClick({ e, form, fields, submitButtonClickEvent });
        },
        [form, fields, submitButtonClickEvent]
    );

    const button = {
        [REDUX_FORM_BUTTON_STATE.INACTIVE]: (
            <DisabledEvent active onDisabledClick={onDisabledClick}>
                <SolidButton disabled key="inactive" className={className}>
                    {children}
                </SolidButton>
            </DisabledEvent>
        ),

        [REDUX_FORM_BUTTON_STATE.ACTIVE]: (
            <SolidButton
                className={className}
                color={color}
                onClick={onClick}
                isLoading={loading || submitting}
                type={onClick ? 'onClick' : 'submit'}
            >
                {children}
            </SolidButton>
        )
    };

    switch (true) {
        case import.meta.env.MODE === 'development' && !exists:
            return <InactiveButton key="dev-warning">⚠ Could not find state.form.{form}, the form prop is likely mispelt ⚠</InactiveButton>;
        case !!force:
            return button[force];
        case pristine && !ignorePristine:
        case !exists:
        case !valid:
            return button[REDUX_FORM_BUTTON_STATE.INACTIVE];
        case submitting:
        case loading:
        default:
            return button[REDUX_FORM_BUTTON_STATE.ACTIVE];
    }
};
