/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { PropInjector } from 'components/Utils/PropInjector';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_FlexChild.scss';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { NXUtils } from 'utilities/NXUtils';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Props = {
    children: React.ReactNode | React.ReactElement;
    inject?: any;
    grow?: NXUtils.NumericRange<1, 2> | true;
    align?: 'start' | 'end' | 'center';
    flex?: boolean;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const _FlexChild: React.FC<Props> = ({ children, inject, grow, align, flex }) => {
    /***** RENDER HELPERS *****/
    const flexChildClasses = classNames('FlexChild', {
        'FlexChild--inject': inject,
        [`FlexChild--grow-${grow === true ? 1 : grow}`]: grow,
        [`FlexChild--align-${align}`]: align,
        [`FlexChild--flex`]: flex
    });

    /***** RENDER *****/
    return (
        <PropInjector inject={inject} injectableProps={{ className: flexChildClasses }} injectable={children}>
            <div className={flexChildClasses}>{children}</div>
        </PropInjector>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { _FlexChild };
