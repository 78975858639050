/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React, { useMemo } from 'react';
import { useController } from 'react-hook-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { FormItem } from 'components/Form/FormItem';
import { FormLabel } from 'components/Form/FormLabel';
import Switch from 'components/Switch';
import { Flex } from 'components/Utils/Flex';
import { WrapWithComponent } from 'components/WrapWithComponent';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_SwitchHookFormDefault.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
import type { NFormLabel } from 'components/Form/FormLabel/types';
import type { NSwitch } from 'components/Switch/types';
type SwitchHookFormDefaultProps = Omit<NSwitch.Props, 'onClick' | 'switchvalue'> & {
    validate?: (value: string) => string | undefined;
    name: string;
    defaultValue?: boolean;
    variant?: 'default' | 'label-right';
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const _SwitchHookFormDefault: React.FC<SwitchHookFormDefaultProps> = ({
    label,
    name: fieldName,
    validate,
    defaultValue = true,
    className,
    variant = 'default',
    ...props
}) => {
    /***** HOOKS *****/
    const { field } = useController({ name: fieldName, rules: { validate }, defaultValue });

    const value = field.value as boolean;

    /***** FUNCTIONS *****/
    const handleClick: React.MouseEventHandler<HTMLButtonElement> = () => {
        field.onChange(!value);
    };

    /***** RENDER HELPERS *****/
    const labelProps: Partial<Pick<NFormLabel.Props, 'noMargin'>> = useMemo(() => {
        if (variant === 'label-right') {
            return { noMargin: true };
        }
        return {};
    }, [variant]);

    /***** RENDER *****/
    return (
        <WrapWithComponent component={Flex} wrap={variant === 'label-right'} inject justify="center" align="center" direction="row-reverse">
            <FormItem disabled={props.disabled} name={field.name} className={classNames('SwitchHookFormDefault', className)}>
                <FormLabel htmlFor={field.name || ''} noMargin={labelProps.noMargin}>
                    {label}
                </FormLabel>

                <Switch {...props} onClick={handleClick} switchvalue={value} />
            </FormItem>
        </WrapWithComponent>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default _SwitchHookFormDefault;
