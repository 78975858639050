import { pushNotification } from 'components/Toast/functions';
import { API } from 'utilities/api/email';
import { createPopup, getDataFromSuccessResponse, getErrorFromFailResponse, getMetaFromSuccessResponse } from 'utilities/methods/commonActions';

import store from 'store/store';
import { API as SERVICES } from 'utilities/api/services';
import {
    EMAIL_ACTIVE_SYNC_FAIL,
    EMAIL_ACTIVE_SYNC_REQUEST,
    EMAIL_ACTIVE_SYNC_SUCCESS,
    EMAIL_ACTIVE_SYNC_TOGGLE_FAIL,
    EMAIL_ACTIVE_SYNC_TOGGLE_REQUEST,
    EMAIL_ACTIVE_SYNC_TOGGLE_SUCCESS,
    EMAIL_ALIASES_DELETE_FAIL,
    EMAIL_ALIASES_DELETE_REQUEST,
    EMAIL_ALIASES_DELETE_SUCCESS,
    EMAIL_ALIASES_FAIL,
    EMAIL_ALIASES_LIST_FAIL,
    EMAIL_ALIASES_LIST_REQUEST,
    EMAIL_ALIASES_LIST_SUCCESS,
    EMAIL_ALIASES_REQUEST,
    EMAIL_ALIASES_SUCCESS,
    EMAIL_BILLINGCYCLE_FAIL,
    EMAIL_BILLINGCYCLE_REQUEST,
    EMAIL_BILLINGCYCLE_SUCCESS,
    EMAIL_BILLING_FAIL,
    EMAIL_BILLING_REQUEST,
    EMAIL_BILLING_SUCCESS,
    EMAIL_DISKBLOCK_FAIL,
    EMAIL_DISKBLOCK_LIST_FAIL,
    EMAIL_DISKBLOCK_LIST_REQUEST,
    EMAIL_DISKBLOCK_LIST_SUCCESS,
    EMAIL_DISKBLOCK_PURCHASE_FAIL,
    EMAIL_DISKBLOCK_PURCHASE_REQUEST,
    EMAIL_DISKBLOCK_PURCHASE_SUCCESS,
    EMAIL_DISKBLOCK_REQUEST,
    EMAIL_DISKBLOCK_RESET,
    EMAIL_DISKBLOCK_SUCCESS,
    EMAIL_DOMAIN_GROUP_DETAILS_ERROR,
    EMAIL_DOMAIN_GROUP_DETAILS_REQUEST,
    EMAIL_DOMAIN_GROUP_DETAILS_SUCCESS,
    EMAIL_DOWNGRADE_FAIL,
    EMAIL_DOWNGRADE_REQUEST,
    EMAIL_DOWNGRADE_SUCCESS,
    EMAIL_INFORMATION_MAILBOX_FAIL,
    EMAIL_INFORMATION_MAILBOX_REQUEST,
    EMAIL_INFORMATION_MAILBOX_SUCCESS,
    EMAIL_INITIAL_STATE,
    EMAIL_KEEP_FAIL,
    EMAIL_KEEP_REQUEST,
    EMAIL_KEEP_SUCCESS,
    EMAIL_MAILBOX_LIST_FAIL,
    EMAIL_MAILBOX_LIST_MORE_FAIL,
    EMAIL_MAILBOX_LIST_MORE_REQUEST,
    EMAIL_MAILBOX_LIST_MORE_SUCCESS,
    EMAIL_MAILBOX_LIST_REQUEST,
    EMAIL_MAILBOX_LIST_SUCCESS,
    EMAIL_MAILBOX_LOGIN_FAIL,
    EMAIL_MAILBOX_LOGIN_REQUEST,
    EMAIL_MAILBOX_LOGIN_SUCCESS,
    EMAIL_MAILBOX_SEARCH_ERROR,
    EMAIL_MAILBOX_SEARCH_REQUEST,
    EMAIL_MAILBOX_SEARCH_RESET,
    EMAIL_MAILBOX_SEARCH_SUCCESS,
    EMAIL_MAILBOX_SETUP_FAIL,
    EMAIL_MAILBOX_SETUP_REQUEST,
    EMAIL_MAILBOX_SETUP_SUCCESS,
    EMAIL_MAILBOX_UPDATE_FAIL,
    EMAIL_MAILBOX_UPDATE_REQUEST,
    EMAIL_MAILBOX_UPDATE_SUCCESS,
    EMAIL_PRODUCT_LIST_FAIL,
    EMAIL_PRODUCT_LIST_REQUEST,
    EMAIL_PRODUCT_LIST_SUCCESS,
    EMAIL_PURCHASE_MAILBOX_FAIL,
    EMAIL_PURCHASE_MAILBOX_REQUEST,
    EMAIL_PURCHASE_MAILBOX_RESET,
    EMAIL_PURCHASE_MAILBOX_SUCCESS,
    EMAIL_RESTORE_COST_FAIL,
    EMAIL_RESTORE_COST_REQUEST,
    EMAIL_RESTORE_COST_SUCCESS,
    EMAIL_RESTORE_MAILBOX_FAIL,
    EMAIL_RESTORE_MAILBOX_REQUEST,
    EMAIL_RESTORE_MAILBOX_SUCCESS,
    EMAIL_SEND_DETAILS_FAIL,
    EMAIL_SEND_DETAILS_REQUEST,
    EMAIL_SEND_DETAILS_SUCCESS,
    EMAIL_UPGRADE_DETAILS_ERROR,
    EMAIL_UPGRADE_DETAILS_REQUEST,
    EMAIL_UPGRADE_DETAILS_SUCCESS,
    EMAIL_UPGRADE_FAIL,
    EMAIL_UPGRADE_REQUEST,
    EMAIL_UPGRADE_SUCCESS,
    EMAIL_USAGE_ERROR,
    EMAIL_USAGE_REQUEST,
    EMAIL_USAGE_SUCCESS,
    EMAIL_VALIDATE_ERROR,
    EMAIL_VALIDATE_REQUEST,
    EMAIL_VALIDATE_RESET,
    EMAIL_VALIDATE_SUCCESS
} from './actionTypes';
import { handleDefaultSuccessNotification } from 'utilities/methods/commonActions/handleDefaultSuccessNotification';



/**********************************************************************************************************
 *   EMAIL HOSTING INFORMATION
 **********************************************************************************************************/
export const resetEmailState = () => {
    return (dispatch) => {
        dispatch({
            type: EMAIL_INITIAL_STATE
        });
    };
};

export const mailboxSearch = (id, keyword) => {
    return (dispatch) => {
        dispatch({
            type: EMAIL_MAILBOX_SEARCH_REQUEST
        });
        SERVICES.email
            .mailboxSearch(id, keyword)
            .then((response) => {
                const email_mailbox_search_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: EMAIL_MAILBOX_SEARCH_SUCCESS,
                    email_mailbox_search_data
                });
            })
            .catch((error) => {
                const email_mailbox_search_error = getErrorFromFailResponse(error);
                if (error.response) {
                    dispatch({
                        type: EMAIL_MAILBOX_SEARCH_ERROR,
                        email_mailbox_search_error
                    });
                }
            });
    };
};

export const resetMailboxSearch = () => {
    return (dispatch) => {
        dispatch({
            type: EMAIL_MAILBOX_SEARCH_RESET
        });
    };
};

/**********************************************************************************************************
 *   EMAIL HOSTING RECOVERY FEE
 **********************************************************************************************************/
export const getEmailHostingRecoverFee = (id) => {
    const { dispatch } = store;
    dispatch({
        type: EMAIL_RESTORE_COST_REQUEST
    });
    API.email.get
        .restore(id)
        .then((response) => {
            const email_restore_fee_data = getDataFromSuccessResponse(response);
            dispatch({
                type: EMAIL_RESTORE_COST_SUCCESS,
                email_restore_fee_data
            });
        })
        .catch(() => {
            dispatch({
                type: EMAIL_RESTORE_COST_FAIL
            });
        });
};

/**********************************************************************************************************
 *   EMAIL HOSTING MAILBOX INFORMATION
 **********************************************************************************************************/
export const getEmailMailboxInformation = (mailbox) => {
    return (dispatch) => {
        dispatch({
            type: EMAIL_INFORMATION_MAILBOX_REQUEST
        });

        return API.email.get
            .overview(mailbox)
            .then((response) => {
                const email_information_mailbox_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: EMAIL_INFORMATION_MAILBOX_SUCCESS,
                    email_information_mailbox_data
                });
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                dispatch({
                    type: EMAIL_INFORMATION_MAILBOX_FAIL,
                    email_information_mailbox_error: response
                });
            });
    };
};

/**********************************************************************************************************
 *   MAILBOX USAGE
 **********************************************************************************************************/
/**
 * @param {number} mailbox
 */
export const getEmailMailboxUsage = (mailbox) => {
    const { dispatch } = store;

    dispatch({
        type: EMAIL_USAGE_REQUEST
    });

    API.email.get
        .usage(mailbox)
        .then((response) => {
            const email_usage_data = getDataFromSuccessResponse(response);
            dispatch({
                type: EMAIL_USAGE_SUCCESS,
                email_usage_data
            });
        })
        .catch(() => {
            dispatch({
                type: EMAIL_USAGE_ERROR
            });
        });
};

/**********************************************************************************************************
 *   MAILBOX
 **********************************************************************************************************/
export const getMailboxList = (id, params, prevPage) => {
    return (dispatch) => {
        if (params) {
            dispatch({
                type: EMAIL_MAILBOX_LIST_MORE_REQUEST
            });
            API.email.get
                .mailboxes(id, params)
                .then((response) => {
                    const email_mailbox_list_data = getDataFromSuccessResponse(response);
                    const email_mailbox_list_meta = getMetaFromSuccessResponse(response);
                    let new_email_mailbox_list_data = [];

                    if (prevPage && prevPage.length > 0) {
                        new_email_mailbox_list_data = prevPage.concat(email_mailbox_list_data);

                        dispatch({
                            type: EMAIL_MAILBOX_LIST_MORE_SUCCESS,
                            email_mailbox_list_data: Object.assign([], new_email_mailbox_list_data),
                            email_mailbox_list_meta
                        });
                    } else {
                        dispatch({
                            type: EMAIL_MAILBOX_LIST_MORE_SUCCESS,
                            email_mailbox_list_data,
                            email_mailbox_list_meta
                        });
                    }
                })
                .catch((error) => {
                    const response = getErrorFromFailResponse(error);
                    dispatch({
                        type: EMAIL_MAILBOX_LIST_MORE_FAIL,
                        email_mailbox_list_more_error: response
                    });
                });
        } else {
            dispatch({
                type: EMAIL_MAILBOX_LIST_REQUEST
            });
            API.email.get
                .mailboxes(id)
                .then((response) => {
                    const email_mailbox_list_data = getDataFromSuccessResponse(response);
                    const email_mailbox_list_meta = getMetaFromSuccessResponse(response);
                    dispatch({
                        type: EMAIL_MAILBOX_LIST_SUCCESS,
                        email_mailbox_list_data,
                        email_mailbox_list_meta
                    });
                })
                .catch((error) => {
                    const response = getErrorFromFailResponse(error);
                    dispatch({
                        type: EMAIL_MAILBOX_LIST_FAIL,
                        email_mailbox_list_error: response
                    });
                });
        }
    };
};

/**********************************************************************************************************
 *   UPGRADE
 **********************************************************************************************************/
export const keepService = (id, emailid) => {
    return (dispatch) => {
        dispatch({
            type: EMAIL_KEEP_REQUEST
        });
        SERVICES.service.service_id.cancellation
            .DELETE(id)
            .then((response) => {
                handleDefaultSuccessNotification(response);
                dispatch({
                    type: EMAIL_KEEP_SUCCESS,
                    email_keep_data: response
                });
                dispatch(getMailboxList(emailid));
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                pushNotification(response);
                dispatch({
                    type: EMAIL_KEEP_FAIL,
                    email_keep_error: response
                });
            });
    };
};

/**********************************************************************************************************
 *   UPDATE
 **********************************************************************************************************/
export const updateServiceDetails = (id, attributes) => {
    return (dispatch) => {
        dispatch({
            type: EMAIL_MAILBOX_UPDATE_REQUEST
        });

        API.email.put
            .credentials(id, attributes)
            .then((response) => {
                const email_mailbox_update_data = getDataFromSuccessResponse(response);
                pushNotification(email_mailbox_update_data);
                dispatch({
                    type: EMAIL_MAILBOX_UPDATE_SUCCESS,
                    email_mailbox_update_data
                });
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                pushNotification(response);
                dispatch({
                    type: EMAIL_MAILBOX_UPDATE_FAIL,
                    email_mailbox_update_error: response
                });
            });
    };
};

/**********************************************************************************************************
 *   DOWNGRADE
 **********************************************************************************************************/
export const downgradeEmailService = (id, attributes) => {
    return (dispatch) => {
        dispatch({
            type: EMAIL_DOWNGRADE_REQUEST
        });
        API.email.post
            .downgrade(id, attributes)
            .then((response) => {
                const email_downgrade_data = getDataFromSuccessResponse(response);
                pushNotification(getDataFromSuccessResponse(response));
                dispatch({
                    type: EMAIL_DOWNGRADE_SUCCESS,
                    email_downgrade_data
                });
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                pushNotification(getErrorFromFailResponse(error));
                dispatch({
                    type: EMAIL_DOWNGRADE_FAIL,
                    email_downgrade_error: response
                });
            });
    };
};

/**********************************************************************************************************
 *   UPGRADE
 **********************************************************************************************************/
export const upgradeEmailService = (id, attributes) => {
    return (dispatch) => {
        dispatch({
            type: EMAIL_UPGRADE_REQUEST
        });
        API.email.post
            .upgrade(id, attributes)
            .then((response) => {
                const email_upgrade_data = getDataFromSuccessResponse(response);
                pushNotification(getDataFromSuccessResponse(response));
                dispatch({
                    type: EMAIL_UPGRADE_SUCCESS,
                    email_upgrade_data
                });
            })
            .catch((error) => {
                const email_upgrade_error = getErrorFromFailResponse(error);
                pushNotification(email_upgrade_error);
                dispatch({
                    type: EMAIL_UPGRADE_FAIL,
                    email_upgrade_error
                });
            });
    };
};

export const upgradeDetails = (id, attributes) => {
    return (dispatch) => {
        dispatch({
            type: EMAIL_UPGRADE_DETAILS_REQUEST
        });
        API.email.post
            .upgradeDetails(id, attributes)
            .then((response) => {
                const email_upgrade_details_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: EMAIL_UPGRADE_DETAILS_SUCCESS,
                    email_upgrade_details_data
                });
            })
            .catch((error) => {
                const email_upgrade_details_error = getErrorFromFailResponse(error);
                pushNotification(email_upgrade_details_error);
                dispatch({
                    type: EMAIL_UPGRADE_DETAILS_ERROR,
                    email_upgrade_details_error
                });
            });
    };
};

/**********************************************************************************************************
 *   DISK BLOCKS
 **********************************************************************************************************/
export const resetDiskBlockInfo = () => {
    return (dispatch) => {
        dispatch({
            type: EMAIL_DISKBLOCK_RESET
        });
    };
};

export const getDiskBlockInfo = (id) => {
    return (dispatch) => {
        dispatch({
            type: EMAIL_DISKBLOCK_LIST_REQUEST
        });
        API.email.get
            .diskblocks(id)
            .then((response) => {
                const email_diskblock_list_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: EMAIL_DISKBLOCK_LIST_SUCCESS,
                    email_diskblock_list_data
                });
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                dispatch({
                    type: EMAIL_DISKBLOCK_LIST_FAIL,
                    email_diskblock_list_error: response
                });
            });
    };
};

export const purchaseDiskBlock = (id, diskblock, attributes) => {
    return (dispatch) => {
        dispatch({
            type: EMAIL_DISKBLOCK_PURCHASE_REQUEST
        });
        API.email.post.diskblocks
            .add(id, diskblock, attributes)
            .then((response) => {
                const email_diskblock_purchase_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: EMAIL_DISKBLOCK_PURCHASE_SUCCESS,
                    email_diskblock_purchase_data
                });
                dispatch(getDiskBlockInfo(id));
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                dispatch({
                    type: EMAIL_DISKBLOCK_PURCHASE_FAIL,
                    email_diskblock_purchase_error: response
                });
            });
    };
};

export const removeDiskBlock = (id, attributes) => {
    return (dispatch) => {
        dispatch({
            type: EMAIL_DISKBLOCK_REQUEST
        });
        SERVICES.service.post
            .cancel(id, attributes)
            .then((response) => {
                const email_diskblock_data = getDataFromSuccessResponse(response);
                pushNotification(email_diskblock_data);
                dispatch({
                    type: EMAIL_DISKBLOCK_SUCCESS,
                    email_diskblock_data
                });
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                pushNotification(response);
                dispatch({
                    type: EMAIL_DISKBLOCK_FAIL,
                    email_diskblock_error: response
                });
            });
    };
};

export const cancelRemoveDiskBlock = (id, diskblock) => {
    return (dispatch) => {
        dispatch({
            type: EMAIL_DISKBLOCK_REQUEST
        });
        API.email.post.diskblocks
            .cancel(id, diskblock)
            .then((response) => {
                const email_diskblock_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: EMAIL_DISKBLOCK_SUCCESS,
                    email_diskblock_data
                });
                dispatch(getDiskBlockInfo(id));
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                dispatch({
                    type: EMAIL_DISKBLOCK_FAIL,
                    email_diskblock_error: response
                });
            });
    };
};

/**********************************************************************************************************
 *   BILLING CYCLE
 **********************************************************************************************************/
export const getEmailBillingCycles = (id) => {
    return (dispatch) => {
        dispatch({
            type: EMAIL_BILLINGCYCLE_REQUEST
        });
        API.email.get.billing
            .pricing(id)
            .then((response) => {
                const email_billingcycle_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: EMAIL_BILLINGCYCLE_SUCCESS,
                    email_billingcycle_data
                });
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                dispatch({
                    type: EMAIL_BILLINGCYCLE_FAIL,
                    email_billingcycle_error: response
                });
            });
    };
};

export const updateEmailBillingCycle = (id, attributes) => {
    return (dispatch) => {
        dispatch({
            type: EMAIL_BILLING_REQUEST
        });
        API.email.post
            .billing(id, attributes)
            .then((response) => {
                const email_billing_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: EMAIL_BILLING_SUCCESS,
                    email_billing_data
                });
            })
            .catch((error) => {
                const email_billing_error = getErrorFromFailResponse(error);
                pushNotification(email_billing_error);
                dispatch({
                    type: EMAIL_BILLING_FAIL,
                    email_billing_error
                });
            });
    };
};

/**********************************************************************************************************
 *   MAILBOX LOGIN
 **********************************************************************************************************/
export const mailboxLogin = (mailbox) => {
    return (dispatch) => {
        const { goToTargetUrl, closePopup } = createPopup();

        dispatch({
            type: EMAIL_MAILBOX_LOGIN_REQUEST
        });

        API.email.get
            .credentials(mailbox)
            .then((response) => {
                const email_mailbox_login_data = getDataFromSuccessResponse(response);

                dispatch({
                    type: EMAIL_MAILBOX_LOGIN_SUCCESS,
                    email_mailbox_login_data
                });

                goToTargetUrl(email_mailbox_login_data.attributes.action);
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);

                pushNotification(response);
                dispatch({
                    type: EMAIL_MAILBOX_LOGIN_FAIL,
                    email_mailbox_login_error: response
                });

                closePopup();
            });
    };
};

/**********************************************************************************************************
 *   HOSTING DETAILS
 **********************************************************************************************************/
export const sendServiceDetails = (id) => {
    return (dispatch) => {
        dispatch({
            type: EMAIL_SEND_DETAILS_REQUEST
        });
        API.email.post
            .send(id)
            .then((response) => {
                const email_send_details_data = getDataFromSuccessResponse(response);
                pushNotification(getDataFromSuccessResponse(response));
                dispatch({
                    type: EMAIL_SEND_DETAILS_SUCCESS,
                    email_send_details_data
                });
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                pushNotification(response);
                dispatch({
                    type: EMAIL_SEND_DETAILS_FAIL,
                    email_send_details_error: response
                });
            });
    };
};

/**********************************************************************************************************
 *   HOSTING DETAILS
 **********************************************************************************************************/
export const getEmailHostingDomainGroupDetails = (id) => {
    return (dispatch) => {
        dispatch({
            type: EMAIL_DOMAIN_GROUP_DETAILS_REQUEST
        });
        return API.email.get
            .domainGroupDetail(id)
            .then((response) => {
                const email_domain_group_details_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: EMAIL_DOMAIN_GROUP_DETAILS_SUCCESS,
                    email_domain_group_details_data
                });
            })
            .catch(() => {
                dispatch({
                    type: EMAIL_DOMAIN_GROUP_DETAILS_ERROR
                });
            });
    };
};

/**********************************************************************************************************
 *   EMAIL HOSTING PRODUCTS
 **********************************************************************************************************/
export const getEmailProducts = () => {
    return (dispatch) => {
        dispatch({
            type: EMAIL_PRODUCT_LIST_REQUEST
        });
        API.email.get
            .product()
            .then((response) => {
                const email_product_list_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: EMAIL_PRODUCT_LIST_SUCCESS,
                    email_product_list_data
                });
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                dispatch({
                    type: EMAIL_PRODUCT_LIST_FAIL,
                    email_product_list_error: response
                });
            });
    };
};

/**********************************************************************************************************
 *   PURCHASE MAILBOX
 **********************************************************************************************************/
export const purchaseMailboxReset = () => {
    return (dispatch) => {
        dispatch({
            type: EMAIL_PURCHASE_MAILBOX_RESET
        });
    };
};

export const purchaseMailbox = (payload) => {
    return (dispatch) => {
        dispatch({
            type: EMAIL_PURCHASE_MAILBOX_REQUEST
        });
        API.email.post
            .mailbox(payload)
            .then((response) => {
                const email_purchase_mailbox_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: EMAIL_PURCHASE_MAILBOX_SUCCESS,
                    email_purchase_mailbox_data
                });
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                pushNotification(response);
                dispatch({
                    type: EMAIL_PURCHASE_MAILBOX_FAIL,
                    email_purchase_mailbox_error: response
                });
            });
    };
};

/**********************************************************************************************************
 *   SETUP MAILBOX
 **********************************************************************************************************/
export const setupMailbox = (id, attributes, options) => {
    return (dispatch) => {
        dispatch({
            type: EMAIL_MAILBOX_SETUP_REQUEST
        });
        API.email.post
            .setup(id, attributes)
            .then((response) => {
                const email_mailbox_setup_data = getDataFromSuccessResponse(response);
                pushNotification(getDataFromSuccessResponse(response));
                dispatch({
                    type: EMAIL_MAILBOX_SETUP_SUCCESS,
                    email_mailbox_setup_data
                });

                options?.successCallback?.();
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                pushNotification(response);
                dispatch({
                    type: EMAIL_MAILBOX_SETUP_FAIL,
                    email_mailbox_setup_error: response
                });

                options?.errorCallback?.();
            });
    };
};

/**********************************************************************************************************
 *   ALIASES
 **********************************************************************************************************/
export const getAliasList = (id) => {
    return (dispatch) => {
        dispatch({
            type: EMAIL_ALIASES_LIST_REQUEST
        });
        API.email.get
            .aliases(id)
            .then((response) => {
                const email_aliases_list_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: EMAIL_ALIASES_LIST_SUCCESS,
                    email_aliases_list_data
                });
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                dispatch({
                    type: EMAIL_ALIASES_LIST_FAIL,
                    email_aliases_list_error: response
                });
            });
    };
};

export const addAlias = (id, attributes) => {
    return (dispatch) => {
        dispatch({
            type: EMAIL_ALIASES_REQUEST
        });
        API.email.post
            .alias(id, attributes)
            .then((response) => {
                const email_aliases_data = getDataFromSuccessResponse(response);
                pushNotification(getDataFromSuccessResponse(response));
                dispatch({
                    type: EMAIL_ALIASES_SUCCESS,
                    email_aliases_data
                });
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                pushNotification(response);
                dispatch({
                    type: EMAIL_ALIASES_FAIL,
                    email_aliases_error: response
                });
            });
    };
};

export const removeAlias = (id, attributes) => {
    return (dispatch) => {
        dispatch({
            type: EMAIL_ALIASES_DELETE_REQUEST
        });
        API.email.post
            .aliasDelete(id, attributes)
            .then((response) => {
                const email_aliases_delete_data = getDataFromSuccessResponse(response);
                pushNotification(email_aliases_delete_data);
                dispatch({
                    type: EMAIL_ALIASES_DELETE_SUCCESS,
                    email_aliases_delete_data
                });
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                pushNotification(response);
                dispatch({
                    type: EMAIL_ALIASES_DELETE_FAIL,
                    email_aliases_delete_error: response
                });
            });
    };
};

/**********************************************************************************************************
 *   MAILBOX LOGIN
 **********************************************************************************************************/
export const getActiveSync = (id) => {
    return (dispatch) => {
        dispatch({
            type: EMAIL_ACTIVE_SYNC_REQUEST
        });
        API.email.get
            .activeSync(id)
            .then((response) => {
                const email_active_sync_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: EMAIL_ACTIVE_SYNC_SUCCESS,
                    email_active_sync_data
                });
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                dispatch({
                    type: EMAIL_ACTIVE_SYNC_FAIL,
                    email_active_sync_error: response
                });
            });
    };
};

export const toggleActiveSync = (id, attributes) => {
    return (dispatch) => {
        dispatch({
            type: EMAIL_ACTIVE_SYNC_TOGGLE_REQUEST
        });
        API.email.put
            .activeSync(id, attributes)
            .then((response) => {
                const email_active_sync_toggle_data = getDataFromSuccessResponse(response);
                pushNotification(email_active_sync_toggle_data);
                dispatch({
                    type: EMAIL_ACTIVE_SYNC_TOGGLE_SUCCESS,
                    email_active_sync_toggle_data
                });
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                pushNotification(response);
                dispatch({
                    type: EMAIL_ACTIVE_SYNC_TOGGLE_FAIL,
                    email_active_sync_toggle_error: response
                });
            });
    };
};

/**********************************************************************************************************
 *   MAILBOX RESTORE
 **********************************************************************************************************/
/**
 *
 * @param id{number}
 * @returns {(function(*): void)|*}
 */
export const restoreAxigenMailbox = (id) => {
    return (dispatch) => {
        dispatch({
            type: EMAIL_RESTORE_MAILBOX_REQUEST
        });
        API.email.post
            .restore(id)
            .then((response) => {
                const email_restore_mailbox_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: EMAIL_RESTORE_MAILBOX_SUCCESS,
                    email_restore_mailbox_data
                });
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                pushNotification(response);
                dispatch({
                    type: EMAIL_RESTORE_MAILBOX_FAIL
                });
            });
    };
};

/**********************************************************************************************************
 *   MAILBOX EMAIL ADDRESS VALIDATE
 **********************************************************************************************************/
export const validateEmailAddress = (emailAddress, uniqueKey) => {
    return (dispatch) => {
        dispatch({
            type: EMAIL_VALIDATE_REQUEST,
            uniqueKey
        });
        API.email.post
            .validate(emailAddress)
            .then((response) => {
                const uniqueKeyData = getDataFromSuccessResponse(response);
                dispatch({
                    type: EMAIL_VALIDATE_SUCCESS,
                    uniqueKey,
                    uniqueKeyData
                });
            })
            .catch((error) => {
                const uniqueKeyError = getErrorFromFailResponse(error);
                dispatch({
                    type: EMAIL_VALIDATE_ERROR,
                    uniqueKey,
                    uniqueKeyError
                });
            });
    };
};

export const validateEmailAddressReset = (uniqueKey) => {
    return (dispatch) => {
        dispatch({
            type: EMAIL_VALIDATE_RESET,
            uniqueKey
        });
    };
};
