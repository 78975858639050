/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { getRouteApi } from '@tanstack/react-router';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { CustomDropdown } from 'components/Dropdowns/CustomDropdown';
import SelectOptions from 'components/Dropdowns/SelectDropdown/Options';
import Icons from 'components/Icons';
import SolidTag from 'components/Tags/SolidTag';
import Title from 'components/Title';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { useTppDomainQuery } from 'containers/domain/tppDomains/queries/useTppDomainQuery';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
const LegacyDomainsIdRoute = getRouteApi('/my-services/domains/legacy/$legacyDomainId');

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const LegacyDomainsTitle = () => {
    /***** HOOKS *****/
    const { legacyDomainId } = LegacyDomainsIdRoute.useParams();
    const navigate = LegacyDomainsIdRoute.useNavigate();

    /***** QUERIES *****/
    const { data: tpp_domain_data, isLoading: isTppDomainLoading } = useTppDomainQuery(legacyDomainId);

    /***** RENDER *****/
    return (
        <Title
            isLoading={isTppDomainLoading}
            serviceTitle={
                <div className="tppDomain__serviceTitle">
                    <Text size--xl black>
                        {tpp_domain_data?.attributes?.domain || ''}
                    </Text>
                    <div className="tppDomain__serviceTitleLegacy">
                        <SolidTag color="legacy">Legacy</SolidTag>
                        {/* TEMP: remove until we have FAQ's */}
                        {/* <Anchor href={legacyDomainsFaqLink} target="_blank">
                        What does this mean?
                    </Anchor> */}
                    </div>
                </div>
            }
            serviceType="legacy-domain"
            action={[
                <CustomDropdown
                    className="TitleShared__singleDropdown"
                    label={({ labelRef, dropdownTitleOnClick }) => (
                        <button className="TitleShared__singleDropdownButton" ref={labelRef} type="button" onClick={dropdownTitleOnClick}>
                            Domain Names Help
                            <Icons.ChevronDown />
                        </button>
                    )}
                    key="dropdown"
                    render={() => (
                        <SelectOptions
                            options={[
                                {
                                    label: 'Domain Name Help Articles',
                                    onSelect: () => {
                                        navigate({
                                            to: '/support/support-centre',
                                            search: {
                                                categories: 'products, domain-name-products'
                                            }
                                        });
                                    }
                                }
                                // TEMP: disable as don't have time. Will do later
                                // {
                                //     label: 'Submit a Technical Support eTicket for this Service',
                                //     onSelect: () => history.push(getTicketSubmitUrl({ department: 'technical-support', service: id }))
                                // },
                                // {
                                //     label: 'Submit a Billing eTicket for this Service',
                                //     onSelect: () => history.push(getTicketSubmitUrl({ department: 'accounts-billing', service: id }))
                                // }
                            ]}
                        />
                    )}
                />
            ]}
        />
    );
};
