import { SolidButton } from 'components/Buttons/SolidButton';
import { NXTooltip } from 'components/NXTooltip';
import { Flex } from 'components/Utils/Flex';
import Text from 'components/Utils/Text';
import { useOrderingFormContext } from 'containers/orderingForm/contexts/useOrderingFormContext';
import { ExclamationMark } from 'containers/orderingForm/exclamationMarkIcon';
import { IntaserveFormInput } from 'containers/orderingForm/orderingFormInputs';
import { FieldValidation } from 'containers/orderingForm/orderingFormInputs/fieldValidation';
import type { EditFormValues, Errors, Register, Watch } from 'containers/orderingForm/orderingFormInputs/types';
import { eppValidationMessage } from 'containers/orderingForm/utils';
import { useEffect } from 'react';
import { NXQuery } from 'utilities/query';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type EppFormFields = React.FC<EppFormFieldsProps>;
type EppFormFieldsProps = {
    register: Register<EditFormValues>;
    watch: Watch<EditFormValues>;
    errors: Errors<EditFormValues>;
    setSkipEppForNow: React.Dispatch<React.SetStateAction<boolean>>;
    skipEppForNow: boolean;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const EppFormFields: EppFormFields = ({ register, watch, errors, setSkipEppForNow, skipEppForNow }) => {
    /***** HOOKS *****/
    const { currentItem } = useOrderingFormContext();

    const eppFieldValue = watch('epp');

    const eppValidationParams = {
        domain: currentItem?.name,
        eppCode: eppFieldValue,
    };

    /***** QUERIES *****/
    const { data: epp_validation_data, isLoading: isEppValidationDataLoading } = NXQuery.domain.eppValidation.useQuery(eppValidationParams);

    const validationResult = eppValidationMessage(epp_validation_data);

    useEffect(() => {
        if (skipEppForNow) setSkipEppForNow(false);
    }, [eppFieldValue]);

    /***** RENDER *****/
    return (
        <>
            {/* Eligibility fields */}
            <Flex direction="column" gap={4} className="editItemConfig__eligibilityForm">
                <Text size--xm bold>
                    EPP Code
                </Text>
                <Flex direction="column" className="editItemConfig__eligibilityFormInfoNotice">
                    <Flex align="center">
                        <ExclamationMark size={15} />
                        <Text size--xm bold>
                            Transfer Password Required
                        </Text>
                    </Flex>
                    <Text size--s>
                        Every domain name has a unique EPP key or domain password that you will need to enter during the ordering process
                    </Text>

                    <NXTooltip
                        title={`You can obtain the EPP code (sometimes called "domain password") from the registrar your domain name is currently
                                registered with`}
                        className=""
                    >
                        <div>
                            <Text lead--none className="editItemConfig__eligibilityFormInfoNoticeAnchor">
                                Where do I find this code?
                            </Text>
                        </div>
                    </NXTooltip>
                </Flex>
                <Flex direction="column" gap={1}>
                    <Flex direction="column">
                        <IntaserveFormInput.HookForm.Text
                            register={register}
                            name="epp"
                            placeholder="EPP Code"
                            label="EPP Code"
                            validationErrors={errors}
                            errorBorder={validationResult?.isSuccess === false}
                            successBorder={validationResult?.isSuccess}
                        />
                    </Flex>
                    <FieldValidation
                        isError={validationResult?.isSuccess === false}
                        isSuccess={validationResult?.isSuccess}
                        isLoading={isEppValidationDataLoading}
                        message={validationResult?.message}
                    />
                </Flex>
                {epp_validation_data?.reason === 'epp_invalid' && (
                    <Flex direction="column" className="editItemConfig__eppSkipForNowNotice">
                        <Flex align="center">
                            <Text size--xm bold>
                                Making sure your EPP code is correct
                            </Text>
                        </Flex>
                        <Text size--s>
                            Be sure to copy and paste your EPP code to avoid adding or missing any characters, otherwise you can proceed with the
                            transfer and correct the EPP code later.
                        </Text>
                        <SolidButton
                            type="onClick"
                            onClick={() => setSkipEppForNow(true)}
                            disabled={skipEppForNow}
                            className="editItemConfig__eppSkipForNowButton"
                        >
                            <Text size--sm bold>
                                {skipEppForNow ? 'Adding later' : 'Skip for now'}
                            </Text>
                        </SolidButton>
                    </Flex>
                )}
            </Flex>
        </>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
