import { useUnregisteredChildPaths } from 'containers/katana/components/dynamicFormFieldRenderer/hooks/useUnregisteredChildPaths';
import { useFormContext, useFormState } from 'react-hook-form';
import { getAllPaths } from 'utilities/methods/getAllPaths/getAllPaths';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export function useFieldStateWithUnregisteredChildren(path: string) {
    /***** HOOKS *****/
    const { getFieldState, getValues } = useFormContext();
    const formState = useFormState();
    const unregisteredChildPaths = useUnregisteredChildPaths();

    /***** RENDER HELPERS *****/
    const formValues = getValues();
    const fieldState = getFieldState(path, formState);
    const allPaths = getAllPaths(formValues);

    const fieldStates = unregisteredChildPaths.map((fieldPath) => {
        const fieldState = getFieldState(fieldPath, formState);
        if (!allPaths.includes(fieldPath)) {
            return { ...fieldState, isTouched: true };
        }
        return fieldState;
    });

    const allFieldStates = [...fieldStates, fieldState].filter((state) => typeof state.error?.message === 'string');

    /***** HOOK RESULTS *****/
    return allFieldStates[0] || { isTouched: false, error: undefined };
}
