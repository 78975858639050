import { TabsContext } from 'components/NXBox/Tabs/contexts/useTabContext';
import { useContext } from 'react';
import invariant from 'tiny-invariant';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export const useRawTabsContext = () => {
    /***** HOOKS *****/
    const context = useContext(TabsContext);

    invariant(context, 'The Tabs context must be used within a TabsProvider');

    /***** HOOK RESULTS *****/
    return context;
};
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
