import { createRoute, redirect } from '@tanstack/react-router';
import { loadAppConfig } from 'App/actions/loadAppConfig';
import AppLoader from 'components/Loaders/App';
import { RootRoute } from 'router/__root';
import { queryClient } from 'store/queryClient';
import { NXQuery } from 'utilities/query';

/**********************************************************************************************************
 *   ROUTE COMPONENT START
 **********************************************************************************************************/
export const GoogleProviderSignupRoute = createRoute({
    getParentRoute: () => RootRoute,
    path: '/user/additional-user/google/signup',
    pendingMs: 0, // ensure appLoader always shows
    pendingComponent: () => <AppLoader fullHeight />,
    staticData: {
        preventLoadAppConfig: true
    },
    async loader({ location: { hash } }) {
        const provider = 'google';
        const urlParams = new URLSearchParams(hash);
        const accessToken = urlParams.get('access_token') as string;
        const additionalUserToken = urlParams.get('state') as string;
        const redirectUrl = '';

        await queryClient.fetchQuery(
            NXQuery.auth.oauth.additionalUserLogin.createQueryOptions({
                provider,
                accessToken,
                redirectUrl,
                token: additionalUserToken
            })
        );

        // Once we have checked our token is valid, we can load the relevant app config and redirect to dashboard
        await loadAppConfig({
            context: 'loader',
            onError: () => redirect({ to: '/login', throw: true })
        });
    }
});
