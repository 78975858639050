import { createRoute, stripSearchParams } from '@tanstack/react-router';
import { fallback, zodValidator } from '@tanstack/zod-adapter';
import { ventraSynMigrationBanners } from 'config/config';
import { MyServicesRoute } from 'containers/services';
import { checkRequiresMigration } from 'containers/ventraSynMigration/action';
import { routerMiddleware } from 'router/utils/middleware';
import { lazyFN } from 'utilities/methods/tanstack/router/lazyFn';
import { z } from 'zod';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
const defaultSearch = {
    record_per_page: 10,
    page: 1,
    search: '',
    sort: 'asc',
    sort_by: 'name'
} as const;

/**********************************************************************************************************
 *   SCHEMA START
 **********************************************************************************************************/
export const myServicesHostingSchema = z.object({
    record_per_page: fallback(z.number(), defaultSearch.record_per_page).default(defaultSearch.record_per_page),
    page: fallback(z.number(), defaultSearch.page).default(defaultSearch.page),
    search: fallback(z.string(), defaultSearch.search).default(defaultSearch.search),
    sort: fallback(z.union([z.literal('asc'), z.literal('desc')]), defaultSearch.sort).default(defaultSearch.sort),
    sort_by: fallback(z.literal('name'), defaultSearch.sort_by).default(defaultSearch.sort_by)
});

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const MyServicesHostingRoute = createRoute({
    getParentRoute: () => MyServicesRoute,
    path: 'hosting',
    staleTime: 0,
    loaderDeps: ({ search }) => ({ search }),
    loader: ({ context: { store, queryClient, NXQuery }, deps }) => {
        if (ventraSynMigrationBanners) {
            if (store.getState().vsm.vsm_requires_migration_status !== 'success') {
                checkRequiresMigration()(store.dispatch);
            }
        }

        queryClient.prefetchQuery(NXQuery.hosting.list.createQueryOptions(deps.search));
    },
    beforeLoad(opts) {
        routerMiddleware.business(this, opts);
        routerMiddleware.authentication('user', opts);
    },
    validateSearch: zodValidator(myServicesHostingSchema),
    search: {
        middlewares: [stripSearchParams(defaultSearch)]
    }
}).lazy(lazyFN(() => import('./hosting.lazy'), 'LazyMyServicesHostingRoute'));
