import { ClickAwayListener } from '@mui/material';
import passwordImg from 'assets/images/account/password.svg';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import RequestLoader from 'components/Loaders/Request';
import { NXTooltip } from 'components/NXTooltip';
import { Flex } from 'components/Utils/Flex';
import Text from 'components/Utils/Text';
import { queryClient } from 'store/queryClient';
import { useBoolean } from 'usehooks-ts';
import { NXQuery } from 'utilities/query';
import './_supportPIN.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const HeaderSupportPIN = () => {
    /***** HOOKS *****/
    const { value: isValueShown, setTrue: showValue, setFalse: hideValue } = useBoolean(false);

    /***** QUERIES ******/
    const { data: support_pin_data, isPending: isSupportCodePending } = NXQuery.account.supportPIN.useQuery(void 0, {
        enabled: isValueShown,
    });
    const prefetchPIN = () => queryClient.prefetchQuery(NXQuery.account.supportPIN.createQueryOptions());

    /***** RENDER *****/
    if (isValueShown) {
        return (
            <ClickAwayListener onClickAway={hideValue}>
                <div className="headerSupportPIN">
                    <Flex align="center" fullHeight fullWidth justify="between" className="headerSupportPIN--active">
                        <Flex align="center" gap={1}>
                            {isSupportCodePending ? (
                                <RequestLoader width={45} />
                            ) : (
                                <Text bold secondary size--custom={15}>
                                    {support_pin_data?.data.code}
                                </Text>
                            )}
                            <NXTooltip title="Quote this number when calling for quick authentication">
                                <PhosphorIcons.Question.Bold size={15} secondary className="headerSupportPIN__tooltip" />
                            </NXTooltip>
                        </Flex>

                        <button onClick={hideValue}>
                            <PhosphorIcons.X.Bold />
                        </button>
                    </Flex>
                </div>
            </ClickAwayListener>
        );
    }

    return (
        <button className="headerSupportPIN" onClick={showValue} onMouseEnter={prefetchPIN}>
            <Flex align="center" fullHeight fullWidth justify="center" className="headerSupportPIN--inactive">
                <img src={passwordImg} alt="PIN symbols" />
                <Text bold secondary size--xss>
                    Support PIN
                </Text>
            </Flex>
        </button>
    );
};
