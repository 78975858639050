import { createRoute, redirect } from '@tanstack/react-router';
import { superUserSuperActionsState } from 'components/StaffMenu/SuperUser/consts';
import { VMaxErrorComponent } from 'containers/vmax/routes/VMaxErrorComponent';
import { RootRoute } from 'router/__root';
import store from 'store/store';

/**********************************************************************************************************
 *   ROUTE START
 ******************************s****************************************************************************/
export const VIPSitesVMAXAccessControlRoute = createRoute({
    getParentRoute: () => RootRoute,
    id: '_vmaxAccessControl',
    path: '',
    beforeLoad() {
        if (store.getState().app.app_user_data?.is_vpn) throw new Error('this route is only available for VMAX users');
        if (!superUserSuperActionsState.state.KATANA_VMAX) throw redirect({ to: '/my-services/vip-sites' });
    },
    errorComponent: VMaxErrorComponent,
});
