import Tooltip from '@mui/material/Tooltip';
import classNames from 'classnames';
import { SolidButton } from 'components/Buttons/SolidButton';
import CheckListItemCircle from 'components/CheckListItemCircle';
import { Flex } from 'components/Utils/Flex';
import { Padding } from 'components/Utils/Padding';
import Text from 'components/Utils/Text';
import { WrapWithComponent } from 'components/WrapWithComponent';
import { isChecklistItemEnabled } from 'containers/katana/modules/launchChecklist/methods';
import { useKatanaChecklistData } from 'containers/katana/modules/launchChecklist/useKatanaChecklistData';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import type { KatanaNamespace } from 'containers/katana/types';
import React, { Fragment } from 'react';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type CheckListItems = React.FC<{
    circleSize?: number;
    serviceID?: KatanaNamespace.ServiceId;
    showItemTitle?: boolean;
    showItemButton?: boolean;
    separateLinePath?: boolean;
    showIconHoverToolTip?: boolean;
}>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Rendered at
 * - `/dashboard`
 * - `/my-services/vip-sites/$katanaServiceId/overview#basic-details`
 */
export const CheckListItems: CheckListItems = ({
    serviceID,
    circleSize = 44,
    showItemTitle = true,
    showItemButton = true,
    separateLinePath = false,
    showIconHoverToolTip = false,
}) => {
    /***** HOOKS *****/
    const launchChecklistData = useKatanaChecklistData({ iconSize: circleSize * 0.6, ...(serviceID ? { serviceID } : {}) });

    /***** QUERIES *****/
    const { data: get_katana_service_data } = katanaQuery.serviceID.service.useQuery(serviceID);

    /***** RENDER HELPERS *****/
    const launch_checklist = get_katana_service_data?.attributes?.launch_checklist ?? [];

    /***** RENDER *****/
    return launchChecklistData.map(({ icon, title, to, checkListKey }, i) => {
        const isCompleted = launch_checklist?.includes?.(checkListKey);
        const isEnabled = isChecklistItemEnabled(launch_checklist, checkListKey);

        const checklistClassNames = classNames('KatanaLaunchChecklist__checklistItem', {
            'KatanaLaunchChecklist__checklistItem--completed': isCompleted,
            'KatanaLaunchChecklist__checklistItem--enabled': isEnabled,
            'KatanaLaunchChecklist__checklistItem--separateLinePath': separateLinePath,
        });

        const checkListStyle = { '--KatanaLaunchChecklist__checklistItem_circleSize': `${circleSize}px` } as React.CSSProperties;

        return (
            <Fragment key={checkListKey}>
                <WrapWithComponent component={Tooltip} wrap={showIconHoverToolTip} title={title} arrow>
                    <div key={title} className={checklistClassNames} style={checkListStyle}>
                        <CheckListItemCircle size={circleSize} checkSize={circleSize * 0.5} checked={isCompleted} noBorder={isCompleted}>
                            {icon}
                        </CheckListItemCircle>
                        {showItemTitle && (
                            <Flex.Child grow>
                                <Padding top={2}>
                                    <Text size--s align--center black semiBold>
                                        {title}
                                    </Text>
                                </Padding>
                            </Flex.Child>
                        )}
                        {!separateLinePath && <div className="KatanaLaunchChecklist__linePath"></div>}
                        {showItemButton && isEnabled && !isCompleted && (
                            <SolidButton.Link to={to} key={title} size="small">
                                Continue
                            </SolidButton.Link>
                        )}
                    </div>
                </WrapWithComponent>
                {separateLinePath && i !== launchChecklistData.length - 1 && <div className="KatanaLaunchChecklist__linePath"></div>}
            </Fragment>
        );
    });
};
