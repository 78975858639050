import './_ModalContent.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type ModalContent = React.FC<{ children: React.ReactNode }>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _ModalContent: ModalContent = ({ children }) => {
    /***** RENDER *****/
    return <div className="ModalContent">{children}</div>;
};
