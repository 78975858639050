import { SUPER_USER_ACTION_ITEM_KEYS } from 'components/StaffMenu/SuperUser/actionKeys';
import { useActiveSuperUserItems } from 'components/StaffMenu/SuperUser/hooks/useActiveSuperUserItems';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export function useInactiveSuperUserItems() {
    /***** HOOKS *****/
    const activeSuperUserItems = useActiveSuperUserItems();

    /***** HOOK RESULTS *****/
    return SUPER_USER_ACTION_ITEM_KEYS.filter((key) => !activeSuperUserItems.includes(key));
}
