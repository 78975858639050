import { PhosphorIcons } from 'components/Icons/Phosphor';
import { useNXToggleWrapperContext } from 'components/NXTable/NXTableContext';
import { Revealer } from 'components/Revealer';
import { createElement, useLayoutEffect, useState } from 'react';
import './_NXTableAccordion.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Mobile Accordion component to handle display of table data
 *
 * The Accordian (used as `<NXTable.Accordian />`) must be used within the ToggleWrapper Context (used as `<NXTable.Accordian.ToggleWrapper />`). This is because
 * The ToggleWrapper uses the index of the accordian to handle which accordian is open and subsequently close other accordians if they were open.
 *
 * A parent `<NXTable>` component is not required as the context is isolated to the ToggleWrapper and Accordion components.
 *
 * The preventClickRefs prop is used to prevent the Accordion from closing when the user clicks on a specific ref. This is useful when you want to allow the user to click on a button or link within the Accordion without closing the Accordion.
 *
 * In addition, the accordions "content" prop can be a React.Node (JSX) or a component (React.FC). If your component does not accept props, then the recommended approach is to pass the function as this reduces the likelihood
 * of the accordion being formatted across multiple lines.
 *
 * @example
 * ```jsx
 *   return (
 *     <NXTable.Accordian.ToggleWrapper>
 *       <NXTable.Accordion index={1} content="My Accordion Content">
 *         My Accordion Body
 *       </NXTable.Accordion>
 *       <NXTable.Accordion index={2} content={<MyComponent someProp={someProp} />}>
 *         My Accordion Body 2
 *       </NXTable.Accordion>
 *       <NXTable.Accordion index={3} content={MyComponent}>
 *          My Accordion Body 3
 *       </NXTable.Accordion>
 *     </NXTable.Accordian.ToggleWrapper>
 *   );
 * ```
 *
 * @type {NAccordian.TAccordion}
 */
const Accordion = ({ index, preventClickRefs, children, content }) => {
    /***** STATE *****/
    const [accordionIsOpen, setAccordionIsOpen] = useState(false);

    /***** HOOKS *****/
    const { activeIndex, initialised, onToggle } = useNXToggleWrapperContext();

    if (!initialised) {
        throw new Error('Accordian context missing! Please wrap the Accordion in a ToggleWrapper.');
    }

    /***** FUNCTIONS *****/
    /**
     * When the user toggles the accordion
     */
    function toggleAccordion(e) {
        /**
         * If any of the clicks happen on the ref or any of the children within the ref, prevent click toggle event from occurring.
         */
        const preventClick = preventClickRefs?.current?.some?.((ref) => ref?.contains?.(e?.target));
        if (preventClick) return;

        const newOpenState = !accordionIsOpen;
        setAccordionIsOpen(newOpenState);
        onToggle?.(index);
    }

    /***** EFFECTS *****/
    /**
     * When the activeKey changes and it's not the same as key close the accordion
     */
    useLayoutEffect(() => {
        if (activeIndex !== index && accordionIsOpen) {
            setAccordionIsOpen(false);
        }
    }, [activeIndex, index, accordionIsOpen]);

    /***** RENDER HELPERS *****/
    const renderContent = () => {
        if (!content) return null;
        if (typeof content === 'function') return createElement(content, {});
        return <>{content}</>;
    };

    /***** RENDER *****/
    return (
        <div className="NXTableAccordion">
            <button className="NXTableAccordion__bodyButton" onClick={toggleAccordion}>
                <div className="NXTableAccordion__body">{children}</div>
                {!!content && <PhosphorIcons.Chevron state={accordionIsOpen ? 'up' : 'down'} />}
            </button>
            <Revealer isOpen={accordionIsOpen && !!content}>
                <div className="NXTableAccordion__content">{renderContent()}</div>
            </Revealer>
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { Accordion };
