export const PROMOTION_INITIAL_STATE = 'promotion/PROMOTION_INITIAL_STATE';

export const PROMOTION_PROMO_CODE_STATUS_RESET = 'promotion/PROMOTION_PROMO_CODE_STATUS_RESET';
export const PROMOTION_PROMO_CODE_STATUS_REQUEST = 'promotion/PROMOTION_PROMO_CODE_STATUS_REQUEST';
export const PROMOTION_PROMO_CODE_STATUS_SUCCESS = 'promotion/PROMOTION_PROMO_CODE_STATUS_SUCCESS';
export const PROMOTION_PROMO_CODE_STATUS_ERROR = 'promotion/PROMOTION_PROMO_CODE_STATUS_ERROR';

export const PROMOTION_CLAIM_FREE_DOMAIN_REQUEST = 'promotion/PROMOTION_CLAIM_FREE_DOMAIN_REQUEST';
export const PROMOTION_CLAIM_FREE_DOMAIN_SUCCESS = 'promotion/PROMOTION_CLAIM_FREE_DOMAIN_SUCCESS';
export const PROMOTION_CLAIM_FREE_DOMAIN_ERROR = 'promotion/PROMOTION_CLAIM_FREE_DOMAIN_ERROR';

export const PROMOTION_RADIX_RENEW_OFFERS_REQUEST = 'promotion/PROMOTION_RADIX_RENEW_OFFERS_REQUEST';
export const PROMOTION_RADIX_RENEW_OFFERS_SUCCESS = 'promotion/PROMOTION_RADIX_RENEW_OFFERS_SUCCESS';
export const PROMOTION_RADIX_RENEW_OFFERS_ERROR = 'promotion/PROMOTION_RADIX_RENEW_OFFERS_ERROR';
