/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import axios from 'axios';

const ventraIPWordpressAxios = axios.create({
    withCredentials: false
});

/**
 * You might get a CORS error if you're trying to access the VentraIP Wordpress API from your local.
 * You can use https://chromewebstore.google.com/detail/allow-cors-access-control/lhobafahddgcelffkeicbaginigeejlf?pli=1 to bypass this.
 */
ventraIPWordpressAxios.defaults.baseURL = 'https://ventraip.com.au/wp-json';

export { ventraIPWordpressAxios };
