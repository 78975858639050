import { useMutation } from '@tanstack/react-query';
import { noop } from 'lodash';
import { API } from 'utilities/api/account';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useMutation() {
    /***** HOOK RESULTS *****/
    return useMutation({
        mutationFn: API.account.POST.subaccount.resendInvitation,
        onSuccess: noop
    });
}

export const resendUserInvitation = Object.freeze({
    useMutation: _useMutation
});
