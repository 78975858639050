import { type AxiosError, type Cancel, isAxiosError, isCancel } from 'axios';
import { pushNotification } from 'components/Toast/functions';
import type { ToastNamespace } from 'components/Toast/types';
import { getMessageFromErrorsData } from 'utilities/methods/commonActions/getMessageFromErrorsData';
import type { DefaultErrorResponse } from 'utilities/methods/types';

type ErrorTracking = {
    lastErrorTime: number;
    timeout?: NodeJS.Timeout;
};

// Map to track recent errors to prevent duplicates
const lastErrors = new Map<DefaultErrorResponse | Cancel | AxiosError, ErrorTracking>();

// Timeout duration in milliseconds to prevent duplicate error notifications
const errorDuplicationTimeout = 2500;

/**
 * Default handling of error response by showing notification
 */
export function handleDefaultErrorNotification(error: unknown, opts?: { scope?: ToastNamespace.Scope }) {
    if (isCancel(error)) {
        return;
    }

    if (lastErrors.has(error)) {
        const lastError = lastErrors.get(error);
        if (lastError) {
            const { lastErrorTime, timeout } = lastError;
            const diff = Date.now() - lastErrorTime;
            if (diff < errorDuplicationTimeout) {
                clearTimeout(timeout);

                const lastErrorTimeout = setTimeout(() => {
                    lastErrors.delete(error);
                }, errorDuplicationTimeout);

                lastErrors.set(error, { lastErrorTime: Date.now(), timeout: lastErrorTimeout });
                return;
            }
        }
    } else {
        lastErrors.set(error, { lastErrorTime: Date.now() });
    }

    if (isAxiosError(error)) {
        pushNotification({ message: error.message, status: error.status || 500 }, 8000, 'global');
        return;
    }

    const message = error?.message || getMessageFromErrorsData(error);
    pushNotification({ ...error, message }, null, opts?.scope);
}
