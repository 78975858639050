/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Padding } from 'components/Utils/Padding';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useNXBoxDefaultPadding } from 'components/NXBox/DefaultPadding/useNXBoxDefaultPadding';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Adds the default 30px of padding that is usually used with boxes
 * @type {React.FC<{
 *      children: React.ReactNode;
 *      paddingOnly?: boolean;
 *      inject?: boolean;
 * }>}
 */
const _NXBoxDefaultPadding = ({ children, paddingOnly, inject }) => {
    /***** HOOKS *****/
    const padding = useNXBoxDefaultPadding();

    /***** RENDER *****/
    return (
        <Padding xy={padding} paddingOnly={paddingOnly} className="NXBoxDefaultPadding" inject={inject}>
            {children}
        </Padding>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

const NXBoxDefaultPadding = Object.assign(_NXBoxDefaultPadding, {
    useDefaultPadding: useNXBoxDefaultPadding
});

export default NXBoxDefaultPadding;
