/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useLocation } from '@tanstack/react-router';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { SolidButton } from 'components/Buttons/SolidButton';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type ButtonComponent = React.FC<{
    searchParam: string;
    children: React.ReactNode;
}>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const RewardCardButton: ButtonComponent = ({ searchParam, children }) => {
    /***** HOOKS *****/
    const location = useLocation();

    /***** RENDER *****/
    return (
        <SolidButton color="primary" to={`${location.pathname}?${searchParam}`}>
            {children}
        </SolidButton>
    );
};
