import { useQuery, useSuspenseQuery, type UseQueryOptions, type UseQueryResult, type UseSuspenseQueryResult } from '@tanstack/react-query';
import { hostingQueryKeys } from 'containers/hosting/queries/queryKeys';
import { queryClient } from 'store/queryClient';
import { API } from 'utilities/api/hosting';
import { createBaseQueryKey } from 'utilities/methods/tanstack/createBaseQueryKey';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type QueryOptionsSubset<TError = Error, TData = DefaultSelectResult> = Omit<
    UseQueryOptions<DefaultSelectResult, TError, TData, ReturnType<typeof hostingQueryKeys.hosting.information>>,
    'queryFn' | 'queryKey'
>;
export type DefaultSelectResult = Awaited<API.hosting.GET.information.nxAxios.ReturnType>;
type Select<TResult> = (data: DefaultSelectResult) => TResult;

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
const _useQuery = <TData = DefaultSelectResult, TError = Error>(
    serviceId: API.hosting.GET.information.nxAxios.Params,
    options?: QueryOptionsSubset<TError, TData>
): UseQueryResult<TData, TError> => {
    /***** QUERIES *****/
    return useQuery({
        queryKey: hostingQueryKeys.hosting.information(serviceId),
        queryFn: () => API.hosting.GET.information.nxAxios(serviceId),
        ...options
    });
};

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
const _useSuspenseQuery = <TData = DefaultSelectResult, TError = Error>(
    serviceId: API.hosting.GET.information.nxAxios.Params,
    options?: QueryOptionsSubset<TError, TData>
): UseSuspenseQueryResult<TData, TError> => {
    /***** QUERIES *****/
    return useSuspenseQuery({
        queryKey: hostingQueryKeys.hosting.information(serviceId),
        queryFn: () => API.hosting.GET.information.nxAxios(serviceId),
        ...options
    });
};

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
const _useSelectQuery = <TResult = DefaultSelectResult>(
    serviceId: API.hosting.GET.information.nxAxios.Params,
    select: Select<TResult> | undefined = undefined
) => {
    return _useQuery(serviceId, { select });
};

/***** FUNCTIONS *****/
/**
 * Invalidates all queries that use this serviceId
 */
const invalidateQueries = (serviceId: API.hosting.GET.information.nxAxios.Params) => {
    queryClient.resetQueries({
        queryKey: createBaseQueryKey(),
        predicate: (query) => query.queryKey.includes(serviceId)
    });
};

/**********************************************************************************************************
 *  HOOK END
 * **********************************************************************************************************/

export const hostingInformation = {
    useQuery: _useQuery,
    useSuspenseQuery: _useSuspenseQuery,
    useSelectQuery: _useSelectQuery,
    invalidate: invalidateQueries,
    createQueryKey: hostingQueryKeys.hosting.information
} as const;
