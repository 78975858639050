import type { TooltipProps } from '@mui/material';
import { ClickAwayListener, Tooltip } from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import { useBoolean } from 'usehooks-ts';
import './_NXTooltip.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type NXTooltipProps = React.FC<TooltipProps & { tooltipClassName?: string }>;

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
const defaultValues: Partial<TooltipProps> = {
    placement: 'top',
    arrow: true
} as const;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Wrapper around MUI's Tooltip that creates custom styling for an on-hover tooltip.
 *
 * Takes text or element *without* an onclick event for good UX.
 */
export const NXTooltip: NXTooltipProps = ({ children, tooltipClassName, ...intrinsicProps }) => {
    /***** HOOKS *****/
    const { value: isTooltipOpen, setTrue: openTooltip, setFalse: closeTooltip, toggle: toggleTooltip } = useBoolean();

    /***** CONSTS *****/
    const mergedProps = { ...defaultValues, ...intrinsicProps };

    /***** RENDER *****/
    return (
        <ClickAwayListener onClickAway={closeTooltip}>
            <Tooltip
                {...mergedProps}
                slotProps={{ tooltip: { className: classNames('NXTooltip', tooltipClassName) } }}
                open={isTooltipOpen}
                onOpen={openTooltip}
                onClose={closeTooltip}
                onClick={toggleTooltip}
            >
                <span>{children}</span>
            </Tooltip>
        </ClickAwayListener>
    );
};
