import { Link } from '@tanstack/react-router';
import { OutlineButton } from 'components/Buttons/OutlineButton';
import { useHostingContext } from 'containers/services/modules/hosting/context';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const RenewServiceButton = () => {
    /***** HOOKS *****/
    const { id } = useHostingContext();

    /***** RENDER *****/
    return (
        <Link to="/my-services/hosting/$serviceId/account" params={{ serviceId: String(id) }} hash="renew">
            <OutlineButton size="medium" color="warning">
                Renew
            </OutlineButton>
        </Link>
    );
};
