import type { AllTokens, ComponentTokens, ExcludePrimitiveToken } from 'config/tokens/base';
import type { ComponentKey, PrimitiveKey, SemanticKey } from 'config/tokens/types';

export function getFlatTokens(componentTokens: Record<string, Record<ComponentKey, PrimitiveKey | SemanticKey>>) {
    const flatComponentTokens: Record<ComponentKey, PrimitiveKey | SemanticKey> = {};
    Object.values(componentTokens).forEach((val) => {
        Object.entries(val).forEach(([key, iVal]) => {
            const actualKey = key as ComponentKey;

            flatComponentTokens[actualKey] = iVal;
        });
    });

    return flatComponentTokens as Record<ComponentTokens, ExcludePrimitiveToken<AllTokens>>;
}

export function getAllNonPrimitiveTokens(tokensObject: any): Record<ExcludePrimitiveToken<AllTokens>, string> {
    return {
        ...tokensObject.semantic,
        ...getFlatTokens(tokensObject.component),
        ...getFlatTokens(tokensObject.brand)
    };
}

export function getAllSemanticAndComponentTokens(tokensObject: any): Record<ExcludePrimitiveToken<AllTokens>, string> {
    return {
        ...tokensObject.semantic,
        ...getFlatTokens(tokensObject.component)
    };
}

export function getAllTokens(tokensObject: any): Record<AllTokens, string> {
    return {
        ...tokensObject.primitive,
        ...tokensObject.semantic,
        ...getFlatTokens(tokensObject.component),
        ...getFlatTokens(tokensObject.brand)
    };
}
