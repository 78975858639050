import { createRoute } from '@tanstack/react-router';
import { VIPSitesVMAXAccessControlRoute } from 'containers/vmax/routes/accessControl';
import { lazyFN } from 'utilities/methods/tanstack/router/lazyFn';
import { z } from 'zod';

const searchSchema = z.object({
    overlay: z.union([z.literal('preview'), z.literal('theme')]).optional(),
    side_panel: z.boolean().optional(),
});

/**********************************************************************************************************
 *   ROUTE START
 ******************************s****************************************************************************/
export const VIPSitesVMAXRoute = createRoute({
    getParentRoute: () => VIPSitesVMAXAccessControlRoute,
    path: 'vmax',
    validateSearch: searchSchema,
}).lazy(lazyFN(() => import('./index.lazy'), 'LazyVIPSitesVMAXRoute'));
