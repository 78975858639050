import { OutlineButton } from 'components/Buttons/OutlineButton';
import { forceResetHostingInfo, resetHostingState } from 'containers/hosting/state/accountActions';
import { resetResellerState } from 'containers/hosting/state/resellerActions';
import { useHostingContext } from 'containers/services/modules/hosting/context';
import { useSelector } from 'react-redux';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const ManageServiceButton = () => {
    /***** SELECTORS *****/
    const hosting_subaccount_username = useSelector((state: any) => state.hosting.hosting_subaccount_username);
    const hosting_information_data = useSelector((state: any) => state.hosting.hosting_information_data);

    /***** HOOKS *****/
    const { id } = useHostingContext();

    /***** FUNCTIONS *****/
    const handleClick = () => {
        forceResetHostingInfo();

        if (hosting_subaccount_username) {
            resetResellerState();
        }

        if (hosting_information_data?.id !== id) {
            resetHostingState();
        }
    };

    /***** RENDER *****/
    return (
        <OutlineButton.Link
            to="/my-services/hosting/$serviceId/account"
            params={{ serviceId: String(id) }}
            hash="overview"
            onClick={handleClick}
            size="medium"
        >
            Manage
        </OutlineButton.Link>
    );
};
