/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Radio } from 'components/Form/Radio';
import SelectOptions from '../../SelectDropdown/Options';
import { _CustomDropdown as CustomDropdown } from '../_CustomDropdown';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_BillingCyclePrefab.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type _BillingCyclePrefab = React.FC<_BillingCyclePrefabProps>;
type _BillingCyclePrefabProps = {
    /**
     * The text of the current selected billing cycle
     */
    selectedNewCycle: string;
    
    /**
     * An array of the options you want inside the drop down.
     */
    options?: Array<Option>;
    isActive: boolean;
    
    /**
     * set the selected option
     */
    setOption: (value: string) => void;
};

type Option = {
    label: React.ReactNode;
    value: string;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _BillingCyclePrefab: _BillingCyclePrefab = ({ isActive, selectedNewCycle, options = [], setOption }) => {
    /***** RENDER *****/
    return (
        <CustomDropdown
            className="BillingCyclePrefab"
            label={({ labelRef, dropdownTitleOnClick }) => (
                <button className="BillingCyclePrefab__title" ref={labelRef} type="button" onClick={() => dropdownTitleOnClick()}>
                    <Radio.Circle isActive={isActive} />
                    {isActive && selectedNewCycle ? `Switch billing cycle to ${selectedNewCycle}` : 'Choose an alternative billing cycle'}
                    <i className="item__icon icon icon-chevron-down" />
                </button>
            )}
            render={(closeDropdown) => (
                <SelectOptions
                    options={options.map(({ label, value }) => ({
                        label,
                        value,
                        onSelect: () => {
                            setOption(value);
                            closeDropdown();
                        },
                        isActive: isActive && selectedNewCycle === value
                    }))}
                />
            )}
        />
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
