/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useEffect } from 'react';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { CollectMissingDetailsContent } from './content';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { checkIsAccountHolder } from 'containers/account/methods';
import { useIsVPN } from 'utilities/hooks/redux/useIsVPN';
import { NXQuery } from 'utilities/query';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type CollectMissingDetailsCheck = React.FC<CollectMissingDetailsCheckProps>;
type CollectMissingDetailsCheckProps = {
    markSelfAsRequired: () => void;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Runs the logic to see whether the user is required to complete the missing details form, and marks itself as "required" if so
 */
export const CollectMissingDetailsCheck: CollectMissingDetailsCheck = ({ markSelfAsRequired }) => {
    /***** HOOKS *****/
    const isVPN = useIsVPN();

    /***** QUERIES *****/
    const { data: check_token_data } = NXQuery.auth.login.checkToken.useSelectSuspenseQuery(void 0, ({ data }) => data.attributes);
    const { data: isAccountHolder } = NXQuery.auth.userData.useSelectSuspenseQuery(void 0, ({ data }) => checkIsAccountHolder(data.attributes));

    /***** DERIVED *****/
    const { is_security_detail_completed, is_user_detail_completed, do_detail_completed_check } = check_token_data;
    
    /***** EFFECTS *****/
    useEffect(() => {
        if (isVPN || !isAccountHolder) return;
        const isMissingDetailsCollectionRequired = do_detail_completed_check && (!is_security_detail_completed || !is_user_detail_completed);
        if (isMissingDetailsCollectionRequired) {
            markSelfAsRequired();
        }
    }, []);

    /***** RENDER *****/
    return null;
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { CollectMissingDetailsContent };
