import { useMutation, useMutationState } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { pushNotification } from 'components/Toast/functions';
import { API } from 'utilities/api/login';
import { getMessageFromErrorsData } from 'utilities/methods/commonActions/getMessageFromErrorsData';
import { parseAxiosErrorWithErrorsResponse } from 'utilities/methods/commonActions/parseAxiosErrorWithErrorsResponse';
import type { NXQueryUtils } from 'utilities/methods/tanstack/types';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type UseMutationOpts<TContext> = NXQueryUtils.TMutationOptions<typeof API.login.twoFactor.requestAlternate, TContext>;

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
const _createMutationKey = () => ['requestAlternativeTwoFactor'] as const;

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
const _useMutation = <TContext>(opts?: UseMutationOpts<TContext>) => {
    return useMutation({
        ...opts,
        mutationKey: _createMutationKey(),
        mutationFn: API.login.twoFactor.requestAlternate,
        onError(error: AxiosError<unknown, unknown>) {
            if (error instanceof AxiosError) {
                const parsed = parseAxiosErrorWithErrorsResponse(error);

                if (parsed.success) {
                    const { status } = parsed.data.response.data;
                    const details = getMessageFromErrorsData(parsed.data.response.data);

                    pushNotification({ details, status }, null, 'global');
                }
            }
        }
    });
};

const _useResendCodeCount = (method: API.login.twoFactor.listMethods.Method) => {
    const resendCodeMutations = useMutationState({
        filters: { mutationKey: _createMutationKey() }
    });

    return resendCodeMutations.filter(({ variables }) => (variables as unknown) === method).length;
};

/***** EXPORTS *****/
export const requestAlternativeTwoFactor = {
    useMutation: _useMutation,

    /**
     * Returns the number of times the resend code mutation has been called for the given method
     */
    useResendCodeCount: _useResendCodeCount,
    createMutationKey: _createMutationKey
};
