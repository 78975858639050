import { createRoute, Outlet } from '@tanstack/react-router';
import { zodValidator } from '@tanstack/zod-adapter';
import { ApplicationAlertBanner } from 'App/components/AppAlertBanner';
import { useBrandStore } from 'config/hooks/useBrandStore';
import { RootRoute } from 'router/__root';
import { routerMiddleware } from 'router/utils/middleware';
import { z } from 'zod';

/**********************************************************************************************************
 *   ROUTE SCHEMA
 **********************************************************************************************************/
const appSchema = z.object({
    // A reference to the route the user wants to access when authenticated.
    ref: z.string().optional(),
});

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const GuestLayoutRoute = createRoute({
    getParentRoute: () => RootRoute,
    id: '_guest',
    beforeLoad(opts) {
        routerMiddleware.business(this, opts);
        routerMiddleware.authentication('guest', opts);
    },
    validateSearch: zodValidator(appSchema),
    component: GuestRouteComponent
});

/**********************************************************************************************************
 *   ROUTE COMPONENT START
 **********************************************************************************************************/
function GuestRouteComponent() {
    const { application } = useBrandStore();

    switch (application) {
        case 'VIPcontrol':
            return (
                <>
                    <ApplicationAlertBanner />
                    <Outlet />
                </>
            );
        case 'Intaserve':
        default:
            return <Outlet />;
    }
}
