import { authenticationQueryKeys } from 'containers/login/queries/queryKeys';
import type React from 'react';
import { createElement } from 'react';
import { API } from 'utilities/api/login';
import { createNXQuery } from 'utilities/methods/tanstack/createQuery';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
export const _checkToken = createNXQuery(() => ({
    queryKey: authenticationQueryKeys.checkToken(),
    queryFn: API.token.check,

    // Provide select for legacy code compatibility
    select: ({ data }) => data,

    // This query should never invalidate since it should be valid for the lifetime of the application
    // and only invalid when the user logs out.
    staleTime: Infinity,

    // This query should not rerun on failure, or on mount if a failure has occurred. This should only ever be called
    // imperatively, so we don't want to retry it.
    retry: false,
    retryOnMount: false
}));

/**
 * Check token may seem like it should be a mutation, but this only returns the users token state based on their cookie,
 * so you'd expect this to return the same thing every time it is called, hence a query.
 *
 * In places where this needs to be called imperatively, you can call `ensureQueryData` on the queryClient.
 */
export const checkToken = Object.assign(_checkToken, {
    /**
     * HOC to pass through check_token_data to a class component during a transition phase from redux to react-query
     */
    withData<P = {}>(component: React.Component<P>) {
        return (props: P) => {
            const { data: app_check_token_data } = _checkToken.useQuery();

            // @ts-ignore
            return createElement(component, {
                ...props,
                app_check_token_data
            });
        };
    }
});
