import { queryOptions, useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { queryClient } from 'store/queryClient';
import { API } from 'utilities/api/services';
import { MINUTE } from 'utilities/consts';
import { createBaseQueryKey } from 'utilities/methods/tanstack/createBaseQueryKey';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Keyword = string | null;

const createQueryKey = (keyword: Keyword) => createBaseQueryKey(['ssl', keyword]);

/***** FUNCTIONS *****/
function createQueryOptions(keyword: Keyword) {
    return queryOptions({
        queryKey: createQueryKey(keyword),
        queryFn: () => API.ssl.search(keyword),
        staleTime: MINUTE * 5
    });
}

function invalidateQueries(keyword: Keyword) {
    return queryClient.invalidateQueries({
        queryKey: createQueryKey(keyword)
    });
}

function prefetchQuery(keyword: Keyword) {
    queryClient.prefetchQuery(createQueryOptions(keyword));
}

function _useSuspenseQuery(keyword: Keyword) {
    return useSuspenseQuery(createQueryOptions(keyword));
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useQuery(keyword: Keyword) {
    return useQuery(createQueryOptions(keyword));
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const sslSearch = Object.freeze({
    useQuery: _useQuery,
    useSuspenseQuery: _useSuspenseQuery,
    invalidateQueries,
    createQueryKey,
    createQueryOptions,
    prefetchQuery
});
