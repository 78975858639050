import { Padding } from 'components/Utils/Padding';
import { RenderForBrands } from 'config/brandRenderer/component';
import { useBrandStore } from 'config/hooks/useBrandStore';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const LoginFooter = () => {
    /***** HOOKS *****/
    const { companyFull, abn, contactUrl, termsUrl, activeBrand, websiteUrl } = useBrandStore();
    const year = new Date().getFullYear();

    /***** RENDER *****/
    return (
        <div className="login__footer">
            <div className="title">
                &copy; {activeBrand === 'ventra' && '2009 - '} {year} {companyFull} ABN {abn}
            </div>
            <nav className="links">
                <ul>
                    <RenderForBrands brands={['ventra', 'intaserve']}>
                        <Padding as="li" top={1} x={2} bottom={6} inject>
                            <a rel="noopener noreferrer" target="_blank" href={websiteUrl}>
                                Website
                            </a>
                        </Padding>
                    </RenderForBrands>
                    <Padding as="li" top={1} x={2} bottom={6} inject>
                        <a rel="noopener noreferrer" target="_blank" href={contactUrl}>
                            Contact
                        </a>
                    </Padding>
                    <Padding as="li" top={1} x={2} bottom={6} inject>
                        <a rel="noopener noreferrer" target="_blank" href={termsUrl}>
                            Terms & Conditions
                        </a>
                    </Padding>
                </ul>
            </nav>
        </div>
    );
};
