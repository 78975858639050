import { queryOptions, useQuery } from '@tanstack/react-query';
import { API } from 'utilities/api/domain';
import { createBaseQueryKey } from 'utilities/methods/tanstack/createBaseQueryKey';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { DomainData } from 'containers/orderingForm/types';

/***** FUNCTIONS *****/
function createQueryKey(domainData: DomainData) {
    return createBaseQueryKey(['domain', domainData]);
}

function createQueryOptions(domainData: DomainData) {
    return queryOptions({
        queryKey: createQueryKey(domainData),
        queryFn: () => API.domain.POST.nxAvailability(domainData),
        enabled: Boolean(domainData.domain),
        select: (data) => {
            if (data?.status === 200) {
                return data.data;
            }
        }
    });
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useQuery(domainData: DomainData) {
    return useQuery(createQueryOptions(domainData));
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const availability = Object.freeze({
    useQuery: _useQuery,
    createQueryKey,
    createQueryOptions
});
