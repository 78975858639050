import { infiniteQueryOptions, useInfiniteQuery } from '@tanstack/react-query';
import { createNestedCategories } from 'containers/support/sections/supportCentre/methods';
import { WP_SUPPORT_CENTRE_API } from 'utilities/api/ventraip-website-wp-support-centre';
import { SUPPORT_CENTRE_API_DEFAULTS } from 'utilities/api/ventraip-website-wp-support-centre/consts';
import type { VentraIPWordpressSupportCentreAPI } from 'utilities/api/ventraip-website-wp-support-centre/types';
import { handleDefaultErrorNotification } from 'utilities/methods/commonActions/handleDefaultErrorNotification';
import { getNextPageParamDefault } from 'utilities/methods/queries/getNextPageParamDefault';
import { createBaseQueryKey } from 'utilities/methods/tanstack/createBaseQueryKey';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type PartialParams = Partial<VentraIPWordpressSupportCentreAPI.SupportArticleParams>;

function createQueryKey(params: PartialParams) {
    return createBaseQueryKey(['ventraip-website-wp-support-centre', 'categories', params]);
}

function createQueryOptions(params: PartialParams) {
    return infiniteQueryOptions({
        queryKey: createQueryKey(params),
        queryFn: async (queryFunctionContext) => {
            const { pageParam = params } = queryFunctionContext;
            return WP_SUPPORT_CENTRE_API.support_article_categories.GET(pageParam).catch((e) => {
                handleDefaultErrorNotification(e);
                throw e;
            });
        },
        initialPageParam: params,
        getNextPageParam: (lastPage) => {
            return getNextPageParamDefault({ lastPage, filters: params });
        },
        staleTime: Infinity,
        select: (data) => {
            const dataFlatMap = data.pages.map((page) => page.data).flat();
            const nestedCategories = createNestedCategories(dataFlatMap);
            return nestedCategories;
        }
    });
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useInfiniteQuery(params: PartialParams = SUPPORT_CENTRE_API_DEFAULTS.support_article_categories) {
    return useInfiniteQuery(createQueryOptions(params));
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export const getCategories = Object.freeze({
    useInfiniteQuery: _useInfiniteQuery
});
