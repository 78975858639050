/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { RouteConfig } from 'router/types';

/**********************************************************************************************************
 *   CONFIG START
 **********************************************************************************************************/
export const intaserveRoutes = {
    /**
     * Special Routes (Must always be enabled)
     */
    '__root__': true,

    '/': true, // redirect handled in component
    '/forgot': {
        active: false,
        redirect: '/_login/login'
    },
    '/reset-password': {
        active: false,
        redirect: '/_login/login'
    },
    '/_login/login': true,
    '/_login/reset-password/$token': true,
    '/_guest': false,
    '/_login': true,
    '/approve-user/$token': true,
    '/move-service/$moveToken': true,
    '/staff-login/$staffLoginToken': true,

    '/activate': true,
    '/dashboard': true,

    '/katana/authorize': false,
    '/katana/authorize/instagram': false,
    '/katana/authorize/instagram_business': false,

    '/account': true,
    '/account/general': {
        active: true,
        hashes: {
            'overview': true,
            'personal-details': true,
            'referral': false,
            'close': true,
            'contacts': true,
            'additional-users': false,
            'org-accounts': true,
            'account-access': false,
            'lleyton-only-fans': false
        }
    },
    '/account/security': {
        active: true,
        hashes: {
            'password': true,
            'information': true,
            'two-factor': true,
            'login-history': true
        }
    },
    '/account/emails': {
        active: true,
        hashes: {
            subscriptions: false,
            view: true
        }
    },

    '/my-services': true,

    '/my-services/domains/direct-au-applications': false,

    '/my-services/domains': true,
    '/my-services/domains/bulk': true,
    '/my-services/domains/bulk/$section': true,
    '/my-services/domains/$domainId': true,
    '/my-services/domains/$domainId/general': {
        active: true,
        hashes: {
            'add-hosting': false,
            'add-vip-site': false,
            'contacts': true,
            'eligibility': true,
            'overview': true,
            'renew': true,
            'registrant-email': true
        }
    },

    '/my-services/domains/$domainId/manage': {
        active: true,
        hashes: {
            'dns': true,
            'child-nameservers': true,
            'dnssec': true
        }
    },

    '/my-services/domains/$domainId/security': {
        active: true,
        hashes: {
            'transfer': true,
            'epp': true,
            'id-protection': true
        }
    },

    '/my-services/domains/$domainId/admin': {
        active: true,
        hashes: {
            move: false,
            cor: true,
            delete: true
        }
    },

    '/my-services/domains/legacy/$legacyDomainId': true,
    '/my-services/domains/legacy/$legacyDomainId/general': {
        active: true,
        hashes: {
            migrate: true,
            overview: true,
            renew: true,
            contacts: true,
            nameservers: true
        }
    },
    '/my-services/domains/legacy/$legacyDomainId/security': {
        active: true,
        hashes: {
            transfer: true,
            epp: true
        }
    },

    '/my-services/hosting': false,
    '/my-services/hosting/$serviceId': false,
    '/my-services/hosting/$serviceId/account': {
        active: false,
        hashes: {
            'overview': true,
            'billing': false,
            'custom-addons': false,
            'custom-hosting': false,
            'details': false,
            'primary-domain': false,
            'purchase': false,
            'upgrade': false,
            'renew': false
        }
    },
    '/my-services/hosting/$serviceId/config': {
        active: false,
        hashes: {
            'ipv6': false,
            'sql': false,
            'ssh': false,
            'temp-url': false
        }
    },
    '/my-services/hosting/$serviceId/security': {
        active: false,
        hashes: {
            autossl: false,
            firewall: false,
            modsec: false,
            permissions: false,
            quarantine: false
        }
    },
    '/my-services/hosting/$serviceId/admin': {
        active: false,
        hashes: {
            cancel: true,
            move: true
        }
    },
    '/my-services/hosting/$serviceId/reseller': {
        active: false,
        hashes: {
            subaccounts: true
        }
    },
    '/my-services/hosting/$serviceId/subaccount/$subaccountId': false,
    '/my-services/hosting/$serviceId/subaccount/$subaccountId/account': {
        active: false,
        hashes: {
            'details': true,
            'primary-domain': true
        }
    },
    '/my-services/hosting/$serviceId/subaccount/$subaccountId/config': {
        active: false,
        hashes: {
            'ipv6': true,
            'sql': true,
            'ssh': true,
            'temp-url': true
        }
    },
    '/my-services/hosting/$serviceId/subaccount/$subaccountId/security': {
        active: false,
        hashes: {
            autossl: true,
            firewall: true,
            modsec: true,
            permissions: true,
            quarantine: true
        }
    },

    '/my-services/vip-sites': false,
    '/my-services/vip-sites/trial/$katanaTrialDomain': false,
    '/my-services/vip-sites/register/$katanaTrialDomain': false,
    '/my-services/vip-sites/external-add-domain-names/$katanaRandomSubdomain': false,
    '/my-services/vip-sites/$katanaServiceId': false,
    '/my-services/vip-sites/$katanaServiceId/overview': {
        active: false,
        hashes: {
            'setup': false,
            'basic-details': false,
            'launch-checklist': false,
            'colours-fonts': false,
            'finalise': false,
            'preset-content': false
        }
    },
    '/my-services/vip-sites/$katanaServiceId/admin': {
        active: false,
        hashes: {
            dns: false,
            reset: false,
            cancel: false
        }
    },
    '/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox': false,
    '/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox/setup': false,
    '/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox/setup/basic-details': false,
    '/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox/setup/contact-information': false,
    '/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox/setup/colours-fonts': false,
    '/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox/setup/template-content': false,
    '/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox/setup/navigation-and-socials': false,
    '/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox/setup/template-content/$childPage': false,
    '/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox/setup/finalise': false,
    '/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox/setup/register-domain': false,
    '/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox/setup/launch': false,
    '/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox/editor': false,
    '/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox/editor/launch': false,
    '/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox/editor/finalise': false,
    '/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox/editor/basic-details': false,
    '/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox/editor/contact-information': false,
    '/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox/editor/colours-fonts': false,
    '/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox/editor/navigation-and-socials': false,
    '/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox/add-section': false,
    '/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox/add-page': false,
    '/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox/editor/template-content': false,
    '/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox/editor/template-content/$childPage': false,
    '/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox/editor/fix-dns': false,
    '/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox/page/$pageId': false,
    '/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox/page/$pageId/add-section': false,

    '/my-services/email-hosting': false,
    '/my-services/email-hosting/$groupId': false,
    '/my-services/email-hosting/$groupId/general': {
        active: false,
        hashes: {
            'mailboxes': false,
            'add-mailbox': false,
            'details': false
        }
    },
    '/my-services/email-hosting/$groupId/tools': {
        active: false,
        hashes: {
            aliases: false
        }
    },
    '/my-services/email-hosting/$groupId/mailbox/$mailboxId': false,
    '/my-services/email-hosting/$groupId/mailbox/$mailboxId/general': {
        active: false,
        hashes: {
            overview: false,
            diskblocks: false,
            upgrade: false,
            billing: false,
            details: false
        }
    },
    '/my-services/email-hosting/$groupId/mailbox/$mailboxId/admin': {
        active: false,
        hashes: {
            activesync: false,
            delete: false
        }
    },

    '/my-services/google': false,
    '/my-services/google/$workspaceId': false,
    '/my-services/google/$workspaceId/manage': {
        active: false,
        hashes: {
            'status': false,
            'overview': false,
            'upgrade': false,
            'billing': false,
            'manage-seats': false,
            'user-accounts': false
        }
    },
    '/my-services/google/$workspaceId/mailbox/$mailboxId/manage': {
        active: false,
        hashes: {
            'user-details': false,
            'delete': false
        }
    },
    '/my-services/google/$workspaceId/admin': {
        active: false,
        hashes: {
            'account-details': false,
            'delete-account': false
        }
    },
    '/my-services/google/$workspaceId/mailbox/$mailboxId': false,
    '/my-services/google/$workspaceId/setup': false,

    '/my-services/microsoft-365': false,
    '/my-services/microsoft-365/$microsoftId': false,
    '/my-services/microsoft-365/$microsoftId/admin': {
        active: false,
        hashes: {
            'delete-subscription': false
        }
    },
    '/my-services/microsoft-365/$microsoftId/manage': {
        active: false,
        hashes: {
            'overview': false,
            'upgrade': false,
            'manage-seats': false
        }
    },

    '/my-services/vps': false,
    '/my-services/vps/$vpsId': false,
    '/my-services/vps/$vpsId/account': {
        active: false,
        hashes: {
            'overview': true,
            'renew': false,
            'upgrade-plan': false,
            'resources': false,
            'server-jobs': false,
            'whm-link': false
        }
    },
    '/my-services/vps/$vpsId/addons': {
        active: false,
        hashes: {
            'resource-addons': false,
            'software-licenses': false
        }
    },
    '/my-services/vps/$vpsId/admin': {
        active: false,
        hashes: {
            'cancel-service': false,
            'format-vps': false
        }
    },
    '/my-services/vps/$vpsId/manage': {
        active: false,
        hashes: {
            'power-management': false,
            'vnc-access': false,
            'vnc-login': false,
            'reverse-dns': false
        }
    },

    '/my-services/ssl': true,
    '/my-services/ssl/$sslId': true,
    '/my-services/ssl/$sslId/manage': {
        active: true,
        hashes: {
            details: true,
            install: false,
            overview: true
        }
    },
    '/my-services/ssl/$sslId/configure': {
        active: true,
        hashes: {
            configuration: true,
            review: true
        }
    },
    '/my-services/ssl/$sslId/validate': {
        active: true,
        hashes: {
            further: true,
            validation: true
        }
    },

    '/vip-rewards': false,

    '/billing': true,
    '/billing/payment-methods': true,
    '/billing/invoices': true,
    '/billing/invoice': true,
    '/billing/invoice/$invoiceId': true,
    '/billing/invoice/$invoiceId/cancel': true,
    '/billing/invoice/$invoiceId/pay': true,
    '/billing/orders': true,
    '/billing/order': true,
    '/billing/order/$orderId': true,
    '/billing/split-invoice/$invoiceId': true,
    '/billing/ventraip-wallet': false,
    '/billing/invoices-merge': true,
    '/billing/sla': false,
    '/billing/services-alignment': false,

    '/support': false,
    '/support/support-centre': false,
    '/support/support-centre/$supportArticle': false,
    '/support/tickets': false,
    '/support/tickets/$section': false,
    '/support/tickets/$section/$param4': false,
    '/support/tickets/$section/$param4/$param5': false,
    '/support/migration-request': false,
    '/support/feedback': false,
    '/support/eTickets': false,

    '/support-centre/$supportArticle': false,

    '/contact-support': true,

    '/synergy-reseller-migration': false,
    '/synergy-reseller-migration/more-info': false,
    '/synergy-reseller-migration/select-services': false,
    '/synergy-reseller-migration/confirm-migration': false,
    '/synergy-reseller-migration/create-account': false,
    '/synergy-reseller-migration/success': false,

    '/my-services/domains/$section/$module/$id': {
        active: false,
        redirect: '/my-services/domains'
    },
    '/my-services/hosting/$section/$module/$id': {
        active: false,
        redirect: '/my-services/hosting'
    },
    '/my-services/hosting/$section/$module/$id/subaccount/$subaccountId': {
        active: false,
        redirect: '/my-services/hosting'
    },
    '/my-services/email-hosting/$section/$module/$id': {
        active: false,
        redirect: ({ id, section }) => `/my-services/email-hosting/${id}/${section}`
    },
    '/my-services/email-hosting/$section/$module/$id/mailbox/$mailboxId': {
        active: false,
        redirect: ({ id, section, mailboxId }) => `/my-services/email-hosting/mailbox/${mailboxId}/${id}/${section}`
    },
    '/my-services/google/$section/$module/$id': {
        active: false,
        redirect: ({ id, section }) => `/my-services/google/${id}/${section}`
    },
    '/my-services/microsoft-365/$section/$module/$id': {
        active: false,
        redirect: ({ id, section }) => `/my-services/microsoft-365/${id}/${section}`
    },
    '/my-services/vps/$section/$module/$id': {
        active: false,
        redirect: ({ id, section }) => `/my-services/vps/${id}/${section}`
    },
    '/my-services/ssl/$section/$module/$id': {
        active: false,
        redirect: ({ id, section }) => `/my-services/ssl/${id}/${section}`
    },
    '/my-services/vip-sites/$section/$module/$id': {
        active: false,
        redirect: ({ id, section }) => `/my-services/vip-sites/${id}/${section}`
    },
    '/home/account/$section/$module': {
        active: false,
        redirect: ({ section }) => `/account/${section}`
    }
} as const satisfies RouteConfig;
