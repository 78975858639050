import sectigoImage from 'assets/images/ssl/sectigo.svg';
import trustwaveimage from 'assets/images/ssl/trustwave.png';
import classNames from 'classnames';
import { CheckBox } from 'components/Form/CheckBox';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import { NXTooltip } from 'components/NXTooltip';
import { Flex } from 'components/Utils/Flex';
import { Padding } from 'components/Utils/Padding';
import Text from 'components/Utils/Text';
import 'containers/services/forms/ssl/_ssl.scss';
import { sslTooltipText } from 'containers/services/forms/ssl/productCard/helperFunctions';
import { QuantitySelector } from 'containers/services/forms/ssl/productCard/quantitySelector';
import type { Product } from 'containers/services/forms/ssl/types';
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type ProductCard = React.FC<ProductCardProps>;
type ProductCardProps = {
    product: any;
    setSelectedProducts: React.Dispatch<React.SetStateAction<Product[]>>;
    selectedProducts: Array<Product>;
    isOrderProcessing: boolean;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const ProductCard: ProductCard = ({ product, setSelectedProducts, selectedProducts, isOrderProcessing }) => {
    const {
        id,
        attributes: { name, description, price, billing_cycle_id },
    } = product;

    /***** HOOKS *****/
    const isMobileOrTablet = useAppViewport(['xs', 'sm', 'md']);

    const isProductSelected = !!selectedProducts.find((product) => {
        return product.id === id;
    });

    const productQty = selectedProducts.filter((product) => {
        return product.id === id;
    }).length;

    const image = name.startsWith('Trustwave') ? trustwaveimage : sectigoImage;

    /***** FUNCTIONS *****/
    const selectProduct = (id: number, price: string) => {
        setSelectedProducts((prevSelected) => {
            if (prevSelected.some((product) => product.id === id)) {
                return prevSelected.filter((product) => product.id !== id);
            }

            return [...prevSelected, { id, price, billing_cycle_id: billing_cycle_id }];
        });
    };

    /***** RENDER *****/
    return (
        <button
            key={`ssl-` + id}
            className={`sslProducts__product ${isProductSelected ? 'selected' : ''}`}
            onClick={(e) => {
                e.preventDefault();
                selectProduct(id, price);
            }}
            disabled={isOrderProcessing}
        >
            <CheckBox
                isChecked={!!isProductSelected}
                disabled={isOrderProcessing}
                className={classNames('sslProducts__checkBox gridItem-1', {
                    'sslProducts__checkBox--selected': !!isProductSelected,
                })}
            />
            {image === 'trustwave' ? (
                <img src={trustwaveimage} alt="Trustwave" className="gridItem-2" />
            ) : (
                <img src={sectigoImage} alt="Sectigo" className="gridItem-2" />
            )}
            <Padding left={isMobileOrTablet ? 0 : 4} right={isMobileOrTablet ? 10 : 0} className="gridItem-3">
                <Flex align="start">
                    <Text size--s semiBold color="S_color_text_default_base" align--left>
                        {name}
                    </Text>
                    <NXTooltip title={sslTooltipText(product?.attributes?.name)} placement="top">
                        <PhosphorIcons.Question />
                    </NXTooltip>
                </Flex>

                <Text size--s align--left className="description">
                    {description}
                </Text>
            </Padding>
            {!!isProductSelected && (
                <QuantitySelector
                    id={id}
                    setSelectedProducts={setSelectedProducts}
                    productQty={productQty}
                    product={product}
                    disabled={isOrderProcessing}
                    className="gridItem-4"
                />
            )}
            <Text size--s semiBold align--right className="price gridItem-5">
                {productQty > 0 ? `$${(price * productQty).toFixed(2)} AUD` : `$${price} AUD`}
            </Text>
        </button>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
