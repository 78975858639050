// Mutations will go here
/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useMutation } from '@tanstack/react-query';
import { useNXQuery } from 'components/NXQueryProvider';
import { API } from 'utilities/api/services';
import { useDefaultQueryClientOptions } from 'utilities/hooks/tanstack-query/useDefaultQueryClientOptions';
import type { NXQueryUtils } from 'utilities/methods/tanstack/types';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Endpoint = typeof API.service.post.unsuspend;
type Params = Parameters<Endpoint>[number];
type UseMutationOpts<TContext> = NXQueryUtils.TMutationOptions<Endpoint, TContext>;

/**********************************************************************************************************
 *   Cancel a service
 **********************************************************************************************************/
const mutationKey = ['useUnsuspendServiceMutation'];

/**
 * Returns a onSuccess function that performs the default onSuccess behaviour from the queryClient
 * and Resets all queries that contain the cancelled serviceId
 */
const useDefaultOnSuccess = () => {
    /***** HOOKS *****/
    const [, { onSuccess }] = useDefaultQueryClientOptions();
    const NXQuery = useNXQuery();

    /***** HOOK RESULTS *****/
    /**
     * Default onSuccess function for unsuspendService mutation. Resets all queries that contain the serviceId
     * and calls the default onSuccess function from the queryClient (toast notification)
     */
    return (response: Awaited<ReturnType<Endpoint>>, serviceId: Params, context: unknown) => {
        onSuccess?.(response, serviceId, context);
        NXQuery.hosting.invalidate(serviceId);
    };
};

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
const _useUnsuspendServiceMutation = <TContext>(options: UseMutationOpts<TContext> = {}) => {
    /***** HOOKS *****/
    const onSuccess = useDefaultOnSuccess();

    /***** QUERIES *****/
    return useMutation({
        mutationKey,
        mutationFn: API.service.post.unsuspend,
        onSuccess,
        ...options
    });
};

export const unsuspendService = Object.freeze({
    useMutation: _useUnsuspendServiceMutation,
    mutationKey,
    defaults: {
        useOnSuccess: useDefaultOnSuccess
    }
});
