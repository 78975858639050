/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useVipRewards } from 'containers/vipRewards/hooks';
import { capitalize } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { orderedTiers, vipRewardsReadableName } from 'containers/vipRewards/consts';

import NewHostingIcon from 'assets/images/vipRewards/business-services.svg';
import NewDomainIcon from 'assets/images/vipRewards/domain-names.svg';
import DomainRenewIcon from 'assets/images/vipRewards/domain-renew.svg';
import HostingRenewIcon from 'assets/images/vipRewards/hosting-renew.svg';

import './_vipRewardsHomeTierBenefitsLightbox.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function VipRewardsHomeTierBenefitsLightbox() {
    /***** HOOKS *****/
    const { currentTier, rewardsTiers } = useVipRewards();

    /***** RENDER HELPERS *****/
    const currentTierIndex = orderedTiers.findIndex((tierName) => tierName === currentTier?.lowercase) ?? -1;

    if (currentTierIndex < 0) return '';

    const currentTierData = rewardsTiers[currentTier?.lowercase];
    const nextTierData = rewardsTiers[orderedTiers[currentTierIndex + 1]];

    /***** RENDER *****/
    return (
        <div className="vipRewardsHomeTierBenefits">
            {[currentTierData, nextTierData].map(
                ({ name, new_hosting_benefit, new_domain_benefit, existing_hosting_benefit, existing_domain_benefit }) => (
                    <div key={name} className={`vipRewardsHomeTierBenefits__card vipRewardsHomeTierBenefits__card--${name.toLowerCase()}`}>
                        <h3 className="vipRewardsHomeTierBenefits__cardHeading">
                            {capitalize(name)} {vipRewardsReadableName} benefits
                        </h3>
                        <div className="vipRewardsHomeTierBenefits__cardBody">
                            {(function () {
                                if (!new_hosting_benefit && !new_domain_benefit && !existing_hosting_benefit && !existing_domain_benefit) {
                                    return (
                                        <Text black>
                                            You have no rewards to redeem. Exclusive discounts and rewards will be unlocked once you reach Bronze
                                            status.
                                        </Text>
                                    );
                                }
                            })()}
                            {new_hosting_benefit !== 0 ? (
                                <div className="vipRewardsHomeTierBenefits__cardRow">
                                    <img src={NewHostingIcon} alt="New web hosting" />
                                    <Text black>
                                        <span>{new_hosting_benefit}% off</span> all new web hosting plans
                                    </Text>
                                </div>
                            ) : (
                                ''
                            )}
                            {new_domain_benefit !== 0 ? (
                                <div className="vipRewardsHomeTierBenefits__cardRow">
                                    <img src={NewDomainIcon} alt="New domain names" />
                                    <Text black>
                                        <span>{new_domain_benefit}% off</span> all new domain name registrations
                                    </Text>
                                </div>
                            ) : (
                                ''
                            )}
                            {existing_hosting_benefit !== 0 ? (
                                <div className="vipRewardsHomeTierBenefits__cardRow">
                                    <img src={HostingRenewIcon} alt="Web hosting renewals" />
                                    <Text black>
                                        <span>{existing_hosting_benefit}% off</span> existing hosting service renewals
                                    </Text>
                                </div>
                            ) : (
                                ''
                            )}
                            {existing_domain_benefit !== 0 ? (
                                <div className="vipRewardsHomeTierBenefits__cardRow">
                                    <img src={DomainRenewIcon} alt="Domain renewals" />
                                    <Text black>
                                        <span>{existing_domain_benefit}% off</span> all domain name renewals
                                    </Text>
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                )
            )}
        </div>
    );
}

/**********************************************************************************************************
 *   COMPONENT end
 **********************************************************************************************************/
export default VipRewardsHomeTierBenefitsLightbox;
