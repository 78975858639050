import { queryOptions, useQuery } from '@tanstack/react-query';
import { katanaQueryKeys } from 'containers/katana/queries/katanaQueryKeys';
import { queryClient } from 'store/queryClient';
import { KATANA_API } from 'utilities/api/katana';
import { handleDefaultErrorNotification } from 'utilities/methods/commonActions/handleDefaultErrorNotification';
import { TruthKeys } from 'utilities/methods/commonActions/Truth';

type Params = Parameters<typeof KATANA_API.katana.site.service_id.meta.palette.GET>[0];

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
function createQueryKey(params: Params) {
    return katanaQueryKeys.katana.meta.palette.colour(params.colour);
}

function createQueryOptions(params: Params) {
    return queryOptions({
        queryKey: createQueryKey(params),
        queryFn: () =>
            KATANA_API.katana.site.service_id.meta.palette.GET(params).catch((e) => {
                handleDefaultErrorNotification(e);
                throw e;
            }),
        enabled: TruthKeys(params),
        staleTime: Infinity,
        select: (data) => {
            if (data?.status === 200) {
                return data.data;
            }
        }
    });
}

function prefetchQuery(params: Params) {
    return queryClient.prefetchQuery(createQueryOptions(params));
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Gets the colours meta data for the site
 */
function _useQuery(params: Params) {
    return useQuery(createQueryOptions(params));
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const palette = Object.freeze({
    useQuery: _useQuery,
    prefetchQuery,
    createQueryKey,
    createQueryOptions
});
