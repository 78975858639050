import type { KatanaNamespace } from 'containers/katana/types';
import type { KatanaAPI } from 'utilities/api/katana/types';

export function sortSectionIntoCorrectOrder<
    TSectionData extends KatanaNamespace.SectionDefinition.SectionData[] | KatanaAPI.Katana.Site.ServiceID.Sections.GET.Sections_200_DataEntryMerged[]
>(sectionData: TSectionData | undefined): TSectionData {
    if (!Array.isArray(sectionData)) {
        return [] as any;
    }
    return sectionData.sort((a, b) => (a.order < b.order ? -1 : 1)) as any;
}
