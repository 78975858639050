import { queryOptions, useQuery } from '@tanstack/react-query';
import { API } from 'utilities/api/email';
import { createBaseQueryKey } from 'utilities/methods/tanstack/createBaseQueryKey';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
function createQueryKey(id: number) {
    return createBaseQueryKey(['email', id, 'usage']);
}

function createQueryOptions(id: number) {
    return queryOptions({
        queryKey: createQueryKey(id),
        queryFn: () => API.email.get.usage(id),
        enabled: Boolean(id),
        select: (data) => {
            if (data.status === 200) {
                return data.data;
            }
        }
    });
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useQuery(id: number) {
    return useQuery(createQueryOptions(id));
}

export const mailboxUsage = Object.freeze({
    useQuery: _useQuery
});
