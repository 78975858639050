import { useMutation } from '@tanstack/react-query';
import { API } from 'utilities/api/hosting';
import type { NXQueryUtils } from 'utilities/methods/tanstack/types';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type UseMutationOpts<TContext> = NXQueryUtils.TMutationOptions<typeof API.hosting.GET.login.cpanel, TContext>;

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
const _useMutation = <TContext>(opts?: UseMutationOpts<TContext>) => {
    return useMutation({
        ...opts,
        mutationFn: API.hosting.GET.login.cpanel
    });
};

/***** EXPORTS *****/
export const loginCpanelMutation = {
    useMutation: _useMutation
};
