import Anchor from 'components/Anchor';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const RestoreServiceModalErrorComponent = () => {
    return (
        <div className="OverlayRestore__error">
            We&apos;re unable to retrieve the requested hosting details. Please try again later. If you believe there may be an issue and require
            assistance, feel free to submit a{' '}
            <Anchor className="support-link" to="/support/tickets/submit">
                support ticket
            </Anchor>
        </div>
    );
};
