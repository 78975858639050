import { useNavigate, useParams } from '@tanstack/react-router';
import { useStore } from '@tanstack/react-store';
import { useRegistrationOrderingFormContext } from 'App/components/templates/contexts/registrationOrderingFormContext/useRegistrationOrderingFormContext';
import { SolidButton } from 'components/Buttons/SolidButton';
import { getInvoiceActionUrl } from 'components/Lightboxes/OverlayLightbox/Components/invoice/invoiceUrlLightbox/methods';
import RequestLoader from 'components/Loaders/Request';
import { pushNotification } from 'components/Toast/functions';
import { useBrandStore } from 'config/hooks/useBrandStore';
import { useGetFilteredAndPaginatedInvoiceListInfiniteBoilerPlate } from 'containers/billing/queries/invoice/useGetFilteredAndPaginatedInvoiceListInfiniteQuery';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import { AU_NZ_STATES } from 'containers/orderingForm/consts';
import { useOrderingFormContext } from 'containers/orderingForm/contexts/useOrderingFormContext';
import 'containers/orderingForm/footer/_footer.scss';
import type { Item } from 'containers/orderingForm/types';
import { getDomainsList } from 'containers/services/action';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { NXQuery } from 'utilities/query';
import { UserPreferences } from 'utilities/UserPreferences';
import { OrderingFormStore } from '../store';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type NextButton = React.FC<NextButtonProps>;
type NextButtonProps = {
    setIsCartListOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

type Payment = {
    id: number;
    module_id?: number;
    name: string;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const NextButton: NextButton = ({ setIsCartListOpen }) => {
    /***** STATE *****/
    const [isAnyDomainIneligible, setIsAnyDomainIneligible] = useState(false);
    const [vipSitesDomain] = UserPreferences.useLocalStorage('CART_VIP_SITES_DOMAIN_NAME');

    /***** HOOKS *****/
    const { cartToken, setCartToken, goForwardPage, goToPage, currentPage, clearState, setCurrentItem } = useOrderingFormContext();
    const { setIsDomainRegistrationFormOpen } = useRegistrationOrderingFormContext();
    const dispatch = useDispatch();
    const { activeBrand } = useBrandStore();
    const navigate = useNavigate();
    const { katanaServiceId } = useParams({ strict: false });
    const isPremiumAcknowledgedMap = useStore(OrderingFormStore, (state) => state.isPremiumAcknowledgedMap);

    /***** QUERIES *****/
    const { mutate: createOrder, isPending: isCreateOrderPending } = NXQuery.orderingForm.cart.cartId.createOrder.useMutation();
    const { mutate: updateUserProfile } = NXQuery.account.updateUserProfile.useMutation();
    const { mutate: addDomainToKatanaService } = katanaQuery.serviceID.domains.add.useMutation();
    const { data: payment_methods_data } = NXQuery.orderingForm.cart.getPaymentMethods.useQuery();
    const { data: app_user_data } = NXQuery.auth.userData.useSelectSuspenseQuery(void 0, ({ data }) => data.attributes);
    const { data: cart_data } = NXQuery.orderingForm.cart.cartId.getCart.useQuery(cartToken);
    const { data: is_user_detail_completed } = NXQuery.auth.login.checkToken.useSelectSuspenseQuery(void 0, ({ data }) => {
        return data.attributes.is_user_detail_completed;
    });
    const productIds = cart_data?.cart?.items?.map((item: Item) => item.id) || [];

    const { data: products_data } = NXQuery.orderingForm.getProducts.useQuery({ ids: productIds });

    // TODO: remove cartToken from being passed to mutation
    const { mutateAsync: mutateEditItemAsync, isPending: isEditItemPending } = NXQuery.orderingForm.cart.cartId.editItem.useMutation(
        cartToken as string
    );

    const eftPayment = payment_methods_data?.find((payment: Payment) => payment.name === 'EFT');
    const BPAYPayment = payment_methods_data?.find((payment: Payment) => payment.name === 'BPAY');
    const applyCredit = activeBrand === 'intaserve';

    const allPremiumDomainsAcknowledged = cart_data?.cart?.items
        ?.filter?.((item) => item.is_premium === true)
        ?.every((item) => {
            return isPremiumAcknowledgedMap[item.uuid] === true;
        });

    /***** EFFECTS *****/
    // Check if any of the domains are ineligible for register
    useEffect(() => {
        const hasIneligibleDomains = cart_data?.cart?.items?.some((item: Item) => {
            const product = products_data?.find(({ id }) => id === item.id);
            const customFieldsLength = product?.attributes?.custom_fields?.length ?? 0;

            return (item.order_type === 'register' && customFieldsLength > 0 && item.custom_fields.length === 0) || item?.epp === 'invalid epp';
        });

        setIsAnyDomainIneligible(!!hasIneligibleDomains);
    }, [cart_data?.cart?.items, products_data]);

    /***** FUNCTIONS *****/
    async function handleOnClick() {
        setIsCartListOpen(false);

        if (currentPage === 'register') {
            if (activeBrand === 'ventra') {
                setCurrentItem(cart_data?.cart?.items?.[0]);
                return goToPage('editItemConfiguration');
            }
            return goToPage('configuration');
        }
        if (currentPage === 'configuration') {
            return goToPage('confirmOrder');
        }

        if (currentPage === 'confirmOrder') {
            const paymentId = eftPayment?.id || BPAYPayment?.id;

            // If a VIPsites domain is selected, update the cart to add the dns preset before creating the order
            const itemToUseForVipSites = vipSitesDomain && cart_data?.cart?.items?.find((cartItem) => cartItem.name === vipSitesDomain);
            if (activeBrand === 'ventra' && katanaServiceId && itemToUseForVipSites) {
                await mutateEditItemAsync([
                    {
                        ...itemToUseForVipSites,
                        domain_dns_preset: {
                            dns_config: 2,
                            name: 'VIPsites'
                        },
                        action: 'edit'
                    }
                ]);
                // No need to catch this, if this request fails we don't want to proceed with the order
            }

            return createOrder(
                { paymentMethodId: paymentId, tokenId: cartToken as string, applyCredit },
                {
                    onSuccess: (response) => {
                        UserPreferences.removeItem('CART_TOKEN');
                        setCartToken(null);
                        getDomainsList()(dispatch);
                        window.dispatchEvent(new Event('cartTokenChanges'));
                        setIsDomainRegistrationFormOpen(false);
                        clearState();

                        pushNotification({ status: 200, details: 'Domains ordered successfully' });
                        if (activeBrand === 'ventra') {
                            // Invalidate invoices list
                            useGetFilteredAndPaginatedInvoiceListInfiniteBoilerPlate.invalidate({ page: 1 });

                            // If one of the domains is being used for the VIPsites express flow then we need to attach that domain to the VIPsite
                            if (katanaServiceId && itemToUseForVipSites) {
                                // Let this happen in the background while we load the pay invoice lightbox
                                addDomainToKatanaService({
                                    serviceID: katanaServiceId,
                                    body: {
                                        domain: vipSitesDomain,
                                        is_active: true
                                    }
                                });
                            }

                            // Update user profile data with formData only if the user is currently missing some details
                            const contactData = cart_data?.cart?.items?.[0]?.domain_contact_fields;

                            if (!is_user_detail_completed && contactData) {
                                const newUserDetails = {
                                    firstname: app_user_data?.firstname || contactData?.firstName || '',
                                    lastname: app_user_data?.lastname || contactData?.lastname || '',
                                    company: app_user_data?.company || contactData?.company_name || '',
                                    address1: app_user_data?.address1 || contactData?.address1 || '',
                                    address2: app_user_data?.address2 || contactData?.address2 || '',
                                    city: app_user_data?.city || contactData?.suburb,
                                    state: app_user_data?.state || AU_NZ_STATES[contactData.state as keyof AU_NZ_STATES],
                                    postcode: app_user_data?.postcode || contactData?.postcode,
                                    country: app_user_data?.country || contactData?.country,
                                    phone: app_user_data?.phone || contactData?.phone
                                };

                                updateUserProfile(newUserDetails);
                            }

                            return navigate({
                                to: getInvoiceActionUrl('pay', response?.data.invoice_id)
                            });
                        }
                    },
                    onError: () => {}
                }
            );
        }

        return goForwardPage();
    }

    /***** RENDER HELPERS *****/
    const isNextButtonDisabled = () => {
        switch (true) {
            case currentPage === 'configuration' &&
                cart_data?.cart?.items.some((item: Item) => Array.isArray(item.domain_contact_fields)) &&
                !is_user_detail_completed &&
                activeBrand === 'ventra':
            case !cart_data || !cart_data?.cart?.items?.length:
            case ['register', 'transfer'].includes(currentPage) === false && isAnyDomainIneligible:
            case isCreateOrderPending:
            case isEditItemPending:
            case currentPage === 'configuration' && !allPremiumDomainsAcknowledged:
                return true;
            default:
                return false;
        }
    };

    const RenderButtonContent = () => {
        switch (true) {
            case isCreateOrderPending || isEditItemPending:
                return <RequestLoader />;
            case currentPage === 'confirmOrder':
                return 'Place Order';

            default:
                return 'Next Step';
        }
    };

    /***** RENDER *****/
    return (
        <SolidButton disabled={isNextButtonDisabled()} onClick={handleOnClick} className="modalFooter__nextButton">
            {RenderButtonContent()}
        </SolidButton>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
