import { Flex } from 'components/Utils/Flex';
import Text from 'components/Utils/Text';
import { isValidElement } from 'react';
import './_NXBoxInfoPair.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type NXBoxInfoPairProps = {
    title: string;
    description: React.ReactNode;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * A key / value type pair which renders the title in grey, and the description below it in black
 */
export const NXBoxInfoPair: React.FC<NXBoxInfoPairProps> = ({ title, description }) => {
    /***** RENDER *****/
    return (
        <Flex className="NXBoxInfoPair" direction="column" gap={0}>
            <Text size--s medium lead--none>
                <h5 className="NXBoxInfoPair__title">{title}</h5>
            </Text>
            {isValidElement(description) ? (
                description
            ) : (
                <Text lead--none className="NXBoxInfoPair__description">
                    {description}
                </Text>
            )}
        </Flex>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
