import { _WrapperBox as WrapperBox } from 'components/Form/WrapperBox/_WrapperBox';
import { useFieldStateWithUnregisteredChildren } from 'containers/katana/components/dynamicFormFieldRenderer/hooks/useFieldStateWithUnregisteredChildren';
import { useKatanaProperty } from 'containers/katana/components/dynamicFormFieldRenderer/PropertyContext/useKatanaProperty';
import { returnErrorAndWarnClass } from 'utilities/methods/form';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Props = {
    children: React.ReactNode;
};
type _WrapperBoxKatanaProperty = React.FC<Props>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _WrapperBoxKatanaProperty: _WrapperBoxKatanaProperty = ({ children }) => {
    /***** HOOKS *****/
    const { path } = useKatanaProperty();
    const { isTouched, error } = useFieldStateWithUnregisteredChildren(path);

    /***** RENDER *****/
    return (
        <WrapperBox
            fullWidth
            // className={wrapperboxClasses}
            error={returnErrorAndWarnClass(isTouched, error && typeof error?.message === 'string', null, null)}
            // isPrepended={!!prepend}
            // disabled={disabled}
            // validatedValid={returnErrorAndWarnClass(isTouched, validated?.valid, null, null)}
            // validatedInvalid={returnErrorAndWarnClass(isTouched, validated && !validated.valid, null, null)}
        >
            {children}
        </WrapperBox>
    );
};
