import { useMutation } from '@tanstack/react-query';
import { API } from 'utilities/api/account';
import { handleDefaultSuccessNotification } from 'utilities/methods/commonActions/handleDefaultSuccessNotification';
import type { NXQueryUtils } from 'utilities/methods/tanstack/types';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type UseMutationOpts<TContext> = NXQueryUtils.TMutationOptions<typeof API.account.PUT.update.user, TContext>;
type Attributes = {
    firstname: string;
    lastname: string;
    company: string;
    email: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    postcode: string;
    country: string;
    phone: string;
};

/**********************************************************************************************************
 *   MUTATION
 **********************************************************************************************************/
const _useMutation = <TContext>(opts?: UseMutationOpts<TContext>) => {
    return useMutation({
        ...opts,
        mutationFn: (attributes: Partial<Attributes>) => API.account.PUT.update.user(attributes),
        onSuccess: (response) => {
            handleDefaultSuccessNotification(response.data);
        }
    });
};

/***** EXPORTS *****/
export const updateUserProfile = {
    useMutation: _useMutation
};
