import { createRoute } from '@tanstack/react-router';
import { lazyFN } from 'utilities/methods/tanstack/router/lazyFn';
import { VIPSitesVMAXRoute } from 'containers/vmax/routes/vmax';

/**********************************************************************************************************
 *   ROUTE START
 ******************************s****************************************************************************/
export const VIPSitesVMAXWebsiteRoute = createRoute({
    getParentRoute: () => VIPSitesVMAXRoute,
    path: 'website'
}).lazy(lazyFN(() => import('./index.lazy'), 'LazyVIPSitesVMAXWebsiteRoute'));
