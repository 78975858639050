import classNames from 'classnames';
import { useAdvancedListContext } from 'components/Form/AdvancedList/context';
import { Flex } from 'components/Utils/Flex';
import { Padding } from 'components/Utils/Padding';
import Text from 'components/Utils/Text';
import { useController } from 'react-hook-form';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type AdvanceListItem = React.FC<{
    children: React.ReactNode;
    value: string;
    displayName: string;
    disabled?: boolean;
    tag?: React.ReactNode;
}>;

/**
 * RHF Advance List Item
 */
export const _AdvanceListItem: AdvanceListItem = ({ value, displayName, children, disabled, tag }) => {
    /***** HOOKS *****/
    const name = useAdvancedListContext();
    const { field } = useController({ name });

    /***** RENDER HELPERS *****/
    const id = `advanced-list-item-${value}`;
    const classes = classNames('AdvancedList__item', {
        'AdvancedList__item--selected': field.value === value,
    });

    /***** RENDER *****/
    return (
        // eslint-disable-next-line jsx-a11y/label-has-associated-control
        <label htmlFor={id} className={classes}>
            <input
                type="radio"
                name="render-advance-list-slim"
                id={id}
                value={value}
                className="AdvancedList__input"
                checked={field.value === value}
                onChange={() => field.onChange(value)}
                disabled={disabled}
            />
            <div className="AdvancedList__itemInner">
                <Flex align="center" className="AdvancedList__itemContent--left">
                    <span className="AdvancedList__itemRadio" />
                </Flex>
                <Padding left={4} inject>
                    <Flex align="center" className="AdvancedList__itemContent--right">
                        <div>
                            <Flex gap={1}>
                                <Padding top={2} inject>
                                    <Text secondary size--xss bold uppercase lead--1>
                                        {displayName}
                                    </Text>
                                </Padding>
                                {tag}
                            </Flex>
                            <Padding top={1} bottom={2} inject>
                                <Text size--s color="C_descriptionText_color_text">
                                    {children}
                                </Text>
                            </Padding>
                        </div>
                    </Flex>
                </Padding>
            </div>
        </label>
    );
};
