import classNames from 'classnames';
import type { PaddingNamespace } from 'components/Utils/Padding/types';
import { PropInjector } from 'components/Utils/PropInjector';
import { createElement } from 'react';
import { useStyle } from 'utilities/hooks/useStyle';
import './_Padding.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Just adds padding to its direct child
 */
export const Padding: React.FC<PaddingNamespace.Props> = ({
    x = 0,
    y = 0,
    left,
    right,
    top,
    bottom,
    xy,
    children,
    className,
    paddingOnly,
    fullWidth,
    fullHeight,
    inject,
    style,
    as = 'div',
}) => {
    /***** RENDER HELPERS *****/
    const _x = xy ?? x;
    const _y = xy ?? y;
    const paddingClasses = classNames('Padding', className, {
        'Padding--x': _x,
        'Padding--y': _y,
        'Padding--left': left,
        'Padding--right': right,
        'Padding--top': top,
        'Padding--bottom': bottom,
        'Padding--paddingOnly': paddingOnly,
        'Padding--fullWidth': fullWidth,
        'Padding--fullHeight': fullHeight,
    });

    const paddingStyles = useStyle({
        '--Padding-x': _x ? `${_x}` : undefined,
        '--Padding-y': _y ? `${_y}` : undefined,
        '--Padding-left': left ? `${left}` : undefined,
        '--Padding-right': right ? `${right}` : undefined,
        '--Padding-top': top ? `${top}` : undefined,
        '--Padding-bottom': bottom ? `${bottom}` : undefined,
    });

    const styles = { ...paddingStyles, ...style };

    /***** RENDER *****/
    return (
        <PropInjector inject={inject} injectableProps={{ className: paddingClasses, style: styles }} injectable={children}>
            {createElement(as, { className: paddingClasses, style: styles }, children)}
        </PropInjector>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
