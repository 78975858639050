/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
export const CANCEL_INITIAL_STATE = 'cancel/CANCEL_INITIAL_STATE';

export const SERVICE_CANCEL_REQUEST = 'cancel/SERVICE_CANCEL_REQUEST';
export const SERVICE_CANCEL_SUCCESS = 'cancel/SERVICE_CANCEL_SUCCESS';
export const SERVICE_CANCEL_ERROR = 'cancel/SERVICE_CANCEL_ERROR';

export const SERVICE_REVERSE_CANCEL_REQUEST = 'cancel/SERVICE_REVERSE_CANCEL_REQUEST';
export const SERVICE_REVERSE_CANCEL_SUCCESS = 'cancel/SERVICE_REVERSE_CANCEL_SUCCESS';
export const SERVICE_REVERSE_CANCEL_ERROR = 'cancel/SERVICE_REVERSE_CANCEL_ERROR';

export const EMAIL_DELETE_REQUEST = 'cancel/EMAIL_DELETE_REQUEST';
export const EMAIL_DELETE_SUCCESS = 'cancel/EMAIL_DELETE_SUCCESS';
export const EMAIL_DELETE_ERROR = 'cancel/EMAIL_DELETE_ERROR';

export const GSUITE_DELETE_REQUEST = 'cancel/GSUITE_DELETE_REQUEST';
export const GSUITE_DELETE_SUCCESS = 'cancel/GSUITE_DELETE_SUCCESS';
export const GSUITE_DELETE_ERROR = 'cancel/GSUITE_DELETE_ERROR';

export const GSUITE_REVERSE_CANCEL_REQUEST = 'cancel/GSUITE_REVERSE_CANCEL_REQUEST';
export const GSUITE_REVERSE_CANCEL_SUCCESS = 'cancel/GSUITE_REVERSE_CANCEL_SUCCESS';
export const GSUITE_REVERSE_CANCEL_ERROR = 'cancel/GSUITE_REVERSE_CANCEL_ERROR';

export const MS365_DELETE_SERVICE_REQUEST = 'cancel/MS365_DELETE_SERVICE_REQUEST';
export const MS365_DELETE_SERVICE_SUCCESS = 'cancel/MS365_DELETE_SERVICE_SUCCESS';
export const MS365_DELETE_SERVICE_ERROR = 'cancel/MS365_DELETE_SERVICE_ERROR';

export const MS365_REVERSE_DELETE_SERVICE_REQUEST = 'cancel/MS365_REVERSE_DELETE_SERVICE_REQUEST';
export const MS365_REVERSE_DELETE_SERVICE_SUCCESS = 'cancel/MS365_REVERSE_DELETE_SERVICE_SUCCESS';
export const MS365_REVERSE_DELETE_SERVICE_ERROR = 'cancel/MS365_REVERSE_DELETE_SERVICE_ERROR';

export const VPS_CANCEL_REQUEST = 'cancel/VPS_CANCEL_REQUEST';
export const VPS_CANCEL_SUCCESS = 'cancel/VPS_CANCEL_SUCCESS';
export const VPS_CANCEL_ERROR = 'cancel/VPS_CANCEL_ERROR';

export const SERVICE_CHECK_CANCEL_STATUS = 'cancel/SERVICE_CHECK_CANCEL_STATUS';
export const SERVICE_CHECK_CANCEL_SUCCESS = 'cancel/SERVICE_CHECK_CANCEL_SUCCESS';
export const SERVICE_CHECK_CANCEL_ERROR = 'cancel/SERVICE_CHECK_CANCEL_ERROR';
