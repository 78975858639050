import axios from 'axios';
import { nxAxios } from 'store/nxAxios';
const CancelToken = axios.CancelToken;
export let cancelUpdateConfigCalculate;

export const API = {
    /**********************************************************************************************************
     *   GSUITE API
     **********************************************************************************************************/
    ms365: {
        GET: {
            service: (id) => axios.get(`/api/microsoft365/${id}`),

            productConfigs: () => axios.get('/api/microsoft365/product-configs')
        },

        POST: {
            upgradeCalculate: (id, attributes) =>
                axios.post(`/api/microsoft365/${id}/upgrade/calculate`, {
                    type: 'microsoft365',
                    attributes
                }),

            upgrade: (id, attributes) =>
                axios.post(`/api/microsoft365/${id}/upgrade`, {
                    type: 'microsoft365',
                    attributes
                }),

            updateConfigCalculate: (id, attributes) =>
                axios.post(
                    `/api/microsoft365/${id}/update-config/calculate`,
                    {
                        type: 'microsoft365',
                        attributes
                    },
                    {
                        cancelToken: new CancelToken(function executor(c) {
                            cancelUpdateConfigCalculate = c;
                        })
                    }
                ),

            updateConfig: (id, attributes) =>
                nxAxios.post(`/api/microsoft365/${id}/update-config`, {
                    type: 'microsoft365',
                    attributes
                })
        }
    }
};
