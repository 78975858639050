/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useRef, useState, useMemo, useEffect } from 'react';
import type { TouchEvent, SyntheticEvent, KeyboardEvent, MouseEvent } from 'react';
import classNames from 'classnames';
import useResizeObserver from '@react-hook/resize-observer';
import { capitalize, clamp } from './utils';
import './_Slider.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type SliderProps = {
    min: number;
    max: number;
    step?: number;
    value: number;
    orientation?: 'horizontal' | 'vertical';
    tooltip?: boolean;
    reverse?: boolean;
    labels?: object;
    handleLabel?: string;
    className?: string;
    format?: (value: number) => number;
    onChangeStart?: () => void;
    onChange: (value: number) => void;
    onChangeComplete?: (value: any, e: TouchEvent | MouseEvent | Event) => void;
};

/**
 * Predefined constants
 */
const constants = {
    orientation: {
        horizontal: {
            dimension: 'width',
            direction: 'left',
            reverseDirection: 'right',
            coordinate: 'x'
        },
        vertical: {
            dimension: 'height',
            direction: 'top',
            reverseDirection: 'bottom',
            coordinate: 'y'
        }
    }
} as const;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export function _Slider({
    min = 0,
    max = 100,
    step = 1,
    value = 0,
    orientation = 'horizontal',
    tooltip = true,
    reverse = false,
    labels = {},
    handleLabel = '',
    className,
    format,
    onChangeStart,
    onChange,
    onChangeComplete
}: SliderProps) {
    /***** STATE *****/
    const [active, setActive] = useState(false);
    const [limit, setLimit] = useState(0);
    const [grab, setGrab] = useState(0);

    /***** HOOKS *****/
    const slider = useRef<HTMLDivElement | null>(null);
    const handle = useRef<HTMLDivElement | null>(null);

    useResizeObserver(slider?.current, handleUpdate);

    /***** FUNCTIONS *****/
    // Format label/tooltip value
    function handleFormat(value: number) {
        return format ? format(value) : value;
    }

    // Update slider state on change
    function handleUpdate() {
        if (!slider.current || !handle.current) {
            // for shallow rendering
            return;
        } else {
            const dimension = capitalize(constants.orientation[orientation].dimension);
            const sliderPos = slider.current[`offset${dimension}`];
            const handlePos = handle.current[`offset${dimension}`];
            setLimit(sliderPos - handlePos);
            setGrab(handlePos / 2);
        }
    }

    // Attach event listeners to mousemove/mouseup events
    function handleStart(e: TouchEvent | MouseEvent) {
        document.addEventListener('mousemove', handleDrag);
        document.addEventListener('mouseup', handleEnd);
        setActive(true);
        onChangeStart && onChangeStart(e);
    }

    // Handle drag/mousemove event
    function handleDrag(e: TouchEvent<HTMLDivElement> | MouseEvent<HTMLButtonElement | HTMLDivElement> | Event) {
        e.stopPropagation();
        if (!onChange || e?.target?.className === 'rangeslider__labels') return;

        let value = getPosition(e);

        if (e?.target?.classList && e?.target?.classList?.contains('rangeslider__label-item') && e?.target?.dataset?.value) {
            value = parseFloat(e?.target?.dataset?.value);
        }

        onChange && onChange(value);
    }

    function handleKeyboardLabelInteraction(e: KeyboardEvent<HTMLButtonElement>, key: string) {
        switch (e.key) {
            case ' ':
            case 'Space':
            case 'Enter':
                e.preventDefault();
                onChange(key);
                break;
            default:
                break;
        }
    }

    // Detach event listeners to mousemove/mouseup events
    function handleEnd(e: TouchEvent | MouseEvent | Event) {
        const value = getPosition(e);
        setActive(false);
        onChangeComplete && onChangeComplete(value, e);
        document.removeEventListener('mousemove', handleDrag);
        document.removeEventListener('mouseup', handleEnd);
    }

    // Support for key events on the slider handle
    function handleKeyDown(e: KeyboardEvent) {
        const { key } = e;
        let sliderValue;
        switch (key) {
            case 'ArrowUp':
            case 'ArrowRight':
                e.preventDefault();
                sliderValue = value + step > max ? max : value + step;
                onChange && onChange(sliderValue);
                break;
            case 'ArrowDown':
            case 'ArrowLeft':
                e.preventDefault();
                sliderValue = value - step < min ? min : value - step;
                onChange && onChange(sliderValue);
                break;
            case 'Home':
                e.preventDefault();
                onChange(min);
                break;

            case 'End':
                e.preventDefault();
                onChange(max);
                break;
            default:
                break;
        }
    }

    // Calculate position of slider based on its value
    function getPositionFromValue(value: number) {
        const diffMaxMin = max - min;
        const diffValMin = value - min;
        const percentage = diffValMin / diffMaxMin;
        const pos = Math.round(percentage * limit);
        return pos;
    }

    // Translate position of slider to slider value
    function getValueFromPosition(pos: number): number {
        const percentage = clamp(pos, 0, limit) / (limit || 1);
        const baseVal = step * Math.round((percentage * (max - min)) / step);
        const value = orientation === 'horizontal' ? baseVal + min : max - baseVal;

        return clamp(value, min, max);
    }

    // Correctly types an Event/SynethicEvent to include the properties from the more specific Event Type
    const isEventInstanceOf = (e: SyntheticEvent | Event, instance) =>
        e instanceof instance || (!(e instanceof Event) && e.nativeEvent instanceof instance);

    function getCoordinate(e: SyntheticEvent | Event, clientCoordinateStyle: 'clientX' | 'clientY') {
        const fallback = 0;

        // handle mouse events
        if (isEventInstanceOf(e, MouseEvent)) {
            return e[clientCoordinateStyle];
        }

        if (!isEventInstanceOf(e, TouchEvent)) {
            return fallback; //fallback to a default value
        }

        // try getting touches first (used for touchMove)
        const coordinate = e.touches[0]?.[clientCoordinateStyle];

        if (coordinate) {
            return coordinate;
        }

        // if no touches, try changedTouches (used for touchEnd)
        const touch = e.changedTouches[0];

        if (touch) {
            return touch[clientCoordinateStyle];
        }

        // fallback to a default value
        return fallback;
    }

    // Calculate position of slider based on value
    function getPosition(e: TouchEvent | MouseEvent | Event): number {
        const node = slider.current;
        const coordinateStyle = constants.orientation[orientation].coordinate;
        const directionStyle = reverse ? constants.orientation[orientation].reverseDirection : constants.orientation[orientation].direction;
        const clientCoordinateStyle = `client${capitalize(coordinateStyle)}` as const;
        const coordinate = getCoordinate(e, clientCoordinateStyle);
        const direction = node?.getBoundingClientRect?.()?.[directionStyle];
        const pos = reverse ? direction - coordinate - grab : coordinate - direction - grab;
        const value = getValueFromPosition(pos);
        return value;
    }

    // Grab coordinates of slider

    function coordinates(pos: number): {
        fill: number;
        handle: number;
        label: number;
    } {
        const value = getValueFromPosition(pos);
        const position = getPositionFromValue(value);
        const handlePos = orientation === 'horizontal' ? position + grab : position;
        const fillPos = orientation === 'horizontal' ? handlePos : limit - handlePos;

        return {
            fill: fillPos,
            handle: handlePos,
            label: handlePos
        };
    }

    /***** Effects *****/
    useEffect(() => {
        handleUpdate();
    }, [slider, handle]);

    /***** RENDER HELPERS *****/
    const dimension = constants.orientation[orientation].dimension;
    const direction = reverse ? constants.orientation[orientation].reverseDirection : constants.orientation[orientation].direction;
    const position = getPositionFromValue(value);
    const coords = coordinates(position);
    const fillStyle = { [dimension]: `${coords.fill}px` };
    const handleStyle = { [direction]: `${coords.handle}px` };
    const showTooltip = tooltip && active;

    const labelItems = useMemo(() => {
        const labelItemsAray = [];
        const labelKeys = Object.keys(labels);

        if (labelKeys.length > 0) {
            for (const key of labelKeys) {
                const labelPosition = getPositionFromValue(key);
                const labelCoords = coordinates(labelPosition);
                const labelStyle = { [direction]: `${labelCoords.label}px` };
                labelItemsAray.push(
                    <li key={key} className={classNames('rangeslider__label-item')} data-value={key} style={labelStyle}>
                        <button
                            className={`rangeslider__label-button${labels[key].disabled ? ' rangeslider__label-button--disabled' : ''}`}
                            disabled={labels[key].disabled}
                            onMouseDown={handleDrag}
                            onTouchStart={handleStart}
                            onTouchEnd={handleEnd}
                            onKeyDown={(e) => {
                                handleKeyboardLabelInteraction(e, key);
                            }}
                        >
                            {labels[key].label}
                        </button>
                    </li>
                );
            }
        }

        return labelItemsAray;
    }, [labels, direction, limit]);

    /***** RENDER *****/
    return (
        <div
            ref={slider}
            role="slider"
            tabIndex={-1}
            className={classNames('rangeslider', `rangeslider-${orientation}`, { 'rangeslider-reverse': reverse }, className)}
            onMouseDown={handleDrag}
            onMouseUp={handleEnd}
            // onTouchStart={handleStart}
            onTouchEnd={handleEnd}
            onKeyDown={handleKeyDown}
            aria-valuemin={min}
            aria-valuemax={max}
            aria-valuenow={value}
            aria-orientation={orientation}
        >
            <div tabIndex={-1} className="rangeslider__fill" style={fillStyle} />
            <div
                ref={handle}
                role="button"
                tabIndex={0}
                className="rangeslider__handle"
                onMouseDown={handleStart}
                onMouseUp={handleEnd}
                onTouchMove={handleDrag}
                onTouchEnd={handleEnd}
                onKeyDown={handleKeyDown}
                style={handleStyle}
            >
                {showTooltip ? (
                    <div className="rangeslider__handle-tooltip">
                        <span>{handleFormat(value)}</span>
                    </div>
                ) : null}
                <div className="rangeslider__handle-label">{handleLabel}</div>
            </div>
            {labels ? <ul className={classNames('rangeslider__labels')}>{labelItems}</ul> : null}
        </div>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
