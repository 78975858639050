/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { createAppliedStylingClasses } from 'components/Utils/methods';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { appliedStylingPropTypeKeys } from 'components/Loaders/Request/consts';
import { useBrandStore } from 'config/hooks/useBrandStore';
import './_RequestLoader.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Shows a loading animation with an optional message
 */
export const _RequestLoader: React.FC<NRequestLoader.Props> = (props) => {
    const { className, fillHeight = 70, fillWidth, height = 70, width = 70, message } = props;

    /***** HOOKS *****/
    const { activeBrand } = useBrandStore();

    /***** RENDER HELPERS *****/
    const appliedStylingClasses = createAppliedStylingClasses({
        props,
        keyBoundary: appliedStylingPropTypeKeys,
        componentName: 'requestLoader',
        delimiter: '--'
    });

    const requestLoaderClassNames = classNames('loader', 'requestLoader', className, appliedStylingClasses);

    const fillWrapperStyle = {
        height: fillHeight
    };

    if (fillWidth) {
        fillWrapperStyle.width = fillWidth;
    }

    function getSvgColors() {
        switch (activeBrand) {
            case 'intaserve':
                return {
                    circleOne: '#12143E',
                    circleTwo: '#1256FA',
                    circleThree: '#36F7CD'
                };
            case 'ventra':
            default:
                return {
                    circleOne: '#1CB4E9',
                    circleTwo: '#C42A87',
                    circleThree: '#FFCB11'
                };
        }
    }

    const svgColors = getSvgColors();

    /***** RENDER *****/
    return (
        <div className={requestLoaderClassNames}>
            <div className="requestLoader__fillWrapper" style={fillWrapperStyle}>
                <div style={{ width, height }}>
                    <svg width="100%" height="100%" viewBox="0 0 140 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="20" cy="20" r="0" fill={svgColors.circleOne}>
                            <animate attributeName="r" dur="2s" begin="0s" values="0; 20; 0; 0; 0; 0" repeatCount="indefinite" />
                        </circle>
                        <circle cx="70" cy="20" r="0" fill={svgColors.circleTwo}>
                            <animate attributeName="r" dur="2s" begin="0.3s" values="0; 20; 0; 0; 0; 0" repeatCount="indefinite" />
                        </circle>
                        <circle cx="120" cy="20" r="0" fill={svgColors.circleThree}>
                            <animate attributeName="r" dur="2s" begin="0.6s" values="0; 20; 0; 0; 0; 0" repeatCount="indefinite" />
                        </circle>
                    </svg>
                </div>
                {message ? <div className="loader__text">{message}</div> : ''}
            </div>
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
