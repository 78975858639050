import classNames from 'classnames';
import { CheckBox } from 'components/Form/CheckBox';
import type { CheckboxListItem } from 'components/Form/CheckBoxList/_CheckBoxListItem/types';
import Text from 'components/Utils/Text';
import './__CheckBoxListItem.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _CheckBoxListItem: CheckboxListItem.Component = ({ children, onChange, isChecked = false, className, intrinsic, disabled }) => {
    /***** RENDER *****/
    return (
        <CheckBox
            className={classNames('CheckBoxListItem', className)}
            isChecked={isChecked}
            onChange={onChange}
            intrinsic={intrinsic}
            disabled={disabled}
        >
            <Text size--s align--left>
                {children}
            </Text>
        </CheckBox>
    );
};
