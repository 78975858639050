export function getMessageFromErrorsData(data) {
    if (data?.errors?.length) {
        const errorType = typeof data?.errors?.[0];

        if (Array.isArray(data?.errors?.[0])) {
            return data?.errors?.[0]?.[0];
        }

        if (errorType === 'string') {
            return data?.errors?.[0];
        }

        return data?.errors?.[0]?.details ?? '';
    }

    return data?.errors ?? '';
}
