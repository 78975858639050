/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import type { WrappedFieldProps } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { FormItem } from 'components/Form/FormItem';
import { FormItemInner } from 'components/Form/FormItemInner';
import { FormLabel } from 'components/Form/FormLabel';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { _Radio as Radio } from 'components/Form/Radio/_Radio';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { NRadio } from 'components/Form/Radio/_Radio/types';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type RadioReduxFormDefaultProps = Omit<NRadio.Props, 'setActiveRadioButton' | 'activeRadioButton'> &
    WrappedFieldProps & {
        label?: React.ReactNode;
        fullWidth?: boolean;
    };

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _RadioReduxFormDefault: React.FC<RadioReduxFormDefaultProps> = ({ input, meta, label, fullWidth, ...props }) => {
    const { value, onChange, name } = input;
    
    /***** RENDER *****/
    return (
        <FormItem>
            <FormLabel htmlFor={name}>{label}</FormLabel>
            <FormItemInner meta={meta} noBackground fullWidth={fullWidth}>
                <Radio {...props} activeRadioButton={value} setActiveRadioButton={onChange} />
            </FormItemInner>
        </FormItem>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
