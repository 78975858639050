import classNames from 'classnames';
import { GridStyledPropKeys } from 'components/Grid/consts';
import GridSpan from 'components/Grid/GridSpan';
import type { GridNamespace } from 'components/Grid/types';
import { createAppliedStylingClasses } from 'components/Utils/methods';
import { PropInjector } from 'components/Utils/PropInjector';
import React from 'react';
import './_Grid.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * The Grid component. just a simple grid
 */
const _Grid: React.FC<GridNamespace.Props> = (props) => {
    const { className, columns, gap = 2, children, inject, style } = props;

    /***** RENDER HELPERS *****/
    const appliedStylingClasses = createAppliedStylingClasses({
        props,
        keyBoundary: GridStyledPropKeys,
        componentName: 'NXGrid',
        delimiter: '--'
    });

    const rowBaseClassNames = classNames('NXGrid', className, appliedStylingClasses);

    const rowStyle = {
        ...style,
        gridTemplateColumns: columns,
        gap: `calc(5px * ${gap})`
    };

    /***** RENDER *****/
    return (
        <PropInjector inject={inject} injectableProps={{ className: rowBaseClassNames, style: rowStyle }} injectable={children}>
            <div className={rowBaseClassNames} style={rowStyle}>
                {children}
            </div>
        </PropInjector>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

const Grid = Object.assign(_Grid, { Span: GridSpan });

export default Grid;
