/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { NXQuery } from 'utilities/query';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export const useIsAuthenticated = () => {
    return NXQuery.auth.userData.useQuery().isSuccess;
};
