import { SolidButton } from "components/Buttons/SolidButton";
import _ from 'lodash';
import { useFormContext, useFormState } from 'react-hook-form';
import { getAllPaths } from 'utilities/methods/getAllPaths/getAllPaths';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const LoginAuthSubmitButton = () => {
    /***** HOOKS *****/
    const { getValues, setValue, trigger } = useFormContext();
    const { isSubmitting } = useFormState();

    /***** FUNCTIONS *****/
    const handleButtonClick = () => {
        /**
         * 1. Set all fields to touched & dirty so that all validation errors will show
         * 2. trigger validation to run.
         *
         * This assumes that all fields were provided with an initial value, which we should be doing anyway
         */
        const formValues = getValues();
        const allPaths = getAllPaths(formValues);

        allPaths.forEach((path) => {
            const value = _.get(formValues, path);
            setValue(path, value, { shouldTouch: true, shouldDirty: true });
        });

        trigger();
    };

    /***** RENDER *****/
    return (
        <SolidButton isLoading={isSubmitting} className="loginAuth__button" onClick={handleButtonClick}>
            Login
        </SolidButton>
    );
};
