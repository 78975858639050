import { Navigate, Outlet, createRoute, useRouter } from '@tanstack/react-router';
import 'App/_app.scss';
import { checkAlertBanner } from 'App/action';
import { AppRouteTemplate } from 'App/components/AppTemplate';
import { handleRootRoute } from 'App/methods';
import 'assets/icons/ccpicons.css';
import AppLoader from 'components/Loaders/App';
import 'config/tokens/_cssVariables.scss';
import { RootRoute } from 'router/__root';
import 'styles/_anim.scss';
import 'styles/_common.scss';
import 'styles/_form.scss';
import 'styles/_reset.scss';
import { useIsAuthenticated } from 'utilities/hooks/redux/useIsAuthenticated';
import { z } from 'zod';

/**********************************************************************************************************
 *   ROUTE SCHEMA
 **********************************************************************************************************/
const appSchema = z.object({
    /**
     * Used to identify what module should be rendered from the current section. If this is not provided, all will be rendered.
     * This is used by the ModuleRenderer to determine what module to render.
     */
    module: z.string().optional(),
});

export type AppSchema = z.infer<typeof appSchema>;

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
/**
 * The app route is the main authenticated route for the application. If this route is matched, then the user
 * should be authenticated to view the application. Routes that expect the user to be a guest (unauthed) should
 * be added as a child of the `_guest` layout route while routes that can exist with either authentication
 * may be added directly to the root route.
 *
 * Note that this route does not provide any authentication middleware for it's children, as this may break existing routes.
 * All authenticated routes (for now) should continue to use `routeMiddleware.authentication()`
 */
export const AppRoute = createRoute({
    getParentRoute: () => RootRoute,
    path: '/',
    pendingComponent: () => <AppLoader fullHeight />,
    pendingMs: 0, // ensure appLoader always shows
    validateSearch: appSchema.parse,
    loader() {
        checkAlertBanner();
    },
    async beforeLoad({ location, cause, search }) {
        // Do not allow redirects in preload, less risk crashing the router due to bug
        if ('preload' === cause) return;

        if (location.pathname === '/') {
            handleRootRoute(search);
        }
    },
    component: AppRouteComponent,
});

/**********************************************************************************************************
 *   ROUTE COMPONENT START
 **********************************************************************************************************/
function AppRouteComponent() {
    /***** HOOKS *****/
    const isAuthenticated = useIsAuthenticated();
    const router = useRouter();

    /***** REACTIVE REDIRECTS *****/
    if (!isAuthenticated) {
        console.warn('[DEBUG | /src/App/index.tsx]: User is not authenticated, redirecting to login page');

        // using non-reactive values to prevent unnecessary work when hash or path changes
        const { hash, pathname } = router.latestLocation;

        return (
            <Navigate
                to="/login"
                search={(prev) => ({
                    ...prev,
                    ref: `${pathname}${hash ? `#${hash}` : ''}`,
                })}
            />
        );
    }

    /***** RENDER *****/
    return (
        <AppRouteTemplate>
            <Outlet />
        </AppRouteTemplate>
    );
}
