import { useMutation } from '@tanstack/react-query';
import type { Item } from 'containers/orderingForm/types';
import { API } from 'utilities/api/services';
import { UserPreferences } from 'utilities/UserPreferences';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useMutation(setCartToken?: React.Dispatch<React.SetStateAction<string | null>>) {
    return useMutation({
        mutationKey: ['createCart'],
        mutationFn: (item: Item[]) => API.cart.internal.POST.nxCreate(item),
        onSuccess: (data) => {
            const newToken = data?.data?.token;
            if (newToken) {
                UserPreferences.setItem('CART_TOKEN', newToken);
                window.dispatchEvent(new Event('cartTokenChanges'));

                if (setCartToken) {
                    setCartToken(newToken);
                }
            }
        }
    });
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const createCartInternal = Object.freeze({
    useMutation: _useMutation
});
