import {
    EMAIL_ACTIVE_SYNC_FAIL,
    EMAIL_ACTIVE_SYNC_REQUEST,
    EMAIL_ACTIVE_SYNC_SUCCESS,
    EMAIL_ACTIVE_SYNC_TOGGLE_FAIL,
    EMAIL_ACTIVE_SYNC_TOGGLE_REQUEST,
    EMAIL_ACTIVE_SYNC_TOGGLE_SUCCESS,
    EMAIL_ALIASES_DELETE_FAIL,
    EMAIL_ALIASES_DELETE_REQUEST,
    EMAIL_ALIASES_DELETE_SUCCESS,
    EMAIL_ALIASES_FAIL,
    EMAIL_ALIASES_LIST_FAIL,
    EMAIL_ALIASES_LIST_REQUEST,
    EMAIL_ALIASES_LIST_SUCCESS,
    EMAIL_ALIASES_REQUEST,
    EMAIL_ALIASES_SUCCESS,
    EMAIL_BILLINGCYCLE_FAIL,
    EMAIL_BILLINGCYCLE_REQUEST,
    EMAIL_BILLINGCYCLE_SUCCESS,
    EMAIL_BILLING_FAIL,
    EMAIL_BILLING_REQUEST,
    EMAIL_BILLING_SUCCESS,
    EMAIL_DELETE_FAIL,
    EMAIL_DELETE_REQUEST,
    EMAIL_DELETE_SUCCESS,
    EMAIL_DISKBLOCK_FAIL,
    EMAIL_DISKBLOCK_LIST_FAIL,
    EMAIL_DISKBLOCK_LIST_REQUEST,
    EMAIL_DISKBLOCK_LIST_SUCCESS,
    EMAIL_DISKBLOCK_PURCHASE_FAIL,
    EMAIL_DISKBLOCK_PURCHASE_REQUEST,
    EMAIL_DISKBLOCK_PURCHASE_SUCCESS,
    EMAIL_DISKBLOCK_REQUEST,
    EMAIL_DISKBLOCK_RESET,
    EMAIL_DISKBLOCK_SUCCESS,
    EMAIL_DOMAIN_GROUP_DETAILS_ERROR,
    EMAIL_DOMAIN_GROUP_DETAILS_REQUEST,
    EMAIL_DOMAIN_GROUP_DETAILS_SUCCESS,
    EMAIL_DOWNGRADE_FAIL,
    EMAIL_DOWNGRADE_REQUEST,
    EMAIL_DOWNGRADE_SUCCESS,
    EMAIL_INFORMATION_MAILBOX_FAIL,
    EMAIL_INFORMATION_MAILBOX_REQUEST,
    EMAIL_INFORMATION_MAILBOX_SUCCESS,
    EMAIL_INITIAL_STATE,
    EMAIL_KEEP_FAIL,
    EMAIL_KEEP_REQUEST,
    EMAIL_KEEP_SUCCESS,
    EMAIL_MAILBOX_LIST_FAIL,
    EMAIL_MAILBOX_LIST_MORE_FAIL,
    EMAIL_MAILBOX_LIST_MORE_REQUEST,
    EMAIL_MAILBOX_LIST_MORE_SUCCESS,
    EMAIL_MAILBOX_LIST_REQUEST,
    EMAIL_MAILBOX_LIST_SUCCESS,
    EMAIL_MAILBOX_LOGIN_FAIL,
    EMAIL_MAILBOX_LOGIN_REQUEST,
    EMAIL_MAILBOX_LOGIN_SUCCESS,
    EMAIL_MAILBOX_SEARCH_ERROR,
    EMAIL_MAILBOX_SEARCH_REQUEST,
    EMAIL_MAILBOX_SEARCH_RESET,
    EMAIL_MAILBOX_SEARCH_SUCCESS,
    EMAIL_MAILBOX_SETUP_FAIL,
    EMAIL_MAILBOX_SETUP_REQUEST,
    EMAIL_MAILBOX_SETUP_SUCCESS,
    EMAIL_MAILBOX_UPDATE_FAIL,
    EMAIL_MAILBOX_UPDATE_REQUEST,
    EMAIL_MAILBOX_UPDATE_SUCCESS,
    EMAIL_PRODUCT_LIST_FAIL,
    EMAIL_PRODUCT_LIST_REQUEST,
    EMAIL_PRODUCT_LIST_SUCCESS,
    EMAIL_PURCHASE_MAILBOX_FAIL,
    EMAIL_PURCHASE_MAILBOX_REQUEST,
    EMAIL_PURCHASE_MAILBOX_RESET,
    EMAIL_PURCHASE_MAILBOX_SUCCESS,
    EMAIL_RESTORE_COST_FAIL,
    EMAIL_RESTORE_COST_REQUEST,
    EMAIL_RESTORE_COST_SUCCESS,
    EMAIL_RESTORE_MAILBOX_FAIL,
    EMAIL_RESTORE_MAILBOX_REQUEST,
    EMAIL_RESTORE_MAILBOX_SUCCESS,
    EMAIL_SEND_DETAILS_FAIL,
    EMAIL_SEND_DETAILS_REQUEST,
    EMAIL_SEND_DETAILS_SUCCESS,
    EMAIL_UPGRADE_DETAILS_ERROR,
    EMAIL_UPGRADE_DETAILS_REQUEST,
    EMAIL_UPGRADE_DETAILS_SUCCESS,
    EMAIL_UPGRADE_FAIL,
    EMAIL_UPGRADE_LIST_FAIL,
    EMAIL_UPGRADE_LIST_REQUEST,
    EMAIL_UPGRADE_LIST_SUCCESS,
    EMAIL_UPGRADE_REQUEST,
    EMAIL_UPGRADE_SUCCESS,
    EMAIL_USAGE_ERROR,
    EMAIL_USAGE_REQUEST,
    EMAIL_USAGE_SUCCESS,
    EMAIL_VALIDATE_ERROR,
    EMAIL_VALIDATE_REQUEST,
    EMAIL_VALIDATE_RESET,
    EMAIL_VALIDATE_SUCCESS
} from './actionTypes';

const initialState = {
    email_restore_fee_status: null,
    email_restore_fee_data: null,
    email_information_mailbox_status: null,
    email_information_mailbox_data: null,
    email_information_mailbox_error: null,
    email_admin_status: null,
    email_admin_data: null,
    email_admin_error: null,
    email_delete_status: null,
    email_delete_data: null,
    email_delete_error: null,
    email_mailbox_list_more_status: null,
    email_mailbox_list_status: null,
    email_mailbox_list_data: null,
    email_mailbox_list_error: null,
    email_mailbox_list_meta: null,
    email_mailbox_search_status: null,
    email_mailbox_search_data: null,
    email_mailbox_search_error: null,
    email_keep_status: null,
    email_keep_data: null,
    email_keep_error: null,
    email_mailbox_update_status: null,
    email_mailbox_update_data: null,
    email_mailbox_update_error: null,
    email_diskblock_list_status: null,
    email_diskblock_list_data: null,
    email_diskblock_list_error: null,
    email_diskblock_purchase_status: null,
    email_diskblock_purchase_data: null,
    email_diskblock_purchase_error: null,
    email_diskblock_status: null,
    email_diskblock_data: null,
    email_diskblock_error: null,
    email_upgrade_list_status: null,
    email_upgrade_list_data: null,
    email_upgrade_list_error: null,
    email_upgrade_status: null,
    email_upgrade_data: null,
    email_upgrade_error: null,
    email_upgrade_details_status: null,
    email_upgrade_details_data: null,
    email_upgrade_details_error: null,
    email_billingcycle_status: null,
    email_billingcycle_data: null,
    email_billingcycle_error: null,
    email_billing_status: null,
    email_billing_data: null,
    email_billing_error: null,
    email_downgrade_status: null,
    email_downgrade_data: null,
    email_downgrade_error: null,
    email_mailbox_login_status: null,
    email_mailbox_login_data: null,
    email_mailbox_login_error: null,
    email_domain_group_details_status: null,
    email_domain_group_details_data: null,
    email_send_details_status: null,
    email_product_list_status: null,
    email_product_list_data: null,
    email_product_list_error: null,
    email_purchase_mailbox_status: null,
    email_purchase_mailbox_data: null,
    email_purchase_mailbox_error: null,
    email_mailbox_setup_status: null,
    email_mailbox_setup_data: null,
    email_mailbox_setup_error: null,
    email_aliases_list_status: null,
    email_aliases_list_data: null,
    email_aliases_list_error: null,
    email_aliases_status: null,
    email_aliases_data: null,
    email_aliases_error: null,
    email_aliases_delete_status: null,
    email_aliases_delete_data: null,
    email_aliases_delete_error: null,
    email_active_sync_status: null,
    email_active_sync_data: null,
    email_active_sync_error: null,
    email_active_sync_toggle_status: null,
    email_active_sync_toggle_data: null,
    email_active_sync_toggle_error: null,
    email_restore_mailbox_status: null,
    email_validate_status: {},
    email_validate_data: {},
    email_validate_error: {},
    email_usage_status: null,
    email_usage_data: null
};

const emailReducer = (state = initialState, action) => {
    switch (action.type) {
        case EMAIL_INITIAL_STATE:
            return initialState;

        /**********************************************************************************************************
         *   EMAIL HOSTING USAGE
         **********************************************************************************************************/
        case EMAIL_USAGE_REQUEST:
            return {
                ...state,
                email_usage_status: 'loading',
                email_usage_data: null
            };

        case EMAIL_USAGE_SUCCESS:
            return {
                ...state,
                email_usage_status: 'success',
                email_usage_data: action.email_usage_data
            };

        case EMAIL_USAGE_ERROR:
            return {
                ...state,
                email_usage_status: 'error'
            };

        /**********************************************************************************************************
         *   EMAIL HOSTING RESTORE FEE
         **********************************************************************************************************/
        case EMAIL_RESTORE_COST_REQUEST:
            return {
                ...state,
                email_restore_fee_status: 'loading',
                email_restore_fee_data: null
            };

        case EMAIL_RESTORE_COST_SUCCESS:
            return {
                ...state,
                email_restore_fee_status: 'success',
                email_restore_fee_data: action.email_restore_fee_data
            };

        case EMAIL_RESTORE_COST_FAIL:
            return {
                ...state,
                email_restore_fee_status: 'error'
            };

        /* EMAIL HOSTING SERVICE DETAILS
         *******************************************************************************/
        case EMAIL_INFORMATION_MAILBOX_REQUEST:
            return {
                ...state,
                email_information_mailbox_status: 'loading',
                email_information_mailbox_data: null,
                email_information_mailbox_error: null
            };

        case EMAIL_INFORMATION_MAILBOX_SUCCESS:
            return {
                ...state,
                email_information_mailbox_status: 'success',
                email_information_mailbox_data: action.email_information_mailbox_data
            };

        case EMAIL_INFORMATION_MAILBOX_FAIL:
            return {
                ...state,
                email_information_mailbox_status: 'error',
                email_information_mailbox_error: action.email_information_mailbox_error
            };

        /* EMAIL HOSTING SEARCH MAILBOX
         *******************************************************************************/
        case EMAIL_MAILBOX_SEARCH_REQUEST:
            return {
                ...state,
                email_mailbox_search_status: 'loading',
                email_mailbox_search_data: null,
                email_mailbox_search_error: null
            };

        case EMAIL_MAILBOX_SEARCH_SUCCESS:
            return {
                ...state,
                email_mailbox_search_status: 'success',
                email_mailbox_search_data: action.email_mailbox_search_data
            };

        case EMAIL_MAILBOX_SEARCH_ERROR:
            return {
                ...state,
                email_mailbox_search_status: 'error',
                email_mailbox_search_error: action.email_mailbox_search_error
            };

        case EMAIL_MAILBOX_SEARCH_RESET:
            return {
                ...state,
                email_mailbox_search_status: null,
                email_mailbox_search_data: null,
                email_mailbox_search_error: null
            };

        /**********************************************************************************************************
         *   CANCEL STATES
         **********************************************************************************************************/
        case EMAIL_DELETE_REQUEST:
            return {
                ...state,
                email_delete_status: 'loading',
                email_delete_data: null,
                email_delete_error: null
            };

        case EMAIL_DELETE_SUCCESS:
            return {
                ...state,
                email_delete_status: 'success',
                email_delete_data: action.email_delete_data
            };

        case EMAIL_DELETE_FAIL:
            return {
                ...state,
                email_delete_status: 'error',
                email_delete_error: action.email_delete_error
            };

        /**********************************************************************************************************
         *   MAILBOX
         **********************************************************************************************************/
        case EMAIL_MAILBOX_LIST_REQUEST:
            return {
                ...state,
                email_mailbox_list_status: 'loading',
                email_mailbox_list_data: null,
                email_mailbox_list_error: null,
                email_mailbox_list_meta: null
            };

        case EMAIL_MAILBOX_LIST_SUCCESS:
            return {
                ...state,
                email_mailbox_list_status: 'success',
                email_mailbox_list_data: action.email_mailbox_list_data,
                email_mailbox_list_meta: action.email_mailbox_list_meta
            };

        case EMAIL_MAILBOX_LIST_FAIL:
            return {
                ...state,
                email_mailbox_list_status: 'error',
                email_mailbox_list_error: action.email_mailbox_list_error
            };

        case EMAIL_MAILBOX_LIST_MORE_REQUEST:
            return {
                ...state,
                email_mailbox_list_more_status: 'loading',
                email_mailbox_list_error: null
            };
        case EMAIL_MAILBOX_LIST_MORE_SUCCESS:
            return {
                ...state,
                email_mailbox_list_more_status: 'success',
                email_mailbox_list_data: action.email_mailbox_list_data,
                email_mailbox_list_meta: action.email_mailbox_list_meta
            };
        case EMAIL_MAILBOX_LIST_MORE_FAIL:
            return {
                ...state,
                email_mailbox_list_more_status: 'error',
                email_mailbox_list_error: action.email_mailbox_list_error
            };

        /**********************************************************************************************************
         *   KEEP MAILBOX
         **********************************************************************************************************/
        case EMAIL_KEEP_REQUEST:
            return {
                ...state,
                email_keep_status: 'loading',
                email_keep_data: null,
                email_keep_error: null
            };

        case EMAIL_KEEP_SUCCESS:
            return {
                ...state,
                email_keep_status: 'success',
                email_keep_data: action.email_keep_data
            };

        case EMAIL_KEEP_FAIL:
            return {
                ...state,
                email_keep_status: 'error',
                email_keep_error: action.email_keep_error
            };

        /**********************************************************************************************************
         *   UPGRADE
         **********************************************************************************************************/
        case EMAIL_MAILBOX_UPDATE_REQUEST:
            return {
                ...state,
                email_mailbox_update_status: 'loading',
                email_mailbox_update_data: null,
                email_mailbox_update_error: null
            };

        case EMAIL_MAILBOX_UPDATE_SUCCESS:
            return {
                ...state,
                email_mailbox_update_status: 'success',
                email_mailbox_update_data: action.email_mailbox_update_data
            };

        case EMAIL_MAILBOX_UPDATE_FAIL:
            return {
                ...state,
                email_mailbox_update_status: 'error',
                email_mailbox_update_error: action.email_mailbox_update_error
            };

        /**********************************************************************************************************
         *   DISKBLOCK
         **********************************************************************************************************/
        case EMAIL_DISKBLOCK_RESET:
            return {
                ...state,
                email_diskblock_status: null,
                email_diskblock_error: null,
                email_diskblock_purchase_status: null,
                email_diskblock_purchase_error: null
            };

        case EMAIL_DISKBLOCK_LIST_REQUEST:
            return {
                ...state,
                email_diskblock_list_status: 'loading',
                email_diskblock_list_data: null,
                email_diskblock_list_error: null
            };

        case EMAIL_DISKBLOCK_LIST_SUCCESS:
            return {
                ...state,
                email_diskblock_list_status: 'success',
                email_diskblock_list_data: action.email_diskblock_list_data
            };

        case EMAIL_DISKBLOCK_LIST_FAIL:
            return {
                ...state,
                email_diskblock_list_status: 'error',
                email_diskblock_list_error: action.email_diskblock_list_error
            };

        case EMAIL_DISKBLOCK_PURCHASE_REQUEST:
            return {
                ...state,
                email_diskblock_purchase_status: 'loading',
                email_diskblock_purchase_data: null,
                email_diskblock_purchase_error: null
            };

        case EMAIL_DISKBLOCK_PURCHASE_SUCCESS:
            return {
                ...state,
                email_diskblock_purchase_status: 'success',
                email_diskblock_purchase_data: action.email_diskblock_purchase_data
            };

        case EMAIL_DISKBLOCK_PURCHASE_FAIL:
            return {
                ...state,
                email_diskblock_purchase_status: 'error',
                email_diskblock_purchase_error: action.email_diskblock_purchase_error
            };

        case EMAIL_DISKBLOCK_REQUEST:
            return {
                ...state,
                email_diskblock_status: 'loading',
                email_diskblock_data: null,
                email_diskblock_error: null
            };

        case EMAIL_DISKBLOCK_SUCCESS:
            return {
                ...state,
                email_diskblock_status: 'success',
                email_diskblock_data: action.email_diskblock_data
            };

        case EMAIL_DISKBLOCK_FAIL:
            return {
                ...state,
                email_diskblock_status: 'error',
                email_diskblock_error: action.email_diskblock_error
            };

        /**********************************************************************************************************
         *   UPGRADE
         **********************************************************************************************************/
        case EMAIL_UPGRADE_LIST_REQUEST:
            return {
                ...state,
                email_upgrade_list_status: 'loading',
                email_upgrade_list_data: null,
                email_upgrade_list_error: null
            };

        case EMAIL_UPGRADE_LIST_SUCCESS:
            return {
                ...state,
                email_upgrade_list_status: 'success',
                email_upgrade_list_data: action.email_upgrade_list_data
            };

        case EMAIL_UPGRADE_LIST_FAIL:
            return {
                ...state,
                email_upgrade_list_status: 'error',
                email_upgrade_list_error: action.email_upgrade_list_error
            };

        case EMAIL_UPGRADE_REQUEST:
            return {
                ...state,
                email_upgrade_status: 'loading',
                email_upgrade_data: null,
                email_upgrade_error: null
            };

        case EMAIL_UPGRADE_SUCCESS:
            return {
                ...state,
                email_upgrade_status: 'success',
                email_upgrade_data: action.email_upgrade_data
            };

        case EMAIL_UPGRADE_FAIL:
            return {
                ...state,
                email_upgrade_status: 'error',
                email_upgrade_error: action.email_upgrade_error
            };

        case EMAIL_UPGRADE_DETAILS_REQUEST:
            return {
                ...state,
                email_upgrade_details_status: 'loading',
                email_upgrade_details_data: null,
                email_upgrade_details_error: null
            };

        case EMAIL_UPGRADE_DETAILS_SUCCESS:
            return {
                ...state,
                email_upgrade_details_status: 'success',
                email_upgrade_details_data: action.email_upgrade_details_data
            };

        case EMAIL_UPGRADE_DETAILS_ERROR:
            return {
                ...state,
                email_upgrade_details_status: 'error',
                email_upgrade_details_error: action.email_upgrade_details_error
            };

        /**********************************************************************************************************
         *   DOWNGRADE
         **********************************************************************************************************/
        case EMAIL_DOWNGRADE_REQUEST:
            return {
                ...state,
                email_downgrade_status: 'loading',
                email_downgrade_data: null,
                email_downgrade_error: null
            };

        case EMAIL_DOWNGRADE_SUCCESS:
            return {
                ...state,
                email_downgrade_status: 'success',
                email_downgrade_data: action.email_downgrade_data
            };

        case EMAIL_DOWNGRADE_FAIL:
            return {
                ...state,
                email_downgrade_status: 'error',
                email_downgrade_error: action.email_downgrade_error
            };

        /**********************************************************************************************************
         *   BILLING CYCLE
         **********************************************************************************************************/
        case EMAIL_BILLINGCYCLE_REQUEST:
            return {
                ...state,
                email_billingcycle_status: 'loading',
                email_billingcycle_data: null,
                email_billingcycle_error: null
            };

        case EMAIL_BILLINGCYCLE_SUCCESS:
            return {
                ...state,
                email_billingcycle_status: 'success',
                email_billingcycle_data: action.email_billingcycle_data
            };

        case EMAIL_BILLINGCYCLE_FAIL:
            return {
                ...state,
                email_billingcycle_status: 'error',
                email_billingcycle_error: action.email_billingcycle_error
            };

        case EMAIL_BILLING_REQUEST:
            return {
                ...state,
                email_billing_status: 'loading',
                email_billing_data: null,
                email_billing_error: null
            };

        case EMAIL_BILLING_SUCCESS:
            return {
                ...state,
                email_billing_status: 'success',
                email_billing_data: action.email_billing_data
            };

        case EMAIL_BILLING_FAIL:
            return {
                ...state,
                email_billing_status: 'error',
                email_billing_error: action.email_billing_error
            };

        /**********************************************************************************************************
         *   DETAILS
         **********************************************************************************************************/
        case EMAIL_MAILBOX_LOGIN_REQUEST:
            return {
                ...state,
                email_mailbox_login_status: 'loading',
                email_mailbox_login_data: null,
                email_mailbox_login_error: null
            };

        case EMAIL_MAILBOX_LOGIN_SUCCESS:
            return {
                ...state,
                email_mailbox_login_status: 'success',
                email_mailbox_login_data: action.email_mailbox_login_data
            };

        case EMAIL_MAILBOX_LOGIN_FAIL:
            return {
                ...state,
                email_mailbox_login_status: 'error',
                email_mailbox_login_error: action.email_mailbox_login_error
            };

        /**********************************************************************************************************
         *   DETAILS
         **********************************************************************************************************/
        case EMAIL_DOMAIN_GROUP_DETAILS_REQUEST:
            return {
                ...state,
                email_domain_group_details_status: 'loading',
                email_domain_group_details_data: null
            };

        case EMAIL_DOMAIN_GROUP_DETAILS_SUCCESS:
            return {
                ...state,
                email_domain_group_details_status: 'success',
                email_domain_group_details_data: action.email_domain_group_details_data
            };

        case EMAIL_DOMAIN_GROUP_DETAILS_ERROR:
            return {
                ...state,
                email_domain_group_details_status: 'error'
            };

        case EMAIL_SEND_DETAILS_REQUEST:
            return {
                ...state,
                email_send_details_status: 'loading'
            };

        case EMAIL_SEND_DETAILS_SUCCESS:
            return {
                ...state,
                email_send_details_status: 'success'
            };

        case EMAIL_SEND_DETAILS_FAIL:
            return {
                ...state,
                email_send_details_status: 'error'
            };

        /**********************************************************************************************************
         *   PRODUCTS
         **********************************************************************************************************/
        case EMAIL_PRODUCT_LIST_REQUEST:
            return {
                ...state,
                email_product_list_status: 'loading',
                email_product_list_data: null,
                email_product_list_error: null
            };

        case EMAIL_PRODUCT_LIST_SUCCESS:
            return {
                ...state,
                email_product_list_status: 'success',
                email_product_list_data: action.email_product_list_data
            };

        case EMAIL_PRODUCT_LIST_FAIL:
            return {
                ...state,
                email_product_list_status: 'error',
                email_product_list_error: action.email_product_list_error
            };

        /**********************************************************************************************************
         *   PURCHASE MAILBOX
         **********************************************************************************************************/
        case EMAIL_PURCHASE_MAILBOX_RESET:
            return {
                ...state,
                email_purchase_mailbox_status: null,
                email_purchase_mailbox_data: null,
                email_purchase_mailbox_error: null
            };

        case EMAIL_PURCHASE_MAILBOX_REQUEST:
            return {
                ...state,
                email_purchase_mailbox_status: 'loading',
                email_purchase_mailbox_data: null,
                email_purchase_mailbox_error: null
            };

        case EMAIL_PURCHASE_MAILBOX_SUCCESS:
            return {
                ...state,
                email_purchase_mailbox_status: 'success',
                email_purchase_mailbox_data: action.email_purchase_mailbox_data
            };

        case EMAIL_PURCHASE_MAILBOX_FAIL:
            return {
                ...state,
                email_purchase_mailbox_status: 'error',
                email_purchase_mailbox_error: action.email_purchase_mailbox_error
            };

        /**********************************************************************************************************
         *   SETUP MAILBOX
         **********************************************************************************************************/
        case EMAIL_MAILBOX_SETUP_REQUEST:
            return {
                ...state,
                email_mailbox_setup_status: 'loading',
                email_mailbox_setup_data: null,
                email_mailbox_setup_error: null
            };

        case EMAIL_MAILBOX_SETUP_SUCCESS:
            return {
                ...state,
                email_mailbox_setup_status: 'success',
                email_mailbox_setup_data: action.email_mailbox_setup_data
            };

        case EMAIL_MAILBOX_SETUP_FAIL:
            return {
                ...state,
                email_mailbox_setup_status: 'error',
                email_mailbox_setup_error: action.email_mailbox_setup_error
            };

        /**********************************************************************************************************
         *   ALIASES
         **********************************************************************************************************/
        case EMAIL_ALIASES_LIST_REQUEST:
            return {
                ...state,
                email_aliases_list_status: 'loading',
                email_aliases_list_data: null,
                email_aliases_list_error: null
            };

        case EMAIL_ALIASES_LIST_SUCCESS:
            return {
                ...state,
                email_aliases_list_status: 'success',
                email_aliases_list_data: action.email_aliases_list_data
            };

        case EMAIL_ALIASES_LIST_FAIL:
            return {
                ...state,
                email_aliases_list_status: 'error',
                email_aliases_list_error: action.email_aliases_list_error
            };

        case EMAIL_ALIASES_REQUEST:
            return {
                ...state,
                email_aliases_status: 'loading',
                email_aliases_data: null,
                email_aliases_error: null
            };

        case EMAIL_ALIASES_SUCCESS:
            return {
                ...state,
                email_aliases_status: 'success',
                email_aliases_data: action.email_aliases_data
            };

        case EMAIL_ALIASES_FAIL:
            return {
                ...state,
                email_aliases_status: 'error',
                email_aliases_error: action.email_aliases_error
            };

        case EMAIL_ALIASES_DELETE_REQUEST:
            return {
                ...state,
                email_aliases_delete_status: 'loading',
                email_aliases_delete_data: null,
                email_aliases_delete_error: null
            };

        case EMAIL_ALIASES_DELETE_SUCCESS:
            return {
                ...state,
                email_aliases_delete_status: 'success',
                email_aliases_delete_data: action.email_aliases_delete_data
            };

        case EMAIL_ALIASES_DELETE_FAIL:
            return {
                ...state,
                email_aliases_delete_status: 'error',
                email_aliases_delete_error: action.email_aliases_delete_error
            };

        /**********************************************************************************************************
         *   ACTIVE SYNC
         **********************************************************************************************************/
        case EMAIL_ACTIVE_SYNC_REQUEST:
            return {
                ...state,
                email_active_sync_status: 'loading',
                email_active_sync_data: null,
                email_active_sync_error: null
            };

        case EMAIL_ACTIVE_SYNC_SUCCESS:
            return {
                ...state,
                email_active_sync_status: 'success',
                email_active_sync_data: action.email_active_sync_data
            };

        case EMAIL_ACTIVE_SYNC_FAIL:
            return {
                ...state,
                email_active_sync_status: 'error',
                email_active_sync_error: action.email_active_sync_error
            };

        case EMAIL_ACTIVE_SYNC_TOGGLE_REQUEST:
            return {
                ...state,
                email_active_sync_toggle_status: 'loading',
                email_active_sync_toggle_data: null,
                email_active_sync_toggle_error: null
            };

        case EMAIL_ACTIVE_SYNC_TOGGLE_SUCCESS:
            return {
                ...state,
                email_active_sync_toggle_status: 'success',
                email_active_sync_toggle_data: action.email_active_sync_toggle_data
            };

        case EMAIL_ACTIVE_SYNC_TOGGLE_FAIL:
            return {
                ...state,
                email_active_sync_toggle_status: 'error',
                email_active_sync_toggle_error: action.email_active_sync_toggle_error
            };

        /**********************************************************************************************************
         *   MAILBOX RESTORE
         **********************************************************************************************************/
        case EMAIL_RESTORE_MAILBOX_REQUEST:
            return {
                ...state,
                email_restore_mailbox_status: 'loading',
                email_restore_mailbox_data: null
            };

        case EMAIL_RESTORE_MAILBOX_SUCCESS:
            return {
                ...state,
                email_restore_mailbox_status: 'success',
                email_restore_mailbox_data: action.email_restore_mailbox_data
            };

        case EMAIL_RESTORE_MAILBOX_FAIL:
            return {
                ...state,
                email_restore_mailbox_status: 'error'
            };

        /**********************************************************************************************************
         *   MAILBOX EMAIL ADDRESS VALIDATE
         **********************************************************************************************************/
        case EMAIL_VALIDATE_REQUEST:
            return {
                ...state,
                email_validate_status: {
                    ...state.email_validate_status,
                    [action.uniqueKey]: 'loading'
                },
                email_validate_data: {
                    ...state.email_validate_data,
                    [action.uniqueKey]: null
                },
                email_validate_error: {
                    ...state.email_validate_error,
                    [action.uniqueKey]: null
                }
            };

        case EMAIL_VALIDATE_SUCCESS:
            return {
                ...state,
                email_validate_status: {
                    ...state.email_validate_status,
                    [action.uniqueKey]: 'success'
                },
                email_validate_data: {
                    ...state.email_validate_data,
                    [action.uniqueKey]: action.uniqueKeyData
                }
            };

        case EMAIL_VALIDATE_ERROR:
            return {
                ...state,
                email_validate_status: {
                    ...state.email_validate_status,
                    [action.uniqueKey]: 'error'
                },
                email_validate_error: {
                    ...state.email_validate_error,
                    [action.uniqueKey]: action.uniqueKeyError
                }
            };

        case EMAIL_VALIDATE_RESET: {
            if (!action.uniqueKey) {
                return {
                    ...state,
                    email_validate_status: initialState.email_validate_status,
                    email_validate_data: initialState.email_validate_data,
                    email_validate_error: initialState.email_validate_error
                };
            }

            const newStatus = { ...state.email_validate_status };
            const newData = { ...state.email_validate_data };
            const newError = { ...state.email_validate_error };

            delete newStatus[action.uniqueKey];
            delete newData[action.uniqueKey];
            delete newError[action.uniqueKey];

            return {
                ...state,
                email_validate_status: newStatus,
                email_validate_data: newData,
                email_validate_error: newError
            };
        }

        default:
            return state;
    }
};
export default emailReducer;
