import { useMutation } from '@tanstack/react-query';
import { API } from 'utilities/api/services';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type params = {
    paymentMethodId: string;
    tokenId: string;
    applyCredit: boolean;
};

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useMutation() {
    return useMutation({
        mutationFn: API.order.POST.nxCreate,
        onSuccess: () => {}
    });
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const createOrder = Object.freeze({
    useMutation: _useMutation
});
