/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import RequestLoader from 'components/Loaders/Request';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_InactiveButton.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @deprecated
 * The InactiveButton component implements a button that is disabled, that is to say, it is a greyed out button with light text (or other children).
 *
 * The InactiveButton also provides the ability to customize the size and loading state.
 *
 * @param {{
 *   children: React.ReactNode,
 *   className?: string,
 *   size?: 'small' | 'medium' | 'large',
 *   loading?: boolean
 * }} props
 * @returns
 */
const InactiveButton = ({ children, className, size, loading }) => {
    const classes = classNames('InactiveButton', className, size);

    /***** RENDER *****/
    return (
        <button aria-disabled type="button" className={classes}>
            {loading ? <RequestLoader width={40} height={40} /> : children}
        </button>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default InactiveButton;
