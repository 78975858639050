import classNames from 'classnames';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import { Flex } from 'components/Utils/Flex';
import { Padding } from 'components/Utils/Padding';
import Text from 'components/Utils/Text';
import type { FieldValues } from 'react-hook-form';
import 'containers/orderingForm/orderingFormInputs/selectDropDown/_orderingFormDropDownIntaserve.scss';
import type { SelectDropDownHookFormProps } from 'containers/orderingForm/orderingFormInputs/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _SelectDropDownHookFormIntaserve = <T extends FieldValues>({
    options,
    label,
    placeholder,
    register,
    name,
    watch,
    validationErrors,
    errorBorder,
    successBorder,
    disabled
}: SelectDropDownHookFormProps<T>) => {
    const value = watch(name);
    const iconClassNames = classNames('orderingFormDropDown__icon', { 'orderingFormDropDown__icon--noLabel': !label });
    const selectFieldClassNames = classNames('orderingFormDropDown__selectField', {
        'orderingFormDropDown__selectField--placeHolder': value === '',
        'orderingFormDropDown__selectField--error': errorBorder,
        'orderingFormDropDown__selectField--success': successBorder,
        'orderingFormDropDown__selectField--disabled': disabled
    });

    /***** RENDER *****/
    return (
        <div className="orderingFormDropDown__container">
            <PhosphorIcons.Chevron state="down" className={iconClassNames} />
            <Flex direction="column" gap={2}>
                {label && <Text semiBold>{label}</Text>}
                <select disabled={disabled} className={selectFieldClassNames} {...register(name)}>
                    {placeholder && (
                        <option value="" disabled selected hidden>
                            {placeholder}
                        </option>
                    )}
                    {options?.map((option, index) => (
                        <option key={index} value={option?.value || option}>
                            {option?.label || option}
                        </option>
                    ))}
                </select>
                {validationErrors?.[name]?.message && (
                    <Padding left={2} y={1}>
                        <Text size--xs lead--0 color="S_color_text_warn_base">
                            {validationErrors?.[name].message as string}
                        </Text>
                    </Padding>
                )}
            </Flex>
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
