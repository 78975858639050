import type { KatanaNamespace } from 'containers/katana/types';

/**
 * Optimistically add a new section to the sections list and update the indexes of the other sections
 *
 * Used in conjunction with the `optimistic.setWith` method.
 *
 * @example
 * ```
 * sections.optimistic.setWith(serviceID, 'data', sections.optimistic.predicates.newSection(data.data));
 * ```
 */
export function _newSection(data: KatanaNamespace.Section) {
    return (sections: KatanaNamespace.Sections): KatanaNamespace.Sections => {
        const newOrder = data.order;
        // Optimistically update the sort order of the sections so that the new section is in the correct place
        const updatedDataEntries = sections.map((section) => {
            if (section.order >= newOrder) {
                return { ...section, order: section.order + 1 };
            }
            return section;
        });

        updatedDataEntries.push(data);

        return updatedDataEntries;
    };
}
