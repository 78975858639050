import { _RequestLoaderSuspenseBoundary as RequestLoaderSuspenseBoundary } from 'components/Loaders/Request/_SuspenseBoundary';
import { useSuperUserItem } from 'components/StaffMenu/SuperUser/hooks/useSuperUserItem';
import type { SuperUserNamespace } from 'components/StaffMenu/SuperUser/types';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Props = {
    children: React.ReactNode;
    itemKey: SuperUserNamespace.Key;
};
type _SuperUserItemSuspenseBoundary = React.FC<Props>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _SuperUserItemSuspenseBoundary: _SuperUserItemSuspenseBoundary = ({ children, itemKey }) => {
    /***** HOOKS *****/
    const isEnabled = useSuperUserItem(itemKey);

    /***** RENDER *****/
    if (!isEnabled) return null;
    return <RequestLoaderSuspenseBoundary>{children}</RequestLoaderSuspenseBoundary>;
};
