import { queryOptions, useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { NON_ELIGIBILITY_FIELD_VALUES } from 'containers/orderingForm/consts';
import { API } from 'utilities/api/domain';
import { createBaseQueryKey } from 'utilities/methods/tanstack/createBaseQueryKey';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Params = {
    eligibility_number: number;
    eligibility_type: string;
};

/***** FUNCTIONS *****/
function createQueryKey({ eligibility_number, eligibility_type }: Params) {
    return createBaseQueryKey(['domain', 'ordering', 'eligibility', eligibility_type, eligibility_number]);
}

function createQueryOptions(params: Params) {
    return queryOptions({
        queryKey: createQueryKey(params),
        queryFn: ({ signal }) => {
            return API.domain.POST.auEligibility(params, signal);
        },
        select: (data) => data,
        enabled: Boolean(params?.eligibility_number && params?.eligibility_type && !NON_ELIGIBILITY_FIELD_VALUES.includes(params.eligibility_type))
    });
}

function _useSuspenseQuery(params: Params) {
    return useSuspenseQuery(createQueryOptions(params));
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useQuery(params: Params) {
    return useQuery(createQueryOptions(params));
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const auEligibility = Object.freeze({
    useQuery: _useQuery,
    useSuspenseQuery: _useSuspenseQuery,
    createQueryKey,
    createQueryOptions
});
