/**********************************************************************************************************
 *   DECLARATIONS
 **********************************************************************************************************/
export const REWARDS_TIERS_LIST_REQUEST = 'rewards/REWARDS_TIERS_LIST_REQUEST';
export const REWARDS_TIERS_LIST_SUCCESS = 'rewards/REWARDS_TIERS_LIST_SUCCESS';
export const REWARDS_TIERS_LIST_ERROR = 'rewards/REWARDS_TIERS_LIST_ERROR';

export const REWARDS_SIGNUP_REQUEST = 'rewards/REWARDS_SIGNUP_REQUEST';
export const REWARDS_SIGNUP_SUCCESS = 'rewards/REWARDS_SIGNUP_SUCCESS';
export const REWARDS_SIGNUP_ERROR = 'rewards/REWARDS_SIGNUP_ERROR';
