import { queryOptions, useQuery } from '@tanstack/react-query';
import { katanaQueryKeys } from 'containers/katana/queries/katanaQueryKeys';
import type { KatanaNamespace } from 'containers/katana/types';
import { queryClient } from 'store/queryClient';
import { KATANA_API } from 'utilities/api/katana';

type Params = Parameters<typeof KATANA_API.katana.service_id.preview.site.POST>[0];

function createQueryKey(params: Params) {
    return katanaQueryKeys.katana.service.ID.preview.site(params);
}

function createQueryOptions({ serviceID, attributes }: Params, enabled = true) {
    return queryOptions({
        queryKey: createQueryKey({ serviceID, attributes }),
        queryFn: ({ signal }) => KATANA_API.katana.service_id.preview.site.POST({ serviceID, attributes }, signal),
        staleTime: Infinity,
        enabled: Boolean(serviceID) && enabled,
        select: (data) => {
            if (typeof data === 'string') {
                return data;
            }
        },
    });
}

function prefetchQuery(data: Params) {
    return queryClient.prefetchQuery(createQueryOptions(data));
}

// TODO: invalidate this query when any of the service data has changed
function invalidateQueries(serviceID: KatanaNamespace.ServiceId) {
    return queryClient.invalidateQueries({ queryKey: createQueryKey({ serviceID }) });
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useQuery(data: Params, enabled?: boolean) {
    return useQuery(createQueryOptions(data, enabled));
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const getSitePreview = Object.freeze({
    useQuery: _useQuery,
    prefetchQuery,
    invalidateQueries,
    createQueryKey,
    createQueryOptions,
});
