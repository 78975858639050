/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import * as Sentry from '@sentry/react';
import { redirect } from '@tanstack/react-router';
import type { AppSchema } from 'App';
import { isAuthenticated } from 'router/utils/isAuthenticated/isAuthenticated';

export function configureSentryEmailScope(email: string) {
    try {
        // @ts-ignore
        Sentry.configureScope((scope) => {
            scope.setUser({ email });
        });
    } catch (error) {
        console.error('Failed to attach user context to Sentry.', error);
    }
}

/**
 * Handle unauthenticated redirects and redirects after login (where ref is provided in the URL)
 */
export function handleRootRoute(search: AppSchema) {
    if (!isAuthenticated()) {
        throw redirect({ to: '/login', search });
    }

    throw redirect({ to: '/dashboard' });
}
