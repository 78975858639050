import { Store } from '@tanstack/react-store';
import classNames from 'classnames';
import type { CheckBoxNamespace } from 'components/Form/CheckBox/types';
import React, { useState } from 'react';
import { useEventListener } from 'usehooks-ts';
import { useUUID } from 'utilities/hooks/useUUID';
import './_CheckBox.scss';

const checkboxPointerDownStartUUID = new Store('');

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _CheckBox: React.FC<CheckBoxNamespace.Props> = ({
    isChecked = false,
    onChange,
    children,
    disabled,
    className,
    type = 'square',
    intrinsic
}) => {
    /***** STATE *****/
    const _uuid = useUUID();
    const [isPointerDown, setIsPointerDown] = useState(false);

    /***** HOOKS *****/
    useEventListener('pointerdown', () => {
        // setIsPointerDown(true);
        // pointer down sliding selection through checkboxes has been disabled as per QA request, uncomment above line to release :)
    });

    useEventListener('pointerup', () => {
        setIsPointerDown(false);
    });

    // Prevent further pointer events from being triggered when page blurs
    useEventListener('blur', () => {
        setIsPointerDown(false);
    });

    /***** FUNCTIONS *****/
    /**
     * This function now acts as the "onClick" rather than having "onClick" on the actual button
     */
    const onPointerDown: React.PointerEventHandler<HTMLButtonElement> = (e) => {
        checkboxPointerDownStartUUID.setState(() => _uuid);
        onChange?.(!isChecked);
    };

    /**
     * When the pointer enters another checkbox and the pointer is down at the same time
     * If the checkboxPointerDownStartUUID has a uuid and the CheckBox has not been checked yet
     * then it will allow the toggle
     */
    function onPointerEnter() {
        if (isPointerDown) {
            const uuid = checkboxPointerDownStartUUID.state;
            if (uuid && isChecked === false) {
                onChange?.(!isChecked);
            }
        }
    }

    function onKeyUp(e: React.KeyboardEvent<HTMLButtonElement>) {
        if (e.code === 'Space') {
            onChange?.(!isChecked);
        }
    }

    /***** RENDER *****/
    return (
        <button
            disabled={disabled}
            role="checkbox"
            type="button"
            aria-checked={isChecked ? 'true' : 'false'}
            className={classNames('CheckBox', { 'CheckBox--active': isChecked, [`CheckBox--type-${type}`]: type }, className)}
            onPointerDown={onPointerDown}
            onPointerEnter={onPointerEnter}
            onKeyUp={onKeyUp}
            {...intrinsic}
        >
            <span className="CheckBox__toggleBox" />
            {children}
        </button>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
