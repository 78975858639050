import type { KatanaNamespace } from 'containers/katana/types';
import type { KATANA_API } from 'utilities/api/katana';
import { createBaseQueryKey } from 'utilities/methods/tanstack/createBaseQueryKey';

type ServiceAndSectionDefinitionID = { serviceID: KatanaNamespace.ServiceId; sectionDefinitionID: KatanaNamespace.SectionDefinitionId };
type ServiceAndPageID = { serviceID: KatanaNamespace.ServiceId; pageID: KatanaNamespace.PageId };
type ServiceAndPageIDAndSectionID = { serviceID: KatanaNamespace.ServiceId; pageID: KatanaNamespace.PageId; sectionID: KatanaNamespace.SectionId };

export const katanaQueryKeys = {
    katana: Object.assign(() => [...createBaseQueryKey(), 'katana'] as const, {
        service: Object.assign(() => [...katanaQueryKeys.katana(), 'service'] as const, {
            ID: Object.assign((serviceID: KatanaNamespace.ServiceId) => [...katanaQueryKeys.katana.service(), serviceID] as const, {
                cancel: Object.assign(
                    (serviceID: KatanaNamespace.ServiceId) => [...katanaQueryKeys.katana.service.ID(serviceID), 'cancel'] as const,
                    {}
                ),
                sections: Object.assign(
                    (serviceID: KatanaNamespace.ServiceId) => [...katanaQueryKeys.katana.service.ID(serviceID), 'sections'] as const,
                    {
                        ID: Object.assign(
                            ({ serviceID, sectionID }: { serviceID: KatanaNamespace.ServiceId; sectionID: KatanaNamespace.SectionId | null }) =>
                                [...katanaQueryKeys.katana.service.ID.sections(serviceID), sectionID] as const,
                            {}
                        ),
                        add: Object.assign(
                            (serviceID: KatanaNamespace.ServiceId) => [...katanaQueryKeys.katana.service.ID(serviceID), 'add'] as const,
                            {}
                        ),
                        reorder: Object.assign(
                            (serviceID: KatanaNamespace.ServiceId) => [...katanaQueryKeys.katana.service.ID(serviceID), 'reorder'] as const,
                            {}
                        ),
                    }
                ),
                pages: Object.assign((serviceID: KatanaNamespace.ServiceId) => [...katanaQueryKeys.katana.service.ID(serviceID), 'pages'] as const, {
                    ID: Object.assign(
                        (params: ServiceAndPageID) => [...katanaQueryKeys.katana.service.ID.pages(params.serviceID), params.pageID] as const,
                        {
                            sections: Object.assign(
                                (params: ServiceAndPageID) => [...katanaQueryKeys.katana.service.ID.pages.ID(params), 'sections'] as const,
                                {
                                    duplicate: Object.assign(
                                        (params: ServiceAndPageID) =>
                                            [...katanaQueryKeys.katana.service.ID.pages.ID.sections(params), 'duplicate'] as const,
                                        {}
                                    ),

                                    ID: Object.assign(
                                        ({ sectionID, ...rest }: ServiceAndPageIDAndSectionID) =>
                                            [...katanaQueryKeys.katana.service.ID.pages.ID.sections(rest), sectionID] as const,
                                        {}
                                    ),
                                    add: Object.assign(
                                        (params: ServiceAndPageID) => [...katanaQueryKeys.katana.service.ID.pages.ID(params), 'add'] as const,
                                        {}
                                    ),
                                }
                            ),
                            update: Object.assign(
                                (params: ServiceAndPageID) => [...katanaQueryKeys.katana.service.ID.pages.ID(params), 'update'] as const,
                                {}
                            ),
                        }
                    ),
                    create: Object.assign(
                        (serviceID: KatanaNamespace.ServiceId) => [...katanaQueryKeys.katana.service.ID(serviceID), 'create'] as const,
                        {}
                    ),
                }),
                dns: Object.assign((serviceID: KatanaNamespace.ServiceId) => [...katanaQueryKeys.katana.service.ID(serviceID), 'dns'] as const, {}),
                domainOptions: Object.assign(
                    (serviceID: KatanaNamespace.ServiceId) => [...katanaQueryKeys.katana.service.ID(serviceID), 'domain_options'] as const,
                    {}
                ),
                navigation: Object.assign(
                    (serviceID: KatanaNamespace.ServiceId) => [...katanaQueryKeys.katana.service.ID(serviceID), 'navigation'] as const,
                    {
                        items: Object.assign(
                            (serviceID: KatanaNamespace.ServiceId) => [...katanaQueryKeys.katana.service.ID.navigation(serviceID), 'items'] as const,
                            {}
                        ),
                    }
                ),
                preview: Object.assign(
                    (serviceID: KatanaNamespace.ServiceId) => [...katanaQueryKeys.katana.service.ID(serviceID), 'preview'] as const,
                    {
                        themePreset: Object.assign(
                            (serviceID: KatanaNamespace.ServiceId) =>
                                [...katanaQueryKeys.katana.service.ID.preview(serviceID), 'themePreset'] as const,
                            {
                                ID: Object.assign(
                                    ({ serviceID, presetID, site }: KATANA_API.katana.preview.site_preset.POST.Params) =>
                                        [
                                            ...katanaQueryKeys.katana.service.ID.preview.themePreset(serviceID),
                                            presetID,
                                            JSON.stringify(site),
                                        ] as const,
                                    {}
                                ),
                            }
                        ),
                        pagePreset: Object.assign(
                            (serviceID: KatanaNamespace.ServiceId) =>
                                [...katanaQueryKeys.katana.service.ID.preview(serviceID), 'pagePreset'] as const,
                            {
                                ID: Object.assign(
                                    ({ serviceID, presetID, site }: KATANA_API.katana.preview.page_preset.POST.Params) =>
                                        [...katanaQueryKeys.katana.service.ID.preview.pagePreset(serviceID), presetID, JSON.stringify(site)] as const,
                                    {}
                                ),
                            }
                        ),
                        sectionDefinition: Object.assign(
                            (serviceID: KatanaNamespace.ServiceId) =>
                                [...katanaQueryKeys.katana.service.ID.preview(serviceID), 'section-definition'] as const,
                            {
                                ID: Object.assign(
                                    ({ serviceID, sectionDefinitionID }: ServiceAndSectionDefinitionID) =>
                                        [...katanaQueryKeys.katana.service.ID.preview.sectionDefinition(serviceID), sectionDefinitionID] as const,
                                    {}
                                ),
                            }
                        ),

                        section: Object.assign(
                            (serviceID: KatanaNamespace.ServiceId) => [...katanaQueryKeys.katana.service.ID.preview(serviceID), 'section'] as const,
                            {
                                ID: Object.assign(
                                    ({ serviceID, sectionID, ...attributes }: KATANA_API.katana.preview.section.section_id.POST.QueryParams) =>
                                        [...katanaQueryKeys.katana.service.ID.preview.section(serviceID), sectionID, attributes] as const,
                                    {}
                                ),
                            }
                        ),
                        site: Object.assign(
                            ({ serviceID, attributes }: KATANA_API.katana.preview.site.POST.Params) =>
                                [...katanaQueryKeys.katana.service.ID.preview(serviceID), 'site', attributes] as const,
                            {}
                        ),
                        page: Object.assign(
                            ({ serviceID, pageID }: KATANA_API.katana.preview.page.POST.Params) =>
                                [...katanaQueryKeys.katana.service.ID.preview(serviceID), 'page', pageID] as const,
                            {}
                        ),
                    }
                ),
                user: Object.assign((serviceID: KatanaNamespace.ServiceId) => [...katanaQueryKeys.katana.service.ID(serviceID), 'user'] as const, {
                    socials: Object.assign(
                        (serviceID: KatanaNamespace.ServiceId) => [...katanaQueryKeys.katana.service.ID.user(serviceID), 'socials'] as const,
                        {
                            ID: Object.assign(
                                ({ serviceID, socialID }: { serviceID: KatanaNamespace.ServiceId; socialID?: number }) =>
                                    [...katanaQueryKeys.katana.service.ID.user.socials(serviceID), socialID] as const,
                                {}
                            ),
                        }
                    ),
                }),
                socials: Object.assign(
                    (serviceID: KatanaNamespace.ServiceId) => [...katanaQueryKeys.katana.service.ID(serviceID), 'socials'] as const,
                    {}
                ),
                info: Object.assign((serviceID: KatanaNamespace.ServiceId) => [...katanaQueryKeys.katana.service.ID(serviceID), 'info'] as const, {}),
                business: Object.assign(
                    (serviceID: KatanaNamespace.ServiceId) => [...katanaQueryKeys.katana.service.ID(serviceID), 'business'] as const,
                    {}
                ),
                uploadFile: Object.assign(
                    (serviceID: KatanaNamespace.ServiceId) => [...katanaQueryKeys.katana.service.ID(serviceID), 'upload-file'] as const,
                    {}
                ),
                serviceDetails: Object.assign(
                    (serviceID: KatanaNamespace.ServiceId) => [...katanaQueryKeys.katana.service.ID(serviceID), 'service-details'] as const,
                    {}
                ),
                theme: Object.assign((serviceID: KatanaNamespace.ServiceId) => [...katanaQueryKeys.katana.service.ID(serviceID), 'theme'] as const),
            }),
            list: Object.assign(() => [...katanaQueryKeys.katana(), 'list'] as const, {
                filters: Object.assign(
                    (filters?: KATANA_API.katana.GET.Filters) =>
                        filters ? ([...katanaQueryKeys.katana.service.list(), filters] as const) : katanaQueryKeys.katana.service.list(),
                    {}
                ),
            }),
        }),
        file: Object.assign(() => [...katanaQueryKeys.katana(), 'file'] as const, {
            ID: Object.assign(
                ({ serviceID, uploadID }: KATANA_API.katana.service_id.uploads.uploadId.file.GET.Params) =>
                    [...katanaQueryKeys.katana.file(), serviceID, uploadID] as const,
                {}
            ),
        }),
        trialDomains: Object.assign(() => [...katanaQueryKeys.katana(), 'trial_domains'] as const, {}),
        domains: Object.assign((filters: KATANA_API.katana.domains.GET.Filters) => [...katanaQueryKeys.katana(), 'domains', filters] as const, {}),
        checkDomain: Object.assign((domain: string) => [...katanaQueryKeys.katana(), 'check_domain', domain] as const, {}),
        meta: Object.assign(() => [...katanaQueryKeys.katana(), 'meta'] as const, {
            sitePresets: () => [...katanaQueryKeys.katana.meta(), 'site_presets'] as const,
            pagePresets: () => [...katanaQueryKeys.katana.meta(), 'page_presets'] as const,
            socials: () => [...katanaQueryKeys.katana.meta(), 'socials'] as const,
            sections: () => [...katanaQueryKeys.katana.meta(), 'sections'] as const,
            colours: () => [...katanaQueryKeys.katana.meta(), 'colours'] as const,
            fonts: () => [...katanaQueryKeys.katana.meta(), 'fonts'] as const,
            palette: Object.assign(() => [...katanaQueryKeys.katana.meta(), 'palette'] as const, {
                colour: (colour?: string) => [...katanaQueryKeys.katana.meta.palette(), colour] as const,
            }),
        }),
        unsplash: Object.assign(() => [...katanaQueryKeys.katana(), 'unsplash'] as const, {
            search: (params: KATANA_API.katana.service_id.unsplash.GET.Params) => [...katanaQueryKeys.katana.unsplash(), 'search', params] as const,
        }),
    }),
};
