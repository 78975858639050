import { StatusTag } from 'components/StatusTag';
import { HostingTableRowTitle } from 'containers/services/modules/hosting/components/accordionTitle';
import { HostingTableColumn } from 'containers/services/modules/hosting/components/column';
import { useHostingContext } from 'containers/services/modules/hosting/context';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const OwnStatus = () => {
    /***** HOOKS *****/
    const serviceData = useHostingContext();

    /***** RENDER *****/
    return (
        <HostingTableColumn>
            <HostingTableRowTitle>STATUS</HostingTableRowTitle>
            <StatusTag width="fit-content" status={serviceData.attributes.domain_status} />
        </HostingTableColumn>
    );
};
