import { createRoute, Outlet } from '@tanstack/react-router';

import { Redirect } from 'components/Router/Redirect';
import { BillingRoute } from 'containers/billing';
import { routerMiddleware } from 'router/utils/middleware';

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const OrderRoute = createRoute({
    getParentRoute: () => BillingRoute,
    path: 'order',
    beforeLoad(opts) {
        routerMiddleware.business(this, opts);
        routerMiddleware.authentication('user', opts);
    },
    component: () => (
        <Redirect.When when="/billing/order" to="/billing/orders">
            <Outlet />
        </Redirect.When>
    )
});
