import type { AxiosError } from 'axios';
import { z } from 'zod';

/**
 * Safely parses an AxioseError instance to see if it contains an errors array within the response.
 */
export const parseAxiosErrorWithErrorsResponse = (error: AxiosError) => {
    const schema = z.object({
        response: z.object({
            data: z.object({
                status: z.number(),
                errors: z.array(
                    z.object({
                        status: z.number(),
                        details: z.string()
                    })
                )
            })
        })
    });

    return schema.safeParse(error);
};
