import { BaseDropdown } from 'components/Dropdowns/_BaseDropdown';
import type { DropdownNamespace } from 'components/Dropdowns/types';
import './_OutlineDropdown.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Outline Dropdown Variant, use this for Secondary dropdowns
 */
export const OutlineDropdown: React.FC<DropdownNamespace.Props> = (props) => <BaseDropdown variant="OutlineDropdown" {...props} />;
