/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import htmr from 'htmr';
import { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'utilities/methods/tanstack/router/withRouter';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Box from 'components/Box';
import { OutlineButton } from 'components/Buttons/OutlineButton';
import { OutlineDropdown } from 'components/Dropdowns/OutlineDropdown';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';
import { ScrollableComponent } from 'components/ScrollableComponent';
import SolidTag from 'components/Tags/SolidTag';
import Text from 'components/Utils/Text';
import TwoFactorForm from '../forms/2fa';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/

/*   ACTIONS
 *****************************************************/
import { disableTwoFactor, getTwoFactor, setPrimaryTwoFactor } from '../action';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import AppIcon from 'assets/images/app_icon.png';
import VIPControlApp from 'assets/images/vipcontrol_app.png';
import AppStore from 'config/containers/promotions/vipcontrolApp/assets/app-store.png';
import PlayStore from 'config/containers/promotions/vipcontrolApp/assets/play-store.png';
import { withBrandStore } from 'config/hooks/useBrandStore';

export const enableTwoFactorLightboxSearchParam = 'enable2fa';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class TwoFactor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showSetupMode: null,
            showDisableLightbox: false,
            showDisableData: {
                id: null,
                type: null,
                displayText: null
            },
            configuredMethods: []
        };
        this.openSetupLightbox = this.openSetupLightbox.bind(this);
        this.closeSetupLightbox = this.closeSetupLightbox.bind(this);
        this.openDisableLightbox = this.openDisableLightbox.bind(this);
        this.closeDisableLightbox = this.closeDisableLightbox.bind(this);
        this.setAsPrimary = this.setAsPrimary.bind(this);
        this.disableSelected = this.disableSelected.bind(this);
    }

    openSetupLightbox(mode = null) {
        const { history } = this.props;

        this.setState({ showSetupMode: mode }, () => history.push(`/account/security?${enableTwoFactorLightboxSearchParam}=true#two-factor`));
    }

    closeSetupLightbox() {
        const { history } = this.props;

        this.setState({ showSetupMode: null }, () => history.push('/account/security#two-factor'));
    }

    openDisableLightbox(id, type) {
        let displayText;

        const { application } = this.props;

        switch (type) {
            case 'EMAIL':
                displayText = `Email Authentication`;
                break;

            case 'SMS':
                displayText = `SMS Authentication`;
                break;

            case 'PUSH':
                displayText = `${application} App`;
                break;

            case 'APP':
            default:
                displayText = `App Authentication`;
                break;
        }

        this.setState({
            showDisableLightbox: true,
            showDisableData: {
                id: id,
                type: type,
                displayText
            }
        });
    }

    closeDisableLightbox() {
        this.setState({
            showDisableLightbox: false,
            showDisableData: {
                id: null,
                type: null,
                displayText: null
            }
        });
    }

    setAsPrimary(id) {
        const { setPrimaryTwoFactor } = this.props;
        setPrimaryTwoFactor(id);
    }

    disableSelected() {
        const { disableTwoFactor } = this.props;
        const { showDisableData } = this.state;
        disableTwoFactor(showDisableData.id);
    }

    handle2faForm(values) {
        const { updateEmailAddress } = this.props;
        updateEmailAddress(values.new_email);
    }

    componentDidUpdate(prevProps) {
        const { account_twofactor_status, account_twofactor_data, account_delete_twofactor_status, account_twofactor_verify_status, getTwoFactor } =
            this.props;
        const { closeSetupLightbox } = this;

        if (account_twofactor_status === 'success' && prevProps.account_twofactor_status === 'loading') {
            this.setState({
                configuredMethods: account_twofactor_data
            });
        }

        if (account_delete_twofactor_status === 'success' && prevProps.account_delete_twofactor_status === 'loading') {
            this.setState(
                {
                    showDisableLightbox: false,
                    showDisableData: {
                        id: null,
                        type: null,
                        displayText: null
                    }
                },
                () => {
                    getTwoFactor();
                }
            );
        }

        if (account_twofactor_verify_status === 'success' && prevProps.account_twofactor_verify_status === 'loading') {
            closeSetupLightbox();
            getTwoFactor();
        }
    }

    render() {
        const {
            account_twofactor_status,
            account_primary_twofactor_status,
            account_delete_twofactor_status,
            getTwoFactor,
            application,
            activeBrand
        } = this.props;
        const { showSetupMode, showDisableLightbox, showDisableData, configuredMethods } = this.state;
        const { openSetupLightbox, closeSetupLightbox, openDisableLightbox, closeDisableLightbox, setAsPrimary, disableSelected } = this;

        const searchParams = new URLSearchParams(this.props.location.search);
        const isSetup2faLightboxOpen = searchParams.get(enableTwoFactorLightboxSearchParam);

        const handleRenderDropdownList = (data) => {
            let menu = [];

            if (data) {
                const { id, attributes } = data;
                const { is_primary, method } = attributes;

                menu = [
                    {
                        label: 'Disable Two-Factor',
                        type: 'onClick',
                        onClick: (e) => {
                            e.preventDefault();
                            openDisableLightbox(id, method);
                        }
                    }
                ];
                if (!is_primary) {
                    menu.push({
                        label: 'Make Primary',
                        type: 'onClick',
                        onClick: (e) => {
                            e.preventDefault();
                            setAsPrimary(id);
                        }
                    });
                }
            }

            return menu;
        };

        const handleBackupMethodRender = () => {
            return (
                <div className="account2fa__configured">
                    <div className="method__info">
                        <div className="method__frame">
                            <div className="method__title">Add Backup Authentication</div>
                            <div className="method__desc">
                                If you lose access to your primary authentication method, you can still access your account using a secondary
                                authentication method.
                            </div>
                        </div>
                    </div>
                    <div className="method__action">
                        <OutlineButton type="onClick" onClick={() => openSetupLightbox()}>
                            Add Backup Method
                        </OutlineButton>
                    </div>
                </div>
            );
        };

        const handleConfiguredMethodRender = () => {
            return configuredMethods.map((data) => {
                const { method, method_value, is_primary } = data.attributes;
                switch (method) {
                    case 'EMAIL':
                        return (
                            <div key={method} className={`account2fa__configured email${is_primary ? ` primary` : ``}`}>
                                <div className="method__info">
                                    <i className="method__icon icon icon-email2"></i>
                                    <div className="method__frame">
                                        <div className="method__title">
                                            Email Two-Factor Enabled
                                            {is_primary ? (
                                                <SolidTag className="options__item__primarytag" color="success">
                                                    Primary
                                                </SolidTag>
                                            ) : (
                                                ``
                                            )}
                                        </div>
                                        <div className="method__desc">
                                            A code will be sent to <strong>{method_value}</strong>
                                        </div>
                                    </div>
                                </div>
                                <div className="method__action">
                                    {is_primary ? (
                                        <OutlineButton color="notice" type="onClick" onClick={() => openDisableLightbox(data.id, method)}>
                                            Disable
                                        </OutlineButton>
                                    ) : (
                                        <OutlineDropdown
                                            color="notice"
                                            title="Disable"
                                            titleOnClick={() => openDisableLightbox(data.id, method)}
                                            list={[
                                                {
                                                    label: 'Make Primary',
                                                    type: 'onClick',
                                                    onClick: () => setAsPrimary(data.id)
                                                }
                                            ]}
                                        />
                                    )}
                                </div>
                            </div>
                        );

                    case 'SMS':
                        return (
                            <div key={method} className={`account2fa__configured sms${is_primary ? ` primary` : ``}`}>
                                <div className="method__info">
                                    <i className="method__icon icon icon-phone1"></i>
                                    <div className="method__frame">
                                        <div className="method__title">
                                            SMS Two-Factor Enabled
                                            {is_primary ? (
                                                <SolidTag className="options__item__primarytag" color="success">
                                                    Primary
                                                </SolidTag>
                                            ) : (
                                                ``
                                            )}
                                        </div>
                                        <div className="method__desc">
                                            A code will be sent to <strong>{method_value}</strong>
                                        </div>
                                    </div>
                                </div>
                                <div className="method__action">
                                    <OutlineDropdown
                                        color="primary"
                                        title="Configure"
                                        titleOnClick={() => openSetupLightbox('sms')}
                                        list={handleRenderDropdownList(data)}
                                    />
                                </div>
                            </div>
                        );

                    case 'PUSH':
                        return (
                            <div key={method} className={`account2fa__configured app${is_primary ? ` primary` : ``}`}>
                                <div className="method__info">
                                    <img className="method__icon icon icon-gauth" alt="app" src={VIPControlApp} />
                                    <div className="method__frame">
                                        <div className="method__title">
                                            {application} App Enabled
                                            {is_primary ? (
                                                <SolidTag className="options__item__primarytag" color="success">
                                                    Primary
                                                </SolidTag>
                                            ) : (
                                                ``
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="method__action">
                                    <OutlineDropdown
                                        color="primary"
                                        title="Configure"
                                        titleOnClick={() => openSetupLightbox('push')}
                                        list={handleRenderDropdownList(data)}
                                    />
                                </div>
                            </div>
                        );
                    case 'APP':
                    default:
                        return (
                            <div key={method} className={`account2fa__configured app${is_primary ? ` primary` : ``}`}>
                                <div className="method__info">
                                    <img className="method__icon icon icon-gauth" alt="app" src={AppIcon} />
                                    <div className="method__frame">
                                        <div className="method__title">
                                            Authentication App Enabled
                                            {is_primary ? (
                                                <SolidTag className="options__item__primarytag" color="success">
                                                    Primary
                                                </SolidTag>
                                            ) : (
                                                ``
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="method__action">
                                    <OutlineDropdown
                                        color="primary"
                                        title="Configure"
                                        titleOnClick={() => openSetupLightbox('app')}
                                        list={handleRenderDropdownList(data)}
                                    />
                                </div>
                            </div>
                        );
                }
            });
        };

        const renderVIPControlAppStores = () => {
            return (
                <div className="account2fa__notification">
                    <img className="account2fa__appIcon" src={VIPControlApp} alt="Powered by Google" />
                    <div className="account2fa__vipcontrolApp">
                        <Text size--xl semiBold>
                            Download the new {application} app
                        </Text>
                        <Text size--m medium>
                            Secure your account with app based Two-Factor Authentication, check notifications, pay bills and renew your domain names.
                        </Text>
                    </div>
                    <div className="account2fa__stores">
                        <a className="" href="https://apps.apple.com/us/app/vipcontrol/id6448714430" target="_blank" rel="noreferrer">
                            <img className="" src={AppStore} alt="Download on the App Store" />
                        </a>
                        <a className="" href="https://play.google.com/store/apps/details?id=com.vipcontrol" target="_blank" rel="noreferrer">
                            <img className="" src={PlayStore} alt="GET IT ON Google Play" />
                        </a>
                    </div>
                </div>
            );
        };

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <ScrollableComponent ready={account_twofactor_status === 'success'} className="account2fa">
                {configuredMethods && configuredMethods.length <= 0 ? (
                    <Box
                        request={{
                            action: getTwoFactor,
                            args: false,
                            status: account_twofactor_status
                        }}
                        recommended={true}
                        className="account2fa__box"
                        title="Two-Factor Authentication"
                        desc="Two-factor authentication is an added layer of security to your account. Upon login, you'll need to provide a code along with your normal email address and password. This lets us know it's you."
                        status={account_twofactor_status}
                        info="When setting up Two-Factor Authentication, you have the option to set a backup authentication method which you can use in the event that you lose access to your primary method. Alternatively, you can contact our support team directly to go through the manual authentication process."
                        action={{
                            buttonType: 'Outline',
                            label: 'Enable',
                            type: 'onClick',
                            size: 'large',
                            onClick: () => openSetupLightbox()
                        }}
                    />
                ) : (
                    <Box
                        request={{
                            action: getTwoFactor,
                            args: false,
                            status: account_twofactor_status
                        }}
                        className="account2fa__box"
                        title="Two-Factor Authentication"
                        status={account_twofactor_status}
                        info="When setting up Two-Factor Authentication, you have the option to set a backup authentication method which you can use in the event that you lose access to your primary method. Alternatively, you can contact our support team directly to go through the manual authentication process."
                        custom={{
                            render: (
                                <Fragment>
                                    {handleConfiguredMethodRender()}
                                    {configuredMethods.length < 4 ? handleBackupMethodRender() : ''}
                                </Fragment>
                            ),
                            pos: 'bottom'
                        }}
                    />
                )}
                {isSetup2faLightboxOpen ? (
                    <OverlayLightbox
                        customMsg={activeBrand === 'ventra' ? renderVIPControlAppStores() : null}
                        title="Setup Two-Factor Authentication"
                        loading={account_primary_twofactor_status}
                        onOpen={isSetup2faLightboxOpen}
                        onClose={closeSetupLightbox}
                    >
                        <TwoFactorForm closeSetupLightbox={closeSetupLightbox} preset={showSetupMode} />
                    </OverlayLightbox>
                ) : (
                    ''
                )}
                {showDisableLightbox ? (
                    <OverlayLightbox
                        title="Remove Two-Factor Method"
                        warningMsg={
                            showDisableData.type === 'PUSH'
                                ? `PLEASE NOTE: Disabling this will log you out of the ${application} App on all devices.`
                                : ''
                        }
                        onOpen={showDisableLightbox}
                        loading={account_delete_twofactor_status}
                        confirm={{
                            desc: htmr(
                                `Are you sure you want to disable <strong>${showDisableData.displayText}</strong>? You will no longer be sent ${
                                    showDisableData.type === 'PUSH' ? 'push notifications to' : 'a unique code upon'
                                } login to your ${application} account.`
                            ),
                            buttonText: 'Disable',
                            buttonAction: disableSelected,
                            closeText: 'No, Keep Enabled',
                            closeAction: closeDisableLightbox
                        }}
                        onClose={closeDisableLightbox}
                        className="account2fa__overlay--remove"
                    />
                ) : (
                    ''
                )}
            </ScrollableComponent>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => {
    return {
        account_twofactor_status: state.account.account_twofactor_status,
        account_twofactor_data: state.account.account_twofactor_data,
        account_primary_twofactor_status: state.account.account_primary_twofactor_status,
        account_delete_twofactor_status: state.account.account_delete_twofactor_status,
        account_twofactor_verify_status: state.account.account_twofactor_verify_status
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getTwoFactor,
            setPrimaryTwoFactor,
            disableTwoFactor
        },
        dispatch
    );

export default withBrandStore(withRouter(connect(mapStateToProps, mapDispatchToProps)(TwoFactor)));
