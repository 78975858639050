import { useStore } from '@tanstack/react-store';
import { superUserItemRenderTracker } from 'components/StaffMenu/SuperUser/consts';
import type { SuperUserNamespace } from 'components/StaffMenu/SuperUser/types';
import type { Entries } from 'type-fest';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export function useRenderedSuperUserItems<TReturnValue = SuperUserNamespace.Key[]>(
    selector?: (state: SuperUserNamespace.Key[]) => TReturnValue
): TReturnValue {
    /***** HOOK RESULTS *****/
    return useStore(superUserItemRenderTracker, (record) => {
        const filteredValues = (Object.entries(record) as Entries<typeof record>).filter(([, count]) => count && count > 0).map(([key]) => key);
        return selector ? selector(filteredValues) : filteredValues;
    }) as TReturnValue;
}
