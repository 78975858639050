import { useEffect, useRef } from 'react';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type UseWebOTPOpts = {
    onSuccess?: (OTP: OTPCredential | null) => void;
    onError?: (error: unknown) => void;
};

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Provides a hook to use the WebOTP API to automatically fill in the OTP code from the SMS.
 */
export const useWebOTP = (opts?: UseWebOTPOpts) => {
    /***** STATE *****/
    const inputRef = useRef<HTMLInputElement>(null);
    const abortController = useRef<AbortController | null>(null);

    /***** EFFECTS *****/
    useEffect(() => {
        if (!('OTPCredential' in window)) return;
        if (!inputRef?.current) return;

        abortController.current = new AbortController();

        navigator.credentials
            .get({
                otp: { transport: ['sms'] },
                signal: abortController.current.signal
            })
            .then((OTP: Credential | null) => {
                // WebOTP Types not implemented yet. Custom types defined as per https://wicg.github.io/web-otp/#sctn-discover-from-external-source
                // in `src/types.d.ts` file.
                opts?.onSuccess?.(OTP as any);

                if (abortController.current?.abort) {
                    abortController.current?.abort();
                }
            })
            .catch((error) => {
                if (error instanceof Error && error.name !== 'AbortError') {
                    return;
                }

                opts?.onError?.(error);
            });

        // make sure to clean up the abort controller on unmount
        return () => abortController.current?.abort();
    }, []);

    /***** HOOK RESULTS *****/
    return { inputRef, abortController };
};
