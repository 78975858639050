/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import type { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { _CheckBoxListItem as CheckBoxListItem } from 'components/Form/CheckBoxList/_CheckBoxListItem';
import { ValidationMessage } from 'components/Form/ValidationMessage';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _CheckBoxListItemReduxFormDefault: React.FC<{
    input: WrappedFieldInputProps;
    meta: WrappedFieldMetaProps;
    children: React.ReactNode;
    inverse?: boolean;
    disabled?: boolean;
}> = ({ input, meta, children, inverse, disabled = false }) => {
    const { value, onChange } = input;

    /***** FUNCTIONS *****/
    const handleChangeTransform = (value: boolean) => {
        if (inverse) {
            onChange(!value);
            return;
        }
        onChange(value);
    };
    
    /***** RENDER HELPERS *****/
    const valueTransform = inverse ? !value : value;

    /***** RENDER *****/
    return (
        <CheckBoxListItem isChecked={valueTransform} onChange={handleChangeTransform} disabled={disabled}>
            {children}
            <ValidationMessage.ReduxForm.Default {...meta} />
        </CheckBoxListItem>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
