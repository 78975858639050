import type { KatanaNamespace } from 'containers/katana/types';
import type { KATANA_API_V2 } from 'utilities/api/katana/v2';

export const KatanaQueryKeysV2 = {
    katana: Object.assign(() => ['katana', 'v2'] as const, {
        service: Object.assign(() => [...KatanaQueryKeysV2.katana(), 'service'] as const, {
            Id: Object.assign((serviceId: KatanaNamespace.ServiceId) => [...KatanaQueryKeysV2.katana.service(), serviceId] as const, {
                preview: Object.assign(
                    (serviceId: KatanaNamespace.ServiceId) => [...KatanaQueryKeysV2.katana.service.Id(serviceId), 'preview'] as const,
                    {
                        page: Object.assign(
                            ({ serviceId, pageId }: KATANA_API_V2.site.service_id.preview.page.page_id.GET.Params) =>
                                [...KatanaQueryKeysV2.katana.service.Id.preview(serviceId), 'page', pageId] as const,
                            {}
                        ),
                        section: Object.assign(
                            (serviceId: KatanaNamespace.ServiceId) => [...KatanaQueryKeysV2.katana.service.Id.preview(serviceId), 'section'] as const,
                            {
                                section_id: Object.assign(
                                    ({ serviceId, sectionId }: KATANA_API_V2.site.service_id.preview.section.section_id.GET.Params) =>
                                        [...KatanaQueryKeysV2.katana.service.Id.preview(serviceId), sectionId] as const,
                                    {}
                                ),
                                new_section: Object.assign(
                                    ({
                                        serviceId,
                                        sectionDefinitionId,
                                        attributes,
                                    }: KATANA_API_V2.site.service_id.preview.section.new_section.GET.Params) =>
                                        [
                                            ...KatanaQueryKeysV2.katana.service.Id.preview(serviceId),
                                            'new_section',
                                            sectionDefinitionId,
                                            attributes,
                                        ] as const,
                                    {}
                                ),
                            }
                        ),
                    }
                ),
            }),
        }),
    }),
};
