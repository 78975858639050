import { KatanaQueryKeysV2 } from 'containers/katana/queries/v2/queryKeys';
import { KATANA_API_V2 } from 'utilities/api/katana/v2';
import { createNXQuery } from 'utilities/methods/tanstack/createQuery';

// prettier-ignore
export class KatanaQueryV2 {

    /**
	 * Previewing an existing page
	 */
    public static pagePreview = createNXQuery((
        params: KATANA_API_V2.site.service_id.preview.page.page_id.GET.Params
    ) => ({
        queryFn: () => KATANA_API_V2.site.service_id.preview.page.page_id.GET(params),
        queryKey: KatanaQueryKeysV2.katana.service.Id.preview.page(params),
        staleTime: Infinity
    }));

    /**
	 * Previewing an existing section
	 */
    public static sectionPreview = createNXQuery((
        params: KATANA_API_V2.site.service_id.preview.section.section_id.GET.Params
    ) => ({
        queryFn: () => KATANA_API_V2.site.service_id.preview.section.section_id.GET(params),
        queryKey: KatanaQueryKeysV2.katana.service.Id.preview.section.section_id(params),
        staleTime: Infinity
    }));

    /**
	 * Previewing a section that doesn't exist, based on the attributes passed through
	 */
    public static newSectionPreview = createNXQuery((
        params: KATANA_API_V2.site.service_id.preview.section.new_section.GET.Params
    ) => ({
        queryFn: () => KATANA_API_V2.site.service_id.preview.section.new_section.GET(params),
        queryKey: KatanaQueryKeysV2.katana.service.Id.preview.section.new_section(params),
        staleTime: Infinity
    }));
}
