import { useNavigate } from '@tanstack/react-router';
import { CustomDropdown } from 'components/Dropdowns/CustomDropdown';
import SelectOptions from 'components/Dropdowns/SelectDropdown/Options';
import { getTicketSubmitUrl } from 'containers/support/methods';
import { useSelector } from 'react-redux';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const GoogleTitleDropdown = () => {
    /***** HOOKS *****/
    const id = useSelector((state: any) => state.gsuite.gsuite_service_data?.id);
    const navigate = useNavigate();

    /***** RENDER *****/
    return (
        <CustomDropdown
            className="TitleShared__singleDropdown"
            label={({ labelRef, dropdownTitleOnClick }) => (
                <button className="TitleShared__singleDropdownButton" ref={labelRef} type="button" onClick={() => dropdownTitleOnClick()}>
                    Google Workspace Help
                    <i className="item__icon icon icon-chevron-down" />
                </button>
            )}
            key="dropdown"
            render={() => (
                <SelectOptions
                    options={[
                        {
                            label: 'Google Workspace Help Articles',
                            onSelect: () =>
                                navigate({
                                    to: '/support/support-centre',
                                    search: {
                                        categories: 'products, google-workspace'
                                    }
                                })
                        },
                        {
                            label: 'Submit a Technical Support eTicket for this Service',
                            onSelect: () => navigate({ to: getTicketSubmitUrl({ department: 'technical-support', service: id }) })
                        },
                        {
                            label: 'Submit a Billing eTicket for this Service',
                            onSelect: () => navigate({ to: getTicketSubmitUrl({ department: 'accounts-billing', service: id }) })
                        }
                    ]}
                />
            )}
        />
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
