import { useSuperUserItemPortal } from 'components/StaffMenu/SuperUser/components/SuperUserItemPortal/useSuperUserItemPortal';
import type { SuperUserNamespace } from 'components/StaffMenu/SuperUser/types';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Props = {
    actionKey: SuperUserNamespace.Key;
};
type SuperUserItemPortalConsumer = React.FC<Props>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const SuperUserItemPortalConsumer: SuperUserItemPortalConsumer = ({ actionKey }) => {
    /***** HOOKS *****/
    const superUserComponents = useSuperUserItemPortal(actionKey);

    /***** RENDER *****/
    if (!superUserComponents?.length) return null;
    return superUserComponents;
};
