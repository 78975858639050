/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import { useDomainManageRouteSelector } from 'containers/domain/hooks';
import { getCCTLD } from 'containers/domain/methods';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import { useEffect } from 'react';
import { useIsVPN } from 'utilities/hooks/redux/useIsVPN';
import { createModuleStore } from 'utilities/methods/createModuleStore';

/**********************************************************************************************************
 *   MODULE STORE START
 **********************************************************************************************************/
/***** EXPORTS *****/
export const DomainModuleStore = createModuleStore(
    {
        general: {
            existingVIPSites: {
                enabled: false,
                label: 'VIPsites Service',
                to: '/my-services/domains/$domainId/general',
                hash: 'add-vip-site'
            },
            overview: {
                enabled: true,
                label: 'Overview',
                to: '/my-services/domains/$domainId/general',
                hash: 'overview'
            },
            renew: {
                enabled: true,
                label: 'Renew',
                to: '/my-services/domains/$domainId/general',
                hash: 'renew'
            },
            registrantEmail: {
                enabled: true,
                label: 'Registrant Email',
                to: '/my-services/domains/$domainId/general',
                hash: 'registrant-email'
            },
            contacts: {
                enabled: true,
                label: 'Contacts',
                to: '/my-services/domains/$domainId/general',
                hash: 'contacts'
            },
            eligibilityInformation: {
                enabled: false,
                label: 'Eligibility Information',
                to: '/my-services/domains/$domainId/general',
                hash: 'eligibility-information'
            },
            addHosting: {
                enabled: false,
                label: 'Add Hosting Service',
                to: '/my-services/domains/$domainId/general',
                hash: 'add-hosting'
            },
            newVIPSites: {
                enabled: false,
                label: 'Add VIPsites Service',
                to: '/my-services/domains/$domainId/general',
                hash: 'add-vip-site'
            }
        },
        dns: {
            manageDNS: {
                enabled: true,
                label: 'Manage DNS',
                to: '/my-services/domains/$domainId/manage',
                hash: 'dns'
            },
            childNameservers: {
                enabled: true,
                label: 'Child Nameservers',
                to: '/my-services/domains/$domainId/manage',
                hash: 'child-nameservers'
            },
            DNSSEC: {
                enabled: true,
                label: 'DNSSEC DS Data',
                to: '/my-services/domains/$domainId/manage',
                hash: 'dnssec'
            }
        },
        security: {
            idProtection: {
                enabled: true,
                label: 'ID Protection',
                to: '/my-services/domains/$domainId/security',
                hash: 'id-protection'
            },
            transfer: {
                enabled: true,
                label: 'Transfer Lock',
                to: '/my-services/domains/$domainId/security',
                hash: 'transfer'
            },
            epp: {
                enabled: true,
                label: 'EPP Password',
                to: '/my-services/domains/$domainId/security',
                hash: 'epp'
            }
        },
        admin: {
            move: {
                enabled: true,
                label: 'Move Domain Name',
                to: '/my-services/domains/$domainId/admin',
                hash: 'move'
            },
            cor: {
                enabled: true,
                label: 'Change of Registrant',
                to: '/my-services/domains/$domainId/admin',
                hash: 'cor'
            },
            delete: {
                enabled: true,
                label: 'Delete Domain Name',
                to: '/my-services/domains/$domainId/admin',
                hash: 'delete'
            }
        }
    },
    useModuleConditions
);

function useModuleConditions(args: any) {
    const store = createModuleStore.castHookProps<typeof DomainModuleStore>(args);

    /***** HOOKS *****/
    const { domain_information_data, hosting_list_data } = useDomainManageRouteSelector();
    const isVPN = useIsVPN();

    /***** RENDER HELPERS *****/
    const domain = domain_information_data?.attributes?.domain;
    const ccTLD = getCCTLD(domain);
    const { data: check_katana_domain_data } = katanaQuery.checkDomain.useQuery(domain, isVPN);

    const hasCorrespondingHostingService = hosting_list_data?.find(
        (service: any) => service.attributes?.domain === domain_information_data?.attributes?.domain
    );

    /***** EFFECTS *****/
    useEffect(() => {
        store.batch(() => {
            store.updateChangedStoreKey('general.addHosting.enabled', !hasCorrespondingHostingService);
            store.updateChangedStoreKey('general.eligibilityInformation.enabled', ccTLD && ccTLD?.au);
            store.updateChangedStoreKey('general.existingVIPSites.enabled', !!check_katana_domain_data?.site_exists);
            store.updateChangedStoreKey('general.newVIPSites.enabled', !!check_katana_domain_data && !check_katana_domain_data?.site_exists);
            store.updateChangedStoreKey('security.idProtection.enabled', !ccTLD);
            store.updateChangedStoreKey('security.transfer.enabled', !ccTLD);
            store.updateChangedStoreKey('admin.cor.enabled', ccTLD && ccTLD?.au);

            store.updateEnabledWithReason(
                'admin.delete',
                !!domain_information_data?.attributes?.auto_online_giveaway,
                'The delete module is only available if auto_online_giveaway is in the domains attributes'
            );
        });
    });
} 
