import { useMutation } from '@tanstack/react-query';
import type { KatanaNamespace } from 'containers/katana/types';
import { KATANA_API } from 'utilities/api/katana';

type Params = Parameters<typeof KATANA_API.katana.service_id.user.socials.$type.link.GET>;

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useMutation(serviceID: KatanaNamespace.ServiceId) {
    return useMutation({
        mutationFn: (socialType: Params[1]) => KATANA_API.katana.service_id.user.socials.$type.link.GET(serviceID, socialType),
        onSuccess: () => {},
    });
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const socialsAuthorisationLink = Object.freeze({
    useMutation: _useMutation,
});
