import {
    queryOptions,
    type InvalidateQueryFilters,
    type QueryFilters,
    type QueryKey,
    type RefetchOptions,
    type ResetOptions,
} from '@tanstack/react-query';
import { queryClient } from 'store/queryClient';
import type {
    CreateQueryOptionsCallback,
    CreateQueryOptionsReturn,
    InternalEnsureQueryDataOptions,
    InternalFetchQueryOptions,
} from 'utilities/methods/tanstack/createQuery/factories/types';
import { createSetQueryDataMethod } from 'utilities/methods/tanstack/createSetQueryDataMethod';
import { createOptimisticMethods } from 'utilities/methods/tanstack/optimistic/createOptimisticMethods';
import type { NXQueryUtils } from 'utilities/methods/tanstack/types';

/**********************************************************************************************************
 *   FACTORY START
 **********************************************************************************************************/
export class InternalQueryMethodFactory<TParams, TQueryFnData extends NXQueryUtils.ApiData, TError, TData, TQueryKey extends QueryKey> {
    constructor(private defaultCreateQueryOptions: CreateQueryOptionsCallback<TParams, TQueryFnData, TError, TData, TQueryKey>) {}

    /**
     * Function used to get the query options for the given set of params. If only the query key is required for passing to a queryClient
     * method, then `createQueryKey` should be used instead.
     */
    public createQueryOptions = <TInternalData = TData>(
        params: TParams
    ): CreateQueryOptionsReturn<TInternalData, TQueryFnData, TError, TQueryKey> => {
        type Select = (data: TQueryFnData) => TInternalData;
        const options = this.defaultCreateQueryOptions(params);

        const defaultSelect = (d: TQueryFnData) => d;
        const select = (options.select ?? defaultSelect) as unknown as Select;

        // @ts-expect-error - queryOptions doesn't think it returns `queryFn` but it must since options MUST have queryFn
        return queryOptions({ ...options, select });
    };

    /**
     * Function for getting the query key for the provided params. This is useful if the entire queryOptions object is not required
     */
    public createQueryKey = (params: TParams) => this.createQueryOptions(params).queryKey;

    /**
     * Used to invalidate the query by directly invalidating the query key provided as default. This can be overridden by assigning a new "invalidate"
     * function to the returned object.
     *
     * @see {@link https://tanstack.com/query/latest/docs/reference/QueryClient/#queryclientinvalidatequeries}
     */
    public invalidate = (params: TParams, opts?: Omit<InvalidateQueryFilters, 'queryKey'>) =>
        queryClient.invalidateQueries({ queryKey: this.createQueryKey(params), ...opts });

    /**
     * Removes a query from the query cache based on the query key. this can be overridden by assigning a new "remove" function to the returned object
     *
     * @see {@link https://tanstack.com/query/latest/docs/reference/QueryClient/#queryclientremovequeries}
     */
    public remove = (params: TParams, opts?: Omit<QueryFilters, 'queryKey'>) =>
        queryClient.removeQueries({ queryKey: this.createQueryKey(params), ...opts });

    /**
     * Reset the query to it's initial state.
     *
     * @see {@link https://tanstack.com/query/latest/docs/reference/QueryClient/#queryclientresetqueries}
     */
    public reset = (params: TParams, options?: ResetOptions) => queryClient.resetQueries({ queryKey: this.createQueryKey(params), ...options });

    /**
     * Get the data for this query from the query cache. This will not attempt to fetch the data if it does not exist or is stale
     *
     * @see {@link https://tanstack.com/query/latest/docs/reference/QueryClient/#queryclientgetquerydata}
     */
    public getData = (params: TParams) => queryClient.getQueryData(this.createQueryKey(params));

    /**
     * Will refetch the query based on the queryKey. This will ignore existing cache data and will fetch the data from the server.
     *
     * @see {@link https://tanstack.com/query/latest/docs/reference/QueryClient/#queryclientrefetchqueries}
     */
    public refetch = (params: TParams, options?: RefetchOptions) =>
        queryClient.refetchQueries({ queryKey: this.createQueryKey(params), stale: true }, options);

    /**
     * Will fetch the query based on the queryKey using `queryClient.fetchQuery`. This will either resolve if there is (non-stale) cache data, or fetch the data from the server and THEN return the fresh data.
     * This is a common confusion point, but the difference between this and `ensureQueryData` is that `ensureQueryData` will immediately return stale data from the cache and then fetch in the background,
     * so if the latest data is necessary, use `fetch`.
     *
     * @see {@link https://tanstack.com/query/latest/docs/reference/QueryClient/#queryclientfetchquery}
     */
    public fetch = (params: TParams, options?: InternalFetchQueryOptions<TQueryFnData, TError, TQueryKey>) => {
        return queryClient.fetchQuery({
            ...this.createQueryOptions(params),
            ...options,
        });
    };

    /**
     * Similar to `fetch`, but first invalidates the query to ensure the data is re-fetched. Use this when you need to get the latest data from the server
     * and the cache should be ignored. This is typically not a common scenario, but for queries that rely on external state that is not mapped
     * to the queryKey such as a users cookie data, a manual force fetch will normally be required.
     */
    public forceFetch = async (params: TParams, options?: Omit<InternalFetchQueryOptions<TQueryFnData, TError, TQueryKey>, 'staleTime'>) => {
        await this.invalidate(params);
        return await this.fetch(params, options);
    };

    public prefetch = async (params: TParams) => {
        return queryClient.prefetchQuery(this.createQueryOptions<TQueryFnData>(params));
    };

    /**
     * Will ensure the query data is in the cache, and if not, will fetch the data from the server. If there is data in the cache, but the data is stale,
     * this will return the stale data and then fetch the fresh data in the background. If the latest data is necessary, use `fetch`.
     *
     * @see {@link https://tanstack.com/query/latest/docs/reference/QueryClient/#queryclientensurequerydata}
     */
    public ensureData = (params: TParams, options?: InternalEnsureQueryDataOptions<TQueryFnData, TError, TQueryKey>) => {
        return queryClient.ensureQueryData({
            ...this.createQueryOptions<TQueryFnData>(params),
            ...options,
        });
    };

    /**
     * optimistic update methods for Setting, Merging and Filtering data at specific layers of the query data object
     *
     * - when an attached function is called, the data in the tanstack query store is updated.
     * - the called function returns an object with a method `restore` on it, allowing the consumer to "undo" their optimistic update.
     */
    public optimistic = createOptimisticMethods(createSetQueryDataMethod<TParams, TQueryFnData>(this.createQueryKey));
}
