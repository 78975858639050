import { queryOptions, useQuery } from '@tanstack/react-query';
import { katanaQueryKeys } from 'containers/katana/queries/katanaQueryKeys';
import { pages } from 'containers/katana/queries/serviceID/pages';
import _ from 'lodash';
import { queryClient } from 'store/queryClient';
import { KATANA_API } from 'utilities/api/katana';
import { SECOND } from 'utilities/consts';
import { TruthKeys } from 'utilities/methods/commonActions/Truth';
import { createSetQueriesDataMethod } from 'utilities/methods/tanstack/createSetQueriesDataMethod';
import { createOptimisticMethods } from 'utilities/methods/tanstack/optimistic/createOptimisticMethods';
import type { NXQueryUtils } from 'utilities/methods/tanstack/types';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type TData = Awaited<ReturnType<typeof KATANA_API.katana.site.service_id.pages.page_id.GET>>;
type Params = Parameters<typeof KATANA_API.katana.site.service_id.pages.page_id.GET>[0];

function createQueryKey(params: Params) {
    return katanaQueryKeys.katana.service.ID.pages.ID(params);
}

function createQueryOptions(params: Params) {
    return queryOptions({
        queryKey: createQueryKey(params),
        queryFn: async () => {
            const result = await KATANA_API.katana.site.service_id.pages.page_id.GET(params);

            const pagesQueryData = pages.getQueryData(params.serviceID);

            if (pagesQueryData) {
                pages.optimistic.setWith(params.serviceID, 'data', (value) => {
                    return value.map((page) => {
                        if (page.id === params.pageID) {
                            return _.merge(page, result.data);
                        }
                        return page;
                    });
                });
            }

            return result;
        },
        staleTime: SECOND * 25,
        enabled: TruthKeys(params),
        select: (data) => {
            if (data?.status === 200) {
                return data.data;
            }
        }
    });
}

function prefetchQuery(params: Params) {
    return queryClient.prefetchQuery(createQueryOptions(params));
}

const setQueryData = createSetQueriesDataMethod<Params, NXQueryUtils.ApiData200<TData>>(createQueryKey);
const removeQueries = (params: Params) => {
    queryClient.removeQueries({ queryKey: createQueryKey(params), exact: true });
};

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Gets the page from a service
 */
function _useQuery(serviceID: Params) {
    return useQuery(createQueryOptions(serviceID));
}

export const page = Object.freeze({
    useQuery: _useQuery,
    prefetchQuery,
    optimistic: createOptimisticMethods(setQueryData),
    removeQueries
});
