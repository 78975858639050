import classNames from 'classnames';
import type { ReactNode } from 'react';
import React from 'react';
import './_WrapperBox.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Props = {
    error?: boolean;
    isPrepended?: boolean;
    disabled?: boolean;
    validatedValid?: boolean;
    validatedInvalid?: boolean;
    className?: string;
    children: ReactNode;
    fullWidth?: boolean;
};

type _WrapperBox = React.FC<Props>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _WrapperBox: _WrapperBox = ({ error, isPrepended, disabled, validatedValid, validatedInvalid, className, children, fullWidth }) => {
    /***** RENDER HELPERS *****/
    const wrapperBoxClass = classNames('wrapperbox', className, {
        'wrapperbox--fullWidth': fullWidth,
        'wrapperbox--error': error,
        'error': error,
        'wrapperbox--isPrepended': isPrepended,
        'wrapperbox--disabled': disabled,
        'wrapperbox--validated_valid': validatedValid,
        'wrapperbox--validated_invalid': validatedInvalid
    });

    /***** RENDER *****/
    return <div className={wrapperBoxClass}>{children}</div>;
};
