import classNames from 'classnames';
import { useRawTabsContext } from 'components/NXBox/Tabs/contexts/useRawTabsContext';
import { useTabsContext } from 'components/NXBox/Tabs/contexts/useTabContext';
import { createPortal } from 'react-dom';
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';

import './_NXBoxTab.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type NXBoxTab = React.FC<NXBoxTabProps>;
type NXBoxTabProps = {
    children?: React.ReactNode;
    icon?: string;
    className?: string;
    id: string;
    disabled?: boolean;
    visuallyDisabled?: boolean;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _NXBoxTab: NXBoxTab = ({ id, children, className, disabled, visuallyDisabled }) => {
    /***** HOOKS *****/
    const { isActive, activateTab, handleKeyDown, registerTabsRefs, portalRef } = useTabsContext(id);
    const { setShowMobileTabs, showMobileTabs } = useRawTabsContext();
    const isMobile = useAppViewport(['xs', 'sm']);

    /***** FUNCTIONS *****/
    function handleClick() {
        setShowMobileTabs(false);
        activateTab();
    }

    /***** RENDER HELPERS *****/
    const renderTitle = () => {
        const title = <div onClick={() => setShowMobileTabs(!showMobileTabs)}>{children}</div>;

        return portalRef.current ? createPortal(title, portalRef.current) : null;
    };

    return (
        <>
            {isMobile && isActive && renderTitle()}
            {(showMobileTabs || !isMobile) && (
                <button
                    ref={registerTabsRefs}
                    className={classNames('NXBoxTab', className, {
                        'NXBoxTab--active': isActive,
                        'NXBoxTab--visuallyDisabled': visuallyDisabled || disabled
                    })}
                    onClick={handleClick}
                    onKeyDown={handleKeyDown}
                    disabled={disabled}
                    data-name={id}
                    role="tab"
                    aria-selected={isActive}
                    id={`tab-${id}`}
                    aria-controls={`tabcontent-${id}`}
                    tabIndex={isActive ? 0 : -1}
                >
                    {children}
                </button>
            )}
        </>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
