import { useNavigate, useParams, useSearch } from '@tanstack/react-router';
import { useRegistrationOrderingFormContext } from 'App/components/templates/contexts/registrationOrderingFormContext/useRegistrationOrderingFormContext';
import classNames from 'classnames';
import { useSuperUserItem } from 'components/StaffMenu/SuperUser/hooks/useSuperUserItem';
import { Flex } from 'components/Utils/Flex';
import 'containers/orderingForm/_orderingForm.scss';
import { OrderingFormContext } from 'containers/orderingForm/contexts/orderingFormContext';
import { Footer } from 'containers/orderingForm/footer';
import { ConfigurationPage } from 'containers/orderingForm/pages/configurationPage';
import { ConfirmOrderPage } from 'containers/orderingForm/pages/confirmOrderPage';
import { EditItemConfigPage } from 'containers/orderingForm/pages/editItemConfigPage';
import { RegisterPage } from 'containers/orderingForm/pages/registerPage';
import { TransferPage } from 'containers/orderingForm/pages/transferPage';
import type { DomainData, Item } from 'containers/orderingForm/types';
import { useEffect, useState } from 'react';
import { useDebounceValue } from 'usehooks-ts';
import { NXQuery } from 'utilities/query';
import { UserPreferences } from 'utilities/UserPreferences';
import { OrderingFormStore } from './store';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const OrderingForm = () => {
    /***** STATE *****/
    const [cartToken, setCartToken] = useState(UserPreferences.getItem('CART_TOKEN') || null);
    const [currentPage, setCurrentPage] = useState('register');
    const [currentItem, setCurrentItem] = useState<Item | null>(null);
    const [domainSearchValue, setDomainSearchValue] = useState('');
    const [debounceDomainSearchValue] = useDebounceValue(domainSearchValue, 500);
    const isOrderingFormForcedOpen = useSuperUserItem('FORCE_ORDERING_FORM_OPEN');

    /***** HOOKS *****/
    const { isRegistration, isDomainRegistrationFormOpen } = useRegistrationOrderingFormContext();
    const navigate = useNavigate();
    const { katanaServiceId } = useParams({ strict: false });

    const pages = ['register', 'transfer', 'configuration', 'editItemConfiguration', 'confirmOrder'];
    const currentIndex = pages.indexOf(currentPage);

    // When the ordering form is opened via the VIPsites express flow we want to populate the search term
    const initialSearchTerm = useSearch({ strict: false, select: (search) => search['vip-express-add-domains'] });

    /***** QUERIES *****/
    const { data: cart_data } = NXQuery.orderingForm.cart.cartId.getCart.useQuery(cartToken);

    /***** FUNCTIONS *****/
    // split search term into domain name and tld
    const [domain, ...tldParts] = debounceDomainSearchValue.split('.');
    const tld = tldParts.length > 0 ? `.${tldParts.join('.')}` : null;

    const domainData = {
        domain: domain,
        action: 'register',
        promos: [],
        tlds: [tld ? tld : '.com']
    } as DomainData;

    /***** EFFECTS *****/
    // Add event listener to watch the creation of cart token in local storage
    useEffect(() => {
        const handleTokenCreation = () => {
            const updatedToken = UserPreferences.getItem('CART_TOKEN');
            if (updatedToken) {
                setCartToken(updatedToken);
            }
            setCartToken(null);
        };

        window.addEventListener('cartTokenChanges', handleTokenCreation);

        return () => {
            window.removeEventListener('cartTokenChanges', handleTokenCreation);
        };
    }, []);

    useEffect(() => {
        if (!cart_data) return;

        OrderingFormStore.setState((prev) => {
            const isPremiumAcknowledgedMap = { ...prev.isPremiumAcknowledgedMap };

            // Add missing premium domains
            cart_data?.cart?.items.forEach((item) => {
                if (item.is_premium && !(item.uuid in isPremiumAcknowledgedMap)) {
                    isPremiumAcknowledgedMap[item.uuid] = false;
                }
            });

            return { ...prev, isPremiumAcknowledgedMap };
        });
    }, [cart_data]);

    //set the initail form page
    useEffect(() => {
        if (isRegistration || !isDomainRegistrationFormOpen) {
            setCurrentPage('register');
            return;
        }

        setCurrentPage('transfer');
    }, [isRegistration, isDomainRegistrationFormOpen]);

    // When the ordering form is opened via the VIPsites express flow we want to populate the search term
    useEffect(() => {
        if (initialSearchTerm) {
            setDomainSearchValue(initialSearchTerm);

            navigate({
                to: '/my-services/vip-sites/$katanaServiceId/overview',
                params: { katanaServiceId },
                search: {}
            });
        }
    }, [initialSearchTerm]);

    /***** FUNCTIONS *****/
    function goToPage(page: string) {
        setCurrentPage(page);
    }

    function goForwardPage() {
        setCurrentPage(pages[currentIndex + 1] as string);
    }

    function goBackPage() {
        setCurrentPage(pages[currentIndex - 1] as string);
    }

    const clearState = () => {
        setCartToken(null);
        setCurrentPage('register');
        setCurrentItem(null);
        setDomainSearchValue('');
    };

    /***** RENDER *****/
    return (
        <>
            <div
                className={classNames('domainRegistration__opaquePanel', {
                    'domainRegistration__opaquePanel--open': isOrderingFormForcedOpen || isDomainRegistrationFormOpen
                })}
            />
            <Flex
                direction="column"
                justify="between"
                className={classNames('domainRegistration__modal', {
                    'domainRegistration__modal--open': isOrderingFormForcedOpen || isDomainRegistrationFormOpen
                })}
            >
                <OrderingFormContext.Provider
                    value={{
                        cartToken,
                        setCartToken,
                        goToPage,
                        goForwardPage,
                        goBackPage,
                        currentPage,
                        currentItem,
                        setCurrentItem,
                        clearState,
                        domainData
                    }}
                >
                    <Flex direction="column" className="domainRegistration__pageContainer">
                        <RegisterPage name="register" domainSearchValue={domainSearchValue} setDomainSearchValue={setDomainSearchValue} />
                        <TransferPage name="transfer" domainSearchValue={domainSearchValue} setDomainSearchValue={setDomainSearchValue} />
                        <ConfigurationPage name="configuration" />
                        <EditItemConfigPage name="editItemConfiguration" />
                        <ConfirmOrderPage name="confirmOrder" />
                    </Flex>
                    <Footer />
                </OrderingFormContext.Provider>
            </Flex>
        </>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
