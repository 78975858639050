import Anchor from 'components/Anchor';
import { pushNotification } from 'components/Toast/functions';
import { Flex } from 'components/Utils/Flex';
import { Padding } from 'components/Utils/Padding';
import { useLoginContext } from 'containers/login/consts';
import { DefaultTwoFactorForm } from 'containers/login/forms/LoginTwoFactor/defaultForm';
import { TwoFactorTemplateHeading } from 'containers/login/forms/LoginTwoFactor/TwoFactorHeading';
import { useEffect } from 'react';
import type { API } from 'utilities/api/login';
import { NXQuery } from 'utilities/query';
import VipcAppTwoFactorPolling from './vipcAppTwoFactorPolling';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type LoginTwoFactor = React.FC<{
    backupMethod?: API.login.twoFactor.listMethods.Method;
}>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const LoginTwoFactor: LoginTwoFactor = ({ backupMethod }) => {
    /***** HOOKS *****/
    const { setView } = useLoginContext();

    /***** QUERIES *****/
    const { mutate: requestAlternativeTwoFactor } = NXQuery.auth.twoFactor.requestAlternate.useMutation();
    const { mutate: triggerDefaultTwoFactor } = NXQuery.auth.twoFactor.requestDefault.useMutation();
    const { data: template } = NXQuery.auth.twoFactor.list.useSelectSuspenseQuery(void 0, ({ data }) => {
        const primaryMethod = data.find(({ attributes }) => attributes?.is_primary)?.attributes.method;
        return backupMethod || primaryMethod;
    });

    /***** EFFECTS *****/
    useEffect(() => {
        // Ensure that we are requesting the correct two-factor code based on the backup method
        // Whenever this changes. Backup method should not change once this component mounts, as such
        // this should only run once on mount. Potentially, if this component get's a unique route,
        // this can be moved to the loader

        if (backupMethod) {
            requestAlternativeTwoFactor(backupMethod);
        } else {
            triggerDefaultTwoFactor();
        }
    }, [backupMethod]);

    /***** FUNCTIONS *****/
    const handleResendCode = () => {
        if (template) {
            requestAlternativeTwoFactor(template, {
                onSuccess(response) {
                    pushNotification(response, 8000, 'global');
                },
            });
        }
    };

    const handleBackToLogin = () => {
        NXQuery.auth.twoFactor.list.remove();
        setView('login');
    };

    /***** RENDER *****/
    return (
        <div className="twoFactor">
            <TwoFactorTemplateHeading template={template} />
            {template === 'PUSH' ? <VipcAppTwoFactorPolling /> : <DefaultTwoFactorForm />}
            <Padding top={2}>
                <Flex justify="between" align="center" direction="column" gap={2}>
                    <Anchor onClick={() => setView('alternative-2fa')}>More Two-Factor Options</Anchor>
                    {template !== 'GAUTH' && (
                        <Anchor onClick={handleResendCode}>
                            {template === 'PUSH' ? 'Resend Verification Push Notification' : 'Resend Verification Code'}
                        </Anchor>
                    )}
                    <Anchor onClick={handleBackToLogin}>Back to Login Page</Anchor>
                </Flex>
            </Padding>
        </div>
    );
};
