export const HOSTING_ADMIN_INITIAL_STATE = 'hosting/HOSTING_ADMIN_INITIAL_STATE';
export const HOSTING_ADMIN_REQUEST_RESET = 'hosting/HOSTING_ADMIN_REQUEST_RESET';

export const HOSTING_CANCEL_REQUEST = 'Hosting/HOSTING_CANCEL_REQUEST';
export const HOSTING_CANCEL_SUCCESS = 'Hosting/HOSTING_CANCEL_SUCCESS';
export const HOSTING_CANCEL_FAIL = 'Hosting/HOSTING_CANCEL_FAIL';

export const HOSTING_ACCOUNT_INITIAL_STATE = 'hosting/HOSTING_ACCOUNT_INITIAL_STATE';
export const HOSTING_SUBACCOUNT_INITIAL_STATE = 'hosting/HOSTING_SUBACCOUNT_INITIAL_STATE';

export const HOSTING_INFORMATION_RESET = 'hosting/HOSTING_INFORMATION_RESET';
export const HOSTING_INFORMATION_REQUEST = 'hosting/HOSTING_INFORMATION_REQUEST';
export const HOSTING_INFORMATION_SUCCESS = 'hosting/HOSTING_INFORMATION_SUCCESS';
export const HOSTING_INFORMATION_FAIL = 'hosting/HOSTING_INFORMATION_FAIL';

export const HOSTING_PLESK_REQUEST = 'hosting/HOSTING_PLESK_REQUEST';
export const HOSTING_PLESK_SUCCESS = 'hosting/HOSTING_PLESK_SUCCESS';
export const HOSTING_PLESK_FAIL = 'hosting/HOSTING_PLESK_FAIL';

export const HOSTING_SET_RESELLER = 'hosting/HOSTING_SET_RESELLER';
export const HOSTING_INFORMATION_SUBACCOUNT_REQUEST = 'hosting/HOSTING_INFORMATION_SUBACCOUNT_REQUEST';
export const HOSTING_INFORMATION_SUBACCOUNT_SUCCESS = 'hosting/HOSTING_INFORMATION_SUBACCOUNT_SUCCESS';
export const HOSTING_INFORMATION_SUBACCOUNT_FAIL = 'hosting/HOSTING_INFORMATION_SUBACCOUNT_FAIL';

export const HOSTING_SEND_DETAILS_REQUEST = 'hosting/HOSTING_SEND_DETAILS_REQUEST';
export const HOSTING_SEND_DETAILS_SUCCESS = 'hosting/HOSTING_SEND_DETAILS_SUCCESS';
export const HOSTING_SEND_DETAILS_FAIL = 'hosting/HOSTING_SEND_DETAILS_FAIL';

export const HOSTING_SUBACCOUNT_LIST_REQUEST = 'hosting/HOSTING_SUBACCOUNT_LIST_REQUEST';
export const HOSTING_SUBACCOUNT_LIST_SUCCESS = 'hosting/HOSTING_SUBACCOUNT_LIST_SUCCESS';
export const HOSTING_SUBACCOUNT_LIST_FAIL = 'hosting/HOSTING_SUBACCOUNT_LIST_FAIL';

export const HOSTING_SUBACCOUNT_LIST_RESET = 'hosting/HOSTING_SUBACCOUNT_LIST_RESET';

export const HOSTING_CPANEL_LOGIN_REQUEST = 'hosting/HOSTING_CPANEL_LOGIN_REQUEST';
export const HOSTING_CPANEL_LOGIN_SUCCESS = 'hosting/HOSTING_CPANEL_LOGIN_SUCCESS';
export const HOSTING_CPANEL_LOGIN_FAIL = 'hosting/HOSTING_CPANEL_LOGIN_FAIL';

export const HOSTING_CPANEL_DETAILS_REQUEST = 'hosting/HOSTING_CPANEL_DETAILS_REQUEST';
export const HOSTING_CPANEL_DETAILS_SUCCESS = 'hosting/HOSTING_CPANEL_DETAILS_SUCCESS';
export const HOSTING_CPANEL_DETAILS_FAIL = 'hosting/HOSTING_CPANEL_DETAILS_FAIL';

export const HOSTING_CPANEL_REQUEST = 'hosting/HOSTING_CPANEL_REQUEST';
export const HOSTING_CPANEL_SUCCESS = 'hosting/HOSTING_CPANEL_SUCCESS';
export const HOSTING_CPANEL_FAIL = 'hosting/HOSTING_CPANEL_FAIL';

export const HOSTING_DISKBLOCK_RESET = 'hosting/HOSTING_DISKBLOCK_RESET';

export const HOSTING_DISKBLOCK_LIST_REQUEST = 'hosting/HOSTING_DISKBLOCK_LIST_REQUEST';
export const HOSTING_DISKBLOCK_LIST_SUCCESS = 'hosting/HOSTING_DISKBLOCK_LIST_SUCCESS';
export const HOSTING_DISKBLOCK_LIST_FAIL = 'hosting/HOSTING_DISKBLOCK_LIST_FAIL';

export const HOSTING_DISKBLOCK_PURCHASE_REQUEST = 'hosting/HOSTING_DISKBLOCK_PURCHASE_REQUEST';
export const HOSTING_DISKBLOCK_PURCHASE_SUCCESS = 'hosting/HOSTING_DISKBLOCK_PURCHASE_SUCCESS';
export const HOSTING_DISKBLOCK_PURCHASE_FAIL = 'hosting/HOSTING_DISKBLOCK_PURCHASE_FAIL';

export const HOSTING_DISKBLOCK_REQUEST = 'hosting/HOSTING_DISKBLOCK_REQUEST';
export const HOSTING_DISKBLOCK_SUCCESS = 'hosting/HOSTING_DISKBLOCK_SUCCESS';
export const HOSTING_DISKBLOCK_FAIL = 'hosting/HOSTING_DISKBLOCK_FAIL';

export const HOSTING_UPGRADE_LIST_REQUEST = 'hosting/HOSTING_UPGRADE_LIST_REQUEST';
export const HOSTING_UPGRADE_LIST_SUCCESS = 'hosting/HOSTING_UPGRADE_LIST_SUCCESS';
export const HOSTING_UPGRADE_LIST_FAIL = 'hosting/HOSTING_UPGRADE_LIST_FAIL';

export const HOSTING_UPGRADE_CALCULATE_REQUEST = 'hosting/HOSTING_UPGRADE_CALCULATE_REQUEST';
export const HOSTING_UPGRADE_CALCULATE_SUCCESS = 'hosting/HOSTING_UPGRADE_CALCULATE_SUCCESS';
export const HOSTING_UPGRADE_CALCULATE_ERROR = 'hosting/HOSTING_UPGRADE_CALCULATE_ERROR';

export const HOSTING_UPGRADE_REQUEST = 'hosting/HOSTING_UPGRADE_REQUEST';
export const HOSTING_UPGRADE_SUCCESS = 'hosting/HOSTING_UPGRADE_SUCCESS';
export const HOSTING_UPGRADE_FAIL = 'hosting/HOSTING_UPGRADE_FAIL';

export const HOSTING_UPGRADE_PRORATA_REQUEST = 'hosting/HOSTING_UPGRADE_PRORATA_REQUEST';
export const HOSTING_UPGRADE_PRORATA_SUCCESS = 'hosting/HOSTING_UPGRADE_PRORATA_SUCCESS';
export const HOSTING_UPGRADE_PRORATA_FAIL = 'hosting/HOSTING_UPGRADE_PRORATA_FAIL';

export const HOSTING_BILLINGCYCLE_REQUEST = 'hosting/HOSTING_BILLINGCYCLE_REQUEST';
export const HOSTING_BILLINGCYCLE_SUCCESS = 'hosting/HOSTING_BILLINGCYCLE_SUCCESS';
export const HOSTING_BILLINGCYCLE_FAIL = 'hosting/HOSTING_BILLINGCYCLE_FAIL';

export const HOSTING_BILLING_REQUEST = 'hosting/HOSTING_BILLING_REQUEST';
export const HOSTING_BILLING_SUCCESS = 'hosting/HOSTING_BILLING_SUCCESS';
export const HOSTING_BILLING_FAIL = 'hosting/HOSTING_BILLING_FAIL';

export const HOSTING_PRIMARY_REQUEST = 'hosting/HOSTING_PRIMARY_REQUEST';
export const HOSTING_PRIMARY_SUCCESS = 'hosting/HOSTING_PRIMARY_SUCCESS';
export const HOSTING_PRIMARY_FAIL = 'hosting/HOSTING_PRIMARY_FAIL';

export const HOSTING_RENEW_REQUEST = 'hosting/HOSTING_RENEW_REQUEST';
export const HOSTING_RENEW_SUCCESS = 'hosting/HOSTING_RENEW_SUCCESS';
export const HOSTING_RENEW_FAIL = 'hosting/HOSTING_RENEW_FAIL';

export const HOSTING_RENEW_INFO_REQUEST = 'hosting/HOSTING_RENEW_INFO_REQUEST';
export const HOSTING_RENEW_INFO_SUCCESS = 'hosting/HOSTING_RENEW_INFO_SUCCESS';
export const HOSTING_RENEW_INFO_FAIL = 'hosting/HOSTING_RENEW_INFO_FAIL';

export const HOSTING_PREPAY_DETAILS_REQUEST = 'hosting/HOSTING_PREPAY_DETAILS_REQUEST';
export const HOSTING_PREPAY_DETAILS_SUCCESS = 'hosting/HOSTING_PREPAY_DETAILS_SUCCESS';
export const HOSTING_PREPAY_DETAILS_ERROR = 'hosting/HOSTING_PREPAY_DETAILS_ERROR';

export const CUSTOM_HOSTING_DETAILS_REQUEST = 'hosting/CUSTOM_HOSTING_DETAILS_REQUEST';
export const CUSTOM_HOSTING_DETAILS_SUCCESS = 'hosting/CUSTOM_HOSTING_DETAILS_SUCCESS';
export const CUSTOM_HOSTING_DETAILS_FAIL = 'hosting/CUSTOM_HOSTING_DETAILS_FAIL';

export const CUSTOM_HOSTING_CONFIG_REQUEST = 'hosting/CUSTOM_HOSTING_CONFIG_REQUEST';
export const CUSTOM_HOSTING_CONFIG_SUCCESS = 'hosting/CUSTOM_HOSTING_CONFIG_SUCCESS';
export const CUSTOM_HOSTING_CONFIG_FAIL = 'hosting/CUSTOM_HOSTING_CONFIG_FAIL';

export const CUSTOM_HOSTING_CHANGE_REQUEST = 'hosting/CUSTOM_HOSTING_CHANGE_REQUEST';
export const CUSTOM_HOSTING_CHANGE_SUCCESS = 'hosting/CUSTOM_HOSTING_CHANGE_SUCCESS';
export const CUSTOM_HOSTING_CHANGE_FAIL = 'hosting/CUSTOM_HOSTING_CHANGE_FAIL';

export const CUSTOM_HOSTING_REMOVE_REQUEST = 'hosting/CUSTOM_HOSTING_REMOVE_REQUEST';
export const CUSTOM_HOSTING_REMOVE_SUCCESS = 'hosting/CUSTOM_HOSTING_REMOVE_SUCCESS';
export const CUSTOM_HOSTING_REMOVE_FAIL = 'hosting/CUSTOM_HOSTING_REMOVE_FAIL';

export const CUSTOM_HOSTING_CALCULATE_RESET = 'hosting/CUSTOM_HOSTING_CALCULATE_RESET';
export const CUSTOM_HOSTING_CALCULATE_REQUEST = 'hosting/CUSTOM_HOSTING_CALCULATE_REQUEST';
export const CUSTOM_HOSTING_CALCULATE_SUCCESS = 'hosting/CUSTOM_HOSTING_CALCULATE_SUCCESS';
export const CUSTOM_HOSTING_CALCULATE_FAIL = 'hosting/CUSTOM_HOSTING_CALCULATE_FAIL';

export const CUSTOM_HOSTING_ADDON_LIST_REQUEST = 'hosting/CUSTOM_HOSTING_ADDON_LIST_REQUEST';
export const CUSTOM_HOSTING_ADDON_LIST_SUCCESS = 'hosting/CUSTOM_HOSTING_ADDON_LIST_SUCCESS';
export const CUSTOM_HOSTING_ADDON_LIST_FAIL = 'hosting/CUSTOM_HOSTING_ADDON_LIST_FAIL';

export const CUSTOM_HOSTING_ADDON_PURCHASE_REQUEST = 'hosting/CUSTOM_HOSTING_ADDON_PURCHASE_REQUEST';
export const CUSTOM_HOSTING_ADDON_PURCHASE_SUCCESS = 'hosting/CUSTOM_HOSTING_ADDON_PURCHASE_SUCCESS';
export const CUSTOM_HOSTING_ADDON_PURCHASE_FAIL = 'hosting/CUSTOM_HOSTING_ADDON_PURCHASE_FAIL';

export const CUSTOM_HOSTING_ADDON_REMOVE_REQUEST = 'hosting/CUSTOM_HOSTING_ADDON_REMOVE_REQUEST';
export const CUSTOM_HOSTING_ADDON_REMOVE_SUCCESS = 'hosting/CUSTOM_HOSTING_ADDON_REMOVE_SUCCESS';
export const CUSTOM_HOSTING_ADDON_REMOVE_FAIL = 'hosting/CUSTOM_HOSTING_ADDON_REMOVE_FAIL';

export const CUSTOM_HOSTING_ADDON_KEEP_REQUEST = 'hosting/CUSTOM_HOSTING_ADDON_KEEP_REQUEST';
export const CUSTOM_HOSTING_ADDON_KEEP_SUCCESS = 'hosting/CUSTOM_HOSTING_ADDON_KEEP_SUCCESS';
export const CUSTOM_HOSTING_ADDON_KEEP_FAIL = 'hosting/CUSTOM_HOSTING_ADDON_KEEP_FAIL';

export const HOSTING_SUBACCOUNT_INFO_RESET = 'hosting/HOSTING_SUBACCOUNT_INFO_RESET';

export const HOSTING_RESOURCE_REQUEST = 'hosting/HOSTING_RESOURCE_REQUEST';
export const HOSTING_RESOURCE_SUCCESS = 'hosting/HOSTING_RESOURCE_SUCCESS';
export const HOSTING_RESOURCE_ERROR = 'hosting/HOSTING_RESOURCE_ERROR';

export const HOSTING_RESOURCE_CONFIG_REQUEST = 'hosting/HOSTING_RESOURCE_CONFIG_REQUEST';
export const HOSTING_RESOURCE_CONFIG_SUCCESS = 'hosting/HOSTING_RESOURCE_CONFIG_SUCCESS';
export const HOSTING_RESOURCE_CONFIG_ERROR = 'hosting/HOSTING_RESOURCE_CONFIG_ERROR';

export const HOSTING_RESOURCE_UPDATE_REQUEST = 'hosting/HOSTING_RESOURCE_UPDATE_REQUEST';
export const HOSTING_RESOURCE_UPDATE_SUCCESS = 'hosting/HOSTING_RESOURCE_UPDATE_SUCCESS';
export const HOSTING_RESOURCE_UPDATE_ERROR = 'hosting/HOSTING_RESOURCE_UPDATE_ERROR';

export const HOSTING_ADDON_LIST_REQUEST = 'hosting/HOSTING_ADDON_LIST_REQUEST';
export const HOSTING_ADDON_LIST_SUCCESS = 'hosting/HOSTING_ADDON_LIST_SUCCESS';
export const HOSTING_ADDON_LIST_ERROR = 'hosting/HOSTING_ADDON_LIST_ERROR';

export const HOSTING_ADDON_REMOVE_REQUEST = 'hosting/HOSTING_ADDON_REMOVE_REQUEST';
export const HOSTING_ADDON_REMOVE_SUCCESS = 'hosting/HOSTING_ADDON_REMOVE_SUCCESS';
export const HOSTING_ADDON_REMOVE_ERROR = 'hosting/HOSTING_ADDON_REMOVE_ERROR';

export const HOSTING_ADDON_UPDATE_REQUEST = 'hosting/HOSTING_ADDON_UPDATE_REQUEST';
export const HOSTING_ADDON_UPDATE_SUCCESS = 'hosting/HOSTING_ADDON_UPDATE_SUCCESS';
export const HOSTING_ADDON_UPDATE_ERROR = 'hosting/HOSTING_ADDON_UPDATE_ERROR';

export const HOSTING_ADDON_CANCEL_REQUEST = 'hosting/HOSTING_ADDON_CANCEL_REQUEST';
export const HOSTING_ADDON_CANCEL_SUCCESS = 'hosting/HOSTING_ADDON_CANCEL_SUCCESS';
export const HOSTING_ADDON_CANCEL_ERROR = 'hosting/HOSTING_ADDON_CANCEL_ERROR';

export const HOSTING_ADDON_KEEP_REQUEST = 'hosting/HOSTING_ADDON_KEEP_REQUEST';
export const HOSTING_ADDON_KEEP_SUCCESS = 'hosting/HOSTING_ADDON_KEEP_SUCCESS';
export const HOSTING_ADDON_KEEP_ERROR = 'hosting/HOSTING_ADDON_KEEP_ERROR';

export const HOSTING_CREATE_CART_REQUEST = 'hosting/HOSTING_CREATE_CART_REQUEST';
export const HOSTING_CREATE_CART_SUCCESS = 'hosting/HOSTING_CREATE_CART_SUCCESS';
export const HOSTING_CREATE_CART_ERROR = 'hosting/HOSTING_CREATE_CART_ERROR';

export const HOSTING_EDIT_CART_ITEMS_REQUEST = 'hosting/HOSTING_EDIT_CART_ITEMS_REQUEST';
export const HOSTING_EDIT_CART_ITEMS_SUCCESS = 'hosting/HOSTING_EDIT_CART_ITEMS_SUCCESS';
export const HOSTING_EDIT_CART_ITEMS_ERROR = 'hosting/HOSTING_EDIT_CART_ITEMS_ERROR';

export const HOSTING_RESET_CART_PROMO = 'hosting/HOSTING_RESET_CART_PROMO';

export const HOSTING_ADD_PROMO_CODE_REQUEST = 'hosting/HOSTING_ADD_PROMO_CODE_REQUEST';
export const HOSTING_ADD_PROMO_CODE_SUCCESS = 'hosting/HOSTING_ADD_PROMO_CODE_SUCCESS';
export const HOSTING_ADD_PROMO_CODE_ERROR = 'hosting/HOSTING_ADD_PROMO_CODE_ERROR';

export const HOSTING_ADD_BEST_PROMO_REQUEST = 'hosting/HOSTING_ADD_BEST_PROMO_REQUEST';
export const HOSTING_ADD_BEST_PROMO_SUCCESS = 'hosting/HOSTING_ADD_BEST_PROMO_SUCCESS';
export const HOSTING_ADD_BEST_PROMO_ERROR = 'hosting/HOSTING_ADD_BEST_PROMO_ERROR';

export const HOSTING_REMOVE_PROMO_CODE_REQUEST = 'hosting/HOSTING_REMOVE_PROMO_CODE_REQUEST';
export const HOSTING_REMOVE_PROMO_CODE_SUCCESS = 'hosting/HOSTING_REMOVE_PROMO_CODE_SUCCESS';
export const HOSTING_REMOVE_PROMO_CODE_ERROR = 'hosting/HOSTING_REMOVE_PROMO_CODE_ERROR';

export const HOSTING_CREATE_ORDER_REQUEST = 'hosting/HOSTING_CREATE_ORDER_REQUEST';
export const HOSTING_CREATE_ORDER_SUCCESS = 'hosting/HOSTING_CREATE_ORDER_SUCCESS';
export const HOSTING_CREATE_ORDER_ERROR = 'hosting/HOSTING_CREATE_ORDER_ERROR';

export const HOSTING_LIMITS_REQUEST = 'hosting/HOSTING_LIMITS_REQUEST';
export const HOSTING_LIMITS_SUCCESS = 'hosting/HOSTING_LIMITS_SUCCESS';
export const HOSTING_LIMITS_ERROR = 'hosting/HOSTING_LIMITS_ERROR';

export const HOSTING_CONFIGURATION_INITIAL_STATE = 'hosting/HOSTING_CONFIGURATION_INITIAL_STATE';
export const HOSTING_CONFIGURATION_REQUEST_RESET = 'hosting/HOSTING_CONFIGURATION_REQUEST_RESET';

export const HOSTING_TEMPURL_CHECK_REQUEST = 'hosting/HOSTING_TEMPURL_CHECK_REQUEST';
export const HOSTING_TEMPURL_CHECK_SUCCESS = 'hosting/HOSTING_TEMPURL_CHECK_SUCCESS';
export const HOSTING_TEMPURL_CHECK_FAIL = 'hosting/HOSTING_TEMPURL_CHECK_FAIL';

export const HOSTING_TEMPURL_REQUEST = 'hosting/HOSTING_TEMPURL_REQUEST';
export const HOSTING_TEMPURL_SUCCESS = 'hosting/HOSTING_TEMPURL_SUCCESS';
export const HOSTING_TEMPURL_FAIL = 'hosting/HOSTING_TEMPURL_FAIL';

export const HOSTING_IPV6_CHECK_REQUEST = 'hosting/HOSTING_IPV6_CHECK_REQUEST';
export const HOSTING_IPV6_CHECK_SUCCESS = 'hosting/HOSTING_IPV6_CHECK_SUCCESS';
export const HOSTING_IPV6_CHECK_FAIL = 'hosting/HOSTING_IPV6_CHECK_FAIL';

export const HOSTING_IPV6_REQUEST = 'hosting/HOSTING_IPV6_REQUEST';
export const HOSTING_IPV6_SUCCESS = 'hosting/HOSTING_IPV6_SUCCESS';
export const HOSTING_IPV6_FAIL = 'hosting/HOSTING_IPV6_FAIL';

export const HOSTING_CHECK_IP_REQUEST = 'hosting/HOSTING_CHECK_IP_REQUEST';
export const HOSTING_CHECK_IP_SUCCESS = 'hosting/HOSTING_CHECK_IP_SUCCESS';

export const HOSTING_DATABASE_LIST_REQUEST = 'hosting/HOSTING_DATABASE_LIST_REQUEST';
export const HOSTING_DATABASE_LIST_SUCCESS = 'hosting/HOSTING_DATABASE_LIST_SUCCESS';
export const HOSTING_DATABASE_LIST_FAIL = 'hosting/HOSTING_DATABASE_LIST_FAIL';

export const HOSTING_DATABASE_REQUEST = 'hosting/HOSTING_DATABASE_REQUEST';
export const HOSTING_DATABASE_SUCCESS = 'hosting/HOSTING_DATABASE_SUCCESS';
export const HOSTING_DATABASE_FAIL = 'hosting/HOSTING_DATABASE_FAIL';

export const HOSTING_SSHACCESS_LIST_REQUEST = 'hosting/HOSTING_SSHACCESS_LIST_REQUEST';
export const HOSTING_SSHACCESS_LIST_SUCCESS = 'hosting/HOSTING_SSHACCESS_LIST_SUCCESS';
export const HOSTING_SSHACCESS_LIST_FAIL = 'hosting/HOSTING_SSHACCESS_LIST_FAIL';

export const HOSTING_SSHACCESS_REQUEST = 'hosting/HOSTING_SSHACCESS_REQUEST';
export const HOSTING_SSHACCESS_SUCCESS = 'hosting/HOSTING_SSHACCESS_SUCCESS';
export const HOSTING_SSHACCESS_FAIL = 'hosting/HOSTING_SSHACCESS_FAIL';

export const HOSTING_RESELLER_INITIAL_STATE = 'hosting/HOSTING_RESELLER_INITIAL_STATE';

export const HOSTING_SUBACCOUNT_USERNAME = 'hosting/HOSTING_SUBACCOUNT_USERNAME';
export const HOSTING_SUBACCOUNT_META = 'hosting/HOSTING_SUBACCOUNT_META';
export const HOSTING_CPANEL_SUBACCOUNT_REQUEST = 'hosting/HOSTING_CPANEL_SUBACCOUNT_REQUEST';
export const HOSTING_CPANEL_SUBACCOUNT_SUCCESS = 'hosting/HOSTING_CPANEL_SUBACCOUNT_SUCCESS';
export const HOSTING_CPANEL_SUBACCOUNT_FAIL = 'hosting/HOSTING_CPANEL_SUBACCOUNT_FAIL';

export const HOSTING_SECURITY_INITIAL_STATE = 'hosting/HOSTING_SECURITY_INITIAL_STATE';
export const HOSTING_SECURITY_REQUEST_RESET = 'hosting/HOSTING_SECURITY_REQUEST_RESET';

export const HOSTING_AUTOSSL_CHECK_REQUEST = 'hosting/HOSTING_AUTOSSL_CHECK_REQUEST';
export const HOSTING_AUTOSSL_CHECK_SUCCESS = 'hosting/HOSTING_AUTOSSL_CHECK_SUCCESS';
export const HOSTING_AUTOSSL_CHECK_FAIL = 'hosting/HOSTING_AUTOSSL_CHECK_FAIL';

export const HOSTING_PERMISSIONS_REQUEST = 'hosting/HOSTING_PERMISSIONS_REQUEST';
export const HOSTING_PERMISSIONS_SUCCESS = 'hosting/HOSTING_PERMISSIONS_SUCCESS';
export const HOSTING_PERMISSIONS_FAIL = 'hosting/HOSTING_PERMISSIONS_FAIL';

export const HOSTING_MODSEC_REQUEST = 'hosting/HOSTING_MODSEC_REQUEST';
export const HOSTING_MODSEC_SUCCESS = 'hosting/HOSTING_MODSEC_SUCCESS';
export const HOSTING_MODSEC_FAIL = 'hosting/HOSTING_MODSEC_FAIL';

export const HOSTING_FILE_QUARANTINE_REQUEST = 'hosting/HOSTING_FILE_QUARANTINE_REQUEST';
export const HOSTING_FILE_QUARANTINE_SUCCESS = 'hosting/HOSTING_FILE_QUARANTINE_SUCCESS';
export const HOSTING_FILE_QUARANTINE_FAIL = 'hosting/HOSTING_FILE_QUARANTINE_FAIL';
