import FetchPageError from 'components/Errors/FetchPageError';
import { IsDataUpdatingOverlay } from 'components/IsDataUpdatingOverlay';
import RequestLoader from 'components/Loaders/Request';
import NXTable from 'components/NXTable';
import { useBrandStore } from 'config/hooks/useBrandStore';
import { COPY_updatingInvoices } from 'containers/billing/modules/consts';
import { has } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import './_invoicesTable.scss';
import { InvoicesTableContext, desktopColumns, desktopTableHeadings, mobileColumns, mobileTableHeadings, mobileTableSizes } from './consts';
import { renderHeader } from './methods';
import { MobileInvoiceRow } from './mobileInvoiceRow';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<{
 *      status: import('@tanstack/react-query').MutationStatus
 * }>}
 */
let InvoicesTable = ({
    invoicesData,
    currentSearchKeyword,
    handleSort,
    status,
    contentComponents,
    
    /**
     * Redux Props
     */
    app_viewport
}) => {
    const invoicesTableContext = {
        contentComponents
    };
    
    /***** HOOKS *****/
    const { activeBrand } = useBrandStore();

    /***** RENDER HELPERS *****/
    const hasInvoicesData = !!invoicesData?.length;

    function getDataErrorMessage() {
        if (status === 'error') return <FetchPageError />;
        if (status !== 'pending' && !hasInvoicesData) {
            if (currentSearchKeyword) return 'No invoices matched your search.';
            if (activeBrand === 'intaserve') return 'You have no invoices at the moment';
            return 'You have no invoices at the moment. Why not go ahead and purchase some amazing services to get this list started?';
        }
        return '';
    }

    function renderErrorMessage() {
        return <NXTable.ErrorMessage message={getDataErrorMessage()} />;
    }

    function renderInvoicesRequestLoader() {
        if (status === 'pending') return <RequestLoader fillHeight={100} message="Loading invoices" />;
    }

    function renderDesktopInvoicesRows() {
        return (
            invoicesData?.map((invoiceData) => {
                const filteredDesktopTableHeading = desktopTableHeadings.filter(([sortKey]) => has(contentComponents, sortKey));
                return (
                    <IsDataUpdatingOverlay
                        message={COPY_updatingInvoices}
                        isDataUpdating={invoiceData.isDataUpdating}
                        fillHeight={40}
                        height={30}
                        key={invoiceData.id}
                    >
                        <NXTable.Row>
                            {filteredDesktopTableHeading.map(([sortKey], i) => {
                                const hasDesktopRender = has(contentComponents[sortKey], 'desktop');
                                if (hasDesktopRender) return contentComponents[sortKey].desktop(invoiceData, i);
                                return contentComponents[sortKey](invoiceData, i);
                            })}
                        </NXTable.Row>
                    </IsDataUpdatingOverlay>
                );
            }) ?? ''
        );
    }

    function renderMobileInvoiceRows() {
        return (
            invoicesData?.map((invoiceData, i) => (
                <IsDataUpdatingOverlay
                    message={COPY_updatingInvoices}
                    isDataUpdating={invoiceData.isDataUpdating}
                    fillHeight={50}
                    height={40}
                    key={invoiceData.id}
                >
                    <MobileInvoiceRow invoiceData={invoiceData} index={i} />
                </IsDataUpdatingOverlay>
            )) ?? ''
        );
    }

    function renderDesktopTable() {
        return (
            <InvoicesTableContext.Provider value={invoicesTableContext}>
                <NXTable key="desktop" columns={desktopColumns} onSort={handleSort} className="invoicesTable">
                    {renderHeader(desktopTableHeadings)}
                    <NXTable.Body>
                        {renderErrorMessage()}
                        {renderInvoicesRequestLoader() ?? renderDesktopInvoicesRows()}
                    </NXTable.Body>
                </NXTable>
            </InvoicesTableContext.Provider>
        );
    }

    function renderMobileTable() {
        return (
            <InvoicesTableContext.Provider value={invoicesTableContext}>
                <NXTable key="mobile" columns={mobileColumns} onSort={handleSort} className="invoicesTable">
                    {renderHeader(mobileTableHeadings)}
                    {renderErrorMessage()}
                    {renderInvoicesRequestLoader() ?? <NXTable.Accordion.ToggleWrapper>{renderMobileInvoiceRows()}</NXTable.Accordion.ToggleWrapper>}
                </NXTable>
            </InvoicesTableContext.Provider>
        );
    }

    /***** RENDER *****/
    if (mobileTableSizes.includes(app_viewport)) return renderMobileTable();

    return renderDesktopTable();
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

const mapStateToProps = (state) => {
    return {
        app_viewport: state.app.app_viewport
    };
};

InvoicesTable = connect(mapStateToProps)(InvoicesTable);

export default InvoicesTable;
