import { SkeletonHOC } from 'components/Utils/Text/VMax/Skeleton/_SkeletonHOC';
import type { _TextVMax as TextVMax } from 'components/Utils/Text/VMax/TextVMax';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
export namespace _TextVMaxParagraph {
    export type Props = TextVMax.BaseProps;
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _TextVMaxParagraph = SkeletonHOC({ size: 14, fontWeight: 'medium' });
