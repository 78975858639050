import { Padding } from 'components/Utils/Padding';
import Text from 'components/Utils/Text';

export function sslTooltipText(productName: string) {
    const warrantyValue = () => {
        switch (productName) {
            case 'SectigoSSL Wildcard':
            case 'Sectigo Positive SSL':
                return '$50,000';
            case 'Sectigo PremiumSSL Certificate':
            case 'Sectigo SSL Wildcard Certificate':
            case 'PositiveSSL Multi-Domain (5)':
            case 'PositiveSSL Multi-Domain (10)':
            case 'PositiveSSL Multi-Domain (20)':
                return '$1,000,000';
            case 'Sectigo SSL Certificate':
                return '$500,000';
            case 'SectigoSSL OV Pro':
                return '$1,000,000';
            case 'Sectigo EV SSL Certificate':
                return '$1,750,000';

            default:
                return;
        }
    };

    return (
        <Padding x={3}>
            <Text semiBold>Certificate Includes:</Text>
            <Padding left={5} top={1}>
                <ul style={{ listStyle: 'disc' }}>
                    <li key="validation">
                        <Text semiBold>Domain Validation</Text>
                    </li>
                    <li key="delivery">
                        <Text semiBold>Instant Delivery</Text>
                    </li>
                    <li key="warranty">
                        <Text semiBold>{warrantyValue()} Warranty Value </Text>
                    </li>
                    <li key="secured">
                        <Text semiBold>Domains Secured: {productName === 'Sectigo SSL Wildcard Certificate' ? 'Unlimited Subdomains' : 1}</Text>
                    </li>
                </ul>
            </Padding>
        </Padding>
    );
}
