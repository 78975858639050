import { Store, useStore } from '@tanstack/react-store';
import _ from 'lodash';
import { useCallback } from 'react';
import { Logger } from 'utilities/methods/logger';
import type { NXUtils } from 'utilities/NXUtils';
import { historicMigrationsSchema, userPreferencesDictionary, userPreferencesDictionaryKeys } from 'utilities/UserPreferences/consts';
import { getAllKeys } from 'utilities/UserPreferences/methods/getAllKeys';
import { localStorageMigrations } from 'utilities/UserPreferences/migrations';
import type { UserPreferencesNamespace } from 'utilities/UserPreferences/types';
import { type SafeParseError } from 'zod';

/**********************************************************************************************************
 *   UTILITY START
 **********************************************************************************************************/
export class UserPreferences {
    /**
     * The base key for all user preference keys. This is used to differentiate user preference keys from other keys in the localStorage.
     */
    public static baseKey = 'USER_PREFERENCE:' as const;

    /**
     * The key used to store the migration data in the localStorage.
     * This key is used to keep track of which migrations have been run.
     */
    public static migrationDataKey = 'USER_PREFERENCE_MIGRATIONS';

    /**
     * The store that holds all the user preference items.
     *
     * Only to be used in unit tests
     */
    public static store = UserPreferences.createStore();

    private static parseJSONString(value: string): undefined | unknown {
        try {
            return JSON.parse(value);
        } catch (error) {
            Logger.error('Error parsing JSON\nValue:\n', value, '\nError: \n', error);
            return undefined;
        }
    }

    private static createKey<TKey extends UserPreferencesNamespace.Keys>(key: TKey) {
        return `${UserPreferences.baseKey}${key}` as UserPreferencesNamespace.StorageKey<TKey>;
    }

    private static stripBaseKey(storageKey: string) {
        return storageKey.replace(UserPreferences.baseKey, '') as UserPreferencesNamespace.Keys;
    }

    private static allKeys() {
        return getAllKeys();
    }

    private static rawKeys() {
        return UserPreferences.allKeys().filter(UserPreferences.isUserPreferenceKey);
    }

    private static keys() {
        return UserPreferences.rawKeys().map(UserPreferences.stripBaseKey);
    }

    private static isUserPreferenceKey<TKey extends UserPreferencesNamespace.Keys>(key: unknown): key is UserPreferencesNamespace.StorageKey<TKey> {
        return typeof key === 'string' && key.includes(UserPreferences.baseKey);
    }

    private static getMigrations(): UserPreferencesNamespace.HistoricMigrations {
        const storedMigrationData = window.localStorage.getItem(UserPreferences.migrationDataKey);
        if (typeof storedMigrationData === 'string') {
            const parseResult = UserPreferences.parseJSONString(storedMigrationData);
            const result = historicMigrationsSchema.safeParse(parseResult);
            if (result.error) return {};
            return result.data;
        }
        return {};
    }

    private static composeMigrationKey(from: string, to: string) {
        return `${from}->${to}`;
    }

    private static deleteUnusedKeys() {
        const keys = UserPreferences.keys();
        keys.forEach((key) => {
            if (!userPreferencesDictionaryKeys.includes(key)) {
                window.localStorage.removeItem(UserPreferences.createKey(key));
            }
        });
    }

    private static handleStorageEvents() {
        const handleNewValue = ({ key, newValue }: StorageEvent) => {
            if (!UserPreferences.isUserPreferenceKey(key)) return;

            const itemKey = UserPreferences.stripBaseKey(key);
            if (newValue === null) {
                UserPreferences.removeStoreItem(itemKey);
                return;
            }

            if (typeof newValue === 'string') {
                const JSONparseResult = UserPreferences.parseJSONString(newValue);
                const parseResult = userPreferencesDictionary[itemKey].safeParse(JSONparseResult);
                if (parseResult?.error) {
                    UserPreferences.handleParseError(itemKey, parseResult, 'UserPreferences.handleStorageEvents -> handleNewValue');
                    return;
                }

                UserPreferences.setStoreItem(itemKey, parseResult.data);
                return;
            }

            Logger.error('Unexpected UserPreferences value:', newValue);
        };

        window.addEventListener('storage', handleNewValue);
    }

    private static getStorageItems() {
        type HandleStoredKey = <TRawKey extends UserPreferencesNamespace.StorageKey<UserPreferencesNamespace.Keys>>(
            accumulator: Partial<UserPreferencesNamespace.Items>,
            rawKey: TRawKey
        ) => Partial<UserPreferencesNamespace.Items>;

        const handleStoredKey: HandleStoredKey = (accumulator, rawKey) => {
            const key = UserPreferences.stripBaseKey(rawKey);
            const storedValue = window.localStorage.getItem(rawKey);
            if (!storedValue) return accumulator;
            const JSONparseResult = UserPreferences.parseJSONString(storedValue);

            if (_.isUndefined(JSONparseResult)) return accumulator;

            const parsedValue = JSON.parse(storedValue);
            const parseResult = userPreferencesDictionary[key].safeParse(parsedValue);
            if (parseResult?.error) {
                UserPreferences.handleParseError(key, parseResult, 'UserPreferences.getStorageItems -> handleStoredKey');
                return accumulator;
            }

            accumulator[key] = parsedValue;
            return accumulator;
        };

        return UserPreferences.rawKeys().reduce<Partial<UserPreferencesNamespace.Items>>(handleStoredKey, {});
    }

    private static createStore() {
        UserPreferences.runMigrations(localStorageMigrations);
        UserPreferences.deleteUnusedKeys();
        UserPreferences.handleStorageEvents();
        const storageItems = UserPreferences.getStorageItems();
        return new Store<Partial<UserPreferencesNamespace.Items>>(storageItems);
    }

    private static setStoreItem<TKey extends UserPreferencesNamespace.Keys>(key: TKey, value: UserPreferencesNamespace.Items[TKey]) {
        UserPreferences.store.setState((items) => ({ ...items, [key]: value }));
    }

    private static removeStoreItem<TKey extends UserPreferencesNamespace.Keys>(key: TKey) {
        UserPreferences.store.setState((items) => _.omit(items, key));
    }

    // prettier-ignore
    private static handleParseError(
        key: UserPreferencesNamespace.Keys,
        parseResult: SafeParseError<any>,
        subject: string
    ) {
        Logger.error(
            `The value passed to "${subject}" failed the safeParse\n`,
            `key: "${key}".\n`,
            'value:\n',
            parseResult.data,
            '\n',
            parseResult.error
        );
    }

    private static createDefaultData<TKey extends UserPreferencesNamespace.Keys>(key: TKey) {
        const type = userPreferencesDictionary[key]._def.typeName;
        switch (type) {
            case 'ZodRecord':
                return {} as UserPreferencesNamespace.Items[TKey];

            default:
                Logger.error('zod type to create default data is not supported yet:\n', 'Type: ', type);
                return undefined;
        }
    }

    private static handleSetStateAtPath<
        TKey extends UserPreferencesNamespace.PathItemKeys,
        TPath extends keyof UserPreferencesNamespace.PathItems[TKey],
        TPathValue extends NXUtils.ValueOf<UserPreferencesNamespace.PathItems[TKey]>
    >(key: TKey, path: TPath, value: TPathValue) {
        const hasState = _.has(UserPreferences.store.state, key);

        if (hasState) {
            const currentData = _.get(UserPreferences.store.state, key) as UserPreferencesNamespace.PathItems[TKey];
            _.set(currentData, path, value);
            UserPreferences.setItem(key, currentData as UserPreferencesNamespace.Items[TKey]);
            return;
        }

        const defaultData = UserPreferences.createDefaultData(key);
        if (!defaultData) {
            return;
        }
        _.set(defaultData, path, value);
        UserPreferences.setItem(key, defaultData);
        return;
    }

    private static storeSelect<TKey extends UserPreferencesNamespace.Keys>(state: Partial<UserPreferencesNamespace.Items>, key: TKey) {
        const stateValue = _.get(state, key);
        if (!_.isNil(stateValue)) return stateValue;
        return UserPreferences.getItem(key);
    }

    private static isValidKey(key: string): key is UserPreferencesNamespace.Keys {
        if (!_.has(userPreferencesDictionary, key)) {
            Logger.error("You're trying to get a UserPreferences key that the User Preferences dictionary is not aware of.\nKey: ", key);
            return false;
        }
        return true;
    }

    private static storeInLocalStorage(key: UserPreferencesNamespace.Keys, value: unknown) {
        const stringifiedValue = JSON.stringify(value);
        const storageKey = UserPreferences.createKey(key);
        window.localStorage.setItem(storageKey, stringifiedValue);
    }

    public static has(key: UserPreferencesNamespace.Keys) {
        const storeValue = UserPreferences.getItem(key);
        return Boolean(storeValue);
    }

    /**
     * Runs all migrations that have not been run yet.
     */
    public static runMigrations(migrations: UserPreferencesNamespace.MigrationData[]) {
        const historicMigrations = UserPreferences.getMigrations();

        migrations
            .filter(({ from, to }) => !_.has(historicMigrations, UserPreferences.composeMigrationKey(from, to)))
            .forEach(({ from, to, method }) => {
                method();
                historicMigrations[UserPreferences.composeMigrationKey(from, to)] = Date.now();
            });

        if (Object.keys(historicMigrations).length === 0) return;
        const stringifiedMigrationData = JSON.stringify(historicMigrations);
        window.localStorage.setItem(UserPreferences.migrationDataKey, stringifiedMigrationData);
    }

    /**
     * Given a key, returns the value stored in the store or in the localStorage.
     * If the value is not in the store, it will be parsed from the localStorage and stored in the store.
     * If the value is not in the localStorage, it will return undefined.
     */
    public static getItem<TKey extends UserPreferencesNamespace.Keys>(key: TKey): UserPreferencesNamespace.Items[TKey] | undefined {
        if (!UserPreferences.isValidKey(key)) return;

        const hasKey = _.has(UserPreferences.store.state, key);

        if (hasKey) {
            return _.get(UserPreferences.store.state, key);
        }

        const storageKey = UserPreferences.createKey(key);
        const value = window.localStorage.getItem(storageKey);

        if (!value) return;
        if (typeof value !== 'string') return;

        const JSONparseResult = UserPreferences.parseJSONString(value);

        if (_.isUndefined(JSONparseResult)) return;

        const parseResult = userPreferencesDictionary[key].safeParse(JSONparseResult);

        if (parseResult?.error) {
            UserPreferences.handleParseError(key, parseResult, 'UserPreferences.getItem');
            return;
        }

        this.setStoreItem(key, parseResult.data as UserPreferencesNamespace.Items[TKey]);
        return parseResult.data as UserPreferencesNamespace.Items[TKey];
    }

    /**
     * Given a key and a value, sets the value in the store and in the localStorage.
     * If the value is not valid, it will not be set.
     */
    public static setItem<TKey extends UserPreferencesNamespace.Keys>(key: TKey, value: UserPreferencesNamespace.Items[NoInfer<TKey>]) {
        if (!UserPreferences.isValidKey(key)) return;

        const parseResult = userPreferencesDictionary[key].safeParse(value);
        if (parseResult?.error) {
            UserPreferences.handleParseError(key, parseResult, 'UserPreferences.setItem');
            return;
        }

        UserPreferences.setStoreItem(key, value);
        UserPreferences.storeInLocalStorage(key, value);
    }

    // prettier-ignore
    /**
     * Given a key and a update method, updates the value in the store and in the localStorage with the result from the update method.
     * If the value is not valid, it will not be set.
     */
    public static updateItem<
        TKey extends UserPreferencesNamespace.Keys,
        TUpdater extends (
            (value: UserPreferencesNamespace.Items[TKey] | undefined) 
                => UserPreferencesNamespace.Items[TKey]
        )
    >(key: TKey, updater:TUpdater) {

        if (!UserPreferences.isValidKey(key)) return;

        const currentKeyState = UserPreferences.getItem(key);
      
        const updatedValue = updater(currentKeyState);

        const parseResult = userPreferencesDictionary[key].safeParse(updatedValue);
        if (parseResult.error) {
            UserPreferences.handleParseError(key, parseResult, 'UserPreferences.updateItem');
            return;
        }

        UserPreferences.store.setState((items) => ({
            ...items,
            [key]: parseResult.data
        })); 

        UserPreferences.storeInLocalStorage(key, parseResult.data);
    }

    /**
     * Given a key, removes the value from the store and the localStorage.
     * @returns `true` when key was successfully removed,
     * @returns `false` if key is invalid.
     */
    public static removeItem(key: UserPreferencesNamespace.Keys) {
        const hasKey = _.has(userPreferencesDictionary, key);
        if (!hasKey) return false;
        const storageKey = UserPreferences.createKey(key);
        window.localStorage.removeItem(storageKey);
        UserPreferences.removeStoreItem(key);
        return true;
    }

    /**
     * A more granular hook for selecting and updating a value within a record or array.
     * If you need to update the a key in a record or an index in an array, use this hook.
     */
    public static useSelect<
        TKey extends UserPreferencesNamespace.PathItemKeys,
        TPath extends keyof UserPreferencesNamespace.PathItems[TKey],
        TPathValue extends NXUtils.ValueOf<UserPreferencesNamespace.PathItems[TKey]>
    >(key: TKey, path: TPath, initialValue?: TPathValue) {
        if (!UserPreferences.isValidKey(key)) return [undefined, _.noop] as const;

        /***** HOOKS *****/
        const value = useStore(UserPreferences.store, (state) => {
            const stateValue = UserPreferences.storeSelect(state, key);
            if (_.isNil(stateValue)) return;
            const statePathValue = _.get(state, `${key}.${String(path)}`);
            if (_.isNil(statePathValue)) return;
            return statePathValue as TPathValue;
        });

        /***** FUNCTIONS *****/
        const setState = useCallback(
            (newValue: TPathValue) => {
                return UserPreferences.handleSetStateAtPath(key, path, newValue);
            },
            [key, path]
        );

        /***** EFFECTS *****/
        return [value ?? initialValue, setState] as const;
    }

    /**
     * A hook for selecting and updating a value in the store.
     * Updates the value in the store and in the localStorage through the setState function.
     */
    public static useLocalStorage<TKey extends UserPreferencesNamespace.Keys>(
        key: TKey,
        initialValue?: UserPreferencesNamespace.Items[TKey]
    ): UserPreferencesNamespace.UseResult<TKey> {
        if (!UserPreferences.isValidKey(key)) return [undefined, _.noop] as const;

        /***** HOOKS *****/
        const value = useStore(UserPreferences.store, (state) => {
            return UserPreferences.storeSelect(state, key);
        });

        /***** FUNCTIONS *****/
        const setState = useCallback(
            (newValue: UserPreferencesNamespace.Items[TKey]) => {
                UserPreferences.setItem(key, newValue);
            },
            [key]
        );

        /***** EFFECTS *****/
        return [value ?? initialValue, setState] as const;
    }
}
