import { ADD_REF } from './actionTypes';

const initialStates = {
    sidebarRefs: {}
};

function sidebarReducer(state = initialStates, action) {
    switch (action.type) {
        default: {
            return state;
        }

        /**
         * If your side bar refs are disappearing between registrations, check if there's no `resetScrollEvents` being fired anywhere. that causes these to get reset.
         */
        case ADD_REF: {
            const newSidebarRefs = Object.assign({}, action.sidebarRefs);
            return {
                ...state,
                sidebarRefs: newSidebarRefs
            };
        }
    }
}

export default sidebarReducer;
