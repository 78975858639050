import type { TabsContextType } from 'components/NXBox/Tabs/contexts/types';
import { useRawTabsContext } from 'components/NXBox/Tabs/contexts/useRawTabsContext';
import { createContext } from 'react';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
export const TabsContext = createContext<TabsContextType | undefined>(undefined);

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export const useTabsContext = (id: string) => {
    /***** HOOKS *****/
    const { active, setActive, tabRefs, portalRef, handleKeyDown } = useRawTabsContext();

    const isActive = id === active;
    const activateTab = () => setActive(id);

    /***** FUNCTIONS *****/
    function registerTabsRefs(tabElement: HTMLButtonElement | null) {
        tabRefs.current = tabRefs.current.filter((ref) => ref.dataset.name !== id);

        if (tabElement && tabRefs) {
            tabRefs.current.push(tabElement);

            tabRefs.current.sort((a, b) => {
                if (a?.compareDocumentPosition(b) === Node.DOCUMENT_POSITION_FOLLOWING) return -1;
                if (a?.compareDocumentPosition(b) === Node.DOCUMENT_POSITION_PRECEDING) return 1;
                return 0;
            });
        }
    }

    /***** HOOK RESULTS *****/
    return { isActive, setActive, activateTab, tabRefs, registerTabsRefs, handleKeyDown, portalRef };
};
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
