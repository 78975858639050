import React from 'react';
import { useController } from 'react-hook-form';
import { Slider } from 'components/Slider';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Props = {
    name: string;
    min: number;
    max: number;
    step?: number;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _SliderHookFormDefault: React.FC<Props> = ({ name, min, max, step }) => {
    /***** HOOKS *****/
    const { field } = useController({ name });
    const { onChange, value } = field;

    /***** RENDER *****/
    return <Slider onChange={onChange} min={min} max={max} step={step} value={value} />;
};
