import { _intaserveBrand } from 'config/tokens/intaserve/brand';
import { _intaserveComponent } from 'config/tokens/intaserve/component';
import { _intaservePrimitive } from 'config/tokens/intaserve/primitive';
import { _intaserveSemantic } from 'config/tokens/intaserve/semantic';

/***** EXPORTS *****/
export const __DO_NOT_USE_IN_PRODUCTION_OR_YOU_WILL_BE_FIRED__INTASERVE__CONFIG__ = {
    primitive: _intaservePrimitive,
    semantic: _intaserveSemantic,
    component: _intaserveComponent,
    brand: _intaserveBrand
};
