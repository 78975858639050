/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_NXTableActions.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {NXTable.Actions.TBase}
 */
export const Base = ({ children, className }) => <div className={classNames('NXTableActions', className)}>{children}</div>;
