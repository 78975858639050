import { addUserAccount } from 'containers/account/queries/additionalUser/addUserAccount';
import { cancelUserInvitation } from 'containers/account/queries/additionalUser/cancelInvitation';
import { deleteUserAccount } from 'containers/account/queries/additionalUser/deleteAcount';
import { userSubaccountList } from 'containers/account/queries/additionalUser/getAdditionalUsers';
import { resendUserInvitation } from 'containers/account/queries/additionalUser/resendInvitation';
import { updateUserProfile } from 'containers/account/queries/mutations/updateUserProfie';
import { approveSubaccount } from 'containers/account/queries/queries/subaccountApprove';
import { checkSubaccountExists } from 'containers/account/queries/queries/subaccountExists';
import { supportPIN } from 'containers/account/queries/supportPIN';
import { useSubscriptionsMutation } from 'containers/account/queries/useSubscriptionMutation';
import { useSubscriptionsQuery } from 'containers/account/queries/useSubscriptionsQuery';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
export const _AccountQuery = {
    subaccount: {
        exists: checkSubaccountExists,
        approve: approveSubaccount
    },
    additionalUser: {
        getAccountList: userSubaccountList,
        delete: deleteUserAccount,
        add: addUserAccount,
        resendInvitation: resendUserInvitation,
        cancelInvitation: cancelUserInvitation
    },
    getSubscription: useSubscriptionsQuery,
    useSubscriptionsMutation,
    updateUserProfile,
    supportPIN
};
