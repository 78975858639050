import RequestLoader from 'components/Loaders/Request';
import Text from 'components/Utils/Text';
import { useOrderingFormContext } from 'containers/orderingForm/contexts/useOrderingFormContext';
import { NXQuery } from 'utilities/query';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type RemoveButton = React.FC<RemoveButtonProps>;
type RemoveButtonProps = {
    item: {
        uuid: string;
    };
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const RemoveButton: RemoveButton = ({ item }) => {
    /***** HOOKS *****/
    const { cartToken } = useOrderingFormContext();

    /***** QUERIES *****/
    const { mutate: removeCartItem, isPending: isRemoveCartItemLoading } = NXQuery.orderingForm.cart.cartId.removeItems.useMutation();

    /***** FUNCTIONS *****/
    function handleRemove() {
        if (!item || !cartToken) {
            return;
        }

        removeCartItem({ itemId: item.uuid, cartToken });
    }

    /***** RENDER *****/
    return (
        <button type="button" disabled={isRemoveCartItemLoading} onClick={handleRemove} className="cartList__cartItemButton">
            {isRemoveCartItemLoading ? (
                <RequestLoader fillHeight={30} />
            ) : (
                <Text color={isRemoveCartItemLoading ? 'S_color_text_secondary_base' : 'S_color_text_warn_base'}>Remove</Text>
            )}
        </button>
    );
};
