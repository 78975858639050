import { useQuery, type UseQueryOptions } from '@tanstack/react-query';
import { serviceQueryKeys } from 'containers/services/queries/keys';
import { API } from 'utilities/api/services';
import type { Nullable } from 'vitest';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
export type DefaultSelectResult = Awaited<API.service.get.suspensionReason.ReturnType>;
export type DefaultError = Awaited<API.service.get.suspensionReason.ErrorReturnType>;

type Select<TData> = (data: DefaultSelectResult) => TData;
type QueryOptionsSubset<TError = DefaultError, TData = DefaultSelectResult> = Omit<
    UseQueryOptions<DefaultSelectResult, TError, TData, ReturnType<typeof serviceQueryKeys.services.suspensionReason>>,
    'queryFn' | 'queryKey'
>;

const defaultSelect = (d: any): any => d;

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
const _useQuery = <TError = DefaultError, TData = DefaultSelectResult>(
    id: Nullable<API.service.get.suspensionReason.Params>,
    options: QueryOptionsSubset<TError, TData>
) => {
    const { enabled, ...opts } = options;

    /***** QUERIES *****/
    return useQuery({
        queryKey: serviceQueryKeys.services.suspensionReason(/* defined as long as enabled condition is correct */ id!),
        queryFn: () => API.service.get.suspensionReason(/* defined as long as enabled condition is correct */ id!),
        enabled: !!id && (enabled ?? true),
        ...opts
    });
};

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
const _useSelectQuery = <TData = DefaultSelectResult>(
    id?: API.service.get.suspensionReason.Params,
    select: Select<TData> | undefined = defaultSelect
) => _useQuery(id, { select });

export const suspensionReason = {
    useQuery: _useQuery,
    useSelectQuery: _useSelectQuery
};
