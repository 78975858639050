import { useMutation } from '@tanstack/react-query';
import type { Item } from 'containers/orderingForm/types';
import { API } from 'utilities/api/services';
import { NXQuery } from 'utilities/query';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useMutation(tokenId: string) {
    return useMutation({
        mutationFn: (items: Array<Item & { action: string }>) => API.cart.POST.editItem(tokenId, items),
        onSuccess: (items) => NXQuery.orderingForm.cart.cartId.getCart.setQueryData(tokenId, items)
    });
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const editItem = Object.freeze({
    useMutation: _useMutation
});
