import RequestLoader from 'components/Loaders/Request';
import type { Ref } from 'react';
import React from 'react';

const SuperUserDVD = React.lazy(() =>
    import('components/StaffMenu/SuperUser/components/dvd.lazy').then((res) => ({
        default: res._SuperUserDVD,
    }))
);

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
export namespace NXIframe {
    export type Props = React.DetailedHTMLProps<React.IframeHTMLAttributes<HTMLIFrameElement>, HTMLIFrameElement> & {
        iframeRef?: Ref<HTMLIFrameElement>;
    };
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const NXIframe: React.FC<NXIframe.Props> = (props) => {
    const { height, width, title, iframeRef, ...iframeProps } = props;

    /***** RENDER *****/
    return (
        <>
            <iframe height={height} width={width} title={title} ref={iframeRef} {...iframeProps} />
            <RequestLoader.Suspense.SuperUserItem itemKey="DVD_LOGO">
                <SuperUserDVD />
            </RequestLoader.Suspense.SuperUserItem>
        </>
    );
};
