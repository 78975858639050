import { useNavigate, useSearch } from '@tanstack/react-router';
import classNames from 'classnames';
import Anchor from 'components/Anchor';
import { pushNotification } from 'components/Toast/functions';
import { Flex } from 'components/Utils/Flex';
import AppLogo from 'config/images/app.svg';
import { useLoginContext } from 'containers/login/consts';
import { LoginAuthSubmitButton } from 'containers/login/forms/LoginAuth/submitButton';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { FormProvider } from 'react-hook-form';
import { useToggle } from 'usehooks-ts';
import { useNXHookForm } from 'utilities/hooks/useNXForm';
import { handleDefaultErrorNotification } from 'utilities/methods/commonActions/handleDefaultErrorNotification';
import { requiredFieldErrorMessage } from 'utilities/methods/form';
import { NXQuery } from 'utilities/query';
import { z } from 'zod';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Schema = z.infer<typeof Schema>;

/**********************************************************************************************************
 *   SCHEMA
 **********************************************************************************************************/
const Schema = z.object({
    email: z.string().min(1, requiredFieldErrorMessage).email('Invalid email address'),
    password: z.string().min(1, requiredFieldErrorMessage),
    remember: z.boolean(),
});

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const LoginForm = () => {
    /***** STATE *****/
    const [isPasswordVisible, toggleIsPasswordVisible] = useToggle(false);

    /***** HOOKS *****/
    const { setView } = useLoginContext();
    const { executeRecaptcha } = useGoogleReCaptcha();
    const navigate = useNavigate();
    const { searchRef, approveToken, moveToken } = useSearch({
        from: '/_login',
        select: (search) => ({
            searchRef: search.ref,
            approveToken: search['approve-token'],
            moveToken: search['move-token'],
        }),
    });

    /***** QUERIES *****/
    const { mutateAsync: loginAsync } = NXQuery.auth.login.submit.useMutation({
        onSuccess: () => void 0,
        onError: (error) => void handleDefaultErrorNotification(error, { scope: 'guest' }),
    });

    /***** FORM *****/
    const Form = useNXHookForm(Schema, {
        defaultValues: {
            email: '',
            password: '',
            remember: false,
        },
        mode: 'all',
    });

    /***** FUNCTIONS *****/
    const handleCaptcha = async () => {
        // for some reason executeRecaptcha throws regardless of calling .catch, so using a try catch here.
        try {
            return await executeRecaptcha?.('login');
        } catch (_) {
            return undefined;
        }
    };

    const handleSubmit = async (values: Schema) => {
        const g_recaptcha_response = await handleCaptcha();
        if (!g_recaptcha_response) {
            return pushNotification({ status: 500, details: 'ReCaptcha failed, please try again later.' }, null, 'guest');
        }

        const loginResponse = await loginAsync({ ...values, g_recaptcha_response });

        if (loginResponse.data.attributes.two_factor_required) {
            await NXQuery.auth.twoFactor.list.fetch();
            return setView('2fa');
        }

        const [accountList] = await Promise.all([
            // -- comment to force new line 🤦‍♂️
            NXQuery.auth.accountList.forceFetch(),
            NXQuery.auth.login.checkToken.forceFetch(),
        ]);

        // Need to go to the account route to finish the approve user flow if this is in the URL.
        if (approveToken) {
            navigate({ to: '/approve-user/$token', params: { token: approveToken } });
        }
        if (moveToken) {
            navigate({ to: '/move-service/$moveToken', params: { moveToken } });
        }

        if (accountList.data.length > 1) {
            return setView('accountlist');
        }

        await NXQuery.auth.userData.fetch();

        navigate({ to: searchRef ?? '/dashboard' });
    };

    /***** RENDER HELPERS *****/
    const iconClasses = classNames('icon', 'login__handler__passwordIcon', {
        'icon-eye-open': isPasswordVisible,
        'icon-eye-closed': !isPasswordVisible,
    });

    /***** RENDER *****/
    return (
        <Flex direction="column" justify="center" align="center" className="login__handler">
            <div className="login__header">
                <img src={AppLogo} alt="CCP" />
            </div>
            <FormProvider {...Form}>
                <form className="login__form" onSubmit={Form.handleSubmit(handleSubmit)}>
                    <div className="form__row">
                        <div className="form__column full login__textfield">
                            <Form.Input
                                name="email"
                                label="Email Address"
                                intrinsicProps={{
                                    autoComplete: 'email',
                                    type: 'email',
                                    className: 'form__textfield',
                                }}
                            />

                            <div className="form__column full login__textfield" style={{ position: 'relative' }}>
                                <Form.Input
                                    name="password"
                                    label="Password"
                                    intrinsicProps={{
                                        autoComplete: 'current-password',
                                        type: isPasswordVisible ? 'text' : 'password',
                                        className: 'form__textfield',
                                    }}
                                />

                                {/* Button comes after input in dom to ensure correct tab ordering */}
                                <button type="button" onClick={toggleIsPasswordVisible} tabIndex={0} className="login__handler__passwordIconButton">
                                    <i className={iconClasses} />
                                </button>
                            </div>

                            <div className="form__column full login__checkboxfield login__rememberButton">
                                <Form.CheckboxList.Item
                                    name="remember"
                                    intrinsic={{
                                        autoComplete: 'current-remember',
                                    }}
                                >
                                    Keep me logged in for 30 days
                                </Form.CheckboxList.Item>
                            </div>
                        </div>
                    </div>

                    <LoginAuthSubmitButton />
                </form>
            </FormProvider>
            <div className="login__link-container">
                <Anchor className="login__link login__forgotDetailsButton" onClick={() => setView('forgot')}>
                    Forgot your details?
                </Anchor>
            </div>
        </Flex>
    );
};
