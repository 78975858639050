import { ms365InformationData } from './microsoftId/useGetMs365InformationData';
import { updateMs365ConfigCalcuateData } from './microsoftId/useUpdateMs365ConfigCalcuateData';
import { updateMs365ConfigData } from './microsoftId/useUpdateMs365ConfigData';

export const _ms365Query = Object.freeze({
    microsoftId: {
        getMs365Information: ms365InformationData,
        updateMs365ConfigData,
        updateMs365ConfigCalcuateData
    }
});
