import { useSearch } from '@tanstack/react-router';
import { SolidButton } from 'components/Buttons/SolidButton';
import NXTable from 'components/NXTable';
import { renderForBrands } from 'config/brandRenderer/helper';
import { useExpressServiceManagerContext } from 'containers/dashboard/Components/expressServiceManager/context';
import { getPurchaseLink } from 'containers/services/consts';
import { NXQuery } from 'utilities/query';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type HostingTableBody = React.FC<{
    children: React.ReactNode;
}>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const HostingTableBody: HostingTableBody = ({ children }) => {
    /***** HOOKS *****/
    const isExpressServiceManager = useExpressServiceManagerContext();
    const { search } = useSearch({ strict: false });

    /***** QUERIES *****/
    const { data: listData, isLoading: isListLoading, isError } = NXQuery.hosting.list.useSearchQuery(({ data }) => data);
    const { data: searchData, isLoading: isSearchLoading } = NXQuery.hosting.search.useSearchQuery({ select: ({ data }) => data });

    const data = searchData ?? listData;
    const isLoading = isListLoading || isSearchLoading;

    /***** RENDER HELPERS *****/
    const isErrorMessage = isError && "You have no hosting services on your account. Why don't you purchase some from our website!";
    const getIsEmptyMessage = () => {
        if (isError) return false;
        if (data?.length) return false;

        return (
            <>
                {(() => {
                    if (isExpressServiceManager) return 'You have no hosting services on your account.';
                    else if (search) return 'No hosting services matched your search.';
                    else if (!renderForBrands(['ventra'])) return 'You have no hosting services on your account.';
                    return "You have no hosting services on your account. Why don't you purchase some from our website!";
                })()}
                {!search && renderForBrands(['ventra']) && (
                    <SolidButton className="HostingTable__noServiceAction" type="anchor" href={getPurchaseLink('Web Hosting')}>
                        Purchase Web Hosting
                    </SolidButton>
                )}
            </>
        );
    };

    /***** RENDER *****/
    return (
        <NXTable.Body isLoading={isLoading} isError={isErrorMessage} isEmpty={getIsEmptyMessage()}>
            {children}
        </NXTable.Body>
    );
};
