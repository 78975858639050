import type { QueryClient } from '@tanstack/react-query';
import type { AnyRouter } from '@tanstack/react-router';
import { createRootRouteWithContext, Navigate, Outlet, redirect, ScrollRestoration, useLocation, useRouter } from '@tanstack/react-router';
import { loadAppConfig } from 'App/actions/loadAppConfig';
import { CustomErrorFallback } from 'components/Errors/ErrorBoundary';
import PageNotFound from 'components/Errors/PageNotFound';
import InvoiceURLLightbox from 'components/Lightboxes/OverlayLightbox/Components/invoice/invoiceUrlLightbox';
import AppLoader from 'components/Loaders/App';
import RequestLoader from 'components/Loaders/Request';
import { StaffMenu } from 'components/StaffMenu';
import ToastNotification from 'components/Toast';
import type React from 'react';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import type { Action, Store } from 'redux';
import { useIsAuthenticated } from 'utilities/hooks/redux/useIsAuthenticated';
import type { NXQuery } from 'utilities/query';
import { z } from 'zod';
import './_Root.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type RouterContext = {
    /**
     * Tanstack Query client
     */
    queryClient: QueryClient;

    /**
     * Redux store
     */
    store: Store<unknown, Action, any>;

    /**
     * NXQuery tree
     */
    NXQuery: typeof NXQuery;
};
type AppMounting = React.FC<{ children: React.ReactNode }>;

/**********************************************************************************************************
 *   ROUTE SCHEMA
 **********************************************************************************************************/
const rootSchema = z.object({
    // Token provided for approving an additional user. This is only used to redirect to the correct route
    'token': z.string().optional(),

    // Token provided for approving a move service. This is only used to redirect to the correct route
    'move_token': z.string().optional(),

    // Used to identify what action was performed on the invoice lightbox URL
    'invoice-action': z.union([z.literal('cancel'), z.literal('pay')]).optional()
});

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const RootRoute = createRootRouteWithContext<RouterContext>()({
    errorComponent: CustomErrorFallback,
    pendingComponent: () => <AppLoader fullHeight />,
    notFoundComponent: NotFoundComponent,
    component: RootRouteComponent,
    validateSearch: rootSchema,
    staleTime: 0,
    async beforeLoad({ location, search: { token, move_token }, cause, matches }) {
        // Handle legacy "referral(s)" hash to ensure historical legacy links are redirected to the correct location.
        // Added: 14/10/2024; consider removing in +1 year.
        if (location.pathname.includes('/account/general/referrals')) {
            throw redirect({
                to: '/account/general',
                hash: 'referral',
                search: (prev) => prev,
                replace: true
            });
        }

        // Handle legacy account emails routes to ensure legacy links are redirected to the correct location.
        // Added: 14/10/2024; consider removing in +1 year.
        if (location.pathname.startsWith('/home/account/emails')) {
            throw redirect({
                to: '/account/emails',
                hash: 'view',
                search: (prev) => prev,
                replace: true
            });
        }

        if (cause === 'enter') {
            // When mounting, take the user to the relevant route/loader
            if (token) {
                throw redirect({ to: '/approve-user/$token', params: { token } });
            }

            if (move_token) {
                throw redirect({ to: '/move-service/$moveToken', params: { moveToken: move_token } });
            }

            if (!matches.some((match) => match.staticData.preventLoadAppConfig)) {
                // Attempt to login the user first although we only want to do this once when the application mounts
                // Some routes do not want this to happen, but moving this further down the router would cause it to
                // run when switching to said route, and we do not want to have to include every route in this file
                // that does not want this behavior
                await loadAppConfig({
                    context: 'loader',
                    preventSuccessRedirect: true
                });
            }
        }
    }
});

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const AppMounting: AppMounting = ({ children }) => {
    const app_mounting = useSelector((state: any) => state.app.app_mounting);

    if (app_mounting) {
        return <AppLoader fullHeight />;
    }

    return children;
};

/**********************************************************************************************************
 *   ROUTE COMPONENT START
 **********************************************************************************************************/
function RootRouteComponent() {
    const hasRenderedRef = useRef(false);

    // Allow "AnyRouter" instead of registered router to avoid type errors relating to updating the router directly
    const router = useRouter<AnyRouter>();

    // We need to call `loadAppConfig` in the beforeLoad to ensure that we are correctly authenticated and subsequently redirect
    // However when calling beforeLoad in the __root__ route, this doesn't use the `pendingComponent` defined on the route,
    // but instead uses the defaultPendingComponent of the router. I assume this is a bug - however, since we do not want a
    // requestLoader showing at this step, we have instead defaulted the pendingComponent to an AppLoader and then on the first
    // actual render, update it to a RequestLoader before we move onto deeper routes

    // This cannot be done in the loader because we do not have a reference to the router itself within the loader / beforeLoad.
    // If you are reading this, please check if this has changed as of the latest version, or if before load is correctly
    // using the pending component on the route, and if so, change it to use the "correct" approach instead of this.

    if (!hasRenderedRef.current) {
        hasRenderedRef.current = true;
        router.update({
            defaultPendingComponent: RequestLoader
        });
    }

    return (
        <>
            <InvoiceURLLightbox />
            <ToastNotification />
            <StaffMenu />
            <ScrollRestoration getKey={({ pathname }) => pathname} />
            <AppMounting>
                <Outlet />
            </AppMounting>
        </>
    );
}

/**********************************************************************************************************
 *   NOT FOUND COMPONENT
 **********************************************************************************************************/
/**
 * Note: If you are here because a route is disabled in the config file and the notfound component showing is propagating to the
 * __root component when it should not, please refer to: {@link https://github.com/TanStack/router/issues/2139}
 */
function NotFoundComponent() {
    /***** HOOKS *****/
    const isAuthenticated = useIsAuthenticated();
    const pathname = useLocation({ select: ({ pathname }) => pathname });

    /***** RENDER *****/
    return (
        <div className="RootNotFound">
            <PageNotFound />
            {!isAuthenticated && <Navigate to="/login" search={{ ref: pathname }} />}
        </div>
    );
}

