export const CaveatTypes = Object.freeze({
    /**
     * Editor Layer
     *
     * Can use the target key within the current list of properties to handle filling out the title on the accordion based on the value of that target,
     * i.e. the user types in a title field, it automatically updates the title of the accordion
     */
    ACCORDION_TITLE_VALUE_TARGET: 'accordion_title_value_target',

    /**
     * Editor Layer
     *
     * Renders a NXBox Description above the property
     */
    BOX_DESCRIPTION: 'box_description',

    /**
     * Editor Layer
     *
     * Renders a NXBox title above the property
     */
    BOX_TITLE: 'box_title',

    /**
     * Editor Layer
     *
     * Allows us to tell the frontend that we want to do custom rendering based on the keys manually.
     * (Somewhat unstable because if the key changes it breaks the frontend rendering, this would be better if it was all handled  on the backend)
     */
    COLOUR_STYLE_SELECT_TILES: 'colour_style_select_tiles',

    /**
     * Editor Layer
     *
     * Allows for the creation of a complex label which has state based on hover, values in key and priority label see ComplexLabelCaveat
     */
    COMPLEX_LABEL: 'complex_label',

    /**
     * Editor Layer
     *
     * When the user clicks on the rendered version of the property, copy the value to the target key
     */
    COPY_VALUE_TO_TARGET_KEY_ON_CLICK: 'copy_value_to_target_key_on_click',

    /**
     * Editor Layer
     *
     * Renders a Text component with props as required
     */
    CUSTOM_TEXT: 'custom_text',

    /**
     * Editor Layer
     *
     * Makes the description of an input field Italic
     */
    DESCRIPTION_ITALIC: 'description_italic',

    /**
     * Editor Layer
     *
     * Shows an orange dialog notification with content when the field has not value, Instead of the field
     */
    DIALOG_NOTIFICATION_WHEN_NO_VALUE: 'dialog_notification_when_no_value',

    /**
     * Editor Layer
     *
     * Allows setting the field to the "disabled" state, preventing usage
     */
    DISABLED: 'disabled',

    /**
     * Editor Layer
     *
     * Render a disabled input field instead, this is sort of a placeholder to display some information to the user that they should not be able to modify
     */
    DISABLED_INPUT_FIELD: 'disabled_input_field',

    /**
     * Editor Layer
     *
     * Used on a `RepeatablePropertyDefinition` to determine how the user creates the property,
     * i.e. from `KatanaCaveatNamespace.EntryCreationBehaviourTypes` -> `on_image_add`,
     *  this renders an image dropper which then creates the whole property with the image getting set on the target key
     */
    ENTRY_CREATION_BEHAVIOUR: 'entry_creation_behaviour',

    /**
     * Editor Layer
     *
     * Used in combination with `EntryCreationBehaviourCaveat`
     * When the `$target_key` is provided when the entry behaviour gets the first input in the repeatable array it copies the value over to the `$target_key`
     */
    ENTRY_CREATION_FIRST_ENTRY_COPY_TO_TARGET_KEY: 'entry_creation_first_entry_copy_to_target_key',

    /**
     * Editor Layer
     *
     * Used in combination with `EntryCreationBehaviourCaveat`
     * This determines if the rendered Entry Creation element is rendered below or above the list/entries of repeatable properties.
     */
    ENTRY_CREATION_POSITION: 'entry_creation_position',

    /**
     * Editor Layer
     *
     * Used in combination with `RepeatablePropertyDefinition`
     * This adds a simple visual label to the first entry in the Repeatable Properties list in the Editor Layer
     */
    FIRST_ITEM_IN_LIST_LABEL_CAVEAT: 'first_item_in_list_label_caveat',

    /**
     * Editor Layer
     *
     * Adds a minimum requirement of how many repeatable properties need to be present in order for them to get submitted,
     * This will get replaced with proper validation rules.
     */
    GROUP_PROPERTY_MINIMUM_REQUIRED_PROPERTY_VALUES: 'group_property_minimum_required_property_values',

    /**
     * Editor Layer
     *
     * Hidden input field for handling more hardcoded fields
     * Still makes the key available but hides it for the user, so they can't input it manually
     */
    HIDDEN: 'hidden',

    /**
     * Editor Layer, Visual Layer
     *
     * Doesn't show the property when the section style is one of a range of styles
     */
    HIDE_ON_SECTION_STYLE: 'hide_on_section_style',

    /**
     * Editor Layer, Visual Layer
     *
     * Doesn't show the property when the target field key has a specific value
     */
    HIDE_PROPERTY_WHEN_KEY_HAS_VALUE: 'hide_property_when_key_has_value',

    /**
     * Editor Layer
     *
     * For handling the validation of image mime types
     * This will get replaced with proper validation rules later
     */
    IMAGE_MIME_TYPE: 'image_mime_type',

    /**
     * Editor Layer
     *
     * Renders an "IndentFlush" component to render the content at an additional indent
     */
    INDENT_FLUSH: 'indent_flush',

    /**
     * Editor Layer
     *
     * Renders an interactive google maps frame based on the data value key for the katana service details.
     * i.e. `business.address`, the frontend will try to get the address to render in the google maps frame form the katana business address
     */
    INTERACTIVE_GOOGLE_MAPS: 'interactive_google_maps',

    /**
     * Editor Layer
     *
     * Renders the options of media picking form
     */
    MEDIA_FEATURES: 'media_features',

    /**
     * Editor Layer
     *
     * Used in conjunction with `RepeatablePropertyDefinition`.
     *      When the property gets removed
     *      If the value of the current property is in the target property
     *      Then the value of the target property gets reassigned to the first entry of the list
     *      (if available)
     */
    REMOVE_ENTRY_AND_REASSIGN_FIRST: 'remove_entry_and_reassign_first',

    /**
     * Editor Layer
     *
     * Value to display in the property's placeholder
     */
    PLACEHOLDER: 'placeholder',

    /**
     * Editor Layer
     *
     * Set a validation limit for the amount of repeatable properties you're allowed to create
     * This will get replaced with proper validation rules later
     */
    REPEATABLE_PROPERTY_LIMIT: 'repeatable_property_limit',

    /**
     * Editor Layer
     *
     * Overrides the Image Title Description blocks' description that's displayed above each Section Editor Form
     */
    SECTION_CONFIGURATION_DESCRIPTION: 'section_configuration_description',

    /**
     * Editor Layer
     *
     * Overrides the Image Title Description blocks' title that's displayed above each Section Editor Form
     */
    SECTION_CONFIGURATION_INFORMATION_TITLE_DESCRIPTION_CAVEAT: 'section_configuration_information_title_description_caveat',

    /**
     * Editor Layer, Visual Layer
     *
     * Only shows the property when the target field key has a specific value
     * This uses `deepFind` in the frontend, which returns an array of the first instances where the object has the key.
     * This means it won't dig deeper into the object if it finds the key in the first level.
     */
    SHOW_PROPERTY_WHEN_KEY_HAS_VALUE: 'show_property_when_key_has_value',

    /**
     * Editor Layer
     *
     * Allows setting of the singular name of the repeated property for displaying in things like the remove button
     * i.e. "Remove Service" vs "Remove Services" (where "Services" is inferred from the repeated property definition name
     */
    SINGULAR_NAME: 'singular_name',

    /**
     * Editor Layer
     *
     * Renders a social account login button
     */
    SOCIAL_LOGIN: 'social_login',

    /**
     * Editor Layer
     *
     * Used in combination with the KatanaTextFormField input_type for Text and TextArea
     */
    RICH_TEXT: 'rich_text',

    /**
     * Editor Layer
     *
     * Renders a tooltip with the text provided
     */
    TOOL_TIP: 'tool_tip',

    /**
     * Editor Layer
     *
     * Determines the override for how many items per row to display
     */
    ITEMS_PER_ROW: 'items_per_row',

    /**
     * Editor Layer
     *
     * Used to get data into the dynamic fields based on the katana service path.
     * i.e. `business.title` or `site.style`, etc.
     */
    VALUE_FROM_SERVICE_DATA: 'value_from_service_data'
});
