import { useMutation, useMutationState, type MutationOptions, type MutationState } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import { createExecuteMutation, mutationOptions } from 'utilities/methods/tanstack/createMutation/utilities';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type CreateOptions<TData, TError, TVariables, TInternalContext> = Omit<
    MutationOptions<TData, TError, TVariables, TInternalContext>,
    'mutationKey' | 'mutationFn'
>;
type CreateOptionsReturnType<TData, TError, TVariables, TContext> = (
    opts?: CreateOptions<TData, TError, TVariables, TContext>
) => MutationOptions<TData, TError, TVariables, TContext>;
type Options<TData, TError, TVariables, TContext> = Omit<MutationOptions<TData, TError, TVariables, TContext>, 'mutationKey' | 'mutationFn'>;

/**
 * Factory for creating the internal returned functions for createNXMutation. This should handle the
 * core logic so that we can create two types of `createNXMutation`, one where it accepts the variables in
 * the hook, another where it accepts the variables in the mutate function (and can therefore be called outside react).
 */
export class InternalMutationFactory<TData = unknown, TError = AxiosError, TVariables = void, TContext = unknown> {
    constructor() {}

    /**
     * Creates a function that can be used to create mutation options. This is useful for creating a mutation with a default set of options,
     * as well as using these options outside of react to build a mutation.
     */
    public createMutationOptions = (defaultOpts: MutationOptions<TData, TError, TVariables, TContext>) => {
        return <TInternalContext = TContext>(opts?: CreateOptions<TData, TError, TVariables, TInternalContext>) => {
            return mutationOptions<TData, TError, TVariables, TInternalContext>({
                ...(defaultOpts as unknown as MutationOptions<TData, TError, TVariables, TInternalContext>),
                ...opts
            });
        };
    };

    /**
     * This method is typically not necessary, but in the case where we want to call the logout API outside of react, and still populate the mutation cache, the execute
     * method is available. This allows us to use the same NXQuery inside and outside react.
     *
     * This is also useful if we want this method to be called in a class component where hooks are not available, and there is no side effect necessary
     *
     * @see {@link https://github.com/TanStack/query/blob/5d29f8ff47958a2349fdc4ca682c3630beeabc9d/packages/query-core/src/tests/utils.ts#L35-L44}
     */
    public executeMutation = (createOpts: CreateOptionsReturnType<TData, TError, TVariables, any>) => {
        return createExecuteMutation(createOpts);
    };

    /**
     * Standard useMutation hook to be consumed within react. This is automatically typed based on the mutation options provided.
     *
     * Note that this function returns a mutation that accepts a single param. In the future, to support passing args to the hook (instead of mutate function)
     * we will need a separate hook.
     */
    public useStandardMutation = (createOpts: CreateOptionsReturnType<TData, TError, TVariables, any>) => {
        return <TInternalContext = TContext>(opts?: Options<TData, TError, TVariables, TInternalContext>) => {
            return useMutation(createOpts(opts));
        };
    };

    /**
     * Creates a useSelectMutationState hook that can be used to access the mutation state based on the mutationKey and provide an optional select function to
     * transform the data.
     */
    public useSelectMutationState = (createOpts: CreateOptionsReturnType<TData, TError, TVariables, any>) => {
        type Select<TSelected> = (mutationState: MutationState<TData, TError, TVariables, unknown>[] | undefined) => TSelected;

        /**
         * Allows the consumer to access the mutation state for this mutation and select (transform) the array of states into the desired return value.
         */
        return <TSelected = MutationState<TData, TError, TVariables, unknown>[]>(select: Select<TSelected> = (d) => d as any) => {
            const mutationState = useMutationState<MutationState<TData, TError, TVariables>>({
                filters: {
                    mutationKey: createOpts().mutationKey
                }
            });

            /***** HOOK RESULTS *****/
            return select(mutationState);
        };
    };

    /**
     * Creates a similar hook to useSelectMutationState, but only returns the latest mutation state. This is useful for when we only care about the latest mutation state.
     */
    public useSelectLatestMutationState = (createOpts: CreateOptionsReturnType<TData, TError, TVariables, any>) => {
        type Select<TSelected> = (mutationState: MutationState<TData, TError, TVariables, unknown> | undefined) => TSelected;

        /**
         * Allows the consumer to access the latest mutation state for this mutation, and select a subset of data to return to the component. Note that
         * the selector is handled inside react, so does not prevent re-renders, but does provide a convenience API for accessing relevant data in one place, instead
         * of getting the latest state and then deriving it in subsequent lines/variables
         */
        return <TSelected = MutationState<TData, TError, TVariables, unknown> | undefined>(select: Select<TSelected> = (d) => d as any) => {
            const mutationState = useMutationState<MutationState<TData, TError, TVariables>>({
                filters: {
                    mutationKey: createOpts().mutationKey
                }
            });

            /***** HOOK RESULTS *****/
            return select(mutationState.at(-1));
        };
    };
}
