import { DisabledEvent } from 'components/Buttons/DisabledEvent';
import { SolidButton } from 'components/Buttons/SolidButton';
import { HOOK_FORM_BUTTON_STATE } from 'components/Form/Button/hookForm/consts';
import type { HookFormButtonNamespace } from 'components/Form/Button/hookForm/types';
import _ from 'lodash';
import React, { useCallback } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';
import { getAllPaths } from 'utilities/methods/getAllPaths/getAllPaths';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type HookFormButton = React.FC<HookFormButtonNamespace.Props>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @description
 * Button to be used within a React Hook Form as the submit button. This button will automatically disable itself if the form is invalid,
 * pristine, or submitting. If the form is not valid, the button will be disabled and clicking it will trigger the form to be touched,
 * which will display the errors to the user.
 *
 * If the form that this button is associated with has no initial fields, ensure that initial values are provided to the form otherwise
 * the button will not be able to determine if the form exists or not.
 *
 * While in development mode, instead of displaying the default text when the form does not exist, a warning will be displayed to the developer.
 */
export const HookFormButton: HookFormButton = ({ children, color, className, loading, force, ignorePristine, onDisabledClick }) => {
    /***** HOOKS *****/
    const { getValues, setValue, trigger } = useFormContext();
    const { isSubmitting, isDirty, isValid } = useFormState();

    /***** FUNCTIONS *****/
    const handleDisabledClick: HookFormButtonNamespace.OnInactiveClick = useCallback(
        async (e) => {
            if (e instanceof KeyboardEvent && e.key !== 'Enter') {
                return; // Do not trigger touch if key is not "Enter"
            }

            /**
             * 1. Set all fields to touched & dirty so that all validation errors will show
             * 2. trigger validation to run.
             *
             * This assumes that all fields were provided with an initial value, which we should be doing anyway
             */
            const formValues = getValues();
            const allPaths = getAllPaths(formValues);

            allPaths.forEach((path) => {
                const value = _.get(formValues, path);
                setValue(path, value, { shouldTouch: true, shouldDirty: true });
            });

            await trigger();

            onDisabledClick?.(e);
        },
        [onDisabledClick]
    );

    /***** RENDER *****/
    const button = {
        [HOOK_FORM_BUTTON_STATE.INACTIVE]: (
            <DisabledEvent active onDisabledClick={handleDisabledClick} data-testid="form-submit-button">
                <SolidButton disabled key="inactive" className={className}>
                    {children}
                </SolidButton>
            </DisabledEvent>
        ),

        [HOOK_FORM_BUTTON_STATE.ACTIVE]: (
            <SolidButton className={className} color={color} isLoading={loading || isSubmitting} type="submit" data-testid="form-submit-button">
                {children}
            </SolidButton>
        ),
    };

    switch (true) {
        case !!force:
            return button[force];
        case !isDirty && !ignorePristine:
        case !isValid:
            return button[HOOK_FORM_BUTTON_STATE.INACTIVE];
        case isSubmitting:
        case loading:
        default:
            return button[HOOK_FORM_BUTTON_STATE.ACTIVE];
    }
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
