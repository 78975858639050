export const AUDIRECT_REGISTER_REQUEST = 'audirect/AUDIRECT_REGISTER_REQUEST';
export const AUDIRECT_REGISTER_SUCCESS = 'audirect/AUDIRECT_REGISTER_SUCCESS';
export const AUDIRECT_REGISTER_ERROR = 'audirect/AUDIRECT_REGISTER_ERROR';
export const AUDIRECT_REGISTER_RESET = 'audirect/AUDIRECT_REGISTER_RESET';

export const AUDIRECT_STATUS_REQUEST = 'audirect/AUDIRECT_STATUS_REQUEST';
export const AUDIRECT_STATUS_SUCCESS = 'audirect/AUDIRECT_STATUS_SUCCESS';
export const AUDIRECT_STATUS_ERROR = 'audirect/AUDIRECT_STATUS_ERROR';
export const AUDIRECT_STATUS_RESET = 'audirect/AUDIRECT_STATUS_RESET';

export const AUDIRECT_CONTENTION_DOMAINS_REQUEST = 'auDirect/AUDIRECT_CONTENTION_DOMAINS_REQUEST';
export const AUDIRECT_CONTENTION_DOMAINS_SUCCESS = 'auDirect/AUDIRECT_CONTENTION_DOMAINS_SUCCESS';
export const AUDIRECT_CONTENTION_DOMAINS_ERROR = 'auDirect/AUDIRECT_CONTENTION_DOMAINS_ERROR';

export const AUDIRECT_CONTESTED_DOMAINS_REQUEST = 'auDirect/AUDIRECT_CONTESTED_DOMAINS_REQUEST';
export const AUDIRECT_CONTESTED_DOMAINS_SUCCESS = 'auDirect/AUDIRECT_CONTESTED_DOMAINSS_SUCCESS';
export const AUDIRECT_CONTESTED_DOMAINS_ERROR = 'auDirect/AUDIRECT_CONTESTED_DOMAINS_ERROR';

export const AUDIRECT_APPLY_REQUEST = 'auDirect/AUDIRECT_APPLY_REQUEST';
export const AUDIRECT_APPLY_SUCCESS = 'auDirect/AUDIRECT_APPLY_SUCCESS';
export const AUDIRECT_APPLY_ERROR = 'auDirect/AUDIRECT_APPLY_ERROR';

export const AUDIRECT_UPDATE_APPLICATION_REQUEST = 'auDirect/AUDIRECT_UPDATE_APPLICATION_REQUEST';
export const AUDIRECT_UPDATE_APPLICATION_SUCCESS = 'auDirect/AUDIRECT_UPDATE_APPLICATION_SUCCESS';
export const AUDIRECT_UPDATE_APPLICATION_ERROR = 'auDirect/AUDIRECT_UPDATE_APPLICATION_ERROR';
