import { BaseDropdown } from 'components/Dropdowns/_BaseDropdown';
import type { DropdownNamespace } from 'components/Dropdowns/types';
import './_SolidDropdown.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
// IMPROVE: Base styling for the list does not match OutlineDropdown
/**
 * Solid Dropdown Variant, use this for most dropdowns
 */
export const SolidDropdown: React.FC<DropdownNamespace.Props> = (props) => <BaseDropdown variant="SolidDropdown" {...props} />;
