import { queryOptions, useQuery } from '@tanstack/react-query';
import { katanaQueryKeys } from 'containers/katana/queries/katanaQueryKeys';
import { sortSectionIntoCorrectOrder } from 'containers/katana/queries/methods/sortSectionOrder';
import type { KatanaNamespace } from 'containers/katana/types';
import { queryClient } from 'store/queryClient';
import { KATANA_API } from 'utilities/api/katana';
import { handleDefaultErrorNotification } from 'utilities/methods/commonActions/handleDefaultErrorNotification';
import { createGetQueryDataMethod } from 'utilities/methods/tanstack/createGetQueryDataMethod';
import { createUseQuerySelectorMethod } from 'utilities/methods/tanstack/createUseQuerySelectorMethod';

type TData = Awaited<ReturnType<typeof KATANA_API.katana.site.service_id.meta.sections.GET>>;

function createQueryKey() {
    return katanaQueryKeys.katana.meta.sections();
}

function createQueryOptions(serviceID: KatanaNamespace.ServiceId) {
    return queryOptions({
        // eslint-disable-next-line @tanstack/query/exhaustive-deps
        queryKey: createQueryKey(),
        queryFn: () =>
            KATANA_API.katana.site.service_id.meta.sections.GET(serviceID).catch((e) => {
                handleDefaultErrorNotification(e);
                throw e;
            }),
        staleTime: Infinity,
        enabled: Boolean(serviceID),
        select: (data) => {
            return data.data;
        }
    });
}

const getQueryData = createGetQueryDataMethod<KatanaNamespace.ServiceId, TData>(createQueryKey);

function prefetchQuery(serviceID: KatanaNamespace.ServiceId) {
    return queryClient.prefetchQuery(createQueryOptions(serviceID));
}

function useIsFetching(serviceID: KatanaNamespace.ServiceId) {
    return queryClient.isFetching({ queryKey: createQueryKey(serviceID) });
}

function ensureQueryData(serviceID: KatanaNamespace.ServiceId) {
    return queryClient.ensureQueryData(createQueryOptions(serviceID));
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Gets section definitions for katana site
 */
function _useQuery(serviceID: KatanaNamespace.ServiceId) {
    return useQuery(createQueryOptions(serviceID));
}

const useQuerySelector = createUseQuerySelectorMethod<KatanaNamespace.ServiceId, TData, typeof createQueryOptions>(createQueryOptions);

/**
 * Gets section definitions ordered
 */
function useOrderedQuery(serviceID: KatanaNamespace.ServiceId) {
    return useQuery({
        ...createQueryOptions(serviceID),
        select: (response) => {
            return sortSectionIntoCorrectOrder(Object.values(response.data));
        },
    });
}

/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const sectionDefinitions = Object.freeze({
    useQuery: _useQuery,
    useQuerySelector,
    useOrderedQuery,
    useIsFetching,
    getQueryData,
    prefetchQuery,
    createQueryKey,
    createQueryOptions,
    ensureQueryData,
});
