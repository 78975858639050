import { Store } from '@tanstack/react-store';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type OrderingFormStore = {
    isPremiumAcknowledgedMap: Record<string, boolean>;
};

export const OrderingFormStore = new Store<OrderingFormStore>({
    isPremiumAcknowledgedMap: {}
});
