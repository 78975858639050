import classNames from 'classnames';
import { adjustNumberRatio } from 'components/Icons/adjustNumberRatio';
import { phosphorIconPropKeys } from 'components/Icons/Phosphor/consts';
import type { IconifyIconNamespace } from 'components/Icons/types';
import { createAppliedStylingClasses } from 'components/Utils/methods';
import './_SectionIcon.scss';

// Ratio of the original SVG size vs the standard size
const ratio = 255 / 28.3;
const path1 = adjustNumberRatio(
    'M25.4,7.6H2.9c-1.4,0-2.6,1.1-2.6,2.6v7.9c0,1.4,1.1,2.6,2.6,2.6h22.5c1.4,0,2.6-1.1,2.6-2.6v-7.9C28,8.8,26.9,7.6,25.4,7.6z',
    ratio
);
const path2 = adjustNumberRatio(
    'M26.7,0c-0.7,0-1.3,0.6-1.3,1.3v1.2H2.9V1.3C2.9,0.6,2.3,0,1.6,0C0.9,0,0.3,0.6,0.3,1.3v1.2c0,1.4,1.1,2.6,2.6,2.6h22.5c1.4,0,2.6-1.2,2.6-2.6V1.3C28,0.6,27.4,0,26.7,0z',
    ratio
);
const path3 = adjustNumberRatio(
    'M1.6,28.3c0.7,0,1.3-0.6,1.3-1.3v-1.2h22.5v1.2c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3v-1.2c0-1.4-1.1-2.6-2.6-2.6H2.9c-1.4,0-2.6,1.2-2.6,2.6v1.2C0.3,27.8,0.9,28.3,1.6,28.3z',
    ratio
);

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const Section: IconifyIconNamespace.SVGComponent = (props = {}) => {
    const { size = 25, svgProps } = props;

    /***** RENDER HELPERS *****/
    const appliedStylingClasses = createAppliedStylingClasses({
        props,
        keyBoundary: phosphorIconPropKeys,
        componentName: 'Icon',
        delimiter: '--'
    });

    const styles = {
        transform: classNames({
            [`rotate(${svgProps?.rotate}deg)`]: svgProps?.rotate
        })
    };

    return (
        <svg
            width={size}
            height={size}
            className={classNames('Icon', appliedStylingClasses, 'SectionIcon')}
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 256 256"
            fill="currentColor"
            style={styles}
        >
            <g>
                <path d={path1} />
                <path className="SectionIcon__spareBar" d={path2} />
                <path className="SectionIcon__spareBar" d={path3} />
            </g>
        </svg>
    );
};
