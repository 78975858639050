import FetchComponentError from 'components/Errors/FetchComponentError';
import { NXBoxTitle } from 'components/NXBox/Title';
import { Padding } from 'components/Utils/Padding';
import React from 'react';
import './_RefreshableError.scss';

const componentError = (message) => {
    if (message) {
        return {
            data: {
                errors: [
                    {
                        details: message
                    }
                ]
            }
        };
    }

    return null;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @param {{
 *   onRefresh: () => void,
 *   message?: React.ReactNode,
 *   title: React.ReactNode
 * }} props
 */
const _NXBoxRefreshableError = ({ onRefresh, message, title }) => {
    /***** RENDER *****/
    return (
        <Padding xy={6}>
            <NXBoxTitle title={title} />
            <button onClick={onRefresh} className="RefreshableError__refresh">
                <i className="icon icon-reload"></i>
            </button>
            <FetchComponentError error={componentError(message)} />
        </Padding>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default _NXBoxRefreshableError;
