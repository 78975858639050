import { ErrorBoundary } from '@sentry/react';
import classNames from 'classnames';
import FetchComponentError from 'components/Errors/FetchComponentError';
import { actionButtonWidths } from 'components/NXTable/consts';
import { useTableActions } from 'components/NXTable/hooks/useTableActions';
import { checkContextOverrideProps } from 'components/NXTable/methods';
import { NXTableContext } from 'components/NXTable/NXTableContext';
import React, { useRef, useState } from 'react';
import { useStyle } from 'utilities/hooks/useStyle';
import { activePromotion } from 'utilities/methods/commonActions';
import './_NXTable.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type ActivePromotion = Parameters<typeof activePromotion>[number] | Array<Parameters<typeof activePromotion>[number]>;
type Table = React.FC<{
    children: React.ReactNode;
    columns: string;
    onSort?: (sort: NXTableSortValue) => void;
    className?: string;

    /**
     * An active promotion name. If this is provided and the promotion is active, the `actionButtonWidths.promo` will be used
     * for the `--actionButtonWidth` css variable, otherwise `actionButtonWidths.default` will be used.
     *
     * This css variable is used to determine the width of the action buttons and subsequently the width of the action column.
     */
    activePromotion?: ActivePromotion;
}>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const Table: Table = ({ children, columns, onSort, className, activePromotion: activePromotionProp }) => {
    /***** HOOKS *****/
    const tableRef = useRef(null);
    const styles = useStyle({
        '--actionButtonWidth': getActionButtonWidth(activePromotionProp)
    });

    /***** STATE *****/
    const [currentSort, setCurrentSort] = useState({ sortKey: null, sortMethod: false });
    const [columnsFinal, setColumnsFinal] = useState(columns);
    const [allActions, setAllActions] = useTableActions({ columns, setColumnsFinal, columnsFinal, tableRef });

    /***** FUNCTIONS *****/
    /**
     * Function that you need to call when you are performing sort so that the table context can keep record of what is actively being sorted
     */
    function _onSort(sort: NXTableSortValue) {
        setCurrentSort(sort as any);
        onSort?.(sort);
    }

    /***** RENDER HELPERS *****/
    /**
     * The context data that get's passed down the context object
     */
    const context = {
        columnsDefinition: columns,
        columns: columnsFinal,
        _onSort,
        _currentSort: currentSort,
        allActions,
        setAllActions,
        setColumns: setColumnsFinal
    };

    // Override child props
    const overriddenChildren = checkContextOverrideProps({ children, context });

    /***** RENDER *****/
    return (
        <div ref={tableRef} style={styles} className={classNames('NXTable', className)}>
            <ErrorBoundary fallback={<FetchComponentError />}>
                <NXTableContext.Provider value={context as any}>{overriddenChildren}</NXTableContext.Provider>
            </ErrorBoundary>
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
/**
 * Determines whether any of the provided promotions are active and returns the appropriate action button width. This accepts
 * a single promotion or an array of promotions.
 *
 * If not value is provided, the default button with will be returned.
 */
function getActionButtonWidth(activePromotionsProp: ActivePromotion | undefined) {
    if (Array.isArray(activePromotionsProp)) {
        for (const promotion of activePromotionsProp) {
            if (activePromotion(promotion)) {
                return actionButtonWidths.promo;
            }
        }

        return actionButtonWidths.default;
    }

    return activePromotion(activePromotionsProp) ? actionButtonWidths.promo : actionButtonWidths.default;
}
