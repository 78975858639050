import { useNavigate } from '@tanstack/react-router';
import Anchor from 'components/Anchor';
import { SolidButton } from 'components/Buttons/SolidButton';
import { COPY_restoreConfirmDialogNotification } from 'components/Lightboxes/OverlayLightbox/Components/consts';
import { DialogNotification } from 'components/Notifications/DialogNotification';
import { useHostingContext } from 'containers/services/modules/hosting/context';
import htmr from 'htmr';
import { formatDescription } from 'utilities/methods/commonActions';
import { NXQuery } from 'utilities/query';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const RestoreServiceModalContent = () => {
    /***** HOOKS *****/
    const navigate = useNavigate();
    const { id } = useHostingContext();

    /***** QUERIES *****/
    const { data: hostingInformationData } = NXQuery.hosting.serviceId.useSuspenseQuery(id);
    const { mutate: createRestoreInvoice, isPending: isCreatingRestoreInvoice } = NXQuery.hosting.serviceId.createRestoreInvoice.useMutation();
    const { name, recurring_price, billing_cycle_name, recovery_fee, product } = hostingInformationData?.attributes ?? {};

    /***** FUNCTIONS *****/
    const handleOnRestore = () => {
        createRestoreInvoice(id, {
            onSuccess({ data }) {
                navigate({
                    to: '/billing/invoice/$invoiceId/pay',
                    params: { invoiceId: data?.id }
                });
            }
        });
    };

    /***** RENDER *****/
    return (
        <>
            <div className="OverlayRestore__wrapper">
                <div className="OverlayRestore__domain">{name}</div>

                <div className="OverlayRestore__row-spacing OverlayRestore__text">
                    Services that are terminated within 28 days are able to be restored back to the previous state using the Acronis Backup system.
                </div>
                <div className="OverlayRestore__row-spacing OverlayRestore__text--link">
                    <Anchor
                        className="OverlayRestore__row-spacing OverlayRestore__text--link"
                        to="/support/support-centre/web-hosting/ventraip-australia-take-backups-web-hosting-services"
                    >
                        Read more about Acronis here!
                    </Anchor>
                </div>
                <div className="OverlayRestore__details">
                    <div className="OverlayRestore__items">
                        <div className="item__row">
                            <div className="item__column--description">{htmr(formatDescription(name))}</div>
                            <div className="item__column--price">
                                ${recurring_price}/{billing_cycle_name}
                            </div>
                        </div>
                        <div className="item__row">
                            <div className="item__column--description">{htmr(formatDescription(product.name))}</div>
                        </div>
                    </div>
                    <div className="OverlayRestore__items">
                        <div className="item__row">
                            <div className="item__column--description">Recovery Fee</div>
                            <div className="item__column--price">${recovery_fee}</div>
                        </div>
                    </div>
                </div>

                <DialogNotification tail={{ pos: 'bottom' }} type="warning">
                    {COPY_restoreConfirmDialogNotification}
                </DialogNotification>

                <SolidButton type="onClick" isLoading={isCreatingRestoreInvoice} className="OverlayRestore__button" onClick={handleOnRestore}>
                    Restore
                </SolidButton>
            </div>
        </>
    );
};
