/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { queryOptions, useQuery } from '@tanstack/react-query';
import { cloneDeep } from 'lodash';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { queryClient } from 'store/queryClient';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { KATANA_API } from 'utilities/api/katana';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { katanaQueryKeys } from 'containers/katana/queries/katanaQueryKeys';
import { MINUTE } from 'utilities/consts';

function createQueryKey() {
    return katanaQueryKeys.katana.trialDomains();
}

function createQueryOptions() {
    return queryOptions({
        queryKey: createQueryKey(),
        queryFn: () => KATANA_API.katana.trialDomains.GET(),
        staleTime: 5 * MINUTE,
        select: (data) => {
            return data.data;
        }
    });
}

function invalidateQueries() {
    return queryClient.invalidateQueries({
        queryKey: createQueryKey()
    });
}

function refetchQueries() {
    return queryClient.refetchQueries({
        queryKey: createQueryKey(),
        exact: true
    });
}

type TData = Awaited<ReturnType<typeof KATANA_API.katana.trialDomains.GET>>;

function setQueryData(updater: (oldData: TData) => TData | undefined) {
    queryClient.setQueryData(createQueryKey(), updater);
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useQuery() {
    return useQuery(createQueryOptions());
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

function optimisticallyRemoveFirstDomian() {
    setQueryData((oldData) => {
        if (oldData?.status !== 200) {
            return;
        }

        const clonedData = cloneDeep(oldData);

        if (!clonedData?.data?.length) {
            return;
        }

        clonedData.data = clonedData.data.splice(1);

        return clonedData;
    });
}

export const getTrialDomains = Object.freeze({
    useQuery: _useQuery,
    createQueryKey,
    createQueryOptions,
    invalidateQueries,
    refetchQueries,
    optimisticallyRemoveFirstDomian
});
