/**********************************************************************************************************
 *   DECLARATIONS
 **********************************************************************************************************/
export const ACCOUNT_SERVICES_ALIGNMENT_REQUEST = 'account/ACCOUNT_SERVICES_ALIGNMENT_REQUEST';
export const ACCOUNT_SERVICES_ALIGNMENT_SUCCESS = 'account/ACCOUNT_SERVICES_ALIGNMENT_SUCCESS';
export const ACCOUNT_SERVICES_ALIGNMENT_ERROR = 'account/ACCOUNT_SERVICES_ALIGNMENT_ERROR';
export const ACCOUNT_SERVICES_ALIGNMENT_PREVIEW_REQUEST = 'account/ACCOUNT_SERVICES_ALIGNMENT_PREVIEW_REQUEST';
export const ACCOUNT_SERVICES_ALIGNMENT_PREVIEW_SUCCESS = 'account/ACCOUNT_SERVICES_ALIGNMENT_PREVIW_SUCCESS';
export const ACCOUNT_SERVICES_ALIGNMENT_PREVIEW_ERROR = 'account/ACCOUNT_SERVICES_ALIGNMENT_PREVIEW_ERROR';
export const ACCOUNT_SERVICES_ALIGN_REQUEST = 'account/ACCOUNT_SERVICES_ALIGN_REQUEST';
export const ACCOUNT_SERVICES_ALIGN_SUCCESS = 'account/ACCOUNT_SERVICES_ALIGN_SUCCESS';
export const ACCOUNT_SERVICES_ALIGN_ERROR = 'account/ACCOUNT_SERVICES_ALIGN_ERROR';
export const ACCOUNT_SLA_CLAIM_SERVICES_REQUEST = 'account/ACCOUNT_SLA_CLAIM_SERVICES_REQUEST';
export const ACCOUNT_SLA_CLAIM_SERVICES_SUCCESS = 'account/ACCOUNT_SLA_CLAIM_SERVICES_SUCCESS';
export const ACCOUNT_SLA_CLAIM_SERVICES_ERROR = 'account/ACCOUNT_SLA_CLAIM_SERVICES_ERROR';
export const ACCOUNT_SLA_CLAIM_EVENTS_REQUEST = 'account/ACCOUNT_SLA_CLAIM_EVENTS_REQUEST';
export const ACCOUNT_SLA_CLAIM_EVENTS_SUCCESS = 'account/ACCOUNT_SLA_CLAIM_EVENTS_SUCCESS';
export const ACCOUNT_SLA_CLAIM_EVENTS_ERROR = 'account/ACCOUNT_SLA_CLAIM_EVENTS_ERROR';
export const ACCOUNT_SLA_CLAIM_SUBMIT_REQUEST = 'account/ACCOUNT_SLA_CLAIM_SUBMIT_REQUEST';
export const ACCOUNT_SLA_CLAIM_SUBMIT_SUCCESS = 'account/ACCOUNT_SLA_CLAIM_SUBMIT_SUCCESS';
export const ACCOUNT_SLA_CLAIM_SUBMIT_ERROR = 'account/ACCOUNT_SLA_CLAIM_SUBMIT_ERROR';

export const ACCOUNT_WALLET_REQUEST = 'account/ACCOUNT_WALLET_REQUEST';
export const ACCOUNT_WALLET_SUCCESS = 'account/ACCOUNT_WALLET_SUCCESS';
export const ACCOUNT_WALLET_ERROR = 'account/ACCOUNT_WALLET_ERROR';

export const ACCOUNT_WALLET_TRANSACTIONS_REQUEST = 'account/ACCOUNT_WALLET_TRANSACTIONS_REQUEST';
export const ACCOUNT_WALLET_TRANSACTIONS_SUCCESS = 'account/ACCOUNT_WALLET_TRANSACTIONS_SUCCESS';
export const ACCOUNT_WALLET_TRANSACTIONS_ERROR = 'account/ACCOUNT_WALLET_TRANSACTIONS_ERROR';

export const ACCOUNT_WALLET_TRANSACTIONS_SEARCH_REQUEST = 'account/ACCOUNT_WALLET_TRANSACTIONS_SEARCH_REQUEST';
export const ACCOUNT_WALLET_TRANSACTIONS_SEARCH_SUCCESS = 'account/ACCOUNT_WALLET_TRANSACTIONS_SEARCH_SUCCESS';
export const ACCOUNT_WALLET_TRANSACTIONS_SEARCH_ERROR = 'account/ACCOUNT_WALLET_TRANSACTIONS_SEARCH_ERROR';
export const ACCOUNT_WALLET_TRANSACTIONS_SEARCH_RESET = 'account/ACCOUNT_WALLET_TRANSACTIONS_SEARCH_RESET';

export const ACCOUNT_WALLET_ADD_CREDIT_REQUEST = 'account/ACCOUNT_WALLET_ADD_CREDIT_REQUEST';
export const ACCOUNT_WALLET_ADD_CREDIT_SUCCESS = 'account/ACCOUNT_WALLET_ADD_CREDIT_SUCCESS';
export const ACCOUNT_WALLET_ADD_CREDIT_ERROR = 'account/ACCOUNT_WALLET_ADD_CREDIT_ERROR';

export const ACCOUNT_CALCULATE_WALLET_CASH_OUT_REQUEST = 'account/ACCOUNT_CALCULATE_WALLET_CASH_OUT_REQUEST';
export const ACCOUNT_CALCULATE_WALLET_CASH_OUT_SUCCESS = 'account/ACCOUNT_CALCULATE_WALLET_CASH_OUT_SUCCESS';
export const ACCOUNT_CALCULATE_WALLET_CASH_OUT_ERROR = 'account/ACCOUNT_CALCULATE_WALLET_CASH_OUT_ERROR';

export const ACCOUNT_WALLET_CASH_OUT_REQUEST = 'account/ACCOUNT_WALLET_CASH_OUT_REQUEST';
export const ACCOUNT_WALLET_CASH_OUT_SUCCESS = 'account/ACCOUNT_WALLET_CASH_OUT_SUCCESS';
export const ACCOUNT_WALLET_CASH_OUT_ERROR = 'account/ACCOUNT_WALLET_CASH_OUT_ERROR';
