import { queryOptions, useQuery } from '@tanstack/react-query';
import { katanaQueryKeys } from 'containers/katana/queries/katanaQueryKeys';
import { _newSection } from 'containers/katana/queries/serviceID/sections/predicates/_newSection';
import _ from 'lodash';
import { queryClient } from 'store/queryClient';
import { KATANA_API } from 'utilities/api/katana';
import { SECOND } from 'utilities/consts';
import { TruthKeys } from 'utilities/methods/commonActions/Truth';
import { createSetQueryDataMethod } from 'utilities/methods/tanstack/createSetQueryDataMethod';
import { createOptimisticMethods } from 'utilities/methods/tanstack/optimistic/createOptimisticMethods';
import { createOptimisticResponseObject } from 'utilities/methods/tanstack/optimistic/createOptimisticResponseObject';
import type { NXQueryUtils } from 'utilities/methods/tanstack/types';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Params = Parameters<typeof KATANA_API.katana.site.service_id.pages.page_id.sections.GET>[0];
type TData = Awaited<ReturnType<typeof KATANA_API.katana.site.service_id.pages.page_id.sections.GET>>;
type TData200 = NXQueryUtils.ApiData200<TData>;

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
function createQueryKey(params: Params) {
    return katanaQueryKeys.katana.service.ID.pages.ID.sections(params);
}

function createQueryOptions(params: Params) {
    const { pageID, serviceID } = params;
    return queryOptions({
        queryKey: createQueryKey(params),
        queryFn: async () => {
            const result = await KATANA_API.katana.site.service_id.pages.page_id.sections.GET(params);

            // Seed the individual section data - https://tkdodo.eu/blog/seeding-the-query-cache
            _.each(result.data, (section) => {
                // IDEA: opportunity for automated chaining and updating of dependant data. kind of like events?, i.e. if this data updates, it updates the child, if the child data updates and the parent exists, it updates the parent

                const optimisticObject = createOptimisticResponseObject<TData['data'][number]>(section);

                queryClient.setQueryData(katanaQueryKeys.katana.service.ID.sections.ID({ serviceID, sectionID: section.id }), optimisticObject);

                queryClient.setQueryData(
                    katanaQueryKeys.katana.service.ID.pages.ID.sections.ID({ serviceID, pageID, sectionID: section.id }),
                    optimisticObject
                );
            });

            return result;
        },
        staleTime: SECOND * 25,
        enabled: TruthKeys(params),
        select: (data) => {
            if (data?.status === 200) {
                return data.data;
            }
        }
    });
}

const setQueryData = createSetQueryDataMethod<Params, TData200>(createQueryKey);

function cancelQueries(params: Params) {
    return queryClient.cancelQueries({ queryKey: createQueryKey(params) });
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Gets all the sections of a specific page on the service
 */
function _useQuery(params: Params) {
    return useQuery(createQueryOptions(params));
}

export const pageSections = Object.freeze({
    useQuery: _useQuery,
    createQueryOptions,
    createQueryKey,
    optimistic: Object.assign(createOptimisticMethods(setQueryData), {
        predicates: {
            newSection: _newSection
        }
    }),
    cancelQueries
});
