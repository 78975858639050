/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import RequestLoader from 'components/Loaders/Request';
import NXBox from 'components/NXBox';
import { Flex } from 'components/Utils/Flex';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_NXTableFooterTanstackListShowButton.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Props = {
    isFetchingNextPage: boolean;
    hasNextPage: boolean;
    fetchNextPage: () => void;
    children?: React.ReactNode;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _NXTableFooterTanstackListShowButton: React.FC<Props> = ({ isFetchingNextPage, hasNextPage, fetchNextPage, children }) => {
    /***** RENDER HELPERS *****/
    const canRenderNoNextPage = !hasNextPage && !isFetchingNextPage && children;

    /***** RENDER *****/
    if (!canRenderNoNextPage) return;
    return (
        <NXBox.Footer className="NXTableFooterTanstackListShowButton">
            {isFetchingNextPage && <RequestLoader />}
            {hasNextPage && !isFetchingNextPage && (
                <Anchor onClick={fetchNextPage} color="primary">
                    <Text align--center size--s uppercase decoration="none">
                        Show More
                    </Text>
                </Anchor>
            )}
            {canRenderNoNextPage && (
                <Flex fullWidth justify="center">
                    <Text align--center size--s secondary>
                        {children}
                    </Text>
                </Flex>
            )}
        </NXBox.Footer>
    );
};
