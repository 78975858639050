import classNames from 'classnames';
import { OutlineButton } from 'components/Buttons/OutlineButton';
import React from 'react';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type QuantityButton = React.FC<QuantityButtonProps>;

type QuantityButtonProps = {
    icon: React.ReactElement;
    onClick: any;
    disabled?: boolean;
};

export const QuantityButton: QuantityButton = ({ icon, onClick, disabled }) => {
    const buttonClassNames = classNames('sslProducts__quantityButton', {
        'sslProducts__quantityButton--disabled': disabled
    });
    return (
        <OutlineButton onClick={(e) => onClick(e)} className={buttonClassNames}>
            {icon}
        </OutlineButton>
    );
};
