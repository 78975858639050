import classNames from 'classnames';
import { Shimmer } from 'components/Shimmer';
import { _TextVMax as TextVMax } from 'components/Utils/Text/VMax/TextVMax';
import _ from 'lodash';
import { useMemo, useRef } from 'react';
import './_Skeleton.scss';

const LINE_HEIGHT_NORMAL = 1.2;

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
export namespace _TextVMaxSkeleton {
    export type BaseProps = {
        isLoading: boolean;
        width?: number | string | 'random';
        children?: React.ReactNode;
    };

    export type Props = BaseProps & TextVMax.Props;
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _TextVMaxSkeleton: React.FC<_TextVMaxSkeleton.Props> = ({ isLoading, width, children, ...restProps }) => {
    const { size = 16, className } = restProps;

    /***** HOOKS *****/
    const randomWidthRef = useRef(_.random(10, 100));

    /***** RENDER HELPERS *****/
    const _width = useMemo(() => {
        if (width === 'random') {
            return `${randomWidthRef.current}%`;
        }

        if (width === undefined) {
            return `min(100%, 125px)`;
        }

        return width;
    }, [width]);

    /***** RENDER *****/
    if (isLoading) {
        return <Shimmer className={classNames('TextVMaxSkeleton', className)} style={{ width: _width, height: size * LINE_HEIGHT_NORMAL }} />;
    }
    return <TextVMax {...restProps}>{children}</TextVMax>;
};
