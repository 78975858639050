import type { ErrorRouteComponent } from '@tanstack/react-router';
import { useRouter } from '@tanstack/react-router';
import FetchPageError from 'components/Errors/FetchPageError';
import { useSuperUserItem } from 'components/StaffMenu/SuperUser/hooks/useSuperUserItem';
import useEffectAfterMount from 'utilities/hooks/useEffectAfterMount';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const VMaxErrorComponent: ErrorRouteComponent = ({ error }) => {
    /***** HOOKS *****/
    const isEnabled = useSuperUserItem('KATANA_VMAX');
    const router = useRouter();

    /***** EFFECTS *****/
    useEffectAfterMount(() => {
        router.invalidate();
    }, [isEnabled]);

    /***** RENDER *****/
    return <FetchPageError message={error.message} />;
};
