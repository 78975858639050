import { createRoute, redirect } from '@tanstack/react-router';
import { loadAppConfig } from 'App/actions/loadAppConfig';
import AppLoader from 'components/Loaders/App';
import { pushNotification } from 'components/Toast/functions';
import { RootRoute } from 'router/__root';
import { NXQuery } from 'utilities/query';
import { z } from 'zod';

/**********************************************************************************************************
 *   ROUTE COMPONENT START
 **********************************************************************************************************/
export const MicrosoftProviderCallbackRoute = createRoute({
    getParentRoute: () => RootRoute,
    path: '/oauth/microsoft',
    pendingMs: 0, // ensure appLoader always shows
    pendingComponent: () => <AppLoader fullHeight />,
    staticData: {
        preventLoadAppConfig: true
    },
    validateSearch: z.object({
        code: z.string()
    }),
    loaderDeps: ({ search }) => ({ token: search.code }),
    async loader({ deps: { token } }) {
        const provider = 'microsoft';

        try {
            await NXQuery.auth.oauth.login.fetch({ provider, token });
        } catch (error) {
            pushNotification(error?.errors?.[0], null, 'global');
            throw redirect({ to: '/login' });
        }

        await loadAppConfig({
            context: 'loader',
            onError: () => redirect({ to: '/login', throw: true })
        });
    }
});
