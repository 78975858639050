/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { createContext } from 'react';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
export const InvoicesTableContext = createContext({});

export const INVOICE_HEADINGS = {
    INVOICE_ID: 'invoice_id',
    STATUS: 'status',
    TOTAL: 'total',
    DUE_DATE: 'due_date',
    DATE_PAID: 'date_paid',
    ACTION: 'action',
    DATE_ORDERED: 'created_date'
} as const;

export const ORDER_HEADINGS = {
    ORDER_ID: 'invoice_id',
    DATE_ORDERED: 'created_date',
    USER: 'user',
    TOTAL: 'total',
    ACTION: 'orderAction'
} as const;

export const sortableHeadings = [INVOICE_HEADINGS.DUE_DATE, INVOICE_HEADINGS.DATE_PAID] as const;

export const orderSortableHeadings = [ORDER_HEADINGS.DATE_ORDERED] as const;

export const desktopTableHeadings = [
    [INVOICE_HEADINGS.INVOICE_ID, 'Invoice #'],
    [INVOICE_HEADINGS.STATUS, 'Status'],
    [INVOICE_HEADINGS.TOTAL, 'Total'],
    [INVOICE_HEADINGS.DUE_DATE, 'Due Date'],
    [INVOICE_HEADINGS.DATE_PAID, 'Date Paid'],
    [INVOICE_HEADINGS.ACTION, '']
] as const;

export const orderDesktopTableHeadings = [
    [ORDER_HEADINGS.ORDER_ID, 'Order #'],
    [ORDER_HEADINGS.DATE_ORDERED, 'Order Date'],
    [ORDER_HEADINGS.USER, 'Placed By'],
    [ORDER_HEADINGS.TOTAL, 'Total'],
    [ORDER_HEADINGS.ACTION, '']
] as const;

export const mobileTableHeadings = [
    [INVOICE_HEADINGS.INVOICE_ID, 'Invoice #'],
    [INVOICE_HEADINGS.STATUS, 'Status'],
    [INVOICE_HEADINGS.DUE_DATE, 'Due Date']
] as const;

export const orderMobileTableHeadings = [
    [ORDER_HEADINGS.ORDER_ID, 'Order #'],
    [ORDER_HEADINGS.DATE_ORDERED, 'Order Date'],
    [ORDER_HEADINGS.TOTAL, 'Total']
] as const;

export const desktopColumns = '95px 1fr 1fr 1fr 1fr NXActions';
export const orderDesktopColumns = '95px 1fr 1fr 1fr NXActions';
export const mobileColumns = '80px 1fr 1fr';
export const mobileColumnsHead = `${mobileColumns} 50px`;

export const mobileTableSizes = ['sm', 'xs'];
