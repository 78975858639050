/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useMutation, useMutationState } from '@tanstack/react-query';
import { cloneDeep } from 'lodash';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { useSubscriptionsQuery } from 'containers/account/queries/useSubscriptionsQuery';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { API as AccountAPI } from 'utilities/api/account';
import { handleDefaultErrorNotification } from 'utilities/methods/commonActions/handleDefaultErrorNotification';
import { handleDefaultSuccessNotification } from 'utilities/methods/commonActions/handleDefaultSuccessNotification';
import { createBaseQueryKey } from 'utilities/methods/tanstack/createBaseQueryKey';
import type { NXQueryUtils } from 'utilities/methods/tanstack/types';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type UseSubscriptionMutation = NXQueryUtils.TMutation<typeof AccountAPI.account.PUT.subscription>;

const createMutationKey = () => createBaseQueryKey(['account', 'subscriptions']);
const handleOptimistic = (subscription: string, enable: boolean) => {
    useSubscriptionsQuery.optimistic((old) => {
        const cloned = cloneDeep(old);

        switch (subscription) {
            case 'smsPromotion':
                cloned.data.data.attributes.sms_promo_enabled = enable;
                break;
            case 'emailPromotion':
                cloned.data.data.attributes.promotion_email_enabled = enable;
                break;
            case 'smsInvoice':
                cloned.data.data.attributes.sms_invoice_enabled = enable;
                break;
            case 'smsNotifications':
                cloned.data.data.attributes.sms_domain_enabled = enable;
                break;
            case 'smsSuspension':
                cloned.data.data.attributes.sms_suspend_enabled = enable;
                break;
        }

        return cloned;
    });
};

/**
 * Exposes the current state of the mutation. Since this mutation has a static queryKey, this state
 * is shared across all instances of the mutation.
 */
const useSubscriptionMutationState = () => {
    /***** HOOKS *****/
    const result = useMutationState({
        filters: { mutationKey: createMutationKey() },
        select: (mutation) => mutation?.state
    });

    return result.at(-1) ?? ({} as (typeof result)[number]);
};

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
const _useSubscriptionMutation: UseSubscriptionMutation = (options) => {
    /***** QUERIES *****/
    return useMutation({
        mutationKey: createMutationKey(),
        mutationFn: AccountAPI.account.PUT.subscription,
        onMutate({ subscription, enable }) {
            handleOptimistic(subscription, enable);
        },
        onSuccess({ data }) {
            useSubscriptionsQuery.invalidate();
            handleDefaultSuccessNotification(data);
        },
        onError(error, { subscription, enable }) {
            handleOptimistic(subscription, !enable);
            handleDefaultErrorNotification(error);
        },
        ...options
    });
};
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

/***** EXPORTS *****/
export const useSubscriptionsMutation = Object.assign(_useSubscriptionMutation, {
    createMutationKey,
    useState: useSubscriptionMutationState
});
