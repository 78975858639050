import { queryOptions, useQuery } from '@tanstack/react-query';
import { queryClient } from 'store/queryClient';
import { WP_SUPPORT_CENTRE_API } from 'utilities/api/ventraip-website-wp-support-centre';
import type { VentraIPWordpressSupportCentreAPI } from 'utilities/api/ventraip-website-wp-support-centre/types';
import { createBaseQueryKey } from 'utilities/methods/tanstack/createBaseQueryKey';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type PartialParams = Partial<VentraIPWordpressSupportCentreAPI.SearchParams> & { slug?: string };

function createQueryKey(params: PartialParams) {
    return createBaseQueryKey(['ventraip-website-wp-support-centre', 'support-articles', params]);
}

function createQueryOptions(params: PartialParams) {
    return queryOptions({
        queryKey: createQueryKey(params),
        queryFn: () => WP_SUPPORT_CENTRE_API.support_articles.single.GET(params),
        enabled: Boolean(params.slug),
        select: (data) => {
            return data?.data;
        }
    });
}

function prefetchQuery(params: PartialParams) {
    return queryClient.prefetchQuery(createQueryOptions(params));
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useQuery(params: PartialParams) {
    return useQuery(createQueryOptions(params));
}

/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const getArticle = Object.freeze({
    useQuery: _useQuery,
    prefetchQuery
});
