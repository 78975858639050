import { hashKey, useMutation, useQueryClient } from '@tanstack/react-query';
import { APP_MOUNTING } from 'App/actionTypes';
import { SERVICES_INITIAL_STATE } from 'containers/services/actionTypes';
import { noop } from 'lodash';
import { useDispatch } from 'react-redux';
import { NXQuery } from 'utilities/query';

/**
 * Sets the application into a loading state (AppLoader) before remounting
 * the application. Ensure this always is set to false at the end of the method
 */
const useSetAppMounting = () => {
    /***** HOOKS *****/
    const dispatch = useDispatch();

    return (state: boolean) => {
        dispatch({
            type: APP_MOUNTING,
            app_mounting: state
        });
    };
};

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Custom select account function to be used while the user is logged in. This automatically handles app mounting
 * states, as well as removing necessary queries from the cache (without causing a redirect due to authentication)
 */
export const useSelectAccountMutation = () => {
    /***** HOOKS *****/
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const setAppMounting = useSetAppMounting();

    /***** QUERIES *****/
    const { mutateAsync: handleSelectAccountAsync } = NXQuery.auth.selectAccount.useMutation();

    /***** HOOK RESULTS *****/
    return useMutation({
        onMutate: () => {
            setAppMounting(true);
            dispatch({
                type: SERVICES_INITIAL_STATE
            });
        },
        async mutationFn(id: number) {
            await handleSelectAccountAsync(id);

            queryClient.removeQueries({
                // We do not want to remove the userDataKey because otherwise we will instantly be kicked out of the application (due to missing auth).
                // Instead, we want to remove all other queries and start fetching the userData immediately after
                predicate: (q) => q.queryHash !== hashKey(NXQuery.auth.userData.createQueryKey())
            });

            // must refetch to ensure that these match up with our newly selected account token
            // prettier-ignore
            await Promise.allSettled([
                NXQuery.auth.login.checkToken.fetch(), 
                NXQuery.auth.userData.forceFetch()
            ]);
        },
        onSuccess: noop,
        onSettled: () => setAppMounting(false)
    });
};
