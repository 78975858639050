import { useOrderingFormContext } from 'containers/orderingForm/contexts/useOrderingFormContext';
import { PageHeader } from 'containers/orderingForm/pageHeader';
import { RecommendedDomains } from 'containers/orderingForm/pages/registerPage/recommendedDomains';
import { RegistrationAvailabilityConfirmation } from 'containers/orderingForm/pages/registerPage/registrationAvailabilityConfirmation';
import { Searchbar } from 'containers/orderingForm/pages/registerPage/searchbar';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type RegisterPage = React.FC<RegisterPageProps>;
type RegisterPageProps = {
    name: string;
    domainSearchValue: string;
    setDomainSearchValue: React.Dispatch<React.SetStateAction<string>>;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const RegisterPage: RegisterPage = ({ name, domainSearchValue, setDomainSearchValue }) => {
    /***** HOOKS *****/
    const { currentPage } = useOrderingFormContext();

    /***** RENDER *****/
    if (currentPage !== name) return;

    return (
        <div className="domainRegistration__suggestionPage">
            <PageHeader title="Domain Name Registration" />
            <Searchbar domainSearchValue={domainSearchValue} setDomainSearchValue={setDomainSearchValue} />
            <RegistrationAvailabilityConfirmation />
            <RecommendedDomains />
        </div>
    );
};
