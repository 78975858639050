import type { API } from 'utilities/api/login';
import { createBaseQueryKey } from 'utilities/methods/tanstack/createBaseQueryKey';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type StaffLoginProps = { isActive: boolean; token: string | null };

/**********************************************************************************************************
 *   QUERY KEY TREE START
 **********************************************************************************************************/
export const authenticationQueryKeys = Object.assign(() => createBaseQueryKey(['authentication']), {
    listTwoFactor: () => [...authenticationQueryKeys(), 'listTwoFactor'] as const,
    checkToken: () => [...authenticationQueryKeys(), 'checkToken'] as const,
    login: () => [...authenticationQueryKeys(), 'login'] as const,
    userData: () => [...authenticationQueryKeys(), 'userData'] as const,
    staffLogin: (props: StaffLoginProps) => [...authenticationQueryKeys(), 'staffLogin', props] as const,
    accountList: () => [...authenticationQueryKeys(), 'accountList'] as const,
    oAuthLogin: (params: API.login.oAuthSubmit.params) => [...authenticationQueryKeys(), 'oAuth', params] as const
});

export const authenticationMutationKeys = Object.assign(() => createBaseQueryKey(['authentication']), {
    logout: () => [...authenticationMutationKeys(), 'logout'] as const,
    login: () => [...authenticationMutationKeys(), 'login'] as const,
    selectAccount: () => [...authenticationMutationKeys(), 'selectAccount'] as const,
    submitTwoFactor: () => [...authenticationMutationKeys(), 'submitTwoFactor'] as const,
    requestDefaultTwoFactor: () => [...authenticationMutationKeys(), 'requestDefaultTwoFactor'] as const
});
